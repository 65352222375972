/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/BloomGooLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip, Modal, Table, Tab, Tabs } from "react-bootstrap";
import Axios from "axios";
import { TimeDateFormat } from "../../../../_helpers/DateHelpers";
import { setFilterConfig } from "../../../../../app/_helpers/LocalStorageConfig";


export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);
  //console.log(user);
  const [isShowHistoryLogIn, setIsShowHistoryLogIn] = useState(false);
  const [key, setKey] = useState("history")
  const historyLogIn = [
    { dateTimeLogin: 12312312, dateTimeLogout: 1231231231 },
    { dateTimeLogin: 1231231231, dateTimeLogout: 2321231231 },
    { dateTimeLogin: 2321231231, dateTimeLogout: 8328331231231 },
    { dateTimeLogin: 8328331231231, dateTimeLogout: 123123123112 },
  ]

  const historyActive = [
    { function: 'Tòa nhà', active: 'Thêm mới', name: 'Tòa nhà Funny', activeTime: 123123123 },
    { function: 'Tòa nhà', active: 'Chỉnh sửa', name: 'Tòa nhà Hòa_Pig', activeTime: 1232123123 },
    { function: 'Đơn vị thuê', active: 'Xóa', name: 'CH_103/Tòa nhà Hòa_Pig', activeTime: 123123123 },
  ]

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-sm px-0"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">

            < FormattedMessage id="AUTH_USER" />,

          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstName} {user.lastName}
          </span>
          <span className="symbol symbol-30 symbol-light-success">

          </span>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id=" language-panel-tooltip">

                Tài khoản:  {user.firstName} {user.lastName}

                <div>{user.email} </div>
              </Tooltip>

            }
          >
            <div className="btn btn-icon btn-clean btn-dropdown btn-sm">
              <img
                className="h-30px w-30px rounded"
                src={`${Axios.defaults.cdnURL}/${user && user.avatar}`}
                alt=""
                onError={(e) => e.target.src = "/media/public/blank.png"}
              />
            </div>
          </OverlayTrigger>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstName} {user.lastName}

                </div>
                {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span> */}
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div className="d-flex align-items-center justify-content-between flex-wrap p-5 bgi-size-cover bgi-no-repeat rounded-top bg-primary">
              <div className="symbol bg-white-o-15 mr-3">
                {/* <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.firstName}
                </span> */}

                <img
                  className="h-50px w-50px rounded"
                  src={`${Axios.defaults.cdnURL}/${user && user.avatar}`}
                  alt=""
                  onError={(e) => e.target.src = "/media/public/blank.png"}
                />

              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstName} {user.lastName}
                <div className="font-size-sm"> {user.tel}</div>

              </div>
              {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/settings/personal" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-avatar text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  <FormattedMessage id="SETTINGS.UI.Title.Persional" />
                </div>
                <div className="text-muted">
                  <FormattedMessage id="USER_PROFILE.UI.Title.Persional" />
                  {/* <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> */}
                </div>
              </div>
            </div>
          </Link>
          <Link to="/tasks" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-graphic text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Công việc của tôi</div>
                <div className="text-muted">Danh sách dự án, công việc</div>
              </div>
            </div>
          </Link>

          {/* Tạm off */}
          {/* <Link to="/chats" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-chat text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Tin nhắn của tôi</div>
                <div className="text-muted">Hộp thư trao đổi</div>
              </div>
            </div>
          </Link> */}


          <Link to="/notifications" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-rotate text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Hoạt động của tôi</div>
                <div className="text-muted">Nhật ký và thông báo</div>
              </div>
            </div>
          </Link>

          {/* <Link to="/notifications" className="navi-item px-8 cursor-pointer" onClick={() => setIsShowHistoryLogIn(true)}> */}
          <Link to="/notifications" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="fas fa-history text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Lịch sử đăng nhập</div>
                <div className="text-muted">Đăng nhập và thao tác</div>
              </div>
            </div>
          </Link>

          <Modal
            size="lg"
            show={isShowHistoryLogIn}

            aria-labelledby="example-modal-sizes-title-lg"
            id="modal-scroll"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                <span className="fas fa-history"> </span>&nbsp;
                <FormattedMessage
                  id="HISTORY_LOGIN.UI.Title" />
              </Modal.Title>
            </Modal.Header>
            {(
              <>
                <Modal.Body className="overlay overlay-block cursor-default">
                  {/* {isLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )} */}
                  <form className="form form-label-right">
                    <Tabs
                      defaultActiveKey="history"
                      transition={false}
                      id="noanim-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                    >
                      <Tab eventKey="history" title="Lịch sử đăng nhập">
                        <div className="form-group">

                          <Table bordered hover size="sm" className="mt-2">
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center"><FormattedMessage id="MODEL.UI.historyLogin.login" ></FormattedMessage></th>
                                <th className="text-center"><FormattedMessage id="MODEL.UI.historyLogin.logout" /></th>
                                <th className="text-center"><FormattedMessage id="Chi tiết" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                historyLogIn && historyLogIn.map((item, rowIndex) => (
                                  <tr key={`${rowIndex}-history`}>
                                    <td className="text-center">{rowIndex + 1}</td>
                                    <td className="text-center">{TimeDateFormat(item.dateTimeLogin)}</td>
                                    <td className="text-center">{TimeDateFormat(item.dateTimeLogout)}</td>
                                    <td className="text-center" onClick={() => setKey('details')}><a><i className="fas fa-search"></i></a></td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>

                        </div>
                      </Tab>
                      <Tab eventKey="details" title="Lịch sử thao tác" disabled>
                        <div className="form-group-right">
                          <div className="text-right mt-2">{`Phiên đăng nhập: ${TimeDateFormat(123123)} - ${TimeDateFormat(12312312)}`}</div>
                          <Table bordered hover size="sm">
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Chức năng</th>
                                <th className="text-center">Thao tác</th>
                                <th className="text-center">Tên</th>
                                <th className="text-center">Thời gian</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                historyActive && historyActive.map((item, rowIndex) => (
                                  <tr key={`${rowIndex}-details`}>
                                    <td className="text-center">{rowIndex + 1}</td>
                                    <td className="text-center">{item.function}</td>
                                    <td className="text-center">{item.active}</td>
                                    <td className="text-center">{item.name}</td>
                                    <td className="text-center">{TimeDateFormat(item.activeTime)}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>

                        </div>
                      </Tab>
                    </Tabs>


                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={() => setIsShowHistoryLogIn(!isShowHistoryLogIn)}
                    className="btn btn-light btn-elevate btn-sm"
                  >
                    <i className="flaticon2-left-arrow-1 icon-ms"></i>
                    <FormattedMessage id="COMMONS.UI.Cancel"></FormattedMessage>
                  </button>
                </Modal.Footer>
              </>
            )
            }
          </Modal >

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer px-8 py-5">
            <Link
              to="/dang-xuat"
              className="btn btn-light-primary font-weight-bold"
            >
              <i className="flaticon-logout"></i>
              Đăng xuất
            </Link>

            <Link
              to="/nang-cap"
              className="btn btn-clean font-weight-bold"
            >
              <i className="flaticon2-arrow-up"></i>
              Nâng cấp
            </Link>
          </div>
        </div >
      </Dropdown.Menu >
    </Dropdown >
  );
}
