import { createSlice } from "@reduxjs/toolkit";
import { CUSTOMER_TYPES_OBJ, ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialEntryExitHistoriesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  entryExitHistoryForEdit: null,
  entryExitHistoryForShow: null,
  currentEntryExitHistory: null,
  currentEntryExitHistories: [],
  notification: null,
  excludedEntities: [],
  excludedTotalCount: 0,
  error: null,
  isError: null,
  errorType: null,
  entryExitHistoryIndividualImport: null,
  entryExitHistoryOrganizationImport: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const entryExitHistoriesSlice = createSlice({
  name: "entryExitHistories",
  initialState: initialEntryExitHistoriesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentEntryExitHistory = null;
      state.currentEntryExitHistories = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.entryExitHistoryForEdit = null;
    },

    resetCurrentEntryExitHistories: (state, action) => {
      state.currentEntryExitHistory = null;
      state.currentEntryExitHistories = [];
    },

    // getEntryExitHistoryById
    entryExitHistoryFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.entryExitHistoryForEdit = action.payload.entryExitHistoryForEdit;
      state.error = null;

    },
    //show
    entryExitHistorieshowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.entryExitHistoryForShow = action.payload.entryExitHistoryForShow;
      state.error = null;

    },
    // findAllEntryExitHistories
    entryExitHistoriesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findEntryExitHistories
    entryExitHistoriesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },

    entryExitHistoriesFetchedExclude: (state, action) => {
      //console.log("Slice")
      const { excludedTotalCount, excludedEntities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.excludedEntities = excludedEntities;
      state.excludedTotalCount = excludedTotalCount;
    },
    // findParentEntryExitHistories
    entryExitHistoriesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createEntryExitHistory
    entryExitHistoryCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.currentEntryExitHistory = action.payload.entryExitHistory;
      state.currentEntryExitHistories.push(action.payload.entryExitHistory);
      state.entities.unshift(action.payload.entryExitHistory);
      state.excludedEntities.unshift(action.payload.entryExitHistory);
    },
    // updateEntryExitHistory
    entryExitHistoryUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.entryExitHistory.id) {
          return action.payload.entryExitHistory;
        }
        return entity;
      });
      state.currentEntryExitHistory = action.payload.entryExitHistory;
      state.currentEntryExitHistories.push(action.payload.entryExitHistory);

    },
    // deleteEntryExitHistory
    entryExitHistoryDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);

    },
    // deleteEntryExitHistories
    entryExitHistoriesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
      state.currentEntryExitHistory = null;
      state.currentEntryExitHistories = [];

    },
    // entryExitHistoriesUpdateState
    entryExitHistoriesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    entryExitHistoryImportFetched: (state, action) => {
      const { entryExitHistoryImport, customerType } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.entryExitHistoryIndividualImport = (customerType === CUSTOMER_TYPES_OBJ.INDIVIDUAL) ? entryExitHistoryImport : state.entryExitHistoryIndividualImport;
      state.entryExitHistoryOrganizationImport = (customerType === CUSTOMER_TYPES_OBJ.ORGANIZATION) ? entryExitHistoryImport : state.entryExitHistoryOrganizationImport;
    },

    entryExitHistoryImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },

    resetEntryExitHistoryImport: (state, action) => {
      action.payload === CUSTOMER_TYPES_OBJ.INDIVIDUAL && (state.entryExitHistoryIndividualImport = null);
      action.payload === CUSTOMER_TYPES_OBJ.ORGANIZATION && (state.entryExitHistoryOrganizationImport = null);
    },
  }
});
