import axios from "axios";

export const ASSET_INVENTORY_PERIODS_URL = "/assetInventoryPeriods";

// CREATE =>  POST: add a new assetInventoryPeriod to the server
export function createAssetInventoryPeriod(assetInventoryPeriod) {
  return axios.post(ASSET_INVENTORY_PERIODS_URL, assetInventoryPeriod);
}

// READ
export function getAllAssetInventoryPeriods() {
  return axios.get(ASSET_INVENTORY_PERIODS_URL);
}

export function getInitAssetInventoryPeriod() {
  return axios.get(`${ASSET_INVENTORY_PERIODS_URL}/create`);
}

export function getAssetInventoryPeriodCloneById(assetInventoryPeriodId) {
  return axios.get(`${ASSET_INVENTORY_PERIODS_URL}/${assetInventoryPeriodId}/clone`);
}
export function getAssetInventoryPeriodById(assetInventoryPeriodId) {
  return axios.get(`${ASSET_INVENTORY_PERIODS_URL}/${assetInventoryPeriodId}`);
}

export function getAssetInventoryPeriodEditById(assetInventoryPeriodId) {
  return axios.get(`${ASSET_INVENTORY_PERIODS_URL}/${assetInventoryPeriodId}/edit`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAssetInventoryPeriods(queryParams) {
  return axios.post(`${ASSET_INVENTORY_PERIODS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateAssetInventoryPeriod(assetInventoryPeriod) {
  return axios.put(`${ASSET_INVENTORY_PERIODS_URL}/${assetInventoryPeriod.id}`, assetInventoryPeriod);
}

// UPDATE Status
export function updateStatusForAssetInventoryPeriods(id, status) {
  return axios.put(`${ASSET_INVENTORY_PERIODS_URL}/${id}/status/${status}`,
    {
    }
  );
}

// DELETE => delete the assetInventoryPeriod from the server
export function deleteAssetInventoryPeriod(assetInventoryPeriodId) {
  return axios.delete(`${ASSET_INVENTORY_PERIODS_URL}/${assetInventoryPeriodId}`);
}

// DELETE AssetInventoryPeriods by ids
export function deleteAssetInventoryPeriods(ids) {
  return axios.delete(`${ASSET_INVENTORY_PERIODS_URL}/bulk`, { data: { ids } });

}

export function findAssetInventoryPeriodsCount(ids) {
  return axios.get(`${ASSET_INVENTORY_PERIODS_URL}/count`);
}
