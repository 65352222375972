/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import Particle from "react-particles-js";
import particlesConfig from "../../../../_bloomgoo/layout/components/particle/particlesConfig.json";
import { parseDomain } from "parse-domain";
import particlesAplusConfig from "../../../../_bloomgoo/layout/components/particle/aplus/particlesConfig.json";
import particlesBizcitiConfig from "../../../../_bloomgoo/layout/components/particle/bizciti/particlesConfig.json";
import particlesTingtongConfig from "../../../../_bloomgoo/layout/components/particle/tingtong/particlesConfig.json";
import { OrganizationSlide } from "./OrganizationSlide";

const parseResult = parseDomain(window.location.host.split(":")[0]);

const {
  subDomains,
  //  domain,
  //  topLevelDomains
} = parseResult;
const initialValues = {
  organizationCode:
    subDomains === undefined || subDomains === null || subDomains.length === 0
      ? null
      : subDomains[subDomains.length - 1].toUpperCase() === "HOST"
      ? null
      : subDomains[subDomains.length - 1].toUpperCase(),
  email: "",
};

const code =
  subDomains === undefined || subDomains === null || subDomains.length === 0
    ? "chuoicanho"
    : subDomains[subDomains.length - 1];

const VIP_ORGANIZATIONS = [
  {
    code: "aplus",
    title: "Chào mừng bạn đến với APLUS,",
    subTitle:
      "APLUS xây dựng không gian sống chất lượng với căn hộ dịch vụ tiêu chuẩn, tiện nghi, hiện đại.",
    isNavToRegistration: false,
    textClass: "text-aplus",
    textHoverClass: "text-hover-aplus",
    btnClass: "btn-aplus",
    btnHoverClass: "btn-hover-aplus",
    particles: particlesAplusConfig,
  },
  {
    code: "bizciti",
    title: "Chào mừng bạn đến với BizCiti,",
    subTitle:
      "BizCiti, Đơn vị vận hành quản lý cho thuê căn hộ và văn phòng hàng đầu tại Đà Nẵng.",
    isNavToRegistration: false,
    textClass: "text-bizciti",
    textHoverClass: "text-hover-bizciti",
    btnClass: "btn-bizciti",
    btnHoverClass: "btn-hover-bizciti",
    particles: particlesBizcitiConfig,
  },
  {
    code: "tingtong",
    title: "Chào mừng bạn đến với TingTong,",
    subTitle:
      "Tại TingTong chúng tôi khát khao trong việc tiên phong đi đầu và tạo ra các tiêu chuẩn dành riêng cho cộng đồng thuê trọ.",
    isNavToRegistration: false,
    textClass: "text-tingtong",
    textHoverClass: "text-hover-tingtong",
    btnClass: "btn-tingtong",
    btnHoverClass: "btn-hover-tingtong",
    particles: particlesTingtongConfig,
  },
];
const VIP_ORGANIZATION = VIP_ORGANIZATIONS.filter((item) => item.code === code);
const organization =
  VIP_ORGANIZATION.length === 0
    ? {
        code: "chuoicanho",
        title: "Chào mừng bạn trở lại,",
        subTitle:
          "ChuoiCanHo, không chỉ đơn giản là giải pháp quản lý mà còn là một Hệ Sinh Thái Bất Động Sản toàn diện.",
        isNavToRegistration: true,
        textClass: "text-primary",
        btnClass: "btn-primary",
        particles: particlesConfig,
      }
    : VIP_ORGANIZATION[0];
function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    organizationCode: Yup.string()
      .nullable()
      .trim()
      .required(
        intl.formatMessage({
          id: "AUTH.ORGANIZATIONCODE.REQUIRED_FIELD",
        })
      ),

    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.VALIDATION.EMAIL_FIELD",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: "MINIMUM_3_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "MAXIMUM_50_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION_EMAIL_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  let [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setNotification();
      requestPassword(values)
        .then((response) => {
          setNotification({
            status: "success",
            message: intl.formatMessage({
              id: response?.data?.code,
            }),
          });
          // setIsRequested(true);
          setLoading(false);
          setSubmitting(false);
        })
        .catch((error) => {
          setLoading(false);
          setNotification({
            status: "error",
            message: intl.formatMessage({
              id: error?.response?.data?.code
                ? error?.response?.data?.code
                : "ERRORS.COMMONS.GLOBAL",
            }),
          });
          // setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  console.log("formik: ", formik);
  return (
    <>
      {isRequested && <Redirect to="/dang-nhap" />}
      {!isRequested && (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid w-100">
          <div className="order-2 order-lg-1 d-flex flex-column w-50 position-relative">
            <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-8 px-lg-35">
              <div className="d-flex flex-column-fluid flex-column flex-center">
                <Particle
                  params={organization.particles}
                  className="particles-container"
                />
                <div
                  className="login-form login-signin bg-white"
                  style={{ zIndex: 999999 }}
                >
                  <div className="w-lg-450px bg-body rounded shadow-sm p-10 p-lg-10 mx-auto">
                    <span
                      type="button"
                      className="pb-5"
                      style={{ zIndex: 999999 }}
                    >
                      <img
                        src={`/media/login/${organization.code}/logo.png`}
                        width="220"
                        height="66"
                        alt=""
                      />
                    </span>
                    <form className="form">
                      <div className="mb-5">
                        <h1
                          className="text-dark mb-3"
                          style={{ fontSize: "1.8rem" }}
                        >
                          Quên mật khẩu
                        </h1>
                        <div className="text-warning-500 fw-bold fs-4">
                          {organization.isNavToRegistration && (
                            <div className="mt-5">
                              <div className="text-warning-400 fw-bold fs-4">
                                Bạn chưa có tài khoản?
                                <Link
                                  to="/dang-ky"
                                  className={`link-primary fw-bolder ml-2 ${organization.textClass}`}
                                >
                                  Đăng ký ngay!
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {notification?.message && (
                        <div
                          className={`mb-10 alert alert-custom alert-light-${
                            notification?.status === "success"
                              ? "primary"
                              : "danger"
                          } alert-dismissible`}
                        >
                          <div className="alert-text font-weight-bold">
                            {notification?.message}
                          </div>
                        </div>
                      )}
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Mã tổ chức<span className="required">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="organizationCode"
                            autoComplete="off"
                            {...formik.getFieldProps("organizationCode")}
                            className={`form-control  ${getInputClasses(
                              "organizationCode"
                            )}`}
                            placeholder="Mã tổ chức"
                            disabled={
                              !(
                                subDomains === undefined ||
                                subDomains === null ||
                                subDomains.length === 0 ||
                                subDomains[
                                  subDomains.length - 1
                                ].toUpperCase() === "HOST"
                              )
                            }
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              .chuoicanho.com
                            </span>
                          </div>
                        </div>
                        {formik.touched.organizationCode &&
                        formik.errors.organizationCode ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.organizationCode}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email <span className="required">*</span>
                        </label>
                        <div className="input-icon">
                          <input
                            placeholder="Nhập email"
                            type="email"
                            className={`form-control form-control-md ${getInputClasses(
                              "email"
                            )}`}
                            name="email"
                            autoComplete="off"
                            {...formik.getFieldProps("email")}
                          />
                          <span>
                            <i
                              className={`fas flaticon-mail-1 icon-md text-primary ${organization.textClass}`}
                            ></i>
                          </span>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.email}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="text-center">
                        <button
                          type="button"
                          onClick={() => formik.handleSubmit()}
                          disabled={formik.isSubmitting || !formik.values.email}
                          className={`btn btn-lg btn-primary ${organization.btnClass} w-100 mb-5`}
                        >
                          <span className="indicator-label">Xác nhận</span>
                          {loading && (
                            <span className="indicator-progress">
                              <span className="spinner-border spinner-border-sm align-middle ms-2 ml-2"></span>
                            </span>
                          )}
                        </button>
                        <span className={`${organization.code}`}>
                          <Link to="/auth">Huỷ bỏ</Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OrganizationSlide organizationCode={organization.code} />
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
