/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { CheckboxField, Input } from "../../../../_bloomgoo/_partials/controls";
import * as actionsSettings from "../_redux/settings/settingsActions";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
export default function SettingsEmailsDialog() {
  const dispatch = useDispatch();
  const { settingForEdit, actionsLoading } = useSelector(
    (state) => ({
      settingForEdit: state.settings.settingForEdit,
      actionsLoading: state.settings.actionsLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actionsSettings.fetchEdit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const saveSettingEmail = (values) => {
    dispatch(actionsSettings.updateSetting(values));
  };

  const emailSchema = Yup.object().shape({

  });

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    }),
    shallowEqual
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={settingForEdit}
        validationSchema={emailSchema}
        onSubmit={(values) => {
          saveSettingEmail(values);
        }}
      >
        {({ handleSubmit, handleChange, handleReset, ...props }) => (
          <>
            <Modal.Body className="p-0">
              <div className="modal-body container container-fluid">
                <div className=" row py-5 ">
                  <div className="col-lg-12">
                    <div className="row  pb-3">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        Email gửi
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailSender"
                          component={Input}
                          className="form-control form-control-md"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3 ">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        BCC
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailBcc"
                          component={Input}
                          className="form-control form-control-md"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        Tài khoản
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailAddress"
                          component={Input}
                          className="form-control form-control-md"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        Mật khẩu
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailPassword"
                          component={Input}
                          className="form-control form-control-md"
                          type="password"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3 ">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        SMTP Server
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailSmtp"
                          component={Input}
                          className="form-control form-control-md"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3 ">
                      <div className="col-2 d-flex  align-items-center pl-1">
                        Port
                      </div>
                      <div className="col-10">
                        <Field
                          name="emailPort"
                          component={Input}
                          className="form-control form-control-md"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                    <div className="row  pb-3" hidden={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}>
                      <div className="col-2"></div>
                      <div className="col-5 d-flex  align-items-center">
                        <Field
                          name="isUsingSSL"
                          component={CheckboxField}
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e);
                          }}

                        />
                        <span className="ml-1" disabled={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE")}>Sử dụng SSL</span>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                      <div className="">
                        <Button
                          handleClick={handleReset}
                          message="COMMONS.UI.Reload"
                          icon="flaticon2-refresh-arrow icon-sm"
                          color="secondary"
                          disabled={actionsLoading}
                        />
                        &nbsp;
                        <Button
                          isLoading={actionsLoading}
                          handleClick={() => handleSubmit()}
                          disabled={actionsLoading}
                          message="COMMONS.UI.Save"
                          icon="flaticon-edit-1 icon-sm"
                          hidden={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Formik>
    </>
  );
}
