/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessages, Input, ModalProgressBar } from "../../../../_bloomgoo/_partials/controls";
import * as actions from "../_redux/api-keys/apiKeysActions";
import { FormattedMessage } from "react-intl";
import { ERROR_TYPES_OBJ, useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { Field, Formik } from "formik";
import * as Yup from "yup";

const APIKeyPassWordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /(?=.*?[A-Z]).{6,}/,
      "Ít nhất 6 ký tự, ít nhất 1 ký tự hoa "
    )
    .required(),
});

export function APIKeyDeleteDialog({ selectedRow, show, onHide, setIds, setSelectedRows, queryParams }) {


  const dispatch = useDispatch();
  const { actionsLoading, error, errorType, apiKeyEdit } = useSelector(
    (state) => ({
      actionsLoading: state.apiKeys.actionsLoading,
      errorType: state.apiKeys.errorType,
      error: state.apiKeys.error,
      apiKeyEdit: state.apiKeys.apiKeyEdit,
    }),
    shallowEqual
  );

  useDidMountEffect(() => {
    if (errorType === ERROR_TYPES_OBJ.END) {
      setIds([]);
      setSelectedRows([]);
      dispatch(actions.fetchAPIKeys(queryParams));
      if (selectedRow?.id === apiKeyEdit?.id) {
        dispatch(actions.getInitAPIKey());
      }
      onHide();
    }

  }, [errorType, error]);
  const deleteAPIKey = (values) => {
    dispatch(actions.deleteAPIKeyById(selectedRow?.id, values))
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <i className="flaticon-clipboard icon-lg text-white"> &nbsp; </i>
          <FormattedMessage id="API_KEYS.UI.Title.Delete"></FormattedMessage>{" "}
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{ password: "" }}
        validationSchema={APIKeyPassWordSchema}
        onSubmit={(values) => {
          deleteAPIKey(values);
        }}
      >
        {({ handleSubmit, handleChange, errors, ...props }) => (
          <>
            <Modal.Body>
              {error && error.action === "deleteApiKey" && ((error.response === !undefined || error.response === !null) ?
                <ErrorMessages type="danger" message={error && error.response.data ? error.response.data.code : "COMMONS.UI.Notification.Danger"} icon="flaticon-danger text-danger" /> :
                <ErrorMessages type="danger" message={error && error.clientMessage} icon="flaticon-danger text-danger" />
              )
              }
              {!actionsLoading && (
                <span>
                  <FormattedMessage id="MODEL.UI.DeleteConfirm" values={{
                    params: `"${selectedRow?.name ? selectedRow.name : ""}"`,
                  }}></FormattedMessage>
                  <div className="text-italic">Ghi chú: Xóa API Key làm gián đoạn tới các nền tảng bên thứ ba.</div>
                  <div className="mt-3">Xác nhận mật khẩu để xóa API Key</div>
                  <Field
                    name="password"
                    component={Input}
                    required={true}
                    type={"password"}
                    onChange={e => { handleChange(e) }}
                  />
                </span>
              )}
              {actionsLoading && (
                <span>
                  <FormattedMessage id="COMMONS.UI.IsDeleting"></FormattedMessage>
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                handleClick={onHide}
                message="COMMONS.UI.Close"
                icon="flaticon-cancel icon-md"
                className="btn btn-light btn-elevate btn-md"
              />
              &nbsp;
              <Button
                actionsLoading={actionsLoading}
                handleClick={() => handleSubmit()}
                disabled={actionsLoading}
                className="btn btn-delete btn-danger btn-elevate btn-md"
                message="COMMONS.UI.Delete"
                icon="flaticon-edit-1 icon-md"
              />
            </Modal.Footer>
          </>
        )}
      </Formik>


    </Modal>
  );
}


