import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import Particle from "react-particles-js";
import particlesConfig from "../../../../_bloomgoo/layout/components/particle/particlesConfig.json";
import particlesAplusConfig from "../../../../_bloomgoo/layout/components/particle/aplus/particlesConfig.json";
import particlesBizcitiConfig from "../../../../_bloomgoo/layout/components/particle/bizciti/particlesConfig.json";
import { parseDomain } from "parse-domain";
import Recaptcha from 'react-google-invisible-recaptcha';
import { OrganizationSlide } from './OrganizationSlide';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
  const { intl } = props;
  const refRecaptcha = React.useRef(null);

  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .nullable()
      .min(
        3,
        intl.formatMessage({
          id: "MINIMUM_3_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "MAXIMUM_50_SYMBOLS",
        })
      )

      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION_EMAIL_USERNAME_FIELD",
        })
      ),

    password: Yup.string()
      .nullable()
      .trim()
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Chứa ít nhất 8 ký tự, chữ hoa, chữ thường, chữ số và ký tự đặc biệt"
      //   // Ít nhất 8 kí tự, ít nhất 1 chữ hoa,1 chữ thường,1 chữ số và 1 kí tự đặc biệt
      // )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_PASS_FIELD",
        })
      ),
    organizationCode: Yup.string()
      .nullable()
      .trim()
      .required(
        intl.formatMessage({
          id: "AUTH.ORGANIZATIONCODE.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //   return "custom-is-valid";
    // }
    return "";
  };



  // const [error, setError] = useState("");
  const [showPass, setShowPass] = useState(false);

  const parseResult = parseDomain(window.location.host.split(":")[0]);
  const { subDomains } = parseResult;
  const code = (subDomains === undefined || subDomains === null || subDomains.length === 0) ? 'chuoicanho' : subDomains[subDomains.length - 1];
  const VIP_ORGANIZATIONS = [
    { code: 'aplus', title: "Chào mừng bạn đến với APLUS,", subTitle: "APLUS xây dựng không gian sống chất lượng với căn hộ dịch vụ tiêu chuẩn, tiện nghi, hiện đại.", isNavToRegistration: false, textClass: "text-aplus", textHoverClass: "text-hover-aplus", btnClass: "btn-aplus", btnHoverClass: "btn-hover-aplus", particles: particlesAplusConfig },
    { code: 'bizciti', title: "Chào mừng bạn đến với BizCiti,", subTitle: "BizCiti, Đơn vị vận hành quản lý cho thuê căn hộ và văn phòng hàng đầu tại Đà Nẵng.", isNavToRegistration: false, textClass: "text-bizciti", textHoverClass: "text-hover-bizciti", btnClass: "btn-bizciti", btnHoverClass: "btn-hover-bizciti", particles: particlesBizcitiConfig },
    { code: 'tingtong', title: "Chào mừng bạn đến với TingTong,", subTitle: "Tại TingTong chúng tôi khát khao trong việc tiên phong đi đầu và tạo ra các tiêu chuẩn dành riêng cho cộng đồng thuê trọ.", isNavToRegistration: false, textClass: "text-nghiepvu", textHoverClass: "text-hover-nghiepvu", btnClass: "btn-nghiepvu", btnHoverClass: "btn-hover-nghiepvu", particles: particlesBizcitiConfig }
  ];

  const VIP_ORGANIZATION = VIP_ORGANIZATIONS.filter(item => item.code === code);

  const organization = VIP_ORGANIZATION.length === 0 ? { code: 'chuoicanho', title: "Chào mừng bạn trở lại,", subTitle: "ChuoiCanHo, không chỉ đơn giản là giải pháp quản lý mà còn là một Hệ Sinh Thái Bất Động Sản toàn diện.", isNavToRegistration: true, textClass: "text-primary", btnClass: "btn-primary", particles: particlesConfig } : VIP_ORGANIZATION[0];

  const initialValues = {
    organizationCode:
      subDomains === undefined || subDomains === null || subDomains.length === 0
        ? null
        : (subDomains[subDomains.length - 1].toUpperCase() === 'HOST' ? null : subDomains[subDomains.length - 1].toUpperCase()),
    username: null,
    password: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      if (!recaptchaValid) {
        refRecaptcha.current.execute();
      } else {
        setTimeout(() => {
          // step1 call API loggin
          login(values.organizationCode, values.username, values.password)
            .then((response) => {
              disableLoading();
              // action login in authRedux
              props.login(response.data.authToken);
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else {
                // setError(
                //   (error.response &&
                //     error.response.data &&
                //     error.response.data.code) ||
                //   "errors"
                // );
              }

              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );

              disableLoading();
              setSubmitting(false);
              setRecaptchaValid(false);
            });
        }, 1000);
      }
    },
  });

  const onResolved = () => {
    disableLoading();
    setRecaptchaValid(true);
    formik.submitForm();
  }

  return (
    <>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid w-100 bg-white">
        <div className="order-2 order-lg-1 d-flex flex-column w-50 position-relative">
          <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-8 px-lg-35">
            <div className="d-flex flex-column-fluid flex-column flex-center">
              <Particle
                params={organization.particles}
                className="particles-container"
              />

              <div
                className="login-form login-signin bg-white"
                style={{ zIndex: 999999 }}
              >
                <div className="w-lg-450px bg-body rounded shadow-sm p-10 p-lg-10 mx-auto">
                  <span
                    type="button"
                    className="pb-5"
                    style={{ zIndex: 999999 }}
                  >
                    <img
                      src={`/media/login/${organization.code}/logo.png`}
                      width="220"
                      height="66"
                      alt="Logo"
                    />
                  </span>
                  <form className="form" onSubmit={formik.handleSubmit}>
                    <div className="mb-5">
                      <h1 className="text-dark mb-3" style={{ fontSize: "1.8rem" }}>{organization.title}</h1>
                      <div className="text-warning-500 fw-bold fs-4">
                        {organization.subTitle}
                      </div>
                    </div>

                    {formik.status && (
                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                          {formik.status} hoặc tài khoản chưa được xác nhận!
                        </div>
                      </div>
                    )}
                    {/* {error && (<ErrorMessages type="danger" message={error && error.response ? error.response.code : "COMMONS.UI.Notification.Danger"} icon="flaticon-danger text-danger" />)} */}
                    <div className="fv-row mb-5">
                      <label className="form-label fs-6 fw-bolder text-dark">
                        Mã tổ chức<span className="required">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="organizationCode"
                          autoComplete="off"
                          {...formik.getFieldProps("organizationCode")}
                          className={`form-control  ${getInputClasses(
                            "organizationCode"
                          )}`}
                          placeholder="Mã tổ chức"
                          disabled={
                            !(
                              subDomains === undefined ||
                              subDomains === null ||
                              subDomains.length === 0 ||
                              code === 'host' || code === 'HOST'
                            )
                          }
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            .chuoicanho.com
                          </span>
                        </div>
                      </div>
                      {formik.touched.organizationCode &&
                        formik.errors.organizationCode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.organizationCode}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="fv-row mb-5">
                      <label className="form-label fs-6 fw-bolder text-dark">
                        Tên đăng nhập <span className="required">*</span>
                      </label>
                      <div className="input-icon">
                        <input
                          placeholder="Tên đăng nhập"
                          type="text"
                          className={`form-control form-control-md ${getInputClasses(
                            "username"
                          )}`}
                          name="username"
                          autoComplete="off"
                          {...formik.getFieldProps("username")}
                        />
                        <span>
                          <i className={`fas fa-address-card icon-md text-primay ${organization.textClass}`}></i>
                        </span>
                      </div>
                      {formik.touched.username && formik.errors.username ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.username}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="fv-row mb-5">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0 mr-3">
                          Mật khẩu <span className="required">*</span>
                        </label>
                        <span className={`${organization.code}`}>
                          <Link
                            to="/quen-mat-khau"
                            className={`link-primary fs-6 fw-bolder`}
                          >
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />?
                          </Link>
                        </span>
                      </div>

                      <div className="input-icon">
                        <input
                          placeholder="Nhập mật khẩu"
                          type={!showPass ? "password" : "text"}
                          className={`form-control form-control-md ${getInputClasses(
                            "password"
                          )}`}
                          name="password"
                          autoComplete="off"
                          {...formik.getFieldProps("password")}
                        />
                        <span>
                          <i className={`fas fa-key icon-md text-primay ${organization.textClass}`}></i>
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.password}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <input
                      type={"checkbox"}
                      checked={showPass}
                      className=""
                      onClick={(e) => setShowPass(!showPass)}
                    />
                    <label className="form-label fw-bolder text-dark fs-6 ml-2 mr-3">
                      Hiển thị mật khẩu{" "}
                    </label>
                    <div className="text-center">
                      <button
                        type="submit"
                        name="submit"
                        disabled={formik.isSubmitting || !formik.isValid}
                        className={`btn btn-lg btn-primary btn-login ${organization.btnClass} w-100`}
                      >
                        <span className="indicator-label">Đăng nhập</span>
                        {loading && (
                          <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2 ml-2"></span>
                          </span>
                        )}
                      </button>

                      {/* <div className="text-center text-muted text-uppercase fw-bolder mb-5">hoặc</div> */}
                      {/* <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                <img alt="Logo" src="/media/svg/brand-logos/google-icon.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Google</a>
              <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                <img alt="Logo" src="/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Facebook</a> */}
                    </div>
                    <Recaptcha
                      onResolved={onResolved}
                      ref={refRecaptcha}
                      sitekey="6Lcsg_4dAAAAAPo_yPTuYnTfihPC9GCyg9FKbPr3"
                    />
                  </form>
                  {organization.isNavToRegistration && (<div className="mt-5">
                    <div className="text-warning-400 fw-bold fs-4">
                      Bạn chưa có tài khoản?
                      <Link
                        to="/dang-ky"
                        className={`link-primary fw-bolder ml-2  ${organization.textClass}`}
                      >
                        Đăng ký ngay!
                      </Link>
                    </div>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrganizationSlide
          organizationCode={organization.code}
        />
      </div >
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
