import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialUnitSymbolsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  unitSymbolForEdit: null,
  unitSymbolForShow: null,
  currentUnitSymbol: null,
  currentUnitSymbols: [],

  filterCount: null,
  notification: null,
  error: null,
  isError: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const unitSymbolsSlice = createSlice({
  name: "unitSymbols",
  initialState: initialUnitSymbolsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentUnitSymbol = null;
      state.currentUnitSymbols = [];
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.unitSymbolForEdit = null;
    },

    resetModalShow: (state, action) => {
      state.errorType = null;
      state.isError = null;
      state.unitSymbolForShow = null;
    },

    resetCurrentUnitSymbols: (state, action) => {
      state.currentUnitSymbol = null;
      state.currentUnitSymbols = [];
    },

    cleanCurrentUnitSymbol: (state, action) => {
      state.currentUnitSymbol = null;
    },
    // getUnitSymbolById
    unitSymbolFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.unitSymbolForEdit = action.payload.unitSymbolForEdit;
      state.error = null;

    },
    //show
    getUnitSymbol: (state, action) => {
      state.actionsLoading = false;
      state.unitSymbolForShow = action.payload.unitSymbolForShow;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },
    // findAllUnitSymbols
    unitSymbolsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findUnitSymbols
    unitSymbolsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentUnitSymbols
    unitSymbolsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createUnitSymbol
    unitSymbolCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.unitSymbol);
      state.currentUnitSymbol = action.payload.unitSymbol;
      state.currentUnitSymbols.push(action.payload.unitSymbol);

    },
    // updateUnitSymbol
    unitSymbolUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.unitSymbol.id) {
          return action.payload.unitSymbol;
        }
        return entity;
      });
      state.currentUnitSymbol = action.payload.unitSymbol;
      state.currentUnitSymbols.push(action.payload.unitSymbol);
    },
    // deleteUnitSymbol
    unitSymbolDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;


      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentUnitSymbol = null;
      state.currentUnitSymbols = [];
    },
    // deleteUnitSymbols
    unitSymbolsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;


      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentUnitSymbol = null;
      state.currentUnitSymbols = [];

    },
    // unitSymbolsUpdateState
    unitSymbolsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;

      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
