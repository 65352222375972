import React from "react";
import { CurrenciesFilter } from "./CurrenciesFilter";
import { CurrenciesTable } from "./CurrenciesTable";
import { CurrenciesLoadingDialog } from "./CurrenciesLoadingDialog";
import { CurrenciesDeleteDialog } from "./CurrenciesDeleteDialog";
import { CurrencyDeleteDialog } from "./CurrencyDeleteDialog";
import { CurrenciesFetchDialog } from "./CurrenciesFetchDialog";

export function Currencies() {

  return (
    <>
      <CurrenciesLoadingDialog />
      <CurrencyDeleteDialog />
      <CurrenciesDeleteDialog />
      <CurrenciesFetchDialog />
      <div className="form margin-b-30">
        <CurrenciesFilter />

      </div>
      <CurrenciesTable />
    </>
  );
}
