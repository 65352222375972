import axios from "axios";

export const BANK_ACCOUNTS_URL = "/bankAccounts";

// CREATE =>  POST: add a new bankAccount to the server
export function createBankAccount(bankAccount) {
  return axios.post(BANK_ACCOUNTS_URL, bankAccount);
}



// READ
export function getAllBankAccounts() {
  return axios.get(BANK_ACCOUNTS_URL);
}

export function getInitBankAccount(buildingId) {
  return axios.get(`${BANK_ACCOUNTS_URL}/building/${buildingId}/create`);
}

export function getInitBankAccountByType(buildingId, bankAccountType) {
  return axios.get(`${BANK_ACCOUNTS_URL}/building/${buildingId}/bankAccountType/${bankAccountType}/create`);
}

export function findByBuildingId(buildingId) {
  return axios.get(`${BANK_ACCOUNTS_URL}/building/${buildingId}`);
}

export function findBankAccountsExcluded(queryParams, buildingId, ids) {
  return axios.post(`${BANK_ACCOUNTS_URL}/building/${buildingId}/excluded`, { queryParams, ids });
}

export function getBankAccountCloneById(bankAccountId) {
  return axios.get(`${BANK_ACCOUNTS_URL}/${bankAccountId}/clone`);
}

export function getBankAccountById(bankAccountId) {
  return axios.get(`${BANK_ACCOUNTS_URL}/${bankAccountId}`);
}
export function getBankAccountEditById(bankAccountId) {
  return axios.get(`${BANK_ACCOUNTS_URL}/${bankAccountId}/edit`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBankAccounts(queryParams, buildingId) {
  return axios.post(`${BANK_ACCOUNTS_URL}/building/${buildingId}/find`, queryParams);
}

export function findBankAccountsByOrganizationId(queryParams) {
  return axios.post(`${BANK_ACCOUNTS_URL}/organization`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateBankAccount(bankAccount) {
  return axios.put(`${BANK_ACCOUNTS_URL}/${bankAccount.id}`, bankAccount);
}

// UPDATE Status
export function updateStatusForBankAccounts(ids, status) {
  return axios.post(`${BANK_ACCOUNTS_URL}/updateStatusForBankAccounts`, {
    ids,
    status,
  });
}

// DELETE => delete the bankAccount from the server
export function deleteBankAccount(bankAccountId) {
  return axios.delete(`${BANK_ACCOUNTS_URL}/${bankAccountId}`);
}

// DELETE BankAccounts by ids
export function deleteBankAccounts(ids) {
  return axios.delete(`${BANK_ACCOUNTS_URL}/bulk`, { data: { ids } });

}

