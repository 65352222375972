import React from "react";
import { FormattedMessage } from "react-intl";
import * as requestFromServer from "./bankAccountsCrud";
import { bankAccountsSlice, callTypes } from "./bankAccountsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = bankAccountsSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetModalShow = () => (dispatch) => {
  dispatch(actions.resetModalShow());
};

export const resetCurrentBankAccounts = () => (dispatch) => {
  dispatch(actions.resetCurrentBankAccounts());
};

// Area actions fetch list

export const fetchAllBankAccounts = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllBankAccounts()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.bankAccountsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllBankAccounts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const fetchBanksByBuildingId = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingId(buildingId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;

      dispatch(actions.bankAccountsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBanksByBuildingId";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list

export const fetchBankAccounts = (queryParams, buildingId = 0) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findBankAccounts(queryParams, buildingId)
      // .getAllBankAccounts()
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        const filterCount = {
          total: response.data.total,
          totalBank: response.data.totalBank,
          totalCash: response.data.totalCash,
          totalOther: response.data.totalOther,
        }
        dispatch(actions.bankAccountsFetched({ totalCount, entities, filterCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "bankAccountsFetched";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

export const fetchBankAccountsByOrganizationId = (queryParams) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findBankAccountsByOrganizationId(queryParams)
      .then((response) => {
        //console.log(response);
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;

        dispatch(actions.bankAccountsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchBankAccountsByOrganizationId";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

export const fetchBankAccountsExcluded = (queryParams, buildingId, ids) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findBankAccountsExcluded(queryParams, buildingId, ids)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.bankAccountsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchBankAccountsExcluded";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

// Area actions get item

export const fetchBankAccount = (id, mode, buildingId) => (dispatch) => {
  const callType = callTypes.initialization
  if (!id) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitBankAccount(buildingId)
      .then((response) => {
        const bankAccount = response.data;
        dispatch(
          actions.bankAccountFetched({ bankAccountForEdit: bankAccount })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchBankAccount";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (id && mode && mode === "clone") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getBankAccountCloneById(id)
        .then((response) => {
          const bankAccount = response.data;
          dispatch(
            actions.bankAccountFetched({ bankAccountForEdit: bankAccount })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchBankAccount";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    } else
      if (mode && mode === "edit") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getBankAccountEditById(id)
          .then((response) => {
            const bankAccount = response.data;
            dispatch(
              actions.bankAccountFetched({ bankAccountForEdit: bankAccount })
            );
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchBankAccount";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      } else
        if (mode === "show") {
          dispatch(actions.startCall({ callType: callType }));
          return requestFromServer
            .getBankAccountById(id)
            .then((response) => {
              const bankAccount = response.data;
              dispatch(
                actions.bankAccountShowFetched({ bankAccountForShow: bankAccount })
              );
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else
                error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
              error.callType = callType;
              error.action = "fetchBankAccount";
              error.status = "warning"
              dispatch(actions.catchError({ error, callType: callType }));
              dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
            });
        };
};

export const getShowBankAccount = (id) => (dispatch) => {
  const callType = callTypes.initialization
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBankAccountById(id)
    .then((response) => {
      const bankAccount = response.data;
      dispatch(
        actions.bankAccountShowFetched({ bankAccountForShow: bankAccount })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBankAccount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions create, update item

export const createBankAccount = (bankAccountForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createBankAccount(bankAccountForCreation)
    .then((response) => {
      const bankAccount = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.bankAccountCreated({ bankAccount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createBankAccount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateBankAccount = (bankAccount) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateBankAccount(bankAccount)
    .then((response) => {
      const bankAccount = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.bankAccountUpdated({ bankAccount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateBankAccount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updateBankAccountsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForBankAccounts(ids, status)
    .then(() => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.bankAccountsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateBankAccountsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area actions delete item

export const deleteBankAccounts = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteBankAccounts(ids)
    .then(() => {
      dispatch(actions.bankAccountsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteBankAccounts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteBankAccount = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteBankAccount(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.bankAccountDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteBankAccount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchBankAccountByType = ({ buildingId, bankAccountType, callType = callTypes.initialization }) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getInitBankAccountByType(buildingId, bankAccountType)
    .then((response) => {
      const bankAccount = response.data;
      dispatch(
        actions.bankAccountFetched({ bankAccountForEdit: bankAccount })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBankAccountByType";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
