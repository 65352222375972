import axios from "axios";

export const NOTIFICATIONS_URL = "/notifications";

export function getAllNotifications() {
  return axios.get(NOTIFICATIONS_URL);
}

export function getNotificationById(notificationId) {
  return axios.get(`${NOTIFICATIONS_URL}/${notificationId}`);
}

export function getNotifications(queryParams) {
  return axios.post(`${NOTIFICATIONS_URL}/find`, queryParams);
}





