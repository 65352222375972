/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { checkFileFormat, formatBytes, toAbsoluteUrl } from "../../../_helpers";

export function InputFile({
  label,
  withFeedbackLabel = true,
  setIsShowAttachmentList,
  required = false,
  customFeedbackLabel,
  sampleFile = false,
  setFileSelected,
  actionDownloadTemplate,
  actionDownload,
  type = undefined,
  multiple = true,
  formProps,
  setIsShowPreview,
  updateFile,
  cancelAction = false,
  ...props
}) {
  const [showDeleteFile, setShowDeleteFile] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    formProps.setFieldValue("template", null);
    setFileSelected({ selectedFile: acceptedFiles });
  }, [acceptedFiles]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: type, noClick: showDeleteFile, onDrop, multiple: multiple })
  const remove = indexFile => {
    acceptedFiles.splice(indexFile, 1);
  };

  const files = acceptedFiles.length > 0 &&
    acceptedFiles.map((file, index) => (
      <>
        <div
          key={index}
          className="col-lg-3 pb-6"
          onMouseEnter={() => { setShowDeleteFile(true) }}
          onMouseLeave={() => { setShowDeleteFile(false) }}
        >
          <div
            className="image-input image-input-drop"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                // URL.createObjectURL(file)
              )}`,
            }}
          >
            <img
              className="image-input-drop"
              src={checkFileFormat(file)}
              alt=""
              key={file.path}
            />
            <div>
              <span>{file.name}</span>&nbsp;{<i className="far fa-window-close icon-1x" onClick={() => remove(index)}></i>}
            </div>

          </div>
        </div>
        {acceptedFiles.length === index + 1 &&
          <div className="col-lg-12 icon-1x"><i className="flaticon-upload-1 icon-2x text-dark"></i>&ensp;Thả tệp để đính kèm hoặc trong&nbsp;
            <span
              type="button"
              className="text-primary text-reset"
              onMouseEnter={() => setShowDeleteFile(true)}
              onMouseLeave={() => setShowDeleteFile(false)}
              onClick={() => setIsShowAttachmentList(true)}
            >
              thư mục
            </span>
          </div>
        }
      </>
    )

    );

  const file = (formProps.values && formProps.values?.template) ?
    <>
      <div className="col-lg-12">
        <div className="form-group row">
          <div className="col-lg-4">
            <div
              className="image-input image-input-drop"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  // URL.createObjectURL(file)
                )}`,
              }}
            >
              <img
                className="image-input-drop"
                src="/media/users/file_docx.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="text-left">
              <ul>
                <li><b>Tên tệp: </b><span className="text-wrap word-break">{formProps.values?.template?.documentOriginalName}</span></li>
                <li><b>Kích thước: </b>{formatBytes(formProps.values?.template?.documentSize)}</li>
              </ul></div>
            <div
              className="d-flex justify-content-around"
            >
              <button
                type="button"
                onClick={() => { formProps.setFieldValue("template", null); setShowDeleteFile(false) }}
                className="btn btn-outline-danger"
                onMouseEnter={() => setShowDeleteFile(true)}
                onMouseLeave={() => setShowDeleteFile(false)}
              >
                Xóa tệp
              </button>
              <button
                type="button"
                onClick={actionDownload}
                className="btn btn-outline-success"
                onMouseEnter={() => setShowDeleteFile(true)}
                onMouseLeave={() => setShowDeleteFile(false)}
              >
                Tải xuống
              </button>
              <a
                target="_blank"
                href={`${['doc', 'docx', 'xls', 'xlsx'].includes(formProps.values?.template?.documentExtension) ? "https://docs.google.com/viewerng/viewer?url=" : ""}${Axios.defaults.cdnURL}/${formProps.values?.template?.documentPath}`}
              >
                <button
                  type="button"
                  onClick={() => setIsShowPreview(true)}
                  className="btn btn-outline-primary"
                  onMouseEnter={() => setShowDeleteFile(true)}
                  onMouseLeave={() => setShowDeleteFile(false)}
                >
                  Xem trước
                </button>
              </a>
            </div>
          </div>
        </div>
      </div >
    </>
    :
    acceptedFiles.length > 0 ? acceptedFiles.map((file, index) => (
      <div className="col-lg-12" key={index}>
        <div className="form-group row">
          <div className="col-lg-4">
            <div
              className="image-input image-input-drop"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  // URL.createObjectURL(file)
                )}`,
              }}
            >
              <img
                className="image-input-drop"
                src="/media/users/file_docx.png"
                alt=""
                key={file.path}
              />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="text-left">
              <ul>
                <li><b>Tên tệp: </b><span className="text-wrap word-break">{file.name}</span></li>
                <li><b>Kích thước: </b>{formatBytes(file.size)}</li>
              </ul>
            </div>
            <div>
              <button
                type="button"
                onClick={() => remove(index)}
                className="btn btn-outline-danger"
                onMouseEnter={() => setShowDeleteFile(true)}
                onMouseLeave={() => setShowDeleteFile(false)}
              >
                Xóa tệp
              </button>
            </div>
          </div>
        </div>
      </div >
    )) :
      <>
        <div className="col-lg-12 icon-1x"><i className="flaticon-upload-1 icon-2x text-dark"></i>&ensp;Thả tệp để đính kèm hoặc trong&nbsp;
          <span
            type="button"
            className="text-primary text-reset"
            onMouseEnter={() => setShowDeleteFile(true)}
            onMouseLeave={() => setShowDeleteFile(false)}
            onClick={() => setIsShowAttachmentList(true)}
          >
            thư mục
          </span>
        </div>
        <div
          className="image-input image-input-drop"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              // URL.createObjectURL(file)
            )}`,
          }}>
        </div>
      </>
    ;

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="form-group row  d-flex justify-content-center">
          <div className="col-lg-12 col-md-9 col-sm-12">
            <div className="dropzone dropzone-default dropzone-primary pt-1 pb-1">
              <div className="dropzone-msg dz-message needsclick form-group row d-flex justify-content-center">
                <div className="col-lg-12 d-flex justify-content-end">
                  {sampleFile ?
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="contracts-delete-tooltip">
                          Tải tệp mẫu
                        </Tooltip>
                      }
                    >
                      <span
                        onMouseEnter={() => setShowDeleteFile(true)}
                        onMouseLeave={() => setShowDeleteFile(false)}
                        className="btn btn-icon btn-sm"
                        type="button"                 >
                        <i className="flaticon-download text-primary" onClick={actionDownloadTemplate}></i>
                      </span>
                    </OverlayTrigger>
                    :
                    <span className="pt-2"></span>
                  }
                </div>
                {(acceptedFiles.length > 0 || (formProps.values && formProps.values.template)) ? (multiple ? files : file) :
                  <>
                    <div className="col-lg-12 icon-1x"><i className="flaticon-upload-1 icon-2x text-dark"></i>&ensp;Thả tệp để đính kèm hoặc trong&nbsp;
                      <span
                        type="button"
                        className="text-primary text-reset"
                        onMouseEnter={() => setShowDeleteFile(true)}
                        onMouseLeave={() => setShowDeleteFile(false)}
                        onClick={() => setIsShowAttachmentList(true)}
                      >
                        thư mục
                      </span>
                    </div>
                    <div
                      className="image-input image-input-drop"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          // URL.createObjectURL(file)
                        )}`,
                      }}>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {cancelAction && acceptedFiles?.length > 0 &&
        <div className="text-right mt-1">
          <button
            className={`btn btn-light-success btn-elevate btn-sm font-size-xs font-size-icon-md mr-1 btn`}
            onClick={updateFile}
          >
            <i className="flaticon2-check-mark icon-md  mr-2"></i>Lưu lại
          </button>
          <button
            className={`btn btn-light-danger btn-elevate btn-sm font-size-xs`}
            onClick={() => acceptedFiles.splice(0, acceptedFiles.length)}>
            <i className="flaticon2-delete icon-sm  mr-2"></i>Hủy bỏ
          </button>
        </div>
      }
    </>
  );
}
