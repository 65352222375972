import moment from "moment";

export const TimeDateFormat = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("HH:mm:ss DD-MM-YYYY") : "";
};

export const DateFormat = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("DD-MM-YYYY") : "";
};

export const DateFormatByPattern = (value, pattern) => {
  return value ? moment(value).format(pattern) : "";
};

export const DateTimeFormat = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("DD-MM-YYYY HH:mm:ss") : "";
};

export const GetDays = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const handleDatedBlur = (e, props, activeMonth, activeYear) => {
  const fieldName = e.target.name;
  const value = Number(e.target.value);
  if (fieldName === "month") {
    if (Number(value) < 1 || Number(value) > 12) {
      props.setFieldValue("month", activeMonth);
    } else {
    }
  } else if (fieldName === "year") {
    if (value < 2010 || value > 2048) {
      props.setFieldValue("year", activeYear);
    } else {
    }
  } else {
  }
};
export const handleDateChange = ({ e, fieldName, props, values, type }) => {
  if (fieldName === "month" && type === "change") {
    const value = Number(e.target.value);
    if (value >= 1 && value <= 12) {
      props.handleSubmit();
    } else {
    }
  } else if (fieldName === "month" && (type === "up" || type === "down")) {
    const month = Number(values.month);
    const year = Number(values.year);
    //console.log(month);
    //console.log(type);
    type === "up" &&
      (month === 12
        ? props.setFieldValue("month", 1) &&
          props.setFieldValue("year", year + 1)
        : props.setFieldValue("month", month + 1));
    type === "down" &&
      (month === 1
        ? props.setFieldValue("month", 12) &&
          props.setFieldValue("year", year - 1)
        : props.setFieldValue("month", month - 1));
    props.handleSubmit();
  } else if (fieldName === "year" && type === "change") {
    const value = Number(e.target.value);
    if (value >= 2010 && value <= 2048) {
      props.handleSubmit();
    } else {
    }
  } else if (fieldName === "year" && (type === "up" || type === "down")) {
    const value = Number(values.year);
    type === "up" &&
      (value === 2048
        ? props.setFieldValue("year", 2010)
        : props.setFieldValue("year", value + 1)) &&
      props.setFieldValue("month", 1);
    type === "down" &&
      (value === 2010
        ? props.setFieldValue("year", 2048)
        : props.setFieldValue("year", value - 1)) &&
      props.setFieldValue("month", 12);
    props.handleSubmit();
  }
};

export const DATE_TIME_TIMESTAMP = {
  MONTH: 86400000 * 30,
  DAY: 86400000,
};
