/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import * as actionsComments from "../../../_redux/supportComments/supportCommentsActions";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { uploadAttachmentData } from "../../../../../../_bloomgoo/_helpers";
import { CommentTypeEnum } from "../../../../../_helpers/_CommonUIHelpers";
import { InputFile, SwitchField, TextareaField } from "../../../../../../_bloomgoo/_partials/controls";
import { useDropzone } from "react-dropzone";
import { AttachmentsDialog } from "../../../../Documents/pages/documents/attachment-dialog/AttachmentsDialog";
import { AttachmentTable } from "../../../../Documents/pages/documents/attachment-table/AttachmentTable";
import { DocumentPreview } from "../../../../Documents/pages/documents/document-preview/DocumentPreview";
export function ConversationBox(props) {
  const { comment, support, handleFieldChange, supportKey, mode } = props;
  const [fileSelected, setFileSelected] = useState({ selectedFile: null })

  const queryParams = {
    filter: {
      searchCriteria: [
        {
          key: "commentType",
          operation: ":",
          value: supportKey === "historyOwner" ? CommentTypeEnum.INTERNAL : CommentTypeEnum.EXTERNAL,
          prefix: "",
          suffix: "",
          isDefault: true,
          level: 1
        },
      ]
    },
    sortOrder: "desc", // asc||desc
    sortField: "updatedAt",
    pageNumber: 1,
    pageSize: 99999,
  };


  const onDrop = useCallback(acceptedFiles => {
    setFileSelected({ selectedFile: acceptedFiles });
    // eslint-disable-next-line no-use-before-define
  }, [acceptedFiles])

  const { acceptedFiles } = useDropzone({ noClick: false, onDrop })

  const resetModal = (rest) => {
    rest.setFieldValue("comment", "")
    rest.setFieldValue("attachments", [])
    acceptedFiles.splice(0, acceptedFiles.length);
  }
  const dispatch = useDispatch();
  const handleSubmitComment = (rest) => {

    if (mode === "show") {
      const _rest = {
        attachments: rest.values.attachments,
        comment: rest.values.comment,
        id: rest.values.id,
        supportId: rest.values.supportId,
        userId: rest.values.userId,
        commentType: CommentTypeEnum.EXTERNAL,
      }
      dispatch(actionsComments.createComment(support.id, uploadAttachmentData(fileSelected.selectedFile, _rest))).then(() => dispatch(actionsComments.fetchComments(support.id, queryParams))).then(() => dispatch(actionsComments.resetModal())).then(() => dispatch(actionsComments.fetchInitComments(support.id)));
    }
    else
      dispatch(actionsComments.createComment(support.id, uploadAttachmentData(fileSelected.selectedFile, rest.values))).then(() => dispatch(actionsComments.fetchComments(support.id, queryParams))).then(() => dispatch(actionsComments.resetModal())).then(() => dispatch(actionsComments.fetchInitComments(support.id)));
    resetModal(rest)
  };

  let documentsArrayHelpers;

  const [isShowAttachmentList, setIsShowAttachmentList] = useState(false);
  function applyDocumentsSelectedRows(selectedRows) {
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.map((row) => {
        const documentObj = {
          document: {
            id: row.id ? Number(row.id) : null,
            documentPath: row.documentPath ? row.documentPath : null,
            documentCode: row.documentCode ? row.documentCode : null,
            documentExtension: row.documentExtension ? row.documentExtension : null,
            documentFormat: row.documentFormat ? row.documentFormat : null,
            documentName: row.documentName ? row.documentName : null,
            documentOriginalName: row.documentOriginalName ? row.documentOriginalName : null,
            documentSize: row.documentSize ? Number(row.documentSize) : null,
            createdAt: row.createdAt ? row.createdAt : null,
            createdBy: row.createdBy ? Number(row.createdBy) : null,
            createdUser: row.createdUser ? row.createdUser : null,
          },
          documentId: row.id ? Number(row.id) : null,
          functionName: "CUSTOMERS",
        };
        documentsArrayHelpers.push(documentObj)

      });
    }
    setIsShowAttachmentList(false);
  };

  const [isShowPreviewFile, setIsShowPreviewFile] = useState(false)
  const [currentAttachment, setCurrentAttachment] = useState();

  return (
    <>
      {isShowPreviewFile &&
        <DocumentPreview
          mode="previewAttachmentFromSupportComment"
          show={isShowPreviewFile}
          onHide={() => setIsShowPreviewFile(false)}
          file={currentAttachment}
        >
        </DocumentPreview>
      }
      {isShowAttachmentList && <AttachmentsDialog
        show={isShowAttachmentList}
        onHide={() => { setIsShowAttachmentList(false) }}
        isMulti={true}
        applySelectedRows={applyDocumentsSelectedRows}
      />}

      <Formik
        enableReinitialize={true}
        initialValues={comment}
        onSubmit={(values, { resetForm }) => {

        }}
      >
        {({ handleReset, handleSubmit, handleChange, ...rest }) => {
          console.log("rest: ", rest)
          return (
            <>
              <Form className="form form-label-right">
                {mode !== "show" && <div className="form-group row">
                  <div className="col-lg-8"></div>
                  <div className="col-lg-4 row">
                    <span className="mt-2 mr-2 ml-16">Nội bộ</span><Field
                      name="commentType"
                      component={SwitchField}
                      type={"checkbox"}
                      checked={rest.values.commentType !== 1}
                      onChange={e => { handleChange(e); handleFieldChange(e, rest) }}
                    /><span className="mt-2 ml-2">Khách hàng</span>
                  </div>
                </div>}
                <div className="form-group row">
                  <div className="col-lg-12 mt-3">
                    <Field
                      name="comment"
                      component={TextareaField}
                      placeholder={"Nội dung trao đổi..."}
                      type={"textarea"}
                      handleFieldChange={handleFieldChange}
                      formProps={rest}
                    />
                  </div>
                </div>
                <InputFile
                  setFileSelected={setFileSelected}
                  setIsShowAttachmentList={setIsShowAttachmentList}
                  formProps={rest}
                />

                {rest.values && rest.values.attachments && rest.values.attachments.length > 0 && (<div className="form-group">
                  <FieldArray name="attachments">
                    {arrayHelpers => {
                      return (
                        <>
                          <AttachmentTable
                            arrayHelpers={arrayHelpers}
                            formProps={rest}
                            setIsShowPreviewFile={setIsShowPreviewFile}
                            setCurrentAttachment={setCurrentAttachment}
                          />
                        </>
                      );
                    }}
                  </FieldArray>
                </div>)}
                <div className="form-group text-right pt-2">
                  {rest.values && rest.values.id ?
                    <>
                      <button
                        disabled={rest.values && !rest.values.comment}
                        onClick={() => resetModal(rest)}
                        className="btn btn-primary btn-elevate btn-sm"
                      >
                        <i className="flaticon2-cancel icon-ms"></i>
                        Hủy
                      </button>
                      <> </>
                      <button
                        disabled={rest.values && !rest.values.comment}
                        type="submit"
                        onClick={() => handleSubmitComment(rest)}
                        className="btn btn-primary btn-elevate btn-sm"
                      >
                        <i className="flaticon2-pen icon-ms"></i>
                        Cập nhật
                      </button>
                    </>
                    :
                    <button
                      disabled={rest.values && (!rest.values.comment && acceptedFiles.length === 0)}
                      type="submit"
                      onClick={() => handleSubmitComment(rest)}
                      className="btn btn-primary btn-elevate btn-sm"
                    >
                      <i className="flaticon2-send-1 icon-ms"></i>
                      Trao đổi
                    </button>}
                </div>
              </Form>
            </>
          )
        }
        }
      </Formik>
    </>
  );
}

