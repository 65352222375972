import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import Particle from "react-particles-js";
import particlesConfig from "../../../../_bloomgoo/layout/components/particle/particlesConfig.json";
import { parseDomain } from "parse-domain";
import particlesAplusConfig from "../../../../_bloomgoo/layout/components/particle/aplus/particlesConfig.json";
import particlesBizcitiConfig from "../../../../_bloomgoo/layout/components/particle/bizciti/particlesConfig.json";
import { OrganizationSlide } from "./OrganizationSlide";
import Axios from "axios";

const parseResult = parseDomain(window.location.host.split(":")[0]);

const { subDomains } = parseResult;

const code = (subDomains === undefined || subDomains === null || subDomains.length === 0) ? 'chuoicanho' : subDomains[subDomains.length - 1];

const VIP_ORGANIZATIONS = [
  { code: 'aplus', title: "Chào mừng bạn đến với APLUS,", subTitle: "APLUS xây dựng không gian sống chất lượng với căn hộ dịch vụ tiêu chuẩn, tiện nghi, hiện đại.", isNavToRegistration: false, textClass: "text-aplus", textHoverClass: "text-hover-aplus", btnClass: "btn-aplus", btnHoverClass: "btn-hover-aplus", particles: particlesAplusConfig },
  { code: 'bizciti', title: "Chào mừng bạn đến với BizCiti,", subTitle: "BizCiti, Đơn vị vận hành quản lý cho thuê căn hộ và văn phòng hàng đầu tại Đà Nẵng.", isNavToRegistration: false, textClass: "text-bizciti", textHoverClass: "text-hover-bizciti", btnClass: "btn-bizciti", btnHoverClass: "btn-hover-bizciti", particles: particlesBizcitiConfig },
  { code: 'tingtong', title: "Chào mừng bạn đến với TingTong,", subTitle: "Tại TingTong chúng tôi khát khao trong việc tiên phong đi đầu và tạo ra các tiêu chuẩn dành riêng cho cộng đồng thuê trọ.", isNavToRegistration: false, textClass: "text-nghiepvu", textHoverClass: "text-hover-nghiepvu", btnClass: "btn-nghiepvu", btnHoverClass: "btn-hover-nghiepvu", particles: particlesBizcitiConfig }
];
const VIP_ORGANIZATION = VIP_ORGANIZATIONS.filter(item => item.code === code);
const organization = VIP_ORGANIZATION.length === 0 ? { code: 'chuoicanho', title: "Chào mừng bạn trở lại,", subTitle: "ChuoiCanHo, không chỉ đơn giản là giải pháp quản lý mà còn là một Hệ Sinh Thái Bất Động Sản toàn diện.", isNavToRegistration: true, textClass: "text-primary", btnClass: "btn-primary", particles: particlesConfig } : VIP_ORGANIZATION[0];
function RegisterPassword(props) {
  const { intl } = props;
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Axios.get(`/auth/resetPassword`, { params: { token: token } })
      .then(function (response) {
        setInitialValues(response.data);
      })
      .catch(function (error) {
        // history.push("/error/error-v1");
      });
  }, [token]);


  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .nullable()
      .trim()
      .matches(
        /(?=.*?[A-Z]).{6,}/,
        intl.formatMessage({
          id: "AUTH.VALIDATION.PASS_FIELD",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_PASS_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .nullable()
      .trim()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION_PASSWORD_FIELD",
        })
      )
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          intl.formatMessage({
            id: "AUTH.VALIDATION_CONFIRMPASSWORD_FIELD",
          })
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const [notification, setNotification] = useState();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setNotification();
      setLoading(true)
      resetPassword(values)
        .then((response) => {
          setIsRequested(true);
          setLoading(false);
          setSubmitting(false)
          setNotification({
            status: 'success',
            message: 'Cập nhật mật khẩu thành công'
          })
        })
        .catch((error) => {
          setNotification({
            status: 'error',
            message: intl.formatMessage({
              id: error?.response?.data?.code ? error?.response?.data?.code : "ERRORS.COMMONS.GLOBAL",
            }),
          })
          setIsRequested(false);
          setLoading(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.newPassword }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested ?
        <Redirect to="/dang-nhap" />
        :
        <>
          <div className="d-flex flex-column flex-lg-row flex-column-fluid w-100">
            <div className="order-2 order-lg-1 d-flex flex-column w-50 position-relative">
              <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-8 px-lg-35">
                <div className="d-flex flex-column-fluid flex-column flex-center">
                  <Particle
                    params={organization.particles}
                    className="particles-container"
                  />
                  <div
                    className="login-form login-signin bg-white"
                    style={{ zIndex: 999999 }}
                  >
                    <div className="w-lg-450px bg-body rounded shadow-sm p-10 p-lg-10 mx-auto">
                      <span
                        type="button"
                        className="pb-5"
                        style={{ zIndex: 999999 }}
                      >
                        <img
                          src={`/media/login/${organization.code}/logo.png`}
                          width="220"
                          height="66"
                          alt=""
                        />
                      </span>
                      <form className="form">
                        <div className="mb-5">
                          <h1 className="text-dark mb-3" style={{ fontSize: "1.8rem" }}>Đăng ký mật khẩu mới</h1>
                          <div className="text-warning-500 fw-bold fs-4">
                            {organization.isNavToRegistration && (
                              <div className="mt-5">
                                <div className="text-warning-400 fw-bold fs-4">

                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {notification?.message && (
                          <div className={`mb-10 alert alert-custom alert-light-${notification?.status === "success" ? "primary" : "danger"} alert-dismissible`}>
                            <div className="alert-text font-weight-bold">
                              {notification?.message}
                            </div>
                          </div>
                        )}

                        <div className="fv-row mb-5">
                          <label className="form-label fs-6 fw-bolder text-dark">
                            Mật khẩu mới <span className="required">*</span>
                          </label>
                          <div className="input-icon">
                            <input
                              placeholder="Nhập mật khẩu mới"
                              type="password"
                              className={`form-control form-control-md ${getInputClasses(
                                "newPassword"
                              )}`}
                              name="newPassword"
                              autoComplete="off"
                              {...formik.getFieldProps("newPassword")}
                            />
                            <span>
                              <i className={`fas fa-key icon-md text-primay ${organization.textClass}`}></i>
                            </span>
                          </div>
                          {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.newPassword}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="fv-row mb-5">
                          <div className="d-flex flex-stack mb-2">
                            <label className="form-label fw-bolder text-dark fs-6 mb-0 mr-3">
                              Xác nhận mật khẩu <span className="required">*</span>
                            </label>
                          </div>

                          <div className="input-icon">
                            <input
                              placeholder="Nhập mật khẩu xác nhận"
                              type={"password"}
                              className={`form-control form-control-md ${getInputClasses(
                                "confirmPassword"
                              )}`}
                              name="confirmPassword"
                              autoComplete="off"
                              {...formik.getFieldProps("confirmPassword")}
                            />
                            <span>
                              <i className={`fas flaticon-lock icon-md text-primay ${organization.textClass}`}></i>
                            </span>
                          </div>
                          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.confirmPassword}
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div className="text-center">
                          <button
                            type="button"
                            onClick={() => formik.handleSubmit()}
                            disabled={formik.isSubmitting || !formik.values?.newPassword || !formik.values?.confirmPassword}
                            className={`btn btn-lg btn-primary ${organization.btnClass} w-100 mb-5`}
                          >
                            <span className="indicator-label">Xác nhận</span>
                            {loading && (
                              <span className="indicator-progress">
                                <span className="spinner-border spinner-border-sm align-middle ms-2 ml-2"></span>
                              </span>
                            )}
                          </button>
                          <span className={`${organization.code}`}><Link to="/auth" >Huỷ bỏ</Link></span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <OrganizationSlide
              organizationCode={organization.code}
            />
          </div >
        </>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(RegisterPassword));
