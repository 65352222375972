/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
export function changeAccount(handlingAccount, rowIndex, Obj, mode) {
  //console.log("handlingAccount: ", handlingAccount)
  //console.log("rowIndex: ", rowIndex)
  //console.log("Obj: ", Obj)
  //console.log("Obj: ", Obj)
  if (mode === "add") {
    let bankAccountPast = 0;
    handlingAccount && handlingAccount.map((item, index) => {
      if (index == 0)
        bankAccountPast = item.account.id;
      if (index == rowIndex - 1) {
        const ObjNew = {
          id: item.account.id ? item.account.id : 0,
          receiptPaymentVoucherId: "",
          bankAccountId: item.account.bankAccountId ? item.account.bankAccountId : "",
          bankAccount: {
            id: item.account.bankAccount.id ? item.account.bankAccount.id : "",
            buildingId: item.account.bankAccount.buildingId ? item.account.bankAccount.buildingId : 0,
            bankAccountType: item.account.bankAccount.bankAccountType ? item.account.bankAccount.bankAccountType : 0,
            code: item.account.bankAccount.code ? item.account.bankAccount.code : "",
            accountName: item.account.bankAccount.accountName ? item.account.bankAccount.accountName : "",
            accountNumber: item.account.bankAccount.accountNumber ? item.account.bankAccount.accountNumber : "",
            bank: item.account.bankAccount.bank ? item.account.bankAccount.bank : 0,
            bankId: item.account.bankAccount.bankId ? item.account.bankAccount.bankId : 0,
            branch: item.account.bankAccount.branch ? item.account.bankAccount.branch : "",
            walletId: item.account.bankAccount.walletId ? item.account.bankAccount.walletId : 0,
          },
          currencyId: Obj.currency.id ? Obj.currency.id : 0,
          currency: {
            id: Obj.currency.id ? Obj.currency.id : 0,
            name: Obj.currency.name ? Obj.currency.name : "",
            code: Obj.currency.code ? Obj.currency.code : ""
          },
          amountMoney: 0,
          exchangeRate: Obj.currency ? Obj.currency.exchangeRate ? Obj.currency.exchangeRate : 1 : 1,
          totalMoney: 0,
          description: ""
        };

        handlingAccount.splice(rowIndex, 0, { account: ObjNew, rowSpanCurrency: handlingAccount[index].rowSpanCurrency + 1, totalRow: handlingAccount[index].totalRow + 1, totalButtonNew: handlingAccount[index].totalButtonNew });

      }

      handlingAccount && handlingAccount.map((itemIngredient, indexIngredient) => {
        if (itemIngredient.account.bankAccountId == handlingAccount[rowIndex - 1].account.bankAccountId) {
          itemIngredient.totalRow = handlingAccount[rowIndex].totalRow;

          if (itemIngredient.account.currency.code == "")
            itemIngredient.rowSpanCurrency = handlingAccount[rowIndex - 1].totalRow;

        }

        bankAccountPast = item.account.bankAccountId;
      })
    })
  }

  if (mode === "delete") {
    let totalRow = handlingAccount[rowIndex].totalRow - 1;
    handlingAccount.splice(rowIndex, 1);
    handlingAccount && handlingAccount.map((item, index) => {
      if (item.account.bankAccountId == handlingAccount[rowIndex].account.bankAccountId) {
        if (index >= rowIndex)
          item.rowSpanCurrency = item.rowSpanCurrency - 1;
        item.totalRow = totalRow;
      }
    })
    let totalId = 0;
    handlingAccount && handlingAccount.map((item) => {
      if (item.account.id == handlingAccount[rowIndex].account.id) {
        totalId++;
      }

    })
    if (totalId == 1) {
      handlingAccount.splice(rowIndex, 1);
      handlingAccount && handlingAccount.map((item, index) => {
        if (index >= rowIndex)
          item.totalButtonNew--;
      })
    }
  }

  if (mode === "new") {
    const ObjNew = {
      id: Obj.id ? Obj.id : 0,
      receiptPaymentVoucherId: "",
      bankAccountId: Obj.bankAccountId ? Obj.bankAccountId : "",
      bankAccount: {
        id: Obj.bankAccount.id ? Obj.bankAccount.id : 0,
        buildingId: Obj.bankAccount.buildingId ? Obj.bankAccount.buildingId : 0,
        bankAccountType: Obj.bankAccount.bankAccountType ? Obj.bankAccount.bankAccountType : 0,
        code: Obj.bankAccount.code ? Obj.bankAccount.code : "",
        accountName: Obj.bankAccount.accountName ? Obj.bankAccount.accountName : "",
        accountNumber: Obj.bankAccount.accountNumber ? Obj.bankAccount.accountNumber : "",
        bankId: Obj.bankAccount.bankId ? Obj.bankAccount.bankId : 0,
        bank: Obj.bankAccount.bank ? Obj.bankAccount.bank : 0,
        branch: Obj.bankAccount.branch ? Obj.bankAccount.branch : "",
        walletId: Obj.bankAccount.walletId ? Obj.bankAccount.walletId : 0,
      },
      currencyId: 0,
      currency: {
        id: "",
        name: "",
        code: ""
      },
      amountMoney: 0,
      exchangeRate: 1,
      totalMoney: 0,
      description: ""
    }
    let totalButtonNew = 0;
    if (handlingAccount.length > 0) {
      totalButtonNew = handlingAccount[handlingAccount.length - 1].totalButtonNew + 1;

    }
    handlingAccount.push({ account: Obj, rowSpanCurrency: 1, totalRow: 1, totalButtonNew: totalButtonNew }, { account: ObjNew, rowSpanCurrency: 1, totalRow: 1, totalButtonNew: totalButtonNew })
    totalButtonNew++;
  }
  //console.log("handlingAccount: ", handlingAccount)
}

export function buttonAddNew(account, handlingAccount) {
  let rowSpanCurrency = 0;
  let totalRow = 0;
  let accountId = 0;
  let totalButtonNew = 0;

  handlingAccount = [];
  account && account.map((item, index) => {
    const bankAccounts = {
      amountMoney: item.amountMoney ? item.amountMoney : 0,
      bankAccount: {
        accountName: item.bankAccount ? item.bankAccount.accountName ? item.bankAccount.accountName : "" : "",
        accountNumber: item.bankAccount ? item.bankAccount.accountNumber ? item.bankAccount.accountNumber : "" : "",
        bankAccountType: item.bankAccount ? item.bankAccount.bankAccountType ? item.bankAccount.bankAccountType : "" : "",
        bank: item.bankAccount ? item.bankAccount.bank ? item.bankAccount.bank : "" : "",
        bankId: item.bankAccount ? item.bankAccount.bankId ? item.bankAccount.bankId : "" : "",
        branch: item.bankAccount ? item.bankAccount.branch ? item.bankAccount.branch : "" : "",
        buildingId: item.bankAccount ? item.bankAccount.buildingId ? item.bankAccount.buildingId : "" : "",
        code: item.bankAccount ? item.bankAccount.code ? item.bankAccount.code : "" : "",
        id: item.bankAccount ? item.bankAccount.id ? item.bankAccount.id : "" : "",
        walletId: item.bankAccount ? item.bankAccount.walletId ? item.bankAccount.walletId : 0 : 0,
      },
      bankAccountId: item.bankAccountId ? item.bankAccountId : "",
      currency: {
        code: item.currency ? item.currency.code ? item.currency.code : "" : "",
        id: item.currencyId ? item.currencyId : 0,
        name: item.currency ? item.currency.name ? item.currency.name : "" : "",
      },
      currencyId: item.currency ? item.currency.id ? item.currency.id : 0 : 0,
      description: "",
      exchangeRate: item.exchangeRate ? item.exchangeRate : 1,
      id: item.id ? item.id : "",
      receiptPaymentVoucherId: item.receiptPaymentVoucherId ? item.receiptPaymentVoucherId : "",
      totalMoney: item.totalMoney ? item.totalMoney : 0,
    }
    if (index == 0) {
      accountId = item.bankAccountId;
    }

    if (accountId != item.bankAccountId) {
      const ButtonNew = {
        amountMoney: item.amountMoney ? item.amountMoney : 0,
        bankAccount: {
          accountName: item.bankAccount ? item.bankAccount.accountName ? item.bankAccount.accountName : "" : "",
          accountNumber: item.bankAccount ? item.bankAccount.accountNumber ? item.bankAccount.accountNumber : "" : "",
          bankAccountType: item.bankAccount ? item.bankAccount.bankAccountType ? item.bankAccount.bankAccountType : "" : "",
          bank: item.bankAccount ? item.bankAccount.bank ? item.bankAccount.bank : "" : "",
          bankId: item.bankAccount ? item.bankAccount.bankId ? item.bankAccount.bankId : "" : "",
          branch: item.bankAccount ? item.bankAccount.branch ? item.bankAccount.branch : "" : "",
          buildingId: item.bankAccount ? item.bankAccount.buildingId ? item.bankAccount.buildingId : "" : "",
          code: item.bankAccount ? item.bankAccount.code ? item.bankAccount.code : "" : "",
          id: item.bankAccount ? item.bankAccount.id ? item.bankAccount.id : "" : "",
          walletId: item.bankAccount ? item.bankAccount.walletId ? item.bankAccount.walletId : 0 : 0,
        },
        bankAccountId: account[index - 1] ? account[index - 1].bankAccountId ? account[index - 1].bankAccountId : "" : "",
        currency: {
          code: "",
          id: 0,
          name: "",
        },
        currencyId: 0,
        description: "",
        exchangeRate: 1,
        id: item.id ? item.id : "",
        receiptPaymentVoucherId: item.receiptPaymentVoucherId ? item.receiptPaymentVoucherId : "",
        totalMoney: item.totalMoney ? item.totalMoney : 0,
      }
      handlingAccount.push({ account: ButtonNew, rowSpanCurrency: rowSpanCurrency, totalRow: totalRow, totalButtonNew: totalButtonNew });
      accountId = item.bankAccountId;
      totalButtonNew++;
      rowSpanCurrency = 1;
    }
    else
      rowSpanCurrency++;

    if (account.length - 1 == index) {
      const ButtonNew = {
        amountMoney: 0,
        bankAccount: {
          accountName: item.bankAccount ? item.bankAccount.accountName ? item.bankAccount.accountName : "" : "",
          accountNumber: item.bankAccount ? item.bankAccount.accountNumber ? item.bankAccount.accountNumber : "" : "",
          bankAccountType: item.bankAccount ? item.bankAccount.bankAccountType ? item.bankAccount.bankAccountType : "" : "",
          bank: item.bankAccount ? item.bankAccount.bank ? item.bankAccount.bank : "" : "",
          bankId: item.bankAccount ? item.bankAccount.bankId ? item.bankAccount.bankId : "" : "",
          branch: item.bankAccount ? item.bankAccount.branch ? item.bankAccount.branch : "" : "",
          buildingId: item.bankAccount ? item.bankAccount.buildingId ? item.bankAccount.buildingId : "" : "",
          code: item.bankAccount ? item.bankAccount.code ? item.bankAccount.code : "" : "",
          id: item.bankAccount ? item.bankAccount.id ? item.bankAccount.id : "" : "",
          walletId: item.bankAccount ? item.bankAccount.walletId ? item.bankAccount.walletId : 0 : 0,
        },
        bankAccountId: item.bankAccountId ? item.bankAccountId : "",
        currency: {
          code: "",
          id: 0,
          name: "",
        },
        currencyId: 0,
        description: "",
        exchangeRate: 1,
        id: item.id ? item.id : "",
        receiptPaymentVoucherId: item.receiptPaymentVoucherId ? item.receiptPaymentVoucherId : "",
        totalMoney: 0,
      }
      handlingAccount.push({ account: bankAccounts, rowSpanCurrency: rowSpanCurrency, totalRow: totalRow, totalButtonNew: totalButtonNew });

      handlingAccount.push({ account: ButtonNew, rowSpanCurrency: rowSpanCurrency, totalRow: totalRow, totalButtonNew: totalButtonNew });
    }
    else
      handlingAccount.push({ account: bankAccounts, rowSpanCurrency: rowSpanCurrency, totalRow: totalRow, totalButtonNew: totalButtonNew });
    handlingAccount && handlingAccount.map((item, index) => {
      handlingAccount && handlingAccount.map((itemIngredient, indexIngredient) => {
        if (item.account.currency && item.account.currency.code == "" && itemIngredient.account.bankAccountId == item.account.bankAccountId) {
          itemIngredient.totalRow = item.rowSpanCurrency
        }

      })
    })
  })
  //console.log("handlingAccount: ", handlingAccount)
  return handlingAccount;
}

//------- Xuất excel -------//
export var tableToExcel = (function () {
  var style = "<style>.green { background-color: green; }</style>";
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>{table}</table></body></html>'
    , base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)))
    }
    , format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
    }
  return function (table, name) {
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table && table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }
})()

//------- In -------//
export function printDiv(divName) {
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;

  document.body.innerHTML = printContents;

  window.print();

  document.body.innerHTML = originalContents;
}

export function Export2Word(element, filename = '') {
  var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var postHtml = "</body></html>";
  var html = preHtml + document.getElementById(element).innerHTML + postHtml;

  var blob = new Blob(['\ufeff', html], {
    type: 'application/msword'
  });

  // Specify link url
  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

  // Specify file name
  filename = filename ? filename + '.doc' : 'document.doc';

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
}