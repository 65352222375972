import * as Yup from "yup";

export const UserStatusCssClasses = ["danger", "success"]
export const UserStatusTitles = ["Vô hiệu", "Kích hoạt"];
export const UserIsActiveCssClasses = ["danger", "success"]
export const UserIsActiveTitles = ["Vô hiệu", "Kích hoạt"];
export const UserConditionCssClasses = ["success", "danger", ""];
export const UserConditionTitles = ["New", "Used"];
export const OnlineUserTitles = ["Offline", "Online"];
export const GenderUserStatusCssClasses = ["", "bg-transparent", "bg-transparent"];
export const GenderUserStatusTitles = ["", "Nam", "Nữ"];
export const UserTypeTitles = ["", "Người dùng", "Môi giới", "Đối tác"];
export const defaultSorted = [{ dataField: "displayOrder", order: "asc" }];

export const USER_ISACTIVE = [
  { id: 0, name: "Vô hiệu" },
  { id: 1, name: "Kích hoạt" }
];
export const USER_ISACTIVE_FILTER = [
  { value: false, label: "Vô hiệu" },
  { value: true, label: "Kích hoạt" },
];

export const USER_TYPE = [
  { value: 1, name: "Người dùng" },
  { value: 2, name: "Môi giới" },
  { value: 3, name: "Đối tác" },
];

export const USER_TYPE_OBJ = {
  USER: 1,
  COLLABORATOR: 2,
  PARTNER: 3,
};

export const USER_TYPE_NOT_COLLABORATOR = [
  { value: 1, name: "Người dùng" },
  { value: 3, name: "Đối tác" },
];



export const ROLE_TYPE = [
  { id: 1, name: "Quản trị" },
  { id: 2, name: "Chủ" },
  { id: 3, name: "Môi giới" },

];
export const USER_TYPE_FIND = [
  { value: 1, label: "Người dùng" },
  { value: 3, label: "Đối tác" },
  // { value: 4, label: "Cộng tác viên" },
];

export const GENDER_ORGANIZATION = [
  { id: 1, name: "Nam" },
  { id: 2, name: "Nữ" }
];

export const GENDER_ORGANIZATION_FIND = [
  { value: 1, label: "Nam" },
  { value: 2, label: "Nữ" }
];
export const setUsersUIEvents = (history) => {
  const usersUIEvents = {
    newUserButtonClick: () => {
      history.push("/users/new");
    },
    openCloneUserPage: (id) => {
      history.push(`/users/${id}/clone`);
    },
    openEditUserPage: (id) => {
      history.push(`/users/${id}/edit`);
    },
    openDeleteUserDialog: (id) => {
      history.push(`/users/${id}/delete`);
    },
    openDeleteUsersDialog: () => {
      history.push(`/users/deleteUsers`);
    },
    openFetchUsersDialog: () => {
      history.push(`/users/fetch`);
    },
    openUpdateUsersIsActiveDialog: () => {
      history.push("/users/updateActiveForOrganizations");
    },
    openShowUserPage: (id) => {
      history.push(`/users/${id}/show`);
    },
    openPermissionBuilding: (id) => {
      history.push(`/users/${id}/permission`);
    },
  };
  return usersUIEvents;
};

//user
const USER_COLUMN_TOGGLE_CONFIG_KEY =
  process.env.REACT_APP_USER_COLUMN_TOGGLE_CONFIG_KEY ||
  "userColumnToggleConfig";

export function getColumnToggleConfig() {
  const ls = localStorage.getItem(USER_COLUMN_TOGGLE_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return {
    gender: false,
    departmentId: false,
    userType: false,
    birthday: true,
    tel: true,
    email: true,
    address: true,
    roleId: false,
    positionId: false,
    toltalCommission: true,
    isActive: false,
    displayOrder: false,
    permissionBuilding: false,
    description: true,
    createdAt: true,
    createdBy: true,
    updatedAt: true,
    updatedBy: true,
  };
}

export function setColumnToggleConfig(key, value) {
  const columnToggleConfig = getColumnToggleConfig();
  columnToggleConfig[key] = value;
  localStorage.setItem(
    USER_COLUMN_TOGGLE_CONFIG_KEY,
    JSON.stringify(columnToggleConfig)
  );
}
// -------------------- Schema ------------------------------//

const UserNewSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .min(1)
    .nullable()
    .required(),
  password: Yup.string()
    .matches(
      /(?=.*?[A-Z]).{6,}/,
      "Ít nhất 6 ký tự, ít nhất 1 ký tự hoa "
    )
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null],)
    .nullable()
    .required(),
  isAdministrator: Yup.boolean().nullable(),
  roleId: Yup.number()
    .nullable()
    .when("isAdministrator",
      (isAdministrator, schema) => {
        if (!isAdministrator)
          return schema.min(1)
            .nullable()
            .required()
        else return schema.default(null)
      }),
});

const UserEditSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .min(1)
    .nullable()
    .required(),
  isAdministrator: Yup.boolean().nullable(),
  roleId: Yup.number()
    .nullable()
    .when("isAdministrator",
      (isAdministrator, schema) => {
        if (!isAdministrator)
          return schema.min(1)
            .nullable()
            .required()
        else return schema.default(null)
      }),
});

const CollaBoratorEditSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .trim()
    .nullable()
    .required(),
});
const CollaBoratorNewSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .trim()
    .nullable()
    .required(),
  password: Yup.string()
    .matches(
      /(?=.*?[A-Z]).{6,}/,
      "Ít nhất 6 ký tự, ít nhất 1 ký tự hoa "
    )
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null],)
});

const PartnerNewSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .min(1)
    .nullable()
    .required(),
  isAdministrator: Yup.boolean().nullable(),
  roleId: Yup.number()
    .nullable()
    .when("isAdministrator",
      (isAdministrator, schema) => {
        if (!isAdministrator)
          return schema.min(1)
            .nullable()
            .required()
        else return schema.default(null)
      }),
  password: Yup.string()
    .matches(
      /(?=.*?[A-Z]).{6,}/,
      "Ít nhất 6 ký tự, ít nhất 1 ký tự hoa "
    )
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null],)
    .nullable()
    .required(),
});

const PartnerEditSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),

  firstName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  lastName: Yup.string()
    .trim()
    .nullable()
    .max(5000)
    .required(),
  email: Yup.string()
    .trim()
    .nullable()
    .email()
    .max(5000)
    .required(),
  tel: Yup.string()
    .trim()
    .nullable()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,)
    .required(),
  birthdayString: Yup.date()
    .max(new Date(),),
  displayOrder: Yup.number()
    .integer()
    .nullable(),
  address: Yup.string()
    .max(5000)
    .trim()
    .nullable()
    .required(),
  provinceId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  districtId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  wardId: Yup.number()
    .min(1)
    .nullable()
    .required(),
  username: Yup.string()
    .min(1)
    .nullable()
    .required(),
  isAdministrator: Yup.boolean().nullable(),
  roleId: Yup.number()
    .nullable()
    .when("isAdministrator",
      (isAdministrator, schema) => {
        if (!isAdministrator)
          return schema.min(1)
            .nullable()
            .required()
        else return schema.default(null)
      }),
});

export const UserTypeSchema = [
  {
    new: false,
    edit: false,
  },
  {
    new: UserNewSchema,
    edit: UserEditSchema,
  },
  {
    new: CollaBoratorNewSchema,
    edit: CollaBoratorEditSchema,
  },
  {
    new: PartnerNewSchema,
    edit: PartnerEditSchema,
  },
];

export const usersUIEvents = (history) => {
  const usersUIEvents = {
    newUserButtonClick: () => {
      history.push("/users/new");
    },
    openCloneUserPage: (id) => {
      history.push(`/users/${id}/clone`);
    },
    openEditUserPage: (id) => {
      history.push(`/users/${id}/edit`);
    },
    openDeleteUserDialog: (id) => {
      history.push(`/users/${id}/delete`);
    },
    openDeleteUsersDialog: () => {
      history.push(`/users/deleteUsers`);
    },
    openFetchUsersDialog: () => {
      history.push(`/users/fetch`);
    },
    openUpdateUsersIsActiveDialog: () => {
      history.push("/users/updateActiveForOrganizations");
    },
    openShowUserPage: (id) => {
      history.push(`/users/${id}/show`);
    },
    openPermissionBuilding: (id) => {
      history.push(`/users/${id}/permission`);
    },
    openCommissionCollaboratorPage: (id) => {
      history.push(`/users/${id}/collaboratorCommissions`);
    },
    openCommissionPage: (id) => {
      history.push(`/users/${id}${id}/commission`);
    },
    importUserButtonClick: (id) => {
      history.push(`/users/import`);
    },
    openResetPassWordPage: (id) => {
      history.push(`/users/${id}/resetPassWord`);
    },

    tabOrganizationButtonClick: () => {
      history.push("/organization");
    },
    tabDepartmentButtonClick: () => {
      history.push("/departments");
    },
    tabPositionButtonClick: () => {
      history.push("/positions");
    },
    tabRoleButtonClick: () => {
      history.push("/roles");
    },
    tabUserButtonClick: () => {
      history.push("/users");
    },
  }
  return usersUIEvents;
};
