/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../../_helpers";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";

export function Administrator() {

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    })
  )

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  return (
    <>
      {/* Vai trò quản trị */}
      {isAdministrator(user) && (
        <>
          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản trị cấp cao</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/admin/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/dashboard">
              <span className="svg-icon menu-icon">
                <i className="flaticon-dashboard icon-lg text-white"></i>
              </span>
              <span className="menu-text">Bảng quản trị</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/admin/deskBase", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/deskBase">
              <span className="svg-icon menu-icon">
                <i className="flaticon-dashboard icon-lg text-white"></i>
              </span>
              <span className="menu-text">Quản trị cơ sở</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/admin/desk", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/desk">
              <span className="svg-icon menu-icon">
                <i className="flaticon-computer icon-lg text-white"></i>
              </span>
              <span className="menu-text">Bàn làm việc</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/admin/cashFlows", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/cashFlows">
              <span className="svg-icon menu-icon">
                <i className="flaticon-diagram icon-lg text-white"></i>
              </span>
              <span className="menu-text">Dòng tiền</span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý tổ chức</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive("/admin/organization", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/organization">
              <span className="svg-icon menu-icon">
                <i className="flaticon-globe icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="ORGANIZATIONS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/departments",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/departments">
              <span className="svg-icon menu-icon">
                <i className="flaticon-map icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="DEPARTMENTS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/positions",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/positions">
              <span className="svg-icon menu-icon">
                <i className="flaticon-technology-1 icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="POSITIONS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/roles",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/roles">
              <span className="svg-icon menu-icon">
                <i className="flaticon-user-ok icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="ROLES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/users",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/users">
              <span className="svg-icon menu-icon">
                <i className="flaticon-users icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="USERS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý khách hàng</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/customers",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/customers">
              <span className="svg-icon menu-icon">
                <i className="flaticon-customer icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Khách hàng
              </span>
            </NavLink>
          </li>





          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý gia hạn</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/transactions",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/transactions">
              <span className="svg-icon menu-icon">
                <i className="flaticon2-writing icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Gia hạn
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý cộng tác viên</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/collaborators",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/collaborators">
              <span className="svg-icon menu-icon">
                <i className="flaticon2-group icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Cộng tác viên
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/commissions",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/commissions">
              <span className="svg-icon menu-icon">
                <i className="fas fa-money-bill-wave icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Hoa hồng
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý Hỗ trợ</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/supports",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/supports">
              <span className="svg-icon menu-icon">
                <i className="flaticon-lifebuoy icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="REPORT_BUGS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Danh mục hệ thống</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/privileges",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/privileges">
              <span className="svg-icon menu-icon">
                <i className="flaticon-signs-1 icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="PRIVILEGES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/nationals",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/nationals">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-earth-globe icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="NATIONALS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li> */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/provinces",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/provinces">
              <span className="svg-icon menu-icon">
                <i className="flaticon-pin icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="PROVINCES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/districts",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/districts">
              <span className="svg-icon menu-icon">
                <i className="flaticon-placeholder icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="DISTRICTS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/wards",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/wards">
              <span className="svg-icon menu-icon">
                <i className="flaticon-map-location icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="WARDS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/currencies",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/currencies">
              <span className="svg-icon menu-icon">
                <i className="flaticon-coins icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="CURRENCIES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/banks",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/banks">
              <span className="svg-icon menu-icon">
                <i className="flaticon-suitcase icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="BANKS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/businesses",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/businesses">
              <span className="svg-icon menu-icon">
                <i className="flaticon-tool-1 icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="BUSINESSES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Danh mục Gooland</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/directions",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/directions">
              <span className="svg-icon menu-icon">
                <i className="fas fa-directions icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="DIRECTIONS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/price-ranges",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/price-ranges">
              <span className="svg-icon menu-icon">
                <i className="fas fa-coins icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="PRICERANGES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/real-estate-types",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/real-estate-types">
              <span className="svg-icon menu-icon">
                <i className="flaticon2-layers-2 icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="REALESTATETYPES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/legal-documents",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/legal-documents">
              <span className="svg-icon menu-icon">
                <i className="flaticon-interface-3 icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="LEGALDOCUMENTS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/outstanding-features",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/outstanding-features">
              <span className="svg-icon menu-icon">
                <i className="flaticon-star icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="OUTSTANDINGFEATURES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/metadata-floors",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/metadata-floors">
              <span className="svg-icon menu-icon">
                <i className="flaticon-layers icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="METADATAFLOORS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/road-wide-types",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/road-wide-types">
              <span className="svg-icon menu-icon">
                <i className="fas fa-road icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="ROADWIDETYPES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/bedroom-numbers",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/bedroom-numbers">
              <span className="svg-icon menu-icon">
                <i className="fas fa-bed icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="BEDROOMNUMBERS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/road-widths",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/road-widths">
              <span className="svg-icon menu-icon">
                <i className="fas fa-store-alt icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="ROADWIDTHS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý tin đăng</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/posts",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/posts">
              <span className="svg-icon menu-icon">
                <i className="flaticon-customer icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Danh sách tin đăng
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý khách hàng BGID</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/BGID",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/BGID">
              <span className="svg-icon menu-icon">
                <i className="flaticon-customer icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Khách hàng BGID
              </span>
            </NavLink>
          </li>

          <li className="menu-section mt-0">
            <h4 className="menu-text">Quản lý cập nhật</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin/change-logs",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin/change-logs">
              <span className="svg-icon menu-icon">
                <i className="flaticon2-start-up icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                Nhật ký cập nhật
              </span>
            </NavLink>
          </li>
        </>
      )}
    </>
  );
}
