import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getColorValid = (valid, isFocused) => {
  if (valid.includes('is-valid')) {
    return '#007c7e !important'
  }
  else if (valid.includes('is-invalid'))
    return '#F64E60 !important'
  else {
    if (isFocused) {
      return '#00CFA7 !important'
    }
    else {
      return '#E4E6EF !important'
    }
  }
}




export function SelectMultiField(props) {
  const {
    field,
    form,
    options,
    checkYup = true,
    label,
    placeholder = "-- Chọn --",
    disabled,
    required = false,
    handleFieldChange,
    formProps,
    withFeedbackLabel = true,
    customFeedbackLabel,
    minWidth = 120,
    type
  } = props;

  const filterExtendStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
      flexDirection: "row-reverse"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),

    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#fff'
    }),
  }

  const { name, value } = field;
  const { errors, touched } = form;

  if (value?.includes('*')) {
    let idsSelected = options && options.map((item) => {
      return item.value;
    });

    if (value?.length === idsSelected?.length + 1) {
      formProps.setFieldValue(`${name}`, []);
    }
    else {
      formProps.setFieldValue(`${name}`, idsSelected);
    };
  };

  const selectedOption = options && options.filter(option => value !== null && (value && value.includes(option.value)));

  const onChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : selectedOption;

    const multiValue = [];
    selectedOption && selectedOption.map((item) => multiValue.push(item.value));

    const changeEvent = {
      target: { name: name, value: selectedValue }
    };

    const changeMultiEvent = {
      target: { name: name, value: multiValue }
    };

    field.onChange(changeEvent);
    form.setFieldTouched(field.name, true);

    form.handleChange(changeMultiEvent);
    handleFieldChange(changeMultiEvent, formProps);
  };

  const Option = props => {
    const { innerProps, innerRef, data, isFocused } = props;

    return (
      <>
        <article ref={innerRef} {...innerProps} className={'custom-option'}>
          <div type="button" className="d-flex" style={{ backgroundColor: (value === data.value) ? '#1C7947' : (isFocused && "#C0D8C0"), borderColor: "#0123" }}>
            <div className="d-flex justify-content-center">
              <label className="checkbox">
                <input type="checkbox" className="form-control form-control-sm"
                  name={`${data.value}`}
                  checked={props.selectProps.value.find(element => element.value === data.value) || props.selectProps.value.length === options.length}
                />
                <span className='ml-2'></span>
              </label>
              <span className={`col ${(value === data.value) && "text-white"} my-3`}>{(data.label === "null" || data.label === "undefined") ? "" : data.label}</span>
            </div>
          </div>
        </article >
      </>
    );
  };

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      <Select
        id={name}
        {...field}
        menuPlacement="auto"
        value={selectedOption}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        options={[{ label: "Tất cả", value: "*" }, ...options]}
        noOptionsMessage={() => "Không có dữ liệu"}
        className={checkYup ? "react-select" : ""}
        styles={filterExtendStyles}
        menuPortalTarget={document.body}
        components={{ Option }}
        classNamePrefix={getFieldCSSClasses(touched[field.name], errors[field.name])}
        closeMenuOnSelect={!true}
        isMulti={true}
        hideSelectedOptions={false}
        isOptionDisabled={(option) => option.isdisabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
