/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import { Box, Slider } from "@material-ui/core";
import React, { useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import { Button, Modal } from "react-bootstrap";

export function CroppedImg(props) {
    const { isShowEdit, setIsShowEdit, setAvatar, setFileImgSelected, editor, setPicture, picture, setEditor, typeImage } = props
    const handleSelectImageFile = (file) => {
        setFileImgSelected({ selectedAvatar: file });
    }

    useEffect(() => {
        picture.croppedImg && setAvatar(picture.croppedImg)
    }, [picture, picture.croppedImg]);

    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value
        });
    };

    const handleCancel = () => {
        setPicture({
            ...picture,
            cropperOpen: false
        });
    };

    const setEditorRef = (ed) => {
        setEditor(ed);
    };

    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas()
            let croppedImg = canvasScaled.toDataURL();
            canvasScaled.toBlob(blob => {
                const file = new File([blob], `${picture.nameImg}`, {
                    type: "image/png"
                });
                handleSelectImageFile(file)
            });;
            setPicture({
                ...picture,
                img: null,
                cropperOpen: false,
                croppedImg: croppedImg,
            });

        }
    };



    return (
        <>
            <Modal
                size="md"
                show={isShowEdit}
                onHide={() => setIsShowEdit(false)}
                backdrop="static"
                aria-labelledby="example-modal-sizes-title-lg"
                className="pt-18"
            >
                <Modal.Body className="overlay overlay-block cursor-default px-0 py-0">
                    {picture.cropperOpen && (
                        <Box display="block" className="pt-6 pl-6">
                            <AvatarEditor
                                ref={setEditorRef}
                                image={picture.img}
                                width={360}
                                height={typeImage === "avatar" ? 360 : 202}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                rotate={0}
                                scale={picture.zoom}
                            />
                            <div className="mr-6">
                                <Slider
                                    aria-label="raceSlider"
                                    value={picture.zoom}
                                    min={1}
                                    max={10}
                                    step={0.1}
                                    onChange={handleSlider}
                                />
                            </div>
                            <Box>
                                <div className="mb-6 mr-6 d-flex justify-content-end">
                                    <Button variant="contained" className="btn-light mr-2" onClick={(e) => { handleCancel(e); setIsShowEdit(false) }}>
                                        Hủy
                                    </Button>
                                    <Button onClick={(e) => { handleSave(e); setIsShowEdit(false) }}>Lưu</Button>
                                </div>
                            </Box>
                        </Box>
                    )}

                </Modal.Body>
            </Modal>
        </>
    );
}
