// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actionsAttachments from "../../../_redux/documents/documentsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./AttachmentsUIHelper";
import * as commonUIHelpers from "../../../../../_helpers/_CommonUIHelpers";
import {
  NotFoundTable,
  Pagination,
}
  from "../../../../../../_bloomgoo/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  getSelectOnlyRow,
} from "../../../../../../_bloomgoo/_helpers";
import { useAttachmentsUIContext } from "./AttachmentsUIContext";
import { FormattedMessage, injectIntl } from "react-intl";
import * as columnFormatters from "./column-formatters";
import { HeaderColumnFormatter, OrderColumnFormatter } from "../../../../../_helpers/column-formatters";
import { rowEvents, rowStyle } from "../../../../../../_bloomgoo/_helpers/TableRowStyle";

export function AttachmentsTableIntl(props) {
  const { intl } = props;
  const uIContext = useAttachmentsUIContext();
  const uIProps = useMemo(() => {
    return {
      queryParams: uIContext && uIContext.queryParams,
      setQueryParams: uIContext && uIContext.setQueryParams,
      id: uIContext && uIContext.id,
      setId: uIContext && uIContext.setId,
      ids: uIContext && uIContext.ids,
      setIds: uIContext && uIContext.setIds,
      selectedRows: uIContext && uIContext.selectedRows,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
      isMulti: uIContext && uIContext.isMulti,
    };
  }, [uIContext]);

  // Attachments Redux state
  // Getting current state of products list from store (Redux)

  const { currentState } = useSelector(
    (state) => ({ currentState: state.documents }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    uIProps.setIds && uIProps.setIds([]);
    dispatch(actionsAttachments.fetchDocuments(uIProps.queryParams));
  }, [uIProps.queryParams, dispatch]);

  // uIProps.currentAttachments && uIProps.currentAttachments.map(())

  const columns = [
    {
      dataField: "id",
      headerClasses: "text-center",
      text: "#",
      formatter: OrderColumnFormatter,
      classes: "text-center",
    },
    {
      dataField: "documentOriginalName",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.documents.documentOriginalName",
        }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.NameColumnFormatter,
      classes: "text-left",
    },
    {
      dataField: "documentPath",
      headerClasses: "text-center",
      formatter: columnFormatters.FormatFileColumnFormatter,
      classes: "text-center",
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: commonUIHelpers.sizePerSmallPageList,
    sizePerPage: uIProps.queryParams.pageSize,
    page: uIProps.queryParams.pageNumber,
  };

  return (
    <>
      {entities && (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                  bordered={false}
                  bootstrap4
                  remote
                  keyField="id"
                  data={entities === null ? [] : entities}
                  columns={columns}
                  rowEvents={
                    rowEvents({

                      ids: uIProps.ids,
                      setIds: uIProps.setIds && uIProps.setIds,
                      setSelectedRows: uIProps.setSelectedRows && uIProps.setSelectedRows,
                      selectedRows: uIProps.selectedRows,
                      arrayRemove: commonUIHelpers.arrayRemove,
                    }
                    )
                  }
                  rowStyle={
                    rowStyle({

                      ids: uIProps.ids,
                      currentItems: []
                    }
                    )
                  }
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(
                    uIProps.setQueryParams,
                    uIProps.queryParams
                  )}
                  selectRow={uIProps.isMulti ?
                    getSelectRow({
                      entities,
                      ids: uIProps.ids,
                      setIds: uIProps.setIds && uIProps.setIds,
                      selectedRows: uIProps.selectedRows,
                      setSelectedRows: uIProps.setSelectedRows && uIProps.setSelectedRows,
                    })
                    :
                    getSelectOnlyRow({
                      entities,
                      ids: uIProps.ids,
                      setIds: uIProps.setIds,
                      selectedRows: uIProps.selectedRows,
                      setSelectedRows: uIProps.setSelectedRows,
                    })}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities} />
                  <NoRecordsFoundMessage entities={entities} isLoading={listLoading} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
}
export const AttachmentsTable = injectIntl(AttachmentsTableIntl);
