import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialPostsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  postForEdit: null,
  postForShow: null,

  filterCount: null,
  currentPost: null,
  currentPosts: [],
  notification: null,
  error: null,
  isError: null,
  errorType: null
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const postsSlice = createSlice({
  name: "posts",
  initialState: initialPostsState,
  reducers: {
    catchError: (state, action) => {

      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          //console.log(state.errorType);
          //console.log("initialization")
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          //console.log(action.payload.callType, state.errorType);
          state.actionsLoading = false;
        }
    },
    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      //console.log(action.payload.callType);
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        //console.log(action.payload.callType);
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentPost = null;
      state.currentPosts = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.postForEdit = null;
    },

    // getPostById
    postFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.postForEdit = action.payload.postForEdit;
      state.error = null;

    },
    //show
    getPost: (state, action) => {
      state.actionsLoading = false;
      state.postForShow = action.payload.postForShow;
      state.error = null;

    },
    // findAllPosts
    postsFetchedAll: (state, action) => {
      const { total, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.total = total;
    },
    // findPosts
    postsFetched: (state, action) => {
      //console.log("state: ", state)
      //console.log("action: ", action)
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.currentPost = null;
    },
    // findParentPosts
    postsPareParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createPost
    postCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.post);
      state.currentPost = action.payload.post;
      state.currentPosts.push(action.payload.post);
    },
    // updatePost
    postUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.post.id) {
          return action.payload.post;
        }
        return entity;
      });
      state.currentPost = action.payload.post;
      state.currentPosts.push(action.payload.post);

    },
    // deletePost
    postDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = false;
      state.actionsLoading = false;
      state.currentPosts = [];

    },
    // deletePosts
    postsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentPost = null;
      state.currentPosts = [];

    },
    // postsUpdateState
    postsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
