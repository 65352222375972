import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialIssuesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  issueForEdit: null,

  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const issuesSlice = createSlice({
  name: "issues",
  initialState: initialIssuesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentIssue = null;
      state.currentIssues = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.issueForEdit = null;
    },

    resetCurrentIssues: (state, action) => {
      state.currentIssue = null;
      state.currentIssues = [];
    },

    // getIssueById
    issueFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.issueForEdit = action.payload.issueForEdit;
      state.error = null;

    },
    // findAllIssues
    issuesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findIssues
    issuesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findParentIssues
    issuesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createIssue
    issueCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.entities.unshift(action.payload.issue);
    },
    // updateIssue
    issueUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.issue.id) {
          return action.payload.issue;
        }
        return entity;
      });
    },
    // deleteIssue
    issueDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteIssues
    issuesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // issuesUpdateState
    issuesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
