import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { LoadingDialog } from "../../../../_bloomgoo/_partials/controls";

export function EmailTemplatesLoadingDialog() {
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.emailTemplates.listLoading }),
    shallowEqual
  );
  useEffect(() => { }, [isLoading]);
  return (
    <LoadingDialog
      isLoading={isLoading}
      text={<FormattedMessage id="COMMONS.UI.Loading" ></FormattedMessage>}
    />
  );
}
