/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Table } from "react-bootstrap";
import axios from "axios";
import NumberFormat from "react-number-format";
import * as UIHelpers from "../../../_helpers/_CommonUIHelpers";
import Axios from "axios";
import { SERVICE_TYPES_OBJ } from "../../../_helpers/EnumUIHelpers";

function Preview({ history }) {
  let { id } = useParams()
  const [isRequested] = useState(false);
  const [isErrorRequestServer, setIsErrorRequestServer] = useState(false);
  const [previewData, setPreviewData] = useState()
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(`/public/invoices/${id}/export`)
      .then(function (response) {
        setPreviewData(response.data)
      })
      .catch(function (error) {
        setIsErrorRequestServer(true);
      });
  }, [id, dispatch]);

  let rowIndexAccount = 0;
  let totalInvoiceServices = {
    subtotalServiceAdjustmentMoney: 0,
    subtotalServiceMoney: 0,
  };

  const checkServiceMeter = (serviceType) => {
    if (serviceType === null || serviceType === undefined) {
      return false
    }
    else {
      if (serviceType === SERVICE_TYPES_OBJ.ELECTRICITY || serviceType === SERVICE_TYPES_OBJ.WATER || serviceType === SERVICE_TYPES_OBJ.GAS) {
        return true;
      }
      else {
        return false;
      }
    }
  };

  console.log("user?.organization?.logo: ", user?.organization?.logo)
  return (
    <>
      {isRequested ?
        <Redirect to="/dang-nhap" />
        :
        <>
          
            <div className="container" style={{ width: 'max-content' }}>
            <div className="bg-white my-5" style={{ zIndex: 999999 }}>
              <div className="bg-body rounded shadow-sm p-10 p-lg-15 mx-auto  overflow-auto bg-white" style={{ width: "max-content", maxWidth: "891px", minHeight: "100vh", maxHeight: "1261px" }}>

                    <table className="table table-bordered table-preview table-preview-no-border">
                      <tbody>
                        <tr>
                          <td colSpan="2" className="p-0">
                            <div
                              className="mt-3">
                              <img src={`${Axios.defaults.cdnURL}/${user?.organization?.logo}`} alt="" width="100px" />

                            </div>
                             ;
                          </td>
                          <td colSpan="4" width="50%" className="text-right p-0" >
                            <div className="d-flex justify-content-end">
                              <div className="mt-4">
                                <span className="word-break text-wrap">{previewData?.organizationName}</span>
                                <br />
                                <span className="word-break text-wrap">{previewData?.organizationAddress}</span>
                              </div>
                              <div>
                                {previewData?.id && <img alt="" className="no-print" src={`${Axios.defaults.baseURL}/invoices/${previewData?.id}/qrcode`} width="80" height="80" />}

                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="6" className="text-center p-0">
                            <h1 className="text-center font-weight-bold m-0">Hóa đơn dịch vụ</h1>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="6" className="text-center p-0">
                            <div className="mb-3"><span>Kỳ thanh toán: {previewData && previewData.month ? <span>{previewData && previewData.month && previewData.month.toString().padStart(2, '0')}/</span> : ""}{previewData && previewData.year}</span></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-wrap p-0" >
                            <div className="d-flex">
                              <div className="col-lg-4">
                                <b>Tên khách hàng </b>
                              </div>
                              <div className="col-lg-8">
                                <span >{previewData?.customerName}</span>
                              </div>
                            </div>

                          </td>
                          <td colSpan="3" className="text-wrap p-0" >
                            <div className="d-flex">
                              <div className="col-lg-5">
                                <b className="text-nowrap">Ngày phát hành </b>
                              </div>
                              <div className="col-lg-7 text-right">
                                <span className="text-nowrap">{previewData?.invoiceDate}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-wrap p-0" >
                            <div className="d-flex">
                              <div className="col-lg-4"><b>Địa chỉ </b></div>
                              <div className="col-lg-8"><span >{previewData?.unitName} {previewData?.buildingName}</span></div>
                            </div>
                          </td>
                          <td colSpan="3" className="text-wrap p-0"  >
                            <div className="d-flex">
                              <div className="col-lg-5"><b className="text-nowrap">Hạn thanh toán </b></div>
                              <div className="col-lg-7 text-right"><span className="text-nowrap">{previewData && previewData.invoiceExpiredDate}</span></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="react-bootstrap-table table-responsive" >
                      <Table bordered size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-preview-border">
                        <tbody>
                          <tr >
                            <td className="text-left  font-weight-boldest text-uppercase" colSpan={8}>Thông tin dịch vụ </td>
                          </tr>
                          <tr >
                            <td className="text-center font-weight-boldest" rowSpan={2}><FormattedMessage id="MODEL.UI.invoiceServices.serviceName" /></td>
                            <td className="text-center font-weight-boldest" colSpan={2}><FormattedMessage id="MODEL.UI.invoiceServices.startChargeDate" /></td>
                            <td className="text-center font-weight-boldest" colSpan={3}><FormattedMessage id="MODEL.UI.invoiceServices.amount" /></td>
                            <td className="text-center font-weight-boldest" rowSpan={2}><FormattedMessage id="MODEL.UI.invoiceServices.unitPrice" /></td>
                            <td className="text-center font-weight-boldest" rowSpan={2}><FormattedMessage id="MODEL.UI.invoices.totalRemainMoney" /></td>
                          </tr>
                          <tr >
                            <td className="text-center font-weight-boldest">Từ</td>
                            <td className="text-center font-weight-boldest">Đến</td>
                            <td className="text-center font-weight-boldest">Đầu</td>
                            <td className="text-center font-weight-boldest">Cuối</td>
                            <td className="text-center font-weight-boldest">SL</td>
                          </tr>
                          {previewData && previewData.invoiceServices && previewData.invoiceServices.map((row, rowIndex) => {
                            totalInvoiceServices.subtotalServiceAdjustmentMoney = previewData.subtotalServiceAdjustmentMoney;
                            totalInvoiceServices.subtotalServiceMoney = previewData.subtotalServiceMoney;

                            return (
                              <tr key={row.serviceId}>
                                <td >
                                  <span>{row.serviceName}</span>
                                </td>
                                <td className="text-center">
                                  <span>{row.startDate}</span>
                                </td>
                                <td className="text-center">
                                  <span>{row.endDate}</span>
                                </td>
                                <td className="text-center">
                                  <span>{checkServiceMeter(row.serviceType) ? <NumberFormat value={row.startNumber ? row.startNumber : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} /> : ''}</span>
                                </td>
                                <td className="text-center">
                                  <span>{checkServiceMeter(row.serviceType) ? <NumberFormat value={row.endNumber ? row.endNumber : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} /> : ''} </span>
                                </td>
                                <td className="text-center">
                                  <span>{<NumberFormat value={row.amount ? row.amount : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                                <td className="text-right">
                                  {
                                    row && (row.formulaType === UIHelpers.PRICE_TYPES_OBJ.FORMULA_PRICE && (row.service && row.service.serviceType != UIHelpers.SERVICE_TYPES_OBJ.RENT) && row.service.serviceType != UIHelpers.SERVICE_TYPES_OBJ.VEHICLE) ? (
                                      (row.service && row.service.serviceType != UIHelpers.SERVICE_TYPES_OBJ.VEHICLE) &&
                                      (
                                        // <span>{row.service && row.service.formula && row.service.formula.name}</span>
                                        <span>{row.formula && row.formula.name}</span>
                                      )
                                    ) : (
                                      <span>{row.unitPrice ? <NumberFormat value={row.unitPrice} displayType={"text"} thousandSeparator={true} /> : 0}</span>
                                    )
                                  }
                                </td>

                                <td className="text-right">
                                  <span>{<NumberFormat value={row.totalMoney ? row.totalMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="text-left  font-weight-boldest mb-2">Danh sách nợ kỳ trước</div>
                    {/* {previewData && previewData.invoiceDebts && previewData.invoiceDebts.length > 0 && (<> */}
                    <div className="react-bootstrap-table table-responsive table-invoice-closingDebtsInvoices">
                      <Table bordered size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-preview-border">
                        <tbody>
                          <tr >
                            <td className="text-center font-weight-boldest" style={{ width: "40px" }}>#</td>
                            <td className="text-center font-weight-boldest" style={{ width: "70px" }}>Kỳ</td>
                            <td className="text-center font-weight-boldest">Hóa đơn</td>
                            <td className="text-center font-weight-boldest" style={{ width: "120px" }}  >Loại hóa đơn</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Tổng tiền</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Đã t.toán</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Còn nợ</td>
                          </tr>
                          {previewData && previewData.invoiceDebts && previewData.invoiceDebts.map((row, rowIndex) => {
                            return (
                              <tr key={row.id}>
                                <td className="text-wrap text-center">{rowIndex + 1}</td>
                                <td className="text-wrap text-center">{row?.month ? <span>{row.month.toString().padStart(2, '0')}/</span> : ""}{row.year}</td>
                                <td className="text-wrap">{row.name}</td>
                                <td className="text-wrap text-center">{UIHelpers.InvoiceTypeTitles[row.invoiceType]}</td>
                                <td className="text-wrap text-right">
                                  <span>{<NumberFormat value={row?.totalReceivableMoney ? row.totalReceivableMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                                <td className="text-wrap text-right ">
                                  <span>{<NumberFormat value={row?.totalReceivedMoney ? row.totalReceivedMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                                <td className="text-wrap text-right">
                                  <span>{<NumberFormat value={row?.totalRemainMoney ? row.totalRemainMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>



                        {/* <tr >
                <td className="text-center font-weight-boldest ">{"Tổng"}</td>
                <td className="text-center" colSpan={3}>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalDebtRemainMoney ? previewData.subtotalDebtRemainMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalDebtReceivedMoney ? previewData.subtotalDebtReceivedMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalDebtReceivableMoney ? previewData.subtotalDebtReceivableMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
              </tr> */}
                      </Table>
                    </div>
                    {/* </>
        )} */}
                    <div className="text-left  font-weight-boldest mb-2">Danh sách trả trước</div>
                    {/* {previewData && previewData.invoicePrepays && previewData.invoicePrepays.length > 0 && (<> */}
                    <div className="react-bootstrap-table table-responsive table-invoice-closingDebtsInvoices">
                      <Table bordered size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-preview-border">
                        <tbody>
                          <tr >
                            <td className="text-center font-weight-boldest" style={{ width: "40px" }}>#</td>
                            <td className="text-center font-weight-boldest" style={{ width: "70px" }}>Kỳ</td>
                            <td className="text-center font-weight-boldest">Hóa đơn</td>
                            <td className="text-center font-weight-boldest" style={{ width: "120px" }}>Loại hóa đơn</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Tổng tiền</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Còn lại</td>
                            <td className="text-center font-weight-boldest" style={{ width: "80px" }}>Chi trả</td>
                          </tr>
                          {previewData && previewData.invoicePrepays && previewData.invoicePrepays.map((row, rowIndex) => {
                            return (
                              <tr key={row.id}>
                                <td className="text-wrap text-center">{rowIndex + 1}</td>
                                <td className="text-wrap text-center">{row?.month ? <span>{row.month.toString().padStart(2, '0')}/</span> : ""}{row.year}</td>
                                <td className="text-wrap">{row.name}</td>
                                <td className="text-wrap text-center">{UIHelpers.InvoiceTypeTitles[row.invoiceType]}</td>
                                <td className="text-wrap text-right">
                                  <span>{<NumberFormat value={row?.totalPaymentMoney || 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                                <td className="text-wrap text-right">
                                  <span>{<NumberFormat value={row?.totalReceivedMoney || 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                                <td className="text-wrap text-right ">
                                  <span>{<NumberFormat value={row?.totalPaymentRemainMoney || 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        {/* <tr >
                <td className="text-center font-weight-boldest ">{"Tổng"}</td>
                <td className="text-center" colSpan={3}>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalPrepayReceivedMoney ? previewData.subtotalPrepayReceivedMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalPrepayPaymentRemainMoney ? previewData.subtotalPrepayPaymentRemainMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
                <td className="text-right font-weight-boldest" >
                  <span>{<NumberFormat value={previewData?.subtotalPrepayPaymentMoney ? previewData.subtotalPrepayPaymentMoney : 0} displayType={'text'} allowNegative={true} thousandSeparator={true} />}</span>
                </td>
              </tr> */}
                      </Table>
                    </div>
                    {/* </>)} */}

                    <div className="react-bootstrap-table table-responsive table-invoice-closingDebtsInvoices mt-2">
                      <Table bordered size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-preview-border">
                        <tbody >
                          <tr>
                            <td className="text-left font-weight-boldest text-uppercase" colSpan={2}>Thông tin tổng</td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0  align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.subtotalMoney" />
                              <span className="ml-1">(1)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <b><NumberFormat value={previewData && previewData.subtotalMoney} displayType={'text'} thousandSeparator={true}></NumberFormat></b>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle"><FormattedMessage id="MODEL.UI.invoices.otherCostsMoney" />
                              <span className="ml-1">(2)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <NumberFormat value={previewData && previewData.otherCostsMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.totalMoney" />
                              <span className="ml-1">(3)=(1)+(2)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && previewData.totalMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle"><FormattedMessage id="MODEL.UI.invoices.discountMoney" />
                              <span className="ml-1">(4)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <NumberFormat value={previewData && previewData.discountMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle"><FormattedMessage id="MODEL.UI.invoices.tax" />
                              <span className="ml-1">(5)=(3-4)(%)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <NumberFormat value={previewData && previewData.taxMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.totalDebtsMoney" />
                              <span className="ml-1">(6)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && previewData.debtsMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.prepayMoney" />
                              <span className="ml-1">(7)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && previewData.prepayMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle"><FormattedMessage id="MODEL.UI.invoices.refundDepositMoney" />
                              <span className="ml-1">(8)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <NumberFormat value={previewData && previewData.refundDepositMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle"><FormattedMessage id="MODEL.UI.invoices.refundPrepayMoney" />
                              <span className="ml-1">(9)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle">
                              <NumberFormat value={previewData && previewData.refundPrepayMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.totalRemainMoney" />
                              <span className="ml-1">(10)=(3)-(4)+(5)+(6)-(8)-(9)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && previewData.totalRemainMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest">Tổng số tiền đã thanh toán trong kỳ này
                              <span className="ml-1">(11)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && (previewData.totalReceivedMoney ? previewData.totalReceivedMoney : 0)} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left pb-0 pt-0 align-middle font-weight-boldest"><FormattedMessage id="MODEL.UI.invoices.totalReceivableMoney" />
                              <span className="ml-1">(12)=(10)-(11)</span>
                            </td>
                            <td className="text-right pb-0 pt-0 align-middle font-weight-boldest">
                              <NumberFormat value={previewData && previewData.totalReceivableMoney} displayType={'text'} thousandSeparator={true}></NumberFormat>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    <div className="text-center text-italic">Số tiền bằng chữ: {previewData?.totalReceivableMoneyText}./.</div>


                    <table className="table table-bordered table-preview table-preview-no-border">
                      <tbody>
                        <tr>
                          <td colSpan={6} className="text-left align-middle text-wrap">
                            <b>Ghi chú: </b><span>&nbsp;{previewData && previewData.description}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6} className="text-left py-2 align-middle text-wrap">
                            <b>Thanh toán tiền mặt: </b><span>&nbsp;{previewData?.cashContent}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6} className="text-left pb-0 pt-0 align-middle text-wrap">
                            <b >Nội dung thanh toán:</b><span>&nbsp;{previewData?.paymentContent}</span>
                          </td>
                        </tr>

                        {previewData && previewData.bankAccounts && previewData.bankAccounts.length > 0 && (<>
                          <tr>
                            <td colSpan={6} className="text-left py-2 align-middle font-weight-boldest">Danh sách tài khoản</td>
                          </tr>

                          <tr>
                            <td colSpan={6} >
                              {previewData && previewData.bankAccounts && previewData.bankAccounts.map((row, rowIndex) => {
                                if (row.bankAccountType !== 1)
                                  rowIndexAccount += 1;
                                return (
                                  <>
                                    {row.bankAccountType === 2 && (
                                      <>
                                        <div className="text-left pb-0 pt-0 align-middle text-wrap overflow-wrap">
                                          <b className="ml-2">{rowIndexAccount}.</b><span className="ml-3">Số tài khoản: {`${row.accountNumber} - ${row.accountName} - ${(row.bankName && row.branch) ? `${row.bankName} - ${row.branch}` : (row.bankName || row.branch)}`}</span>
                                        </div>
                                      </>
                                    )}
                                    {row.bankAccountType === 3 && (
                                      <>
                                        <div className="text-left pb-0 pt-0 align-middle text-wrap overflow-wrap">
                                          <b className="ml-2">{rowIndexAccount}.</b><span className="ml-3">Số tài khoản: {`${(row.accountName && row.accountNumber) ? `${row.accountNumber} - ${row.accountName}` : (row.accountName || row.accountNumber)} - ${UIHelpers.BankAccountWalletTitles[row.walletId]}`}</span>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )
                              })}
                            </td>
                          </tr>


                        </>)}
                        <tr>
                          <td colSpan={6} className="pb-0 pt-0 align-middle text-wrap text-italic text-center">
                            <p>{previewData && previewData.paymentDescription}</p>
                          </td>
                        </tr>
                        {previewData?.invoiceIsShowVietQR ?
                          <tr>
                            <td colSpan={6} className="row">
                              {previewData && previewData.bankAccounts && previewData.bankAccounts.map((row, rowIndex) => {
                                if (row.bankAccountType !== 1)
                                  rowIndexAccount += 1;
                                return (
                                  <>
                                    {(row.bankAccountType === 2 && row.vietQR) ? (
                                      <>
                                        <div className="col-lg-4 text-center">
                                          <img src={`${row.vietQR}`} alt="" width="180px" />
                                          <div>Ngân hàng: {row.bankShortName}</div>
                                          <div>Số tài khoản: {row.accountNumber}</div>
                                          <div>Tên tài khoản: {row.accountName}</div>
                                        </div>
                                      </>
                                    ) :
                                      <></>
                                    }
                                  </>
                                )
                              })}
                            </td>

                          </tr>
                          :
                          <></>
                        }
                      </tbody>
                    </table>


                  </div>
                </div>
              </div>
        </>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Preview));
