// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actionsCurrencies from "../../Currencies/_redux/currencies/currenciesActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./CurrenciesUIHelper";
import * as commonUIHelpers from "../../../_helpers/_CommonUIHelpers";
import {
  NotFoundTable,
  Pagination,
}
  from "../../../../_bloomgoo/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_bloomgoo/_helpers";
import { useCurrenciesUIContext } from "./CurrenciesUIContext";
import { FormattedMessage, injectIntl } from "react-intl";
import * as columnFormatters from "./column-formatters";
import { HeaderColumnFormatter, OrderColumnFormatter } from "../../../_helpers/column-formatters";

export function CurrenciesTableIntl(props) {
  const { intl } = props;
  const uIContext = useCurrenciesUIContext();
  const uIProps = useMemo(() => {
    return {
      queryParams: uIContext && uIContext.queryParams,
      setQueryParams: uIContext && uIContext.setQueryParams,
      openEditCurrencyDialog: uIContext && uIContext.openEditCurrencyDialog,
      openDeleteCurrencyDialog: uIContext && uIContext.openDeleteCurrencyDialog,
      ids: uIContext && uIContext.ids,
      setIds: uIContext && uIContext.setIds,
      selectedRows: uIContext && uIContext.selectedRows,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
      currentCurrencies: uIContext && uIContext.currentCurrencies
    };
  }, [uIContext]);

  // Currencies Redux state
  // Getting current state of products list from store (Redux)

  const { currentState } = useSelector(
    (state) => ({ currentState: state.currencies }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    uIProps.setIds && uIProps.setIds([]);
    dispatch(actionsCurrencies.fetchCurrencies(uIProps.queryParams));
  }, [uIProps.queryParams, dispatch]);

  let newWards = [];
  let excluded = false;
  entities && entities.map((item) => {
    excluded = false;
    uIProps.currentCurrencies && uIProps.currentCurrencies.map((itemCurrencies) => {
      if (item.id == itemCurrencies.currencyId) {
        excluded = true
      }
    })
    !excluded && newWards.push(item)
  });

  // entities = []
  // entities.push(newWards);
  // //console.log("entities: ", entities)

  const columns = [
    {
      dataField: "id",
      headerClasses: "text-center",
      text: "#",
      formatter: OrderColumnFormatter,
      classes: "text-center",
    },
    {
      dataField: "code",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.currencies.code",
        }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DefaultColumnFormatter,
      classes: "text-left",
    },
    {
      dataField: "name",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.currencies.name",
        }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DefaultColumnFormatter,
      classes: "text-left",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      hidden: true,
      formatExtraData: {
        openEditCurrencyDialog: uIProps.openEditCurrencyDialog,
        openDeleteCurrencyDialog:
          uIProps.openDeleteCurrencyDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: commonUIHelpers.sizePerPageList,
    sizePerPage: uIProps.queryParams.pageSize,
    page: uIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  uIProps.setQueryParams,
                  uIProps.queryParams
                )}
                selectRow={getSelectRow({
                  // 
                  entities,
                  ids: uIProps.ids,
                  setIds: uIProps.setIds && uIProps.setIds,
                  selectedRows: uIProps.selectedRows,
                  setSelectedRows: uIProps.setSelectedRows && uIProps.setSelectedRows,
                })}
                {...paginationTableProps}
              >
              </BootstrapTable>
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} isLoading={listLoading} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
export const CurrenciesTable = injectIntl(CurrenciesTableIntl);
