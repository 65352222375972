import axios from "axios";

export const ROLES_URL = "/roles";

// CREATE =>  POST: add a new role to the server
export function createRole(role) {
  return axios.post(ROLES_URL, role);
}

// READ
export function getAllRoles() {
  return axios.get(ROLES_URL);
}

export function getRoleCloneById(roleId) {
  return axios.get(`${ROLES_URL}/${roleId}/clone`);
}
export function getRoleById(roleId) {
  return axios.get(`${ROLES_URL}/${roleId}`);
}
export function getRoleEditById(roleId) {
  return axios.get(`${ROLES_URL}/${roleId}/edit`);
}
export function findRolesByOrganizationId(organizationId) {
  return axios.get(`${ROLES_URL}/organization/${organizationId}`);
}

export function findRolesByRoleType(roleType) {
  return axios.get(`${ROLES_URL}/roleTypes/${roleType}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRoles(queryParams) {
  return axios.post(`${ROLES_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateRole(role) {
  return axios.put(`${ROLES_URL}/${role.id}`, role);
}

// UPDATE Status
export function updateStatusForRoles(ids, status) {
  return axios.post(`${ROLES_URL}/updateStatusForRoles`, {
    ids,
    status,
  });
}

// DELETE => delete the role from the server
export function deleteRole(roleId) {
  return axios.delete(`${ROLES_URL}/${roleId}`);
}

// DELETE Roles by ids
export function deleteRoles(ids) {
  return axios.delete(`${ROLES_URL}/bulk`, { data: { ids } });

}

export function getInitRole(roleType) {
  return axios.get(`${ROLES_URL}/roleTypes/${roleType}/create`);
}