/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Input,
  SwitchField,
  TextareaField,
} from "../../../../_bloomgoo/_partials/controls";
import * as Yup from "yup";
import * as UIHelpers from "../../../_helpers/_CommonUIHelpers";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";
import { Field, Formik } from "formik";
import { useEmailsUIContext } from "./EmailsUIContext";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";

const EmailTemplateSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  name: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  subject: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  content: Yup.string()
    .trim()
    .nullable()
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
});

export function EmailTemplateEditDialog(props) {
  const { id, onHide, mode } = props;
  // MeterLogsGovernment Redux state
  const dispatch = useDispatch();
  const { actionsLoading, emailTemplateEdit, error, errorType } = useSelector(
    (state) => ({
      actionsLoading: state.emailTemplates.actionsLoading,
      emailTemplateEdit: state.emailTemplates.emailTemplateEdit,
      buildings: state.buildings.entities,
      error: state.emailTemplates.error,
      errorType: state.emailTemplates.errorType,
    }),
    shallowEqual
  );

  const uIContext = useEmailsUIContext();
  const uIProps = useMemo(() => {
    return {
      ids: uIContext.ids,
      setIds: uIContext.setIds,
      queryParams: uIContext.queryParams,
      selectedRows: uIContext.selectedRows,
      setSelectedRows: uIContext.setSelectedRows,
      setQueryParams: uIContext.setQueryParams,
      openEditEmailPage: uIContext.openEditEmailPage,
      openCloneEmailPage: uIContext.openCloneEmailPage,
      openDeleteEmailDialog: uIContext.openDeleteEmailDialog,
      openShowEmailPage: uIContext.openShowEmailPage,
      stateDataField: uIContext.stateDataField,
      changeHiddenColumns: uIContext.changeHiddenColumns,
    };
  }, [uIContext]);

  useEffect(() => {
    if (id) {
      if (mode === "edit") {
        dispatch(actions.editEmailTemplateById({ id: id }));
      } else if (mode === "clone") {
        dispatch(actions.cloneEmailTemplateById({ id: id }));
      }
    } else {
      dispatch(actions.newEmailTemplate());
    }
  }, [dispatch]);

  // server request for saving meterLogGovernment
  const saveEmailTemplate = (emailTemplate) => {
    // server request for creating meterLogGovernment
    dispatch(actions.createEmailTemplate(emailTemplate));
    uIProps.setIds([])
  };

  const handleFieldChange = (e, props) => { };
  UIHelpers.useDidMountEffect(() => {
    if (errorType === UIHelpers.ERROR_TYPES_OBJ.END) {
      uIProps.setIds && uIProps.setIds([]);
      uIProps.setSelectedRows && uIProps.setSelectedRows([]);
      dispatch(actions.fetchEmailTemplates(uIProps.queryParams));
    }

  }, [errorType, error]);


  return (
    <Formik
      enableReinitialize={true}
      initialValues={emailTemplateEdit}
      validationSchema={EmailTemplateSchema}
      onSubmit={(values) => {
        saveEmailTemplate(values);
      }}
    >
      {({ handleSubmit, handleChange, resetForm, ...props }) => (
        <>
          <div className="row form-group">
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.emailTemplates.code">
                {(msg) => (
                  <Field
                    name="code"
                    component={Input}
                    placeholder={msg}
                    label={msg}
                    required={true}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.emailTemplates.name">
                {(msg) => (
                  <Field
                    name="name"
                    component={Input}
                    placeholder={msg}
                    required={true}
                    label={msg}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-4 text-center">
              <FormattedMessage id="MODEL.UI.emailTemplates.isDefault">
                {(msg) => (
                  <Field
                    name="isDefault"
                    component={SwitchField}
                    placeholder={msg}
                    label={msg}
                    type={"checkbox"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-lg-12">
              <FormattedMessage id="MODEL.UI.emailTemplates.subject">
                {(msg) => (
                  <Field
                    name="subject"
                    component={Input}
                    placeholder={msg}
                    required={true}
                    label={msg}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>

          <div className="row form-group d-flex justify-content-between">
            <label className="d-flex align-items-end">
              <span className="ml-2">
                Nội dung <span className="required">*</span>
              </span>
            </label>
            <label>
              <span>
                <UIHelpers.ShowToken listToken={UIHelpers.listTokenEmail} />
              </span>
            </label>
            <div className="col-lg-12">
              <FormattedMessage id="MODEL.UI.emailTemplates.description">
                {(msg) => (
                  <Field
                    name="content"
                    component={TextareaField}
                    type={"textarea"}
                    required={true}
                    height={props.values?.description ? 350 : 250}
                    handleFieldChange={handleFieldChange}
                    formProps={props}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              handleClick={onHide}
              message="COMMONS.UI.Close"
              icon="flaticon-cancel icon-md"
              className="btn btn-light btn-elevate btn-md"
            />
            &nbsp;
            <Button
              isLoading={actionsLoading}
              handleClick={() => {
                handleSubmit();
              }
              }
              disabled={actionsLoading}
              message="COMMONS.UI.Save"
              icon="flaticon-edit-1 icon-md"
            />
          </div>
        </>
      )}
    </Formik>
  );
}
