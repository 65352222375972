import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./apiKeysCrud";
import { apiKeysSlice, callTypes } from "./apiKeysSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = apiKeysSlice;

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

// get key
export const getAPIKeyById = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAPIKeyById(id)
    .then((response) => {
      const apiKeyValue = response.data;
      const action = "keyAPIKey";
      dispatch(actions.apiKeyGettedById({ apiKeyValue, action }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllMeterLogsGovernment";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// show

export const resetAPIKeyById = (id, password) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .resetAPIKeyById(id, password)
    .then((response) => {
      const apiKeyValue = response.data;
      const action = "resetAPIKey";
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.reset" ></FormattedMessage>
      }
      dispatch(actions.apiKeyReseted({ apiKeyValue, action }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


export const updateIsActiveAPIKeyById = (id, params) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateIsActiveAPIKeyById(id, params)
    .then((response) => {
      const apiKeyValue = response.data;
      const action = "updateActiveAPIKey";
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      dispatch(actions.apiKeyReseted({ apiKeyValue, action }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// show
export const getShowAPIKeyById = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getShowAPIKeyById(id)
    .then((response) => {
      const apiKeyForShow = response.data;
      const action = "showAPIKey";
      dispatch(actions.apiKeyShow({ apiKeyForShow, action }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllMeterLogsGovernment";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// update
export const updateAPIKeyById = (apiKeyShow, id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateAPIKeyById(apiKeyShow, id)
    .then((response) => {
      const apiKeyUpdate = response.data;
      const action = "updateAPIKey";
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      dispatch(actions.apiKeyUpdate({ apiKeyUpdate, action }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateMeterLogGovernment";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// destroy
export const deleteAPIKeyById = (id, password) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteAPIKeyById(id, password)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }
      const action = "deleteAPIKey";
      dispatch(actions.apiKeyDeleted({ id, action }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteApiKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// index
export const getAllAPIKey = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllAPIKey()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.apiKeyFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getApiKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// store
export const createAPIKey = (apiKey) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createAPIKey(apiKey)
    .then((response) => {
      const apiKey = response.data;
      const action = "createAPIKey";
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }
      dispatch(actions.apiKeyCreated({ apiKey, action }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


// find
export const fetchAPIKeys = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findAPIKeys(queryParams)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.apiKeysFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "Can't find units";

      error.callType = callType;
      error.action = "fetchAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// edit
export const getEditAPIKeyById = (id) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getEditAPIKeyById(id)
    .then((response) => {
      const apiKeyEdit = response.data;
      const action = "editAPIKey";
      dispatch(actions.apiKeyEdit({ apiKeyEdit, action }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// clone
export const getCloneAPIKeyById = (id) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getCloneAPIKeyById(id)
    .then((response) => {
      const apiKeyClone = response.data;
      const action = "cloneAPIKey";
      dispatch(actions.apiKeyClone({ apiKeyClone, action }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


// create
export const getInitAPIKey = () => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getInitAPIKey()
    .then((response) => {
      const apiKeyNew = response.data;
      const action = "initAPIKey";
      dispatch(actions.apiKeyNew({ apiKeyNew, action }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getAPIKey";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
