/* eslint-disable no-unused-vars */
import React from "react";
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import { FormattedMessage } from "react-intl";

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "35", value: 35 },
      { text: "50", value: 50 },
    ],
  } = paginationProps;
  const style = {
    width: "75px",
  };

  const onSizeChange = (event) => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  const getShowingRows = (paginationProps) => {
    let start = 0;
    let end = 0;
    let total = 0;
    start = paginationProps && (paginationProps.sizePerPage * (paginationProps.page - 1) + 1);
    end = paginationProps && ((paginationProps.sizePerPage * paginationProps.page < paginationProps.totalSize) ? paginationProps.sizePerPage * paginationProps.page : paginationProps.totalSize);
    total = paginationProps && (paginationProps.totalSize);
    return `Hiển thị từ ${start > end ? 0 : start} đến ${end ? end : 0} của ${total ? total : 0}`
  }

  return (
    <>

      <div className="d-flex align-items-center">
        {isLoading && (
          <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">
              <FormattedMessage id="COMMONS.UI.Pagination.Loading"></FormattedMessage>
            </div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        )}
        <select
          disabled={totalSize === 0}
          className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize === 0 && "disabled"
            }`}
          onChange={onSizeChange}
          value={sizePerPage}
          style={style}
        >
          {sizePerPageList.map((option) => {
            const isSelect = sizePerPage === `${option.page}`;
            return (
              <option
                key={option.text}
                value={option.page}
                className={`btn ${isSelect ? "active" : ""}`}
              >
                {option.text}
              </option>
            );
          })}
        </select>
        {/* <PaginationTotalStandalone className="text-muted" {...paginationProps} /> */}
        <span>{getShowingRows(paginationProps)}</span>
      </div>

    </>
  );
}
