import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialUnitsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  unitForEdit: null,
  unitForShow: null,
  unitsIsRented: [],
  unitsIsEmpty: [],
  filterCount: null,
  assetsUnit: [],
  otherUnit: null,
  notification: null,
  currentUnit: null,
  currentUnits: [],
  error: null,
  isError: null,
  unitsCount: null,
  errorType: null,
  buildingsGrid: [],
  unitsAssets: [],
  totalBuilding: null,
  action: "",
  alert: { type: '', message: '' }
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",

  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const unitsSlice = createSlice({
  name: "units",
  initialState: initialUnitsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.alert = action.payload.alert
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.alert = { type: '', message: '' }
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.notification = null;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.totalBuilding = null;
      state.currentUnit = null;
      state.currentUnits = [];
      state.entities = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.unitForEdit = null;
    },

    resetCurrentUnits: (state, action) => {
      state.currentUnit = null;
      state.currentUnits = [];
    },
    // getUnitById
    unitFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.unitForEdit = action.payload.unitForEdit;
      state.error = null;

    },
    //show
    unitShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.unitForShow = action.payload.unitForShow;
      state.error = null;

    },
    // findAllUnits
    unitsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // findUnits
    unitsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount
    },
    otherUnitsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.otherUnit = entities;
      state.totalCount = totalCount;
    },

    unitImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },


    // findUnitsRentd
    unitsIsRentedFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.unitsIsRented = entities;
      state.totalCount = totalCount;
    },

    // findUnitsEmpty
    unitsIsEmptyFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.unitsIsEmpty = entities;
      state.totalCount = totalCount;
    },

    // findParentUnits
    unitsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createUnit
    unitCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      state.entities.unshift(action.payload.unit);
      state.currentUnit = action.payload.unit;
      state.currentUnits.push(action.payload.unit);
      state.unitsCount = state.unitsCount + 1;
    },
    // updateUnit
    unitUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.unit.id) {
          return action.payload.unit;
        }
        return entity;
      });
      state.currentUnit = action.payload.unit;
      state.currentUnits.push(action.payload.unit);

    },
    // deleteUnit
    unitDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.action = "delete"
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentUnit = null;
      state.currentUnits = [];

    },
    // deleteUnits
    unitsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentUnit = null;
      state.currentUnits = [];
    },
    // unitsUpdateState
    unitsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },


    unitsCount: (state, action) => {
      state.unitsCount = action.payload.unitsCount;
      state.actionsLoading = false;
    },
    assetsFetchedByUnitId: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { assets } = action.payload;
      state.assetsUnit = assets;
    },

    countUnitsClean: (state, action) => {

      state.unitsCount = 1;
    },

    unitsBuildingGrid: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.listLoading = false;
      state.buildingsGrid = action.payload.buildingsGrid;
      state.error = null;
      state.totalBuilding = action.payload.buildingsGrid ? action.payload.buildingsGrid.length : 0;
    },

    unitsFetchedAssets: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.unitsAssets = action.payload.unitsAssets;
      state.error = null;
    },

    unitImportFetched: (state, action) => {
      const { unitImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.unitImport = unitImport;
    },

    unitRealTimeGetted: (state, action) => {
      const { reportRealTime } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.reportRealTime = reportRealTime;
    },

    unitRealtimeUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.isError = false;
    },

    resetUnitImport: (state, action) => {
      state.unitImport = null;
    },
  },
});
