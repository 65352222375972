import axios from "axios";

export const SUPPORTS_URL = "/supports";

// CREATE =>  POST: add a new support to the server
export function createSupport(support) {
  return axios.post(SUPPORTS_URL, support);
}

export function createSupportContact(support) {
  return axios.post(`${SUPPORTS_URL}/contact`, support);
}

export function getInitSupport() {
  return axios.get(`${SUPPORTS_URL}/create`);
}

export function getInitSupportContact() {
  return axios.get(`${SUPPORTS_URL}/contact/create`);
}

// READ
export function getAllSupports() {
  return axios.get(SUPPORTS_URL);
}

export function getSupportById(supportId) {
  return axios.get(`${SUPPORTS_URL}/${supportId}`);
}

export function getSupportContactById(supportId) {
  return axios.get(`${SUPPORTS_URL}/${supportId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSupports(queryParams) {
  return axios.post(`${SUPPORTS_URL}/find`, queryParams);
}

export function findSupportsContact(queryParams) {
  return axios.post(`${SUPPORTS_URL}/contact/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateSupport(support, id) {
  return axios.put(`${SUPPORTS_URL}/${id}`, support);
}

export function updateSupportContact(support, id) {
  return axios.put(`${SUPPORTS_URL}/contact/${id}`, support);
}

// UPDATE Status
export function updateStatusForSupports(ids, status) {
  return axios.post(`${SUPPORTS_URL}/updateStatusForSupports`, {
    ids,
    status,
  });
}

// DELETE => delete the support from the server
export function deleteSupport(supportId) {
  return axios.delete(`${SUPPORTS_URL}/${supportId}`);
}

// DELETE Supports by ids
export function deleteSupports(ids) {
  return axios.delete(`${SUPPORTS_URL}/bulk`, { data: { ids } });

}
