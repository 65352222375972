import { useFormik } from "formik";
import { parseDomain } from "parse-domain";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { InformationRes } from "../../../../_bloomgoo/_partials/accordions/InformationRes";
import { ErrorMessages } from "../../../../_bloomgoo/_partials/controls";
import { useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import { register } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";



function SearchInvoices(props) {
  const { intl } = props;

  const refRecaptcha = React.useRef(null);
  const [recaptchaValid, setRecaptchaValid] = useState(false);

  const [isSuccessFulDialog, setSuccessFulDialog] = useState(false);
  const [isGoSignIn, setIsGoSignIn] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const SearchSchema = Yup.object().shape({
    organizationCode: Yup.string().trim().nullable().required(),
    customerCode: Yup.string().trim().nullable().required(),
    invoiceCode: Yup.string().trim().nullable().required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (
      getPropByString(formik.touched, fieldname) &&
      getPropByString(formik.errors, fieldname)
    ) {
      return "is-invalid";
    }
    if (
      getPropByString(formik.touched, fieldname) &&
      !getPropByString(formik.errors, fieldname)
    ) {
      return "is-valid";
    }

    return "";
  };

  const parseResult = parseDomain(window.location.host.split(":")[0]);
  const { subDomains } = parseResult;
  const initialValues = {
    organizationCode: subDomains === undefined || subDomains === null || subDomains.length === 0
      ? null
      : subDomains[subDomains.length - 1].toUpperCase(),
    customerCode: null,
    invoiceCode: null,
  };

  function getPropByString(obj, propString) {
    if (!propString) return obj;

    var prop,
      props = propString.split(".");
    //console.log("props: ", props)
    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];
      //console.log("prop: ", prop)
      var candidate = obj[prop];
      //console.log("obj: ", obj)
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  }

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: SearchSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      if (!recaptchaValid) {
        refRecaptcha.current.execute();
      } else {
        register(values.organization, values.user)
          .then(({ data: { accessToken } }) => {
            props.register(accessToken);
            disableLoading();
            setSubmitting(false);
            setSuccessFulDialog(true);
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              setError(
                (error.response &&
                  error.response.data &&
                  error.response.data.code) ||
                "errors"
              );
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
            setSubmitting(false);
            setRecaptchaValid(false);
          });
      }
    },
  });

  const onResolved = () => {
    disableLoading();
    setRecaptchaValid(true);
    formik.submitForm();
  }

  const [seconds, setSeconds] = useState(30);

  useDidMountEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("BOOOOM!");
      setSuccessFulDialog(false);
      setIsGoSignIn(true);
    }
    // const timer = setTimeout(() => {
    //   setSuccessFully(false);
    //   setIsGoSignIn(true);
    // }, 2000);
    // return () => {
    //   if (isSuccessFully) {
    //     clearTimeout(timer);
    //   }
    // };
  }, [isSuccessFulDialog, seconds]);

  return (
    <>
      {!isGoSignIn ? (
        <>
          <div
            style={{
              height: "300px",
              backgroundImage: 'url("/media/users/BG_Register.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="login-form login-signup pb-20 bg-white"
            style={{ zIndex: 1 }}
          >
            <div
              className="w-lg-800px bg-body rounded shadow-sm mx-auto overflow-auto bg-white"
              style={{ marginTop: "-95px" }}
            >
              <InformationRes title="Quy định" content="Quy định" />
              {error && (
                <ErrorMessages
                  type="danger"
                  message={
                    error && error.response
                      ? error.response.code
                      : "COMMONS.UI.Notification.Danger"
                  }
                  icon="flaticon-danger text-danger"
                />
              )}
              <form
                className="form p-10 p-lg-15"
                onSubmit={formik.handleSubmit}
              >
                <div className="mb-5 text-center">
                  <h1 className="text-dark mb-3">
                    <FormattedMessage id="AUTH.SEARCH.TITLE" />
                  </h1>
                </div>
                {/* <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img alt="Logo" src="/media/svg/brand-logos/google-icon.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Google</a>
            <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img alt="Logo" src="/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Facebook</a> */}
                <h3 className="mt-5 text-uppercase text-primary">
                  <span className="h6">THÔNG TIN TRA CỨU</span>
                </h3>
                <div className=" row col-lg-2">
                  <div className="col-lg-6 mb-5 border border-primary border-2 bg-primary "></div>
                </div>
                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mã tổ chức <span className="required">*</span>
                    </label>
                    <div className="input-group">

                      <input
                        type="text"
                        name="organizationCode"
                        autoComplete="off"
                        {...formik.getFieldProps("organizationCode")}
                        className={`form-control  ${getInputClasses(
                          "organizationCode"
                        )}`}
                        placeholder="Mã tổ chức"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          .chuoicanho.com
                        </span>
                      </div>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.code &&
                      formik.errors.organization.code ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.organization.code}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mã khách hàng <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập mã khách hàng"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "customerCode"
                        )}`}
                        name="customerCode"
                        autocomplete="off"
                        {...formik.getFieldProps("customerCode")}
                      />
                      <span>
                        <i className="fas flaticon-user icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.taxNumber &&
                      formik.errors.organization.taxNumber ? (
                      <div className="fv-plugins-message-container">
                        {/* <div className="fv-help-block">{formik.errors.organization.taxNumber}</div> */}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mã số bí mật <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập mã số bí mật"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "invoiceCode"
                        )}`}
                        name="invoiceCode"
                        autocomplete="off"
                        {...formik.getFieldProps("invoiceCode")}
                      />
                      <span>
                        <i className="flaticon-edit icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.address &&
                      formik.errors.organization.address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.organization.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row fv-row pt-7 d-flex justify-content-center">
                  <button
                    type="submit"
                    // onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate btn-md"
                  >
                    <i className="flaticon-search-magnifier-interface-symbol icon-md"></i>
                    Tìm kiếm
                  </button>
                </div>
                <hr />
                <div className="row fv-row d-flex justify-content-center">
                  <button
                    type="submit"
                    // onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate btn-md mr-5"
                  >
                    <i className="flaticon-download icon-md"></i>
                    Tải về
                  </button>
                  <button
                    type="submit"
                    // onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate btn-md ml-5"
                  >
                    <i className="flaticon2-printer icon-md"></i>
                    In
                  </button>

                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <Redirect to="/dang-nhap" />
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(SearchInvoices));
