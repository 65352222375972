/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Formik, Field, FieldArray } from "formik";
import { Table } from "react-bootstrap";
import * as Yup from "yup";
import {
  MoneyField,
  CheckboxField,
  Input,
  Card,
  CardHeader,
  CardBody
} from "../../../../_bloomgoo/_partials/controls";
import { useSettingsUIContext } from "../SettingsUIContext";
import * as actionsSettings from "../_redux/settings/settingsActions";
import { SettingCurrencyDialog } from "../setting-currencies-dialog/SettingCurrencyDialog";
import { checkIsActive, removeMoneyCommas } from "../../../../_bloomgoo/_helpers";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { isPrivilegeUserAction, isPrivilegeUserGroup, isPrivilegeUserPacket } from "../../../_helpers/PrivilegesUIHelper/User";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonCardMenu } from "../../../../_bloomgoo/_partials/button/ButtonCardMenu";
import { getHeaderSettings } from "../SettingsUIHelpers";

export default function SettingsCardCurrencies() {
  // Fields
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      tabPeronal2ButtonClick:
        settingsUIContext && settingsUIContext.tabPeronal2ButtonClick,
      tabFunctionsButtonClick:
        settingsUIContext && settingsUIContext.tabFunctionsButtonClick,
      tabEmailsButtonClick:
        settingsUIContext && settingsUIContext.tabEmailsButtonClick,
      tabDecentralizationButtonClick:
        settingsUIContext && settingsUIContext.tabDecentralizationButtonClick,
      tabCurrenciesButtonClick:
        settingsUIContext && settingsUIContext.tabCurrenciesButtonClick,
      tabBuildingButtonClick:
        settingsUIContext && settingsUIContext.tabBuildingButtonClick,
    };
  }, [settingsUIContext]);

  const dispatch = useDispatch();
  const { settingCurrencies, user } = useSelector(
    (state) => ({
      settingCurrencies: state.settings.settingCurrencies,
      user: state.auth.user
    }),
    shallowEqual
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(actionsSettings.fetchSettingCurrencies());
  }, [dispatch])

  const saveUserCurrencies = (values) => {
    //console.log("save Currencies")
    dispatch(actionsSettings.updateSettingCurrencies(values));
  };

  const handleFieldChange = (e, props) => {
    //console.log("value: ", e.target.value)
    //console.log("name: ", e.target.name)
    let fieldName = e.target.name;

    if (fieldName.includes('].isDefault')) {
      let selectedRowIndex = fieldName.replace('].isDefault', '').replace('currencies[', '');
      // eslint-disable-next-line array-callback-return
      props.values && props.values.currencies.map((item, index) => {
        if (index !== Number(selectedRowIndex)) {
          props.setFieldValue(`currencies[${index}].isDefault`, false)
        }
        else {
          props.setFieldValue(`currencies[${selectedRowIndex}].isDefault`, true)
        }
      })
    }
    if (fieldName.includes('].exchangeRate')) {
      let selectedRowIndex = fieldName.replace('].exchangeRate', '').replace('currencies[', '');
      props.setFieldValue(`currencies[${selectedRowIndex}].exchangeRate`, removeMoneyCommas(e.target.value));
    }
  };


  let currenciesArrayHelpers;
  const bindCurrenciesArrayHelpers = (arrayHelpers) => {
    currenciesArrayHelpers = arrayHelpers
  };

  let currencyArrayHelpers;

  const [isShowCurrencies, setIsShowCurrencies] = useState(false);
  function currenciesButtonClick() {
    setIsShowCurrencies(true);
  };



  const currenciesSchema = Yup.object().shape({
    currencies: Yup.array()
      .of(Yup.object().shape({
        exchangeRate: Yup.number().required()
      }))
  });


  let location = useLocation();



  return (
    <>
      <Card>
        <CardHeader>
          <div className="btn-group my-2" role="group" aria-label="...">
            {getHeaderSettings({ user, history, location })}
          </div>
        </CardHeader>
        <CardBody className="card-scroll">
          <div className="nav-tabs-filter">
          </div >
          <Formik
            enableReinitialize={true}
            initialValues={settingCurrencies}
            // initialValues={initCurrency}
            validationSchema={currenciesSchema}
            onSubmit={(values) => {
              saveUserCurrencies(values);
            }}
          >
            {({ handleSubmit, handleChange, handleReset, ...props }) => {
              function applyCurrencySelectedRows(selectedRows) {
                if (selectedRows && selectedRows.length > 0) {
                  // eslint-disable-next-line array-callback-return
                  selectedRows.map((row) => {
                    const CurrencyObj = {
                      currencyEmbed: {
                        code: row.code ? row.code : "",
                        id: row.id ? row.id : 0,
                        name: row.name ? row.name : "",
                      },
                      currencyId: row.id ? row.id : 0,
                      id: row.id ? row.id : 0,
                      exchangeRate: "",
                      isDefault: false,
                    };

                    let existsCurrency = false;
                    let existsCurrencies = false;
                    // eslint-disable-next-line array-callback-return
                    props.values.currency && props.values.currency.map((item) => {
                      if (item.currencyId === row.id) {
                        existsCurrency = true;
                      }
                    });
                    // eslint-disable-next-line array-callback-return
                    props.values.currencies && props.values.currencies.map((item) => {
                      if (item.currencyId === row.id) {
                        existsCurrencies = true;
                      }
                    });

                    if (!existsCurrency) {
                      props.values.oneCurrency && currencyArrayHelpers.replace(0, CurrencyObj);
                    }

                    if (!existsCurrencies) {
                      !props.values.oneCurrency && currenciesArrayHelpers.push(CurrencyObj);
                    }
                  });
                }
                setIsShowCurrencies(false);
              };
              return (
                <>
                  <SettingCurrencyDialog
                    currencies={props.values && props.values.currencies}
                    show={isShowCurrencies}
                    onHide={() => {
                      setIsShowCurrencies(false);
                    }}
                    applySelectedRows={applyCurrencySelectedRows}
                  />
                  <div className="modal-body container container-fluid px-40 mb-3">
                    <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center my-3">
                        <Button
                          className="btn btn-primary btn-md ml-2"
                          handleClick={currenciesButtonClick}
                          message="COMMONS.UI.CheckNew"
                          icon="flaticon-add icon-ms"
                          hidden={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_CREATE_WRITE")}
                        />
                      </div>

                      <div className="text-primary col-lg-12">
                        <FieldArray name="currencies">
                          {arrayHelpers => {
                            bindCurrenciesArrayHelpers(arrayHelpers);
                            return (
                              <>
                                <Table bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th className="text-center">#</th>
                                      <th className="text-center">Mã tiền tệ</th>
                                      <th className="text-center">Tên tiền tệ</th>
                                      <th className="text-center">Tỷ giá</th>
                                      <th className="text-center" style={{ minWidth: "50px" }}>Tiền chính</th>
                                      {isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_DELETE_WRITE") && props.values && props.values.currencies.length > 1 && <th></th>}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      props.values && props.values.currencies && props.values.currencies.map((item, rowIndex) => (
                                        <tr key={rowIndex}>
                                          <td className="text-center">{rowIndex + 1}</td>
                                          <td className="text-center">{item.currencyEmbed && item.currencyEmbed.code}</td>
                                          <td >{item.currencyEmbed && item.currencyEmbed.name}</td>
                                          <td >
                                            <Field
                                              name={`currencies[${rowIndex}].id`}
                                              type={"hidden"}
                                              component={Input}
                                            />
                                            {isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE") ?
                                              <Field
                                                name={`currencies[${rowIndex}].exchangeRate`}
                                                component={MoneyField}
                                                size={"sm"}
                                                type={"number"}
                                                withFeedbackLabel={false}

                                                onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                              />
                                              :
                                              <Field
                                                name={`currencies[${rowIndex}].exchangeRate`}
                                                component={MoneyField}
                                                className={"input-as-text bg-white"}
                                                disabled={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE")}
                                                size={"sm"}
                                                type={"number"}
                                              />}

                                          </td>
                                          <td className="checkbox-center" style={{ minWidth: "50px" }}>
                                            {isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE") ? <Field
                                              name={`currencies[${rowIndex}].isDefault`}
                                              value={item.isDefault}
                                              component={CheckboxField}
                                              size={"sm"}
                                              checked={item.isDefault}
                                              withFeedbackLabel={false}
                                              onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE")}
                                            /> : (item.isDefault ? <Field
                                              name={`currencies[${rowIndex}].isDefault`}
                                              value={item.isDefault}
                                              component={CheckboxField}
                                              size={"sm"}
                                              checked={item.isDefault}
                                              withFeedbackLabel={false}
                                              onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE")}
                                            /> : false)
                                            }

                                          </td>
                                          {isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_DELETE_WRITE") && !item.isDefault && !item.isPrimary && (
                                            <td className="text-center">
                                              <a
                                                id={`currencies[${rowIndex}].actionDelete`}
                                                name={`currencies[${rowIndex}].actionDelete`}
                                                className="btn btn-icon-danger btn-sm text-center"
                                                onClick={() => { arrayHelpers.remove(rowIndex) }}
                                              >
                                                <i className="flaticon-cancel pr-0"></i>
                                              </a>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center px-0 py-0">
                      {isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE") ?
                        <div className="row ml-2">
                          <span className="mr-2"
                          //  hidden={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE")}
                          >
                            Bạn có muốn sử dụng nhiều loại tiền tệ cho các phiếu
                          </span>
                          <Field
                            name="isMultiCurrency"
                            component={CheckboxField}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                          />
                        </div>
                        :
                        <div className="row ml-2">

                        </div>
                      }

                      <div className="mr-2">
                        <Button
                          className="btn btn-secondary btn-md"
                          handleClick={() => handleReset()}
                          message="COMMONS.UI.Reload"
                          icon="flaticon2-refresh-arrow icon-ms"
                        />
                        <Button
                          className="btn btn-primary btn-md ml-2"
                          handleClick={() => handleSubmit()}
                          message="COMMONS.UI.Save"
                          icon="flaticon-edit-1 icon-ms"
                          hidden={!isPrivilegeUserAction(user, "SETTINGS_CURRENCIES_UPDATE_WRITE")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
          </Formik>

        </CardBody>
      </Card>
    </>
  );
}
