import React from "react";
import { FormattedMessage } from "react-intl";

export const StatusAssetInventoryPeriodsCssClasses = ["", "success", "info"];
export const AssetInventoryStatusTitles = ["Selling", "Sold"];
export const AssetInventoryConditionCssClasses = ["success", "danger", ""];
export const AssetInventoryConditionTitles = ["New", "Used"];
export const StatusAssetInventoryConditionCssClasses = ["danger", "success"];
export const StatusAssetInventoryConditionTitles = ["Chưa thanh toán", "Đã thanh toán"];
export const StatusAssetInventoryPeriodsTitles = ["", "Đang mở", "Đang Đóng"];
export const IsUsingAssetInventoriesTitles = ["Trong kho", "Nơi sử dụng"];


export const IsActiveAssetInventoriesConditionCssClasses = ["success", "danger"];
export const IsActiveAssetInventoriesConditionTitles = ["Trong kho", "Nơi sử dụng"];
export const defaultSorted = [{ dataField: "openingDate", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const ASSETS_ISUSING_FILTER = [
  { value: false, label: "Trong kho" },
  { value: true, label: "Nơi sử dụng" }
]

export const STATUS_ASSET_INVENTORY_PERIODS_OBJ = {
  OPEN: 1,
  CLOSED: 2,
};
export const ASSET_INVENTORY_IS_USING_OBJ = {
  ALL: "",
  IS_USING: true,
  NOT_USING: false,
}
export const initAssetInventoryObj = {
  id: 0,
  organizationId: 0,
  assetInventoryPeriodId: 0,
  inventoryTargetType: 0,
  isUsing: false,
  buildingId: null,
  unitId: null,
  code: "string",
  name: "string",
  description: "string",
  userId: 0,
  registrationDate: "2022-01-14T07:54:25.378Z",
  inventoryDate: "2022-01-14T07:54:25.378Z",
  directorId: 0,
  chiefAccountantId: 0,
  headOfId: 0,
  inventoryUsers: "string",
  registrationDateString: "string",
  inventoryDateString: "string",
  assets: [
    {
      id: 0,
      organizationId: 0,
      assetInventoryId: 0,
      assetId: 0,
      assetCode: "",
      assetName: "",
      amount: 0,
      price: 0,
      remainPrice: 0,
      inventoryAmount: 0,
      inventoryPrice: 0,
      inventoryRemainPrice: 0,
      deviantAmount: 0,
      deviantPrice: 0,
      deviantRemainPrice: 0,
      description: ""
    }
  ]
};
export const ACTIVE_STATUSES = [
  { id: 1, name: <FormattedMessage id="COMMONS.UI.SELECT.Active"></FormattedMessage> },
  { id: 2, name: <FormattedMessage id="COMMONS.UI.SELECT.InActive"></FormattedMessage> }
]
export const ASSETCATEGORIE_STATUSES = [
  { id: 1, name: "Đã thanh toán" },
  { id: 0, name: "Chưa thanh toán" }
]
export const ASSET_INVENTORY_PERIODS_STATUS = [
  { id: 1, name: "Mở" },
  { id: 2, name: "Đóng" }
]
export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise",
];

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota",
];

export const dataFields = [
  'id',
  'organizationId',
  'code',
  'name',
  'address',
  'displayOrder',
  'description',
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
  'version',
  'action'
];


const ASSET_INVENTORIES_COLUMN_TOGGLE_CONFIG_KEY =
  process.env.ASSET_INVENTORIES_COLUMN_TOGGLE_CONFIG_KEY ||
  "AssetWareHouseColumnToggleConfig";

export function getColumnToggleConfig() {
  const ls = localStorage.getItem(ASSET_INVENTORIES_COLUMN_TOGGLE_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return {
    isUsing: false,
    unitId: false,
    inventoryDate: false,
    registrationDate: false,
    buildingId: false,
    userId: false,
    description: true,
    createdAt: true,
    createdBy: true,
    updatedAt: true,
    updatedBy: true,
  };
}

export function setColumnToggleConfig(key, value) {
  const assetWareHouseColumnToggleConfig = getColumnToggleConfig();
  assetWareHouseColumnToggleConfig[key] = value;

  localStorage.setItem(
    ASSET_INVENTORIES_COLUMN_TOGGLE_CONFIG_KEY,
    JSON.stringify(assetWareHouseColumnToggleConfig)
  );
}
