import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AttachmentsUIProvider } from "../attachments/AttachmentsUIContext";
import { Attachments } from "../attachments/Attachments";
import { Button } from "../../../../../../_bloomgoo/_partials/button/Button";

export function AttachmentsForm({
  isMulti,
  actionsLoading,
  onHide,
  applySelectedRows,
}) {

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <AttachmentsUIProvider selectedRows={selectedRows} setSelectedRows={setSelectedRows} isMulti={isMulti}>
          <Attachments />
        </AttachmentsUIProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          handleClick={onHide}
          message="COMMONS.UI.Close"
          icon="flaticon-cancel icon-md"
          className="btn btn-light btn-elevate btn-md"
        />
        &nbsp;
        <Button
          isLoading={actionsLoading}
          handleClick={() => applySelectedRows(selectedRows)}
          disabled={actionsLoading}
          message="COMMONS.UI.Save"
          icon="flaticon-edit-1 icon-md"
        />
      </Modal.Footer>
    </>
  );
}
