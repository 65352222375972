import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./usersCrud";
import { usersSlice, callTypes } from "./usersSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = usersSlice;

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentUsers = () => (dispatch) => {
  dispatch(actions.resetCurrentUsers());
};

export const fetchAllUsers = () => (dispatch) => {
  const callType = callTypes.list
  dispatch(actions.startCallParent({ callType: callType }));
  return requestFromServer
    .getAllUsers()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.usersFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllUsers";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUsers = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findUsers(queryParams)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalActives: response.data.totalActives,
        totalInActives: response.data.totalInActives,
        totalCollaborators: response.data.totalCollaborators
      }
      dispatch(actions.usersFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchUsers";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUserCollaborators = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUserCollaborators()
    .then((response) => {
      const userCollaborators = response.data.entities;
      dispatch(actions.userCollaboratorsFetched({ userCollaborators }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchUserCollaborators";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUsersByOrganizationId = (organizationId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findUsersByOrganizationId(organizationId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.usersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchUsersByOrganizationId";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


export const fetchParentUsers = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllUsers()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.usersParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentUsers";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const getUsers = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUserById(id)
    .then((response) => {
      const user = response.data;
      dispatch(actions.getUser({ userForShow: user }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getUsers";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUser = (id, mode) => (dispatch) => {
  const callType = callTypes.initialization
  if (!id) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitUser()
      .then((response) => {
        const user = response.data;
        dispatch(actions.userFetched({ userForEdit: user }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchUser";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode === "clone") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getUserCloneById(id)
        .then((response) => {
          const user = response.data;
          dispatch(actions.userFetched({ userForEdit: user }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchUser";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });

    }
    else
      if (mode && mode === "edit") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getUserEditById(id)
          .then((response) => {
            const user = response.data;
            dispatch(actions.userFetched({ userForEdit: user }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchUser";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
      else
        if (mode === "show") {
          dispatch(actions.startCall({ callType: callType }));
          return requestFromServer
            .getUserById(id)
            .then((response) => {
              const user = response.data;
              dispatch(actions.userFetched({ userForEdit: user }));
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else
                error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
              error.callType = callType;
              error.action = "fetchUser";
              error.status = "warning"
              dispatch(actions.catchError({ error, callType: callType }));
              dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
            });
        }
};

export const fetchUserProfile = (userId) => (dispatch) => {
  if (userId) {
    const callType = callTypes.action;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getUserProfileById(userId)
      .then((response) => {
        const user = response.data;
        dispatch(actions.userFetched({ userForEdit: user }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchUserProfile";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  }
};

export const fetchMyProfile = () => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getMyProfile()
    .then((response) => {
      const profile = response.data;
      dispatch(actions.myProfileFetched({ myProfile: profile }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchUserProfile";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUserByUserType = (id, mode, userType, callType) => (dispatch) => {
  if (!id) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitUserByUserType(userType)
      .then((response) => {
        const user = response.data;
        dispatch(actions.userFetched({ userForEdit: user }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchUserByUserType";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });

  } else
    if (mode === "clone") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getUserCloneById(id)
        .then((response) => {
          const user = response.data;
          dispatch(actions.userFetched({ userForEdit: user }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchUserByUserType";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    }
    else
      if (mode && mode === "edit") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getUserEditById(id)
          .then((response) => {
            const user = response.data;
            dispatch(actions.userFetched({ userForEdit: user }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchUserByUserType";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }


};

export const fetchUserBuildingPermission = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUserBuildingPermissionById(id)
    .then((response) => {
      const userPermission = response.data;

      dispatch(actions.userBuildingPermissionFetched({ userBuildingPermissionForEdit: userPermission }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchUserBuildingPermission";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateReferral = (id, referralCode) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateReferral(id, referralCode)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userUpdated({ user }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateReferral";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteUser = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteUser(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteUser";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const createUser = (userForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createUser(userForCreation)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userCreated({ user }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createUser";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateUser = (user, id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUser(user, id)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userUpdated({ user }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateUser";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const updateProfile = (user, id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateProfile(user, id)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userUpdated({ user }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateProfile";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};



export const updateUserBuildingPermission = (user) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUserBuildingPermission(user)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userBuildingPermissionUpdated({ user }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateUserBuildingPermission";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const updateUserPassword = (userId, password) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUserPassword(userId, password)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.userUpdatedPassword({ user }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateUserPassword";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateUsersActive = (ids, isActive) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateActiveForUsers(ids, isActive)
    .then((response) => {
      const activeIds = response.data.idsUpdated;
      const notification = {
        status: "success",
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.INVOICE.Update"
          values={{
            params: `'${activeIds.length}/${ids.length}'`,
          }}
        ></FormattedMessage >
      }
      dispatch(actions.usersActiveUpdated({ ids: activeIds, isActive }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateUsersActive";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteUsers = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteUsers(ids)
    .then(() => {
      dispatch(actions.usersDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteUsers";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUserImport = (file, callType = callTypes.initialization) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUserImport(file)
    .then((response) => {
      const user = response.data;

      dispatch(
        actions.userImportFetched({ userImport: user })
      );

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchMeterLog";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const resetUserImport = () => (dispatch) => {
  dispatch(actions.resetUserImport());
};

export const fetchUserImportStore = (file, callType = callTypes.initialization) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUserImportStore(file)
    .then((response) => {
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.IMPORT" values={{
          params: `'${response.data.successRecords}/${response.data.records}'`,
        }}></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(
        actions.userImportStoreFetched({ userImport: user })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchMeterLog";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const downloadUserExport = () => (dispatch) => {
  return requestFromServer
    .getUserExportExcel()
    .then((response) => {
      let blob = new Blob([response.data], { type: '.xlsx' }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Nguoi_dung.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.action = "downloadContractForm";
      error.status = "warning"
    });
};

export const downloadTemplateUserExport = () => (dispatch) => {
  return requestFromServer
    .getTemplateUserExportExcel()
    .then((response) => {
      let blob = new Blob([response.data], { type: '.xlsx' }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Tep_mau_nguoi_dung.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.action = "downloadContractForm";
      error.status = "warning"
    });
};