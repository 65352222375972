import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function AttachmentsDialogHeader() {

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = <FormattedMessage id="DOCUMENTS.UI.Title"></FormattedMessage>;
    setTitle(_title);
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg"><span className="flaticon-folder"> {title} </span></Modal.Title>
      </Modal.Header>
    </>
  );
}
