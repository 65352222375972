
export function getDataChartProfit(props) {
  if (
    Object.values(props).filter((ele) => ele === 0).length ===
    Object.keys(props).length
  ) {
    return Array.from({ length: Object.keys(props).length }, () => 1);
  } else {
    return Object.values(props);
  }
}
