/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import { Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  checkIsActive,
  uploadAttachmentData,
  uploadImageData,
} from "../../../../_bloomgoo/_helpers";
import { CroppedImg } from "../../../../_bloomgoo/_partials/accordions/CroppedImg";
import { ButtonCardMenu } from "../../../../_bloomgoo/_partials/button/ButtonCardMenu";
import { ButtonEdit } from "../../../../_bloomgoo/_partials/button/ButtonEdit";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  InputHover,
} from "../../../../_bloomgoo/_partials/controls";
import { SelectHover } from "../../../../_bloomgoo/_partials/controls/forms/SelectHover";
import {
  colorExcel,
  useDidMountEffect,
  UserGenderLabel,
  UserTypeTitles,
} from "../../../_helpers/_CommonUIHelpers";
import { getFilterConfig, setFilterConfig } from "../../../_helpers/LocalStorageConfig";
import * as actionsDepartment from "../../Departments/_redux/departments/departmentsActions";
import * as actionsDistricts from "../../Districts/_redux/districts/districtsActions";
import { ImageDocumnentsDialog } from "../../Organizations/pages/organizations/organization-documents-dialog/ImageDocumnentsDialog";
import * as actionsPositions from "../../Positions/_redux/positions/positionsActions";
import * as actionsProvinces from "../../Provinces/_redux/provinces/provincesActions";
import * as actionsRoles from "../../Roles/_redux/roles/rolesActions";
import {
  GENDER_ORGANIZATION,
} from "../../Users/pages/users/UsersUIHelpers";
import * as actionsUsers from "../../Users/_redux/users/usersActions";
import * as actionsWards from "../../Wards/_redux/wards/wardsActions";
import { useSettingsUIContext } from "../SettingsUIContext";
import * as auth from "./../../Auth/_redux/authRedux";
import PersonalPassword from "./PersonalPassword";
import { getHeaderSettings } from "../SettingsUIHelpers";
import { isPrivilegeUserAction, isUser } from "../../../_helpers/PrivilegesUIHelper/User";
import { isCollaborator } from "../../../_helpers/PrivilegesUIHelper/Collaborator";

// import AvatarEditor from "react-avatar-editor";

function SettingsCardPeronal(props) {
  // Fields
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      setQueryParams: settingsUIContext && settingsUIContext.setQueryParams,
      queryParams: settingsUIContext && settingsUIContext.queryParams,
      activeStatus: settingsUIContext && settingsUIContext.activeStatus,
      setActiveStatus: settingsUIContext && settingsUIContext.setActiveStatus,
      activeIsActive: settingsUIContext && settingsUIContext.activeIsActive,
      setSelectedRows: settingsUIContext && settingsUIContext.setSelectedRows,
      setActiveIsActive:
        settingsUIContext && settingsUIContext.setActiveIsActive,
      clearFilterVal: settingsUIContext && settingsUIContext.clearFilterVal,
      tabPeronal2ButtonClick:
        settingsUIContext && settingsUIContext.tabPeronal2ButtonClick,
      tabFunctionsButtonClick:
        settingsUIContext && settingsUIContext.tabFunctionsButtonClick,
      tabEmailsButtonClick:
        settingsUIContext && settingsUIContext.tabEmailsButtonClick,
      tabDecentralizationButtonClick:
        settingsUIContext && settingsUIContext.tabDecentralizationButtonClick,
      tabCurrenciesButtonClick:
        settingsUIContext && settingsUIContext.tabCurrenciesButtonClick,
      tabBuildingButtonClick:
        settingsUIContext && settingsUIContext.tabBuildingButtonClick,
    };
  }, [settingsUIContext]);

  const dispatch = useDispatch();

  const {
    user,
    userForEdit,
    userForShow,
    provinces,
    provinceForShow,
    districts,
    districtForShow,
    wards,
    positions,
    positionForShow,
    departments,
    departmentForShow,
    roles,
    roleForShow,
    wardForShow,
    myProfile,
    userBuildingPermissionForEdit,
  } = useSelector(
    (state) => ({
      user: state.auth.user,
      userForEdit: state.users.userForEdit,
      userForShow: state.users.userForShow,
      provinces: state.provinces.entities,
      provinceForShow: state.provinces.provinceForShow,
      districts: state.districts.entities,
      districtForShow: state.districts.districtForShow,
      wards: state.wards.entities,
      wardForShow: state.wards.wardForShow,
      positions: state.positions.entities,
      positionForShow: state.positions.positionForShow,
      roles: state.roles.entities,
      roleForShow: state.roles.roleForShow,
      departments: state.departments.entities,
      departmentForShow: state.departments.departmentForShow,
      userBuildingPermissionForEdit: state.users.userBuildingPermissionForEdit,
      myProfile: state.users.myProfile,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actionsUsers.fetchUser(user.id, "edit"));
    dispatch(actionsProvinces.fetchAllProvinces());
    dispatch(actionsRoles.fetchRolesByOrganizationId(user.organizationId));
    dispatch(
      actionsPositions.fetchPositionsByOrganizationId(user.organizationId)
    );
    dispatch(actionsUsers.fetchUserBuildingPermission(user.id));
    dispatch(
      actionsDepartment.fetchDepartmentsByOrganizationId(user.organizationId)
    );
  }, [user.id, dispatch]);

  useEffect(() => {
    userForEdit &&
      userForEdit.provinceId &&
      dispatch(
        actionsProvinces.getProvinceById(userForEdit && userForEdit.provinceId)
      );
    userForEdit &&
      userForEdit.provinceId &&
      dispatch(
        actionsDistricts.fetchDistrictsByProvinceId(
          userForEdit && userForEdit.provinceId
        )
      );
  }, [userForEdit && userForEdit.provinceId]);

  useEffect(() => {
    dispatch(
      actionsDistricts.getDistrictById(
        userForEdit?.districtId ? userForEdit?.districtId : 0
      )
    );
    dispatch(
      actionsWards.fetchWardsByDistrictId(
        userForEdit?.districtId ? userForEdit?.districtId : 0
      )
    );
  }, [userForEdit && userForEdit.districtId]);

  useEffect(() => {
    dispatch(
      actionsWards.getWardById(userForEdit?.wardId ? userForEdit?.wardId : 0)
    );
  }, [userForEdit && userForEdit.wardId]);

  useEffect(() => {
    userForEdit?.departmentId &&
      dispatch(actionsDepartment.getDepartmentById(userForEdit?.departmentId));
  }, [userForEdit && userForEdit.departmentId]);

  useEffect(() => {
    userForEdit?.roleId &&
      dispatch(actionsRoles.getRoleById(userForEdit?.roleId));
  }, [userForEdit && userForEdit.roleId]);

  useEffect(() => {
    userForEdit?.positionId &&
      dispatch(actionsPositions.getPositionById(userForEdit?.positionId));
  }, [userForEdit && userForEdit.positionId]);

  const [checkLength, setCheckLength] = useState(0);
  const [initPermission, setInitPermission] = useState(
    userBuildingPermissionForEdit?.buildings
  );

  useEffect(() => {
    setInitPermission(userBuildingPermissionForEdit?.buildings);
  }, [userBuildingPermissionForEdit, dispatch]);

  useEffect(() => {
    let _checkLength = 0;
    initPermission &&
      initPermission.map((item) => {
        if (item?.isSelected) {
          _checkLength += 1;
        }
        return "";
      });

    setCheckLength(_checkLength);
  }, [initPermission, dispatch]);

  const [fileImgSelected, setFileImgSelected] = useState({
    selectedAvatar: null,
  });

  useEffect(() => {
    setFilterConfig("activeBuildingId", myProfile?.activeBuildingId);
  }, [myProfile?.activeBuildingId]);

  const _setInitPermission = (e, index) => {
    let _initPermission = JSON.parse(JSON.stringify(initPermission));
    if (e.target.name === "isSelectedAll") {
      _initPermission &&
        _initPermission.map((row, rowIndex) => {
          _initPermission[rowIndex].isSelected = e.target.checked;
        });
    } else {
      _initPermission[index].isSelected = e.target.checked;
    }

    setInitPermission(_initPermission);
  };

  const saveUserBuildingPermission = (values) => {
    let _values = {
      id: userBuildingPermissionForEdit?.id,
      buildings: values,
    };
    dispatch(actionsUsers.updateUserBuildingPermission(_values)).then(() =>
      dispatch(actionsUsers.fetchMyProfile())
    );
  };

  const [avatarSelected, setLogoSelected] = useState({ selectedImage: null });
  const [fileSelected, setFileSelected] = useState({ selectedFile: null });
  const saveUser = (user) => {
    dispatch(
      actionsUsers.updateProfile(
        uploadImageData(fileImgSelected.selectedAvatar, user, 'avatar'), user.id
      )
    ).then(() => props.updateUser());
  };


  const persionalInforSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .nullable()
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.firstName"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    lastName: Yup.string()
      .trim()
      .nullable()
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.lastName"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    tel: Yup.string()
      .trim()
      .nullable()
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.tel"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      )
      .min(10)
      .max(10)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
    email: Yup.string()
      .trim()
      .nullable()
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      )
      .email(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.roleId"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    address: Yup.string()
      .trim()
      .nullable()
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.address"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    provinceId: Yup.number()
      .nullable()
      .min(1)
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.provinceId"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    districtId: Yup.number()
      .nullable()
      .min(1)
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.districtId"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
    wardId: Yup.number()
      .nullable()
      .min(1)
      .required(
        <FormattedMessage
          id="MODEL.UI.VALIDATE.Required"
          values={{
            params: (
              <FormattedMessage id="MODEL.UI.users.wardId"></FormattedMessage>
            ),
          }}
        ></FormattedMessage>
      ),
  });

  const [avatar, setAvatar] = useState();
  useEffect(() => {
    userForEdit &&
      userForEdit.avatar &&
      setAvatar(
        userForEdit.avatar
          ? `${Axios.defaults.cdnURL}/${userForEdit.avatar}`
          : ""
      );
  }, [userForEdit && userForEdit.avatar]);

  const [isShowImageDocuments, setIsImageShowDocuments] = useState(false);
  function SelectAvatarButtonClick() {
    setIsImageShowDocuments(true);
  }

  const selectAvatarDocument = (documentCode) => {
    props.setFieldValue("avatar", documentCode);
    setAvatar(`${Axios.defaults.cdnURL}/${documentCode}`);
    setIsImageShowDocuments(false);
  };

  const [editor, setEditor] = useState("");
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: null,
    nameImg: null,
  });

  const [activeEdit, setActiveEdit] = useState();
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(
    getFilterConfig().showUserUpdate
  );

  useEffect(() => {
    setIsShowUpdate(getFilterConfig().showUserUpdate);
  }, [getFilterConfig().showUserUpdate]);

  const handleFileChange = (e) => {
    setIsShowEdit(true);
    let url =
      e.target.files &&
      e.target.files[0] &&
      URL.createObjectURL(e.target.files && e.target.files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
      nameImg: e.target.files[0]?.name,
    });
  };

  useEffect(() => {
    console.log("fileImgSelected.selectedAvatar: ", fileImgSelected.selectedAvatar)
    fileImgSelected.selectedAvatar && saveUser(userForEdit)
  }, [fileImgSelected.selectedAvatar]);

  const [collapse, setCollapse] = useState({
    intro: true,
    owner: true,
    extend: true,
    extendIntro: true,
    extendDetail: true,
    extendPolicy: true,
    extendLatitude: true,
    decentralization: true,
    attachment: true,
  });

  const handleFieldChange = (e, props) => {
    let fieldName = e.target.name;

    if (fieldName === "firstName") {
      props.setFieldValue(
        "fullName",
        e.target.value +
        " " +
        (props.values.lastName ? props.values.lastName : "")
      );
    }

    if (fieldName === "lastName") {
      props.setFieldValue(
        "fullName",
        (props.values.firstName ? props.values.firstName : "") +
        " " +
        e.target.value
      );
    }
    if (fieldName === "provinceId") {
      let _forcusField = JSON.parse(JSON.stringify(userForEdit));
      for (const property in _forcusField) {
        if (
          property === "districtId" ||
          property === "wardId" ||
          property === fieldName
        )
          _forcusField[property] = true;
        else _forcusField[property] = false;
      }
      setForcusField(_forcusField);
      props.setFieldValue("districtId", 0);
      props.setFieldValue("wardId", 0);
      dispatch(actionsDistricts.fetchDistrictsByProvinceId(e.target.value));
      dispatch(actionsWards.fetchWardsByDistrictId(0));
    }

    if (fieldName === "districtId") {
      let _forcusField = JSON.parse(JSON.stringify(userForEdit));
      for (const property in _forcusField) {
        if (
          property === "provinceId" ||
          property === "wardId" ||
          property === fieldName
        )
          _forcusField[property] = true;
        else _forcusField[property] = false;
      }
      setForcusField(_forcusField);
      props.setFieldValue("wardId", 0);
      dispatch(actionsWards.fetchWardsByDistrictId(e.target.value));
    }
  };
  const handleCollapse = (name) => {
    const _collapse = { ...collapse };
    _collapse[name] = !_collapse[name];
    setCollapse(_collapse);
  };

  const getIconCollapse = (name) => {
    if (collapse[name])
      return (
        <i className="fas fa-caret-square-right mr-2 text-hover-primary" />
      );
    else return <i className="fas fa-caret-square-down mr-2 text-primary" />;
  };
  const getIconCollapse1 = (name) => {
    if (collapse[name])
      return (

        <i className="fas fa-caret-square-down mr-2 text-primary" />
      );
    else return <i className="fas fa-caret-square-right mr-2 text-hover-primary" />;
  };
  const updateInfo1 = (fieldName, fieldValue) => {
    let _userForEdit = JSON.parse(JSON.stringify(userForEdit));
    if (_userForEdit[`${fieldName}`] !== fieldValue) {
      if (fieldName === "provinceId") {
        _userForEdit.districtId = 0;
        _userForEdit.wardId = 0;
      } else if (fieldName === "districtId") {
        _userForEdit.wardId = 0;
      }
      _userForEdit[`${fieldName}`] = fieldValue;
      dispatch(
        actionsUsers.updateProfile(
          uploadAttachmentData(fileSelected.selectedFile, _userForEdit),
          _userForEdit?.id
        )
      ).then(() => props.updateUser());
    }
  };
  useDidMountEffect(() => {
    avatarSelected.selectedAvatar && saveUser(userForEdit);
  }, [avatarSelected.selectedAvatar]);

  const [forcusField, setForcusField] = useState(
    JSON.parse(JSON.stringify(userForEdit))
  );
  useEffect(() => {
    let _forcusField = JSON.parse(JSON.stringify(userForEdit));
    for (const property in _forcusField) {
      _forcusField[property] = false;
    }
    setForcusField(_forcusField);
    userForEdit && settingsUIProps.setSelectedRows([userForEdit]);
  }, [userForEdit]);

  const history = useHistory();
  let location = useLocation();


  const [tabEditDialog, setTabEditDialog] = useState();

  const [isSettingPassWord, setIsSettingPassWord] = useState(false)
  return (
    <>
      <CroppedImg
        isShowEdit={isShowEdit}
        setIsShowEdit={setIsShowEdit}
        setAvatar={setAvatar}
        setFileImgSelected={setFileImgSelected}
        editor={editor}
        setEditor={setEditor}
        setPicture={setPicture}
        picture={picture}
        typeImage="avatar"
      />

      {isShowImageDocuments && (
        <ImageDocumnentsDialog
          show={isShowImageDocuments}
          onHide={() => {
            setIsImageShowDocuments(false);
          }}
          selectFileDocument={selectAvatarDocument}
        />
      )}

      {tabEditDialog && (
        <PersonalPassword
          show={Boolean(tabEditDialog)}
          onHide={() => setTabEditDialog()}
          mode="edit"
          tabActive={tabEditDialog}
        />
      )}
      <Card>
        <CardHeader>
          <div className="btn-group my-2" role="group" aria-label="...">
            {getHeaderSettings({ user, history, location })}
          </div>
        </CardHeader>
        <CardBody className="card-scroll">
          <Formik
            enableReinitialize={true}
            initialValues={userForEdit}
            validationSchema={persionalInforSchema}
            onSubmit={(values) => {
              saveUser(values);
            }}
          >
            {({ errors, touched, handleChange, ...props }) => {
              // bindProps(props)
              return (
                <>
                  <div
                    className={"tab-pane fade show active"}
                    id={`tab_panel_1`}
                    role="tabpanel"
                    aria-labelledby={`tab_panel_1`}
                  >
                    <div className="group-border">
                      <div className="form-group d-flex mt-5">

                        <div className="col-lg-8">
                          <div
                            data-toggle="collapse"
                            href="#collapseDetail"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseDetail"
                            onClick={() => handleCollapse("info")}
                          >
                            {getIconCollapse("info")}
                            <b>Tổng quan</b>
                          </div>
                          <div className="collapse show" id="collapseDetail">
                            <div className="d-flex">
                              <div className="col-lg-3 ml-5">
                                <fieldset>
                                  <div
                                    className="image-input image-input-outline mt-4"
                                    id="kt_profile_avatar"
                                  >
                                    {avatar ? (
                                      <img
                                        className="image-input-avatar"
                                        src={`${avatar}`}
                                        alt=""
                                        onError={(e) =>
                                        (e.target.src =
                                          "/media/public/blank.png")
                                        }
                                      />
                                    ) : (
                                      <img
                                        className="image-input-avatar"
                                        src="/media/public/blank.png"
                                        alt=""
                                      />
                                    )}
                                    <Field
                                      name="avatar"
                                      type={"hidden"}
                                      component={Input}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                    <label

                                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                      data-action="change"
                                      data-toggle="tooltip"
                                      title=""
                                      data-original-title="Change avatar"
                                      hidden={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                    >
                                      <i className="fa fa-pen icon-sm text-input-hover"></i>
                                      <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e) => {
                                          handleFileChange(e);
                                        }}
                                      />
                                      <input
                                        type="hidden"
                                        name="profile_avatar_remove"
                                      />
                                    </label>
                                    <span
                                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                      data-action="cancel"
                                      data-toggle="tooltip"
                                      title=""
                                      data-original-title="Cancel avatar"
                                    >
                                      <i className="flaticon2-document icon-sm text-input-hover"></i>
                                    </span>
                                    <span
                                      hidden={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                      onClick={SelectAvatarButtonClick}
                                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                      data-action="remove"
                                      data-toggle="tooltip"
                                      title=""
                                      data-original-title="Remove avatar"
                                    >
                                      <i className="flaticon2-document icon-xs text-input-hover"></i>
                                    </span>
                                  </div>
                                  <span className="form-text text-input-hover mb-3">
                                    Cho phép loại tệp: png, jpg, jpeg.
                                  </span>
                                </fieldset>
                              </div>
                              <div className="col-lg-9 ">
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.firstName">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="firstName"
                                              component={InputHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={
                                                userForEdit?.firstName
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              textImportant={true}
                                              formProps={props}
                                              updateInfo={updateInfo1}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange(e, props);
                                              }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.lastName">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="lastName"
                                              component={InputHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={
                                                userForEdit?.lastName
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              textImportant={true}
                                              formProps={props}
                                              updateInfo={updateInfo1}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange(e, props);
                                              }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.tel">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>{msg}</span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="tel"
                                              component={InputHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={userForEdit?.tel}
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              formProps={props}
                                              updateInfo={updateInfo1}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange(e, props);
                                              }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.birthdayString">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="birthdayString"
                                              component={InputHover}
                                              placeholder={msg}
                                              type={"date"}
                                              defaultValue={
                                                userForEdit?.birthdayString
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              formProps={props}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange(e, props);
                                              }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.gender">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="gender"
                                              component={SelectHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={
                                                UserGenderLabel[
                                                userForEdit?.gender
                                                ]
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              formProps={props}
                                              options={
                                                GENDER_ORGANIZATION &&
                                                GENDER_ORGANIZATION.map(
                                                  function (item) {
                                                    return {
                                                      label: item.name,
                                                      value: item.id,
                                                    };
                                                  }
                                                )
                                              }
                                              handleFieldChange={
                                                handleFieldChange
                                              }
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.settings.email">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                            <span>{msg}</span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="email"
                                              component={InputHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={userForEdit?.email}
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              formProps={props}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange(e, props);
                                              }}
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />

                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.buildings.provinceId">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center  text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="provinceId"
                                              component={SelectHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={
                                                provinceForShow?.name
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              formProps={props}
                                              options={
                                                provinces &&
                                                provinces.map(function (item) {
                                                  return {
                                                    label: item.name,
                                                    value: item.id,
                                                  };
                                                })
                                              }
                                              handleFieldChange={
                                                handleFieldChange
                                              }
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.buildings.districtId">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center  text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="districtId"
                                              component={SelectHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={
                                                districtForShow?.name
                                              }
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              options={
                                                districts &&
                                                districts.map(function (item) {
                                                  return {
                                                    label: item.name,
                                                    value: item.id,
                                                  };
                                                })
                                              }
                                              formProps={props}
                                              handleFieldChange={
                                                handleFieldChange
                                              }
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 d-flex">
                                    <FormattedMessage id="MODEL.UI.buildings.wardId">
                                      {(msg) => (
                                        <>
                                          <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center  text-input-hover">
                                            <span>
                                              {msg}
                                              <span className="required h6">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-9">
                                            <Field
                                              name="wardId"
                                              component={SelectHover}
                                              placeholder={msg}
                                              type={"text"}
                                              defaultValue={wardForShow?.name}
                                              forcusField={forcusField}
                                              setForcusField={setForcusField}
                                              options={
                                                wards &&
                                                wards.map(function (item) {
                                                  return {
                                                    label: item.name,
                                                    value: item.id,
                                                  };
                                                })
                                              }
                                              formProps={props}
                                              handleFieldChange={
                                                handleFieldChange
                                              }
                                              disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </FormattedMessage>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <FormattedMessage id="MODEL.UI.settings.address">
                                    {(msg) => (
                                      <>
                                        <div className="col-lg-1 col-form-label text-lg-left d-flex align-items-center text-input-hover ">
                                          <span>
                                            {msg}
                                            <span className="required h6">
                                              {" "}
                                              *
                                            </span>
                                          </span>
                                        </div>
                                        <div className="col-lg-11 my-auto pl-13">
                                          <Field
                                            name="address"
                                            forcusField={forcusField}
                                            component={InputHover}
                                            setForcusField={setForcusField}
                                            formProps={props}
                                            placeholder={msg}
                                            type={"text"}
                                            defaultValue={userForEdit?.address}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleFieldChange(e, props);
                                            }}
                                            disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </FormattedMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="gutter-b"></div>
                          <div
                            data-toggle="collapse"
                            href="#collapseOrganization"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseOrganization"
                            onClick={() => handleCollapse("organization")}
                          >
                            {getIconCollapse("organization")}
                            <b>Tổ chức</b>
                          </div>
                          <div
                            className="collapse show"
                            id="collapseOrganization"
                          >
                            <div className="row d-flex ml-3">
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.department">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9">
                                        <Field
                                          name="departmentId"
                                          component={SelectHover}
                                          placeholder={msg}
                                          type={"text"}
                                          defaultValue={departmentForShow?.name}
                                          forcusField={forcusField}
                                          textImportant={true}
                                          setForcusField={setForcusField}
                                          options={
                                            departments &&
                                            departments.map(function (item) {
                                              return {
                                                label: item.name,
                                                value: item.id,
                                              };
                                            })
                                          }
                                          formProps={props}
                                          handleFieldChange={handleFieldChange}
                                          disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                        />
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.positionId">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9">
                                        <Field
                                          name="positionId"
                                          component={SelectHover}
                                          placeholder={msg}
                                          type={"text"}
                                          defaultValue={positionForShow?.name}
                                          textImportant={true}
                                          forcusField={forcusField}
                                          setForcusField={setForcusField}
                                          options={
                                            positions &&
                                            positions.map(function (item) {
                                              return {
                                                label: item.name,
                                                value: item.id,
                                              };
                                            })
                                          }
                                          formProps={props}
                                          handleFieldChange={handleFieldChange}
                                          disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                        />
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>
                            <div className="row d-flex ml-3">
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.roleId">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9 my-auto">
                                        <Field
                                          name="roleId"
                                          component={SelectHover}
                                          placeholder={msg}
                                          type={"text"}
                                          defaultValue={roleForShow?.name}
                                          forcusField={forcusField}
                                          setForcusField={setForcusField}
                                          options={
                                            roles &&
                                            roles.map(function (item) {
                                              return {
                                                label: item.name,
                                                value: item.id,
                                              };
                                            })
                                          }
                                          formProps={props}
                                          handleFieldChange={handleFieldChange}
                                          disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                        />
                                        <span></span>
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.displayOrder">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9">
                                        <Field
                                          name="displayOrder"
                                          component={InputHover}
                                          placeholder={msg}
                                          type={"number"}
                                          defaultValue={
                                            userForEdit?.displayOrder
                                          }
                                          forcusField={forcusField}
                                          setForcusField={setForcusField}
                                          formProps={props}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleFieldChange(e, props);
                                          }}
                                          disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                        />
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>
                          </div>
                          <div className="gutter-b"></div>
                          <div
                            data-toggle="collapse"
                            href="#collapseAccount"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseAccount"
                            onClick={() => handleCollapse("account")}
                          >
                            {getIconCollapse("account")}
                            <b>Tài khoản</b>
                          </div>
                          <div className="collapse show" id="collapseAccount">
                            <div className="row d-flex ml-2">
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.userType">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9 my-auto ml-5 font-weight-bold">
                                        <span>
                                          {
                                            UserTypeTitles[
                                            userForEdit?.userType
                                            ]
                                          }
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                              <div className="col-lg-6 d-flex">
                                <FormattedMessage id="MODEL.UI.settings.username">
                                  {(msg) => (
                                    <>
                                      <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                        <span>{msg} : </span>
                                      </div>
                                      <div className="col-lg-9 my-auto ml-5 font-weight-bold">
                                        <span>{userForEdit?.username}</span>
                                      </div>
                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                            </div>
                            {isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE") ?
                              <div className="ml-2 col-lg-6">
                                <FormattedMessage id="MODEL.UI.settings.password">
                                  {(msg) => (
                                    <>
                                      <div className="d-flex">
                                        <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                          <span>{msg}</span>
                                        </div>
                                        <div className="col-lg-9 my-auto ml-5">
                                          <b>********</b>
                                          <ButtonEdit action={() => setIsSettingPassWord(!isSettingPassWord)} />
                                        </div>
                                      </div>

                                      {isSettingPassWord ?
                                        <PersonalPassword
                                          onHide={() => setIsSettingPassWord(false)}
                                        />
                                        :
                                        <></>
                                      }

                                    </>
                                  )}
                                </FormattedMessage>
                              </div>
                              :
                              <></>
                            }
                          </div>
                          <div className="gutter-b"></div>
                        </div>
                        {(isUser(user) || isCollaborator(user)) ?
                          <div className="col-lg-4">
                            <div>
                              <div
                                data-toggle="collapse"
                                href="#collapseCommission"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseCommission"
                                onClick={() => handleCollapse("commission")}
                              >
                                {getIconCollapse("commission")}
                                <b>Thu nhập</b>
                              </div>
                              <div
                                className="collapse show ml-3"
                                id="collapseCommission"
                              >
                                <div className="row d-flex">
                                  <FormattedMessage id="MODEL.UI.settings.totalCommission">
                                    {(msg) => (
                                      <>
                                        <div className="col-lg-4 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                          <span className="ml-3">{msg} : </span>
                                        </div>
                                        <div className="col-lg-8 my-auto">
                                          <span>
                                            {" "}
                                            {userForShow?.totalCommission}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </FormattedMessage>
                                </div>
                                <div className="row d-flex">
                                  <FormattedMessage id="MODEL.UI.settings.totalWithdraw">
                                    {(msg) => (
                                      <>
                                        <div className="col-lg-4 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                          <span className="ml-3">{msg} : </span>
                                        </div>
                                        <div className="col-lg-8 my-auto">
                                          <span>
                                            {" "}
                                            {userForShow?.totalWithdraw}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </FormattedMessage>
                                </div>
                                <div className="row d-flex">
                                  <FormattedMessage id="MODEL.UI.settings.totalBalance">
                                    {(msg) => (
                                      <>
                                        <div className="col-lg-4 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                          <span className="ml-3">{msg} : </span>
                                        </div>
                                        <div className="col-lg-8 my-auto">
                                          <span>
                                            {" "}
                                            {userForEdit?.totalBalance}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </FormattedMessage>
                                </div>
                              </div>
                              <div className="gutter-b"></div>
                            </div>
                            {isUser(user) ?
                              <div>
                                <div
                                  data-toggle="collapse"
                                  href="#collapseDecentralization"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseDecentralization"
                                  onClick={() => handleCollapse("decentralization")}
                                >
                                  {getIconCollapse1("decentralization")}
                                  <b>Phân quyền</b>
                                </div>
                                <div
                                  className="collapse show ml-6 mt-2"
                                  id="collapseDecentralization"
                                >
                                  <div
                                    className="react-bootstrap-table table-responsive"
                                  >
                                    <Table
                                      bordered
                                      hover
                                      size="sm"
                                      className="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                                    >
                                      <thead
                                        style={{
                                          position: "sticky",
                                          top: "0px",
                                          zIndex: "1",
                                        }}
                                      >
                                        <tr
                                          style={{
                                            backgroundColor: `${colorExcel.blue}`,
                                          }}
                                        >
                                          <th className="text-center" rowSpan={2}>
                                            #
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            <FormattedMessage id="MODEL.UI.settings.buildings"></FormattedMessage>
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            Số tầng
                                          </th>
                                          <th className="text-center" hidden={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}>
                                            <FormattedMessage id="MODEL.UI.settings.buildingSelect"></FormattedMessage>
                                          </th>
                                        </tr>
                                        {isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE") ?
                                          <tr
                                            className="text-center"
                                            style={{
                                              backgroundColor: `${colorExcel.red}`,
                                            }}
                                          >
                                            <td className="d-flex justify-content-center">
                                              <label className="checkbox">
                                                <input
                                                  name={`isSelectedAll`}
                                                  type="checkbox"
                                                  className="form-control form-control-sm"
                                                  checked={
                                                    checkLength ===
                                                    initPermission?.length
                                                  }
                                                  onChange={(e) => {
                                                    _setInitPermission(e);
                                                    saveUserBuildingPermission(
                                                      initPermission
                                                    );
                                                  }}

                                                />
                                                <span className="my-1"></span>
                                              </label>
                                            </td>
                                          </tr>
                                          :
                                          <tr
                                            style={{
                                              backgroundColor: `${colorExcel.blue}`,
                                            }}
                                          ><td></td></tr>}

                                      </thead>
                                      <tbody>
                                        {initPermission &&
                                          initPermission.map((item, rowIndex) => (
                                            <tr key={rowIndex}>
                                              <td className="text-center">
                                                {rowIndex + 1}
                                              </td>
                                              <td>
                                                {item.building.level === 1
                                                  ? "-- " + item.building.name
                                                  : item.building.name}
                                              </td>
                                              <td className="text-center">
                                                {item.building.numberFloors}
                                              </td>
                                              <td className="text-center">
                                                <div className="d-flex justify-content-center">
                                                  <label className="checkbox">
                                                    <input
                                                      type="checkbox"
                                                      className="form-control form-control-sm"
                                                      name={`buildings[${rowIndex}].isSelected`}
                                                      checked={
                                                        initPermission[rowIndex]
                                                          .isSelected
                                                      }
                                                      onChange={(e) => {
                                                        _setInitPermission(
                                                          e,
                                                          rowIndex
                                                        );
                                                        saveUserBuildingPermission(
                                                          initPermission
                                                        );
                                                      }}
                                                      disabled={!isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")}
                                                    />
                                                    <span></span>
                                                  </label>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              :
                              <></>
                            }
                          </div>
                          :
                          <></>

                        }
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
}

export default connect(null, auth.actions)(SettingsCardPeronal);
