import * as requestFromServer from "./districtsCrud";
import { districtsSlice, callTypes } from "./districtsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = districtsSlice;

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentDistricts = () => (dispatch) => {
  dispatch(actions.resetCurrentDistricts());
};

export const fetchAllDistricts = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllDistricts()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.districtsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllDistricts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistrictsByProvinceId = (provinceId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDistrictsByProvinceId(provinceId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.districtsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistrictsByProvinceId";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistrictsByProvinceIdCountry = (provinceId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDistrictsByProvinceId(provinceId)
    .then((response) => {
      const entitiesCountry = response.data.entities;
      const totalCountCountry = response.data.totalCount;
      dispatch(actions.districtsFetchedCountry({ totalCountCountry, entitiesCountry }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistrictsByProvinceIdCountry";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistrictsByProvinceIdAddress = (provinceId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDistrictsByProvinceId(provinceId)
    .then((response) => {
      const entitiesAddress = response.data.entities;
      const totalCountAddress = response.data.totalCount;
      dispatch(actions.districtsFetchedAddress({ totalCountAddress, entitiesAddress }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistrictsByProvinceIdAddress";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistrictsByProvinceIdContact = (provinceId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDistrictsByProvinceId(provinceId)
    .then((response) => {
      const entitiesContact = response.data.entities;
      const totalCountContact = response.data.totalCount;
      dispatch(actions.districtsFetchedContact({ totalCountContact, entitiesContact }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistrictsByProvinceIdContact";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistricts = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDistricts(queryParams)
    // .getAllDistricts()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total
      }
      dispatch(actions.districtsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistricts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentDistricts = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllDistricts()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.districtsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentDistricts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchDistrict = (id, mode) => (dispatch) => {
  if (!id) {
    const callType = callTypes.action;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitDistrict()
      .then((response) => {
        const district = response.data;
        dispatch(
          actions.districtFetched({ districtForEdit: district })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchDistrict";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode && mode === "clone") {
      const callType = callTypes.action;
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getDistrictCloneById(id)
        .then((response) => {
          const district = response.data;
          dispatch(
            actions.districtFetched({ districtForEdit: district })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchDistrict";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    }
    else
      if (mode === "edit") {
        const callType = callTypes.action;
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getDistrictById(id)
          .then((response) => {
            const district = response.data;
            dispatch(
              actions.districtFetched({ districtForEdit: district })
            );
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchDistrict";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
};

export const getDistrictById = (id) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getDistrictById(id)
    .then((response) => {
      const district = response.data;
      dispatch(
        actions.districtShowFetched({ districtForShow: district })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDistrict";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteDistrict = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteDistrict(id)
    .then((response) => {
      dispatch(actions.districtDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteDistrict";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const createDistrict = (districtForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createDistrict(districtForCreation)
    .then((response) => {
      const district = response.data;
      dispatch(actions.districtCreated({ district }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createDistrict";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateDistrict = (district) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateDistrict(district)
    .then((response) => {
      const district = response.data;
      dispatch(actions.districtUpdated({ district }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateDistrict";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateDistrictsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForDistricts(ids, status)
    .then(() => {
      dispatch(actions.districtsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateDistrictsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteDistricts = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteDistricts(ids)
    .then(() => {
      dispatch(actions.districtsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteDistricts";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
