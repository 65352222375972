import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ, METER_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialReportsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  reportMeterLogByMonth: null,
  overviewReport: null,
  statisticsReport: null,
  debtsReport: null,
  tasksReport: null,
  entitiesTaskAssigness: null,
  entitiesDebtInvoicesBuildingId: null,
  overviewBuildingReport: null,
  entitiesAdminSummary: [],
  errorType: null,
  entitiesPrepays: [],
  entitiesPrepaysBuilding: [],
  entitiesMeterLogsElectricity: [],
  entitiesMeterLogsWater: [],
  entitiesMeterLogsGas: [],
  entitiesMeterLogsElectricityBuilding: [],
  entitiesMeterLogsWaterBuilding: [],
  entitiesMeterLogsGasBuilding: [],
  entitiesDebts: [],
  entitiesDebtsBuildings: [],
  entitiesAssetWareHouses: [],
  entitiesAssetWareHousesBuildings: [],
  entitiesAssetWareHousesBuildingId: [],
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },

    dashboardFetchedStatistics: (state, action) => {
      const { entitiesStatistics } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesStatistics = entitiesStatistics;
    },

    dashboardFetchedEmptyRates: (state, action) => {
      const { entitiesEmptyRates } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesEmptyRates = entitiesEmptyRates;
    },

    dashboardFetchedDebts: (state, action) => {
      const { entitiesDebts } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesDebts = entitiesDebts;
    },

    dashboardFetchedTasks: (state, action) => {
      const { entitiesTasks } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesTasks = entitiesTasks;
    },


    dashboardFetchedStatisticsBuilding: (state, action) => {
      const { entitiesStatisticsBuilding } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesStatisticsBuilding = entitiesStatisticsBuilding;
    },

    dashboardFetchedDebtsBuilding: (state, action) => {
      const { entitiesDebtsBuilding } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesDebtsBuilding = entitiesDebtsBuilding;
    },

    dashboardFetchedRevenueAndCost: (state, action) => {
      const { entitiesRevenueAndCost } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesRevenueAndCost = entitiesRevenueAndCost;
    },

    dashboardFetchedProfit: (state, action) => {
      const { entitiesProfit } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesProfit = entitiesProfit;
    },

    dashboardFetchedInvoices: (state, action) => {
      const { entitiesInvoices } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesInvoices = entitiesInvoices;
    },

    dashboardFetchedRestService: (state, action) => {
      const { entitiesRestService } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesRestService = entitiesRestService;
    },

    dashboardFetchedCustomerService: (state, action) => {
      const { entitiesCustomerService } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesCustomerService = entitiesCustomerService;
    },

    dashboardFetchedAccount: (state, action) => {
      const { entitiesAccount } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesAccount = entitiesAccount;
    },

    dashboardFetchedSummary: (state, action) => {
      const { entitiesSummary } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesSummary = entitiesSummary;
    },

    dashboardFetchedCurrentProfit: (state, action) => {
      const { entitiesCurrentProfit } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesCurrentProfit = entitiesCurrentProfit;
    },

    dashboardFetchedService: (state, action) => {
      const { entitiesService } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesService = entitiesService;
    },

    dashboardFetchedOverview: (state, action) => {
      const { entitiesOverview } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesOverview = entitiesOverview;
    },

    dashboardFetchedDebtInvoices: (state, action) => {
      const { entitiesDebtInvoices } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesDebtInvoices = entitiesDebtInvoices;
    },

    dashboardFetchedDebtInvoicesBuildingId: (state, action) => {
      const { entitiesDebtInvoicesBuildingId } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesDebtInvoicesBuildingId = entitiesDebtInvoicesBuildingId;
    },
    // findReports
    //DEBTS
    reportDebtsFetched: (state, action) => {
      const { debtsReport } = action.payload;
      state.listLoading = false;
      state.debtsReport = debtsReport;
      state.error = null;

    },
    //TASKS
    reportTasksFetched: (state, action) => {
      const { tasksReport } = action.payload;
      state.listLoading = false;
      state.tasksReport = tasksReport;
      state.error = null;

    },
    //OVERVIEW
    reportOverviewFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { overviewReport } = action.payload;
      state.listLoading = false;
      state.overviewReport = overviewReport;
      state.error = null;

    },

    dashboardFetchedVoucherCategory: (state, action) => {
      const { entitiesVoucherCategory } = action.payload;
      state.listLoading = false;
      state.entitiesVoucherCategory = entitiesVoucherCategory;
      state.error = null;

    },

    //STATICS
    reportStatisticsFetched: (state, action) => {
      const { statisticsReport } = action.payload;
      state.listLoading = false;
      state.statisticsReport = statisticsReport;
      state.error = null;

    },

    reportServiceBuildingFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { overviewBuildingReport } = action.payload;
      state.listLoading = false;
      state.overviewBuildingReport = overviewBuildingReport;
      state.error = null;

    },

    // findParentReports
    reportsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createReport
    reportCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.entities.unshift(action.payload.report);
    },
    // updateReport
    reportUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.report.id) {
          return action.payload.report;
        }
        return entity;
      });
    },
    // deleteReport
    reportDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteReports
    reportsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // reportsUpdateState
    reportsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    reportTaskAssigneeFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { entitiesTaskAssigness } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesTaskAssigness = entitiesTaskAssigness;
    },

    reportTaskFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { entitiesTask } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesTask = entitiesTask;

    },
    // ------------------ dashboard admin ----------------------- //
    dashboardFetchedAdminSummary: (state, action) => {
      //console.log("entitiesAdminSummary: ", action.payload.entitiesAdminSummary)
      const { entitiesAdminSummary } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAdminSummary = entitiesAdminSummary;
    },

    deskFetchedUnit: (state, action) => {
      const { entitiesUnitsDesk } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesUnitsDesk = entitiesUnitsDesk;
    },

    deskFetchedPrepays: (state, action) => {
      const { entitiesPrepays } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesPrepays = entitiesPrepays;
    },

    reportFetchedPrepaysBuilding: (state, action) => {
      const { entitiesPrepaysBuilding } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesPrepaysBuilding = entitiesPrepaysBuilding;
    },

    reportFetchedMeterLogs: (state, action) => {
      const { entitiesMeterLogs, meterType } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesMeterLogsElectricity = meterType === METER_TYPES_OBJ.ELECTRICITY ? entitiesMeterLogs : state.entitiesMeterLogsElectricity;
      state.entitiesMeterLogsWater = meterType === METER_TYPES_OBJ.WATER ? entitiesMeterLogs : state.entitiesMeterLogsWater;
      state.entitiesMeterLogsGas = meterType === METER_TYPES_OBJ.GAS ? entitiesMeterLogs : state.entitiesMeterLogsGas;
    },

    reportFetchedMeterLogByYear: (state, action) => {
      const { reportMeterLogByYear } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reportMeterLogByYear = reportMeterLogByYear;
    },

    reportFetchedMeterLogsBuildingId: (state, action) => {
      const { entitiesMeterLogsBuilding, meterType } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesMeterLogsElectricityBuilding = meterType === METER_TYPES_OBJ.ELECTRICITY ? entitiesMeterLogsBuilding : state.entitiesMeterLogsElectricityBuilding;
      state.entitiesMeterLogsWaterBuilding = meterType === METER_TYPES_OBJ.WATER ? entitiesMeterLogsBuilding : state.entitiesMeterLogsWaterBuilding;
      state.entitiesMeterLogsGasBuilding = meterType === METER_TYPES_OBJ.GAS ? entitiesMeterLogsBuilding : state.entitiesMeterLogsGasBuilding;
    },

    reportFetchedAssetWareHousesBuildings: (state, action) => {
      const { entitiesAssetWareHousesBuildings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAssetWareHousesBuildings = entitiesAssetWareHousesBuildings
    },

    reportFetchedAssetWareHouses: (state, action) => {
      const { entitiesAssetWareHouses } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAssetWareHouses = entitiesAssetWareHouses
    },

    reportFetchedAssetWareHousesBuildingId: (state, action) => {
      const { entitiesAssetWareHousesBuildingId } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAssetWareHousesBuildingId = entitiesAssetWareHousesBuildingId;
    },


    reportFetchedAssets: (state, action) => {
      const { entitiesAssets } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAssets = entitiesAssets;
    },

    reportFetchedDebts: (state, action) => {
      const { entitiesDebts } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesDebts = entitiesDebts;
    },


    reportFetchedDebtsBuildingId: (state, action) => {
      const { entitiesDebtsBuildings } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesDebtsBuildings = entitiesDebtsBuildings;
    },

    reportFetchedCustomersDemographic: (state, action) => {
      const { entitiesCustomersDemographic } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesCustomersDemographic = entitiesCustomersDemographic;
    },

    reportFetchedAssetUnits: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { entitiesAssetUnits } = action.payload;
      state.listLoading = false;
      state.entitiesAssetUnits = entitiesAssetUnits;
      state.error = null;

    },
  },
});
