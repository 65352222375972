import { createSlice } from "@reduxjs/toolkit";
import { CUSTOMER_TYPES_OBJ, ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCustomerProfilesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  customerProfileForEdit: null,
  customerProfileForShow: null,
  currentCustomerProfile: null,
  currentCustomerProfiles: [],
  notification: null,
  excludedEntities: [],
  excludedTotalCount: 0,
  error: null,
  isError: null,
  errorType: null,
  customerProfileIndividualImport: null,
  customerProfileOrganizationImport: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const customerProfilesSlice = createSlice({
  name: "customerProfiles",
  initialState: initialCustomerProfilesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCustomerProfile = null;
      state.currentCustomerProfiles = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.customerProfileForEdit = null;
    },

    resetCurrentCustomerProfiles: (state, action) => {
      state.currentCustomerProfile = null;
      state.currentCustomerProfiles = [];
    },

    // getCustomerProfileById
    customerProfileFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerProfileForEdit = action.payload.customerProfileForEdit;
      state.error = null;

    },
    //show
    customerProfileShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerProfileForShow = action.payload.customerProfileForShow;
      state.error = null;

    },
    // findAllCustomerProfiles
    customerProfilesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findCustomerProfiles
    customerProfilesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },

    customerProfilesFetchedExclude: (state, action) => {
      //console.log("Slice")
      const { excludedTotalCount, excludedEntities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.excludedEntities = excludedEntities;
      state.excludedTotalCount = excludedTotalCount;
    },
    // findParentCustomerProfiles
    customerProfilesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createCustomerProfile
    customerProfileCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.currentCustomerProfile = action.payload.customerProfile;
      state.currentCustomerProfiles.push(action.payload.customerProfile);
      state.entities.unshift(action.payload.customerProfile);
      state.excludedEntities.unshift(action.payload.customerProfile);
    },
    // updateCustomerProfile
    customerProfileUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.customerProfile.id) {
          return action.payload.customerProfile;
        }
        return entity;
      });
      state.currentCustomerProfile = action.payload.customerProfile;
      state.currentCustomerProfiles.push(action.payload.customerProfile);

    },
    // deleteCustomerProfile
    customerProfileDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);

    },
    // deleteCustomerProfiles
    customerProfilesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
      state.currentCustomerProfile = null;
      state.currentCustomerProfiles = [];

    },
    // customerProfilesUpdateState
    customerProfilesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    customerProfileImportFetched: (state, action) => {
      const { customerProfileImport, customerType } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.customerProfileIndividualImport = (customerType === CUSTOMER_TYPES_OBJ.INDIVIDUAL) ? customerProfileImport : state.customerProfileIndividualImport;
      state.customerProfileOrganizationImport = (customerType === CUSTOMER_TYPES_OBJ.ORGANIZATION) ? customerProfileImport : state.customerProfileOrganizationImport;
    },

    customerProfileImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },

    resetCustomerProfileImport: (state, action) => {
      action.payload === CUSTOMER_TYPES_OBJ.INDIVIDUAL && (state.customerProfileIndividualImport = null);
      action.payload === CUSTOMER_TYPES_OBJ.ORGANIZATION && (state.customerProfileOrganizationImport = null);
    },
  }
});
