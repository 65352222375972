import React from "react";
// import { OverviewLoadingDialog } from "./overview-loading-dialog/OverviewLoadingDialog";
import SettingsCardBuildings from "./SettingsCardBuildings";

export default function SettingsBuildingsPage({ history }) {
  return (
    <>
      {/* <OverviewLoadingDialog /> */}
      <SettingsCardBuildings />
    </>
  );
}
