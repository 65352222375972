import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./positionsCrud";
import { positionsSlice, callTypes } from "./positionsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = positionsSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};


export const resetCurrentPositions = () => (dispatch) => {
  dispatch(actions.resetCurrentPositions());
};
// Area actions fetch list

export const fetchAllPositions = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllPositions()
    .then((response) => {
      const entities = response.data.entities;
      const total = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalOthers: response.data.totalOthers,
        totalPrimary: response.data.totalPrimary,
      }
      dispatch(actions.positionsFetchedAll({ total, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllPositions";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentPositions = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllPositions()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.positionsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentPositions";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list

export const fetchPositions = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findPositions(queryParams)
    // .getAllPositions()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalOthers: response.data.totalOthers,
        totalPrimary: response.data.totalPrimary,
      }
      dispatch(actions.positionsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchPositions";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions get item

export const getPositionById = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getPositionById(id)
    .then((response) => {
      const position = response.data;
      dispatch(actions.getPosition({ positionForShow: position }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getPositions";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const fetchPositionsByOrganizationId = (organizationId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findPositionsByOrganizationId(organizationId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.positionsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchPositionsByOrganizationId";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const fetchPosition = (id, mode) => (dispatch) => {
  const callType = callTypes.initialization;
  if (!id || (mode === " new" && mode === "newPosition")) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitPosition()
      .then((response) => {
        const position = response.data;
        dispatch(actions.positionFetched({ positionForEdit: position }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchPosition";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (id && mode && mode === "clone") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getPositionCloneById(id)
        .then((response) => {
          const position = response.data;
          dispatch(actions.positionFetched({ positionForEdit: position }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchPosition";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    }
    else
      if (mode && mode === "edit") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getPositionEditById(id)
          .then((response) => {
            const position = response.data;
            dispatch(actions.positionFetched({ positionForEdit: position }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchPosition";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
      else
        if (mode === "show") {
          dispatch(actions.startCall({ callType: callType }));
          return requestFromServer
            .getPositionById(id)
            .then((response) => {
              const position = response.data;
              dispatch(actions.positionFetched({ positionForEdit: position }));
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else
                error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
              error.callType = callType;
              error.action = "fetchPosition";
              error.status = "warning"
              dispatch(actions.catchError({ error, callType: callType }));
              dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
            });
        }
};

// Area actions create, update item

export const createPosition = (positionForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createPosition(positionForCreation)
    .then((response) => {
      const position = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.positionCreated({ position }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createPosition";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updatePosition = (position) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updatePosition(position)
    .then((response) => {
      const position = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.positionUpdated({ position }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updatePosition";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updatePositionsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForPositions(ids, status)
    .then(() => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.positionsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updatePositionsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item

export const deletePositions = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deletePositions(ids)
    .then(() => {
      dispatch(actions.positionsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deletePositions";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deletePosition = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deletePosition(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.positionDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deletePosition";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
