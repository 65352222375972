import moment from "moment";

export const DatetimeFormatter = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("HH:mm:ss DD-MM-YYYY ") : "";
};

export const DatetimeColumnFormatter = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("DD-MM-YYYY HH:mm:ss ") : "";
};

export const DateColumnFormatter = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("DD-MM-YYYY") : "";
};