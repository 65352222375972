// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { FieldForm } from "../support-form/FieldForm";
import { FormattedMessage } from "react-intl";
import { ErrorMessages } from "../../../../../../_bloomgoo/_partials/controls";
import { SupportDrawerEditDialogHeader } from "./SupportDrawerEditDialogHeader";
import { Button, Drawer, Space, Skeleton } from "antd";
import { SaveOutlined } from "@ant-design/icons";

// Validation schema
const SupportEditSchema = Yup.object().shape({
  name: Yup.string()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.supports.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  contactTel: Yup.string()
    .trim()
    .nullable()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: <FormattedMessage id="MODEL.UI.users.tel"></FormattedMessage>,
        }}
      ></FormattedMessage>
    ),

  contactEmail: Yup.string()
    .trim()
    .nullable(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    )
    .email(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Error"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    )
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),

  contactName: Yup.string()
    .trim()
    .nullable(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    )
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.supports.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  description: Yup.string()
    .trim()
    .nullable(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.users.email"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    )
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.supports.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
});

export function SupportDrawerEditForm({
  saveSupport,
  support,
  parents,
  actionsLoading,
  users,
  onHide,
  mode,
  comment,
  fileSelected,
  handleFieldChange,
  setFileSelected,
  error,
  show,
  id,
}) {
  const [supportKey, setSupportsKey] = useState("supports");
  const isShowErrorMessages = (error) => {
    const actions = [
      "fetchInitComments",
      "fetchSupport",
      "fetchSupportContact",
      "fetchInitSupports",
      "fetchInitSupportsContact",
      "createSupport",
      "updateSupport",
    ];
    if (error && actions.includes(error.action)) {
      return true;
    } else return false;
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={support}
        validationSchema={SupportEditSchema}
        onSubmit={(values) => saveSupport(values)}
      >
        {({ handleSubmit, handleChange, ...props }) => (
          <>
            <Drawer
              size="large"
              title={<SupportDrawerEditDialogHeader id={id} mode={mode} />}
              open={show}
              placement={"left"}
              onClose={onHide}
              bodyStyle={{ padding: "0px 10px 0px 15px" }}
              headerStyle={{ padding: 0 }}
              footer={
                <Space size={3}>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    icon={<SaveOutlined />}
                  >
                    Lưu lại
                  </Button>
                  <Button type="text">Đóng</Button>
                </Space>
              }
            >
              {actionsLoading ? (
                <Skeleton active className="mt-3" />
              ) : (
                <>
                  {error &&
                    ((error.response === !undefined ||
                      error.response === !null) &&
                    isShowErrorMessages(error) ? (
                      <ErrorMessages
                        type="danger"
                        message={
                          error && error.response.data
                            ? error.response.data.code
                            : "COMMONS.UI.Notification.Danger"
                        }
                        icon="flaticon-danger text-danger"
                      />
                    ) : (
                      <ErrorMessages
                        type="danger"
                        message={error && error.clientMessage}
                        icon="flaticon-danger text-danger"
                      />
                    ))}
                  <Form className="form form-label-right">
                    <FieldForm
                      parents={parents}
                      support={support || []}
                      users={users}
                      handleFieldChange={handleFieldChange}
                      handleChange={handleChange}
                      props={props}
                      mode={mode}
                      comment={comment || []}
                      fileSelected={fileSelected}
                      setFileSelected={setFileSelected}
                      supportKey={supportKey}
                      setSupportsKey={setSupportsKey}
                    ></FieldForm>
                  </Form>
                </>
              )}
            </Drawer>
          </>
        )}
      </Formik>
    </>
  );
}
