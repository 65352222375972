import axios from "axios";

export const POSITIONS_URL = "/positions";

// CREATE =>  POST: add a new position to the server
export function createPosition(position) {
  return axios.post(POSITIONS_URL, position);
}

// READ
export function getAllPositions() {
  return axios.get(POSITIONS_URL);
}

export function getInitPosition() {
  return axios.get(`${POSITIONS_URL}/create`);
}
export function findPositionsByOrganizationId(organizationId) {
  return axios.get(`${POSITIONS_URL}/organization/${organizationId}`);
}


export function getPositionCloneById(positionId) {
  return axios.get(`${POSITIONS_URL}/${positionId}/clone`);
}
export function getPositionById(positionId) {
  return axios.get(`${POSITIONS_URL}/${positionId}`);
}
export function getPositionEditById(positionId) {
  return axios.get(`${POSITIONS_URL}/${positionId}/edit`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPositions(queryParams) {
  return axios.post(`${POSITIONS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updatePosition(position) {
  return axios.put(`${POSITIONS_URL}/${position.id}`, position);
}

// UPDATE Status
export function updateStatusForPositions(ids, status) {
  return axios.post(`${POSITIONS_URL}/updateStatusForPositions`, {
    ids,
    status,
  });
}

// DELETE => delete the position from the server
export function deletePosition(positionId) {
  return axios.delete(`${POSITIONS_URL}/${positionId}`);
}

// DELETE Positions by ids
export function deletePositions(ids) {
  return axios.delete(`${POSITIONS_URL}/bulk`, { data: { ids } });

}
