/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_bloomgoo/layout";
import { Client } from "@stomp/stompjs";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { Toasts } from "../_bloomgoo/_partials/toasts-error/Toasts";
import { useSelector } from "react-redux";
import {
  isPrivilegeUserAction,
  isUser,
  isPrivilegeUserPacket,
} from "./_helpers/PrivilegesUIHelper/User";
import PersonalPage from "./modules/Settings/settings-personal/PersonalPage";
import DecentralizationPage from "./modules/Settings/settings-decentralization/DecentralizationPage";
import FunctionsPage from "./modules/Settings/settings-functions/FunctionsPage";
import SettingsAPIKeysPage from "./modules/Settings/settings-api-keys/SettingsAPIKeysPage";
import SettingsBuildingsPage from "./modules/Settings/settings-buildings/SettingsBuildingsPage";
import SettingsBuildingPage from "./modules/Settings/settings-building/SettingsBuildingPage";
import SettingsEmailsPage from "./modules/Settings/settings-emails/SettingEmailsPage";
import CurrenciesPage from "./modules/Settings/settings-currencies/CurrenciesPage";
import {
  isPartner,
  isPrivilegePartnerPacket,
} from "./_helpers/PrivilegesUIHelper/Partner";
import { isCollaborator } from "./_helpers/PrivilegesUIHelper/Collaborator";
import { isAdministrator } from "./_helpers/PrivilegesUIHelper/Administrator";
import UpgradePage from "./pages/UpgradePage";

//------------------- ADMIN ----------------------//
const AdminDashboardModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerSenior/Dashboard/pages/DashboardModulePage"
  )
);
const AdminDeskBaseModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerSenior/DeskBase/pages/DeskBaseModulePage"
  )
);
const AdminDesksModulePage = lazy(() =>
  import("./modules/Administrator/ManagerSenior/Desks/pages/DesksModulePage")
);
const AdminCashFlowsModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerSenior/CashFlows/pages/CashFlowsModulePage"
  )
);

const AdminOrganizationModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerOrganization/Organization/pages/OrganizationModulePage"
  )
);
const AdminDepartmentsModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerOrganization/Departments/pages/DepartmentsModulePage"
  )
);
const AdminPositionsModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerOrganization/Positions/pages/PositionsModulePage"
  )
);
const AdminRolesModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerOrganization/Roles/pages/RolesModulePage"
  )
);

const UsersAdminModulePage = lazy(() =>
  import("./modules/Administrator/Users/pages/UsersModulePage")
);
const CustomersAdminModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerCustomer/Customers/pages/CustomersModulePage"
  )
);
const CustomersBGIDAdminModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerCustomer/BGID_Customers/pages/BGID_CustomersModulePage"
  )
);
const PostsAdminModulePage = lazy(
  () => false
  // import("./modules/Administrator/ManagerCustomer/GOOLAND_Posts/pages/GOOLAND_PostsModulePage")
);
const CollaboratorsAdminModulePage = lazy(() =>
  import("./modules/Administrator/Collaborators/pages/CollaboratorsModulePage")
);
const CommissionsAdminModulePage = lazy(() =>
  import("./modules/Administrator/Commissions/pages/CommissionsModulePage")
);
const ChangeLogAdminModulePage = lazy(() =>
  import(
    "./modules/Administrator/ManagerUpdate/ChangeLogs/pages/ChangeLogsModulePage"
  )
);
const WelcomeModulePage = lazy(() =>
  import("./modules/Welcome/pages/WelcomeModulePage")
);

const DeskbaseModulePage = lazy(() =>
  import("./modules/DeskBase/pages/DeskBaseModulePage")
);
const DeskNewVersionModulePage = lazy(() =>
  import("./modules/DeskNewVersion/pages/DeskModulePage")
);
const DeskTimeLineModulePage = lazy(() =>
  import("./modules/DeskTimeLine/pages/DeskTimeLineModulePage")
);
const ReportOverviewModulePage = lazy(() =>
  import("./modules/ReportOverview/pages/OverviewModulePage")
);
const ReportDebtsModulePage = lazy(() =>
  import("./modules/ReportDebts/pages/DebtsModulePage")
);
const ReportCustomersModulePage = lazy(() =>
  import("./modules/ReportCustomers/pages/CustomersModulePage")
);
const ReportStatisticsModulePage = lazy(() =>
  import("./modules/ReportStatistics/pages/StatisticsModulePage")
);
const ReportTasksModulePage = lazy(() =>
  import("./modules/ReportTasks/pages/TasksModulePage")
);
const ReportAssetsModulePage = lazy(() =>
  import("./modules/ReportAssets/pages/AssetsModulePage")
);
const ReportMeterLogsModulePage = lazy(() =>
  import("./modules/ReportMeterLogs/pages/MeterLogsModulePage")
);
const DashboardModulePage = lazy(() =>
  import("./modules/Dashboard/pages/DashboardModulePage")
);

const BuildingsModulePage = lazy(() =>
  import("./modules/Buildings/pages/BuildingsModulePage")
);
const ContractsModulePage = lazy(() =>
  import("./modules/Contracts/pages/ContractsModulePage")
);
const InvoicesModulePage = lazy(() =>
  import("./modules/Invoices/pages/InvoicesModulePage")
);
const OrganizationsModulePage = lazy(() =>
  import("./modules/Organizations/pages/OrganizationsModulePage")
);
const OrganizationModulePage = lazy(() =>
  import("./modules/Organization/pages/OrganizationModulePage")
);
const ReferralsModulePage = lazy(() =>
  import("./modules/Referrals/pages/ReferralsModulePage")
);
const CollaboratorsModulePage = lazy(() =>
  import("./modules/Collaborators/pages/CollaboratorsModulePage")
);
const DocumentsModulePage = lazy(() =>
  import("./modules/Documents/pages/DocumentsModulePage")
);

const TransactionsModulePage = lazy(() =>
  import("./modules/Transactions/pages/TransactionsModulePage")
);
const CommissionsModulePage = lazy(() =>
  import("./modules/Commissions/pages/CommissionsModulePage")
);
const PaymentVouchersModulePage = lazy(() =>
  import("./modules/PaymentVouchers/pages/PaymentVouchersModulePage")
);
const PositionsModulePage = lazy(() =>
  import("./modules/Positions/pages/PositionsModulePage")
);
const PrivilegesModulePage = lazy(() =>
  import("./modules/Privileges/pages/PrivilegesModulePage")
);
const ReceiptVouchersModulePage = lazy(() =>
  import("./modules/ReceiptVouchers/pages/ReceiptVouchersModulePage")
);
const TransactionHistoriesModulePage = lazy(() =>
  import("./modules/TransactionHistories/pages/TransactionHistoriesModulePage")
);
const HistoriesModulePage = lazy(() =>
  import("./modules/Histories/pages/HistoriesModulePage")
);

const RolesModulePage = lazy(() =>
  import("./modules/Roles/pages/RolesModulePage")
);
const ServicesModulePage = lazy(() =>
  import("./modules/Services/pages/ServicesModulePage")
);
const UnitsModulePage = lazy(() =>
  import("./modules/Units/pages/UnitsModulePage")
);
const MetersModulePage = lazy(() =>
  import("./modules/Meters/pages/MetersModulePage")
);

const FloorsModulePage = lazy(() =>
  import("./modules/Floors/pages/FloorsModulePage")
);
const CustomersModulePage = lazy(() =>
  import("./modules/Customers/pages/CustomersModulePage")
);
const CustomerProfilesModulePage = lazy(() =>
  import("./modules/CustomerProfiles/pages/CustomerProfilesModulePage")
);
const EntryExitHistoriesModulePage = lazy(() =>
  import("./modules/EntryExitHistories/pages/EntryExitHistoriesModulePage")
);

const VehiclesModulePage = lazy(() =>
  import("./modules/Vehicles/pages/VehiclesModulePage")
);
const DesksModulePage = lazy(() =>
  import("./modules/Desks/pages/DesksModulePage")
);
const UsersModulePage = lazy(() =>
  import("./modules/Users/pages/UsersModulePage")
);

const UnitSymbolsModulePage = lazy(() =>
  import("./modules/UnitSymbols/pages/UnitSymbolsModulePage")
);
const ContractFormsModulePage = lazy(() =>
  import("./modules/ContractForms/pages/ContractFormsModulePage")
);
const NationalsModulePage = lazy(() =>
  import("./modules/Nationals/pages/NationalsModulePage")
);
const NationalitiesModulePage = lazy(() =>
  import("./modules/Nationalities/pages/NationalitiesModulePage")
);
const ProvincesModulePage = lazy(() =>
  import("./modules/Provinces/pages/ProvincesModulePage")
);
const DistrictsModulePage = lazy(() =>
  import("./modules/Districts/pages/DistrictsModulePage")
);
const WardsModulePage = lazy(() =>
  import("./modules/Wards/pages/WardsModulePage")
);
const CheckinsModulePage = lazy(() =>
  import("./modules/Checkins/pages/CheckinsModulePage")
);
const MeterLogsModulePage = lazy(() =>
  import("./modules/MeterLogs/pages/MeterLogsModulePage")
);
const MeterPartnerLogsModulePage = lazy(() =>
  import("./modules/MeterPartnerLogs/pages/MeterPartnerLogsModulePage")
);
const InitialsModulePage = lazy(() =>
  import("./modules/Initials/pages/InitialsModulePage")
);
const TasksModulePage = lazy(() =>
  import("./modules/Tasks/pages/TasksModulePage")
);
const IssuesModulePage = lazy(() =>
  import("./modules/Issues/pages/IssuesModulePage")
);
const FeedbacksModulePage = lazy(() =>
  import("./modules/Feedbacks/pages/FeedbacksModulePage")
);
const SupportsModulePage = lazy(() =>
  import("./modules/Supports/pages/SupportsModulePage")
);
const TroublesModulePage = lazy(() =>
  import("./modules/Troubles/pages/TroublesModulePage")
);
const BrokerIntroductionModulePage = lazy(() =>
  import("./modules/BrokerIntroductions/pages/BrokerIntroductionsModulePage")
);

const CurrenciesModulePage = lazy(() =>
  import("./modules/Currencies/pages/CurrenciesModulePage")
);

const ReceiptPaymentVouchersModulePage = lazy(() =>
  import(
    "./modules/ReceiptPaymentVouchers/pages/ReceiptPaymentVouchersModulePage"
  )
);
const UnitTypesModulePage = lazy(() =>
  import("./modules/UnitTypes/pages/UnitTypesModulePage")
);

const CustomFieldsModulePage = lazy(() =>
  import("./modules/CustomFields/pages/CustomFieldsModulePage")
);

const VehicleTypesModulePage = lazy(() =>
  import("./modules/VehicleTypes/pages/VehicleTypesModulePage")
);
const PeriodsModulePage = lazy(() =>
  import("./modules/Periods/pages/PeriodsModulePage")
);
// const CouponsModulePage = lazy(() =>
//   import("./modules/Coupons/pages/CouponsModulePage")
// );
const ContractSourcesModulePage = lazy(() =>
  import("./modules/ContractSources/pages/ContractSourcesModulePage")
);
const FormulasModulePage = lazy(() =>
  import("./modules/Formulas/pages/FormulasModulePage")
);
const TicketsModulePage = lazy(() =>
  import("./modules/Tickets/pages/TicketsModulePage")
);
const VoucherCategoriesModulePage = lazy(() =>
  import("./modules/VoucherCategories/pages/VoucherCategoriesModulePage")
);
const UtilitiesModulePage = lazy(() =>
  import("./modules/Utilities/pages/UtilitiesModulePage")
);
const BanksModulePage = lazy(() =>
  import("./modules/Banks/pages/BanksModulePage")
);
const DirectionsModulePage = lazy(() =>
  import("./modules/Directions/pages/DirectionsModulePage")
);
const PriceRangesModulePage = lazy(() =>
  import("./modules/PriceRanges/pages/PriceRangesModulePage")
);
const RealEstateTypesModulePage = lazy(() =>
  import("./modules/RealEstateTypes/pages/RealEstateTypesModulePage")
);
const LegalDocumentsModulePage = lazy(() =>
  import("./modules/LegalDocuments/pages/LegalDocumentsModulePage")
);
const OutstandingFeaturesModulePage = lazy(() =>
  import("./modules/OutstandingFeatures/pages/OutstandingFeaturesModulePage")
);
const MetadataFloorsModulePage = lazy(() =>
  import("./modules/MetadataFloors/pages/MetadataFloorsModulePage")
);
const RoadWideTypesModulePage = lazy(() =>
  import("./modules/RoadWideTypes/pages/RoadWideTypesModulePage")
);
const BedroomNumbersModulePage = lazy(() =>
  import("./modules/BedroomNumbers/pages/BedroomNumbersModulePage")
);
const RoadWidthsModulePage = lazy(() =>
  import("./modules/RoadWidths/pages/RoadWidthsModulePage")
);

const BusinessesModulePage = lazy(() =>
  import("./modules/Businesses/pages/BusinessesModulePage")
);
const DepartmentsModulePage = lazy(() =>
  import("./modules/Departments/pages/DepartmentsModulePage")
);
const AssetCategoriesModulePage = lazy(() =>
  import("./modules/AssetCategories/pages/AssetCategoriesModulePage")
);
const DepositsModulePage = lazy(() =>
  import("./modules/Deposits/pages/DepositsModulePage")
);
const BankAccountsModulePage = lazy(() =>
  import("./modules/BankAccounts/pages/BankAccountsModulePage")
);
const ProjectsModulePage = lazy(() =>
  import("./modules/Projects/pages/ProjectsModulePage")
);

const AssetsModulePage = lazy(() =>
  import("./modules/Assets/pages/AssetsModulePage")
);

// const GoodsModulePage = lazy(() =>
//   import("./modules/Goods/pages/GoodsModulePage")
// );

// const GoodSequencesModulePage = lazy(() =>
//   import("./modules/GoodSequences/pages/GoodSequencesModulePage")
// );

// const GoodCategoriesModulePage = lazy(() =>
//   import("./modules/GoodCategories/pages/GoodCategoriesModulePage")
// );

const AssetSequencesModulePage = lazy(() =>
  import("./modules/AssetSequences/pages/AssetSequencesModulePage")
);
const AssetInventoriesModulePage = lazy(() =>
  import("./modules/AssetInventories/pages/AssetInventoriesModulePage")
);

const SuppliersModulePage = lazy(() =>
  import("./modules/Suppliers/pages/SuppliersModulePage")
);

const NotificationsModulePage = lazy(() =>
  import("./modules/Notifications/pages/NotificationsModulePage")
);
const NewsLettersModulePage = lazy(() =>
  import("./modules/NewsLetters/pages/NewsLettersModulePage")
);

const BuildingPostsModulePage = lazy(() =>
  import("./modules/BuildingPosts/pages/BuildingPostsModulePage")
);

const UnitPostsModulePage = lazy(() =>
  import("./modules/UnitPosts/pages/UnitPostsModulePage")
);

const ChatsModulePage = lazy(() =>
  import("./modules/Chats/pages/ChatsModulePage")
);
const AssetWarehousesModulePage = lazy(() =>
  import("./modules/AssetWarehouses/pages/AssetWarehousesModulePage")
);
const AssetStatusesModulePage = lazy(() =>
  import("./modules/AssetStatuses/pages/AssetStatusesModulePage")
);

const CollaboratorCommissionsModulePage = lazy(() =>
  import(
    "./modules/CollaboratorCommissions/pages/CollaboratorCommissionsModulePage"
  )
);
const RealTimeModulePage = lazy(() =>
  import("./modules/RealTime/pages/RealTimeModulePage")
);
const RealTimeCardModulePage = lazy(() =>
  import("./modules/RealTimeCard/pages/RealTimeCardModulePage")
);
const TeamsModulePage = lazy(() =>
  import("./modules/Teams/pages/TeamsModulePage")
);

const PostBuildingsModulePage = lazy(() =>
  import("./modules/PostBuildings/pages/PostBuildingsModulePage")
);
const PostUnitsModulePage = lazy(() =>
  import("./modules/PostUnits/pages/PostUnitsModulePage")
);
const PostSamplesModulePage = lazy(() =>
  import("./modules/PostSamples/pages/PostSamplesModulePage")
);
const PostListsModulePage = lazy(() =>
  import("./modules/PostLists/pages/PostListsModulePage")
);
const PostPotentialsModulePage = lazy(() =>
  import("./modules/PostPotentials/pages/PostPotentialsModulePage")
);

const UpdateModulePage = lazy(() =>
  // Bảng quản trị
  import("./pages/UpgradePage")
);

// Vai trò đối tác
const PartnerDashboardModulePage = lazy(() =>
  // Bảng quản trị
  import("./modules/Partner/Dashboard/pages/DashboardPartnerModulePage")
);

export default function BasePage() {
  const client = null;
  useEffect(() => {
    const client = new Client({
      brokerURL: process.env.REACT_APP_SOCKET_URL || "/",
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: onConnected,
      onDisconnect: onDisconnected,
      onWebSocketError: onWebSocketError,
    });

    client.activate();
  }, [onConnected]);

  const onConnected = () => {
    client && client.subscribe("/topic/notifications", onMessageReceived);
  };

  const onDisconnected = () => {
    //console.log("onDisconnected");
  };

  const onMessageReceived = (payload) => {
    //console.log("onMessageReceived");
  };

  const onWebSocketError = (payload) => {
    //console.log("onWebSocketError");
  };

  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  const noSubsciberFree = (user) => {
    if (user !== null && user.organization !== null) {
      if (user.organization.subscriberType !== 1) {
        return true;
      }
      return false;
    }
    return false;
  };

  const subscriberType = user.organization.subscriberType;
  let remainDay = (nowDate = new Date(), endDate = user.organization.endTime) =>
    Math.round(Math.abs(nowDate - endDate) / 86400000);

  let expiredDays = remainDay();

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        {expiredDays <= 0 ? <UpgradePage /> :
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect
                exact
                from="/"
                to={`${isPrivilegeUserAction(user, "DASHBOARD") &&
                  user.organization.subscriberType !== 1
                  ? isAdministrator(user)
                    ? "/admin/dashboard"
                    : isPartner(user)
                      ? "/partner/dashboard"
                      : "/deskbase/buildings"
                  : "welcome"
                  }`}
              />
            }

            <ContentRoute path="/dashboard1" component={DashboardPage} />
            <ContentRoute path="/builder" component={BuilderPage} />
            <ContentRoute path="/my-page" component={MyPage} />

            <Route path="/welcome" component={WelcomeModulePage} />

            {/* Vai trò quản trị */}
            {isAdministrator(user) && (
              <>
                {/* Phân hệ tổ chức */}
                <Route
                  path="/admin/dashboard"
                  component={AdminDashboardModulePage}
                />
                <Route
                  path="/admin/deskBase"
                  component={AdminDeskBaseModulePage}
                />
                <Route path="/admin/desk" component={AdminDesksModulePage} />
                <Route
                  path="/admin/cashFlows"
                  component={AdminCashFlowsModulePage}
                />
                {/* Quản lý tổ chức */}
                <Route
                  path="/admin/organization"
                  component={AdminOrganizationModulePage}
                />
                <Route
                  path="/admin/departments"
                  component={AdminDepartmentsModulePage}
                />
                <Route
                  path="/admin/positions"
                  component={AdminPositionsModulePage}
                />
                <Route path="/admin/roles" component={AdminRolesModulePage} />

                <Route path="/admin/users" component={UsersAdminModulePage} />
                <Route
                  path="/admin/customers"
                  component={CustomersAdminModulePage}
                />
                <Route
                  path="/admin/BGID"
                  component={CustomersBGIDAdminModulePage}
                />
                <Route path="/admin/posts" component={PostsAdminModulePage} />
                <Route
                  path="/admin/collaborators"
                  component={CollaboratorsAdminModulePage}
                />
                <Route
                  path="/admin/commissions"
                  component={CommissionsAdminModulePage}
                />
                <Route
                  path="/admin/change-logs"
                  component={ChangeLogAdminModulePage}
                />
                <Route
                  path="/organizations"
                  component={OrganizationsModulePage}
                />
                <Route path="/transactions" component={TransactionsModulePage} />
                <Route path="/privileges" component={PrivilegesModulePage} />
                <Route path="/nationals" component={NationalsModulePage} />
                <Route
                  path="/nationalities"
                  component={NationalitiesModulePage}
                />
                <Route path="/provinces" component={ProvincesModulePage} />
                <Route path="/districts" component={DistrictsModulePage} />
                <Route path="/wards" component={WardsModulePage} />
                <Route path="/checkins" component={CheckinsModulePage} />
                <Route path="/initials" component={InitialsModulePage} />
                <Route path="/issues" component={IssuesModulePage} />
                <Route
                  path="/brokerIntroductions"
                  component={BrokerIntroductionModulePage}
                />
                <Route path="/currencies" component={CurrenciesModulePage} />
                <Route
                  path="/receiptPaymentVouchers"
                  component={ReceiptPaymentVouchersModulePage}
                />
                <Route path="/tickets" component={TicketsModulePage} />
                <Route path="/banks" component={BanksModulePage} />
                <Route
                  path="/admin/directions"
                  component={DirectionsModulePage}
                />
                <Route
                  path="/admin/price-ranges"
                  component={PriceRangesModulePage}
                />
                <Route
                  path="/admin/real-estate-types"
                  component={RealEstateTypesModulePage}
                />
                <Route
                  path="/admin/legal-documents"
                  component={LegalDocumentsModulePage}
                />
                <Route
                  path="/admin/outstanding-features"
                  component={OutstandingFeaturesModulePage}
                />
                <Route
                  path="/admin/metadata-floors"
                  component={MetadataFloorsModulePage}
                />
                <Route
                  path="/admin/road-wide-types"
                  component={RoadWideTypesModulePage}
                />
                <Route
                  path="/admin/bedroom-numbers"
                  component={BedroomNumbersModulePage}
                />
                <Route
                  path="/admin/road-widths"
                  component={RoadWidthsModulePage}
                />
                <Route path="/businesses" component={BusinessesModulePage} />
                <Route path="/supports" component={SupportsModulePage} />
              </>
            )}

            {/* Vai trò môi giới */}
            {isCollaborator(user) && !isAdministrator(user) && (
              <>
                <Route path="/referrals" component={ReferralsModulePage} />
                <Route path="/settings/personal" component={PersonalPage} />
              </>
            )}

            {/* Vai trò người dùng */}
            {isUser(user) && !isAdministrator(user) && (
              <>
                {/* Phân hệ bảng quản trị */}
                {isPrivilegeUserPacket({
                  namePrivilege: "DASHBOARD",
                  type: subscriberType,
                }) && <Route path="/dashboard" component={DashboardModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "DASHBOARD_BASE",
                  type: subscriberType,
                }) && <Route path="/deskbase" component={DeskbaseModulePage} />}
                {
                }
                {<Route path="/booking" component={DeskTimeLineModulePage} />}

                {isPrivilegeUserPacket({
                  namePrivilege: "DESKS",
                  type: subscriberType,
                }) && <Route path="/desks" component={DesksModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "NOTIFICATIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/notifications"
                      component={NotificationsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "NEWLETTERS",
                  type: subscriberType,
                }) && (
                    <Route path="/newsLetters" component={NewsLettersModulePage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "BUILDINGPOSTS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/building-posts"
                      component={BuildingPostsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "UNITSPOSTS",
                  type: subscriberType,
                }) && (
                    <Route path="/unit-posts" component={UnitPostsModulePage} />
                  )}
                {/* Phân hệ quản lý bảng hàng */}
                {isPrivilegeUserPacket({
                  namePrivilege: "REALTIME",
                  type: subscriberType,
                }) && <Route path="/real-time" component={RealTimeModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "UNITCARD",
                  type: subscriberType,
                }) && (
                    <Route path="/unit-card" component={RealTimeCardModulePage} />
                  )}
                {/* Phân hệ quản lý cơ sở */}
                {isPrivilegeUserPacket({
                  namePrivilege: "BUILDINGS",
                  type: subscriberType,
                }) && <Route path="/buildings" component={BuildingsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "FLOORS",
                  type: subscriberType,
                }) && <Route path="/floors" component={FloorsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "UTILITIES",
                  type: subscriberType,
                }) && <Route path="/utilities" component={UtilitiesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "UNITS",
                  type: subscriberType,
                }) && <Route path="/units" component={UnitsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "SERVICES",
                  type: subscriberType,
                }) && <Route path="/services" component={ServicesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "METERS",
                  type: subscriberType,
                }) && <Route path="/meters" component={MetersModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "VEHICLE_TYPES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/vehicleTypes"
                      component={VehicleTypesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CONTRACT_FORMS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/contractForms"
                      component={ContractFormsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "BANK_ACCOUNTS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/bankAccounts"
                      component={BankAccountsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CUSTOM_FIELDS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/customFields"
                      component={CustomFieldsModulePage}
                    />
                  )}
                {/* Phân hệ quản lý khách hàng */}
                {isPrivilegeUserPacket({
                  namePrivilege: "CUSTOMERS",
                  type: subscriberType,
                }) && <Route path="/customers" component={CustomersModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "VEHICLES",
                  type: subscriberType,
                }) && <Route path="/vehicles" component={VehiclesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "CUSTOMERS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/customer-profiles"
                      component={CustomerProfilesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CUSTOMERS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/entry-exit-histories"
                      component={EntryExitHistoriesModulePage}
                    />
                  )}
                {/* Phân hệ quản lý hợp đồng */}
                {isPrivilegeUserPacket({
                  namePrivilege: "DEPOSITS",
                  type: subscriberType,
                }) && <Route path="/deposits" component={DepositsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "CONTRACTS",
                  type: subscriberType,
                }) && <Route path="/contracts" component={ContractsModulePage} />}
                {/* Phân hệ quản lý tài chính */}
                {isPrivilegeUserPacket({
                  namePrivilege: "METER_LOGS",
                  type: subscriberType,
                }) && <Route path="/meterLogs" component={MeterLogsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "METER_PARTNER_LOGS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/meterPartnerLogs"
                      component={MeterPartnerLogsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "INVOICES",
                  type: subscriberType,
                }) && <Route path="/invoices" component={InvoicesModulePage} />}
                {/* {isPrivilegeUserPacket({ namePrivilege: "TRANSACTION_HISTORIES", type: subscriberType }) && <Route path="/transaction-histories" component={TransactionHistoriesModulePage} />} */}
                {
                  <Route
                    path="/transaction-histories"
                    component={TransactionHistoriesModulePage}
                  />
                }
                {<Route path="/histories" component={HistoriesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "RECEIPT_VOUCHERS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/receiptVouchers"
                      component={ReceiptVouchersModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "PAYMENT_VOUCHERS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/paymentVouchers"
                      component={PaymentVouchersModulePage}
                    />
                  )}
                {/* Phân hệ quản lý tài sản */}
                {isPrivilegeUserPacket({
                  namePrivilege: "SUPPLIES",
                  type: subscriberType,
                }) && <Route path="/suppliers" component={SuppliersModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "ASSET_WAREHOUSES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/assetWarehouses"
                      component={AssetWarehousesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "ASSET_CATEGORIES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/assetCategories"
                      component={AssetCategoriesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "ASSET_STATUSES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/assetStatuses"
                      component={AssetStatusesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "ASSETS",
                  type: subscriberType,
                }) && <Route path="/assets" component={AssetsModulePage} />}
                {/* {<Route path="/goods" component={GoodsModulePage} />}
              {<Route path="/good-sequences" component={GoodSequencesModulePage} />}
              {<Route path="/goodCategories" component={GoodCategoriesModulePage} />} */}

                {isPrivilegeUserPacket({
                  namePrivilege: "ASSET_SEQUENCES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/assetSequences"
                      component={AssetSequencesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "ASSET_INVENTORIES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/assetInventories"
                      component={AssetInventoriesModulePage}
                    />
                  )}
                {/* Phân hệ quản lý công việc */}
                {isPrivilegeUserPacket({
                  namePrivilege: "PROJECTS",
                  type: subscriberType,
                }) && <Route path="/projects" component={ProjectsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "TASKS",
                  type: subscriberType,
                }) && <Route path="/tasks" component={TasksModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "TROUBLES",
                  type: subscriberType,
                }) && <Route path="/troubles" component={TroublesModulePage} />}
                {/* Phân hệ quản lý môi giới */}
                {isPrivilegeUserPacket({
                  namePrivilege: "COLLABORATORS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/collaborators"
                      component={CollaboratorsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "USER_COMMISSIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/userCommissions"
                      component={CollaboratorCommissionsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "COMMISSIONS",
                  type: subscriberType,
                }) && (
                    <Route path="/commissions" component={CommissionsModulePage} />
                  )}
                {/* Phân hệ quản lý tổ chức */}
                {isPrivilegeUserPacket({
                  namePrivilege: "ORGANIZATION",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/organization"
                      component={OrganizationModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "DEPARTMENTS",
                  type: subscriberType,
                }) && (
                    <Route path="/departments" component={DepartmentsModulePage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "POSITIONS",
                  type: subscriberType,
                }) && <Route path="/positions" component={PositionsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "ROLES",
                  type: subscriberType,
                }) && <Route path="/roles" component={RolesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "USERS",
                  type: subscriberType,
                }) && <Route path="/users" component={UsersModulePage} />}
                {/* Phân hệ quản lý danh mục */}
                {isPrivilegeUserPacket({
                  namePrivilege: "UNIT_SYMBOLS",
                  type: subscriberType,
                }) && (
                    <Route path="/unitSymbols" component={UnitSymbolsModulePage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "TEAMS",
                  type: subscriberType,
                }) && <Route path="/teams" component={TeamsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "PERIODS",
                  type: subscriberType,
                }) && <Route path="/periods" component={PeriodsModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "UNIT_TYPES",
                  type: subscriberType,
                }) && <Route path="/unitTypes" component={UnitTypesModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "FORMULARS",
                  type: subscriberType,
                }) && <Route path="/formulas" component={FormulasModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "VOUCHER_CATEGORIES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/voucherCategories"
                      component={VoucherCategoriesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CONTRACT_SOURCES",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/contractSources"
                      component={ContractSourcesModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CONTRACT_FORM_ORGANIZATIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/contractFormOrganization"
                      component={ContractFormsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "BANK_ACCOUNT_ORGANIZATIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/bankAccountOrganization"
                      component={BankAccountsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "CUSTOM_FIELD_ORGANIZATIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/customFieldOrganization"
                      component={CustomFieldsModulePage}
                    />
                  )}
                {/* Phân hệ tài liệu lưu trữ */}
                {isPrivilegeUserPacket({
                  namePrivilege: "DOCUMENTS",
                  type: subscriberType,
                }) && <Route path="/documents" component={DocumentsModulePage} />}
                {/* Phân hệ báo cáo & thống kê */}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_FINANCIALS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/overview"
                      component={ReportOverviewModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_DEBTS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/debts"
                      component={ReportDebtsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_PREPAYS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/customers"
                      component={ReportCustomersModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_STATISTICS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/statistics"
                      component={ReportStatisticsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_TASKS",
                  type: subscriberType,
                }) && (
                    <Route path="/reports/task" component={ReportTasksModulePage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_ASSETS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/asset"
                      component={ReportAssetsModulePage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "REPORTS_METER_LOGS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/meterlog"
                      component={ReportMeterLogsModulePage}
                    />
                  )}
                {/* Phân hệ cấu hình hệ thống */}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_PROFILE",
                  type: subscriberType,
                }) && (
                    <Route path="/settings/personal" component={PersonalPage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_PERMISSION_BUILDINGS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/settings/decentralization"
                      component={DecentralizationPage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_FUNCTIONS",
                  type: subscriberType,
                }) && (
                    <Route path="/settings/functions" component={FunctionsPage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_API_KEYS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/settings/api-keys"
                      component={SettingsAPIKeysPage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_ORGANIZATIONS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/settings/buildings"
                      component={SettingsBuildingsPage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_ORGANIZATION_BUILDINGS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/settings/building"
                      component={SettingsBuildingPage}
                    />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_EMAIL_TEMPLATES",
                  type: subscriberType,
                }) && (
                    <Route path="/settings/emails" component={SettingsEmailsPage} />
                  )}
                {isPrivilegeUserPacket({
                  namePrivilege: "SETTINGS_CURRENCIES",
                  type: subscriberType,
                }) && (
                    <Route path="/settings/currencies" component={CurrenciesPage} />
                  )}
                {/* Phân hệ hỗ trợ */}
                {isPrivilegeUserPacket({
                  namePrivilege: "FEEDBACKS",
                  type: subscriberType,
                }) && <Route path="/feedbacks" component={FeedbacksModulePage} />}
                {isPrivilegeUserPacket({
                  namePrivilege: "SUPPORTS",
                  type: subscriberType,
                }) && <Route path="/supports" component={SupportsModulePage} />}
              </>
            )}

            {noSubsciberFree(user) && (
              <Route path="/chats" component={ChatsModulePage} />
            )}
            <Route path="/postBuildings" component={PostBuildingsModulePage} />
            <Route path="/postUnits" component={PostUnitsModulePage} />
            <Route path="/postTemplates" component={PostSamplesModulePage} />
            <Route path="/posts" component={PostListsModulePage} />
            <Route path="/postPotentials" component={PostPotentialsModulePage} />

            {/* Vai trò đối tác */}
            {isPartner(user) && !isAdministrator(user) && (
              <>
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_DASHBOARD",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/dashboard"
                      component={PartnerDashboardModulePage}
                    />
                  )}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_DASHBOARD_BUILDING",
                  type: subscriberType,
                }) && <Route path="/deskbase" component={DeskbaseModulePage} />}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_CUSTOMERS",
                  type: subscriberType,
                }) && <Route path="/customers" component={CustomersModulePage} />}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_CONTRACTS",
                  type: subscriberType,
                }) && <Route path="/contracts" component={ContractsModulePage} />}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_METER_LOGS",
                  type: subscriberType,
                }) && <Route path="/meterLogs" component={MeterLogsModulePage} />}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_INVOICES",
                  type: subscriberType,
                }) && <Route path="/invoices" component={InvoicesModulePage} />}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_REPORTS_FINANCIALS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/overview"
                      component={ReportOverviewModulePage}
                    />
                  )}
                {isPrivilegePartnerPacket({
                  namePrivilege: "PARTNER_REPORTS_ASSETS",
                  type: subscriberType,
                }) && (
                    <Route
                      path="/reports/asset"
                      component={ReportAssetsModulePage}
                    />
                  )}
              </>
            )}

            <Redirect to="error/error-v1" />
          </Switch>
        }
      </Suspense>
      <Toasts />
    </>
  );
}
