import { createSlice } from "@reduxjs/toolkit";


const initialToastsState = {
  listLoading: null,
  actionsLoading: null,
  notification: null,
  show: null,
};

export const toastsSlice = createSlice({
  name: "toasts",
  initialState: initialToastsState,
  reducers: {
    hideToast: (state, action) => {
      state.notification = null;
      state.show = null;
    },
    warningToast: (state, action) => {
      state.notification = {
        type: "warning",
        message: action.payload.notification
      };
      state.show = true;
    },
    infoToast: (state, action) => {
      state.notification = {
        type: "info",
        text: action.payload.notification
      };
      state.show = true;
    },
    successToast: (state, action) => {

      state.notification = {
        type: "success",
        title: action.payload.notification.title,
        text: action.payload.notification.content,
      };
      state.show = true;
    },
    errorToast: (state, action) => {
      const error = action.payload.error;
      state.notification = {
        type: "warning",
        text: error?.response?.data?.code || error?.response?.data?.error || "ERRORS.CLIENT.COMMONS.GLOBAL"
      };
      state.show = true;
    },
    errorToastText: (state, action) => {
      const notification = action.payload.notification;
      state.notification = {
        type: "warning",
        text: notification.content
      };
      state.show = true;
    },
  }
}
);
