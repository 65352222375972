import React from "react";
import { Carousel } from "react-bootstrap";

export function OrganizationSlide({ organizationCode }) {
  return (
    <>
      <div className={` ${organizationCode} order-1 order-lg-2 d-flex flex-column w-50 pb-0 justify-content-center align-items-center `} style={{ background: "#fbfbfb" }}>
        <Carousel style={{ position: 'relative' }} fade={true}>
          <Carousel.Item>
            <div>
              <img
                src={`/media/login/${organizationCode}/slide1.png`}
                width="570"
                height="448"
                alt=""
              />
              <Carousel.Caption></Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src={`/media/login/${organizationCode}/slide2.png`}
                width="570"
                height="448"
                alt=""
              />
              <Carousel.Caption></Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src={`/media/login/${organizationCode}/slide3.png`}
                width="570"
                height="448"
                alt=""
              />
              <Carousel.Caption></Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}
