import React from 'react';
import { FormattedMessage } from 'react-intl';

export function ButtonCardMenu(props) {
    const {
        getTabActive,
        functionName,
        action = () => { },
        icon,
        title
    } = props;

    return (
        <button

            className={`btn  btn-text-dark ${functionName !== "return" && "btn-hover-secondary  btn-hover-text-primary"} border-0 font-weight-bold mr-2  rounded
            ${getTabActive(`/${functionName}`, false)}
            `}
            //  style={{
            //     borderRadius: "5px !important"
            // }}
            onClick={action}
        >
            <i className={`${icon} icon-md text-primary`} /><span className="text-primary"><FormattedMessage id={title} /></span>
        </button>
    );
}
