import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./buildingsCrud";
import { buildingsSlice, callTypes } from "./buildingsSlice";
import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = buildingsSlice;


// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentBuildings = () => (dispatch) => {
  dispatch(actions.resetCurrentBuildings());
};

export const resetBuildingImport = () => (dispatch) => {
  dispatch(actions.resetBuildingImport());
};

// Area actions fetch list
export const fetchAllBuildings = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType, }));
  return requestFromServer
    .getAllBuildings()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.buildingsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllBuildings";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentBuildings = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCallParent({ callType: callType }));
  return requestFromServer
    .getAllBuildings()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.buildingsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentBuildings";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchBuildingsByOrganizationId = (organizationId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findBuildingsByOrganizationId(organizationId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.buildingsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBuildingsByOrganizationId";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list
export const fetchBuildings = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callTypes.table }));
  return (
    requestFromServer
      .findBuildings(queryParams)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        const filterCount = {
          total: response.data.total
        }
        dispatch(actions.buildingsFetched({ totalCount, entities, filterCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchBuildings";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

// Area actions get item
export const fetchBuilding = (id, mode) => (dispatch) => {
  const callType = callTypes.initialization
  if (mode === "new" || mode === "newBuildingOnDeskBase") {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitBuilding()
      .then((response) => {
        const building = response.data;
        dispatch(actions.buildingFetched({ buildingForEdit: building }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchBuilding";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        // dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode && mode === "clone") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getBuildingCloneById(id)
        .then((response) => {
          const building = response.data;
          dispatch(actions.buildingFetched({ buildingForEdit: building }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchBuilding";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    } else
      if (mode && mode === "edit") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getBuildingEditById(id)
          .then((response) => {
            const building = response.data;
            dispatch(actions.buildingFetched({ buildingForEdit: building }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchBuilding";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      } else
        if (mode === "show") {
          const callType = callTypes.action;
          dispatch(actions.startCall({ callType: callType }));
          return requestFromServer
            .getBuildingById(id)
            .then((response) => {
              const building = response.data;
              dispatch(actions.buildingShowFetched({ buildingForShow: building }));
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else
                error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
              error.callType = callType;
              error.action = "fetchBuilding";
              error.status = "warning"
              dispatch(actions.catchError({ error, callType: callType }));
              dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
            });
        }
};

export const getInitialBuilding = () => (dispatch) => {
  const callType = callTypes.initialization
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getInitBuilding()
    .then((response) => {
      const building = response.data;
      dispatch(actions.buildingFetched({ buildingForEdit: building }));
    })
    .catch((error) => {
      let alert = {
        type: 'warning',
        message: 'ERRORS.CLIENT.COMMONS.GLOBAL'
      }

      if (error?.response?.data) {
        alert.message = error?.response?.data?.code;
      }

      dispatch(actions.catchError({ alert, error, callType: callType }));
    });
};

export const getBuildingById = (id) => (dispatch) => {
  const callType = callTypes.initialization
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBuildingById(id)
    .then((response) => {
      const building = response.data;
      dispatch(actions.buildingShowFetched({ buildingForShow: building }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchInitialBuilding = (initialObj) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .initialBuildings(initialObj)
    .then((response) => {
      const building = response.data;
      dispatch(actions.buildingFetched({ buildingForEdit: building }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchInitialBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const getParentById = (id) => (dispatch) => {
  const callType = callTypes.initialization
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBuildingById(id)
    .then((response) => {
      const building = response.data;
      dispatch(actions.buildingParentShowFetched({ buildingParentForShow: building }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


export const fetchBuildingUserPermission = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBuildingUserPermissionById(id)
    .then((response) => {
      const buildingPermission = response.data;

      dispatch(actions.buildingUserPermissionFetched({ buildingUserPermissionForEdit: buildingPermission }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBuildingUserPermission";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions create, update item
export const createBuilding = (buildingForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createBuilding(buildingForCreation)
    .then((response) => {
      const building = response.data;
      dispatch(actions.buildingCreated({ building }));
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateBuilding = (building, id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateBuilding(building, id)
    .then((response) => {
      const building = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.buildingUpdated({ building }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateBuildingUserPermission = (building) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateBuildingUserPermission(building)
    .then((response) => {
      const building = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.buildingUpdated({ building }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateBuildingUserPermission";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item
export const updateBuildingsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForBuildings(ids, status)
    .then(() => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.buildingsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateBuildingsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item
export const deleteBuildings = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteBuildings(ids)
    .then((response) => {
      const activeIds = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="BUILDINGS.UI.Title" ></FormattedMessage>,
        content:
          <FormattedMessage
            id="COMMONS.UI.SUCCESS.delete.params"
            values={{
              params: `'${ids.length - activeIds.length}/${ids.length}'`,
            }}
          />
      }
      dispatch(actions.buildingsDeleted({ ids }));
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteBuildings";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteBuilding = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteBuilding(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.buildingDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchBuildingsCount = () => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findBuildingCount()
    .then((response) => {
      const buildingCount = response.data;
      dispatch(actions.buildingCount({ buildingCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchBuildingsCount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    })
};

export const cleanbuildingCount = () => (dispatch) => {
  return dispatch(actions.countBuildingsClean());
};

export const downloadTemplateBuildingExport = () => (dispatch) => {
  return requestFromServer
    .getTemplateBuildingExportExcel()
    .then((response) => {
      let blob = new Blob([response.data], { type: '.xlsx' }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Tep_mau_toa nha.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.action = "downloadContractForm";
      error.status = "warning"
    });
};


export const fetchBuildingImport = (file, callType = callTypes.initialization) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBuildingImport(file)
    .then((response) => {
      const building = response.data;

      dispatch(
        actions.buildingImportFetched({ buildingImport: building })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchMeterLog";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchBuildingImportStore = (file, callType = callTypes.initialization) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getBuildingImportStore(file)
    .then((response) => {
      console.log("true")
      const user = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.IMPORT" values={{
          params: `'${response.data.successRecords}/${response.data.records}'`,
        }}></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(
        actions.buildingImportStoreFetched({ userImport: user })
      );
    })
    .catch((error) => {

      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchMeterLog";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const downloadBuildingsExport = (buildingId) => (dispatch) => {
  return requestFromServer
    .getBuildingExportExcel(buildingId)
    .then((response) => {
      console.log("response: ", response)
      let blob = new Blob([response.data], { type: '.xlsx' }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Toa_nha.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.COMMONS.DOWNLOAD_EXCEL_FAILED"
      error.action = "downloadBuilding";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callTypes.download }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callTypes.download }));
    });
};
