/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { CheckboxField, Input, SelectField } from "../../../../_bloomgoo/_partials/controls";
import { AccordionToken, isNullOrEmptyToDefaultZero, listTokenInvoice, listTokenRentDeposit } from "../../../_helpers/_CommonUIHelpers";
import { getFilterConfig } from "../../../_helpers/LocalStorageConfig";
import { isPrivilegeUserAction, } from "../../../_helpers/PrivilegesUIHelper/User";
import * as actionsBankAccounts from "../../BankAccounts/_redux/bankAccounts/bankAccountsActions";
import * as actionsBuildings from "../../Buildings/_redux/buildings/buildingsActions";
import * as actionsDistricts from "../../Districts/_redux/districts/districtsActions";
import * as actionsUsers from "../../Users/_redux/users/usersActions";
import * as actionsWards from "../../Wards/_redux/wards/wardsActions";
import * as actionsSettings from "../_redux/settings/settingsActions";
import SettingsBuildingNotification from "../settings-building-notification/SettingsBuildingNotification";
import { BUILDING_STATUS_OBJ, POSITION_INTERFACE_OBJ } from "../../../_helpers/EnumUIHelpers";

export default function SettingsBuilding() {
  const dispatch = useDispatch();

  const { user, buildings, bankAccounts, settingForEditBuilding, users, actionsLoading, error } = useSelector(
    (state) => ({
      user: state.auth.user,
      positions: state.positions.entities,
      roles: state.roles.entities,
      units: state.units.entities,
      buildings: state.buildings.entities,
      settingForEditBuilding: state.settings.settingForEditBuilding,
      actionsLoading: state.settings.actionsLoading,
      error: state.settings.error,
      bankAccounts: state.bankAccounts.entities,
      notification: state.settings.notification,
      users: state.users.entities,
    }),
    shallowEqual
  );


  useEffect(() => {
    dispatch(actionsUsers.fetchAllUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionsSettings.fetchEditBuildingById(getFilterConfig().activeBuildingId));
    dispatch(actionsBuildings.fetchBuildingsByOrganizationId(user.organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const saveSettingBuilding = (values) => {
    dispatch(actionsSettings.updateByBuildingId(values));
  };

  let invoiceBillingAccounts = [];

  const handleFieldChange = (e, props) => {
    let fieldName = e.target.name
    if (fieldName === 'provinceId') {
      dispatch(actionsDistricts.fetchDistrictsByProvinceId(isNullOrEmptyToDefaultZero(e.target.value)));
      dispatch(actionsWards.fetchWardsByDistrictId(0));
      props.setFieldValue('districtId', 0);
      props.setFieldValue('wardId', 0);
    };
    if (fieldName === 'districtId') {
      dispatch(actionsWards.fetchWardsByDistrictId(isNullOrEmptyToDefaultZero(e.target.value)));
      props.setFieldValue('wardId', 0)
    };

    if (fieldName === "buildingId") {
      dispatch(actionsSettings.fetchEditBuildingById(e.target.value));
      dispatch(actionsBankAccounts.fetchBanksByBuildingId(e.target.value));
    }


    if (fieldName.includes("invoiceBillingAccounts[")) {
      let selectedRowIndex = Number(fieldName.replace("].isSelected", "").replace("invoiceBillingAccounts[", ""))
      if (e.target.checked === true || e.target.checked === "true") {
        invoiceBillingAccounts.push(bankAccounts[selectedRowIndex].id)
      }
      else {
        invoiceBillingAccounts.splice(selectedRowIndex, 1)
      }
      props.setFieldValue("invoiceBillingAccounts", JSON.stringify(invoiceBillingAccounts))
    }
  };

  const popover = (label, msg) => (
    <Popover id="popover-basic">
      <Popover.Title
        className="bg-primary text-white py-1"
        as="h3"
      >
        <div> <i className="flaticon-information text-white mr-1"></i> <span
          style={{ marginBottom: "0.15rem " }}
        >{label}</span></div>
      </Popover.Title>
      <Popover.Content className="py-1">
        {msg.map((el, index) => (
          <p className="py-1" key={index}>{el}</p>
        ))}
      </Popover.Content>
    </Popover>
  );
  const [isShowNotification, setIsShowNotification] = useState(false);
  let formProps;
  const bindProps = (props) => {
    formProps = props;
  }
  return (
    <>
      {isShowNotification &&
        <SettingsBuildingNotification
          show={isShowNotification}
          onHide={() => setIsShowNotification(false)}
          action={() => dispatch(actionsSettings.fetchResetBuildingById(getFilterConfig().activeBuildingId)).then(() => formProps.handleReset())}
          actionsLoading={actionsLoading}
          error={error}
        />
      }
      <Formik
        enableReinitialize={true}
        initialValues={settingForEditBuilding}
        // validationSchema={buildingSchema}
        onSubmit={(values) => {
          saveSettingBuilding(values);
        }}
      >
        {({ handleSubmit, handleChange, ...props }) => {
          bindProps(props);
          return (
            <>

              <div className="tab-content mt-5" id="myTabContent">
                <div className="tab-pane show  active" id="kt_tab_pane_1" role="tabpanel" aria-labelledby="kt_tab_pane_1">
                  <div className="form-group">
                    <div className="">
                      <div className="form-group d-flex justify-content-between">
                        <div className=" align-items-start flex-column">
                          <h3 className="px-2 py-2 text-primary">
                            CẤU HÌNH HỢP ĐỒNG
                          </h3>
                          <div className="col-lg-6">
                            <div className="border border-primary border-2 bg-primary"></div>
                          </div>
                        </div>
                        <div className="d-flex row align-items-center justify-content-end mr-5"
                          style={{ width: "400px" }}
                        >
                          {/* <div className="row d-flex justify-content-end"> */}

                          <FormattedMessage id="MODEL.UI.settings.buildingId" >
                            {msg => (
                              <>
                                <div className="col-lg-4 col-form-label text-lg-left d-flex justify-content-end text-input-hover">
                                  <div className="col-lg-1">
                                    {/* <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={popover("Trợ giúp", ["Khởi tạo lại dữ liệu về mặc định."])
                                      }
                                    >
                                      <span type="button" onClick={() => dispatch(actionsSettings.fetchEditBuilding(getFilterConfig().activeBuildingId))}>
                                        <i className="flaticon2-refresh-arrow text-hover-primary"></i>
                                      </span>
                                    </OverlayTrigger> */}
                                  </div>
                                </div>
                                {/* Cũ PHT */}
                                {/* <div className="col-lg-7">
                                  <Field
                                    name="buildingId"
                                    checkYup={false}
                                    placeholder={'-- Chọn -- '}
                                    component={SelectField}
                                    type={"text"}
                                    options={
                                      buildings && buildings.map(function (item) {
                                        return { 
                                          label: item.parentId ? "".padStart(item.level * 3, '-- ') + item.name : item.name, 
                                          value: item.id }
                                      })
                                    }
                                    formProps={props}
                                    handleFieldChange={handleFieldChange}
                                  />
                                </div> */}
                                <div className="col-lg-7">
                                  <Field
                                    name="buildingId"
                                    component={SelectField}
                                    type={"text"}
                                    positionIcon={POSITION_INTERFACE_OBJ.END}
                                    checkYup={false}
                                    placeholder={'-- Chọn -- '}
                                    options={
                                      buildings && buildings.map(function (item) {
                                        return {
                                          label: item.parentId ? "".padStart(item.level * 3, '-- ') + item.name : item.name,
                                          value: item.id,
                                          icon: item.status === BUILDING_STATUS_OBJ.STOPPED && "fas fa-lock",
                                          iconColor: "text-dark",
                                          iconTooltip: <FormattedMessage id="MODEL.UI.buildings.stopped.notification.units" />
                                        }
                                      })
                                    }
                                    formProps={props}
                                    handleFieldChange={handleFieldChange}
                                  />
                                </div>

                              </>
                            )}
                          </FormattedMessage>
                          {/* </div> */}
                        </div>
                      </div>


                      <div className="py-2 col-lg-12"><b><h5>1. Cấu hình mẫu biểu hợp đồng</h5></b></div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                        </div>
                        <div className="col-lg-9">
                          <div className="react-bootstrap-table table-responsive">
                            <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center">Loại hợp đồng</th>
                                  <th className="text-center">Tên hợp đồng</th>
                                  <th className="text-center">Mã hợp đồng</th>
                                  <th className="text-center">Độ dài số thứ tự</th>
                                  <th className="text-center">Tiêu đề</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td className="text-left">
                                      Hợp đồng thuê
                                    </td>
                                    <td>
                                      <Field
                                        name="contractRentName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="contractRentCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="contractRentCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="contractRentTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Hợp đồng đặt cọc
                                    </td>
                                    <td>
                                      <Field
                                        name="contractDepositName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="contractDepositCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="contractDepositCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="contractDepositTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                </>
                              </tbody>
                            </Table>
                          </div>
                          <AccordionToken listToken={listTokenRentDeposit} id="contracts" />
                        </div>
                      </div>

                      <div className="py-2 col-lg-12"><strong><h5>2. Thông báo/Cảnh báo</h5></strong></div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thông báo hợp đồng mới
                        </div>
                        <div className="row col-lg-9">
                          <FormattedMessage id="MODEL.UI.settings.notificationContractCreated">
                            {(msg) => (
                              <Field
                                name="notificationContractCreated"
                                component={CheckboxField}
                                className="ml-3"
                                checked={props.values && props.values.notificationContractCreated}
                                withFeedbackLabel={false}
                                onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thông báo hợp đồng sắp hết hạn
                        </div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationContractPreExpired"
                            component={CheckboxField}
                            checked={props.values && props.values.notificationContractPreExpired}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trước</span>
                          <Field
                            name="notificationContractPreExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày đến khi hết hạn</span>
                          {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                            <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                              {(msg) => (
                                <Field
                                  name="notificationContractPreExpiredFrequency"
                                  component={SelectField}
                                  type={"text"}
                                  placeholder={'-- Chọn -- '}
                                  checkYup={false}
                                  options={
                                    [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                      return { label: item.name, value: item.id }
                                    }))
                                  }
                                  formProps={props}
                                  handleFieldChange={handleFieldChange} />
                              )}
                            </FormattedMessage> */}
                        </div>
                      </div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thông báo hợp đồng đã hết hạn
                        </div>

                        <div className="row col-lg-9">
                          <Field
                            name="notificationContractExpired"
                            component={CheckboxField}
                            className="ml-3"
                            checked={props.values && props.values.notificationContractExpired}
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trong</span>
                          <Field
                            name="notificationContractExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày sau khi hết hạn</span>
                          {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                            <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                              {(msg) => (
                                <Field
                                  name="notificationContractExpiredFrequency"
                                  component={SelectField}
                                  type={"text"}
                                  checkYup={false}
                                  placeholder={'-- Chọn -- '}
                                  options={
                                    [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                      return { label: item.name, value: item.id }
                                    }))
                                  }
                                  formProps={props}
                                  handleFieldChange={handleFieldChange} />
                              )}
                            </FormattedMessage> */}
                        </div>
                      </div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thông báo gia hạn hợp đồng thành công
                        </div>
                        <div className="row col-lg-9">
                          <FormattedMessage id="MODEL.UI.settings.notificationContractExtension">
                            {(msg) => (
                              <Field
                                name="notificationContractExtension"
                                component={CheckboxField}
                                className="ml-3"
                                checked={props.values && props.values.notificationContractExtension}
                                withFeedbackLabel={false}
                                onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="row  py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thông tin hợp đồng đã được chỉnh sửa
                        </div>
                        <div className="row col-lg-9">
                          <FormattedMessage id="MODEL.UI.settings.notificationContractUpdated">
                            {(msg) => (
                              <Field
                                name="notificationContractUpdated"
                                component={CheckboxField}
                                className="ml-3"
                                checked={props.values && props.values.notificationContractUpdated}
                                withFeedbackLabel={false}
                                onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="">
                        <div className=" align-items-start flex-column">
                          <h3 className="row mt-5 text-primary col-lg-12">
                            CẤU HÌNH HÓA ĐƠN
                          </h3>
                          <div className="row col-lg-1">
                            <div className="col-lg-9 mb-2 border border-primary border-2 bg-primary"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row py-3 col-lg-12">
                        <strong><h5>1. Cấu hình mẫu biểu hóa đơn</h5></strong>
                      </div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                        </div>
                        <div className="col-lg-9">
                          <div className="react-bootstrap-table table-responsive">
                            <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center"></th>
                                  <th className="text-center">Tên hóa đơn</th>
                                  <th className="text-center">Mã hóa đơn</th>
                                  <th className="text-center">Độ dài số thứ tự</th>
                                  <th className="text-center">Tiêu đề</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td className="text-left">
                                      Tổng quan
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceSummaryName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceSummaryCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceSummaryCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceSummaryTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Thuê
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceRentName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceRentCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceRentCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceRentTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Cọc trước
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePreDepositName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePreDepositCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoicePreDepositCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoicePreDepositTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Đặt cọc
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceDepositName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceDepositCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceDepositCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceDepositTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Điện
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceElectricityName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceElectricityCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceElectricityCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceElectricityTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Nước
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceWaterName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceWaterCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceWaterCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceWaterTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Gas
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceGasName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceGasCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceGasCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceGasTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Phương tiện
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceVehicleName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceVehicleCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceVehicleCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceVehicleTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Khác
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceOtherName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceOtherCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceOtherCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceOtherTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}

                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Trả trước
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePrepayName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePrepayCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoicePrepayCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoicePrepayTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      Trả phòng
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceCheckoutName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoiceCheckoutCode"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceCheckoutCodeLength"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoiceCheckoutTitle"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                  </tr>
                                </>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="col-lg-3 col-form-label text-lg-left"></div>
                        <div className="col-lg-9">
                          <div className="react-bootstrap-table table-responsive">
                            <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center"></th>
                                  <th className="text-center">Thanh toán tiền mặt</th>
                                  <th className="text-center">Nội dung thanh toán</th>
                                  <th className="text-center">Ghi chú thông tin thanh toán</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td className="text-left">
                                      Nội dung
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePaymentCashContent"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="invoicePaymentBillingContent"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Field
                                        name="invoicePaymentContactContent"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                  </tr>
                                </>
                              </tbody>
                            </Table>
                          </div>
                          <AccordionToken listToken={listTokenInvoice} />
                        </div>
                      </div>
                      <div className="py-3 col-lg-12"><strong><h5>2. Thông báo/Cảnh báo</h5></strong></div>



                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo hóa đơn sắp hết hạn thanh toán</div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationInvoicePreExpired"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trước</span>
                          <Field
                            name="notificationInvoicePreExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày đến khi hết hạn</span>
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo hóa đơn đã quá hạn thanh toán
                        </div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationInvoiceExpired"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trong</span>
                          <Field
                            name="notificationInvoiceExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày sau khi hết hạn</span>
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông tin hóa đơn đã được chỉnh sửa</div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationInvoiceUpdated"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>
                      <div className="py-3 col-lg-12"><strong><h5>3. Cấu hình khác</h5></strong></div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Hiển thị mã
                          <a
                            className="ml-1"
                            href="https://vietqr.net/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <b style={{ color: '#ee2132' }}>Viet</b><b style={{ color: '#1e427e' }}>QR</b>
                          </a>
                          ?</div>

                        <div className="row col-lg-9">
                          <Field
                            name="invoiceIsShowVietQR"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="right"
                            overlay={popover("Trợ giúp", [<FormattedMessage id="INSTRUCTIONS.SETTINGS.VIETQR" />])
                            }
                          >
                            <i className="flaticon2-information d-flex align-items-center  ml-3" type="button"></i>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Tự động xuất phiếu từ GD ngân hàng?</div>
                        <div className="row col-lg-9">
                          <Field
                            name="invoiceIsAutoCreatedVoucher"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <OverlayTrigger
                            trigger={["click", "focus"]}
                            placement="right"
                            overlay={popover("Trợ giúp", [<FormattedMessage id="INSTRUCTIONS.SETTINGS.TRANSACTION" />])
                            }
                          >
                            <i className="flaticon2-information d-flex align-items-center  ml-3" type="button"></i>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="">
                        <div className="form-group d-flex ">
                          <div className=" align-items-start flex-column">
                            <h3 className="px-2 py-2 text-primary text-uppercase">
                              CẤU HÌNH CHỈ SỐ CÔNG TƠ
                            </h3>
                            <div className="col-lg-6">
                              <div className="border border-primary border-2 bg-primary"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" py-2 col-lg-12"><strong><h5>1. Thông báo/Cảnh báo</h5></strong></div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo chỉ số công tơ mới
                        </div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationMeterLogCreated"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>

                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo chỉ số sắp đến hạn chốt</div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationMeterLogPreExpired"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trước</span>
                          <Field
                            name="notificationMeterLogPreExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày đến khi hết hạn</span>
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo chỉ số đã quá hạn chốt
                        </div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationMeterLogExpired"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">Trong</span>
                          <Field
                            name="notificationMeterLogExpiredDays"
                            component={Input}
                            type={"text"}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                            className="form-control col-lg-1"
                          />
                          <span className="mr-3 ml-3 col-form-label text-lg-left">ngày sau khi hết hạn</span>
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông tin hóa đơn đã được chỉnh sửa</div>
                        <div className="row col-lg-9">
                          <Field
                            name="notificationMeterLogUpdated"
                            component={CheckboxField}
                            className="ml-3"
                            withFeedbackLabel={false}
                            onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>
                      <div className=" align-items-start flex-column col-lg-7 text-left">
                        <h3 className="text-primary">
                          CẤU HÌNH PHIẾU THU - PHIẾU CHI
                        </h3>
                        <div className="row col-lg-2">
                          <div className="col-lg-9 mb-2 border border-primary border-2 bg-primary"></div>
                        </div>
                      </div>
                      <div className="py-2 col-lg-12"><b><h5>1. Cấu hình thông tin phiếu</h5></b>
                      </div>
                      <div className="row py-2 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                        </div>
                        <div className="col-lg-9">
                          <div className="react-bootstrap-table table-responsive">
                            <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center">Loại phiếu</th>
                                  <th className="text-center">Tên phiếu</th>
                                  <th className="text-center">Lý do nộp</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td className="text-center">
                                      Phiếu thu
                                    </td>
                                    <td>
                                      <Field
                                        name="voucherReceiptName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="voucherReceiptDescription"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-center">
                                      Phiếu chi
                                    </td>
                                    <td>
                                      <Field
                                        name="voucherPaymentName"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        name="voucherPaymentDescription"
                                        component={Input}
                                        className={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE") ? "form-control form-control-sm input-as-text bg-white" : "form-control form-control-sm"}
                                        type="text"
                                        onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                        disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                                      />
                                    </td>
                                  </tr>
                                </>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                      <div className=" form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">
                          Thu ngân
                        </div>
                        <div className="row col-lg-9 ml-0">
                          {/* <Field
                                  name="voucherCashierId"
                                  component={SelectField}
                                  type={"text"}
                                  minWidth={290}
                                  placeholder={'-- Chọn --'}
                                  options={
                                    [{ label: '-- Chọn --', value: 0 }].concat(users && users.map(function (item) {
                                      return { label: item.fullName, value: item.id }
                                    }))
                                  }
                                  formProps={props}
                                  handleFieldChange={handleFieldChange}
                                /> */}
                          <Field
                            name="voucherCashierId"
                            component={SelectField}
                            type={"text"}
                            minWidth={290}
                            placeholder={'-- Chọn --'}
                            options={
                              [{ label: '-- Chọn --', value: 0 }].concat(users && users.map(function (item) {
                                return { label: item.fullName, value: item.id }
                              }))
                            }
                            formProps={props}
                            handleFieldChange={handleFieldChange}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Kế toán</div>
                        <div className="row col-lg-9 ml-0">
                          <Field
                            name="voucherChiefAccountantId"
                            component={SelectField}
                            type={"text"}
                            minWidth={290}
                            placeholder={'-- Chọn --'}
                            options={
                              [{ label: '-- Chọn --', value: 0 }].concat(users && users.map(function (item) {
                                return { label: item.fullName, value: item.id }
                              }))
                            }
                            formProps={props}
                            handleFieldChange={handleFieldChange}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>
                      <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Giám đốc
                        </div>
                        <div className="row col-lg-9 ml-0">
                          <Field
                            name="voucherDirectorId"
                            component={SelectField}
                            type={"text"}
                            minWidth={290}
                            placeholder={'-- Chọn --'}
                            options={
                              [{ label: '-- Chọn --', value: 0 }].concat(users && users.map(function (item) {
                                return { label: item.fullName, value: item.id }
                              }))
                            }
                            formProps={props}
                            handleFieldChange={handleFieldChange}
                            disabled={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                    <div className="d-flex">
                      <div className="mr-auto p-2 text-reset d-flex align-items-center text-hover-primary" type="button" onClick={() => setIsShowNotification(true)}>Thiết lập lại dữ liệu mặc định?</div>
                      <div className="p-2">
                        <Button
                          className="btn btn-secondary btn-md "
                          handleClick={() => props.handleReset()}
                          message="COMMONS.UI.Reload"
                          icon="flaticon2-refresh-arrow icon-ms"
                        />
                      </div>
                      <div className="p-2">
                        <Button
                          className="btn btn-primary btn-md ml-2 mr-2"
                          handleClick={() => handleSubmit()}
                          message="COMMONS.UI.Save"
                          icon="flaticon-edit-1 icon-ms"
                          hidden={!isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_WRITE")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        }
      </Formik >
    </>
  );
}
