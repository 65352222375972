/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import {
  Input,
  CheckboxField,
  Card,
  CardBody,
  CardHeader,
} from "../../../../_bloomgoo/_partials/controls";
import { FormattedMessage } from "react-intl";
import * as actionsSettings from "../_redux/settings/settingsActions";
import { Table } from "react-bootstrap";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";

export default function SettingsCardBuildings() {
  const dispatch = useDispatch();

  const { settingForEditBuilding } = useSelector(
    (state) => ({
      settingForEditBuilding: state.settings.settingForEditBuilding,
      actionsLoading: state.settings.actionsLoading,
      notification: state.settings.notification,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actionsSettings.fetchEditBuilding());
  }, [dispatch]);

  const saveSettingBuilding = (values) => {
    dispatch(actionsSettings.updateEditBuildings(values));
  };

  const handleFieldChange = (e, props) => { };

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <Card>
        <CardHeader
          title={"Cấu hình chung > Cấu hình tòa nhà"}
          icon={
            <i className="flaticon-cogwheel-1 icon-md text-primary">&nbsp;</i>
          }
        ></CardHeader>
        <CardBody className="card-scroll">
          <div className="nav-tabs-filter"></div>
          <Formik
            enableReinitialize={true}
            initialValues={settingForEditBuilding}
            // validationSchema={buildingSchema}
            onSubmit={(values) => {
              //console.log("Đã submit")
              saveSettingBuilding(values);
            }}
          >
            {({ handleSubmit, handleChange, handleReset, ...props }) => (
              <>
                <div className="form-group">
                  <div className=" row col-lg-12">
                    <div className="py-2 col-lg-12 d-flex justify-content-end pr-7"></div>
                    <div className=" align-items-start flex-column col-lg-7 text-left">
                      <h3 className="text-primary">CẤU HÌNH HỢP ĐỒNG</h3>
                      <div className="row col-lg-2">
                        <div className="col-lg-9 mb-2 border border-primary border-2 bg-primary"></div>
                      </div>
                    </div>
                    <div className="py-2 col-lg-12">
                      <b>
                        <h5>1. Cấu hình mẫu hợp đồng</h5>
                      </b>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left"></div>
                      <div className="col-lg-9">
                        <div className="react-bootstrap-table table-responsive">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">Loại hợp đồng</th>
                                <th className="text-center">Tên hợp đồng</th>
                                <th className="text-center">Mã hợp đồng</th>
                                <th className="text-center">
                                  Độ dài số thứ tự
                                </th>
                                <th className="text-center">Tiêu đề</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td className="text-left">Hợp đồng thuê</td>
                                  <td>
                                    <Field
                                      name="contractRentName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="contractRentCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="contractRentCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="contractRentTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    Hợp đồng đặt cọc
                                  </td>
                                  <td>
                                    <Field
                                      name="contractDepositName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="contractDepositCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="contractDepositCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="contractDepositTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className="py-2 col-lg-12">
                      <strong>
                        <h5>2. Thông báo/Cảnh báo</h5>
                      </strong>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hợp đồng mới
                      </div>
                      <div className="row col-lg-9">
                        <FormattedMessage id="MODEL.UI.settings.notificationContractCreated">
                          {(msg) => (
                            <Field
                              name="notificationContractCreated"
                              component={CheckboxField}
                              className="ml-3"
                              withFeedbackLabel={false}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, props);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hợp đồng sắp hết hạn
                      </div>
                      <div className="row col-lg-9">
                        <Field
                          name="notificationContractPreExpired"
                          component={CheckboxField}
                          className="ml-3"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          Trước
                        </span>
                        <Field
                          name="notificationContractPreExpiredDays"
                          component={Input}
                          type={"text"}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                          className="form-control col-lg-1"
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          ngày đến khi hết hạn
                        </span>
                        {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                          <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                            {(msg) => (
                              <Field
                                name="notificationContractPreExpiredFrequency"
                                component={SelectField}
                                type={"text"}
                                placeholder={'-- Chọn -- '}
                                checkYup={false}
                                options={
                                  [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                    return { label: item.name, value: item.id }
                                  }))
                                }
                                formProps={props}
                                handleFieldChange={handleFieldChange} />
                            )}
                          </FormattedMessage> */}
                      </div>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hợp đồng đã hết hạn
                      </div>

                      <div className="row col-lg-9">
                        <Field
                          name="notificationContractExpired"
                          component={CheckboxField}
                          className="ml-3"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          Trong
                        </span>
                        <Field
                          name="notificationContractExpiredDays"
                          component={Input}
                          type={"text"}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                          className="form-control col-lg-1"
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          ngày sau khi hết hạn
                        </span>
                        {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                          <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                            {(msg) => (
                              <Field
                                name="notificationContractExpiredFrequency"
                                component={SelectField}
                                type={"text"}
                                checkYup={false}
                                placeholder={'-- Chọn -- '}
                                options={
                                  [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                    return { label: item.name, value: item.id }
                                  }))
                                }
                                formProps={props}
                                handleFieldChange={handleFieldChange} />
                            )}
                          </FormattedMessage> */}
                      </div>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo gia hạn hợp đồng thành công
                      </div>
                      <div className="row col-lg-9">
                        <FormattedMessage id="MODEL.UI.settings.notificationContractExtension">
                          {(msg) => (
                            <Field
                              name="notificationContractExtension"
                              component={CheckboxField}
                              className="ml-3"
                              withFeedbackLabel={false}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, props);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="row  py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông tin hợp đồng đã được chỉnh sửa
                      </div>
                      <div className="row col-lg-9">
                        <FormattedMessage id="MODEL.UI.settings.notificationContractUpdated">
                          {(msg) => (
                            <Field
                              name="notificationContractUpdated"
                              component={CheckboxField}
                              className="ml-3"
                              withFeedbackLabel={false}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, props);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="">
                      <div className=" align-items-start flex-column">
                        <h3 className="row mt-5 text-primary col-lg-12">
                          CẤU HÌNH HÓA ĐƠN
                        </h3>
                        <div className="row col-lg-1">
                          <div className="col-lg-9 mb-2 border border-primary border-2 bg-primary"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-3 col-lg-12">
                      <strong>
                        <h5>1. Cấu hình mẫu biểu hóa đơn</h5>
                      </strong>
                    </div>
                    <div className="row py-2 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left"></div>
                      <div className="col-lg-9">
                        <div className="react-bootstrap-table table-responsive">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th className="text-center"></th>
                                <th className="text-center">Tên hóa đơn</th>
                                <th className="text-center">Mã hóa đơn</th>
                                <th className="text-center">
                                  Độ dài số thứ tự
                                </th>
                                <th className="text-center">Tiêu đề</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td className="text-left">Tổng quan</td>
                                  <td>
                                    <Field
                                      name="invoiceSummaryName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceSummaryCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceSummaryCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceSummaryTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Thuê</td>
                                  <td>
                                    <Field
                                      name="invoiceRentName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceRentCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceRentCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceRentTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Cọc trước</td>
                                  <td>
                                    <Field
                                      name="invoicePreDepositName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoicePreDepositCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoicePreDepositCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoicePreDepositTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Đặt cọc</td>
                                  <td>
                                    <Field
                                      name="invoiceDepositName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceDepositCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceDepositCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceDepositTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Điện</td>
                                  <td>
                                    <Field
                                      name="invoiceElectricityName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceElectricityCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceElectricityCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceElectricityTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Nước</td>
                                  <td>
                                    <Field
                                      name="invoiceWaterName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceWaterCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceWaterCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceWaterTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Gas</td>
                                  <td>
                                    <Field
                                      name="invoiceGasName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceGasCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceGasCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceGasTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Phương tiện</td>
                                  <td>
                                    <Field
                                      name="invoiceVehicleName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceVehicleCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceVehicleCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceVehicleTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Khác</td>
                                  <td>
                                    <Field
                                      name="invoiceOtherName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceOtherCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceOtherCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceOtherTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Trả trước</td>
                                  <td>
                                    <Field
                                      name="invoicePrepayName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoicePrepayCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoicePrepayCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoicePrepayTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">Trả phòng</td>
                                  <td>
                                    <Field
                                      name="invoiceCheckoutName"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name="invoiceCheckoutCode"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceCheckoutCodeLength"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <Field
                                      name="invoiceCheckoutTitle"
                                      component={Input}
                                      className="form-control form-control-sm"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleFieldChange(e, props);
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className=" py-2 col-lg-12">
                      <strong>
                        <h5>2. Thông báo/Cảnh báo</h5>
                      </strong>
                    </div>

                    <div className="form-group row py-3 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hóa đơn mới
                      </div>
                      <div className="row col-lg-9">
                        <FormattedMessage id="MODEL.UI.settings.notificationInvoiceCreated">
                          {(msg) => (
                            <Field
                              name="notificationInvoiceCreated"
                              component={CheckboxField}
                              className="ml-3"
                              withFeedbackLabel={false}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, props);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="form-group row py-3 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hóa đơn sắp hết hạn thanh toán
                      </div>
                      <div className="row col-lg-9">
                        <Field
                          name="notificationInvoicePreExpired"
                          component={CheckboxField}
                          className="ml-3"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          Trước
                        </span>
                        <Field
                          name="notificationInvoicePreExpiredDays"
                          component={Input}
                          type={"text"}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                          className="form-control col-lg-1"
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          ngày đến khi hết hạn
                        </span>
                        {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                          <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                            {(msg) => (
                              <Field
                                name="notificationInvoicePreExpiredFrequency"
                                component={SelectField}
                                type={"text"}
                                placeholder={'-- Chọn -- '}
                                checkYup={false}
                                options={
                                  [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                    return { label: item.name, value: item.id }
                                  }))
                                }
                                formProps={props}
                                handleFieldChange={handleFieldChange} />
                            )}
                          </FormattedMessage> */}
                      </div>
                    </div>
                    <div className="form-group row py-3 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông báo hóa đơn đã quá hạn thanh toán
                      </div>
                      <div className="row col-lg-9">
                        <Field
                          name="notificationInvoiceExpired"
                          component={CheckboxField}
                          className="ml-3"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          Trong
                        </span>
                        <Field
                          name="notificationInvoiceExpiredDays"
                          component={Input}
                          type={"text"}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                          className="form-control col-lg-1"
                        />
                        <span className="mr-3 ml-3 col-form-label text-lg-left">
                          ngày sau khi hết hạn
                        </span>
                        {/* <span className="mr-3 ml-8 col-form-label text-lg-left">Tần suất</span>
                          <FormattedMessage id="MODEL.UI.settings.notificationContractPreExpiredFrequency">
                            {(msg) => (
                              <Field
                                name="notificationInvoiceExpiredFrequency"
                                component={SelectField}
                                type={"text"}
                                placeholder={'-- Chọn -- '}
                                checkYup={false}
                                options={
                                  [{ label: '-- Chọn --', value: 0 }].concat(FREQUENCY && FREQUENCY.map(function (item) {
                                    return { label: item.name, value: item.id }
                                  }))
                                }
                                formProps={props}
                                handleFieldChange={handleFieldChange} />
                            )}
                          </FormattedMessage> */}
                      </div>
                    </div>
                    {/* <div className="form-group row py-3 col-lg-12">
                        <div className="col-lg-3 col-form-label text-lg-left">Thông báo hóa đơn được thanh toán thành công
                        </div>
                        <div className="row col-lg-9">
                          <FormattedMessage id="MODEL.UI.settings.isPaySuccess">
                            {(msg) => (
                              <Field
                                name="isPaySuccess"
                                // value={isSelected}
                                component={CheckboxField}
                                // checked={isSelected}
                                withFeedbackLabel={false}
                                onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </div> */}
                    <div className="form-group row py-3 col-lg-12">
                      <div className="col-lg-3 col-form-label text-lg-left">
                        Thông tin hóa đơn đã được chỉnh sửa
                      </div>
                      <div className="row col-lg-9">
                        <Field
                          name="notificationInvoiceUpdated"
                          component={CheckboxField}
                          className="ml-3"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            handleChange(e);
                            handleFieldChange(e, props);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-12">
                    <div className="col-lg-12 text-right">
                      <Button
                        handleClick={() => refreshPage()}
                        message="COMMONS.UI.Reload"
                        icon="flaticon2-refresh-arrow icon-ms"
                        className="btn btn-light btn-elevate btn-md"
                      />
                      &nbsp;
                      <Button
                        // isLoading={actionsLoading}
                        handleClick={() => handleSubmit()}
                        // disabled={actionsLoading}
                        message="COMMONS.UI.Save"
                        icon="flaticon-edit-1 icon-md"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
}
