/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import * as actionsUnits from "../../../../app/modules/Units/_redux/units/unitsActions";
import * as actionsBuildings from "../../../../app/modules/Buildings/_redux/buildings/buildingsActions";
import * as actionsContracts from "../../../../app/modules/Contracts/_redux/contracts/contractsActions";
import * as  actionsAssetInventoryPeriods from "../../../../app/modules/AssetInventoryPeriods/_redux/assetInventoryPeriods/assetInventoryPeriodsActions";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFilterConfig } from '../../../../app/_helpers/LocalStorageConfig';

// import Skeleton from 'react-loading-skeleton';


export function NotFoundTableMini({
    entities,
    isLoading,
    isNav = false,
    type = "",
    user
}) {
    const typesNavBuildings =
        [
            "deskBase",
            "desk",
            "floor",
            "utilities",
            "units",
            "services",
            "meters",
            "vehicleTypes",
            "customers",
            "vehicles",
            "deposits",
            "contracts",
            "meterLogs",
            "meterPartnerLogs",
            "invoices",
        ];
    const typesNavUnits =
        [
            "meters",
            "deposits",
            "contracts",
            "meterLogs",
            "invoices",
        ];
    const typesNavContracts =
        [
            "invoices",
        ];
    const dispatch = useDispatch();
    const { unitsCount, buildingCount, contractsCount, assetInventoryPeriodsCount } = useSelector(
        (state) => ({
            unitsCount: state.units.unitsCount,
            buildingCount: state.buildings.buildingCount,
            contractsCount: state.contracts.contractsCount,
            assetInventoryPeriodsCount: state.assetInventoryPeriods.assetInventoryPeriodsCount,
        }),
        shallowEqual
    );
    useEffect(() => {
        typesNavUnits.includes(type) && getFilterConfig().activeBuildingId && dispatch(actionsUnits.fetchUnitsCount(getFilterConfig().activeBuildingId));
        // typesNavBuildings.includes(type) && dispatch(actionsBuildings.fetchBuildingsCount());
        type === "invoices" && dispatch(actionsContracts.fetchContractsCount(getFilterConfig().activeBuildingId));
        type === "assetInventories" && dispatch(actionsAssetInventoryPeriods.fetchAssetInventoryPeriodsCount());

    }, []);
    const getLinkNav = () => {
        let nextPage = "";
        let message = "COMMONS.UI.Notification.Notfound";
        if (buildingCount === 0) {
            nextPage = "buildings";
            message = "COMMONS.NAVIGATION.Buildings";
        } else if (assetInventoryPeriodsCount === 0) {
            nextPage = "";
            message = "ASSET_INVENTORIES.PERIOD.NONE";
        } else {
            if (unitsCount === 0) {
                nextPage = "units";
                message = "COMMONS.NAVIGATION.Units";
            } else {
                if (contractsCount === 0) {
                    nextPage = "contracts";
                    message = "COMMONS.NAVIGATION.Contracts";
                }
            }
        }
        return { nextPage, message };
    }

    const Navigator = () => {
        if (getLinkNav().nextPage === "buildings" && typesNavBuildings.includes(type)) {
            return (
                <>
                    <Link
                        to={`/buildings`}
                        className="link-primary fw-bolder ml-2"
                    >
                        <FormattedMessage id={getLinkNav().message} />
                    </Link>
                </>
            );
        }
        else if (getLinkNav().nextPage === "units" && typesNavUnits.includes(type)) {
            return (
                <>
                    <Link
                        to={`/units`}
                        className="link-primary fw-bolder ml-2"
                    >
                        <FormattedMessage id={getLinkNav().message} />
                    </Link>
                </>
            );
        } else if (getLinkNav().nextPage === "contracts" && typesNavContracts.includes(type)) {
            return (
                <>
                    <Link
                        to={`/contracts`}
                        className="link-primary fw-bolder ml-2"
                    >
                        <FormattedMessage id={getLinkNav().message} />
                    </Link>
                </>
            );
        } else if (type === "assetInventories" && assetInventoryPeriodsCount === 0) {
            return (
                <span type="button" >
                    <FormattedMessage id={"ASSET_INVENTORIES.PERIOD.NONE"} />
                </span>
            )
        }
        else {
            return (
                <span type="button" >
                    <FormattedMessage id={"COMMONS.UI.Notification.Notfound"} />
                </span>
            )
        }
    }
    // const parseResult = parseDomain(window.location.host.split(":")[0]);

    // const { subDomains,
    //      domain,
    //       topLevelDomains } = parseResult;
    // const code = (subDomains === undefined || subDomains === null || subDomains.length === 0) ? 'chuoicanho' : subDomains[subDomains.length - 1];
    return (
        <>
            {(
                isLoading === false && <div className="d-flex  justify-content-center  align-items-center" >
                    <div className="flex-column">
                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                            <img src={"/media/svg/errors/empty_cart.png"} alt="" width="150px" height="150px" ></img>
                        </span>
                        <div className="text-center text-primary py-5">
                            <span>Không có bản ghi nào được tìm thấy</span>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}
