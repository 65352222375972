import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialFloorsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  floorForEdit: null,
  floorForShow: null,

  filterCount: null,
  notification: null,
  currentFloor: null,
  currentFloors: [],
  error: null,
  isError: null,
  errorType: null,
  alert: { type: '', message: '' }
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const floorsSlice = createSlice({
  name: "floors",
  initialState: initialFloorsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.initialization) {
        state.alert = action.payload.alert;
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
        state.actionsLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.alert = { type: '', message: '' }
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
      } else {
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentFloor = null;
      state.currentFloors = [];
      state.entities = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.isError = null;
      state.floorForEdit = null;
    },

    resetCurrentFloors: (state, action) => {
      state.currentFloor = null;
      state.currentFloors = [];
    },

    // getFloorById
    floorFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.floorForEdit = action.payload.floorForEdit;
      state.error = null;
    },
    //show
    floorShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.floorForShow = action.payload.floorForShow;
      state.error = null;
    },
    // findAllFloors
    floorsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findFloors
    floorsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentFloors
    floorsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createFloor
    floorCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.isError = false;
      state.error = null;

      state.entities.unshift(action.payload.floor);
    },
    // updateFloor
    floorUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.floor.id) {
          return action.payload.floor;
        }
        return entity;
      });
      state.currentFloor = action.payload.floor;
      state.currentFloors.push(action.payload.floor);
    },
    // deleteFloor
    floorDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentFloor = null;
      state.currentFloors = [];
    },
    // deleteFloors
    floorsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentFloor = null;
      state.currentFloors = [];
    },
    // floorsUpdateState
    floorsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.currentFloor = action.payload.floor;
    },
  },
});
