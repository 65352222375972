import React, { useMemo } from "react";
import { DocumentsFilter } from "./DocumentsFilter";
import { DocumentsTable } from "./DocumentsTable";
import { DocumentsLoadingDialog } from "./DocumentsLoadingDialog";
import { DocumentsDeleteDialog } from "./DocumentsDeleteDialog";
import { DocumentDeleteDialog } from "./DocumentDeleteDialog";
import { DocumentsFetchDialog } from "./DocumentsFetchDialog";
import { useDocumentsUIContext } from "./DocumentsUIContext";

export function Documents() {

  return (
    <>
      <DocumentsLoadingDialog />
      <DocumentDeleteDialog />
      <DocumentsDeleteDialog />
      <div className="form margin-b-30">
        <DocumentsFilter />

      </div>
      <DocumentsTable />
    </>
  );
}
