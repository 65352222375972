/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

export const Row = ({ beforeRow, rowIndex, subtotalMoney, setSubTotalMoney }) => {
  const [row, setRow] = useState({});

  useEffect(() => {
    setRow(beforeRow);
  }, []);

  const handleFieldChange = (e, type, rowIndex) => {

    let newValue = e.target.value;

    let afterRow = caculateTotalRow({ ...beforeRow }, newValue);

    afterRow.startNumber = newValue;
    afterRow.amount = newValue;

    setRow(afterRow);

    let afterSubTotalMoney = subtotalMoney + afterRow.amount;
    setSubTotalMoney(afterSubTotalMoney);
  }

  const caculateTotalRow = (beforeRow, type, newValue) => {
    // Nghiệp khó
  };

  return <>
    <tr key={rowIndex} className="font-weight-bolder">
      {/* <td className="font-weight-bold text-muted text-uppercase">
                    <input
                      name={`services[${rowIndex}].startChargeDateString`}
                      value={row.startChargeDateString}
                    />
                    {row.startChargeDateString}
                  </td>
                  <td className="font-weight-bold text-muted text-uppercase">
                    <input
                      name={`services[${rowIndex}].startChargeDateString`}
                      value={row.endChargeDateString}
                    />
                    {row.endChargeDateString}
                  </td> */}
      <td className="font-weight-bold text-muted text-uppercase">
        <input
          name={`services[${rowIndex}].startNumber`}
          value={row.startNumber}
          onChange={e => { handleFieldChange(e, `services[${rowIndex}].startNumber`, rowIndex) }}
        />
        {row.startNumber}</td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].endNumber`}
          value={row.endNumber}
        />{row.endNumber}</td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].amount`}
          value={row.amount}
        />
        {row.amount}
      </td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].unitPrice`}
          value={row.unitPrice}
        />
        {row.unitPrice}
      </td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].amountMoney`}
          value={row.amountMoney}
        />
        {row.amountMoney}
      </td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].adjustmentMoney`}
          value={row.adjustmentMoney}
        />
        {row.adjustmentMoney}
      </td>
      <td className="font-weight-bold text-muted text-uppercase text-right">
        <input
          name={`services[${rowIndex}].totalMoney`}
          value={row.totalMoney}
        />
        {row.totalMoney}
      </td>
    </tr>
  </>;
};
