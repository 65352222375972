import React, { useEffect, useState } from "react";
import "./jQueryGlobal";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-vi-VN";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function TextareaField(props) {
  const {
    field,
    form,
    label,
    //  placeholder,
    className,
    disabled = false,
    required = false,
    handleFieldChange,
    formProps,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type,
    height,
    displayType = "ReactSummernote",
  } = props;

  const { name, value } = field;

  const { errors, touched } = form;

  const onChange = (content) => {
    const changeEvent = {
      target: { name: name, value: content },
    };

    field.onChange(changeEvent);
    form.setFieldTouched(name, true);

    handleFieldChange(changeEvent, formProps);
  };

  // const onInit = (note) => {
  //   console.log("note: ", note)
  //   note.reset();
  //   console.log("value: ", value)
  //   const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i
  //   if (value && value.match(regex) !== null) {
  //     note.replace(value)
  //   } else {
  //     note.insertNode(value)
  //   }
  // }

  return (
    <>
      {label && (
        <label>
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      {displayType === "ReactQuill" && (
        <ReactQuill theme="snow" value={value} onChange={onChange} />
      )}
      {displayType === "ReactSummernote" && (
        <ReactSummernote
          value={value}
          options={{
            lang: "vi-VN",
            height: height,
            toolbar: [
              ["style", ["style"]],
              ["font", ["bold", "underline", "clear"]],
              ["fontname", ["fontname"]],
              ["para", ["ul", "ol", "paragraph"]],
              ["table", ["table"]],
              ["insert", ["link", "picture", "video"]],
              ["view", ["fullscreen", "codeview"]],
            ],
          }}
          rows={5}
          // onInit={onInit}
          disabled={disabled}
          className={getFieldCSSClasses(className, touched[name], errors[name])}
          onChange={onChange}
        />
      )}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[name]}
          touched={touched[name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
