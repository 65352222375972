/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isCollaborator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Collaborator";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";

export function Collaborator() {

  const ORGANIZATION_TYPES = {
    OWNERSHIP: 1,
    SUBSCRIBERS: 2,
  }

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    })
  )

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };


  return (
    <>
      {/* Vai trò môi giới */}
      {isCollaborator(user) && !isAdministrator(user) && (
        <>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/referrals",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/referrals">
              <span className="svg-icon menu-icon">
                <i className="flaticon-network icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="REFERRALS.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/settings/personal",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/settings/personal">
              <span className="svg-icon menu-icon">
                <i className="flaticon-avatar icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="USERS.INFO.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li> */}
        </>
      )}
    </>
  );
}
