/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";
import {
  isPrivilegeUserGroup,
  isPrivilegeUserPacket,
  isUser,
} from "../../../../../../app/_helpers/PrivilegesUIHelper/User";
import { checkIsActive, toAbsoluteUrl } from "../../../../../_helpers";

export function HeaderUser({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  return (
    <>
      {isUser(user) && !isAdministrator(user) && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/#"
            )}`}
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to="/#">
              <i className="flaticon-grid-menu-v2 icon-md text-primary mr-2"></i>
              <span className="menu-text">Nghiệp vụ</span>
            </NavLink>
            <div
              className="menu-submenu menu-submenu-fixed menu-submenu-left"
              style={{ width: "945px" }}
            >
              <div className="menu-subnav">
                <ul className="menu-content">
                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {(isPrivilegeUserGroup(user, "BUILDINGS") ||
                        isPrivilegeUserGroup(user, "UNITS") ||
                        isPrivilegeUserGroup(user, "SERVICES") ||
                        isPrivilegeUserGroup(user, "ASSETS")) && (
                          <h3 className="menu-heading menu-toggle">
                            <i className="menu-bullet menu-bullet-dot">
                              <span></span>
                            </i>
                            <span className="menu-text d-flex justify-content-center">
                              CƠ SỞ
                            </span>
                            <i className="menu-arrow"></i>
                          </h3>
                        )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegeUserPacket({
                          namePrivilege: "BUILDINGS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "BUILDINGS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/buildings"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/buildings"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/buildings.svg"
                                  )}
                                />
                                <span className="menu-text">Tòa nhà</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "UNITS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "UNITS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/units"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/units"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/units.svg"
                                  )}
                                />
                                <span className="menu-text">Đơn vị thuê</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "SERVICES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "SERVICES") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/services"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/services"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/services.svg"
                                  )}
                                />
                                <span className="menu-text">Dịch vụ</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "ASSETS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "ASSETS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/assets"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/assets"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/assets.svg"
                                  )}
                                />
                                <span className="menu-text">Tài sản</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>

                      {isPrivilegeUserGroup(user, "REPORTS") && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">
                            BÁO CÁO & THỐNG KÊ
                          </span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegeUserPacket({
                          namePrivilege: "REPORTS_FINANCIALS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "REPORTS_FINANCIALS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/overview/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/overview/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-overview.svg"
                                  )}
                                />
                                <span className="menu-text">Tài chính</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "REPORTS_STATISTICS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "REPORTS_STATISTICS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/statistics/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/statistics/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-statistics.svg"
                                  )}
                                />
                                <span className="menu-text">Thống kê</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "REPORTS_DEBTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "REPORTS_DEBTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/debts/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/debts/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-debts.svg"
                                  )}
                                />
                                <span className="menu-text">Công nợ</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "REPORTS_TASKS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "REPORTS_TASKS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/task/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/task/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-tasks.svg"
                                  )}
                                />
                                <span className="menu-text">Công việc</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {(isPrivilegeUserGroup(user, "CUSTOMERS") ||
                        isPrivilegeUserGroup(user, "VEHICLES") ||
                        isPrivilegeUserGroup(user, "CONTRACTS") ||
                        isPrivilegeUserGroup(user, "METER_LOGS") ||
                        isPrivilegeUserGroup(user, "INVOICES") ||
                        isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") ||
                        isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS")) && (
                          <h3 className="menu-heading menu-toggle">
                            <i className="menu-bullet menu-bullet-dot">
                              <span></span>
                            </i>
                            <span className="menu-text d-flex justify-content-center">
                              CHO THUÊ
                            </span>
                            <i className="menu-arrow"></i>
                          </h3>
                        )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegeUserPacket({
                          namePrivilege: "CUSTOMERS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "CUSTOMERS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/customers"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/customers"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/customers.svg"
                                  )}
                                />
                                <span className="menu-text">Khách hàng</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "VEHICLES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "VEHICLES") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/vehicles"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/vehicles"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/vehicles.svg"
                                  )}
                                />
                                <span className="menu-text">Phương tiện</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "CONTRACTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "CONTRACTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/contracts"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/contracts"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/contracts.svg"
                                  )}
                                />
                                <span className="menu-text">Hợp đồng</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "DEPOSITS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "DEPOSITS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/deposits"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/deposits"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/contracts.svg"
                                  )}
                                />
                                <span className="menu-text">
                                  Đặt cọc giữ chỗ
                                </span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "METER_LOGS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "METER_LOGS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/meterLogs"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/meterLogs"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/meterLogs.svg"
                                  )}
                                />
                                <span className="menu-text">Chỉ số</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "INVOICES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "INVOICES") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/invoices/actions"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/invoices/actions"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/invoices.svg"
                                  )}
                                />
                                <span className="menu-text">Hóa đơn</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "RECEIPT_VOUCHERS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/receiptVouchers/actions"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/receiptVouchers/actions"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/receiptVouchers.svg"
                                  )}
                                />
                                <span className="menu-text">Phiếu thu</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "PAYMENT_VOUCHERS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/paymentVouchers/actions"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/paymentVouchers/actions"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/paymentVouchers.svg"
                                  )}
                                />
                                <span className="menu-text">Phiếu chi</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    {(isPrivilegeUserGroup(user, "DASHBOARD") ||
                      isPrivilegeUserGroup(user, "USERS") ||
                      isPrivilegeUserGroup(user, "UNIT_SYMBOLS") ||
                      isPrivilegeUserGroup(user, "COLLABORATORS") ||
                      isPrivilegeUserGroup(user, "TASKS") ||
                      isPrivilegeUserGroup(user, "DOCUMENTS")) && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">
                            TỔ CHỨC
                          </span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                    <ul className="menu-inner menu-inner-grid">
                      {isPrivilegeUserPacket({
                        namePrivilege: "ORGANIZATION",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "ORGANIZATION") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/organization"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/organization"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/dashboard.svg"
                                )}
                              />
                              <span className="menu-text">Tổ chức</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegeUserPacket({
                        namePrivilege: "USERS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "USERS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/users"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/users"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/users.svg"
                                )}
                              />
                              <span className="menu-text">Người dùng</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegeUserPacket({
                        namePrivilege: "UNIT_SYMBOLS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "UNIT_SYMBOLS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/unitSymbols"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/unitSymbols"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/unitSymbols.svg"
                                )}
                              />
                              <span className="menu-text">Danh mục</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegeUserPacket({
                        namePrivilege: "COLLABORATORS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "COLLABORATORS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/collaborators"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/collaborators"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/collaborators.svg"
                                )}
                              />
                              <span className="menu-text">Môi giới</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegeUserPacket({
                        namePrivilege: "TASKS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "TASKS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/tasks"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/tasks"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/tasks.svg"
                                )}
                              />
                              <span className="menu-text">Công việc</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegeUserPacket({
                        namePrivilege: "DOCUMENTS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegeUserGroup(user, "DOCUMENTS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/documents"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/documents"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/documents.svg"
                                )}
                              />
                              <span className="menu-text">Tài liệu</span>
                            </NavLink>
                          </li>
                        )}
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      <h3 className="menu-heading menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i>
                        <span className="menu-text d-flex justify-content-center">
                          TIỆN ÍCH
                        </span>
                        <i className="menu-arrow"></i>
                      </h3>

                      <ul className="menu-inner menu-inner-grid">
                        {false &&
                          isPrivilegeUserPacket({
                            namePrivilege: "NEWLETTERS",
                            type: user.organization.subscriberType,
                          }) && // BỎ MENU NEWLETTERS 04/10/2022
                          isPrivilegeUserGroup(user, "NEWLETTERS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/newsLetters"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/newsLetters"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/settings.svg"
                                  )}
                                />
                                <span className="menu-text">Bảng tin</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "SETTINGS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "SETTINGS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/settings/personal"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/settings/personal"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/settings.svg"
                                  )}
                                />
                                <span className="menu-text">Cấu hình</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegeUserPacket({
                          namePrivilege: "POSTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegeUserGroup(user, "POSTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/posts"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/posts"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/posts.svg"
                                  )}
                                />
                                <span className="menu-text">Đăng tin</span>
                              </NavLink>
                            </li>
                          )}

                        {false &&
                          isPrivilegeUserPacket({
                            namePrivilege: "SUPPORTS",
                            type: user.organization.subscriberType,
                          }) && // BỎ MENU SUPPORTS 04/10/2022
                          isPrivilegeUserGroup(user, "SUPPORTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/supports"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/supports"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/supports.svg"
                                  )}
                                />
                                <span className="menu-text">Trung tâm hỗ trợ</span>
                              </NavLink>
                            </li>
                          )}

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/guides"
                          )} menu-link-grid`}
                        >
                          <a
                            className="menu-link menu-link-grid"
                            href="https://chuoicanho.com/ho-tro/"
                            target="_blank"
                          >
                            <img
                              alt=""
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Apps/guides.svg"
                              )}
                            />
                            <span className="menu-text">Hướng dẫn</span>
                          </a>
                        </li>
                      </ul>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          {isPrivilegeUserPacket({
            namePrivilege: "DASHBOARD",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DASHBOARD") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/dashboard"
                )}`}
              >
                <NavLink className="menu-link" to="/dashboard">
                  <i className="flaticon-dashboard icon-md text-primary mr-2"></i>{" "}
                  <span className="menu-text">Bảng quản trị</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "DASHBOARD_BASE",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DASHBOARD_BASE") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/deskbase"
                )}`}
              >
                <NavLink className="menu-link" to="/deskbase/buildings">
                  <i className="flaticon-computer icon-md text-primary mr-2"></i>
                  <span className="menu-text">Quản trị cở sở</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "DESKS",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DESKS") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/desks"
                )}`}
              >
                <NavLink className="menu-link" to="/desks">
                  <i className="flaticon-computer icon-md text-primary mr-2"></i>
                  <span className="menu-text">Bàn làm việc</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {
            isPrivilegeUserPacket({
              namePrivilege: "DASHBOARD_BASE",
              type: user.organization.subscriberType,
            }) && // BỎ MENU BOOKING 04/10/2022
            isPrivilegeUserGroup(user, "DASHBOARD_BASE") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/booking"
                )}`}
              >
                <NavLink className="menu-link" to="/booking">
                  <i className="flaticon-calendar-1 icon-md text-primary mr-2"></i>
                  <span className="menu-text">Booking</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "NOTIFICATIONS",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "NOTIFICATIONS") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/notifications"
                )}`}
              >
                <NavLink className="menu-link" to="/notifications">
                  <i className="flaticon-alert-1 icon-md text-primary mr-2"></i>
                  <span className="menu-text">Thông báo</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/chats"
          )}`}
        >
          <NavLink className="menu-link" to="/chats">

            <i className="flaticon-chat-1 icon-md text-primary mr-2"></i>
            <span className="menu-text">Chat</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li> */}
          {false &&
            isPrivilegeUserPacket({
              namePrivilege: "SUPPORTS",
              type: user.organization.subscriberType,
            }) && // BỎ MENU SUPPORTS 04/10/2022
            isPrivilegeUserGroup(user, "SUPPORTS") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/supports"
                )}`}
              >
                <NavLink className="menu-link" to="/supports">
                  <i className="flaticon-lifebuoy icon-md text-primary mr-2"></i>
                  <span className="menu-text">Trung tâm hỗ trợ</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}
        </ul>
      )}
    </>
  );
}
