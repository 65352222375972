/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { useOutsideEffect } from '../../../../app/_helpers/_CommonUIHelpers';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("custom-is-invalid");
  }

  if (touched && !errors) {
    classes.push("custom-is-valid");
  }

  return classes.join(" ");
};

const getColorValid = (valid, isFocused) => {
  if (valid.includes('is-valid')) {
    return '#007c7e !important'
  }
  else if (valid.includes('is-invalid'))
    return '#F64E60 !important'
  else {
    if (isFocused) {
      return '#00CFA7 !important'
    }
    else {
      return '#E4E6EF !important'
    }
  }
}




export function SelectHover(props) {
  const {
    field,
    form,
    options,
    label,
    isMulti = false,
    placeholder = "-- Chọn --",
    icon,
    autoComplete = "on",
    disabled,
    minWidth = 120,
    required = false,
    handleFieldChange,
    formProps,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type,
    forcusField,
    setForcusField,
    defaultValue,
  } = props;


  const filterExtendStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
      flexDirection: "row-reverse"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#fff'
    })
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    indicatorsContainer: (provided, state) => ({
      ...provided,
      marginBottom: "0.5px"
    }),
  }

  const { name, value } = field;
  const { errors, touched } = form;

  const selectedOption = options && options.filter(option => value !== null && (isMulti ? value.includes(option.value) : option && option.value === value));

  const onChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : selectedOption;

    const multiValue = [];
    isMulti && selectedOption && selectedOption.map((item) => multiValue.push(item.value));

    const changeEvent = {
      target: { name: name, value: selectedValue }
    };

    const changeMultiEvent = {
      target: { name: name, value: multiValue }
    };

    field.onChange(changeEvent);
    form.setFieldTouched(field.name, true);

    form.handleChange(isMulti ? changeMultiEvent : changeEvent);
    handleFieldChange(isMulti ? changeMultiEvent : changeEvent, formProps);
  }

  const handleForcusField = (value) => {
    if (!disabled) {
      let _forcusField = JSON.parse(JSON.stringify(forcusField));
      for (const property in _forcusField) {
        if (property === field.name) _forcusField[property] = true;
        else _forcusField[property] = false;
      }
      _forcusField[field.name] = value;
      setForcusField(_forcusField)
    }
    else { }
  };

  const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className={`${icon} icon-md`} aria-hidden="true" style={{ position: 'initial' }}></i>
      </components.DropdownIndicator>
    );
  };

  const wrapperRef = useRef(null);

  const [fieldValue, setFieldValue] = useState(defaultValue);

  useEffect(() => {
    setFieldValue(field.value)
  }, [field.value]);

  const handleOutSide = () => {
    // setIsForcus(false)
    // fieldValue = defaultValue;
  };

  useOutsideEffect(wrapperRef, handleOutSide);

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      {forcusField?.[field.name] && !disabled ?
        <div
          className="input-group"
          ref={wrapperRef}
        >
          <Select
            id={name}
            {...field}
            menuPlacement="auto"
            value={selectedOption}
            onChange={onChange}
            placeholder={placeholder}
            isDisabled={disabled}
            options={options}
            autoComplete={autoComplete}
            noOptionsMessage={() => "Không có dữ liệu"}
            className={"react-select"}
            styles={icon ? filterExtendStyles : customStyles}
            menuPortalTarget={document.body}
            components={icon && { DropdownIndicator }}
            classNamePrefix={getFieldCSSClasses(touched[field.name], errors[field.name])}
            closeMenuOnSelect={!isMulti}
            isMulti={isMulti}
            isOptionDisabled={(option) => option.isdisabled}
          />
          <div className="input-group-append">
            <span className="input-group-text  form-control form-control-md  d-flex justify-content-center" style={{
              borderRadius: "0px 4px 4px  0px"
            }}>
              <span
                type="button"
                className="flaticon2-check-mark text-success text-success icon-md font-size-icon-md  mr-2"
                style={{
                  fontSize: "0.95rem !important"
                }}
                onClick={() => formProps.handleSubmit()}
              />
              <span
                type="button"
                className="flaticon2-delete text-danger icon-sm"
                se onClick={() => { handleForcusField(false); formProps.handleReset() }}
              />
            </span>
          </div>
        </div>
        :
        <div
          className={disabled ? "input-group input-off-hover" : "input-group"}
          onClick={() => handleForcusField(true)}
        >
          <div className={!disabled ? "form-control form-control-hover" : 'form-control'} style={disabled ? { border: 0 } : {}}>
            {defaultValue}</div>
          {!disabled && <div className="input-group-append"><span className="input-group-text input-group-text-hover"><i className="fas fa-pen" type="button" /></span></div>}
        </div>
      }

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
