import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./documentsCrud";
import { documentsSlice, callTypes } from "./documentsSlice";
// Quản lí những tác động của UI
import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"
import Axios from "axios";

const { actions } = documentsSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentDocuments = () => (dispatch) => {
  dispatch(actions.resetCurrentDocuments());
};

// Area actions fetch list

export const fetchAllDocuments = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllDocuments()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.documentsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllDocuments";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentDocuments = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllDocuments()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.documentsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentDocuments";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list

export const fetchDocuments = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDocuments(queryParams)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total
      }
      dispatch(actions.documentsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchDocuments";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions get item

export const downloadFile = (fileCode, fileName, extension) => (dispatch) => {
  Axios.get(`documents/${fileCode}/download`, {
    responseType: "blob"
  })
    .then((response) => {
      let blob = new Blob([response.data], { type: `${extension}` }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `${fileName}`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      };
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.download" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: "download" }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        console.log("error?.response?.data?.code: ", error?.response)
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.action = "fetchDocuments";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: "download" }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: "download" }));
    });
}

// Area actions create, update item

export const createDocument = (documentForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createDocument(documentForCreation)
    .then((response) => {
      const document = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.documentCreated({ document }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createDocument";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const uploadDocumentFile = (functionName, file) => (dispatch) => {
  console.log("functionName: ", functionName)
  console.log("file: ", file)
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .uploadFile(functionName, file)
    .then((response) => {
      const document = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.upload" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.documentUpdated({ document }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "uploadDocumentFile";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateDocument = (document) => dispatch => {
  const callType = callTypes.exitForm;
  console.log("callTypes")

  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateDocument(document)
    .then((response) => {
      const document = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.upload" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.documentUpdated({ document }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "uploadDocumentFile";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item

export const deleteDocuments = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteDocuments(ids)
    .then(() => {
      dispatch(actions.documentsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteDocuments";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteDocument = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteDocument(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.documentDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteDocument";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area actions  update status item
