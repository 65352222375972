/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { HeaderAdministrator } from "./header-privileges/HeaderAdministrator";
import { HeaderCollaborator } from "./header-privileges/HeaderCollaborator";
import { HeaderPartner } from "./header-privileges/HeaderPartner";
import { HeaderUser } from "./header-privileges/HeaderUser";

export function HeaderMenuHorizontal({ layoutProps }) {

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <HeaderAdministrator layoutProps={layoutProps}/>
      <HeaderUser layoutProps={layoutProps} />
      <HeaderPartner  layoutProps={layoutProps}/>
      <HeaderCollaborator  layoutProps={layoutProps}/>
    </div>
  );
}
