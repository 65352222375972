/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Field, Formik } from "formik";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input, SwitchField } from "../../../../_bloomgoo/_partials/controls";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import * as actions from "../_redux/api-keys/apiKeysActions";
import { ERROR_TYPES_OBJ, useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";


const APIKeySchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .nullable()
        .max(
            5000,
            <FormattedMessage
                id="COMMONS.UI.FeedbackMaxSymbols"
                values={{ params: "5000" }}
            ></FormattedMessage>
        )
        .required(
            <FormattedMessage
                id="MODEL.UI.VALIDATE.Required"
                values={{
                    params: (
                        <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
                    ),
                }}
            ></FormattedMessage>
        ),
});

export default function APIKeyFieldForm(props) {
    const { setIds, setSelectedRows, queryParams } = props;

    const { actionsLoading, apiKeyEdit, errorType, error, action } = useSelector(
        (state) => ({
            actionsLoading: state.apiKeys.actionsLoading,
            apiKeyEdit: state.apiKeys.apiKeyEdit,
            errorType: state.apiKeys.errorType,
            error: state.apiKeys.error,
            action: state.apiKeys.action,
        }),
        shallowEqual
    );

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(actions.getInitAPIKey());
    }, []);

    useDidMountEffect(() => {
        if (errorType === ERROR_TYPES_OBJ.END && (action === "createAPIKey" || action === "updateAPIKey")) {
            dispatch(actions.getInitAPIKey());
            dispatch(actions.fetchAPIKeys(queryParams));
        }
    }, [errorType, error]);

    const saveAPIKey = (apiKey) => {
        if (apiKey.id) {
            dispatch(actions.updateAPIKeyById(apiKey, apiKey.id));
        }
        else {
            dispatch(actions.createAPIKey(apiKey));
        }
    };

    const handleFieldChange = (e, props) => { };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={apiKeyEdit}

                validationSchema={APIKeySchema}
                onSubmit={(values) => {
                    saveAPIKey(values);
                }}
            >
                {({ handleSubmit, handleChange, resetForm, ...rest }) => {
                    return (
                        <>
                            <Modal.Body className="p-0 mt-3">
                                <div className="modal-body container container-fluid">
                                    <div className="row ">
                                        <div className="col-lg-12">
                                            <div className="row  pb-3 ">
                                                <FormattedMessage id="MODEL.UI.apiKeys.name">
                                                    {(msg) => (
                                                        <>
                                                            <div className="col-3 d-flex  align-items-center pl-1">
                                                                <span>
                                                                    {msg}
                                                                    <span className="required h6">
                                                                        {" "}
                                                                        *
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="col-9">
                                                                <Field
                                                                    name="name"
                                                                    component={Input}
                                                                    placeholder={msg}
                                                                    type={"text"}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        handleFieldChange(e, rest);
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </FormattedMessage>
                                            </div>

                                            <div className="row  pb-3 ">
                                                <FormattedMessage id="MODEL.UI.apiKeys.description">
                                                    {(msg) => (
                                                        <>
                                                            <div className="col-3 d-flex mt-2 pl-1">
                                                                <span>
                                                                    {msg}
                                                                </span>
                                                            </div>
                                                            <div className="col-9">
                                                                <Field
                                                                    name="description"
                                                                    component={Input}
                                                                    type={"textarea"}
                                                                    placeholder={msg}
                                                                    handleFieldChange={handleFieldChange}
                                                                    formProps={rest}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </FormattedMessage>

                                            </div>
                                            <div className="row  pb-3 ">
                                                <FormattedMessage id="MODEL.UI.apiKeys.isActive">
                                                    {(msg) => (
                                                        <>
                                                            <div className="col-3 d-flex  align-items-center pl-1">
                                                                <span>{msg}</span>
                                                            </div>
                                                            <div className="col-9">
                                                                <Field
                                                                    name="isActive"
                                                                    component={SwitchField}
                                                                    type={"checkbox"}
                                                                    onChange={e => { handleChange(e); handleFieldChange(e, props) }}
                                                                >
                                                                </Field>
                                                            </div>
                                                        </>
                                                    )}
                                                </FormattedMessage>
                                            </div>
                                            <div className="d-flex justify-content-end my-3">


                                                {apiKeyEdit?.name ?
                                                    <>
                                                        <Button
                                                            disabled={actionsLoading}
                                                            handleClick={() => {
                                                                dispatch(actions.getInitAPIKey());
                                                                setIds([]);
                                                                setSelectedRows([])
                                                            }}
                                                            message="COMMONS.UI.Cancel"
                                                            icon="flaticon-close icon-sm"
                                                            color="danger"

                                                        />
                                                        &nbsp;
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                <Button
                                                    disabled={actionsLoading}
                                                    handleClick={resetForm}
                                                    message="COMMONS.UI.Reset"
                                                    icon="flaticon2-refresh-arrow icon-sm"
                                                    color="secondary"

                                                />
                                                &nbsp;
                                                <Button
                                                    isLoading={actionsLoading}
                                                    handleClick={() => {
                                                        handleSubmit();
                                                    }}
                                                    disabled={actionsLoading}
                                                    message="COMMONS.UI.Save"
                                                    icon="flaticon-edit-1 icon-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </>
                    );
                }}

            </Formik>
        </>
    );
}

