import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../_bloomgoo/_partials/controls";

export function CurrenciesLoadingDialog() {
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.currencies.listLoading }),
    shallowEqual
  );
  useEffect(() => { }, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}
