/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    {
        actions,
        show,
        setShow = () => { },  //giá trị mặc định khi không có props truyền vào PHT
        mode = null, //Nt  PHT
        hidden
    }
) => {

    const setNewAction = (actions) => {
        if (row.isActive) {
            return actions.filter(el => el.message !== "COMMONS.UI.isActive");
        }
        else {
            return actions.filter(el => el.message !== "COMMONS.UI.UnIsActive");
        }

    }

    const _actions = setNewAction(actions) || [];

    const popover = (
        <Popover
            id="popover-basic"
            style={{ maxWidth: "none" }}
        >
            <Popover.Content className="p-1 notEvent">
                {_actions && _actions.length && <ul
                    className="navi navi-hover notEvent"
                    style={{ width: "180px" }}
                >
                    {_actions && _actions.map((item, index) => {
                        if (hidden && !hidden[index]) {
                            console.log("row?.isActive:", row?.isActive ? "Kích hoạt" : "Hủy")
                            return (
                                <li
                                    key={index}
                                    className="navi-item action"
                                    onClick={() => {
                                        setShow(!show);
                                        item.action(item.mode, row.id)
                                    }}
                                >
                                    <span type="button"
                                        className="navi-link action">
                                        <span className="navi-icon action">
                                            <i className={item.icon + " action"}></i>
                                        </span>
                                        {(row?.isActive || mode !== "active") ?
                                            <span className="navi-text action"><FormattedMessage id={item.message}></FormattedMessage></span>
                                            :
                                            <span className="navi-text action"><FormattedMessage id="COMMONS.UI.UnIsActive"></FormattedMessage></span>
                                        }

                                    </span>
                                </li>
                            )
                        }
                    })}
                </ul>}
            </Popover.Content>
        </Popover >
    );
    return (
        <>
            {hidden?.filter(ele => ele === true).length !== hidden?.length &&
                (show ?
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={popover}
                    >
                        <span
                            type="button"
                            className="notEvent">
                            <i className="ki ki-bold-more-hor icon-ms text-hover-primary notEvent"></i>
                        </span>
                    </OverlayTrigger>
                    :
                    <span
                        type="button"
                        className="notEvent"
                        onClick={setShow(!show)}
                    >
                        <i className="ki ki-bold-more-hor icon-ms text-hover-primary notEvent"></i>
                    </span>
                )
            }
        </>
    )
}