import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";
import { STATUS_ASSET_INVENTORY_PERIODS_OBJ } from "../../../AssetInventories/pages/assetInventories/AssetInventoriesUIHelpers";

const initialAssetInventoryPeriodsState = {
  listLoading: null,
  actionsLoading: null,
  tableLoading: null,
  totalCount: 0,
  entities: [],
  entitiesAll: [],
  parents: [],
  assetInventoryPeriodForEdit: null,
  assetInventoryPeriodForShow: null,
  filterCount: null,
  currentAssetInventoryPeriod: null,
  currentAssetInventoryPeriods: [],
  notification: null,
  error: null,
  isError: null,
  errorType: null,
  assetInventoryPeriodsCount: null,
  openingPeriodsStatus: null,
  currentNotOpeningPeriod: null,
  currentStatus: null,
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const assetInventoryPeriodsSlice = createSlice({
  name: "assetInventoryPeriods",
  initialState: initialAssetInventoryPeriodsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },
    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.tableLoading = true;
        // state.entities = [];
      } else {
      }
    },
    startCallParent: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resetModule: (state, action) => {
      state.error = null;
      state.notification = null;
      state.openingPeriodsStatus = null;
      state.currentAssetInventoryPeriod = null;
      state.currentAssetInventoryPeriods = [];


    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.listLoading = null;
      state.tableLoading = null;
      state.actionsLoading = null;
      state.isError = null;
      state.assetInventoryPeriodForEdit = null;
    },
    // getAssetInventoryPeriodById
    assetInventoryPeriodFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.assetInventoryPeriodForEdit = action.payload.assetInventoryPeriodForEdit;
      state.error = null;

    },
    //show
    assetInventoryPeriodShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.assetInventoryPeriodForShow = action.payload.assetInventoryPeriodForShow;
      state.error = null;

    },
    // findAllAssetInventoryPeriods
    assetInventoryPeriodsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesAll = entities;
      state.entities = entities;
      state.openingPeriodsStatus = entities.some(el => el.status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN);
      state.totalCount = totalCount;
    },
    // findAssetInventoryPeriods
    assetInventoryPeriodsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.tableLoading = false;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },
    // findParentAssetInventoryPeriods
    assetInventoryPeriodsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createAssetInventoryPeriod
    assetInventoryPeriodCreated: (state, action) => {
      const status = action.payload.assetInventoryPeriod.status;
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      state.entities.unshift(action.payload.assetInventoryPeriod);
      state.entitiesAll.unshift(action.payload.assetInventoryPeriod);
      state.openingPeriodsStatus = status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN ? status : state.openingPeriodsStatus;
      state.currentAssetInventoryPeriod = action.payload.assetInventoryPeriod;
      state.currentAssetInventoryPeriods.unshift(action.payload.assetInventoryPeriod);
      state.assetInventoryPeriodsCount = state.assetInventoryPeriodsCount + 1;
    },
    // updateAssetInventoryPeriod
    assetInventoryPeriodUpdated: (state, action) => {
      const status = action.payload.assetInventoryPeriod.status;

      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.assetInventoryPeriod.id) {
          return action.payload.assetInventoryPeriod;
        }
        return entity;
      });
      state.entitiesAll = state.entitiesAll.map((entity) => {
        if (entity.id === action.payload.assetInventoryPeriod.id) {
          return action.payload.assetInventoryPeriod;
        }
        return entity;
      });
      state.currentAssetInventoryPeriod = action.payload.assetInventoryPeriod;
      state.currentAssetInventoryPeriods.unshift(action.payload.assetInventoryPeriod);
      state.openingPeriodsStatus = status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN ? status : state.openingPeriodsStatus;

    },
    // deleteAssetInventoryPeriod
    assetInventoryPeriodDeleted: (state, action) => {
      const newEntitiesAll = state.entitiesAll.filter(
        (el) => el.id !== action.payload.id
      );

      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.entitiesAll = newEntitiesAll;
      state.currentAssetInventoryPeriod = null;
      state.currentAssetInventoryPeriods = [];
      state.assetInventoryPeriodsCount = state.assetInventoryPeriodsCount - 1;
      state.openingPeriodsStatus = newEntitiesAll.some(el => el.status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN);

    },
    // deleteAssetInventoryPeriods
    assetInventoryPeriodsDeleted: (state, action) => {
      const newEntitiesAll = state.entitiesAll.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.entitiesAll = newEntitiesAll;
      state.currentAssetInventoryPeriod = null;
      state.currentAssetInventoryPeriods = [];
      state.assetInventoryPeriodsCount = state.assetInventoryPeriodsCount - action.payload.ids.length;
      state.openingPeriodsStatus = newEntitiesAll.some(el => el.status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN);
    },
    // assetInventoryPeriodsUpdateState
    assetInventoryPeriodsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      const { id, status, closingDate, openingDate } = action.payload;
      const newEntitiesAll = state.entitiesAll.map((entity) => {
        if (entity.id == id) {
          entity.status = status;
          entity.closingDate = closingDate;
          entity.openingDate = openingDate;

        }
        return entity;
      });
      state.entities = state.entities.map((entity) => {
        if (entity.id == id) {
          entity.status = status;
          entity.closingDate = closingDate;
          entity.openingDate = openingDate;
        }
        return entity;
      });
      state.entitiesAll = newEntitiesAll;
      console.log(newEntitiesAll.some(el => el.status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN));
      console.log(newEntitiesAll);
      state.openingPeriodsStatus = newEntitiesAll.some(el => el.status === STATUS_ASSET_INVENTORY_PERIODS_OBJ.OPEN);
    },
    assetInventoryPeriodsFetchCount
      : (state, action) => {
        state.assetInventoryPeriodsCount = action.payload.assetInventoryPeriodsCount;
      },
    countAssetInventoryPeriodsClean: (state, action) => {
      state.assetInventoryPeriodsCount = null;
    },
  },
});