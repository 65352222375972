import React from "react";
import SettingsCardAPIKeys from "./SettingsCardAPIKeys";

export default function SettingsAPIKeysPage({ history }) {

  return (
    <>
      {/* <OverviewLoadingDialog /> */}
      <SettingsCardAPIKeys />
    </>
  );
}
