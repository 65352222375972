import React, { useState } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (size, touched, errors, touchedArray, errorsArray, indexArray, nameChildren, checkYup, checkTrue) => {
  const classes = ["form-control", `form-control-${size}`];
  if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren]) {
    checkYup && classes.push("is-invalid");
  };

  if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && !(errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren])) {
    checkTrue && checkYup && classes.push("custom-is-valid");
  };

  if (touched && errors) {
    checkYup && classes.push("is-invalid");
  };

  if (touched && !errors) {
    checkTrue && checkYup && classes.push("is-valid");
  };
  return classes.join(" ");
};

const getPassWordCSSClasses = (touched, errors, isErrors, checkYup, checkTrue) => {
  const classes = ["", ""];
  if (touched && errors) {
    checkYup && classes.push("is-invalid");
  }
  if (touched && !errors) {
    checkTrue && checkYup && classes.push("is-valid");
  }
  if (isErrors === "is-invalid") {
    checkYup && classes.push("is-invalid");
  }
  if (isErrors === "is-valid") {
    checkTrue && checkYup && classes.push("custom-is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  size = "md",
  withFeedbackLabel = true,
  required = false,
  customFeedbackLabel,
  type = "text",
  checkYup = type === "textarea" ? false : true,
  checkTrue = checkYup ? checkYup : false,
  setFieldValue,
  formProps,
  resize = false,
  hiddenArrows = false,
  rows = 3,
  className = "",
  style,
  min,
  max,
  isEdit = true,
  defaultValue = "",
  autoComplete,
  classNameLabel,
  ...props
}) {

  let isError = false

  const [isShowPassWord, setIsShowPassWord] = useState(false)
  const changeShowPassWord = () => {
    setIsShowPassWord(!isShowPassWord);
  };

  let nameArray = "";
  let nameChildren = "";
  let indexArray;
  if (field.name.includes('].')) {
    const str = field.name;
    let position = str.indexOf('[');
    nameArray = str.slice(0, position);
    nameChildren = str.slice(position + 4);
    indexArray = str.slice(position + 1, position + 2);
  };

  const handleUpValue = () => {
    let value = field.value;
    if (value === max) {
      value = min;
    }
    formProps.setFieldValue(`${field.name}`, value + 1)
  };

  const handleDownValue = () => {
    let value = field.value;
    if (value === min) {
      value = max;
    }
    formProps.setFieldValue(`${field.name}`, value - 1)
  };

  return (
    <>
      {label && <label className={classNameLabel}>{label} {required && <span className="required">*</span>}</label>}
      {type !== 'textarea' && type !== "number" && type !== "password" && (
        <input
          type={type}
          style={style}
          className={`${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup, checkTrue)} ${className}`}
          {...field}
          {...props}
          value={
            isEdit ?
              (field.value === null || field.value === undefined) ? "" : field.value
              :
              defaultValue
          }
        />
      )}
      {type === 'textarea' && (
        <textarea
          style={{ resize: resize }}
          type={type}
          rows={rows}
          className={`${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup, checkTrue)} ${className}`}
          {...field}
          {...props}
          value={(field.value === null || field.value === undefined) ? "" : field.value}
        />
      )}
      {type === 'number' && (
        <>
          {hiddenArrows ?
            <input
              type={type}
              min={min}
              max={max}
              style={{ maxWidth: "40px !important" }}
              className={
                `
            ${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup, checkTrue)}
             ${className}
             form-control form-control-md input-number
             `
              }
              {...field}
              {...props}
              value={(field.value === null || field.value === undefined) ? "" : field.value}
            />
            :
            <div className="input-group">
              <input
                type={type}
                min={min}
                max={max}
                style={{ maxWidth: "40px !important" }}
                className={
                  `
            ${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup, checkTrue)}
             ${className}
             form-control form-control-md input-number
             `
                }
                {...field}
                {...props}
                value={(field.value === null || field.value === undefined) ? "" : field.value}
              />
              <div className="input-group-append">
                <span className="input-group-text  form-control form-control-md  d-flex justify-content-center px-6" style={{
                  borderRadius: "0px 4px 4px  0px"
                }}>
                  <span className="mb-4"
                    style={{ position: "absolute" }}
                    type="button"
                    onClick={(e) => !props.disabled && handleUpValue()}
                  >
                    <i
                      className=" icon-up-down flaticon2-up icon-sm w-100 d-block"
                      style={{
                        color: props.disabled && "#b5b5c3"
                      }}
                    />
                  </span>
                  <span className="mt-4"
                    style={{ position: "absolute" }}
                    type="button"
                    onClick={(e) => !props.disabled && handleDownValue()}
                  >
                    <i
                      className="icon-up-down flaticon2-down icon-sm w-100 d-block"
                      style={{
                        color: props.disabled && "#b5b5c3"
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
          }
        </>
      )}

      {type === 'password' && (
        <div className="input-icon input-icon-right">
          <input
            type={isShowPassWord ? "text" : "password"}
            className={`${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup, checkTrue)} ${className}`}
            {...field}
            autoComplete={autoComplete}
            {...props}
            value={(field.value === null || field.value === undefined) ? "" : field.value}
          />
          <span className={getPassWordCSSClasses(touched[field.name], errors[field.name], isError, checkYup)}>
            <i className={`${isShowPassWord ? "fas fa-eye" : "fas fa-eye-slash"} icon-md `} type="button" onClick={() => changeShowPassWord()}></i>
          </span>
        </div>
      )}

      {false && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
