import React from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../_bloomgoo/_helpers";
import SVG from "react-inlinesvg";


export const HeaderColumnFormatter = (column, colIndex, components) => {
    // //console.log("column", column,
    //     "colIndex", colIndex,
    //     "components", components);
    let order = components?.sortElement?._owner?.pendingProps?.sortOrder;
    const sortCaret = (order) => {
        if (!order)
            return (
                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")} />
                </span>
            );
        else if (order === "asc")
            return (
                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} />
                </span>
            );
        else if (order === "desc")
            return (
                <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")} />
                </span>
            );
        return null;
    };
    return (
        <>
            <span className="d-inline-block "><FormattedMessage id={column.text}></FormattedMessage></span>
            {sortCaret(order)}
        </>
    )
}

