import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialFormulasState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  formulaForEdit: null, mulaForShow: null,

  filterCount: null,
  notification: null,
  error: null,
  currentFormula: null,
  currentFormulas: [],
  isError: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const formulasSlice = createSlice({
  name: "formulas",
  initialState: initialFormulasState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentFormula = null;
      state.currentFormulas = [];
    },

    resetModalShow: (state, action) => {
      state.errorType = null;
      state.isError = null;
      state.formulaForShow = null;
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.formulaForEdit = null;
    },

    resetCurrentFormulas: (state, action) => {
      state.currentFormula = null;
      state.currentFormulas = [];
    },


    // getFormulaById
    formulaFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.formulaForEdit = action.payload.formulaForEdit;
      state.error = null;

    },

    //show
    getFormular: (state, action) => {
      state.actionsLoading = false;
      state.formulaForShow = action.payload.formulaForShow;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },
    // findAllFormulas
    formulasFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findFormulas
    formulasFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },
    // findParentFormulas
    formulasParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createFormula
    formulaCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.formula);
      state.currentFormula = action.payload.formula;
      state.currentFormulas.push(action.payload.formula);

    },
    // updateFormula
    formulaUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.formula.id) {
          return action.payload.formula;
        }
        return entity;
      });
      state.currentFormula = action.payload.formula;
      state.currentFormulas.push(action.payload.formula);
    },
    // deleteFormula
    formulaDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentFormula = null;
      state.currentFormulas = [];

    },
    // deleteFormulas
    formulasDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentFormula = null;
      state.currentFormulas = [];
    },
    // formulasUpdateState
    formulasStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.currentFormula = action.payload.formula;
    },
  },
});
