import React from "react";
import { SettingsUIProvider } from "../SettingsUIContext";
// import { OverviewLoadingDialog } from "./overview-loading-dialog/OverviewLoadingDialog";
import SettingsCardDecentralization from "./SettingsCardDecentralization";

export default function DecentralizationPage({ history }) {
  const settingsUIEvents = {
    tabPeronal2ButtonClick: () => {
      history.push("/settings/personal");
    },
    tabFunctionsButtonClick: () => {
      history.push("/settings/functions");
    },
    tabEmailsButtonClick: () => {
      history.push("/settings/emails");
    },
    tabDecentralizationButtonClick: () => {
      history.push("/settings/decentralization");
    },
    tabCurrenciesButtonClick: () => {
      history.push("/settings/currencies");
    },
    tabBuildingButtonClick: () => {
      history.push("/settings/building");
    },
  };
  return (
    <>
      {/* <OverviewLoadingDialog /> */}
      <SettingsUIProvider settingsUIEvents={settingsUIEvents}>
        <SettingsCardDecentralization />
      </SettingsUIProvider>
    </>
  );
}
