/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_bloomgoo/_partials/controls";
import { EmailFieldForm } from "./EmailFieldForm";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";
import SettingsEmailsDialog from "./SettingsEmailsDialog";
import { getHeaderSettings } from "../SettingsUIHelpers";

export default function SettingsCardEmails() {

  const { user } =
    useSelector(
      (state) => ({
        user: state.auth.user,
      }),
      shallowEqual
    );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.newEmailTemplate());
  }, [dispatch]);
  const history = useHistory();

  let location = useLocation();

  const [collapse, setCollapse] = useState({
    intro: true,
    owner: true,
    extend: true,
    extendIntro: true,
    extendDetail: true,
    extendPolicy: true,
    extendLatitude: true,
    decentralization: true,
    attachment: true,
  });

  const handleCollapse = (name) => {
    const _collapse = { ...collapse };
    _collapse[name] = !_collapse[name];
    setCollapse(_collapse);
  };

  const getIconCollapse = (name) => {
    if (collapse[name])
      return (
        <i className="fas fa-caret-square-right mr-2 text-hover-primary" />
      );
    else return <i className="fas fa-caret-square-down mr-2 text-primary" />;
  };
  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="btn-group" role="group" aria-label="...">
              {getHeaderSettings({ user, history, location })}
            </div>
          }
        ></CardHeader>
        <CardBody className="pt-0 card-scroll">
          <div className="row d-flex mt-5">
            <div className="col-lg-8">
              <div className="col-lg-12 text-right">
                <EmailFieldForm />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                data-toggle="collapse"
                href="#collapseServer"
                role="button"
                aria-expanded="false"
                aria-controls="collapseServer"
                onClick={() => handleCollapse("server")}
              >
                {getIconCollapse("server")}
                <b>Thiết lập</b>
              </div>
              <div className="collapse show" id="collapseServer">
                <SettingsEmailsDialog />
              </div>
              <div className="gutter-b"></div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
