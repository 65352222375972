import React from "react";
import { FormattedMessage } from "react-intl";

export const SupportStatusCssClasses = ["success", "info", ""];
export const SupportStatusTitles = ["Selling", "Sold"];
export const SupportConditionCssClasses = ["success", "danger", ""];
export const SupportConditionTitles = ["New", "Used"];
export const StatusSupportConditionCssClasses = ["", "danger", "warning", "success", "light", "secondary"];
export const StatusSupportConditionTitles = ["", "Vừa khởi tạo", "Đang xử lý", "Đã trả lời", "Đã đóng", "Đã hủy"];
export const IsActiveSupportConditionCssClasses = ["success", "danger"];
export const IsActiveSupportConditionTitles = ["Kích hoạt", "Vô hiệu"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];
export const initialFilter = {
  filter: {
    searchCriteria: [
      {
        key: "status",
        operation: ":",
        value: "",
        prefix: "",
        suffix: ""
      }
    ]
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 35,
};

export const ACTIVE_STATUSES = [
  { id: 1, name: <FormattedMessage id="COMMONS.UI.SELECT.Active"></FormattedMessage> },
  { id: 2, name: <FormattedMessage id="COMMONS.UI.SELECT.InActive"></FormattedMessage> }
]
export const SUPPORTS_STATUSES = [
  { id: 1, name: "Vừa khởi tạo" },
  { id: 2, name: "Đang xử lý" },
  { id: 3, name: "Đã trả lời" },
  { id: 4, name: "Đã đóng" },
  { id: 5, name: "Đã hủy" },
]


export const SUPPORTS_PRIORITY = [
  { id: 1, name: "Thấp" },
  { id: 2, name: "Bình thường" },
  { id: 3, name: "Cao" },
  { id: 4, name: "Khẩn cấp" },
]

export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise",
];

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota",
];

const SUPPORTS_COLUMN_TOGGLE_CONFIG_KEY =
    process.env.REACT_APP_SUPPORTS_COLUMN_TOGGLE_CONFIG_KEY ||
    "SupportColumnToggleConfig";

export function getColumnToggleConfig() {
  const ls = localStorage.getItem(SUPPORTS_COLUMN_TOGGLE_CONFIG_KEY);
  if (ls) {
      try {
          return JSON.parse(ls);
      } catch (er) {
          console.error(er);
      }
  }
  return {
    userId: true,
    contactName: true,
    status: false,
    createdAt: false,
    createdBy: true,
    updatedAt: true,
    updatedBy: true,
  };
}
export function setColumnToggleConfig(key, value) {
  const SupportColumnToggleConfig = getColumnToggleConfig();
  SupportColumnToggleConfig[key] = value;
  localStorage.setItem(
    SUPPORTS_COLUMN_TOGGLE_CONFIG_KEY,
      JSON.stringify(SupportColumnToggleConfig)
  );
}
