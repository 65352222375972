/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, checkMenuIsActive } from "../../../../../_helpers";
import {
  isPrivilegeUserGroup,
  isUser,
  isPrivilegeUserPacket,
} from "../../../../../../app/_helpers/PrivilegesUIHelper/User";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";

export function User() {
  const getDotActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? "#3CFF4F" : "white";
  };

  const getMenuActive = (url, hasSubmenu = false) => {
    return checkMenuIsActive(location, url)
      ? `menu-item-active menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"
      } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const getListMenuItemActive = (listUrl, hasSubmenu = false) => {
    if (listUrl.filter((url) => checkIsActive(location, url)).length > 0) {
      return `${!hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`;
    } else {
      return "";
    }
  };

  const subscriberType = user.organization.subscriberType;
  return (
    <>
      {/* Vai trò nhân viên */}
      {isUser(user) && !isAdministrator(user) && (
        <>
          {isPrivilegeUserPacket({ namePrivilege: "DASHBOARD", type: subscriberType }) &&
            isPrivilegeUserGroup(user, "DASHBOARD") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/dashboard",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/dashboard">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-dashboard icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Bảng quản trị</span>
                </NavLink>
              </li>
            )}

          {/* {isPrivilegeUserPacket({
            namePrivilege: "DASHBOARD_BASE",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DASHBOARD_BASE") && (
              <li
                className={`menu-item ${getMenuItemActive("/deskbase", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/deskbase">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-computer icon-md text-white"></i>
                  </span>
                  <span className="menu-text">Quản trị cơ sở</span>
                </NavLink>
              </li>
            )} */}

          {isPrivilegeUserPacket({
            namePrivilege: "DASHBOARD_BASE",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DASHBOARD_BASE") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/deskbase",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/deskbase/buildings">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-computer icon-md text-white"></i>
                  </span>
                  <span className="menu-text">Quản trị cơ sở</span>
                </NavLink>
              </li>
            )}

          {false &&
            isPrivilegeUserPacket({
              // BỎ MENU BOOKING 04/10/2022
              namePrivilege: "DASHBOARD_BASE",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "DASHBOARD_BASE") && (
              <li
                className={`menu-item ${getMenuItemActive("/booking", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/booking">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-calendar-1 icon-md text-white"></i>
                  </span>
                  <span className="menu-text">Booking</span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "DESKS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DESKS") && (
              <li
                className={`menu-item ${getMenuItemActive("/desks", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/desks">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-computer icon-md text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="DESKS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/* {isPrivilegeUserPacket({ namePrivilege: "NOTIFICATIONS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "NOTIFICATIONS")) &&
            <li
              className={`menu-item ${getMenuItemActive("/notifications", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/notifications">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-alert-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Bảng thông báo</span>
              </NavLink>
            </li>
          } */}

          {isPrivilegeUserPacket({
            namePrivilege: "SETTINGS_PROFILE",
            type: subscriberType,
          }) &&
            !isPrivilegeUserGroup(user, "SETTINGS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/settings/personal",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/settings/personal"
                >
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-avatar icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="USERS.INFO.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/*------------------------------------ Quản lý bảng hàng -------------------------- */}
          {false &&
            (isPrivilegeUserGroup(user, "REALTIME") || // BỎ MENU BẢNG HÀNG 04/10/2022
              isPrivilegeUserGroup(user, "UNITCARD")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý bảng hàng</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {false &&
            isPrivilegeUserPacket({
              // BỎ MENU BẢNG HÀNG 04/10/2022
              namePrivilege: "REALTIME",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "REALTIME") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/real-time",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/real-time">
                  <span className="svg-icon menu-icon">
                    <i className="fas fa-clipboard-list icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Bảng hàng</span>
                </NavLink>
              </li>
            )}
          {/* {isPrivilegeUserPacket({ namePrivilege: "UNITCARD", type: subscriberType })
            && (isPrivilegeUserGroup(user, "UNITCARD")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/unit-card",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/unit-card">
                <span className="svg-icon menu-icon">
                  <i className="flaticon2-menu-4 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Bán phòng
                </span>
              </NavLink>
            </li>
          } */}

          {/*------------------------------------ Quản lý tòa nhà -------------------------- */}

          {(isPrivilegeUserGroup(user, "BUILDINGS") ||
            isPrivilegeUserGroup(user, "FLOORS") ||
            isPrivilegeUserGroup(user, "UTILITIES") ||
            isPrivilegeUserGroup(user, "UNITS") ||
            isPrivilegeUserGroup(user, "METERS") ||
            isPrivilegeUserGroup(user, "SERVICES") ||
            isPrivilegeUserGroup(user, "VEHICLE_TYPES") ||
            isPrivilegeUserGroup(user, "CONTRACT_FORMS") ||
            isPrivilegeUserGroup(user, "BANK_ACCOUNTS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý cơ sở</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "BUILDINGS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "BUILDINGS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/buildings",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/buildings">
                  <span className="svg-icon menu-icon">
                    <i className="far fa-building icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="BUILDINGS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "UNITS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "UNITS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/units",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/units">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-home-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="UNITS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "METERS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "METERS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/meters",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/meters">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-time-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="METERS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "SERVICES",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "SERVICES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/services",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/services">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-clipboard icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICES.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {(isPrivilegeUserGroup(user, "FLOORS") ||
            isPrivilegeUserGroup(user, "UTILITIES") ||
            isPrivilegeUserGroup(user, "VEHICLE_TYPES") ||
            isPrivilegeUserGroup(user, "CUSTOM_FIELDS") ||
            isPrivilegeUserGroup(user, "BANK_ACCOUNTS") ||
            isPrivilegeUserGroup(user, "CUSTOM_FIELDS")) && (
              <li
                className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                  [
                    "/floors",
                    "/utilities",
                    "/vehicleTypes",
                    "/contractForms",
                    "/bankAccounts",
                    "/customFields",
                  ],
                  false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <span type="button" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-more-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Khác</span>
                  <i className="menu-arrow text-white"></i>
                </span>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    {isPrivilegeUserPacket({
                      namePrivilege: "FLOORS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "FLOORS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/floors",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/floors"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/floors",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">Tầng</span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "UTILITIES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "UTILITIES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/utilities",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/utilities"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/utilities",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              <FormattedMessage id="UTILITIES.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "VEHICLE_TYPES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "VEHICLE_TYPES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/vehicleTypes",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/vehicleTypes"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/vehicleTypes",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              <FormattedMessage id="VEHICLE_TYPES.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "CONTRACT_FORMS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "CONTRACT_FORMS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/contractForms",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/contractForms"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/contractForms",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              <FormattedMessage id="CONTRACT_FORMS.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "BANK_ACCOUNTS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "BANK_ACCOUNTS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/bankAccounts",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/bankAccounts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/bankAccounts",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              <FormattedMessage id="BANK_ACCOUNTS.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "CUSTOM_FIELDS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "CUSTOM_FIELDS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/customFields",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/buildings/customFields"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/customFields",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              <FormattedMessage id="CUSTOM_FIELDS.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )}
          {/*------------------------------------ Quản lý khách hàng -------------------------- */}

          {(isPrivilegeUserGroup(user, "CUSTOMERS") ||
            isPrivilegeUserGroup(user, "VEHICLES")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý khách hàng</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "CUSTOMERS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "CUSTOMERS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/customers",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/customers">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-customer icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="CUSTOMERS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/* {(isPrivilegeUserPacket({ namePrivilege: "PROFILE", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "PROFILE")) && */}
          {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive("/customer-profiles", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/customer-profiles">
              <span className="svg-icon menu-icon">
                <i className="flaticon-customer icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="CUSTOMER_PROFILES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li> */}
          {/* )} */}

          {/* {(isPrivilegeUserPacket({ namePrivilege: "PROFILE", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "PROFILE")) && */}
          {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive("/entry-exit-histories", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/entry-exit-histories">
              <span className="svg-icon menu-icon">
                <i className="flaticon-calendar-with-a-clock-time-tools icon-lg text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="CUSTOMER_HISTORIES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li> */}
          {/* )} */}

          {isPrivilegeUserPacket({
            namePrivilege: "VEHICLES",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "VEHICLES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/vehicles",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/vehicles">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-truck icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="VEHICLES.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/*------------------------------------ Quản lý hợp đồng -------------------------- */}
          {(isPrivilegeUserGroup(user, "DEPOSITS") ||
            isPrivilegeUserGroup(user, "CONTRACTS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý hợp đồng</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "DEPOSITS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DEPOSITS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/deposits",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/deposits">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-clock-2 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="DEPOSITS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "CONTRACTS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "CONTRACTS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/contracts",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/contracts">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-edit-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="CONTRACTS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/*------------------------------------ Quản lý tài chính -------------------------- */}

          {(isPrivilegeUserGroup(user, "METER_LOGS") ||
            isPrivilegeUserGroup(user, "METER_PARTNER_LOGS") ||
            isPrivilegeUserGroup(user, "INVOICES") ||
            isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") ||
            isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý tài chính</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "METER_LOGS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "METER_LOGS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/meterLogs",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/meterLogs">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-time icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="METER_LOGS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {false &&
            isPrivilegeUserPacket({
              // BỎ MENU METER_PARTNER_LOGS 04/10/2022
              namePrivilege: "METER_PARTNER_LOGS",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "METER_PARTNER_LOGS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/meterPartnerLogs",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/meterPartnerLogs"
                >
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-time icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="METER_PARTNER_LOGS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserGroup(user, "INVOICES") && (
            <li
              className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                ["/invoices/actions", "/invoices/all"],
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-file-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Hóa đơn</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "INVOICES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "INVOICES") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/invoices/actions",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/invoices/actions"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/invoices/actions",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="INVOICES.UI.Title.Actions"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "INVOICES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "INVOICES") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/invoices/all",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/invoices/all"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/invoices/all",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="INVOICES.UI.Title.All"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}
          {/* {(isPrivilegeUserPacket({ namePrivilege: "INVOICES", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "INVOICES")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/invoices/actions",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/invoices/actions">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-file-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="INVOICES.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )} */}

          {isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS") && (
            <li
              className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                ["/receiptVouchers/actions", "/receiptVouchers/all"],
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-piggy-bank icon-lg text-white"></i>
                </span>
                <span className="menu-text">Phiếu thu</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "RECEIPT_VOUCHERS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/receiptVouchers/actions",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/receiptVouchers/actions"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/receiptVouchers/actions",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="RECEIPT_VOUCHERS.UI.Title.Actions"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "RECEIPT_VOUCHERS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "RECEIPT_VOUCHERS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/receiptVouchers/all",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/receiptVouchers/all"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/receiptVouchers/all",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="RECEIPT_VOUCHERS.UI.Title.All"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") && (
            <li
              className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                ["/paymentVouchers/actions", "/paymentVouchers/all"],
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-price-tag icon-lg text-white"></i>
                </span>
                <span className="menu-text">Phiếu chi</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "PAYMENT_VOUCHERS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/paymentVouchers/actions",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/paymentVouchers/actions"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/paymentVouchers/actions",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="PAYMENT_VOUCHERS.UI.Title.Actions"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "PAYMENT_VOUCHERS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "PAYMENT_VOUCHERS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/paymentVouchers/all",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/paymentVouchers/all"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/paymentVouchers/all",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            {" "}
                            <FormattedMessage id="PAYMENT_VOUCHERS.UI.Title.All"></FormattedMessage>
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {/* {isPrivilegeUserPacket({ namePrivilege: "TRANSACTION_HISTORIES", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "TRANSACTION_HISTORIES")) && */}
          <li
            className={`menu-item ${getMenuItemActive(
              "/transaction-histories",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/transaction-histories">
              <span className="svg-icon menu-icon">
                <i className="fas fa-business-time icon-md text-white"></i>
              </span>
              <span className="menu-text">
                <FormattedMessage id="TRANSACTION_HISTORIES.UI.Title"></FormattedMessage>
              </span>
            </NavLink>
          </li>
          {/* } */}

          {/*------------------------------------ Quản lý tài sản -------------------------- */}
          {(isPrivilegeUserGroup(user, "SUPPLIES") ||
            isPrivilegeUserGroup(user, "ASSET_WAREHOUSES") ||
            isPrivilegeUserGroup(user, "ASSET_CATEGORIES") ||
            isPrivilegeUserGroup(user, "ASSET_STATUSES") ||
            isPrivilegeUserGroup(user, "ASSETS") ||
            isPrivilegeUserGroup(user, "ASSET_SEQUENCES") ||
            isPrivilegeUserGroup(user, "ASSET_INVENTORIES")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý tài sản</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "ASSETS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "ASSETS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/assets",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/assets">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-tool icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="ASSETS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "ASSET_SEQUENCES",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "ASSET_SEQUENCES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/assetSequences",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/assetSequences">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-share icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="ALLOCATIONS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {false &&
            isPrivilegeUserPacket({
              // BỎ MENU ASSET_INVENTORIES 04/10/2022
              namePrivilege: "ASSET_INVENTORIES",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "ASSET_INVENTORIES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/assetInventories",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/assetInventories"
                >
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-interface-10 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="ASSET_INVENTORIES.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {(isPrivilegeUserGroup(user, "SUPPLIES") ||
            isPrivilegeUserGroup(user, "ASSET_WAREHOUSES") ||
            isPrivilegeUserGroup(user, "ASSET_CATEGORIES") ||
            isPrivilegeUserGroup(user, "ASSET_STATUSES")) && (
              <li
                className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                  [
                    "/suppliers",
                    "/assetWarehouses",
                    "/assetCategories",
                    "/assetStatuses",
                  ],
                  false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <span type="button" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-more-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Danh mục tài sản</span>
                  <i className="menu-arrow text-white"></i>
                </span>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    {isPrivilegeUserPacket({
                      namePrivilege: "SUPPLIES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "SUPPLIES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/suppliers",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/suppliers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/suppliers",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              {" "}
                              <FormattedMessage id="SUPPLIERS.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "ASSET_WAREHOUSES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "ASSET_WAREHOUSES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/assetWarehouses",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/assetWarehouses"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/assetWarehouses",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              {" "}
                              <FormattedMessage id="ASSET_WAREHOUSE.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "ASSET_CATEGORIES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "ASSET_CATEGORIES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/assetCategories",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/assetCategories"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/assetCategories",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              {" "}
                              <FormattedMessage id="ASSET_CATEGORIES.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {isPrivilegeUserPacket({
                      namePrivilege: "ASSET_STATUSES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "ASSET_STATUSES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/assetStatuses",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/assetStatuses"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/assetStatuses",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              {" "}
                              <FormattedMessage id="ASSET_STATUSES.UI.Title"></FormattedMessage>
                            </span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )}

          {/*------------------------------------ Quản lý hàng hóa -------------------------- */}
          {/* {(isPrivilegeUserGroup(user, "SUPPLIES") ||
            isPrivilegeUserGroup(user, "ASSET_WAREHOUSES")) && (
            <li className="menu-section mt-0">
              <h4 className="menu-text">
                <span className="text-menu">Quản lý hàng hóa</span>
              </h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          )} */}

          {/* {isPrivilegeUserPacket({
            namePrivilege: "ASSETS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "ASSETS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/goods",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/goods">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon2-box-1 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="GOODS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "GOOD_CATEGORIES",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "GOOD_CATEGORIES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/goodCategories",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/goodCategories">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-background icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="GOODS_GROUPS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )} */}

          {/* {isPrivilegeUserPacket({
            namePrivilege: "ASSET_SEQUENCES",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "ASSET_SEQUENCES") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/good-sequences",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/good-sequences">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon2-supermarket icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="GOODS_SEQUENCES.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )} */}

          {/*------------------------------------ Quản lý công việc -------------------------- */}
          {(isPrivilegeUserGroup(user, "PROJECTS") ||
            isPrivilegeUserGroup(user, "TASKS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý công việc</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "PROJECTS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "PROJECTS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/projects",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/projects">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-list-3 icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="PROJECTS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "TASKS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "TASKS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/tasks",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/tasks">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-graphic icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="TASKS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/* {(isPrivilegeUserPacket({ namePrivilege: "TROUBLES", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "TROUBLES")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/troubles",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/troubles">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-open-box icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Sự cố
                </span>
              </NavLink>
            </li>
          )} */}

          {/*------------------------------------ Quản lý môi giới -------------------------- */}

          {(isPrivilegeUserGroup(user, "COLLABORATORS") ||
            isPrivilegeUserGroup(user, "USER_COMMISSIONS") ||
            isPrivilegeUserGroup(user, "COMMISSIONS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Quản lý môi giới</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "COLLABORATORS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "COLLABORATORS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/collaborators",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/collaborators">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon2-group icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="COLLABORATORS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "USER_COMMISSIONS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "USER_COMMISSIONS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/userCommissions",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/userCommissions"
                >
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-medal icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="COLLABROTOR_COMMISSIONS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {isPrivilegeUserPacket({
            namePrivilege: "COMMISSIONS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "COMMISSIONS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/commissions",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/commissions">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-confetti icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="COMMISSIONS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/*------------------------------------ Quản lý tổ chức -------------------------- */}
          {/* {
            (
              isPrivilegeUserGroup(user, "ORGANIZATIONS") ||
              isPrivilegeUserGroup(user, "DEPARTMENTS") ||
              isPrivilegeUserGroup(user, "POSITIONS") ||
              isPrivilegeUserGroup(user, "ROLES") ||
              isPrivilegeUserGroup(user, "USERS")
            )
            &&
            (
              <li className="menu-section mt-0">
                <h4 className="menu-text"><span className="text-menu">Quản lý tổ chức</span></h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )
          }

          {isPrivilegeUserPacket({ namePrivilege: "ORGANIZATION", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "ORGANIZATION")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive("/organization", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link menu-toggle" to="/organization">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-globe icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="ORGANIZATIONS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          }

          {(isPrivilegeUserPacket({ namePrivilege: "DEPARTMENTS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "DEPARTMENTS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/departments",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/departments">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-map icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="DEPARTMENTS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "POSITIONS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "POSITIONS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/positions",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/positions">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-technology-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="POSITIONS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "ROLES", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "ROLES")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/roles",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/roles">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-user-ok icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="ROLES.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "USERS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "USERS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/users",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/users">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-users icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="USERS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )} */}

          {/* {(isPrivilegeUserPacket({ namePrivilege: "COUPONS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "COUPONS")) &&

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/coupons",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/coupons">
                <span className="svg-icon menu-icon">
                  <i className="fas fa-ticket-alt icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="COUPONS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )} */}

          {/*------------------------------------ Báo cáo & thống kê -------------------------- */}

          {isPrivilegeUserGroup(user, "REPORTS") && (
            <li className="menu-section mt-0">
              <h4 className="menu-text">
                <span className="text-menu">Báo cáo & thống kê</span>
              </h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_FINANCIALS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/overview",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-diagram icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo tài chính</span>
                <i className="menu-arrow text-white"></i>
              </span>

              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_FINANCIALS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_FINANCIALS_OVERVIEW"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/overview/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/overview/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/overview/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_FINANCIALS_REVENUE_COSTS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_FINANCIALS_REVENUE_COSTS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                          "/reports/overview/receipt-payment",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/overview/receipt-payment"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/overview/receipt-payment",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo dòng tiền
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/* {isPrivilegeUserPacket({ namePrivilege: "REPORTS_STATISTICS_CASH_BOOK_MONEY", type: subscriberType }) &&
                    (isPrivilegeUserGroup(user, "REPORTS_STATISTICS_CASH_BOOK_MONEY")) && */}
                  <li
                    className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/overview/cash-book-money",
                      true
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/reports/overview/cash-book-money"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span
                          style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/cash-book-money",
                              true
                            )}`,
                          }}
                        ></span>
                      </i>
                      <span className="menu-text text-white">
                        Sổ quỹ tiền mặt
                      </span>
                    </NavLink>
                  </li>
                  {/* } */}
                  {/* {isPrivilegeUserPacket({ namePrivilege: "REPORTS_FINANCIALS_PROFITS", type: subscriberType }) &&
                    (isPrivilegeUserGroup(user, "REPORTS_FINANCIALS_PROFITS")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/overview/profit",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/profit">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/profit",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Lợi nhuận</span>
                      </NavLink>
                    </li>
                  } */}
                  {/* {isPrivilegeUserPacket({ namePrivilege: "REPORTS_FINANCIALS_VOUCHERCATEGORIES", type: subscriberType }) &&
                    (isPrivilegeUserGroup(user, "REPORTS_FINANCIALS_VOUCHERCATEGORIES")) &&

                    <li className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                      "/reports/overview/voucherCategory",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/voucherCategory">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/voucherCategory",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Theo hạng mục</span>
                      </NavLink>
                    </li>
                  }

                  {isPrivilegeUserPacket({ namePrivilege: "REPORTS_FINANCIALS_VOUCHERCATEGORY_BUILDINGS", type: subscriberType }) &&
                    (isPrivilegeUserGroup(user, "REPORTS_FINANCIALS_VOUCHERCATEGORY_BUILDINGS")) &&

                    <li className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                      "/reports/overview/voucherCategories-building",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/voucherCategories-building">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/voucherCategories-building",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Theo hạng mục - tòa nhà</span>
                      </NavLink>
                    </li>
                  } */}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_FINANCIALS_ACCOUNTS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_FINANCIALS_ACCOUNTS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                          "/reports/overview/accounts",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/overview/accounts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/overview/accounts",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Theo tài khoản
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/* {isPrivilegeUserPacket({ namePrivilege: "REPORTS_FINANCIALS_INVOICES", type: subscriberType }) &&
                    (isPrivilegeUserGroup(user, "REPORTS_FINANCIALS_INVOICES")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                      "/reports/overview/invoice",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/invoice">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/invoice",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Theo hóa đơn</span>
                      </NavLink>
                    </li>
                  } */}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_FINANCIALS_SERVICES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_FINANCIALS_SERVICES"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                          "/reports/overview/service-building",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/overview/service-building"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/overview/service-building",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Theo dịch vụ
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_FINANCIALS_SERVICES_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_FINANCIALS_SERVICES_BUILDINGS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children  ${getMenuItemActive(
                          "/reports/overview/service-floor",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/overview/service-floor"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/overview/service-floor",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Theo dịch vụ - Tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_DEBTS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/debts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-graphic-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo công nợ</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_DEBTS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_DEBTS_OVERVIEW") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/debts/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/debts/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/debts/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_DEBTS_INVOICES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_DEBTS_INVOICES") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/debts/invoice",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/debts/invoice"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/debts/invoice",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Thống kê hóa đơn
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_DEBTS_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_DEBTS_BUILDINGS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/debts/debt",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/debts/debt"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/debts/debt",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Công nợ tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_PREPAYS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/customers",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-graphic-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo tiền giữ khách</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_PREPAYS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_PREPAYS_OVERVIEW") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/customers/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/customers/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/customers/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_PREPAYS_DEPOSITS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_PREPAYS_DEPOSITS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/customers/deposits",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/customers/deposits"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/customers/deposits",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">Tiền cọc</span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_PREPAYS_DEPOSIT_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_PREPAYS_DEPOSIT_BUILDINGS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/customers/deposit-building",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/customers/deposit-building"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/customers/deposit-building",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tiền cọc - Tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_PREPAYS_PREPAY",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_PREPAYS_PREPAY") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/customers/prepaymoneys",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/customers/prepaymoneys"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/customers/prepaymoneys",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tiền trả trước
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_PREPAYS_PREPAY_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_PREPAYS_PREPAY_BUILDINGS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/customers/prepaymoney-building",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/customers/prepaymoney-building"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/customers/prepaymoney-building",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tiền trả trước - Tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_STATISTICS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/statistics",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-statistics icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo thống kê</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_OVERVIEW"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_FILLRATE",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_FILLRATE"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/fillrate",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/fillrate"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/fillrate",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tỷ lệ lấp đầy
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_EMPTYRATE",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_EMPTYRATE"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/emptyrate",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/emptyrate"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/emptyrate",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tỷ lệ trống
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_CONTRACTS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_CONTRACTS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/contract",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/contract"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/contract",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">Hợp đồng</span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_CUSTOMERS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_CUSTOMERS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/customer",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/customer"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/customer",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Khách hàng
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_STATISTICS_CUSTOMER_DEMOGRAPHICS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_STATISTICS_CUSTOMER_DEMOGRAPHICS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/statistics/percentage",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/statistics/percentage"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/statistics/percentage",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tỷ lệ khách hàng
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_TASKS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/task",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-presentation-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo công việc</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_TASKS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_TASKS_OVERVIEW") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/task/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/task/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/task/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_TASKS_PROJECTS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_TASKS_PROJECTS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/task/project",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/task/project"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/task/project",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">Dự án</span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_TASKS_TASKS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_TASKS_TASKS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/task/task",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/task/task"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/task/task",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Công việc
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_TASKS_MY_TASKS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_TASKS_MY_TASKS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/task/mytask",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/task/mytask"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/task/mytask",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Công việc của tôi
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_ASSETS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/asset",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-file-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo tài sản</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_ASSETS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_ASSETS_OVERVIEW") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/asset/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/asset/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/asset/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_ASSETS_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "REPORTS_ASSETS_BUILDINGS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/asset/units",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/asset/units"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/asset/units",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Phân bổ tài sản
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {isPrivilegeUserGroup(user, "REPORTS_METER_LOGS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/reports/meterLog",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-presentation-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo chỉ số công tơ</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_METER_LOGS_OVERVIEW",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_METER_LOGS_OVERVIEW"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/meterLog/chart",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/meterLog/chart"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/meterLog/chart",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Báo cáo tổng quan
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "REPORTS_METER_LOGS_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "REPORTS_METER_LOGS_BUILDINGS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/reports/meterLog/buildings",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/reports/meterLog/buildings"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/reports/meterLog/buildings",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Theo tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {(isPrivilegeUserGroup(user, "SETTINGS") ||
            isPrivilegeUserGroup(user, "UNITSYMBOLS") ||
            isPrivilegeUserGroup(user, "PERIODS") ||
            isPrivilegeUserGroup(user, "UNIT_TYPES") ||
            isPrivilegeUserGroup(user, "FORMULARS") ||
            isPrivilegeUserGroup(user, "VOUCHER_CATEGORIES") ||
            isPrivilegeUserGroup(user, "CONTRACT_FORMS") ||
            isPrivilegeUserGroup(user, "BANK_ACCOUNTS") ||
            isPrivilegeUserGroup(user, "CUSTOM_FIELDS") ||
            isPrivilegeUserGroup(user, "CONTRACT_SOURCES") ||
            isPrivilegeUserGroup(user, "ORGANIZATION") ||
            isPrivilegeUserGroup(user, "ROLES") ||
            isPrivilegeUserGroup(user, "USERS")) && (
              <li className="menu-section mt-0">
                <h4 className="menu-text">
                  <span className="text-menu">Hệ thống</span>
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}

          {(isPrivilegeUserGroup(user, "ORGANIZATION") ||
            isPrivilegeUserGroup(user, "ROLES") ||
            isPrivilegeUserGroup(user, "USERS")) && (
              <li
                className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                  ["/organization", "/roles", "/users"],
                  false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <span type="button" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-globe icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Tổ chức</span>
                  <i className="menu-arrow text-white"></i>
                </span>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    {isPrivilegeUserPacket({
                      namePrivilege: "ORGANIZATION",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "ORGANIZATION") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/organization",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/organization"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/organization",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">Tổ chức</span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "ROLES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "ROLES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/roles",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link menu-toggle" to="/roles">
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/roles",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">Vai trò</span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "USERS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "USERS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/users",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link menu-toggle" to="/users">
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/users",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Người dùng
                            </span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )}

          {(isPrivilegeUserGroup(user, "UNITSYMBOLS") ||
            isPrivilegeUserGroup(user, "PERIODS") ||
            isPrivilegeUserGroup(user, "UNIT_TYPES") ||
            isPrivilegeUserGroup(user, "FORMULARS") ||
            isPrivilegeUserGroup(user, "VOUCHER_CATEGORIES") ||
            isPrivilegeUserGroup(user, "CONTRACT_FORMS") ||
            isPrivilegeUserGroup(user, "BANK_ACCOUNTS") ||
            isPrivilegeUserGroup(user, "CUSTOM_FIELDS") ||
            isPrivilegeUserGroup(user, "CONTRACT_SOURCES")) && (
              <li
                className={`menu-item menu-item-submenu  ${getListMenuItemActive(
                  [
                    "/unitSymbols",
                    "/periods",
                    "/unitTypes",
                    "/formulas",
                    "/voucherCategories",
                    "/contractFormOrganization",
                    "/bankAccountOrganization",
                    "/customFieldOrganization",
                    "/contractSources",
                  ],
                  false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <span type="button" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <i className="fas fa-list-ul icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">Danh mục hệ thống</span>
                  <i className="menu-arrow text-white"></i>
                </span>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    {isPrivilegeUserPacket({
                      namePrivilege: "UNIT_SYMBOLS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "UNIT_SYMBOLS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/unitSymbols",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/unitSymbols"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/unitSymbols",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Đơn vị tính
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "PERIODS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "PERIODS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/periods",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/periods"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/periods",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Kỳ thanh toán
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "UNIT_TYPES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "UNIT_TYPES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/unitTypes",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/unitTypes"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/unitTypes",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Loại đơn vị thuê
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "FORMULARS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "FORMULARS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/formulas",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/formulas"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/formulas",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">Định mức</span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "VOUCHER_CATEGORIES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "VOUCHER_CATEGORIES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/voucherCategories",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/voucherCategories"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/voucherCategories",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Hạng mục thu chi
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "CONTRACT_FORMS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "CONTRACT_FORMS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/contractFormOrganization",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/contractFormOrganization"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/contractFormOrganization",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Mẫu biểu chung
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "BANK_ACCOUNTS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "BANK_ACCOUNTS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/bankAccountOrganization",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/bankAccountOrganization"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/bankAccountOrganization",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Tài khoản chung
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "CUSTOM_FIELDS",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "CUSTOM_FIELDS") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/customFieldOrganization",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/customFieldOrganization"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/customFieldOrganization",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Trường tùy chỉnh chung
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {isPrivilegeUserPacket({
                      namePrivilege: "CONTRACT_SOURCES",
                      type: subscriberType,
                    }) &&
                      isPrivilegeUserGroup(user, "CONTRACT_SOURCES") && (
                        <li
                          className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                            "/contractSources",
                            true
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to="/contractSources"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span
                                style={{
                                  backgroundColor: `${getDotActive(
                                    "/contractSources",
                                    true
                                  )}`,
                                }}
                              ></span>
                            </i>
                            <span className="menu-text text-white">
                              Nguồn hợp đồng
                            </span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )}
          {/* Cấu hình chung */}
          {isPrivilegeUserGroup(user, "SETTINGS") && (
            <li
              className={`menu-item menu-item-submenu  ${getMenuActive(
                "/settings",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-cogwheel-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Cấu hình chung</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_PROFILE",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "SETTINGS_PROFILE") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/personal",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/personal"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/personal",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Hồ sơ cá nhân
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_PERMISSION_BUILDINGS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(
                      user,
                      "SETTINGS_PERMISSION_BUILDINGS"
                    ) && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/decentralization",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/decentralization"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/decentralization",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Phân quyền
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_CURRENCIES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "SETTINGS_CURRENCIES") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/currencies",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/currencies"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/currencies",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">Tiền tệ</span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_EMAIL_TEMPLATES",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "SETTINGS_EMAIL_TEMPLATES") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/emails",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/emails"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/emails",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Cấu hình email
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {(isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_ORGANIZATIONS",
                    type: subscriberType,
                  }) ||
                    isPrivilegeUserPacket({
                      namePrivilege: "SETTINGS_ORGANIZATION_BUILDINGS",
                      type: subscriberType,
                    })) &&
                    isPrivilegeUserGroup(user, "SETTINGS_BUILDINGS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/building",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/building"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/building",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Cấu hình tòa nhà
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_FUNCTIONS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "SETTINGS_FUNCTIONS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/functions",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/functions"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/functions",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Cấu hình chức năng
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {isPrivilegeUserPacket({
                    namePrivilege: "SETTINGS_API_KEYS",
                    type: subscriberType,
                  }) &&
                    isPrivilegeUserGroup(user, "SETTINGS_API_KEYS") && (
                      <li
                        className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                          "/settings/api-keys",
                          true
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to="/settings/api-keys"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span
                              style={{
                                backgroundColor: `${getDotActive(
                                  "/settings/api-keys",
                                  true
                                )}`,
                              }}
                            ></span>
                          </i>
                          <span className="menu-text text-white">
                            Tích hợp API
                          </span>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </li>
          )}

          {/*------------------------------------ Quản lý tài liệu lưu trữ -------------------------- */}

          {isPrivilegeUserGroup(user, "DOCUMENTS") && (
            <li className="menu-section mt-0">
              <h4 className="menu-text">
                <span className="text-menu">Tài liệu lưu trữ</span>
              </h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          )}

          {isPrivilegeUserPacket({
            namePrivilege: "DOCUMENTS",
            type: subscriberType,
          }) &&
            isPrivilegeUserGroup(user, "DOCUMENTS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/documents",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/documents">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-folder icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="DOCUMENTS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {/* Giao dịch */}
          {/* {(isPrivilegeUserAction(user, "POST_BUILDINGS_WRITE") || isPrivilegeUserAction(user, "POST_BUILDINGS_READ") ||
            isPrivilegeUserAction(user, "POST_UNIT_WRITE") || isPrivilegeUserAction(user, "POST_UNIT_READ") ||
            isPrivilegeUserAction(user, "POST_TEMPLATES_WRITE") || isPrivilegeUserAction(user, "POST_TEMPLES_READ") ||
            isPrivilegeUserAction(user, "POSTS_WRITE") || isPrivilegeUserAction(user, "POSTS_READ") ||
            isPrivilegeUserAction(user, "POSTS_LIST_WRITE") || isPrivilegeUserAction(user, "POSTS_LIST_READ")) &&
            <li className="menu-section mt-0">
              <h4 className="menu-text">Quản lý tin đăng</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          }


          {(isPrivilegeUserPacket({ namePrivilege: "POSTS_BUILDINGS", type: subscriberType })
            && (isPrivilegeUserAction(user, "POST_BUILDINGS_WRITE") || isPrivilegeUserAction(user, "POST_BUILDINGS_READ")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/postBuildings",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/postBuildings">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-buildings icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Tòa nhà
                </span>
              </NavLink>
            </li>
          )}
          {(isPrivilegeUserPacket({ namePrivilege: "POSTS_UNIT", type: subscriberType })
            && (isPrivilegeUserAction(user, "POST_UNIT_WRITE") || isPrivilegeUserAction(user, "POST_UNIT_READ")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/postUnits",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/postUnits">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-squares-4 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Đơn vị thuê
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "POSTS_TEMPLATES", type: subscriberType })
            && (isPrivilegeUserAction(user, "POST_TEMPLATES_WRITE") || isPrivilegeUserAction(user, "POST_TEMPLES_READ")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/postTemplates",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/postTemplates">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-doc icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Tin đã lưu
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "POSTS", type: subscriberType })
            && (isPrivilegeUserAction(user, "POSTS_WRITE") || isPrivilegeUserAction(user, "POSTS_READ")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/posts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/posts">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-book icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Đăng tin
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "POSTS_LIST", type: subscriberType })
            && (isPrivilegeUserAction(user, "POSTS_WRITE") || isPrivilegeUserAction(user, "POSTS_READ")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/posts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/posts">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-list icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  Danh sách tin
                </span>
              </NavLink>
            </li>
          )} */}

          <li className="menu-section mt-0">
            <h4 className="menu-text">
              <span className="text-menu">Tiện ích</span>
            </h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          {/* {(isPrivilegeUserPacket({ namePrivilege: "FEEDBACKS", type: subscriberType })
            && (isPrivilegeUserAction(user, "FEEDBACKS")) &&

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/feedbacks",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/feedbacks">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-confetti icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="FEEDBACKS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )} */}

          {/* Here */}
          {/* QL tin đăng */}

          {false &&
            isPrivilegeUserPacket({
              // BỎ MENU NEWLETTERS 04/10/2022
              namePrivilege: "NEWLETTERS",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "NEWLETTERS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/newsLetters",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/newsLetters">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-notes icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="NEWSLETTERS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {
            isPrivilegeUserPacket({
              // BỎ MENU SUPPORTS 04/10/2022
              namePrivilege: "SUPPORTS",
              type: subscriberType,
            }) &&
            isPrivilegeUserGroup(user, "SUPPORTS") && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/supports",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/supports">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-lifebuoy icon-lg text-white"></i>
                  </span>
                  <span className="menu-text">
                    <FormattedMessage id="REPORT_BUGS.UI.Title"></FormattedMessage>
                  </span>
                </NavLink>
              </li>
            )}

          {!isAdministrator(user) && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/reportBugs",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <a
                className="menu-link menu-toggle"
                href="https://chuoicanho.com/ho-tro/"
                target="_blank"
              >
                <span className="svg-icon menu-icon">
                  <i className="flaticon-information icon-lg text-white"></i>
                </span>
                <span className="menu-text">Hướng dẫn sử dụng</span>
              </a>
            </li>
          )}

          {/* Here */}
          {/* QL tin đăng */}
          {/* <li className="menu-section mt-0">
            <h4 className="menu-text"><span className="text-menu">Quản lý tin đăng</span></h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {(isPrivilegeUserPacket({ namePrivilege: "BUILDINGPOSTS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "BUILDINGPOSTS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/building-posts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/building-posts">
                <span className="svg-icon menu-icon">
                  <i className="far fa-building icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="BUILDINGS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "UNITSPOSTS", type: subscriberType }) &&
            (isPrivilegeUserGroup(user, "UNITSPOSTS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/unit-posts",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/unit-posts">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-home-1  icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="MODEL.UI.vehicles.unitId"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          )} */}
        </>
      )}
    </>
  );
}
