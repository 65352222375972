import axios from "axios";

export const DEPARTMENTS_URL = "/departments";

// CREATE =>  POST: add a new department to the server
export function createDepartment(department) {
  return axios.post(DEPARTMENTS_URL, department);
}

// READ
export function getAllDepartments() {
  return axios.get(DEPARTMENTS_URL);
}

export function findDepartmentsByOrganizationId(organizationId) {
  return axios.get(`${DEPARTMENTS_URL}/organization/${organizationId}`);
}

export function getInitDepartment() {
  return axios.get(`${DEPARTMENTS_URL}/create`);
}

export function getDepartmentCloneById(departmentId) {
  return axios.get(`${DEPARTMENTS_URL}/${departmentId}/clone`);
}
export function getDepartment(departmentId) {
  return axios.get(`${DEPARTMENTS_URL}/${departmentId}`);
}


export function getDepartmentById(departmentId) {
  return axios.get(`${DEPARTMENTS_URL}/${departmentId}`);
}

export function getIntDepartmentByParentId(parentId) {
  return axios.get(`${DEPARTMENTS_URL}/parent/${parentId}/create`);
}

export function getDepartmentEditById(departmentId) {
  return axios.get(`${DEPARTMENTS_URL}/${departmentId}/edit`);
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDepartments(queryParams) {
  return axios.post(`${DEPARTMENTS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateDepartment(department) {
  return axios.put(`${DEPARTMENTS_URL}/${department.id}`, department);
}

// UPDATE Status
export function updateStatusForDepartments(ids, status) {
  return axios.post(`${DEPARTMENTS_URL}/updateStatusForDepartments`, {
    ids,
    status,
  });
}

// DELETE => delete the department from the server
export function deleteDepartment(departmentId) {
  return axios.delete(`${DEPARTMENTS_URL}/${departmentId}`);
}

// DELETE Departments by ids
export function deleteDepartments(ids) {
  return axios.delete(`${DEPARTMENTS_URL}/bulk`, { data: { ids } });

}
