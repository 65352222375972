/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/BloomGooLayout";
import { conditionsToDeleteInvoices, toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsNotification from "../_redux/NotificationsActions"
import { AlertsWarning } from "./alert/AlertsWarning";
import { AlertCreate } from "./alert/AlertCreate";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, NavLink } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown(props) {

  const [key, setKey] = useState("All");
  const initialFilter = {
    filter: {
      searchCriteria: [
        {
          key: "notificationType",
          operation: ":",
          value: key === "All" ? "" : (key === "Notifications" ? "NOTIFICATION" : "WARNING"),
          prefix: "",
          suffix: "",
          isDefault: false,
        }
      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "eventAt",
    pageNumber: 1,
    pageSize: 35,
  };
  const { intl } = props
  const applyFilter = (key) => {
    const filters = { ...initialFilter }
    if (key === "Notifications") {
      filters.filter.searchCriteria = [{
        key: "notificationType",
        operation: ":",
        value: "NOTIFICATION",
        prefix: "",
        suffix: "",
      }]
    };
    if (key === "Warning") {
      filters.filter.searchCriteria = [{
        key: "notificationType",
        operation: ":",
        value: "WARNING",
        prefix: "",
        suffix: "",
      },
      {
        key: "userId",
        operation: ":",
        value: user.id,
        prefix: "",
        suffix: "",
      }
      ]
    };
    if (key === "All") {
      filters.filter.searchCriteria = [{
        key: "notificationType",
        operation: ":",
        value: "",
        prefix: "",
        suffix: "",
      }]
    };
    dispatch(actionsNotification.fetchNotifications(filters));

  };



  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const dispatch = useDispatch();
  const { notifications, user } = useSelector(
    (state) => ({
      notifications: state.notifications.entities,
      user: state.auth.user

    }),
    shallowEqual
  );
  useEffect(() => {
    // dispatch(actionsNotification.fetchAllNotifications());
  }, [dispatch]);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  const [show, setShow] = useState(false);

  const ToLink = [
    { functionName: "dashboard", linkTo: "dashboard" },
    { functionName: "deskbase", linkTo: "deskbase" },
    { functionName: "desks", linkTo: "desks" },
    { functionName: "real-time", linkTo: "real-time" },
    { functionName: "buildings", linkTo: "buildings" },
    { functionName: "units", linkTo: "units" },
    { functionName: "meters", linkTo: "meters" },
    { functionName: "services", linkTo: "services" },
    { functionName: "floors", linkTo: "buildings/floors" },
    { functionName: "utilities", linkTo: "buildings/utilities" },
    { functionName: "vehicleTypes", linkTo: "buildings/vehicleTypes" },
    { functionName: "contractForms", linkTo: "buildings/contractForms" },
    { functionName: "bankAccounts", linkTo: "buildings/bankAccounts" },
    { functionName: "customFields", linkTo: "buildings/customFields" },
    { functionName: "customers", linkTo: "customers" },
    { functionName: "vehicles", linkTo: "vehicles" },
    { functionName: "deposits", linkTo: "deposits" },
    { functionName: "contracts", linkTo: "contracts" },
    { functionName: "meterLogs", linkTo: "meterLogs" },
    { functionName: "meterPartnerLogs", linkTo: "meterPartnerLogs" },
    { functionName: "transaction-histories", linkTo: "transaction-histories" },
    { functionName: "assets", linkTo: "assets" },
    { functionName: "assetSequences", linkTo: "assetSequences" },
    { functionName: "assetInventories", linkTo: "assetInventories" },
    { functionName: "suppliers", linkTo: "suppliers" },
    { functionName: "assetWarehouses", linkTo: "assetWarehouses" },
    { functionName: "assetCategories", linkTo: "assetCategories" },
    { functionName: "assetStatuses", linkTo: "assetStatuses" },
    { functionName: "projects", linkTo: "projects" },
    { functionName: "tasks", linkTo: "tasks" },
    { functionName: "collaborators", linkTo: "collaborators" },
    { functionName: "userCommissions", linkTo: "userCommissions" },
    { functionName: "commissions", linkTo: "commissions" },
    { functionName: "organization", linkTo: "organization" },
    { functionName: "roles", linkTo: "roles" },
    { functionName: "users", linkTo: "users" },
    { functionName: "unitSymbols", linkTo: "unitSymbols" },
    { functionName: "periods", linkTo: "periods" },
    { functionName: "unitTypes", linkTo: "unitTypes" },
    { functionName: "formulas", linkTo: "formulas" },
    { functionName: "voucherCategories", linkTo: "voucherCategories" },
    { functionName: "contractFormOrganization", linkTo: "contractFormOrganization" },
    { functionName: "bankAccountOrganization", linkTo: "bankAccountOrganization" },
    { functionName: "customFieldOrganization", linkTo: "customFieldOrganization" },
    { functionName: "contractSources", linkTo: "contractSources" },
    { functionName: "personal", linkTo: "settings/personal" },
    { functionName: "decentralization", linkTo: "settings/decentralization" },
    { functionName: "currencies", linkTo: "settings/currencies" },
    { functionName: "emails", linkTo: "settings/emails" },
    { functionName: "building", linkTo: "settings/building" },
    { functionName: "functions", linkTo: "settings/functions" },
    { functionName: "api-keys", linkTo: "settings/api-keys" },
    { functionName: "documents", linkTo: "documents" },
    { functionName: "newsLetters", linkTo: "newsLetters" },
    { functionName: "supports", linkTo: "supports" },
    { functionName: "building-posts", linkTo: "building-posts" },
    { functionName: "unit-posts", linkTo: "unit-posts" },
    { functionName: "invoices", linkTo: "invoices/actions" },


  ]

  const functionNameToLink = (functionName) => {
    return ToLink.filter(word => word.functionName === functionName)[0]?.linkTo;
  };


  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item"
        >
          <div
            className="btn btn-icon btn-clean btn-sm mr-3 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  <FormattedMessage id="NOTIFICATION_USER" />
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-sm mr-3 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
                onClick={() => { dispatch(actionsNotification.fetchNotifications(initialFilter)); setShow(!show) }}
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xl">
            <form>
              {/** Head */}
              <div className="d-flex flex-column pt-3 bgi-size-cover bgi-no-repeat rounded-top bg-primary">
                <h4 className="d-flex flex-center rounded-top mb-0">
                  <span className="text-white">Thông báo, cảnh báo</span>
                  {notifications && notifications && (
                    <span className="btn btn-text btn-primary btn-sm font-weight-bold btn-font-md ml-2 mb-8">
                      {notifications.length} new
                    </span>
                  )}
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success px-8"
                    onSelect={(_key) => { setKey(_key); applyFilter(_key) }}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="All"
                        className={`nav-link show ${key === "All" ? "active" : ""
                          }`}
                      >
                        <span className={key === "All" ? "text-white" : "text-muted"}>Tất cả</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Notifications"
                        className={`nav-link show ${key === "Notifications" ? "active" : ""
                          }`}
                      >
                        <span className={key === "Notifications" ? "text-white" : "text-muted"}>Thông báo</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Warning"
                        className={`nav-link show ${key === "Warning" ? "active" : ""
                          }`}
                      >
                        <span className={key === "Warning" ? "text-white" : "text-muted"}>Cảnh báo</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="All" className="px-6">

                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 py-3"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <ul className="navi navi-hover">
                          {notifications && notifications.map((item, index) => {
                            return (
                              <li className="navi-item">
                                <NavLink className="menu-link" to={`/${functionNameToLink(item.functionName.toLowerCase())}`}>
                                  <AlertCreate intl={intl} alertCreateInfo={item} key={index} />
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>

                      </PerfectScrollbar>
                      {notifications.length ? <div className='py-3 text-center border-top'>
                        <Link
                          to='/notifications'
                          className='btn btn-color-gray-600 btn-active-color-primary'
                        >
                          <span type="button" onClick={() => setShow(!show)}>
                            Xem tất cả{' '}
                            <i className="flaticon2-fast-next icon-sm"></i>
                          </span>
                        </Link>
                      </div> : <></>}
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="Notifications"
                      className="px-6"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 py-3"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <ul className="navi navi-hover">
                          {notifications && notifications.map((item, index) => {
                            return (
                              <li className="navi-item">
                                <NavLink className="menu-link" to={`/${functionNameToLink(item?.functionName?.toLowerCase())}`}>
                                  <AlertCreate intl={intl} alertCreateInfo={item} key={index} />
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>

                      </PerfectScrollbar>
                      {notifications.length ? <div className='py-3 text-center border-top'>
                        <Link
                          to='/notifications'
                          className='btn btn-color-gray-600 btn-active-color-primary'
                        >
                          <span type="button" onClick={() => setShow(!show)}>
                            Xem tất cả{' '}
                            <i className="flaticon2-fast-next icon-sm"></i>
                          </span>
                        </Link>
                      </div> : <></>}
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="Warning"
                      className="px-6"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 py-3"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <ul className="navi navi-hover">
                          {notifications && notifications.map((item, index) => {
                            return (
                              <li className="navi-item">
                                <NavLink className="menu-link" to={`/${functionNameToLink(item?.functionName?.toLowerCase())}`}>
                                  <AlertCreate intl={intl} alertCreateInfo={item} key={index} />
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>

                      </PerfectScrollbar>
                      {notifications.length ? <div className='py-3 text-center border-top'>
                        <Link
                          to='/notifications'
                          className='btn btn-color-gray-600 btn-active-color-primary'
                        >
                          <span type="button" onClick={() => setShow(!show)}>
                            Xem tất cả{' '}
                            <i className="flaticon2-fast-next icon-sm"></i>
                          </span>
                        </Link>
                      </div> : <></>}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export const UserNotificationsDropdownIntl = injectIntl(UserNotificationsDropdown);
