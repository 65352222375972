import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../../_helpers/_CommonUIHelpers";

const initialAttachmentsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entitiesReportsOccupancy: [],
  entitiesReportsProfit: [],
  entitiesReportsOverview: [],
  filterCount: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const dashboardPartnerSlice = createSlice({
  name: "dashboard",
  initialState: initialAttachmentsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },

    dashboardFetchedReportsOccupancy: (state, action) => {
      const { entitiesReportsOccupancy } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesReportsOccupancy = entitiesReportsOccupancy;
    },

    dashboardFetchedReportsProfit: (state, action) => {
      const { entitiesReportsProfit } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesReportsProfit = entitiesReportsProfit;
    },

    dashboardFetchedReportsOverview: (state, action) => {
      const { entitiesReportsOverview } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesReportsOverview = entitiesReportsOverview;
    },
  },
});
