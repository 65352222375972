import React from "react";
import {
  BG_TYPE_OBJ,
  UnitStatusTitles,
  UNIT_STATUS_OBJ,
  UNIT_USE_TYPE_OBJ,
} from "../_CommonUIHelpers";

export const UnitsStatusColumnFormatter = (
  cellContent,
  row,
  index,
  { newContractButtonClick, openShowContractPage, isPrivilege }
) => {
  let cssUnitChoose = "";
  if (row.status === UNIT_STATUS_OBJ.EMPTY) {
    if (row.isDeposit === true) {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_EMPTY_DEPOSIT;
    } else {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_EMPTY;
    }
  } else if (row.status === UNIT_STATUS_OBJ.RENTED) {
    if (row.isDeposit === true) {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_RENTED_DEPOSIT;
    } else {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_RENTED;
    }
  } else {
    cssUnitChoose = BG_TYPE_OBJ.UNIT_STOPPED;
  }

  return row.useType === UNIT_USE_TYPE_OBJ.DORMITORY ||
    row.useType === UNIT_USE_TYPE_OBJ.WAREHOUSE ? (
    <></>
  ) : (
    isPrivilege ?
      <span
        type="button"
        onClick={() => {
          row.status === UNIT_STATUS_OBJ.EMPTY
            ? newContractButtonClick && newContractButtonClick(row.id)
            : newContractButtonClick && openShowContractPage(row.id);
        }}
        className={`label label-md label-light-${cssUnitChoose} label-inline notEvent`}
      >
        {UnitStatusTitles[row.status]}
      </span>
      :
      <span
        type="button"
        className={`label label-md label-light-${cssUnitChoose} label-inline notEvent`}
      >
        {UnitStatusTitles[row.status]}
      </span>
  );
};
