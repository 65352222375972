import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_bloomgoo/_partials/controls";
import { FormattedMessage } from "react-intl";

export function SupportDrawerEditDialogHeader({ id }) {
  // Supports Redux state
  const { supportForEdit, actionsLoading } = useSelector(
    (state) => ({
      supportForEdit: state.supports.supportForEdit,
      actionsLoading: state.supports.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? (
      ""
    ) : (
      <FormattedMessage
        id="REPORT_BUGS.UI.Title.New"
        values={{ params: "" }}
      ></FormattedMessage>
    );
    if (supportForEdit && id) {
      _title = (
        <FormattedMessage
          id="REPORT_BUGS.UI.Title.Edit"
          values={{
            params: `'${supportForEdit.code} - ${supportForEdit.name}'`,
          }}
        />
      );
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [supportForEdit, actionsLoading]);

  return title;
}
