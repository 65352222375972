export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL || "/";
  axios.defaults.cdnURL = process.env.REACT_APP_BASE_CDN_URL || "/";

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        window.location = "/dang-xuat";
      }
      return Promise.reject(error);
    }
  );
}
