import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialUsersState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  userForShow: null,
  parents: [],
  userForEdit: null,
  roleForShow: null,
  myProfile: null,
  filterCount: null,
  notification: null,
  currentUser: null,
  currentUsers: [],
  error: null,
  isError: null,
  errorType: null,
  userCollaborators: [],
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    startCallParent: (state, action) => {


      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentUser = null;
      state.currentUsers = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.userForEdit = null;
      state.userImport = null;
    },

    resetCurrentUsers: (state, action) => {
      state.currentUser = null;
      state.currentUsers = [];
    },

    // getUserById
    userFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.userForEdit = action.payload.userForEdit;
      state.error = null;

    },

    myProfileFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.myProfile = action.payload.myProfile;
      state.error = null;

    },
    //show
    getUser: (state, action) => {
      state.actionsLoading = false;
      state.userForShow = action.payload.userForShow;
      state.error = null;

    },
    // getUserById
    userBuildingPermissionFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.userBuildingPermissionForEdit = action.payload.userBuildingPermissionForEdit;
      state.error = null;

    },
    // findAllUsers
    usersFetchedAll: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findUsers
    usersFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },
    // findParentUsers
    usersPareParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createUser
    userCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.userForEdit = null;
      state.currentUser = action.payload.user;
      state.currentUsers.push(action.payload.user);
    },
    // updateUser
    userUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.user.id) {
          return action.payload.user;
        }
        return entity;
      });
      state.isError = false;
      state.actionsLoading = false;
      state.userForShow = action.payload.userForShow;
      state.currentUser = action.payload.user;
      state.currentUsers.push(action.payload.user);
      state.userForEdit = action.payload.user;

    },

    userUpdatedPassword: (state, action) => {
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
    },

    userUpdatedInfor: (state, action) => {
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
    },
    userBuildingPermissionUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      
    },
    // deleteUser
    userDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.currentUser = null;
      state.currentUsers = [];
    },
    // deleteUsers
    usersDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.currentUser = null;
      state.currentUsers = [];
    },
    // usersUpdateState
    usersStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.currentUser = action.payload.user;
      state.currentUsers.push(action.payload.user);
      state.isError = false;
      state.currentUser = action.payload.user;
    },
    // usersUpdateState
    usersActiveUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, isActive } = action.payload;
      // state.currentMeterLogs = state.entities.filter(el => ids.some(id => el.id === id));
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.isActive = isActive;
        }
        return entity;
      });
      // state.isError = false;
      // state.currentUser = action.payload.user;
    },
    userCollaboratorsFetched: (state, action) => {
      const { userCollaborators } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userCollaborators = userCollaborators;
    },

    userImportFetched: (state, action) => {
      const { userImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.userImport = userImport;
    },

    resetUserImport: (state, action) => {
      state.userImport = null;
    },

    userImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },
  },
});
