import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, size) => {
  const classes = ["form-control", `form-control-${size}`];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function RadioField({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label = "",
  withFeedbackLabel = true,
  customFeedbackLabel,
  size = "md",
  type = "radio",
  required = false,
  checked,
  value,
  formProps,
  handleFieldChange,
  ...props
}) {

  field.onChange = ((e) => {
    handleFieldChange({
      target: {
        value: value,
        name: field.name,
        checked: checked
      }
    }, formProps)
  })

  return (
    <>
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name], size)}
        checked={checked}
        {...field}
        {...props}
      />
      <span></span> {label}
      {false && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
