import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

export function Image({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  required = false,
  customFeedbackLabel,
  classType = "avatar",
  type = "text",
  ...props
}) {

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      <div className="col-lg-4"></div>
      {type === 'image' && (
        <div id="kt_profile_avatar" className="image-input image-input-outline imagebackground">
          <div className="image-input-wrapper"></div>
          <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
            <i className="fa fa-pen icon-sm text-muted"></i>
            <input type="file" accept=".png, .jpg, .jpeg" />
            <input type="hidden" name="profile_avatar_remove" />
          </label>
          <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
            <i className="ki ki-bold-close icon-xs text-muted"></i>
          </span>
          <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="" data-original-title="Remove avatar">
            <i className="ki ki-bold-close icon-xs text-muted"></i>
          </span>
        </div>
      )}

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}