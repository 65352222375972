import { Description } from "@material-ui/icons";
import axios from "axios";

export const UNITS_URL = "/units";

// CREATE =>  POST: add a new unit to the server
export function createUnit(unit) {
  return axios.post(UNITS_URL, unit);
}

export function storeUnitsBulk(buildingId, floorId, unitBulk) {
  return axios.post(
    `${UNITS_URL}/buildings/${buildingId}/floors/${floorId}/bulk`,
    unitBulk
  );
}

// READ
export function getAllUnits() {
  return axios.get(UNITS_URL);
}

export function getInitialUnitByBuildingId(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/create`);
}

export function getUnitCloneById(unitId) {
  return axios.get(`${UNITS_URL}/${unitId}/clone`);
}

export function getUnitByInvoiceId(invoiceId) {
  return axios.get(`${UNITS_URL}/invoices/${invoiceId}`);
}

export function getUnitById(unitId) {
  return axios.get(`${UNITS_URL}/${unitId}`);
}

export function getUnitEditById(id) {
  return axios.get(`${UNITS_URL}/${id}/edit`);
}

export function findByBuildingId(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}`);
}

// Những mặt bằng đã được thuê
export function findByBuildingIdAndRented(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/rented`);
}

export function findByBuildingIdContractIdAndRented(buildingId, contractId) {
  return axios.get(
    `${UNITS_URL}/buildings/${buildingId}/contracts/${contractId}/rented`
  );
}

export function findByBuildingIdAndRentedInvoiceType(
  buildingId,
  month,
  year,
  invoiceType
) {
  return axios.get(
    `${UNITS_URL}/buildings/${buildingId}/months/${month}/years/${year}/invoiceTypes/${invoiceType}/rented`
  );
}

// export function findByBuildingIdAndEmpty(buildingId, contractId) {
//   return axios.get(`${UNITS_URL}/findByBuildingIdAndEmpty/${buildingId}/byContractId/${contractId}`);
// }

// Tìm mặt bằng chưa có hợp đồng theo tòa nhà, và mặt bằng của chính hợp đồng được chọn
export function findByBuildingIdAndEmptyWithContractId(buildingId, contractId) {
  return axios.get(
    `${UNITS_URL}/buildings/${buildingId}/contracts/${contractId}/empty`
  );
}
// Mặt bằng còn trống
export function findByBuildingIdAndEmpty(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/empty`);
}
// Tìm mặt bằng chưa có cọc giữ chỗ và mặt bằng của chính hợp đồng cọc
export function findByBuildingIdAndNotDeposited(buildingId, depositId) {
  return axios.get(
    `${UNITS_URL}/buildings/${buildingId}/deposits/${depositId}/notDeposited`
  );
}

export function findByContractId(contractId) {
  return axios.get(`${UNITS_URL}/contracts/${contractId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUnits(queryParams, buildingId) {
  return axios.post(`${UNITS_URL}/buildings/${buildingId}/find`, queryParams);
}

export function findDesks(buildingId, queryParams) {
  return axios.post(`${UNITS_URL}/buildings/${buildingId}/desk`, queryParams);
}

export function findDesksByBuildingId(buildingId, month, year, queryParams) {
  return axios.post(
    `${UNITS_URL}/buildings/${buildingId}/months/${month}/years/${year}/desk`,
    queryParams
  );
}

// UPDATE => PUT: update the procuct on the server
export function updateUnit(unit) {
  return axios.put(`${UNITS_URL}/${unit.id}`, unit);
}

// UPDATE Status
export function updateStatusForUnits(ids, status) {
  return axios.post(`${UNITS_URL}/status/update`, {
    ids,
    status,
  });
}

// DELETE => delete the unit from the server
export function deleteUnit(unitId) {
  return axios.delete(`${UNITS_URL}/${unitId}`);
}

// DELETE Units by ids
export function deleteUnits(ids) {
  return axios.delete(`${UNITS_URL}/bulk`, { data: { ids } });
}
//asset
export function findAssetsByUnitId(unitId) {
  return axios.get(`${UNITS_URL}/${unitId}/assets`);
}

export function findUnitsCount(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/count`);
}

export function findUnitsBuildingGrid() {
  return axios.get(`${UNITS_URL}/buildingGrid`);
}

export function findByBuildingIdAssets(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/assets`);
}

export function findByBuildingIdAndAssetInventoryPeriodId(
  buildingId,
  assetInventoryPeriodId
) {
  return axios.get(
    `${UNITS_URL}/buildings/${buildingId}/assetInventoryPeriods/${assetInventoryPeriodId}`
  );
}

export function getUnitExportExcel(buildingId) {
  return axios.get(`${UNITS_URL}/buildings/${buildingId}/export`, {
    // include your additional POSTed data here
    responseType: "blob",
  });
}

export function getTemplateUnitExportExcel() {
  return axios.get(`${UNITS_URL}/import/template`, {
    // include your additional POSTed data here
    responseType: "blob",
  });
}

// IMPORT
export function getUnitImport(buildingId, file) {
  return axios.post(`${UNITS_URL}/buildings/${buildingId}/import`, file);
}

// IMPORT store
export function getUnitImportStore(buildingId, file) {
  return axios.post(`${UNITS_URL}/buildings/${buildingId}/import/store`, file);
}

export function getUnitRealtime() {
  return axios.get(`${UNITS_URL}/realtime`);
}

export function updateUnitRealtime(unit) {
  return axios.put(`${UNITS_URL}/${unit.id}/realtime`, {
    description: unit.description,
  });
}

export function updateUnitEmptyRate(unit) {
  return axios.put(`${UNITS_URL}/${unit.id}/emptyRate`, {
    description: unit.description,
  });
}
