import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialFeedbacksState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  feedbackForEdit: null,
  feedbackForShow: null,
  feedbacksIsRented: null,
  feedbacksIsEmpty: null,
  filterCount: null,
  assetsFeedback: [],
  otherFeedback: null,
  notification: null,
  currentFeedback: null,
  currentFeedbacks: [],
  error: null,
  isError: null,
  feedbacksCount: null,
  errorType: null,
  buildingsGrid: [],
  feedbacksAssets: [],
  totalBuilding: null,
};



export const callTypes = {
  table: "table",
  list: "list",
  action: "action",

  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState: initialFeedbacksState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.notification = null;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.error = null;
      state.notification = null;
      state.totalBuilding = null;
      state.currentFeedback = null;
      state.currentFeedbacks = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      // state.listLoading = null;
      // state.actionsLoading = null;
      state.isError = null;
      state.feedbackForEdit = null;
    },

    resetCurrentFeedbacks: (state, action) => {
      state.currentFeedback = null;
      state.currentFeedbacks = [];
    },
    // getFeedbackById
    feedbackFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.feedbackForEdit = action.payload.feedbackForEdit;
      state.error = null;

    },
    //show
    feedbackShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.feedbackForShow = action.payload.feedbackForShow;
      state.error = null;

    },
    // findAllFeedbacks
    feedbacksFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // findFeedbacks
    feedbacksFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount
    },
    otherFeedbacksFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.otherFeedback = entities;
      state.totalCount = totalCount;
    },


    // findFeedbacksRentd
    feedbacksIsRentedFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.feedbacksIsRented = entities;
      state.totalCount = totalCount;
    },

    // findFeedbacksEmpty
    feedbacksIsEmptyFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.feedbacksIsEmpty = entities;
      state.totalCount = totalCount;
    },

    // findParentFeedbacks
    feedbacksParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createFeedback
    feedbackCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.feedback);
      state.currentFeedback = action.payload.feedback;
      state.currentFeedbacks.push(action.payload.feedback);
    },
    // updateFeedback
    feedbackUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.feedback.id) {
          return action.payload.feedback;
        }
        return entity;
      });
      state.currentFeedback = action.payload.feedback;
      state.currentFeedbacks.push(action.payload.feedback);

    },
    // deleteFeedback
    feedbackDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentFeedback = null;
      state.currentFeedbacks = [];

    },
    // deleteFeedbacks
    feedbacksDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentFeedback = null;
      state.currentFeedbacks = [];
    },
    // feedbacksUpdateState
    feedbacksStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      const { ids, status } = action.payload;
      // state.entities = state.entities.map((entity) => {
      //   if (ids.findIndex((id) => id === entity.id) > -1) {
      //     entity.status = status;
      //   }
      //   return entity;
      // });
      state.currentFeedback = action.payload.feedback;
      state.currentFeedbacks = [];

    },

    feedbacksCount: (state, action) => {
      state.feedbacksCount = action.payload.feedbacksCount;
      state.actionsLoading = false;
    },
    assetsFetchedByFeedbackId: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { assets } = action.payload;
      state.assetsFeedback = assets;
    },

    countFeedbacksClean: (state, action) => {

      state.feedbacksCount = 1;
    },

    feedbacksBuildingGrid: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.listLoading = false;
      state.buildingsGrid = action.payload.buildingsGrid;
      state.error = null;
      state.totalBuilding = action.payload.buildingsGrid ? action.payload.buildingsGrid.length : 0;
    },

    feedbacksFetchedAssets: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.feedbacksAssets = action.payload.feedbacksAssets;
      state.error = null;
    },

    feedbackImportFetched: (state, action) => {
      const { feedbackImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.feedbackImport = feedbackImport;
    },
  },
});
