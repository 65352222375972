import React from "react";
import { setFilterConfig } from "../LocalStorageConfig";

export const CodeColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  // { setIdActive = () => { },
  // }
  { openShowPage = () => { } }
) => {

  return (
    <>
      <span
        type="button"
        // onClick={() => { setIdActive(row.id) }}
        onClick={() => openShowPage(row.id)}
        className="text-primary notEvent">
        {cellContent}
      </span>
    </>
  )
}
