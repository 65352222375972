import React from "react";
import { Modal } from "react-bootstrap";

import { SettingCurrencyDialogHeader } from "./SettingCurrencyDialogHeader";
import { SettingCurrencyForm } from "./SettingCurrencyForm";

export function SettingCurrencyDialog({ currencies, show, onHide, applySelectedRows }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SettingCurrencyDialogHeader />
      <SettingCurrencyForm
        currencies={currencies}
        onHide={onHide}
        applySelectedRows={applySelectedRows}
      />
    </Modal>
  );
}
