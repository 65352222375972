import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialGoodSequencesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  goodSequenceForEdit: null,
  goodSequenceForShow: null,
  goodSequenceImport: null,
  goodSequenceImportForEdit: null,
  filterCount: null,
  notification: null,
  error: null,
  currentGoodSequences: [],
  currentGoodSequence: null,
  isError: null,
  errorType: null

};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const goodSequencesSlice = createSlice({
  name: "goodSequences",
  initialState: initialGoodSequencesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    startCallParent: (state, action) => {
      state.error = null;



      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentGoodSequence = null;
      state.currentGoodSequences = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.goodSequenceForEdit = null;
    },

    resetCurrentGoodSequences: (state, action) => {
      state.currentGoodSequence = null;
      state.currentGoodSequences = [];
    },

    resetImport: (state, action) => {
      state.goodSequenceImport = null;
    },


    //show
    goodSequenceGettedById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceForShow = action.payload.goodSequenceForShow;
      state.error = null;

    },

    // update
    updateGoodSequenceById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.goodSequence.id) {
          return action.payload.goodSequence;
        }
        return entity;
      });
      state.currentGoodSequences.push(action.payload.goodSequence);
    },

    // delete
    goodSequenceDeletedById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentGoodSequences = [];
    },

    // index
    goodSequenceGettedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },

    // create
    goodSequenceCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      state.entities.unshift(action.payload.goodSequence);
      state.currentGoodSequences.push(action.payload.goodSequence);
    },

    // create import
    goodSequenceCreatedImport: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      state.currentGoodSequences.push(action.payload.goodSequence);
    },

    // find
    goodSequenceFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },

    // edit
    goodSequenceGettedEditById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceForEdit = action.payload.goodSequenceForEdit;
      state.error = null;
    },

    // clone
    goodSequenceGettedCloneById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceForEdit = action.payload.goodSequenceForEdit;
      state.error = null;
    },

    // create
    goodSequenceGettedInit: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceForEdit = action.payload.goodSequenceForEdit;
      state.error = null;
    },

    // edit import excel
    goodSequenceGettedEditImportById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceImportForEdit = action.payload.goodSequenceImportForEdit;
      state.error = null;
    },

    // create import excel
    goodSequenceGettedInitImport: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.goodSequenceImportForEdit = action.payload.goodSequenceImportForEdit;
      state.error = null;
    },

    // delete bulk 
    goodSequencesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentGoodSequences = [];
    },
  },
});
