/* eslint-disable eqeqeq */
import { stringToBoolean, PRICE_TYPES_OBJ, SERVICE_TYPES_OBJ, UNIT_PRICE_TYPE_OBJ } from "../../app/_helpers/_CommonUIHelpers";
// Charge date calculation



export const serviceWithMeterNotShared = (service) => {
    let serviceType = Number(service.serviceType);
    if ((serviceType === SERVICE_TYPES_OBJ.ELECTRICITY || serviceType === SERVICE_TYPES_OBJ.WATER || serviceType === SERVICE_TYPES_OBJ.GAS) && service.isShared !== true) {
        return true;
    };
};

export const serviceWithMeter = (service) => {
    let serviceType = Number(service.serviceType);
    if (serviceType === SERVICE_TYPES_OBJ.ELECTRICITY || serviceType === SERVICE_TYPES_OBJ.WATER || serviceType === SERVICE_TYPES_OBJ.GAS) {
        return true;
    }
    else {
        return false;
    };
};

export const setServiceDateObj = (startChargeDateString, endChargeDateString, startChargeDateStringInitial, endChargeDateStringInitial) => {
    let startHour = startChargeDateStringInitial?.includes('T') ? startChargeDateStringInitial.slice(startChargeDateStringInitial.indexOf("T")) : "T00:00";
    let endHour = endChargeDateStringInitial?.includes('T') ? endChargeDateStringInitial.slice(endChargeDateStringInitial.indexOf("T")) : "T23:59";
    let startChargeDateStringDateLocal = startChargeDateString?.includes('T') ? startChargeDateString : (startChargeDateString + startHour);
    let startChargeDateStringDate = startChargeDateString?.includes('T') ? startChargeDateString.slice(0, startChargeDateString.indexOf("T")) : startChargeDateString;
    let endChargeDateStringDateLocal = endChargeDateString?.includes('T') ? endChargeDateString : (endChargeDateString + endHour);
    let endChargeDateStringDate = endChargeDateString?.includes('T') ? endChargeDateString.slice(0, endChargeDateString.indexOf("T")) : endChargeDateString;
    return {
        startChargeDateStringDate,
        endChargeDateStringDateLocal,
        startChargeDateStringDateLocal,
        endChargeDateStringDate,
        startChargeDateString,
        endChargeDateString
    }
};
export const serviceWithMeterAndPerPrice = (service) => {
    const formulaType = Number(service.formulaType)
    if (formulaType !== UNIT_PRICE_TYPE_OBJ.PER_HOUR ||
        formulaType !== UNIT_PRICE_TYPE_OBJ.PER_DAY ||
        formulaType !== UNIT_PRICE_TYPE_OBJ.PER_MONTH) {
        return true;
    }
    else {
        return false;
    };
};

export const serviceIsRentOrDeposit = (service) => {
    const serviceType = Number(service.serviceType);
    if (serviceType === SERVICE_TYPES_OBJ.RENT ||
        serviceType === SERVICE_TYPES_OBJ.DEPOSITS ||
        serviceType === SERVICE_TYPES_OBJ.PRE_DEPOSITS) {
        return true;
    }
    else {
        return false;
    };
};

export const serviceHavePeriod = (service) => {
    const serviceType = Number(service.serviceType);

    if (serviceType === SERVICE_TYPES_OBJ.ELECTRICITY ||
        serviceType === SERVICE_TYPES_OBJ.WATER ||
        serviceType === SERVICE_TYPES_OBJ.GAS ||
        serviceType === SERVICE_TYPES_OBJ.PRE_DEPOSITS ||
        serviceType === SERVICE_TYPES_OBJ.DEPOSITS
    ) {
        return true;
    }
    else {
        return false;
    };
};

export const serviceOnlyFixPrice = (service) => {
    const serviceType = Number(service.serviceType);
    if (serviceType === SERVICE_TYPES_OBJ.PRE_DEPOSITS ||
        serviceType === SERVICE_TYPES_OBJ.DEPOSITS ||
        serviceType === SERVICE_TYPES_OBJ.VEHICLE ||
        serviceType === SERVICE_TYPES_OBJ.OTHER
    ) {
        return true;
    }
    else {
        return false;
    };
};






export const findFormulasByServiceType = (formulas, serviceType) => {
    let newFormulas = [];



    if (formulas === undefined || formulas === null) return newFormulas;

    for (let i = 0; i < formulas.length; i++) {
        let item = formulas[i];
        if (Number(serviceType) === Number(item.serviceType)) {
            newFormulas.push(item)
        };
    }

    return newFormulas;
};

export const setHiddenChargeDateString = (row) => {
    let isShow = true;
    let serviceType = Number(row.service.serviceType);
    if (serviceType === SERVICE_TYPES_OBJ.DEPOSITS || serviceType === SERVICE_TYPES_OBJ.PRE_DEPOSITS) {
        isShow = false;
    };


    return isShow;
};

export const setHiddenActualNumberOfTimes = (row) => {
    let isShow = true;
    let serviceType = Number(row.service.serviceType);
    if (serviceType === SERVICE_TYPES_OBJ.DEPOSITS || serviceType === SERVICE_TYPES_OBJ.PRE_DEPOSITS ||
        serviceType === SERVICE_TYPES_OBJ.PREPAY
    ) {
        isShow = false;
    };
    if (serviceWithMeter(row.service)) {
        const formulaType = Number(row.formulaType);
        if (formulaType === PRICE_TYPES_OBJ.FIX_PRICE || formulaType === PRICE_TYPES_OBJ.FORMULA_PRICE) {
            isShow = false;
        }

    };

    return isShow;
};

export const setDisabledChargeDateString = (row) => {
    let disabled = false;

    // if (serviceWithMeter(row.service)) {
    //     if (row.formulaType == PRICE_TYPES_OBJ.FIX_PRICE || row.formulaType == PRICE_TYPES_OBJ.FORMULA_PRICE) {
    //         disabled = true;
    //     }

    // };
    if (row.isPeriod) {
        disabled = true;
    }

    return disabled;
};

export const hiddenColumnAmount = (row) => {
    let isShow = true;
    // if (row.service.serviceType >= SERVICE_TYPES_OBJ.VEHICLE) {
    //     isShow = true;
    // };
    if (serviceWithMeter(row.service)) {
        const formulaType = Number(row.formulaType);
        if (formulaType == PRICE_TYPES_OBJ.FIX_PRICE || formulaType == PRICE_TYPES_OBJ.FORMULA_PRICE) {
            isShow = false;
        } else { isShow = true };
    };
    // if (row.service.serviceType <= 3) {
    //     isShow = false;
    // };
    return isShow;
};


export const findMeterIdByUnitId = (meters, row) => {
    let metersOfUnit = [];
    meters && meters.map((item) => {
        if (row.service.serviceType == item.meterType && row.service.isShared == item.isShared) {
            metersOfUnit.push(item)
        };
        return "";
    });
    return metersOfUnit;

};


export const setDisabledFieldset = (values) => {
    let disabled = false
    if (values && (Number(values.status) === 2 || stringToBoolean(values.isClosingDebts) === true)) {
        disabled = true;
    } else { }
    return disabled
};



//set action on invoice card
export const conditionsToDeleteInvoices = (invoices) => {
    let disableDeleteInvoice = true
    if (invoices.length === 1) {
    } else {

    }
    return disableDeleteInvoice;
}

