import React, { useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const Button = ({
    type = "button",
    icon = false,
    disabled = false,
    message = "FormatterMessage",
    hideMessage = false,
    handleClick = () => { },
    isLoading = null,
    hidden = false,
    hover = false,
    margin = "mr-1",
    size = "md",
    color = "primary",
    className = `btn btn-${color} btn-elevate btn-${size} font-size-xs ${margin}`,
    dataToggle = "",
    dataTarget = "",
    ariaExpanded = "",
    ariaControls = "",
    style = {},
}) => {

    const [runLoading, setRunLoading] = useState(null);
    useEffect(() => {
        isLoading === false && setRunLoading(null);
        return () => {
        };
    }, [isLoading]);

    return (
        !hidden ?
            (runLoading && isLoading) ?
                <button
                    type="button"
                    disabled={true}
                    className={className}
                    data-toggle={dataToggle}
                    data-target={dataTarget}
                    aria-expanded={ariaExpanded}
                    aria-controls={ariaControls}
                    style={style}
                >
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <FormattedMessage id={message}></FormattedMessage>
                </button>
                :
                (hover ?
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id={`${message}-button-tooltip`}>
                                <FormattedMessage id={message}></FormattedMessage>
                            </Tooltip>}
                    >
                        <button
                            type="button"
                            disabled={disabled}
                            className={className}
                            data-toggle={dataToggle}
                            data-target={dataTarget}
                            aria-expanded={ariaExpanded}
                            aria-controls={ariaControls}
                            style={style}
                            onClick={() => { handleClick(); setRunLoading(true) }}
                        >
                            <i className={icon}></i>
                            {!hideMessage && <FormattedMessage id={message}></FormattedMessage>}
                        </button>

                    </OverlayTrigger>
                    :
                    <button
                        type="button"
                        disabled={disabled}
                        className={className}
                        data-toggle={dataToggle}
                        data-target={dataTarget}
                        aria-expanded={ariaExpanded}
                        aria-controls={ariaControls}
                        style={style}
                        onClick={() => { handleClick(); setRunLoading(true) }}
                    >
                        <i className={icon.toString()}></i>
                        {!hideMessage && <FormattedMessage id={message}></FormattedMessage>}
                    </button>
                )

            : <></>
    )
}
