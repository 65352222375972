import React from "react";
import { AttachmentsFilter } from "./AttachmentsFilter";
import { AttachmentsTable } from "./AttachmentsTable";
import { AttachmentsLoadingDialog } from "./AttachmentsLoadingDialog";

export function Attachments() {

  return (
    <>
      <AttachmentsLoadingDialog />
      <div className="form margin-b-30">
        <AttachmentsFilter />

      </div>
      <AttachmentsTable />
    </>
  );
}
