/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_bloomgoo/_partials/controls";
import * as actionsCurrencies from "../../Currencies/_redux/currencies/currenciesActions"
import { useCurrenciesUIContext } from "./CurrenciesUIContext";

export function CurrenciesDeleteDialog() {
  // Specs UI Context
  const uIContext = useCurrenciesUIContext();
  const uIProps = useMemo(() => {
    return {
      productId: uIContext && uIContext.productId,
      ids: uIContext && uIContext.ids,
      show: uIContext && uIContext.showDeleteCurrenciesDialog,
      onHide: uIContext && uIContext.closeDeleteCurrenciesDialog,
      setIds: uIContext && uIContext.setIds,
      selectedRows: uIContext && uIContext.selectedRows,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
      queryParams: uIContext && uIContext.queryParams,
    };
  }, [uIContext]);

  // Specs Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.currencies.actionsLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => { }, [isLoading, dispatch]);

  // if there weren't selected vouchers we should close modal
  useEffect(() => {
    if (!uIProps.ids || uIProps.ids.length === 0) {
      uIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uIProps.ids]);

  const deleteCurrencies = () => {
    // server request for selected deleting currencies
    dispatch(actionsCurrencies.deleteCurrency(uIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(
        actionsCurrencies.fetchCurrencies(
          uIProps.queryParams,
          uIProps.productId
        )
      ).then(() => {
        uIProps.setIds && uIProps.setIds([]);
        uIProps.onHide();
      });
    });
  };

  return (
    <Modal
      show={uIProps.show}
      onHide={uIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Currencies Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>
            Are you sure to permanently delete selected currencies?
          </span>
        )}
        {isLoading && <span>Currencies are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={uIProps.onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteCurrencies}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
