import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (size, touched, errors, isError) => {
  const classes = ["form-control", `form-control-${size}`];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  if (isError === "is-invalid") {
    classes.push("is-invalid");
  }

  if (isError === "is-valid") {
    classes.push("custom-is-valid");
  }
  return classes.join(" ");
};

export function Select({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  placeholder,
  withFeedbackLabel = true,
  customFeedbackLabel,
  size = "md",
  type,
  required = false,
  defaulted = true,
  defaultedAll = false,
  defaultedOrganization = false,
  defaultedValue = false,
  children,
  ...props
}) {
  // const aErrorsString = JSON.stringify(errors);
  let isError = false;
  // if (aErrorsString.includes(field.name) && aErrorsString.includes('].')) {
  //   isError = true
  // }
  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}

      {type === "text" && <select
        className={getFieldCSSClasses(size, touched[field.name], errors[field.name], isError)}
        style={{
          minWidth: "100px"
        }}
        {...field}
        {...props}
        value={field.value || "0"}
      >
        {defaulted && <option value="0">-- Chọn --</option>}
        {defaultedAll && <option value="">-- Tất cả --</option>}
        {defaultedValue && <option value='0'>-- Tất cả --</option>}
        {defaultedOrganization && <option value='0'>-- Tổ chức --</option>}
        {children}
      </select>}
      {false && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
