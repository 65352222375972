import React from "react";


export const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
    setColumnToggleConfig,
    unutilizedDataField = [
        "id",
        "buildingId",
        "version",
        "action"
    ]
}) => {
    // //console.log(columns);
    const setColumnToggle = (value, dataField) => {
        return setColumnToggleConfig(dataField, !value);
    };
    let dataFields = columns && columns
        .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
        }));

    return (
        <>
            < div className="">
                <div className="card border-0">
                    {/* <div className="card-header bg-white">
                        <div className="card-title py-0 justify-content-end px-10" data-toggle="collapse" data-target="#toggle-column-table" >
                            <i className="flaticon-information"></i> Tuỳ chỉnh cột hiển thị trên  bảng
                        </div>
                    </div> */}
                    <div id="toggle-column-table" className="collapse">
                        <div className="card-body">
                            <div className="">
                                <div className="row ml-3">
                                    {/* <div className="btn-group btn-group-toggle " data-toggle="buttons" role="group"> */}
                                    {
                                        dataFields.filter(item => !unutilizedDataField.some(el => item.dataField === el)
                                        )
                                            .map((item, rowIndex) => (
                                                <div className="col-lg-2" key={item.dataField}>
                                                    <div className="row py-4">
                                                        <label className="checkbox checkbox-single col-2">
                                                            <input type="checkbox" checked={item.toggle} onChange={(e) => { onColumnToggle(item.dataField); setColumnToggle(e.target.checked, item.dataField) }} />
                                                            <span></span>
                                                        </label>
                                                        <span>{item.text} </span>
                                                    </div>

                                                </div>
                                            ))
                                    }
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};