/* eslint-disable eqeqeq */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState } from "react";

export function InformationRes(props) {
    const { title, content } = props;
    const [isCollapse, setIsCollapse] = useState(true);

    return (
        <>
            <div className="accordion accordion-toggle-arrow" onClick={() => setIsCollapse(!isCollapse)}>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title py-0" data-toggle="collapse" data-target="#information">
                            <i className="flaticon-information"></i> {title}
                        </div>
                    </div>
                    <div id="information" className={isCollapse == true ? `collapse` : `collapse show`}>
                        <div className="card-body">
                            <div className="mb-3">
                                <b>{content}</b>
                            </div>
                            <div>
                                <p>“Tổ chức” –Tài khoản của tổ chức cho thuê do khách hàng đặt, tên miền quản trị có dạng <span className="text-primary"><b>matochuc.chuoicanho.com</b></span>, trong đó phần  <span className="text-primary"><b>matochuc</b></span> do chủ tài khoản tự đặt. </p>
                                <p>Mọi thắc mắc vui lòng liên hệ Hotline CSKH:</p>
                                <a href="tel:0931741666" className="btn btn-icon btn-light-success btn-circle btn-md mr-2">
                                    <i className="fas fa-phone-alt"></i>
                                </a>
                                <span className="font-weight-bold">0931 741 666</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
