import axios from "axios";

export const WARDS_URL = "/wards";

// CREATE =>  POST: add a new ward to the server
export function createWard(ward) {
  return axios.post(WARDS_URL, ward);
}

// READ
export function getAllWards() {
  return axios.get(WARDS_URL);
}

export function getInitWard() {
  return axios.get(`${WARDS_URL}/create`);
}

export function getWardCloneById(wardId) {
  return axios.get(`${WARDS_URL}/${wardId}/clone`);
}
export function getWardById(wardId) {
  return axios.get(`${WARDS_URL}/${wardId}`);
}

export function findWardsByDistrictId(districtId) {
  return axios.get(`${WARDS_URL}/district/${districtId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findWards(queryParams) {
  return axios.post(`${WARDS_URL}/find`, queryParams);
}


// UPDATE => PUT: update the procuct on the server
export function updateWard(ward) {
  return axios.put(`${WARDS_URL}/${ward.id}`, ward);
}

// UPDATE Status
export function updateStatusForWards(ids, status) {
  return axios.post(`${WARDS_URL}/updateStatusForWards`, {
    ids,
    status,
  });
}

// DELETE => delete the ward from the server
export function deleteWard(wardId) {
  return axios.delete(`${WARDS_URL}/${wardId}`);
}

// DELETE Wards by ids
export function deleteWards(ids) {
  return axios.delete(`${WARDS_URL}/bulk`, { data: { ids } });

}
