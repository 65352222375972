/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout, AntdProLayout } from "../_bloomgoo/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Preview from "./modules/Auth/pages/Preview";
import RegisterPassword from "./modules/Auth/pages/RegisterPassword";

export function Routes() {
  const ORGANIZATION_ADMINISTRATOR = 1;

  const { isAuthorized, user, organizationType } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user,
      organizationType: auth.user?.organization?.organizationType,
    }),
    shallowEqual
  );
  //console.log("isAuthorized", isAuthorized);
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/dang-nhap" to="/" />
      )}
      <Route exact path="/reset_password" component={RegisterPassword} />
      <Route path="/hoadon/:id" component={Preview} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/dang-xuat" component={Logout} />

      {!isAuthorized ? (
        <Redirect to="/dang-nhap" />
      ) : (
        <>
          {organizationType && organizationType === 1 ? (
            <AntdProLayout>
              <BasePage />
            </AntdProLayout>
          ) : (
            <Layout>
              <BasePage />
            </Layout>
          )}
        </>
      )}
    </Switch>
  );
}
