import React from "react";
import { Modal } from "react-bootstrap";

import { AttachmentsDialogHeader } from "./AttachmentsDialogHeader";
import { AttachmentsForm } from "./AttachmentsForm";

export function AttachmentsDialog({ isMulti = false, show, onHide, applySelectedRows }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AttachmentsDialogHeader />
      <AttachmentsForm
        isMulti={isMulti}
        onHide={onHide}
        applySelectedRows={applySelectedRows}
      />
    </Modal>
  );
}
