import React from "react";
// import { Demo1Dashboard } from "./Demo1Dashboard";
// import { Demo2Dashboard } from "./Demo2Dashboard";
// import { Demo3Dashboard } from "./Demo3Dashboard";
// import { Demo4Dashboard } from "./Demo4Dashboard";
// import { Demo5Dashboard } from "./Demo5Dashboard";
// import { Demo6Dashboard } from "./Demo6Dashboard";
// import { Demo7Dashboard } from "./Demo7Dashboard";

export function Dashboard() {
  // const layoutProps = useMemo(() => {
  //   return {
  //     chuoicanho: objectPath.get(uiService.config, "chuoicanho"),
  //   };
  // }, [uiService]);

  //console.log(uiService.config);

  return (
    <>
      <div className="card card-custom card-stretch">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Bảng quản trị <small>same height cards</small></h3>
          </div>
        </div>
        <div className="card-body card-scroll">
          <div className="row mb-5">
            <div className="col-lg-5">
              <div className="row mb-5">
                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Tổng doanh thu <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className={`container-animate-css-grid `}>
                        <div className={`grid grid-grap`}>
                          <>
                            <div className={`card card--expanded bg-`}>
                              <div>
                                <div className="card__avatar1" />
                                <div className="card__title1" />
                                <div className="card__description1">Tổ chức</div>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Doanh thu thực tế <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Cọc hợp đồng <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Cọc giữ phòng <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Tiền trả trước <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Tỉ suất lợi nhuận <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row mb-5">
                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Lợi nhuận <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Lợi nhuận thực tế <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Tổng chi phí <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Tổng tiền thuê <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Tổng tiền dịch vụ <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-8">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Doanh thu hàng tháng <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="row mb-5">
                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Quý 1 <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Quý 2 <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Quý 3 <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Quý 4 <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-8">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Doanh thu & chi phí <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-8">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="card card-custom card-stretch">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">Thông tin cơ sở <small>same height cards</small></h3>
                      </div>
                    </div>
                    <div className="card-body">
                      ...
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card card-custom card-stretch">
                        <div className="card-header">
                          <div className="card-title">
                            <h3 className="card-label">Đơn vị cho thuê <small>same height cards</small></h3>
                          </div>
                        </div>
                        <div className="card-body">
                          ...
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card card-custom card-stretch">
                        <div className="card-header">
                          <div className="card-title">
                            <h3 className="card-label">Khách hàng <small>same height cards</small></h3>
                          </div>
                        </div>
                        <div className="card-body">
                          ...
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card card-custom card-stretch">
                        <div className="card-header">
                          <div className="card-title">
                            <h3 className="card-label">Hợp đồng <small>same height cards</small></h3>
                          </div>
                        </div>
                        <div className="card-body">
                          ...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Thông báo, cảnh báo <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-8">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Người dùng & Môi giới <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Thống kê tài sản <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Tài sản <small>same height cards</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4">
              <div className="card card-custom card-stretch">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Người dùng <small>full height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-custom card-stretch card-stretch-third gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>third height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>

              <div className="card card-custom card-stretch card-stretch-third gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>third height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>

              <div className="card card-custom card-stretch card-stretch-third">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>third height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-custom card-stretch card-stretch-half gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>half height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>

              <div className="card card-custom card-stretch card-stretch-half">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>half height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="card card-custom card-stretch card-stretch-fourth gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>fourth height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
              <div className="card card-custom card-stretch card-stretch-fourth gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>fourth height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
              <div className="card card-custom card-stretch card-stretch-fourth gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>fourth height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
              <div className="card card-custom card-stretch card-stretch-fourth">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>fourth height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card card-custom card-stretch card-stretch-half gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>full height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>

              <div className="card card-custom card-stretch card-stretch-half">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Card Title <small>full height card</small></h3>
                  </div>
                </div>
                <div className="card-body">
                  ...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="card-group">
        <div className="card ">
          <div className="card-header">
            <div className="card-title h5">Header</div>
          </div>
          <div className="card-body">
            <div className="input-group input-group-solid">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <span className="svg-icon svg-icon-lg">

                  </span>
                </span>
              </div>
              <input type="text" className="form-control py-4 h-auto" placeholder="Email" />
            </div>
            <div className="mt-7 scroll scroll-pull">
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_12.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Matt Pears</span>
                    <span className="text-muted font-weight-bold font-size-sm">Head of Development</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">35 mins</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_11.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Charlie Stone</span>
                    <span className="text-muted font-weight-bold font-size-sm">Art Director</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">7 hrs</span>
                  <span className="label label-sm label-success">4</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_10.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Teresa Fox</span>
                    <span className="text-muted font-weight-bold font-size-sm">Web Designer</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">3 hrs</span>
                  <span className="label label-sm label-danger">5</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_13.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Giannis Nelson</span>
                    <span className="text-muted font-weight-bold font-size-sm">IT Consultant</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">2 days</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_15.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Carles Puyol</span>
                    <span className="text-muted font-weight-bold font-size-sm">Operator</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">5 mins</span>
                  <span className="label label-sm label-success">9</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_16.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Ana Torn</span>
                    <span className="text-muted font-weight-bold font-size-sm">Head Of Finance</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">2 days</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_18.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Lisa Pears</span>
                    <span className="text-muted font-weight-bold font-size-sm">Web Designer</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">6 mins</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_20.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Amanda Bold</span>
                    <span className="text-muted font-weight-bold font-size-sm">Art Director</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">3 hrs</span>
                  <span className="label label-sm label-warning">7</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_21.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Nick Jhonson</span>
                    <span className="text-muted font-weight-bold font-size-sm">IT Consultant</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">10 mins</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_19.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Sarah Larson</span>
                    <span className="text-muted font-weight-bold font-size-sm">Web Designer</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">4 hrs</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_22.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Matt Pears</span>
                    <span className="text-muted font-weight-bold font-size-sm">Head of Development</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">35 mins</span>
                  <span className="label label-sm label-success">5</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_23.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Tim Stone</span>
                    <span className="text-muted font-weight-bold font-size-sm">Web Developer</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">5 hrs</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_17.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Jhon Richardson</span>
                    <span className="text-muted font-weight-bold font-size-sm">Head of Development</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">1 week</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_18.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Ana Kiskia</span>
                    <span className="text-muted font-weight-bold font-size-sm">Web Master</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">35 mins</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_14.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Nick Stone</span>
                    <span className="text-muted font-weight-bold font-size-sm">Art Director</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">3 hrs</span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle symbol-50 mr-3">
                    <img alt="Pic" src="media/users/300_13.jpg" />
                  </div>
                  <div className="d-flex flex-column">
                    <span
                      type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Nick Nilson</span>
                    <span className="text-muted font-weight-bold font-size-sm">Software Arcitect</span>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="text-muted font-weight-bold font-size-sm">3 days</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">Footer</small>
          </div>
        </div>

        <div className="card ">
          <div className="card-header">
            <div className="text-left flex-grow-1">
              <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none" id="kt_app_chat_toggle">
                <span className="svg-icon svg-icon-lg">

                </span>
              </button>
              <div className="dropdown dropdown-inline">
                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="ki ki-bold-more-hor icon-md"></i>
                </button>
                <div className="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-md">
                  <ul className="navi navi-hover py-5">
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-drop"></i>
                        </span>
                        <span className="navi-text">New Group</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-list-3"></i>
                        </span>
                        <span className="navi-text">Contacts</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-rocket-1"></i>
                        </span>
                        <span className="navi-text">Groups</span>
                        <span className="navi-link-badge">
                          <span className="label label-light-primary label-inline font-weight-bold">new</span>
                        </span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Calls</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-gear"></i>
                        </span>
                        <span className="navi-text">Settings</span>
                      </span>
                    </li>
                    <li className="navi-separator my-3"></li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-magnifier-tool"></i>
                        </span>
                        <span className="navi-text">Help</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Privacy</span>
                        <span className="navi-link-badge">
                          <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="text-center flex-grow-1">
              <div className="text-dark-75 font-weight-bold font-size-h5">Matt Pears</div>
              <div>
                <span className="label label-sm label-dot label-success"></span>
                <span className="font-weight-bold text-muted font-size-sm">Active</span>
              </div>
            </div>
            <div className="text-right flex-grow-1">
              <div className="dropdown dropdown-inline">
                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="svg-icon svg-icon-lg">
                  </span>
                </button>
                <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                  <ul className="navi navi-hover py-5">
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-drop"></i>
                        </span>
                        <span className="navi-text">New Group</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-list-3"></i>
                        </span>
                        <span className="navi-text">Contacts</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-rocket-1"></i>
                        </span>
                        <span className="navi-text">Groups</span>
                        <span className="navi-link-badge">
                          <span className="label label-light-primary label-inline font-weight-bold">new</span>
                        </span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Calls</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-gear"></i>
                        </span>
                        <span className="navi-text">Settings</span>
                      </span>
                    </li>
                    <li className="navi-separator my-3"></li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-magnifier-tool"></i>
                        </span>
                        <span className="navi-text">Help</span>
                      </span>
                    </li>
                    <li className="navi-item">
                      <span
                        type="button" className="navi-link">
                        <span className="navi-icon">
                          <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Privacy</span>
                        <span className="navi-link-badge">
                          <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="scroll scroll-pull" data-mobile-height="350">
              <div className="messages">
                <div className="d-flex flex-column mb-5 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle symbol-40 mr-3">
                      <img alt="Pic" src="media/users/300_12.jpg" />
                    </div>
                    <div>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</span>
                      <span className="text-muted font-size-sm">2 Hours</span>
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">How likely are you to recommend our company to your friends and family?</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-end">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-muted font-size-sm">3 minutes</span>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</span>
                    </div>
                    <div className="symbol symbol-circle symbol-40 ml-3">
                      <img alt="Pic" src="media/users/300_21.jpg" />
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle symbol-40 mr-3">
                      <img alt="Pic" src="media/users/300_21.jpg" />
                    </div>
                    <div>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</span>
                      <span className="text-muted font-size-sm">40 seconds</span>
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">Ok, Understood!</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-end">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-muted font-size-sm">Just now</span>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</span>
                    </div>
                    <div className="symbol symbol-circle symbol-40 ml-3">
                      <img alt="Pic" src="media/users/300_21.jpg" />
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">You’ll receive notifications for all issues, pull requests!</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle symbol-40 mr-3">
                      <img alt="Pic" src="media/users/300_12.jpg" />
                    </div>
                    <div>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</span>
                      <span className="text-muted font-size-sm">40 seconds</span>
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">You can unwatch this repository immediately by clicking here:
                    <span
                      type="button">https://github.com</span></div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-end">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-muted font-size-sm">Just now</span>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</span>
                    </div>
                    <div className="symbol symbol-circle symbol-40 ml-3">
                      <img alt="Pic" src="media/users/300_21.jpg" />
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Discover what students who viewed Learn Figma - UI/UX Design. Essential Training also viewed</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle symbol-40 mr-3">
                      <img alt="Pic" src="media/users/300_12.jpg" />
                    </div>
                    <div>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</span>
                      <span className="text-muted font-size-sm">40 seconds</span>
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">Most purchased Business courses during this sale!</div>
                </div>
                <div className="d-flex flex-column mb-5 align-items-end">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-muted font-size-sm">Just now</span>
                      <span
                        type="button" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</span>
                    </div>
                    <div className="symbol symbol-circle symbol-40 ml-3">
                      <img alt="Pic" src="media/users/300_21.jpg" />
                    </div>
                  </div>
                  <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <textarea className="form-control border-0 p-0" rows="2" placeholder="Type a message"></textarea>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <div className="mr-3">
                <span
                  type="button" className="btn btn-clean btn-icon btn-md mr-1">
                  <i className="flaticon2-photograph icon-lg"></i>
                </span>
                <span
                  type="button" className="btn btn-clean btn-icon btn-md">
                  <i className="flaticon2-photo-camera icon-lg"></i>
                </span>
              </div>
              <div>
                <button type="button" className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">Send</button>
              </div>
            </div>
          </div>
        </div>

        <div className="card ">
          <div className="card-header">
            <div className="card-title h5">Header</div>
          </div>
          <div className="card-body">

          </div>
          <div className="card-footer">
            <small className="text-muted">Footer</small>
          </div>
        </div>
      </div >

      <div className="card-group ">
        <div className="card ">
          <div className="card-header">
            <div className="card-title h5">Header</div>
          </div>
          <div className="card-body">
            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-danger">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-success">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-info">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-warning">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-second">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-warning">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-danger">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-info">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-primary">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-warning">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-danger">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-info">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-primary">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-warning">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-danger">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-info">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-primary">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2">
                <div className="card bg-light">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-warning">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-danger">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-info">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="card bg-primary">
                  <div className="card-body">
                    <p className="card-text text-center">CH1001</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">Footer</small>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card-title h5">Header</div>
          </div>
          <div className="card-body">
            <div className="timeline timeline-5">
              <div className="timeline-items">
                <div className="timeline-item">
                  <div className="timeline-media bg-light-primary">
                    <span className="svg-icon-primary svg-icon-md">
                      <i className="flaticon2-fax text-success "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-primary">
                    <span className="font-weight-bolder text-primary">09:30 AM</span>
                    <p className="font-weight-bold text-dark-50 pb-2">
                      To start a blog, think of a topic about and first brainstorm ways to write details

                      <span className="label label-inline label-light-primary font-weight-bolder ml-2">new</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-warning">
                    <span className="svg-icon-warning svg-icon-md">
                      <i className="flaticon2-layers text-success "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-warning">
                    <span className="font-weight-bolder text-warning">2:45 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1 pb-2">
                      Make deposit <span
                        type="button" className="text-primary">USD 700</span>. to ES

                      <span className="label label-light-success font-weight-bolder ml-2">8</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-success">
                    <span className="svg-icon-success svg-icon-md">

                      <i className="flaticon2-pin text-warning "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-success">
                    <span className="font-weight-bolder text-success">3:12 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1 pb-2">
                      To start a blog, think of a topic about and first brainstorm ways to write details

                      <span className="label label-inline label-light-danger font-weight-bolder ml-2">2 pending</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-danger">
                    <span className="svg-icon-danger svg-icon-md">
                      <i className="flaticon2-sms text-danger "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-danger">
                    <span className="font-weight-bolder text-danger">7:05 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1">
                      To start a blog, think of a topic about and first brainstorm ways to write details

                      <span className="label label-inline label-light-success font-weight-bolder ml-2">pending</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-danger">
                    <span className="svg-icon-danger svg-icon-md">
                      <i className="flaticon2-sms text-danger "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-danger">
                    <span className="font-weight-bolder text-danger">7:05 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1">
                      To start a blog, think of a topic about and first brainstorm ways to write details

                      <span className="label label-inline label-light-success font-weight-bolder ml-2">pending</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-danger">
                    <span className="svg-icon-danger svg-icon-md">
                      <i className="flaticon2-paper-plane text-primary"></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-danger">
                    <span className="font-weight-bolder text-danger">7:05 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1">
                      Task <span
                        type="button" className="text-primary fw-bolder me-1">#45890</span>merged with <span
                          type="button" className="text-primary fw-bolder me-1">#45890</span>in “Ads Pro Admin Dashboard project

                      <span className="label label-inline label-light-success font-weight-bolder ml-2">pending</span>
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-media bg-light-danger">
                    <span className="svg-icon-danger svg-icon-md">
                      <i className="flaticon2-sms text-danger "></i>
                    </span>
                  </div>
                  <div className="timeline-desc timeline-desc-light-danger">
                    <span className="font-weight-bolder text-danger">7:05 PM</span>
                    <p className="font-weight-bold text-dark-50 pt-1">
                      New order placed <span
                        type="button" className="text-primary">#XF-2356</span>.

                      <span className="label label-inline label-light-success font-weight-bolder ml-2">pending</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">Footer</small>
          </div>
        </div >
      </div >
    </>
  );
}
