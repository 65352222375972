import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
// import { initialFilter, initSupplierObj } from "./SuppliersUIHelpers";

const SettingsUIContext = createContext();

export function useSettingsUIContext() {
  return useContext(SettingsUIContext);
}

export const SettingsUIConsumer = SettingsUIContext.Consumer;

export function SettingsUIProvider({ settingsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState();
  const [ids, setIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeId, setActiveId] = useState();

  const [tabActive, setTabActive] = useState(`1`);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    selectedRows,
    setSelectedRows,
    activeId,
    setActiveId,
    setQueryParams,
    tabActive,
    setTabActive,
    organization: settingsUIEvents.organization,
    decentralization: settingsUIEvents.decentralization,
    building: settingsUIEvents.building,
    personal: settingsUIEvents.personal,
    currencies: settingsUIEvents.currencies,
    other: settingsUIEvents.other,
    function: settingsUIEvents.function,
    tabPeronal2ButtonClick: settingsUIEvents.tabPeronal2ButtonClick,
    tabFunctionsButtonClick: settingsUIEvents.tabFunctionsButtonClick,
    tabEmailsButtonClick: settingsUIEvents.tabEmailsButtonClick,
    tabDecentralizationButtonClick: settingsUIEvents.tabDecentralizationButtonClick,
    tabCurrenciesButtonClick: settingsUIEvents.tabCurrenciesButtonClick,
    tabBuildingButtonClick: settingsUIEvents.tabBuildingButtonClick,
  };

  return (
    <SettingsUIContext.Provider value={value}>
      {children}
    </SettingsUIContext.Provider>
  );
}
