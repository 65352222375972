/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { notification as NotificationAnt, message } from "antd";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/toasts/toastsActions";

export function Notification(props) {
  const { intl, notification, onClose } = props;

  return (
    notification.text &&
    NotificationAnt[notification.type]({
      message: notification.type === "warning" ? "Cảnh báo" : "Thông báo",
      description:
        notification.type === "warning"
          ? `${intl.formatMessage(
              {
                id: notification.text,
              } // message
            )}`
          : `${intl.formatMessage(
              {
                id: notification.text.props.id,
              }, // message
              notification.text.props.values // values
            )}`,
      onClose: onClose,
    })
  );
}

export const NotificationIntl = injectIntl(Notification);

export function Toasts() {
  const intl = useIntl();
  const { notification, show } = useSelector(
    (state) => ({
      notification: state.toasts.notification,
      show: state.toasts.show,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const onClose = () => dispatch(actions.hideToasts());

  const [showType, setShowType] = useState("Message");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (notification && showType === "Notification") {
      NotificationAnt[notification.type]({
        message: notification.type === "warning" ? "Cảnh báo" : "Thông báo",
        description:
          notification.type === "warning"
            ? `${intl.formatMessage(
                {
                  id: notification.text,
                } // message
              )}`
            : `${intl.formatMessage(
                {
                  id: notification.text.props.id,
                }, // message
                notification.text.props.values // values
              )}`,
        onClose: onClose,
      });
    }

    if (notification && showType === "Message") {
      messageApi.open({
        type: notification.type,
        content:
          notification.type === "warning"
            ? `${intl.formatMessage(
                {
                  id: notification.text,
                } // message
              )}`
            : `${intl.formatMessage(
                {
                  id: notification.text.props.id,
                }, // message
                notification.text.props.values // values
              )}`,
        onClose: onClose,
      });
    }
  }, [notification]);

  return (
    <>
      {contextHolder}

      {/* {notification && showType === "Notification" &&
        NotificationIntl({
          notification: notification,
          onClose: onClose,
        })
      } */}

      {show && notification ? (
        showType === "Toast" && (
          <div
            style={{
              position: "fixed",
              top: 5,
              right: 0,
              marginBottom: 5,
              marginRight: 5,
              zIndex: 9999,
            }}
          >
            <Toast onClose={onClose} show={show} delay={4000} autohide>
              <Toast.Header
                className={`${
                  notification.type === "warning" ? "bg-warning" : "bg-success"
                } d-flex justify-content-between`}
              >
                <strong>
                  <i
                    className={`${
                      notification.type === "warning"
                        ? "flaticon2-warning"
                        : "flaticon2-bell-2"
                    } text-white icon-nm`}
                  ></i>
                  <span className="text-white">
                    {" "}
                    {notification.type === "warning" ? "Cảnh báo" : "Thông báo"}
                  </span>
                </strong>
              </Toast.Header>
              <Toast.Body className="">
                <span className="px-2">
                  {notification.type === "warning" ? (
                    <FormattedMessage id={notification.text}></FormattedMessage>
                  ) : (
                    notification.text
                  )}
                </span>
              </Toast.Body>
            </Toast>
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
}
