/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getHandlerTableChange, getSelectRow, NoRecordsFoundMessage, PleaseWaitMessage, sortCaret } from "../../../../_bloomgoo/_helpers";
import { DateTimeFormat } from "../../../../_bloomgoo/_helpers/DateHelpers";
import { rowEvents, rowStyle } from "../../../../_bloomgoo/_helpers/TableRowStyle";
import {
  NotFoundTable,
  Pagination
} from "../../../../_bloomgoo/_partials/controls";
import { OrderColumnFormatter } from "../../../_helpers/column-formatters";
import * as commonUIHelpers from "../../../_helpers/_CommonUIHelpers";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";
import { ActionsColumnFormatter } from "./column-formatters";
import { defaultSorted } from "./EmailsUIHelpers";


let codeFilter;
let nameFilter;
let parentFilter;
let addressFilter;
let floorNumberFilter;
let displayOrderFilter;
let ownerNameFilter;
let ownerTelFilter;
let descriptionFilter;
let latitudeFilter;
let longitudeFilter;
let createdByFilter;
let updatedByFilter;
let toggles;
// let columnFields;
// let onColumnToggle;

export const clearFilterVal = ({ isReload, queryParams, setQueryParams }) => {
  toggles.code && codeFilter("");
  toggles.name && nameFilter("");
  toggles.parent && parentFilter("");
  toggles.address && addressFilter("");
  toggles.floor && floorNumberFilter({ number: "", comparator: "=" });
  toggles.displayOrder && displayOrderFilter({ number: "", comparator: "=" });
  toggles.ownerNameFilter && ownerNameFilter("");
  toggles.ownerTelFilter && ownerTelFilter("");
  toggles.descriptionFilter && descriptionFilter("");
  toggles.latitudeFilter && latitudeFilter("");
  toggles.longitudeFilter && longitudeFilter("");
  toggles.createdByFilter && createdByFilter("");
  toggles.updatedByFilter && updatedByFilter("");
  const newQueryParams = JSON.parse(JSON.stringify(queryParams));
  const searchCriteria = [];
  newQueryParams && newQueryParams.filter.searchCriteria.forEach(criteria => {
    if (criteria.isDefault || criteria.level === 2) {
      const newCriteria = {
        key: criteria.key,
        operation: criteria.operation,
        value: criteria.value,
        prefix: criteria.prefix,
        suffix: criteria.suffix,
        isDefault: criteria.isDefault,
        level: criteria.level,
      };
      searchCriteria.push(newCriteria);
    }
  });
  newQueryParams.filter.searchCriteria = searchCriteria;
  newQueryParams.sortOrder = defaultSorted[0].order; // asc||desc
  newQueryParams.sortField = defaultSorted[0].dataField;
  newQueryParams.pageNumber = 1;
  newQueryParams.pageSize = 35;
  setQueryParams(newQueryParams);
};
export function EmailTableIntl(props) {
  const { emailTemplateEvents, queryParams, setQueryParams, intl } = props;
  const dispatch = useDispatch();

  // const uIContext = useEmailsUIContext();
  // const uIProps = useMemo(() => {
  //   return {
  //     ids: uIContext.ids,
  //     setIds: uIContext.setIds,
  //     queryParams: uIContext.queryParams,
  //     selectedRows: uIContext.selectedRows,
  //     setSelectedRows: uIContext.setSelectedRows,
  //     setQueryParams: uIContext.setQueryParams,
  //     openEditEmailPage: uIContext.openEditEmailPage,
  //     openCloneEmailPage: uIContext.openCloneEmailPage,
  //     openDeleteEmailDialog: uIContext.openDeleteEmailDialog,
  //     openShowEmailPage: uIContext.openShowEmailPage,
  //     stateDataField: uIContext.stateDataField,
  //     changeHiddenColumns: uIContext.changeHiddenColumns,
  //   };
  // }, [uIContext]);
  const { currentState, user } = useSelector(
    (state) => ({
      currentState: state.emailTemplates,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const [ids, setIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetModule());
    }
  }, []);
  const [showActions, setShowActions] = useState(false);

  const columns = [
    {
      dataField: "id",
      headerClasses: "text-center",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      formatter: OrderColumnFormatter,
      classes: "text-center",
      editor: {
        type: "number"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
      hidden: true,
    },
    {
      dataField: "action",
      headerClasses: "text-center",
      text: "#",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        actions: [
          { action: emailTemplateEvents, icon: "flaticon-edit text-primary", message: "COMMONS.UI.Edit", mode: "edit" },
          { action: emailTemplateEvents, icon: "flaticon-add text-primary", message: "COMMONS.UI.Clone", mode: "clone" },
          { action: emailTemplateEvents, icon: "flaticon-delete text-danger", message: "COMMONS.UI.Delete", mode: "delete" },
        ],
        show: showActions,
        setShow: setShowActions,
        hidden: [
          !isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_UPDATE_WRITE"),
          !isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_CLONE_WRITE"),
          !isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_DELETE_WRITE")
        ]
      },
      classes: "text-center notEvent",
      editor: {
        type: "number"
      },
      style: {
        width: "40px"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    {
      dataField: "code",
      headerClasses: "text-center",
      text: intl.formatMessage({
        id: "MODEL.UI.meters.code"
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        return (
          <span
            type="button"
            onClick={() => { emailTemplateEvents("show", row.id) }}
          >
            {row.code}
          </span>
        )
      },
      classes: "text-left",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    {
      dataField: "name",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.emailTemplates.name",
        }),
      sort: true,
      sortCaret: sortCaret,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
        className: "form-control-sm",
        placeholder: intl.formatMessage({ id: "MODEL.UI.emailTemplates.placeholder.name", }),
      }),
      formatter: (cellContent, row) =>
        <span>{row?.name}</span>,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },
    {
      dataField: "createdAt",
      headerClasses: "text-center",
      text:
        intl.formatMessage({
          id: "MODEL.UI.emailTemplates.createdAt",
        }),
      sort: true,
      sortCaret: sortCaret,
      // filter: selectFilter({
      //   // getFilter: (filter) => { projectIdFilter = filter },
      //   className: "form-control-sm",
      //   placeholder: "-- Tất cả --",
      //   options: projects ? projects.map(item => ({
      //     value: item.id,
      //     label: item.name
      //   })) : [{}],
      //   withoutEmptyOption: false,
      //   style: {},
      // }),
      formatter: (cellContent, row) =>
        <span>{DateTimeFormat(row?.createdAt)}</span>,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },
    {
      dataField: "createdBy",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.emailTemplates.createdAt",
        }),
      sort: true,
      sortCaret: sortCaret,
      // filter: selectFilter({
      //   // getFilter: (filter) => { projectIdFilter = filter },
      //   className: "form-control-sm",
      //   placeholder: "-- Tất cả --",
      //   options: projects ? projects.map(item => ({
      //     value: item.id,
      //     label: item.name
      //   })) : [{}],
      //   withoutEmptyOption: false,
      //   style: {},
      // }),
      formatter: (cellContent, row) =>
        <span>{row?.createdUser?.fullName}</span>,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },
    {
      dataField: "isDefault",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.emailTemplates.isDefault",
        }),
      sort: true,
      sortCaret: sortCaret,

      formatter: (cellContent, row) =>
        <span>{row?.isDefault && <span><i className="flaticon-lock"></i> Mặc định</span>}</span>,
      classes: "text-center",
      editor: {
        type: "datetime-local"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },
  ];

  const defaultSorted = [{ dataField: "id", order: "asc" }];
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: commonUIHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  function beforeSaveCell(oldValue, newValue, row, column, done) {
    //console.log("Thành 23 beforeSaveCell");
    setTimeout(() => {
      if (alert("Mày đồng ý thay đổi giá trị không?")) {
        done(true);
      } else {
        done(false);
      }
    }, 0);
    return { async: true };
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <>
        <Pagination
          isLoading={listLoading}
          paginationProps={paginationProps}
        >
          <ToolkitProvider
            keyField="id"
            data={entities === null ? [] : entities}
            columns={columns}
            columnToggle
          >
            {
              toolkitProps => {

                // columnFields = toolkitProps.columnToggleProps.columns;
                // onColumnToggle = toolkitProps.columnToggleProps.onColumnToggle;
                toggles = toolkitProps.columnToggleProps.toggles;
                return (
                  <div>
                    <BootstrapTable
                      headerClasses="header-table-height"
                      filtersClasses="filter-table-height"
                      wrapperClasses="table-responsive table-scrollable card-scroll"
                      classes="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                      bootstrap4
                      bordered={true}
                      remote
                      rowEvents={
                        rowEvents({

                          ids: ids,
                          setIds: setIds && setIds,
                          setSelectedRows: setSelectedRows && setSelectedRows,
                          selectedRows: selectedRows,
                          arrayRemove: commonUIHelpers.arrayRemove,
                        })
                      }
                      rowStyle={rowStyle({

                        ids: ids,
                        currentItems: [],
                      })}
                      noDataIndication={() =>
                        <NotFoundTable
                          entities={entities}
                          isLoading={listLoading}
                          isNav={true}
                          type="emails"
                          user={user}
                        />}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        onStartEdit: (row, column, rowIndex, columnIndex) => {
                          //console.log("start to edit!!!");
                        },
                        beforeSaveCell: beforeSaveCell,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          //console.log("After Saving Cell!!");
                        },
                      })}
                      filter={filterFactory()}
                      filterPosition="top"
                      defaultSorted={defaultSorted}
                      onTableChange={getHandlerTableChange(
                        setQueryParams,
                        queryParams
                      )}
                      selectRow={getSelectRow({

                        entities,
                        ids: ids,
                        setIds: setIds && setIds,
                        // items: items,
                        // setItems: setItems,
                        selectedRows: selectedRows,
                        setSelectedRows: setSelectedRows && setSelectedRows,
                      })}
                      {...paginationTableProps}
                      {...toolkitProps.baseProps}
                    >
                    </BootstrapTable>
                  </div>
                )
              }
            }
          </ToolkitProvider>
          <PleaseWaitMessage entities={entities} />
          <NoRecordsFoundMessage entities={entities} isLoading={listLoading} />
        </Pagination>
      </>
    );
  };
  return (
    <>
      {/* <div className="mb-2">
        <Button
          isLoading={actionsLoading}
          // handleClick={() => {
          //   handleSubmit();
          // }}
          disabled={actionsLoading}
          color="danger"
          message="COMMONS.UI.Delete"
          icon="flaticon-delete icon-ms"
          margin="mr-0"
        />
      </div> */}

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {contentTable}
      </PaginationProvider>
    </>
  )
}

export const EmailTable = injectIntl(EmailTableIntl);
