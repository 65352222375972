import { createSlice } from "@reduxjs/toolkit";
import { CUSTOMER_TYPES_OBJ, ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCustomersState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  customerForEdit: null,
  customerForShow: null,
  currentCustomer: null,
  currentCustomers: [],
  notification: null,
  excludedEntities: [],
  excludedTotalCount: 0,
  error: null,
  isError: null,
  errorType: null,
  customerIndividualImport: null,
  customerOrganizationImport: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const customersSlice = createSlice({
  name: "customers",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.customerForShow ={};
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCustomer = null;
      state.currentCustomers = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.customerForEdit = null;
    },

    resetCurrentCustomers: (state, action) => {
      state.currentCustomer = null;
      state.currentCustomers = [];
    },

    // getCustomerById
    customerFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;
      state.error = null;

    },
    //show
    customerShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerForShow = action.payload.customerForShow;
      state.error = null;

    },
    // findAllCustomers
    customersFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findCustomers
    customersFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },

    customersFetchedExclude: (state, action) => {
      //console.log("Slice")
      const { excludedTotalCount, excludedEntities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.excludedEntities = excludedEntities;
      state.excludedTotalCount = excludedTotalCount;
    },
    // findParentCustomers
    customersParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createCustomer
    customerCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.currentCustomer = action.payload.customer;
      state.currentCustomers.push(action.payload.customer);
      state.entities.unshift(action.payload.customer);
      state.excludedEntities.unshift(action.payload.customer);
    },
    // updateCustomer
    customerUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.customer.id) {
          return action.payload.customer;
        }
        return entity;
      });
      state.currentCustomer = action.payload.customer;
      state.currentCustomers.push(action.payload.customer);

    },
    // deleteCustomer
    customerDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);

    },
    // deleteCustomers
    customersDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
      state.currentCustomer = null;
      state.currentCustomers = [];

    },
    // customersUpdateState
    customersStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    customerImportFetched: (state, action) => {
      const { customerImport, customerType } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.customerIndividualImport = (customerType === CUSTOMER_TYPES_OBJ.INDIVIDUAL) ? customerImport : state.customerIndividualImport;
      state.customerOrganizationImport = (customerType === CUSTOMER_TYPES_OBJ.ORGANIZATION) ? customerImport : state.customerOrganizationImport;
    },

    customerImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },

    resetCustomerImport: (state, action) => {
      action.payload === CUSTOMER_TYPES_OBJ.INDIVIDUAL && (state.customerIndividualImport = null);
      action.payload === CUSTOMER_TYPES_OBJ.ORGANIZATION && (state.customerOrganizationImport = null);
    },
  }
});
