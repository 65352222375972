import { createSlice } from "@reduxjs/toolkit";
import { DEPOSIT_STATUS_OBJ } from "../../../../_helpers/EnumUIHelpers";
import {
  DepositStatusEnum,
  ERROR_TYPES_OBJ,
  INVOICE_APPROVERDED_STATUS_OBJ,
  INVOICE_APPROVER_OBJ,
  INVOICE_PAYMENT_STATUS_OBJ,
  INVOICE_TYPES_OBJ,
  UNIT_STATUS_OBJ,
} from "../../../../_helpers/_CommonUIHelpers";

const initialDeskBaseState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  entitiesInvoices: null,
  parents: [],
  deskForEdit: null,
  desksData: null,
  assets: null,
  buildingDesks: [],
  invoicesByUnits: null,
  error: null,
  isError: null,
  errorType: null,
  contractDeposit: null,
  meterLogs: [],
  utilities: [],
  unitsDesk: [],
  invoices: [],

  building: {},
  buildings: [],
  units: [],
  statusFilter: "",
  isLoadingBuilding: false,
  isLoadingSentInvoice: false,
  isLoadingBuildings: false,
  isLoadingUnit: false,
  isLoadingListInvoice: false,

  actionsLoadingUnit: false,

  isLoadingMeterLog: false,
  isLoadingInvoiceBulk: false,

  totalUnitFilter: 0,

  contract: {},
  deposit: {},
  unitInfo: {},
  refresh: 0,
  refreshInvoiceUnitDetail: 0,
  buildingsFilter: [],
  isLoadingBuildingsFilter: false,
  isLoadingUnitsFilter: false,
  unitsFilter: [],
  actionName: "DEFAULT_ACTION",
  statusBulkInvoice: ''
};

export const callTypes = {
  // BUILDINGS
  listBuilding: "listBuilding",
  buildingsFilter: "buildingsFilter",
  // UNITS
  listUnit: "listUnit",
  unitsFilter: "unitsFilter",

  // INVOICES
  listInvoice: "listInvoice",
  sentInvoice: "sentInvoice",
  bulkInvoices: "bulkInvoices",

  // UNITS
  createBulkUnit: "createBulkUnit",
  detailUnit: "detailUnit",

  listMeterLog: "listMeterLog",
};

export const deskBaseSlice = createSlice({
  name: "deskBase",
  initialState: initialDeskBaseState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };

      if (action.payload.callType === callTypes.listBuilding) {
        state.isLoadingBuildings = false;
      } else if (action.payload.callType === callTypes.buildingsFilter) {
        state.isLoadingBuildingsFilter = false;
      } else if (action.payload.callType === callTypes.listUnit) {
        state.isLoadingBuilding = false;
        state.statusFilter = "error";
        state.totalUnitFilter = 0;
      } else if (action.payload.callType === callTypes.unitsFilter) {
        state.isLoadingUnitsFilter = false;
      } else if (action.payload.callType === callTypes.detailUnit) {
        state.isLoadingUnit = false;
      } else if (action.payload.callType === callTypes.listMeterLog) {
        state.isLoadingMeterLog = false;
      } else if (action.payload.callType === callTypes.sentInvoice) {
        state.isLoadingSentInvoice = false;
      } else if (action.payload.callType === callTypes.createBulkUnit) {
        state.actionsLoadingUnit = false;
      } else if (action.payload.callType === callTypes.listInvoice) {
        state.isLoadingListInvoice = false;
      } else if (action.payload.callType === callTypes.bulkInvoices) {
        state.isLoadingInvoiceBulk = false;
        state.statusBulkInvoice = 'error'
      }
    },

    startCall: (state, action) => {
      if (action.payload.callType === callTypes.listBuilding) {
        state.isLoadingBuildings = true;
      } else if (action.payload.callType === callTypes.buildingsFilter) {
        state.isLoadingBuildingsFilter = true;
        state.buildingsFilter = [];
      } else if (action.payload.callType === callTypes.listUnit) {
        state.isLoadingBuilding = true;
        state.statusFilter = "";
        state.totalUnitFilter = 0;
      } else if (action.payload.callType === callTypes.unitsFilter) {
        state.isLoadingUnitsFilter = true;
      } else if (action.payload.callType === callTypes.createBulkUnit) {
        state.actionsLoadingUnit = true;
      } else if (action.payload.callType === callTypes.detailUnit) {
        state.isLoadingUnit = true;
      } else if (action.payload.callType === callTypes.listMeterLog) {
        state.isLoadingMeterLog = true;
      } else if (action.payload.callType === callTypes.sentInvoice) {
        state.isLoadingSentInvoice = true;
      } else if (action.payload.callType === callTypes.listInvoice) {
        state.isLoadingListInvoice = true;
        state.invoices = [];
      } else if (action.payload.callType === callTypes.bulkInvoices) {
        state.isLoadingInvoiceBulk = true;
        state.statusBulkInvoice = ''
      }
    },

    refresh: (state, action) => {
      state.refresh += 1;
    },

    refreshInvoiceUnitDetail: (state, action) => {
      state.refreshInvoiceUnitDetail += 1;
    },

    // BUILDINGS
    allBuildingGetted: (state, action) => {
      state.isLoadingBuildings = false;
      state.buildings = action.payload.buildings;
    },

    buildingFetched: (state, action) => {
      state.isLoadingBuilding = false;
      state.building = action.payload.building;
    },

    buildingsByOrganizationIdGetted: (state, action) => {
      state.isLoadingBuildingsFilter = false;
      state.buildingsFilter = action.payload.buildings;
    },

    unitsInBuildingFetched: (state, action) => {
      state.isLoadingBuilding = false;
      state.statusFilter = "success";
      if (state.building) {
        state.building.floors = action.payload.building?.floors;
        state.totalUnitFilter = action.payload.building?.totalUnits;
      }
    },

    unitbulkCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoadingUnit = false;
    },

    // UNITS
    detailUnitByIdGetted: (state, action) => {
      state.isLoadingUnit = false;
      state.contract = action.payload.contract;
      state.deposit = action.payload.deposit;
      state.unitInfo = action.payload.unitInfo;
    },

    unitsByBuildingIdGetted: (state, action) => {
      state.isLoadingUnitsFilter = false;
      state.unitsFilter = action.payload.units;
    },

    // INVOICES
    listInvoiceUnitDetailFetched: (state, action) => {
      state.isLoadingListInvoice = false;
      state.invoices = action.payload.entities;
    },

    invoiceIsSented: (state, action) => {
      state.isLoadingSentInvoice = false;
      state.invoices = state.invoices.map((ele) => {
        if (ele.id === action.payload.id) {
          ele.isSent = action.payload.isSentStatus;
        }
        return ele;
      });
    },

    statusInvoicesUpdated: (state, action) => {
      const { ids, status, actionName = "DEFAULT_ACTION" } = action.payload;

      state.invoices = state.invoices.map((invoice) => {
        if (ids.findIndex((id) => id === invoice.id) > -1) {
          invoice.status = status;
          if (invoice.totalReceivableMoney === 0) {
            if (status === INVOICE_APPROVER_OBJ.APPROVAL) {
              invoice.paymentStatus = INVOICE_PAYMENT_STATUS_OBJ.PAID;
            } else {
              invoice.paymentStatus = INVOICE_PAYMENT_STATUS_OBJ.UNPAID;
            }
          }

          if (
            invoice.invoiceType === INVOICE_TYPES_OBJ.PRE_DEPOSITS &&
            invoice.depositId === state.deposit.id &&
            state.unitInfo === UNIT_STATUS_OBJ.EMPTY
          ) {
            let _deposit = state.deposit;
            if (invoice.status === INVOICE_APPROVERDED_STATUS_OBJ.APPROVED) {
              _deposit.status = DepositStatusEnum.DEPOSITED;
            } else if (
              invoice.status === INVOICE_APPROVERDED_STATUS_OBJ.PENDING
            ) {
              _deposit.status = DepositStatusEnum.NEW;
            }
            state.deposit = _deposit;
          }
        }
        return invoice;
      });

      state.actionName = actionName;
    },

    isClosingDebtsInvoicesUpdated: (state, action) => {
      const { ids, isClosingDebts } = action.payload;

      state.invoices = state.invoices.map((invoice) => {
        if (ids.findIndex((id) => id === invoice.id) > -1) {
          invoice.isClosingDebts = isClosingDebts;
        }
        return invoice;
      });
    },

    // deleteInvoice
    invoiceDeleted: (state, action) => {
      state.invoices = state.invoices.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // METER_LOGS
    listMeterLogUnitDetailFetched: (state, action) => {
      state.isLoadingMeterLog = false;
      state.meterLogs = action.payload.meterLogs;
    },

    bulkInvoiceCreated: (state, action) => {
      state.statusBulkInvoice = 'success';
      state.isLoadingInvoiceBulk = false;
      state.invoices = action.payload.invoices;
    },

    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.isError = null;
      state.deskForEdit = null;
    },
    // getDeskById
    deskFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.deskForEdit = action.payload.deskForEdit;
      state.error = null;
    },
    // findAllDesks
    desksFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findDesks
    desksFetched: (state, action) => {
      const { totalCount, entities, desksData } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.desksData = desksData;
    },

    desksInvoiceFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities, desksData } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesInvoices = entities;
      state.totalCount = totalCount;
      state.desksData = desksData;
    },
    desksFetchedAllUnit: (state, action) => {
      state.listLoading = false;
      state.buildingDesks = action.payload;
      let units = [];
      action.payload.floors.map((floor, index) => {
        units = units.concat(floor.units);
        return "";
      });
      state.unitsDesk = units;
    },
    // findParentDesks
    desksParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createDesk
    deskCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.entities.unshift(action.payload.desk);
    },
    // updateDesk
    deskUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.desk.id) {
          return action.payload.desk;
        }
        return entity;
      });
    },
    // deleteDesk
    deskDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteDesks
    desksDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // desksUpdateState
    desksStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //get unit

    unitCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
    },
    //fetch assets by unitId
    assetsFetchedByUnitId: (state, action) => {
      state.listLoading = false;
      state.error = null;

      const { assets } = action.payload;
      state.assets = assets;
    },

    invoicesFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { invoices } = action.payload;
      state.invoicesByUnits = invoices;
    },
  },
});
