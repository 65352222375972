import axios from "axios";

export const CURRENCIES_URL = "/currencies";

// CREATE =>  POST: add a new currency to the server
export function createCurrency(currency) {
  return axios.post(CURRENCIES_URL, currency);
}

// READ
export function getAllCurrencies() {
  return axios.get(CURRENCIES_URL);
}

export function getInitCurrency() {
  return axios.get(`${CURRENCIES_URL}/create`);
}

export function getCurrencyCloneById(currencyId) {
  return axios.get(`${CURRENCIES_URL}/${currencyId}/clone`);
}
export function getCurrencyById(currencyId) {
  return axios.get(`${CURRENCIES_URL}/${currencyId}`);
}

export function findCurrenciesExcluded(queryParams, ids) {
  return axios.post(`${CURRENCIES_URL}/excluded`, { queryParams, ids });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCurrencies(queryParams) {
  return axios.post(`${CURRENCIES_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateCurrency(currency) {
  return axios.put(`${CURRENCIES_URL}/${currency.id}`, currency);
}

// UPDATE Status
export function updateStatusForCurrencies(ids, status) {
  return axios.post(`${CURRENCIES_URL}/updateStatusForCurrencies`, {
    ids,
    status,
  });
}

// DELETE => delete the currency from the server
export function deleteCurrency(currencyId) {
  return axios.delete(`${CURRENCIES_URL}/${currencyId}`);
}

// DELETE Currencies by ids
export function deleteCurrencies(ids) {
  return axios.delete(`${CURRENCIES_URL}/bulk`, { data: { ids } });

}
