import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./organizationCrud";
import { organizationSlice, callTypes } from "./organizationSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = organizationSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

// Area actions fetch list
// Area Filter list

// Area actions get item

export const fetchOrganization = (id) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getOrganizationShowById(id)
    .then((response) => {
      const organization = response.data;
      dispatch(actions.organizationFetched({ organizationForEdit: organization }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const getAPIKey = (id) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAPIKey(id)
    .then((response) => {
      const apiKey = response.data;
      dispatch(actions.apiKeyGeted({ apiKey: apiKey }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const refreshAPIKey = (id) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .refreshAPIKey(id)
    .then((response) => {
      const apiKey = response.data;
      dispatch(actions.apiKeyGeted({ apiKey: apiKey }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area actions create, update item

export const updateOrganization = (organization, organizationId) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateOrganization(organization, organizationId)
    .then((response) => {
      const organization = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.edit" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationShowFetched({ organization }))
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const getOrganization = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getOrganizationEditById(id)
    .then((response) => {
      const organization = response.data;
      dispatch(
        actions.getOrganization({ organizationForShow: organization })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


export const fetchOrganizationShow = (id) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getOrganizationShowById(id)
    .then((response) => {
      const organization = response.data;
      dispatch(actions.organizationShowFetched({ organizationForShow: organization }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

  // Area actions delete item
  // Area actions  update status item
