import axios from "axios";

export const ORGANIZATIONS_URL = "/organizations";


export function getOrganizationEditById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}/edit`);
};

export function updateOrganization(organization, organizationId) {
  return axios.put(`${ORGANIZATIONS_URL}/${organizationId}`, organization);
};

export function getOrganizationShowById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}`);
};

export function getAPIKey(id) {
  return axios.get(`${ORGANIZATIONS_URL}/${id}/apiKey`);
};

export function refreshAPIKey(id) {
  return axios.put(`${ORGANIZATIONS_URL}/${id}/apiKey`);
};