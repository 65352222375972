import Axios from "axios";
import React from "react";

const formatFile = ['xlsx', 'xls', 'zip', 'txt', 'rar', 'png', 'pdf', 'mpg', 'mp4', 'mov', 'jpg', 'exe', 'dwg', 'docx', 'doc']

export const FormatFileColumnFormatter = (cellContent, row) => {
  let url;
  if (row.documentFormat.includes('image'))
    url = `${Axios.defaults.cdnURL}/${row.documentPath}`
  else {
    formatFile.map((item) => {
      if (row.documentExtension === item) {
        url = `/media/users/upfile_${item}.png`;
      }
    })
  };

  return <span><img alt="" src={url} width="68" height="68" /></span>;
};
