import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useCurrenciesUIContext } from "./CurrenciesUIContext";

const selectedCurrencies = (entities, ids) => {
  const _currencies = [];
  ids.forEach((id) => {
    const currentcy = entities.find((el) => el.id === id);
    if (currentcy) {
      _currencies.push(currentcy);
    }
  });
  return _currencies;
};

export function CurrenciesFetchDialog() {
  // Specs UI Context
  const currenciesUIContext = useCurrenciesUIContext();
  const currenciesUIProps = useMemo(() => {
    return {
      ids: currenciesUIContext.ids,
      show: currenciesUIContext.showFetchCurrenciesDialog,
      onHide: currenciesUIContext.closeFetchCurrenciesDialog,
      queryParams: currenciesUIContext.queryParams,
    };
  }, [currenciesUIContext]);

  // Specs Redux state

  const { currencies } = useSelector(
    (state) => ({
      currencies: selectedCurrencies(
        state.currencies.entities,
        currenciesUIProps.ids
      ),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!currenciesUIProps.ids || currenciesUIProps.ids.length === 0) {
      currenciesUIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenciesUIProps.ids]);

  return (
    <Modal
      show={currenciesUIProps.show}
      onHide={currenciesUIProps.onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {currencies.map((currentcy) => (
              <div className="list-timeline-item mb-3" key={currentcy.id}>
                <span className="list-timeline-text">
                  <span
                    className="label label-lg label-light-success label-inline"
                    style={{ width: "60px" }}
                  >
                    ID: {currentcy.id}
                  </span>{" "}
                  <span className="ml-5">
                    {currentcy.name}: {currentcy.value}{" "}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={currenciesUIProps.onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={currenciesUIProps.onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
