import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialSettingEmailsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  settingEmailForEdit: null,
  notification: null,
  error: null,
  errorType: null,
  settingEmailCurrenciesExcluded: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState: initialSettingEmailsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },


    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.settingEmailForEditBuilding = null;
    },


    // SHOW
    emailTemplateShow: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.emailTemplateShow = action.payload.emailTemplateShow;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // UPDATE
    emailTemplateUpdate: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.emailTemplateUpdate.id) {
          return action.payload.emailTemplateUpdate;
        }
        return entity;
      });
      state.currentEmailTemplates.push(action.payload.emailTemplateUpdate);
    },

    // DELETE
    emailTemplateDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentEmailTemplates = [];
    },

    // FETCHALL
    emailTemplateFetchIndex: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // CREATE
    emailTemplateCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities.unshift(action.payload.emailTemplate);
    },



    // FETCH buildingId
    emailTemplatesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount
    },

    // EDIT
    emailTemplateEdit: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.emailTemplateEdit = action.payload.emailTemplateEdit;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // CLONE
    emailTemplateClone: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.emailTemplateEdit = action.payload.emailTemplateClone;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },



    // FETCH all
    emailTemplatesFetchedAll: (state, action) => {
      const { emailTemplates } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = emailTemplates;
    },

    // NEW
    emailTemplateNew: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.emailTemplateEdit = action.payload.emailTemplateNew;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // DELETE bulk
    emailTemplateDeletedBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentEmailTemplates = [];
      state.errorType = ERROR_TYPES_OBJ.END;

    },
  }
});
