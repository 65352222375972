/* eslint-disable react-hooks/exhaustive-deps */
import { isEqual, isFunction } from "lodash";
import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_bloomgoo/_partials/controls";
import { getHeaderSettings } from "../SettingsUIHelpers";
import APIKeyFieldForm from "./APIKeyFieldForm";
import { APIKeyTable } from "./APIKeyTable";
import * as actions from "../_redux/api-keys/apiKeysActions"
import { FormattedMessage } from "react-intl";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";

export default function SettingsCardAPIKeys() {
  const { actionsLoading, apiKeyEdit, user } = useSelector(
    (state) => ({
      actionsLoading: state.apiKeys.actionsLoading,
      apiKeyEdit: state.apiKeys.apiKeyEdit,
      user: state.auth.user
    }),
    shallowEqual
  );

  const history = useHistory();
  const location = useLocation();

  const initialFilter = {
    filter: {
      searchCriteria: [

      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "displayOrder",
    pageNumber: 1,
    pageSize: 35,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState({
    intro: true,
    owner: true,
    extend: true,
    extendIntro: true,
    extendDetail: true,
    extendPolicy: true,
    extendLatitude: true,
    decentralization: true,
    attachment: true,
  });

  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isShowReset, setIsShowReset] = useState(false);
  const [isShowActive, setIsShowActive] = useState(false);

  const handleCollapse = (name) => {
    const _collapse = { ...collapse };
    _collapse[name] = !_collapse[name];
    setCollapse(_collapse);
  };

  const getIconCollapse = (name) => {
    if (collapse[name])
      return (
        <i className="fas fa-caret-square-right mr-2 text-hover-primary" />
      );
    else return <i className="fas fa-caret-square-down mr-2 text-primary" />;
  };

  const apiKeyEvents = (mode, id) => {
    if (mode === "edit") {
      dispatch(actions.getEditAPIKeyById(id));
    } else if (mode === "reset") {
      setIsShowReset(true);
    } else if (mode === "delete") {
      setIsShowDelete(true);
    } else if (mode === "active") {
      setIsShowActive(true);
    }
  };

  const getHeaderCollapse = (data) => {
    let _title = ""
    if (!data?.id) {
      if (data?.name) {
        _title = (
          <FormattedMessage
            id="API_KEYS.UI.Title.Clone"
            values={{
              params: `'${data?.name}'`,
            }}
          />
        )
      } else {
        _title = (
          <FormattedMessage
            id="API_KEYS.UI.Title.New"
          />
        )
      }

    } else {
      _title = (
        <FormattedMessage
          id="API_KEYS.UI.Title.Edit"
          values={{
            params: `'${data?.name}'`,
          }}
        />
      )
    }
    return _title;
  };

  return (
    <>
      <Card>
        <CardHeader

          title={
            <div className="btn-group" role="group" aria-label="...">
              {getHeaderSettings({ user, history, location })}
            </div>
          } />
        <CardBody className="pt-0 card-scroll">
          <div className="row d-flex mt-5">
            {isPrivilegeUserAction(user, "SETTINGS_API_KEYS_CREATE_WRITE") || isPrivilegeUserAction(user, "SETTINGS_API_KEYS_CREATE_WRITE") ?
              <>
                <div className="col-lg-8">
                  <div
                    data-toggle="collapse"
                    href="#collapseForm"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseForm"
                    onClick={() => handleCollapse("form")}
                  >
                    {getIconCollapse("form")}
                    <b>Danh sách API Key</b>
                  </div>
                  <div className="collapse show mt-3" id="collapseForm">
                    <APIKeyTable
                      queryParams={queryParams}
                      ids={ids}
                      setIds={setIds}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      setQueryParams={setQueryParams}
                      apiKeyEvents={apiKeyEvents}
                      isShowDelete={isShowDelete}
                      setIsShowDelete={setIsShowDelete}
                      isShowReset={isShowReset}
                      setIsShowReset={setIsShowReset}
                      isShowActive={isShowActive}
                      setIsShowActive={setIsShowActive}

                    />
                    <div className="gutter-b"></div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    data-toggle="collapse"
                    href="#collapseServer"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseServer"
                    onClick={() => handleCollapse("server")}
                  >
                    {getIconCollapse("server")}
                    <b>{getHeaderCollapse(apiKeyEdit)}</b>
                  </div>
                  <div className="collapse show" id="collapseServer">
                    <APIKeyFieldForm
                      setIds={setIds}
                      setSelectedRows={setSelectedRows}
                      queryParams={queryParams}
                    />
                  </div>
                  <div className="gutter-b"></div>
                </div>
              </>
              :
              <div className="col-lg-12">
                <div
                  data-toggle="collapse"
                  href="#collapseForm"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseForm"
                  onClick={() => handleCollapse("form")}
                >
                  {getIconCollapse("form")}
                  <b>Danh sách API Key</b>
                </div>
                <div className="collapse show mt-3" id="collapseForm">
                  <APIKeyTable
                    queryParams={queryParams}
                    ids={ids}
                    setIds={setIds}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setQueryParams={setQueryParams}
                    apiKeyEvents={apiKeyEvents}
                    isShowDelete={isShowDelete}
                    setIsShowDelete={setIsShowDelete}
                    isShowReset={isShowReset}
                    setIsShowReset={setIsShowReset}
                  />
                  <div className="gutter-b"></div>
                </div>
              </div>
            }

          </div>
        </CardBody>
      </Card>
    </>
  );
}
