import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialReportOverviewState = {
  listLoading: null,
  actionsLoading: null,
  reportType: 1,
  reportVoucherCategoryRevenueAndCostDtos: [],
  reportBankAccountRevenueAndCostDtos: [],
  reportServiceRevenueDtos: [],
  reportServiceRevenueOrganizationDtos: [],
  error: null,
  filterCount: null,
  errorType: null
};



export const callTypes = {
  serviceRevenueDtos: "serviceRevenueDtos",
  serviceRevenueOrganizationDtos: "serviceRevenueOrganizationDtos",
  bankAccountRevenueAndCostDtos: "bankAccountRevenueAndCostDtos",
  voucherCategoryRevenueAndCostDtos: "voucherCategoryRevenueAndCostDtos"
};

export const reportOverviewSlice = createSlice({
  name: "reportOverview",
  initialState: initialReportOverviewState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.serviceRevenueDtos) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.bankAccountRevenueAndCostDtos) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.voucherCategoryRevenueAndCostDtos) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.serviceRevenueOrganizationDtos) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.voucherCategoryRevenueAndCostDtos) {
        state.reportVoucherCategoryRevenueAndCostDtos = []
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.bankAccountRevenueAndCostDtos) {
        state.reportBankAccountRevenueAndCostDtos = []
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.serviceRevenueDtos) {
        state.reportServiceRevenueDtos = []
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.serviceRevenueOrganizationDtos) {
        state.serviceRevenueOrganizationDtos = []
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.exportExcel) {
        state.actionsLoading = true;
      }
    },
    reportVoucherCategoryRevenueAndCostGeted: (state, action) => {
      const { reportType, reportVoucherCategoryRevenueAndCostDtos } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reportType = reportType;
      state.reportVoucherCategoryRevenueAndCostDtos = reportVoucherCategoryRevenueAndCostDtos;
    },

    reportBankAccountRevenueAndCostGeted: (state, action) => {
      const { reportBankAccountRevenueAndCostDtos } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reportBankAccountRevenueAndCostDtos = reportBankAccountRevenueAndCostDtos;
    },

    reportBankAccountRevenueAndCostExport: (state, action) => {
      state.errorType = null;
      state.error = null;
      state.actionsLoading = false;
    },

    // report service revenue
    reportServiceRevenueGeted: (state, action) => {
      const { reportServiceRevenueDtos, reportServiceRevenueOrganizationDtos, reportType } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (reportType === 1) {
        state.reportServiceRevenueOrganizationDtos = reportServiceRevenueOrganizationDtos;
      } else {
        state.reportServiceRevenueDtos = reportServiceRevenueDtos;
      }
      state.reportType = reportType;
    },
    // export report service revenue
    reportServiceRevenueExport: (state, action) => {
      state.errorType = null;
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
