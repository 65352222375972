import axios from "axios";

export const LOGIN_URL = "/auth/signin";
export const REGISTER_URL = "/auth/signup";
export const REQUEST_PASSWORD_URL = "/auth/forgotPassword";
export const RESET_PASSWORD_URL = "/auth/resetPassword";
export const USERS = "/users"
export const ME_URL = "/profile";

export function login(organizationCode, email, password) {
  var username = email;
  return axios.post(LOGIN_URL, { organizationCode, username, password });
}

export function register(organization, user) {

  return axios.post(REGISTER_URL, { organization, user });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, email);
}

export function initResetPassword(token) {
  return axios.get(RESET_PASSWORD_URL, { params: { token: token } });
}

export function resetPassword(newPassword) {
  return axios.post(RESET_PASSWORD_URL, newPassword);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
