import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (size, touched, errors) => {
  const classes = ["form-control", `form-control-${size}`];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function SwitchField({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required = false,
  withFeedbackLabel = true,
  customFeedbackLabel,
  className,
  size = "md",
  type,
  ...props
}) {
  return (
    <>
      {label && <label className={`${className}`}>{label} {required && <span className="required">*</span>}</label>}
      {type === 'checkbox' && (
        <span className={`switch switch-${size} ${className}`}>
          <label>
            <input
              checked={field.value}
              type={type}
              className={getFieldCSSClasses(size, touched[field.name], errors[field.name])}
              {...field}
              {...props}
              value={field.value ? true : false}
            />
            <span></span>
          </label>
        </span>
      )}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
