/* eslint-disable array-callback-return */
import { SUBSCRIBER_TYPE, USER_TYPE } from "../_CommonUIHelpers";

export const PARTNER_PRIVILEGES_PACKET = [
    "PARTNER_DASHBOARD",                                     // Bảng quản trị
    "PARTNER_DASHBOARD_BUILDING",                            // Quản trị cơ sở
    "SETTINGS_PROFILE",                                      // Hồ sơ cá nhân
    "PARTNER_CUSTOMERS",                                     // Khách hàng
    "PARTNER_CUSTOMERS_CREATE_WRITE",                        // Thêm mới
    "PARTNER_CUSTOMERS_CLONE_WRITE",                         // Sao chép
    "PARTNER_CUSTOMERS_UPDATE_WRITE",                        // Chỉnh sửa
    "PARTNER_CUSTOMERS_IMPORT_WRITE",                        // Nhập excel
    "PARTNER_CUSTOMERS_EXPORT_WRITE",                        // Xuất excel
    "PARTNER_CUSTOMERS_DELETE_WRITE",                        //Xóa
    "PARTNER_CONTRACTS",                                     // Hợp đồng
    "PARTNER_CONTRACTS_CREATE",	                             // Thêm mới
    "PARTNER_CONTRACTS_CLONE",	                             // Sao chép
    "PARTNER_CONTRACTS_UPDATE",	                             // Chỉnh sửa
    "PARTNER_CONTRACTS_EXTENSION",	                         // Gia hạn
    "PARTNER_CONTRACTS_EMAIL",	                             // Danh sách email
    "PARTNER_CONTRACTS_EXPORT",	                             // Xuất excel
    "PARTNER_CONTRACTS_DOWNLOAD",	                         // Tải hợp đồng
    "PARTNER_CONTRACTS_DELETE",	                             // Xóa
    "PARTNER_METER_LOGS",                                       // Chỉ số công tơ
    "PARTNER_METER_LOGS_CREATE",                             // Ghi số
    "PARTNER_METER_LOGS_CREATE_BULK",                        // Ghi nhiều
    "PARTNER_METER_LOGS_UPDATE_STATUS",                      // Trạng thái
    "PARTNER_METER_LOGS_IMPORT",                             // Nhập excel
    "PARTNER_METER_LOGS_EXPORT",                             // Xuất excel
    "PARTNER_METER_LOGS_DELETE",                             // Xóa
    "PARTNER_INVOICES", //Hóa đơn
    "PARTNER_INVOICES_CREATE", //Tạo hóa đơn
    "PARTNER_INVOICES_CREATE_BULK", //Tạo nhiều
    "PARTNER_INVOICES_UPDATE", //Chỉnh sửa
    "PARTNER_INVOICES_UPDATE_STATUS", //Phê duyệt
    "PARTNER_INVOICES_EMAIL", //Gửi email
    "PARTNER_INVOICES_PREVIEW", //Giấy báo
    "PARTNER_INVOICES_UPDATE_CLOSING", //Chốt công nợ
    "PARTNER_INVOICES_CHECKOUT", //Trả phòng
    "PARTNER_INVOICES_DOWNLOAD", //Tải hóa đơn
    "PARTNER_INVOICES_DELETE", //Xóa
    "PARTNER_INVOICES_HISTORY", //Tất cả

    "PARTNER_REPORTS_FINANCIALS",                            // Báo cáo tài chính          // TODO: Lê Huy Hoàng
    "PARTNER_REPORTS_OVERVIEW",                              // Báo cáo tài chính
    "PARTNER_REPORTS_OVERVIEW_PROFIT",                       // Lợi nhuận
    "PARTNER_REPORTS_OVERVIEW_REVENUE_EXPENSES",             // Doanh thu-chi phí
    "PARTNER_REPORTS_FINANCIALS_VOUCHERCATEGORIES",          // Theo hạng mục
    "PARTNER_REPORTS_FINANCIALS_VOUCHERCATEGORY_BUILDINGS",  //	Theo hạng mục - Tòa nhà
    "PARTNER_REPORTS_FINANCIALS_SERVICES",                   //	Theo dịch vụ
    "PARTNER_REPORTS_FINANCIALS_SERVICES_BUILDINGS",         //	Theo dịch vụ - Tòa nhà
    "PARTNER_REPORTS_ASSETS",                                //	Báo cáo tài sản
    "PARTNER_REPORTS_ASSETS_BUILDINGS",                      //	Phân bổ tài sản
];

export const isPartner = (user) => {
    if (user !== null && user.organization !== null) {
        if (user.userType === USER_TYPE.PARTNER) {
            return true;
        };
        return false;
    };
    return false;
};

export const isPrivilegePartnerPacket = (props) => {
    const { namePrivilege, type } = props

    let isPrivilege = false
    if (type === SUBSCRIBER_TYPE.PREMIUM) {
        PARTNER_PRIVILEGES_PACKET.some(el => el === namePrivilege) ?
            isPrivilege = true : isPrivilege = false
    };

    return isPrivilege
};


export const isPrivilegePartnerGroup = (user, privilegeName) => {
    let isPrivilege = false;
    user && user.authorities && user.authorities.map((item) => {
        if (item.includes(privilegeName) && !item.indexOf(privilegeName)) {
            isPrivilege = true;
        };
    });

    if (user.isAdministrator === true && PARTNER_PRIVILEGES_PACKET.includes(privilegeName)) {
        isPrivilege = true;
    };

    return isPrivilege;
};

export const isPrivilegePartnerAction = (user, privilegeName) => {
    let isPrivilege = false;
    if (isPartner(user)) {
        user && user.authorities && user.authorities.map((item) => {
            if (item.includes(privilegeName) && !item.indexOf(privilegeName)) {
                isPrivilege = true;
            };
        });

        if (user.isAdministrator === true) {
            isPrivilege = true;
        };
    }

    return isPrivilege;
};
