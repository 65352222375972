import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialPaymentVouchersState = {
  listLoading: null,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  parents: [],
  paymentVoucherForEdit: null,

  notification: null,
  error: null,

  isError: null,
  filterCount: null,
  paymentVoucherShow: null,
  currentPaymentVouchers: [],
  errorType: null,
  refresh: 0,
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
  export: "export",
};

export const paymentVouchersSlice = createSlice({
  name: "paymentVouchers",
  initialState: initialPaymentVouchersState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.export) {
        state.actionsLoading = null;
      } else {
        state.actionsLoading = false;
      }
    },

    refresh: (state, action) => {
      state.refresh += 1;
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else if (action.payload.callType === callTypes.export) {
        state.actionsLoading = true;
      } else {
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentPaymentVoucher = null;
      state.currentPaymentVouchers = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.isError = null;
      state.paymentVoucherForEdit = null;
    },

    resetCurrentPaymentVouchers: (state, action) => {
      state.currentPaymentVoucher = null;
      state.currentPaymentVouchers = [];
    },

    // getPaymentVoucherById
    paymentVoucherFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.paymentVoucherForEdit = action.payload.paymentVoucherForEdit;
      state.error = null;
    },

    paymentVoucherPreview: (state, action) => {
      state.actionsLoading = false;
      state.paymentVoucherPreview = action.payload.paymentVoucherPreview;
      state.error = null;
    },

    getPaymentVoucher: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.paymentVoucherShow = action.payload.paymentVoucherShow;
      state.actionsLoading = false;
      state.error = null;
    },

    // findAllPaymentVouchers
    paymentVouchersFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findPaymentVouchers
    paymentVouchersFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentPaymentVouchers
    paymentVouchersPareParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createPaymentVoucher
    paymentVoucherCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.paymentVoucher);
      state.currentPaymentVouchers.push(action.payload.paymentVoucher);
    },
    // updatePaymentVoucher
    paymentVoucherUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.paymentVoucher.id) {
          return action.payload.paymentVoucher;
        }
        return entity;
      });
      state.currentPaymentVouchers.push(action.payload.paymentVoucher);
    },
    // deletePaymentVoucher
    paymentVoucherDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentPaymentVouchers = [];
    },
    // deletePaymentVouchers
    paymentVouchersDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentPaymentVouchers = [];
    },
    // paymentVouchersUpdateState
    paymentVouchersStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      // state.currentPaymentVouchers = action.payload.paymentVoucher;
    },

    paymentsExport: (state, action) => {
      state.actionsLoading = false;
    },
  },
});
