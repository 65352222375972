/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_bloomgoo/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_bloomgoo/_assets/sass/pages/login/classic/login-1.scss";
import Preview from "./Preview";
import RegisterPassword from "./RegisterPassword";
import SearchInvoices from "./SearchInvoices";

export function AuthPage() {

  return (
    <>
      <div className={`d-flex flex-column flex-root`}>
        <Switch>
          <ContentRoute path="/dang-nhap" component={Login} />
          <ContentRoute path="/dang-ky" component={Registration} />
          <ContentRoute path="/quen-mat-khau" component={ForgotPassword} />
          <ContentRoute path="/reset_password" component={RegisterPassword} />
          <ContentRoute path="/hoadon/:id" component={Preview} />
          <ContentRoute path="/tim-kiem" component={SearchInvoices} />
          <Redirect to="/dang-nhap" />
        </Switch>
      </div>
    </>
  );
}
