import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const DocumentsUIContext = createContext();

export function useDocumentsUIContext() {
  return useContext(DocumentsUIContext);
}

export const DocumentsUIConsumer = DocumentsUIContext.Consumer;

export function DocumentsUIProvider({ documentsUIEvents, children }) {
  const initialFilter = {
    filter: {
      searchCriteria: [

      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "createdAt",
    pageNumber: 1,
    pageSize: 35,
  };
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [tabActive, setTabActive] = useState('1');
  const [fileSelected, setFileSelected] = useState({ selectedFile: null })
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    selectedRows,
    setSelectedRows,
    fileSelected,
    setFileSelected,
    setQueryParams,
    tabActive,
    setTabActive,
    newDocumentButtonClick:
      documentsUIEvents.newDocumentButtonClick,
    openEditDocumentPage: documentsUIEvents.openEditDocumentPage,
    openCloneDocumentPage: documentsUIEvents.openCloneDocumentPage,
    openDeleteDocumentDialog:
      documentsUIEvents.openDeleteDocumentDialog,
    openDeleteDocumentsDialog:
      documentsUIEvents.openDeleteDocumentsDialog,
    openShowDocumentPage: documentsUIEvents.openShowDocumentPage,
    openEditDocumentModal: documentsUIEvents.openEditDocumentModal,
  };

  return (
    <DocumentsUIContext.Provider value={value}>
      {children}
    </DocumentsUIContext.Provider>
  );
}
