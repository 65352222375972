
import React from "react";
import { FormattedMessage } from "react-intl";

export function DropdownMenu(props) {
    const { actions } = props;
    let itemActive = actions.filter(ele => ele.isActive)[0];

    return (
        actions.filter(ele => ele.isPrivilege).length &&
        <div className="dropdown dropdown-inline">
            <button
                type="button"
                className={`btn btn-hover-secondary btn-text-dark btn-hover-text-primary border-0 font-weight-bold ${actions.filter(ele => ele.isActive).length && "bg-secondary text-primary"}`}
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >
                {itemActive ?
                    <span>
                        <i className={`${itemActive.icon} icon-ms text-primary`} />
                        <span className="text-primary"><FormattedMessage id={itemActive.title} /></span>
                        <i className="fas fa-chevron-down icon-ms text-primary ml-2"></i>
                    </span>
                    :
                    <i className="ki ki-bold-more-hor icon-ms" />
                }
            </button>
            <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                <ul className="navi navi-hover py-5">
                    {actions && actions.map((item, index) =>
                        <li
                            className={`navi-item ${item.isActive && "bg-secondary text-primary"}`}
                            key={index}
                            onClick={item.action}
                        >
                            <span
                                type="button" className="navi-link">
                                <span className="navi-icon">
                                    <i className={item.icon}></i>
                                </span>
                                <span className="navi-text"><FormattedMessage id={item.title}></FormattedMessage></span>
                            </span>
                        </li>
                    )}

                </ul>
            </div>
        </div>)
}