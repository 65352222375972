import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export function ErrorMessages({
    formAlert = false,
    type = "danger",
    message = "COMMONS.UI.Notification.Danger",
    icon = "fas fa-exclamation-circle",
    className = "",
    params
}) {

    const [isEnabled, setIsEnabled] = useState(true)
    useEffect(() => {
        return () => {
            setIsEnabled(true);
        }
    }, []);

    if (isEnabled) {
        return (
            <>
                <div
                    className={`alert alert-custom alert-light-${type} fade show py-3 mb-0`}
                    role="alert"
                >
                    <div className="alert-icon">
                        <i className={`${icon} icon-md`}></i>
                    </div>
                    <div className="alert-text">
                        <FormattedMessage
                            id={message.toUpperCase()}
                            values={{
                                params: params
                            }}
                        />
                    </div>
                    {!formAlert && (
                        <div className="alert-close">
                            <span aria-hidden="true">
                                <i
                                    className="ki ki-close icon-xs"
                                    onClick={() => setIsEnabled(false)}
                                />
                            </span>
                        </div>
                    )}
                </div>
            </>
        );
    };
    return null;
}

