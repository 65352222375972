/* eslint-disable array-callback-return */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { CONTRACT_FORM_TYPE_OBJ } from "../_CommonUIHelpers";

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    {
        actions,
        show,
        setShow = () => { },
        mode = null,
        hidden
    }
) => {
    const setNewAction = () => {
        if (mode === "invoices") {
            if (row.status === 2) {
                if (row.totalReceivableMoney < 0) {
                    return actions.filter(el => el.message !== "MODEL.UI.receiptVouchers.export");
                } else {
                    return actions.filter(el => el.message !== "MODEL.UI.paymentVouchers.export");
                }
            }
            else {
                if (row.totalReceivableMoney < 0) {
                    return actions.filter(el => el.message !== "MODEL.UI.receiptVouchers.export" && el.type !== "sendEmail");
                } else {
                    return actions.filter(el => el.message !== "MODEL.UI.paymentVouchers.export" && el.type !== "sendEmail");
                }
            }
        }
        else if (mode === "assetInventories") {
            if (row.isUsing) {
                return actions.filter(el => el.isUsing !== false);
            } else return actions.filter(el => el.isUsing !== true);
        }
        else if (mode === "meterLogPartner") {
            if (row.id > 0) {
                return actions.filter(el => el.type !== "new");
            }
            else {
                return actions.filter(el => el.type !== "edit");
            }
        }
        else if (mode === "contracts") {
            if (row.contractType === CONTRACT_FORM_TYPE_OBJ.CONTRACT_DEPOSIT) {
                return actions.filter(el => el.contractType !== CONTRACT_FORM_TYPE_OBJ.CONTRACT_RENTED);
            }
            else if (row.status === 2) {
                return actions.filter(el => el.type !== "extension" && el.type !== "Checkout");
            }
            else return actions;
        }
        else if (mode === "bankAccounts") {
            if (row.isOrganization === true) {
                return actions.filter(el => el.type === "clone")
            }
            else if (row.isOrganizationDefault === true || row.isDefault === true || row.isPrimary === true) {
                return actions.filter(el => el.type !== "delete");
            }
            else
                return actions
        }

        else if (mode === "bankAccountOrganization") {
            if (row.isOrganizationDefault === true || row.isDefault === true || row.isPrimary === true) {
                return actions.filter(el => el.type !== "delete");
            }
            else
                return actions
        }
        else if (mode === "contractFormOrganization") {
            if (row.isOrganizationDefault === true || row.isPrimary) {
                return actions.filter(el => el.type !== "delete");
            }
            return actions
        }
        else if (mode === "contractForms") {
            if (row.isOrganizationDefault === true || row.isDefault === true) {
                if (row.isOrganization) {
                    return actions.filter(el => el.type !== "delete" && el.type !== "edit");
                }
                else if (row.isPrimary) {
                    return actions.filter(el => el.type !== "delete");
                }
                else return actions;

            } else if (row.isOrganization === true) {
                return actions.filter(el => el.type === "clone")
            }
            return actions
        }
        else if (mode === "deposits") {
            if (row.status === 1) {
                return actions.filter(el => el.type !== "createContract");
            }
            else if (row.status === 2) {
                return actions.filter(el => el.type !== "disabledStatus");
            }
            else if (row.status === 3) {
                return actions.filter(el => el.type !== "disabledStatus" && el.type !== "createContract");
            }
            else return actions
        }
        else if (mode === "units") {
            if ((row.status !== 1 && row.status !== 3) || row.isDeposit) {
                return actions.filter(el => el.type !== "updateStatus");
            }
            else return actions
        }
        else if (mode === "meters") {
            if (!row.isShared) {
                return actions.filter(el => el.type !== "clone" && el.type !== "delete");
            }
            else return actions
        }
        else if (mode === "meterLogs") {
            if (row.isPeg) {
                return actions.filter(el => el.type !== "record" && el.type !== "delete");
            }
            else return actions;
        }
        else if (mode === "services") {
            if (row.isPrimary) {
                return actions.filter(el => el.type !== "clone");
            }
            else return actions
        }
        else return actions;
    }


    const handleClick = (item, row) => {
        if (mode === "DeskUnitTable") {
            item.action(row.unitId);
        } else if (item.type === "download") {
            item.action(row);
        } else if (mode === "contracts") {
            if (item.type === "download") {
                item.action(row);
            } else if (item.type === "Checkout") {
                item.action(row.unitId);
            } else item.action(row.id);
        }
        else {
            item.action(row.id);
        }
        setShow(!show);

    }

    const _actions = setNewAction(actions) || [];
    const popover = (
        <Popover
            id="popover-basic"
            style={{ maxWidth: "none" }}
        >
            <Popover.Content className="p-1 notEvent">
                {_actions && _actions.length && <ul
                    className="navi navi-hover notEvent"
                    style={{ width: "180px" }}
                >
                    {_actions && _actions.map((item, index) => {
                        if (hidden && !hidden[index]) {
                            return (
                                <li
                                    key={index}
                                    className="navi-item action"
                                    onClick={() => {
                                        handleClick(item, row);
                                    }}
                                >
                                    <span type="button"
                                        className="navi-link action">
                                        <span className="navi-icon action">
                                            <i className={item.icon + " action"}></i>
                                        </span>
                                        <span className="navi-text action"><FormattedMessage id={item.message}></FormattedMessage></span>
                                    </span>
                                </li>
                            )
                        }
                    })}
                </ul>}
            </Popover.Content>
        </Popover >
    );
    return (
        <>
            {hidden?.filter(ele => ele === true).length !== hidden?.length &&
                (show ?
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={popover}
                    >
                        <span
                            type="button"
                            className="notEvent">
                            <i className="ki ki-bold-more-hor icon-ms text-hover-primary notEvent"></i>
                        </span>
                    </OverlayTrigger>
                    :
                    <span
                        type="button"
                        className="notEvent"
                        onClick={setShow(!show)}
                    >
                        <i className="ki ki-bold-more-hor icon-ms text-hover-primary notEvent"></i>
                    </span>
                )
            }
        </>
    )
}