import React from "react";
import { Modal } from "react-bootstrap";

import { ImageDocumnentsDialogHeader } from "./ImageDocumnentsDialogHeader";
import { ImageDocumnentsForm } from "./ImageDocumnentsForm";

export function ImageDocumnentsDialog({ show, onHide, selectFileDocument }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ImageDocumnentsDialogHeader />
      <ImageDocumnentsForm
        onHide={onHide}
        selectFileDocument={selectFileDocument}
      />
    </Modal>
  );
}
