import React from "react";
import { checkIsActive } from "../../../_bloomgoo/_helpers";
import { ButtonCardMenu } from "../../../_bloomgoo/_partials/button/ButtonCardMenu";
import { isPrivilegeUserGroup, isPrivilegeUserPacket } from "../../_helpers/PrivilegesUIHelper/User";
import { DropdownMenu } from "../../../_bloomgoo/_partials/dropdowns/DropdownMenu";

export const TYPE_EMAIL = [

  { id: 1, name: "Hóa đơn khách hàng" },
  { id: 2, name: "Thông báo thanh toán hóa đơn" },
  { id: 3, name: "Thông báo gia hạn hợp đồng" },
]

export const FREQUENCY = [
  { id: 1, name: "Sau mỗi lần đăng nhập" },
  { id: 2, name: "30 phút" },
  { id: 3, name: "1 tiếng" },
  { id: 4, name: "2 tiếng" }
]
export const SUPPLIER_TYPE = [
  { id: 1, name: "Gmail" },
  { id: 2, name: "Yahoo Mail" },
  { id: 3, name: "Outlook" },
  { id: 4, name: "Office365" },
  { id: 5, name: "PA VietNam" },
  { id: 6, name: "Yandex" },
  { id: 7, name: "Khác" },
]

export const getHeaderSettings = ({ user, history, location }) => {
  const menuSettingGroup = [
    {
      functionName: "settings/personal",
      icon: "flaticon-avatar",
      title: "Hồ sơ cá nhân",
      action: () => history.push("/settings/personal"),
      isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_PROFILE", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_PROFILE"))
    },

    {
      functionName: "settings/decentralization",
      icon: "flaticon-interface-5",
      title: "Phân quyền",
      action: () => history.push("/settings/decentralization"),
      isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_PERMISSION_BUILDINGS", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_PERMISSION_BUILDINGS"))
    },
    {
      functionName: "settings/currencies",
      icon: "flaticon-background",
      title: "Tiền tệ",
      action: () => history.push("/settings/currencies"),
      isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_CURRENCIES", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_CURRENCIES"))
    },
    {
      functionName: "settings/emails",
      icon: "flaticon-email",
      title: "Cấu hình email",
      action: () => history.push("/settings/emails"),
      isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_EMAIL_TEMPLATES", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_EMAIL_TEMPLATES"))
    },
    {
      functionName: "settings/building",
      icon: "flaticon-interface-5",
      title: "Cấu hình tòa nhà",
      action: () => history.push("/settings/building"),
      isPrivilege: (isPrivilegeUserPacket({ namePrivilege: "SETTINGS_ORGANIZATIONS", type: user.organization.subscriberType }) || isPrivilegeUserPacket({ namePrivilege: "SETTINGS_ORGANIZATION_BUILDINGS", type: user.organization.subscriberType })) && (isPrivilegeUserGroup(user, "SETTINGS_BUILDINGS"))
    },

  ];

  const getTabActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "bg-secondary"} `
      : "";
  };

  return (
    <>
      {menuSettingGroup.map((item) => {
        return (
          item.isPrivilege ?
            <ButtonCardMenu
              getTabActive={getTabActive}
              functionName={item.functionName}
              action={item.action}
              icon={item.icon}
              title={item.title}
            />
            :
            <></>
        )

      })}
      <DropdownMenu
        actions={[
          {
            functionName: "functions",
            icon: "flaticon-light",
            title: "Cấu hình chức năng",
            action: () => history.push("/settings/functions"),
            isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_FUNCTIONS", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_FUNCTIONS")),
            isActive: getTabActive(`/functions`, false)
          },
          {
            functionName: "api-keys",
            icon: "flaticon-clipboard",
            title: "Tích hợp API",
            action: () => history.push("/settings/api-keys"),
            isPrivilege: isPrivilegeUserPacket({ namePrivilege: "SETTINGS_API_KEYS", type: user.organization.subscriberType }) && (isPrivilegeUserGroup(user, "SETTINGS_API_KEYS")),
            isActive: getTabActive(`/api-keys`, false)
          },

        ]}
      />
    </>
  )
}
