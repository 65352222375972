/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";
import { isPrivilegeUserGroup, isPrivilegeUserPacket } from "../../../../../../app/_helpers/PrivilegesUIHelper/User";
import { checkIsActive, toAbsoluteUrl } from "../../../../../_helpers";


export function HeaderAdministrator({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    })
  )


  return (
    <>
      {isAdministrator(user) && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/#"
            )}`}
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to="/#">
              <i className="flaticon-grid-menu-v2 icon-md text-primary mr-2"></i>
              <span className="menu-text">Nghiệp vụ</span>
            </NavLink>
            <div
              className="menu-submenu menu-submenu-fixed menu-submenu-left"
              style={{ width: "945px" }}
            >
              <div className="menu-subnav">
                <ul className="menu-content">
                  <li className="menu-item ">
                    {
                      (
                        isPrivilegeUserGroup(user, "DASHBOARD") ||
                        isPrivilegeUserGroup(user, "USERS") ||
                        isPrivilegeUserGroup(user, "UNIT_SYMBOLS") ||
                        isPrivilegeUserGroup(user, "COLLABORATORS") ||
                        isPrivilegeUserGroup(user, "TASKS") ||
                        isPrivilegeUserGroup(user, "DOCUMENTS")
                      )
                      &&
                      (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">TỔ CHỨC</span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )
                    }

                    <ul className="menu-inner menu-inner-grid">
                      {(isPrivilegeUserPacket({ namePrivilege: "DASHBOARD", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "DASHBOARD")) &&
                        <li className={`menu-item ${getMenuItemActive("/dashboard")} menu-link-grid`} >
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/dashboard"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/dashboard.svg")} />
                            <span className="menu-text">Quản trị</span>
                          </NavLink>
                        </li>
                      )}

                      {(isPrivilegeUserPacket({ namePrivilege: "USERS", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "USERS")) &&
                        <li className={`menu-item ${getMenuItemActive("/admin/users")} menu-link-grid`}>
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/admin/users"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/users.svg")} />
                            <span className="menu-text">Người dùng</span>
                          </NavLink>
                        </li>
                      )}

                      {(isPrivilegeUserPacket({ namePrivilege: "UNIT_SYMBOLS", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "UNIT_SYMBOLS")) &&
                        <li className={`menu-item ${getMenuItemActive("/unitSymbols")} menu-link-grid`}>
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/admin/directions"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/unitSymbols.svg")} />
                            <span className="menu-text">Danh mục</span>
                          </NavLink>
                        </li>
                      )}

                      {(isPrivilegeUserPacket({ namePrivilege: "COLLABORATORS", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "COLLABORATORS")) &&
                        <li className={`menu-item ${getMenuItemActive("/collaborators")} menu-link-grid`}>
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/admin/collaborators"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/collaborators.svg")} />
                            <span className="menu-text">Môi giới</span>
                          </NavLink>
                        </li>
                      )}

                      {(isPrivilegeUserPacket({ namePrivilege: "TASKS", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "TASKS")) &&
                        <li className={`menu-item ${getMenuItemActive("/tasks")} menu-link-grid`}>
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/admin/tasks"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/tasks.svg")} />
                            <span className="menu-text">Công việc</span>
                          </NavLink>
                        </li>
                      )}

                      {(isPrivilegeUserPacket({ namePrivilege: "DOCUMENTS", type: user.organization.subscriberType }) &&
                        (isPrivilegeUserGroup(user, "DOCUMENTS")) &&
                        <li className={`menu-item ${getMenuItemActive("/documents")} menu-link-grid`}>
                          <NavLink
                            className="menu-link menu-link-grid"
                            to="/admin/documents"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/documents.svg")} />
                            <span className="menu-text">Tài liệu</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {isPrivilegeUserGroup(user, "REPORTS") && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">BÁO CÁO & THỐNG KÊ</span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                      <ul className="menu-inner menu-inner-grid">
                        {(isPrivilegeUserPacket({ namePrivilege: "REPORTS_FINANCIALS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "REPORTS_FINANCIALS")) &&
                          <li className={`menu-item ${getMenuItemActive("/reports/overview/chart")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/reports/overview/chart"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/report-overview.svg")} />
                              <span className="menu-text">Tài chính</span>
                            </NavLink>
                          </li>
                        )}

                        {(isPrivilegeUserPacket({ namePrivilege: "REPORTS_STATISTICS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "REPORTS_STATISTICS")) &&
                          <li className={`menu-item ${getMenuItemActive("/reports/statistics/chart")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/reports/statistics/chart"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/report-statistics.svg")} />
                              <span className="menu-text">Thống kê</span>
                            </NavLink>
                          </li>
                        )}

                        {(isPrivilegeUserPacket({ namePrivilege: "REPORTS_DEBTS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "REPORTS_DEBTS")) &&
                          <li className={`menu-item ${getMenuItemActive("/reports/debts/chart")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/reports/debts/chart"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/report-debts.svg")} />
                              <span className="menu-text">Công nợ</span>
                            </NavLink>
                          </li>
                        )}

                        {(isPrivilegeUserPacket({ namePrivilege: "REPORTS_TASKS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "REPORTS_TASKS")) &&
                          <li className={`menu-item ${getMenuItemActive("/reports/task/chart")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/reports/task/chart"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/report-tasks.svg")} />
                              <span className="menu-text">Công việc</span>
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {isPrivilegeUserGroup(user, "CUSTOMERS") && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">CHO THUÊ</span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                      <ul className="menu-inner menu-inner-grid">
                        {(isPrivilegeUserPacket({ namePrivilege: "CUSTOMERS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "CUSTOMERS")) &&
                          <li className={`menu-item ${getMenuItemActive("/customers")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/customers"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/customers.svg")} />
                              <span className="menu-text">Khách hàng</span>
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      <h3 className="menu-heading menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i>
                        <span className="menu-text d-flex justify-content-center">TIỆN ÍCH</span>
                        <i className="menu-arrow"></i>
                      </h3>

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegeUserPacket({ namePrivilege: "SETTINGS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "SETTINGS")) &&
                          <li className={`menu-item ${getMenuItemActive("/settings/personal")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/settings/personal"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/settings.svg")} />
                              <span className="menu-text">Cấu hình</span>
                            </NavLink>
                          </li>
                        }

                        {(isPrivilegeUserPacket({ namePrivilege: "POSTS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "POSTS")) &&
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/posts"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/posts"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/posts.svg")} /><span className="menu-text">Đăng tin</span>
                            </NavLink>
                          </li>
                        )}




                        {(isPrivilegeUserPacket({ namePrivilege: "SUPPORTS", type: user.organization.subscriberType }) &&
                          (isPrivilegeUserGroup(user, "SUPPORTS")) &&
                          <li className={`menu-item ${getMenuItemActive("/supports")} menu-link-grid`}>
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/supports"
                            >
                              <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/supports.svg")} />
                              <span className="menu-text">Trung tâm hỗ trợ</span>
                            </NavLink>
                          </li>
                        )}

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/guides"
                          )} menu-link-grid`}
                        >
                          <a
                            className="menu-link menu-link-grid"
                            href="https://chuoicanho.com/ho-tro/"
                            target="_blank"
                          >
                            <img alt="" src={toAbsoluteUrl("/media/svg/icons/Apps/guides.svg")} /><span className="menu-text">Hướng dẫn</span>
                          </a>

                        </li>
                      </ul>

                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          {(isPrivilegeUserPacket({ namePrivilege: "DASHBOARD", type: user.organization.subscriberType }) &&
            (isPrivilegeUserGroup(user, "DASHBOARD")) &&
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/admin/dashboard"
              )}`}
            >
              <NavLink className="menu-link" to="/admin/dashboard">
                <i className="flaticon-dashboard icon-md text-primary mr-2"></i> <span className="menu-text">Bảng quản trị</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>
          )}

          {(isPrivilegeUserPacket({ namePrivilege: "DASHBOARD_BASE", type: user.organization.subscriberType }) &&
            (isPrivilegeUserGroup(user, "DASHBOARD_BASE")) &&
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/admin/deskBase"
              )}`}
            >
              <NavLink className="menu-link" to="/admin/deskBase">
                <i className="flaticon-computer icon-md text-primary mr-2"></i><span className="menu-text">Quản trị cở sở</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>)}

          {(isPrivilegeUserPacket({ namePrivilege: "DESKS", type: user.organization.subscriberType }) &&
            (isPrivilegeUserGroup(user, "DESKS")) &&
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/admin/desk"
              )}`}
            >
              <NavLink className="menu-link" to="/admin/desk">

                <i className="flaticon-computer icon-md text-primary mr-2"></i><span className="menu-text">Bàn làm việc</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>)}
          {(isPrivilegeUserPacket({ namePrivilege: "NOTIFICATIONS", type: user.organization.subscriberType }) &&
            (isPrivilegeUserGroup(user, "NOTIFICATIONS")) &&
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/admin/notifications"
              )}`}
            >
              <NavLink className="menu-link" to="/admin/notifications">
                <i className="flaticon-alert-1 icon-md text-primary mr-2"></i>
                <span className="menu-text">Bảng thông báo</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>)}

          {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/chats"
          )}`}
        >
          <NavLink className="menu-link" to="/chats">

            <i className="flaticon-chat-1 icon-md text-primary mr-2"></i>
            <span className="menu-text">Chat</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li> */}
          {(isPrivilegeUserPacket({ namePrivilege: "SUPPORTS", type: user.organization.subscriberType }) &&
            (isPrivilegeUserGroup(user, "SUPPORTS")) &&
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/supports"
              )}`}
            >
              <NavLink className="menu-link" to="/supports">
                <i className="flaticon-lifebuoy icon-md text-primary mr-2"></i>
                <span className="menu-text">Trung tâm hỗ trợ</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>
          )}
        </ul>
      )}

      {/*end::1 Level*/}

      {/*Classic submenu*/}
      {/*Mega submenu*/}
      {/*begin::1 Level*/}


    </ >
  );
}
