import React from "react";
import { FormattedMessage } from "react-intl";
import * as requestFromServer from "./assetInventoryPeriodsCrud";
import { assetInventoryPeriodsSlice, callTypes } from "./assetInventoryPeriodsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = assetInventoryPeriodsSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const cleanCountAssetInventoryPeriods = () => (dispatch) => {
  dispatch(actions.countAssetInventoryPeriodsClean());
};


// Area actions fetch list

export const fetchAllAssetInventoryPeriods = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllAssetInventoryPeriods()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.assetInventoryPeriodsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllAssetInventoryPeriods";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentAssetInventoryPeriods = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCallParent({ callType: callType }));
  return requestFromServer
    .getAllAssetInventoryPeriods()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.assetInventoryPeriodsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentAssetInventoryPeriods";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area Filter list

export const fetchAssetInventoryPeriods = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findAssetInventoryPeriods(queryParams)
      // .getAllAssetInventoryPeriods()
      .then((response) => {
        let entities = response.data.entities;
        const totalCount = response.data.totalCount;
        const filterCount = {
          total: response.data.total
        }
        dispatch(actions.assetInventoryPeriodsFetched({ totalCount, entities, filterCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchAssetInventoryPeriods";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};


// Area actions get item

export const fetchAssetInventoryPeriod = (id, mode) => (dispatch) => {
  const callType = callTypes.initialization;
  if (mode === "new") {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitAssetInventoryPeriod()
      .then((response) => {
        const assetInventoryPeriod = response.data;
        dispatch(
          actions.assetInventoryPeriodFetched({ assetInventoryPeriodForEdit: assetInventoryPeriod })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchAssetInventoryPeriod";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode && mode === "edit") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getAssetInventoryPeriodEditById(id)
        .then((response) => {
          const assetInventoryPeriod = response.data;
          dispatch(
            actions.assetInventoryPeriodFetched({ assetInventoryPeriodForEdit: assetInventoryPeriod })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchAssetInventoryPeriod";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    } else
      if (mode === "show") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getAssetInventoryPeriodById(id)
          .then((response) => {
            const assetInventoryPeriod = response.data;
            dispatch(
              actions.assetInventoryPeriodShowFetched({ assetInventoryPeriodForShow: assetInventoryPeriod })
            );
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchAssetInventoryPeriod";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
};


// Area actions create, update item

export const createAssetInventoryPeriod = (assetInventoryPeriodForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createAssetInventoryPeriod(assetInventoryPeriodForCreation)
    .then((response) => {
      const assetInventoryPeriod = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.assetInventoryPeriodCreated({ assetInventoryPeriod }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createAssetInventoryPeriod";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const updateAssetInventoryPeriod = (assetInventoryPeriod) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateAssetInventoryPeriod(assetInventoryPeriod)
    .then((response) => {
      const assetInventoryPeriod = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.assetInventoryPeriodUpdated({ assetInventoryPeriod }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateAssetInventoryPeriod";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updateAssetInventoryPeriodsStatus = (id, status) => (dispatch) => {
  console.log("assetInventoryPeriodsStatusUpdated");
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForAssetInventoryPeriods(id, status)
    .then((response) => {
      const closingDate = response.data.closingDate;
      const openingDate = response.data.openingDate;

      console.log(response);
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      console.log("assetInventoryPeriodsStatusUpdated");
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.assetInventoryPeriodsStatusUpdated({ id, status, closingDate, openingDate }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateAssetInventoryPeriodsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item

export const deleteAssetInventoryPeriods = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteAssetInventoryPeriods(ids)
    .then(() => {
      dispatch(actions.assetInventoryPeriodsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteAssetInventoryPeriods";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};


export const deleteAssetInventoryPeriod = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteAssetInventoryPeriod(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.delete" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.assetInventoryPeriodDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteAssetInventoryPeriod";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchAssetInventoryPeriodsCount = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findAssetInventoryPeriodsCount()
    .then((response) => {
      const assetInventoryPeriodsCount = response.data;
      dispatch(actions.assetInventoryPeriodsFetchCount({ assetInventoryPeriodsCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAssetInventoryPeriodsCount";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    })
};

export const cleanAssetInventoryPeriodsCount = () => (dispatch) => {
  return dispatch(actions.countAssetInventoryPeriodsClean());
};
