import { USER_TYPE } from "../_CommonUIHelpers";

export const isCollaborator = (user) => {
    if (user !== null && user.organization !== null) {
        if (user.userType === USER_TYPE.COLLABORATORS) {
            return true;
        }
        return false;
    }
    return false;
};