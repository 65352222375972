import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCollaboratorCommissionsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  collaboratorCommissionForEdit: null,
  collaboratorCommissionForShow: null,

  filterCount: null,
  error: null,
  notification: null,

  isError: null,
  currentCollaboratorCommission: null,
  currentCollaboratorCommissions: [],
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const collaboratorCommissionsSlice = createSlice({
  name: "collaboratorCommissions",
  initialState: initialCollaboratorCommissionsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCollaboratorCommission = null;
      state.currentCollaboratorCommissions = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.collaboratorCommissionForEdit = null;
    },

    resetCurrentCollaboratorCommissions: (state, action) => {
      state.currentCollaboratorCommission = null;
      state.currentCollaboratorCommissions = [];
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    // getCommissionById
    collaboratorCommissionFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.collaboratorCommissionForEdit = action.payload.collaboratorCommissionForEdit;
      state.error = null;

    },

    //show
    collaboratorCommissionShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.collaboratorCommissionForShow = action.payload.collaboratorCommissionForShow;
      state.error = null;

    },
    // findAllCommissions
    collaboratorCommissionsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findCommissions
    collaboratorCommissionsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },
    // findParentCommissions
    collaboratorCommissionsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createCommission
    collaboratorCommissionCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.collaboratorCommission);
      state.currentCollaboratorCommission = action.payload.collaboratorCommission;
      state.currentCollaboratorCommissions.push(action.payload.collaboratorCommission);
    },
    // updateCommission
    collaboratorCommissionUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.collaboratorCommission.id) {
          return action.payload.collaboratorCommission;
        }
        return entity;
      });
      state.currentCollaboratorCommission = action.payload.collaboratorCommission;
      state.currentCollaboratorCommissions.push(action.payload.collaboratorCommission);
    },
    // deleteCommission
    collaboratorCommissionDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentCollaboratorCommission = null;
      state.currentCollaboratorCommissions = [];

    },
    // deleteCommissions
    collaboratorCommissionsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentCollaboratorCommission = null;
      state.currentCollaboratorCommissions = [];
    },
    // collaboratorCommissionsUpdateState
    collaboratorCommissionsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
          if (status === 2) {
            state.filterCount.totalApproved = state.filterCount.totalApproved + 1
            state.filterCount.totalUnApproved = state.filterCount.totalUnApproved - 1
          }
          else if (status === 1) {
            state.filterCount.totalApproved = state.filterCount.totalApproved - 1
            state.filterCount.totalUnApproved = state.filterCount.totalUnApproved + 1
          };
        };
        return entity;
      });

      state.currentCollaboratorCommission = action.payload.collaboratorCommission;
    },
  },
});
