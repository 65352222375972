import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialMeterLogsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  meterLogForEdit: null,
  meterLogForShow: null,
  currentMeterLogs: [],
  meterLogShow: [],
  meterLogs: null,
  notification: null,
  filterCount: null,
  meterLogsHistory: [],
  errorType: null,
  refresh: 0,
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const meterLogsSlice = createSlice({
  name: "meterLogs",
  initialState: initialMeterLogsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        // state.entities = [];
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
        state.actionsLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    refresh: (state, action) => {
      state.refresh += 1;
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.meterLogForEdit = null;
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        // state.entities = [];
      } else {
      }
    },
    resetCurrentMeterLogs: (state, action) => {
      state.currentMeterLogs = [];
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentMeterLogs = [];
      state.entities = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.isError = null;
      state.meterForEdit = null;
    },

    // getMeterLogById
    meterLogFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterLogForEdit = action.payload.meterLogForEdit;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },
    //show

    meterLogShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterLogForShow = action.payload.meterLogForShow;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // findAllMeterLogs
    meterLogsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    //find meterLogs in meterLogsNew
    meterLogsFetchedInMeterLogsNew: (state, action) => {
      state.listLoading = false;
      state.error = null;

      state.meterLogs = action.payload.meterLogs;
    },
    // findMeterLogs
    meterLogsFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentMeterLogs
    meterLogsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createMeterLog
    meterLogCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map((entity) => {
        if (
          Math.abs(action.payload.meterLog.meterId) === Math.abs(entity.meterId)
        ) {
          entity = action.payload.meterLog;
        }
        return entity;
      });
      state.currentMeterLogs.push(action.payload.meterLog);
    },

    meterLogsCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.currentMeterLogs = action.payload.meterLogs;
    },
    // updateMeterLog
    meterLogUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.meterLog.id) {
          return action.payload.meterLog;
        }
        return entity;
      });
      state.currentMeterLogs.push(action.payload.meterLog);
    },
    // deleteMeterLog
    meterLogDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentMeterLogs = [];
      state.refresh += 1;
    },
    // deleteMeterLogs
    meterLogsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentMeterLogs = [];
      state.refresh += 1;
    },
    // meterLogsUpdateState
    meterLogsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, isPeg } = action.payload;
      // state.currentMeterLogs = state.entities.filter(el => ids.some(id => el.id === id));
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.isPeg = isPeg;
        }
        return entity;
      });
    },
    meterLogsHistory: (state, action) => {
      state.entities = action.payload.entities;
      state.totalCount = action.payload.totalCount;
      state.listLoading = false;
      state.error = null;
    },

    meterLogImportFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { meterLogImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.meterLogImport = meterLogImport;
    },

    meterLogImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },

    resetMeterLogImport: (state, action) => {
      state.meterLogImport = null;
    },

    meterLogsShow: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { meterLogShow } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.meterLogShow = meterLogShow;
    },
  },
});
