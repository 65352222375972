import axios from "axios";

export const USERS_URL = "/users";

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
  return axios.post(USERS_URL, user);
}

// READ
export function getAllUsers() {
  return axios.get(USERS_URL);
}
export function getUserCollaborators() {
  return axios.get(`${USERS_URL}/collaborators`);
}
export function getInitUser() {
  return axios.get(`${USERS_URL}/create`);
}
export function getInitUserByUserType(userType) {
  return axios.get(`${USERS_URL}/userType/${userType}/create`);
}
export function getUserCloneById(userId) {
  return axios.get(`${USERS_URL}/${userId}/clone`);
}
export function getUserById(userId) {
  return axios.get(`${USERS_URL}/${userId}`);
}
export function getUserEditById(userId) {
  return axios.get(`${USERS_URL}/${userId}/edit`);
}
export function getUserProfileById(userId) {
  return axios.get(`${USERS_URL}/${userId}/profile`);
}

export function getMyProfile() {
  return axios.get(`profile`);
}

export function getUserBuildingPermissionById(userId) {
  return axios.get(`${USERS_URL}/${userId}/buildingPermission/edit`);
}

export function findUsersByOrganizationId(organizationId) {
  return axios.get(`${USERS_URL}/organization/${organizationId}`);
}
export function findAllCollaborators() {
  return axios.get(`${USERS_URL}/collaborators`);
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
  return axios.post(`${USERS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateUser(user, id) {
  return axios.put(`${USERS_URL}/${id}`, user);
}

export function updateProfile(user, id) {
  return axios.put(`${USERS_URL}/${id}/profile`, user);
}

export function updateReferral(userId, referralCode) {
  let referral = {
    id: userId,
    referralCode: referralCode
  }
  return axios.put(`${USERS_URL}/${userId}/ref`, referral);
}

export function updateUserBuildingPermission(user) {
  return axios.put(`${USERS_URL}/${user.id}/buildingPermission/update`, user);
}

export function updateUserPassword(userId, password) {
  return axios.put(`${USERS_URL}/${userId}/changePassword`, password);
}
// UPDATE Status
export function updateStatusForUsers(ids, status) {
  return axios.post(`${USERS_URL}/updateStatusForUsers`, {
    ids,
    status,
  });
}
// UPDATE Active
export function updateActiveForUsers(ids, isActive) {
  return axios.post(`${USERS_URL}/isActive/update`, {
    ids,
    isActive,
  });
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
  return axios.delete(`${USERS_URL}/${userId}`);
}

// DELETE Users by ids
export function deleteUsers(ids) {
  return axios.delete(`${USERS_URL}/bulk`, { data: { ids } });
}

export function getUserImport(file) {
  return axios.post(`${USERS_URL}/import`, file);
}

export function getUserImportStore(file) {
  return axios.post(`${USERS_URL}/import/store`, file);
}

export function getUserExportExcel() {
  return axios.get(`${USERS_URL}/export`, {
    // include your additional POSTed data here
    responseType: "blob"
  })
}

export function getTemplateUserExportExcel() {
  return axios.get(`${USERS_URL}/import/template`, {
    // include your additional POSTed data here
    responseType: "blob"
  })
}