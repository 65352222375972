import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useDocumentsUIContext } from "./DocumentsUIContext";
import { FormattedMessage } from 'react-intl';
import { Input } from "../../../../../../_bloomgoo/_partials/controls";

const prepareFilter = (queryParams, values) => {
  const { documentOriginalName } = values;
  const newQueryParams = { ...queryParams };
  const filter = { searchCriteria: [] };

  filter.searchCriteria.push({
    key: "documentOriginalName",
    operation: ":",
    value: documentOriginalName,
    prefix: "*",
    suffix: "*"
  });

  filter.searchCriteria.push({
    key: "documentFormat",
    operation: ":",
    value: "image/",
    prefix: "*",
    suffix: "*"
  });


  newQueryParams.filter = filter;
  return newQueryParams;
};

export function DocumentsFilter({ listLoading }) {

  const documentsUIContext = useDocumentsUIContext();
  const documentsUIProps = useMemo(() => {
    return {
      openNewVoucherCategoryDialog: documentsUIContext.openNewVoucherCategoryDialog,
      setQueryParams: documentsUIContext.setQueryParams,
      queryParams: documentsUIContext.queryParams,
      buildingId: documentsUIContext.buildingId,
    };
  }, [documentsUIContext]);
  // building Redux state

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(documentsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, documentsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      documentsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-8 margin-bottom-10-mobile">
            <Formik
              enableReinitialize={true}
              initialValues={{
                documentOriginalName: "",
              }}
              onSubmit={(values) => {
                applyFilter(values);
              }}
            >
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-2 ml-5 col-form-label text-lg-left">
                      <FormattedMessage id="MODEL.UI.documents.documentName" ></FormattedMessage>
                    </div>
                    <div className="col-lg-6">
                      <FormattedMessage id="COMMONS.UI.PLACEHOLDER.Input" >
                        {msg => (
                          <Field
                            name="documentOriginalName"
                            component={Input}
                            placeholder={msg}
                            type="text"
                            checkYup={false}
                            className="form-control form-control-md"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              handleSubmit();
                            }}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}