import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from 'react-select';
import { POSITION_INTERFACE_OBJ } from '../../../../app/_helpers/EnumUIHelpers';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getColorValid = (valid, isFocused) => {
  if (valid.includes('is-valid')) {
    return '#007c7e !important'
  }
  else if (valid.includes('is-invalid'))
    return '#F64E60 !important'
  else {
    if (isFocused) {
      return '#00CFA7 !important'
    }
    else {
      return '#E4E6EF !important'
    }
  }
}




export function SelectField(props) {
  const {
    field,  // props field
    form,  // props form
    options,  // Danh sách option
    checkYup = true,  // Hiển thị check Yup hay k
    label,  /// label
    hidden = false, // Ẩn hiện
    isMulti = false,  // Chọn nhiều hay k
    positionIcon = false,  // Vị trí đặt icon nếu có
    placeholder = "-- Chọn --", // placeholder
    sizeIcon = "nm",
    iconPlaceholder = "",  // icon 
    autoComplete = "on", // tự động suggest hay k
    disabled,  // disalbed
    minWidth = 120,  // rộng nhỏ nhất
    required = false,  // có phải trường bắt buộc hay k
    handleFieldChange, // hàm xử lý change
    formProps,  // props của form
    withFeedbackLabel = true, // có hiển thị label feed back yup hay k
    customFeedbackLabel, // string label feed back yup
    type, // loại input
    classNameLabel,
    className = '',
    height
  } = props;


  const filterExtendStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
      flexDirection: "row-reverse"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#fff'
    })
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      height: height,
      minHeight: height,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: height,
      padding: '0px 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: height,
    }),
  }

  const { name, value } = field;
  const { errors, touched } = form;

  const selectedOption = options && options.filter(option => value !== null && (isMulti ? value.includes(option.value) : option && option.value === value));

  const onChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : selectedOption;

    const multiValue = [];
    isMulti && selectedOption && selectedOption.map((item) => multiValue.push(item.value));

    const changeEvent = {
      target: { name: name, value: selectedValue }
    };

    const changeMultiEvent = {
      target: { name: name, value: multiValue }
    };

    field.onChange(changeEvent);
    form.setFieldTouched(field.name, true);

    form.handleChange(isMulti ? changeMultiEvent : changeEvent);
    handleFieldChange(isMulti ? changeMultiEvent : changeEvent, formProps);
  }

  const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className={`${iconPlaceholder} icon-md`} aria-hidden="true" style={{ position: 'initial' }}></i>
      </components.DropdownIndicator>
    );
  };

  const Option = props => {
    const { innerProps, innerRef, data, isFocused } = props;

    return (
      <>
        <article ref={innerRef} {...innerProps} className={'custom-option'}>
          <div type="button" style={{ backgroundColor: (value === data.value) ? '#007bff' : (isFocused && "#deebff"), borderColor: "#0123" }}>
            <div className="d-flex justify-content-center align-items-center">
              {positionIcon === POSITION_INTERFACE_OBJ.FRONT && data.icon &&
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-icon" >
                      {data.iconTooltip}
                    </Tooltip>
                  }
                >
                  <i className={`${data.icon} ${value === data.value ? "text-white" : data.iconColor} mb-1 ml-2 icon-${sizeIcon}`} />
                </OverlayTrigger>

              }
              <span className={`col ${(value === data.value) && "text-white"} my-2`}>{(data.label === "null" || data.label === "undefined") ? "" : data.label}</span>
              {positionIcon === POSITION_INTERFACE_OBJ.REAR && data.icon &&
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-icon"
                      style={{
                        position: "absolute",
                        zIndex: 1000000
                      }}
                    >
                      {data.iconTooltip}
                    </Tooltip>
                  }
                >
                  <i className={`${data.icon} ${value === data.value ? "text-white" : data.iconColor} mb-1 icon-${sizeIcon}`} />
                </OverlayTrigger>

              }
              {positionIcon === POSITION_INTERFACE_OBJ.END && data.icon &&
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-icon"
                      style={{
                        position: "absolute",
                        zIndex: 1000000
                      }}
                    >
                      {data.iconTooltip}
                    </Tooltip>
                  }
                >
                  <i className={`${data.icon} ${value === data.value ? "text-white" : data.iconColor} mb-1 ml-auto mr-2 icon-${sizeIcon}`} />
                </OverlayTrigger>

              }
            </div>
          </div>
        </article >
      </>
    );
  };

  const renderCompoenent = (
    <>
      {label && <label className={classNameLabel}>{label} {required && <span className="required">*</span>}</label>}
      <Select
        id={name}
        {...field}
        menuPlacement="auto"
        value={selectedOption}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        autoComplete={autoComplete}
        noOptionsMessage={() => "Không có dữ liệu"}
        className={checkYup ? `${className} react-select` : className}
        styles={iconPlaceholder ? filterExtendStyles : customStyles}
        menuPortalTarget={document.body}
        components={{ Option }}
        classNamePrefix={getFieldCSSClasses(touched[field.name], errors[field.name])}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        isOptionDisabled={(option) => option.isdisabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );

  return (
    hidden ? <></> : renderCompoenent
  )
}
