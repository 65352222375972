import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialBrokerIntroductionsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  brokerIntroductionForEdit: null,

  filterCount: null,
  error: null,
  isError: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
  table: "table"
};

export const brokerIntroductionsSlice = createSlice({
  name: "brokerIntroductions",
  initialState: initialBrokerIntroductionsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentBrokerIntroduction = null;
      state.currentBrokerIntroductions = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.brokerIntroductionForEdit = null;
    },

    resetCurrentBrokerIntroductions: (state, action) => {
      state.currentBrokerIntroduction = null;
      state.currentBrokerIntroductions = [];
    },

    // getBrokerIntroductionById
    brokerIntroductionFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.brokerIntroductionForEdit = action.payload.brokerIntroductionForEdit;
      state.error = null;

    },
    // findAllBrokerIntroductions
    brokerIntroductionsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findBrokerIntroductions
    brokerIntroductionsFetched: (state, action) => {
      const { entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.filterCount = filterCount;
      state.entities = entities;
    },
    // findParentBrokerIntroductions
    brokerIntroductionsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createBrokerIntroduction
    brokerIntroductionCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.brokerIntroduction);
    },
    // updateBrokerIntroduction
    brokerIntroductionUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
    },
    // deleteBrokerIntroduction
    brokerIntroductionDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
    },
    // deleteBrokerIntroductions
    brokerIntroductionsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
    },
    // brokerIntroductionsUpdateState

  },
});
