/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FormattedMessage, injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from "../../../../_bloomgoo/_helpers";
import { rowEvents, rowStyle } from "../../../../_bloomgoo/_helpers/TableRowStyle";
import { NotFoundTableMini, Pagination } from "../../../../_bloomgoo/_partials/controls";
import { DatetimeColumnFormatter, OrderColumnFormatter } from "../../../_helpers/column-formatters";
import { arrayRemove, sizePerPageList, useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import * as actions from "../_redux/api-keys/apiKeysActions";
import { defaultSorted } from "./APIKeysUIHelpers";
import { ActionsColumnFormatter } from "./column-formatters";
import { toastsSlice } from "../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"
import { APIKeyDeleteDialog } from "./APIKeyDeleteDialog";
import { APIKeyResetDialog } from "./APIKeyResetDialog";
import { APIKeyActiveDialog } from "./APIKeyActiveDialog";


let codeFilter;
let nameFilter;
let parentFilter;
let addressFilter;
let floorNumberFilter;
let displayOrderFilter;
let ownerNameFilter;
let ownerTelFilter;
let descriptionFilter;
let latitudeFilter;
let longitudeFilter;
let createdByFilter;
let updatedByFilter;
let toggles;
// let columnFields;
// let onColumnToggle;

export const clearFilterVal = ({ isReload, queryParams, setQueryParams }) => {
  toggles.code && codeFilter("");
  toggles.name && nameFilter("");
  toggles.parent && parentFilter("");
  toggles.address && addressFilter("");
  toggles.floor && floorNumberFilter({ number: "", comparator: "=" });
  toggles.displayOrder && displayOrderFilter({ number: "", comparator: "=" });
  toggles.ownerNameFilter && ownerNameFilter("");
  toggles.ownerTelFilter && ownerTelFilter("");
  toggles.descriptionFilter && descriptionFilter("");
  toggles.latitudeFilter && latitudeFilter("");
  toggles.longitudeFilter && longitudeFilter("");
  toggles.createdByFilter && createdByFilter("");
  toggles.updatedByFilter && updatedByFilter("");
  const newQueryParams = JSON.parse(JSON.stringify(queryParams));
  const searchCriteria = [];
  newQueryParams && newQueryParams.filter.searchCriteria.forEach(criteria => {
    if (criteria.isDefault || criteria.level === 2) {
      const newCriteria = {
        key: criteria.key,
        operation: criteria.operation,
        value: criteria.value,
        prefix: criteria.prefix,
        suffix: criteria.suffix,
        isDefault: criteria.isDefault,
        level: criteria.level,
      };
      searchCriteria.push(newCriteria);
    }
  });
  newQueryParams.filter.searchCriteria = searchCriteria;
  newQueryParams.sortOrder = defaultSorted[0].order; // asc||desc
  newQueryParams.sortField = defaultSorted[0].dataField;
  newQueryParams.pageNumber = 1;
  newQueryParams.pageSize = 35;
  setQueryParams(newQueryParams);
};


export default function APIKeyTableIntl(props) {
  const {
    queryParams,
    ids,
    setIds,
    selectedRows,
    setSelectedRows,
    setQueryParams,
    apiKeyEvents,
    intl,
    isShowDelete,
    setIsShowDelete,
    isShowReset,
    setIsShowReset,
    isShowActive,
    setIsShowActive,
  } = props;

  const defaultSorted = [{ dataField: "createdAt", order: "asc" }];
  const { currentState, user, apiKeyValue, action } = useSelector(
    (state) => ({
      currentState: state.apiKeys,
      user: state.auth.user,
      apiKeyValue: state.apiKeys.apiKeyValue,
      action: state.apiKeys.action
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (action !== "resetAPIKey" && action !== "deleteAPIKey" && action !== "updateAPIKey") {
      dispatch(actions.fetchAPIKeys(queryParams));
    }
  }, [queryParams]);

  const [showActions, setShowActions] = useState(false);

  function copyLink(link) {
    document.body.insertAdjacentHTML("beforeend", "<div id=\"copy\" contenteditable>" + link + "</div>")
    document.getElementById("copy").focus();
    document.execCommand("selectAll");
    document.execCommand("copy");
    document.getElementById("copy").remove();
    if (link === "" || link === null || link === undefined) {
      const notification = {
        content: <FormattedMessage id="COMMONS.UI.DANGER.copy" />
      }
      dispatch(toastsSlice.actions.errorToastText({ notification }));
    } else {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" />,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.copy" />
      }
      dispatch(toastsSlice.actions.successToast({ notification }));
    }
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };


  const setShowKey = (id, status) => {
    console.log("status: ", status)
    if (status === "show") {
      dispatch(actions.getAPIKeyById(id));
    }
    else if (status === "hide") {
      let _data = JSON.parse(JSON.stringify(data));
      let _isShowAPIKey = JSON.parse(JSON.stringify(isShowAPIKey));
      let index = _data.findIndex((ele) => ele.id === apiKeyValue?.id);
      _data[index].apiKey = "*****";
      _isShowAPIKey[index] = false;
      setIsShowAPIKey(_isShowAPIKey);
      setData(_data);
    }
  };


  const [data, setData] = useState([]);
  const [isShowAPIKey, setIsShowAPIKey] = useState({});

  useEffect(() => {
    setData(entities);
    setIsShowAPIKey(Array(data?.length)
      .fill(0)
      .map((ele, index) => (false)))
  }, [entities]);


  useDidMountEffect(() => {
    let _data = JSON.parse(JSON.stringify(data));
    let _isShowAPIKey = JSON.parse(JSON.stringify(isShowAPIKey));
    let index = _data.findIndex((ele) => ele.id === apiKeyValue?.id);
    _data[index].apiKey = apiKeyValue?.apiKey;
    _isShowAPIKey[index] = true;
    setIsShowAPIKey(_isShowAPIKey);
    setData(_data);
  }, [apiKeyValue]);

  const columns = [
    {
      dataField: "id",
      headerClasses: "text-center",
      text: "#",

      formatter: OrderColumnFormatter,
      classes: "text-center",
      editor: {
        type: "number"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
      hidden: true,
    },
    {
      dataField: "action",
      headerClasses: "text-center",
      text: "#",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        actions: [
          { action: apiKeyEvents, icon: "flaticon-edit text-primary", message: "COMMONS.UI.Edit", mode: "edit" },
          { action: apiKeyEvents, icon: "flaticon-multimedia-5 text-primary", message: "COMMONS.UI.isActive", mode: "active" },
          { action: apiKeyEvents, icon: "flaticon-multimedia-5 text-danger", message: "COMMONS.UI.UnIsActive", mode: "active" },
          { action: apiKeyEvents, icon: "flaticon-refresh text-primary", message: "COMMONS.UI.Reset", mode: "reset" },
          { action: apiKeyEvents, icon: "flaticon-delete text-danger", message: "COMMONS.UI.Delete", mode: "delete" },
        ],
        show: showActions,
        setShow: setShowActions,
        hidden: [
          // !isPrivilegeUserAction(user, "SETTINGS_API_KEYS_UPDATE_WRITE"),
          // !isPrivilegeUserAction(user, "SETTINGS_API_KEYS_DELETE_WRITE")
          // !isPrivilegeUserAction(user, "SETTINGS_API_KEYS_DELETE_WRITE")
          false,
          false,
          false,
          false
        ]
      },
      classes: "text-center notEvent",
      editor: {
        type: "number"
      },
      style: {
        width: "40px"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    {
      dataField: "key",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.apiKeys.name",
        }),

      formatter: (cellContent, row) =>
        <span>{row?.name}</span>,
      classes: "text-left",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },

    {
      dataField: "apiKey",
      headerClasses: "text-center",
      text:
        intl.formatMessage({
          id: "MODEL.UI.apiKeys.apiKey",
        }),

      formatter: (cellContent, row, index) =>
        <span>{row?.apiKey}{
          row?.apiKey !== "*****" ?
            <span className="text-hover-primary px-2 pt-1" type="button" onClick={() => { copyLink(row?.apiKey) }}>
              <i className="far fa-copy notEvent"></i>
            </span>
            :
            <></>
        }</span>,
      classes: "text-left",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },

    {
      dataField: "description",
      headerClasses: "text-center",
      text: intl.formatMessage({
        id: "MODEL.UI.apiKeys.description"
      }),

      classes: "text-left",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    {
      dataField: "createdAt",
      headerClasses: "text-center",
      text: intl.formatMessage({
        id: "MODEL.UI.apiKeys.createdAt"
      }),
      formatter: DatetimeColumnFormatter,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    {
      dataField: "updatedAt",
      headerClasses: "text-center",
      text: intl.formatMessage({
        id: "MODEL.UI.apiKeys.updatedAt"
      }),
      formatter: DatetimeColumnFormatter,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    },
    //  {
    //   dataField: "createdAt",
    //   headerClasses: "text-center",
    //   text: intl.formatMessage({
    //     id: "MODEL.UI.apiKeys.createdAt"
    //   }),

    //   classes: "text-center",
    //   editor: {
    //     type: "text"
    //   },
    //   editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
    // },
    {
      dataField: "isActive",
      headerClasses: "text-center",
      text:
        intl.formatMessage({
          id: "MODEL.UI.apiKeys.isActive",
        }),

      formatter: (cellContent, row, index) =>
        <span>{row?.isActive ? <i className="fas fa-check text-success"></i> : <></>}</span>,
      classes: "text-center",
      editor: {
        type: "text"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm")
    },
    {
      formatter: (cellContent, row, index) => (
        <span className="text-hover-primary px-2 pt-1" type="button" onClick={() => { setShowKey(row?.id, isShowAPIKey[index] ? "hide" : "show") }}>
          <i className={`${isShowAPIKey[index] ? "fas fa-eye" : "fas fa-eye-slash"} notEvent`}></i>
        </span>
      ),
      classes: "text-center notEvent",
      style: {
        width: "40px"
      },
      editorClasses: (cell, row, rowIndex, colIndex) => ("form-control-sm"),
      hidden: data?.length === 0
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <>
        {isShowDelete && <APIKeyDeleteDialog
          selectedRow={selectedRows.length > 0 ? selectedRows[0] : {}}
          show={isShowDelete}
          onHide={() => setIsShowDelete(false)}
          setIds={setIds}
          setSelectedRows={setSelectedRows}
          queryParams={queryParams}
        />}
        {isShowReset && <APIKeyResetDialog
          selectedRow={selectedRows.length > 0 ? selectedRows[0] : {}}
          show={isShowReset}
          onHide={() => setIsShowReset(false)}
          setIds={setIds}
          setSelectedRows={setSelectedRows}
          queryParams={queryParams}
        />}

        {isShowActive && <APIKeyActiveDialog
          selectedRow={selectedRows.length > 0 ? selectedRows[0] : {}}
          show={isShowActive}
          onHide={() => setIsShowActive(false)}
          setIds={setIds}
          setSelectedRows={setSelectedRows}
          queryParams={queryParams}
        />}


        <Pagination
          isLoading={listLoading}
          paginationProps={paginationProps}
        >
          <ToolkitProvider
            keyField="id"
            data={data === null ? [] : data}
            columns={columns}
            columnToggle
          >
            {
              toolkitProps => {

                toggles = toolkitProps.columnToggleProps.toggles;
                return (
                  <div>
                    <BootstrapTable
                      headerClasses="header-table-height"
                      filtersClasses="filter-table-height"
                      wrapperClasses="table-responsive table-scrollable card-scroll"
                      classes="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                      bootstrap4
                      bordered={true}
                      remote
                      rowEvents={
                        rowEvents({
                          ids: ids,
                          setIds: setIds && setIds,
                          setSelectedRows: setSelectedRows && setSelectedRows,
                          selectedRows: selectedRows,
                          arrayRemove: arrayRemove,
                        })
                      }
                      rowStyle={rowStyle({

                        ids: ids,
                        currentItems: [],
                      })}
                      noDataIndication={() =>
                        <NotFoundTableMini
                          entities={data}
                          isLoading={listLoading}
                          isNav={true}
                          type="apiKeys"
                          user={user}
                        />}

                      filter={filterFactory()}
                      filterPosition="top"
                      defaultSorted={defaultSorted}
                      onTableChange={getHandlerTableChange(
                        setQueryParams,
                        queryParams
                      )}
                      {...paginationTableProps}
                      {...toolkitProps.baseProps}
                    >
                    </BootstrapTable>
                  </div>
                )
              }
            }
          </ToolkitProvider>
          <PleaseWaitMessage entities={data} />
          <NoRecordsFoundMessage entities={data} isLoading={listLoading} />
        </Pagination>
      </>
    );
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {contentTable}
      </PaginationProvider>
    </>
  );
}

export const APIKeyTable = injectIntl(APIKeyTableIntl);