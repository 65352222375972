import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialMeterPartnerLogsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  meterPartnerShow: null,
  meterPartnerLogUpdate: null,
  meterPartnerLogEdit: null,
  meterPartnerLogClone: null,
  meterPartnerLogNew: null,
  currentMeterPartner: null,
  currentMeterPartnerLogs: [],
  meterPartnerLogForEdit: null,
  meterPartnerLogs: null,
  notification: null,
  filterCount: null,
  meterPartnerLogsHistory: [],
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const meterPartnerLogsSlice = createSlice({
  name: "meterPartnerLogs",
  initialState: initialMeterPartnerLogsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetcurrentMeterPartnerLogs: (state, action) => {
      state.currentMeterPartnerLogs = [];
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentMeterPartner = null;
      state.currentMeterPartnerLogs = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.meterForEdit = null;
    },

    // SHOW
    meterPartnerLogShow: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerShow = action.payload.meterPartnerShow;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // UPDATE
    meterPartnerLogUpdate: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.meterPartnerLogUpdate.id) {
          return action.payload.meterPartnerLogUpdate;
        }
        return entity;
      });
      state.currentMeterPartnerLogs.push(action.payload.meterPartnerLogUpdate);
    },

    // DELETE
    meterPartnerLogDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentMeterPartnerLogs = [];
    },

    // FETCHALL
    meterPartnerLogFetchIndex: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // CREATE
    meterPartnerLogCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities.unshift(action.payload.meterPartnerLog);
      state.currentMeterPartnerLogs.push(action.payload.meterPartnerLog);
    },

    // CREATE bulk
    meterPartnerLogCreatedBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.currentMeterPartnerLogs.push(action.payload.meterPartnerLogs);
    },

    // DELETE bulk
    meterPartnerLogDeletedBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentMeterPartnerLogs = [];
      state.errorType = ERROR_TYPES_OBJ.END;

    },

    // NEW bulk
    meterPartnerLogNewBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerLogsNew = action.payload.meterPartnerLogsNew;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // FETCH buildingId, month, year
    meterPartnerLogsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount;
    },

    // EDIT
    meterPartnerLogEdit: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerLogEdit = action.payload.meterPartnerLogEdit;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // CLONE
    meterPartnerLogClone: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerLogClone = action.payload.meterPartnerLogClone;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // NEW
    meterPartnerLogNew: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerLogEdit = action.payload.meterPartnerLogNew;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // FETCH all
    meterPartnerLogsFetchedAll: (state, action) => {
      const { meterPartnerLogs } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = meterPartnerLogs;
    },

    // FETCH all
    meterPartnerLogsFetchedByMeterType: (state, action) => {
      const { meterPartnerLogs } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = meterPartnerLogs;
    },
  }
}
);