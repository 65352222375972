/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isPartner, isPrivilegePartnerGroup, isPrivilegePartnerPacket } from "../../../../../../app/_helpers/PrivilegesUIHelper/Partner";
import { isAdministrator } from "../../../../../../app/_helpers/PrivilegesUIHelper/Administrator";


export function Partner() {
  const getDotActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? "#3CFF4F" : "white";
  };


  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    })
  )

  const location = useLocation();
  const getListMenuItemActive = (listUrl, hasSubmenu = false) => {
    if (listUrl.filter(url => checkIsActive(location, url)).length > 0) {
      return `${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`;
    }
    else {
      return "";
    }
  };

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  const subscriberType = user.organization.subscriberType;

  return (
    <>
      {/* Vai trò đối tác */}
      {isPartner(user) && !isAdministrator(user) && (
        <>
          {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_DASHBOARD", type: subscriberType }) &&
            (isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD")) &&
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-dashboard icon-lg text-white"></i>
                </span>
                <span className="menu-text">Bảng quản trị</span>
              </NavLink>
            </li>
          }

          {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_DASHBOARD_BUILDING", type: subscriberType }) &&
            (isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD_BUILDING")) &&
            <li
              className={`menu-item ${getMenuItemActive("/deskbase", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/deskbase/buildings">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-computer icon-md text-white"></i>
                </span>
                <span className="menu-text">Quản trị cơ sở</span>
              </NavLink>
            </li>
          }

          {isPrivilegePartnerGroup(user, "PARTNER_CUSTOMERS") &&
            <li className="menu-section mt-0">
              <h4 className="menu-text"><span className="text-menu">Quản lý khách hàng</span></h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          }

          {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_CUSTOMERS", type: subscriberType }) &&
            (isPrivilegePartnerGroup(user, "PARTNER_CUSTOMERS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive("/customers", true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/customers">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-customer icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="CUSTOMERS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          }

          {isPrivilegePartnerGroup(user, "PARTNER_CONTRACTS") &&
            <li className="menu-section mt-0">
              <h4 className="menu-text"><span className="text-menu">Quản lý hợp đồng</span></h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          }


          {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_CONTRACTS", type: subscriberType }) &&
            (isPrivilegePartnerGroup(user, "PARTNER_CONTRACTS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive("/contracts", true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/contracts">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-edit-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="CONTRACTS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          }

          {(isPrivilegePartnerGroup(user, "PARTNER_METER_LOGS") || isPrivilegePartnerGroup(user, "PARTNER_INVOICES")) &&
            <li className="menu-section mt-0">
              <h4 className="menu-text"><span className="text-menu">Quản lý hóa đơn</span></h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          }


          {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_METER_LOGS", type: subscriberType }) &&
            (isPrivilegePartnerGroup(user, "PARTNER_METER_LOGS")) &&
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive("/meterLogs", true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/meterLogs">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-time-1 icon-lg text-white"></i>
                </span>
                <span className="menu-text">
                  <FormattedMessage id="METER_LOGS.UI.Title"></FormattedMessage>
                </span>
              </NavLink>
            </li>
          }
          {(isPrivilegePartnerGroup(user, "PARTNER_INVOICES")) && (
            <li className={`menu-item menu-item-submenu  ${getListMenuItemActive(
              ["/invoices/actions", "/invoices/all"],
              false
            )}`} aria-haspopup="true" data-menu-toggle="hover">
              <span
                type="button"
                className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-file-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Hóa đơn</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_INVOICES", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_INVOICES")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/invoices/actions",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/invoices/actions">
                        <i className="menu-bullet menu-bullet-dot" >
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/invoices/actions",
                              true
                            )}`
                          }} ></span>
                        </i>
                        <span className="menu-text text-white"> <FormattedMessage id="INVOICES.UI.Title.Actions"></FormattedMessage></span>
                      </NavLink>
                    </li>
                  }

                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_INVOICES_HISTORY", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_INVOICES_HISTORY")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/invoices/all",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/invoices/all">
                        <i className="menu-bullet menu-bullet-dot" >
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/invoices/all",
                              true
                            )}`
                          }} ></span>
                        </i>
                        <span className="menu-text text-white"> <FormattedMessage id="INVOICES.UI.Title.All"></FormattedMessage></span>
                      </NavLink>
                    </li>
                  }

                </ul>
              </div>
            </li>
          )}

          {isPrivilegePartnerGroup(user, "PARTNER_REPORTS_OVERVIEW") &&
            <li className="menu-section mt-0">
              <h4 className="menu-text"><span className="text-menu">Báo cáo & thống kê</span></h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
          }

          {(isPrivilegePartnerGroup(user, "PARTNER_REPORTS_OVERVIEW")) && (
            <li className={`menu-item menu-item-submenu  ${getMenuItemActive(
              "/reports/overview",
              true
            )}`} aria-haspopup="true" data-menu-toggle="hover">
              <span
                type="button" className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-diagram icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo tài chính</span>
                <i className="menu-arrow text-white"></i>
              </span>

              <div className="menu-submenu">
                <ul className="menu-subnav">

                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_REPORTS_OVERVIEW_REVENUE_EXPENSES", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_REPORTS_OVERVIEW_REVENUE_EXPENSES")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/overview/receipt-payment",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/receipt-payment">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/receipt-payment",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Doanh thu - chi phí</span>
                      </NavLink>
                    </li>
                  }
                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_REPORTS_FINANCIALS_SERVICES", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_REPORTS_FINANCIALS_SERVICES")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/overview/service-building",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/service-building">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/service-building",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Theo dịch vụ</span>
                      </NavLink>
                    </li>
                  }
                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_REPORTS_FINANCIALS_SERVICES_BUILDINGS", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_REPORTS_FINANCIALS_SERVICES_BUILDINGS")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/overview/service-floor",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/overview/service-floor">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/overview/service-floor",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Theo dịch vụ - Tòa nhà</span>
                      </NavLink>
                    </li>
                  }
                </ul>
              </div>
            </li>
          )}

          {(isPrivilegePartnerGroup(user, "PARTNER_REPORTS_ASSETS")) && (
            < li className={`menu-item menu-item-submenu  ${getMenuItemActive(
              "/reports/asset",
              true
            )}`} aria-haspopup="true" data-menu-toggle="hover">
              <span
                type="button"
                className="menu-link menu-toggle">
                <span className="svg-icon menu-icon">
                  <i className="flaticon-file-2 icon-lg text-white"></i>
                </span>
                <span className="menu-text">Báo cáo tài sản</span>
                <i className="menu-arrow text-white"></i>
              </span>
              <div className="menu-submenu">
                <ul className="menu-subnav">
                  {isPrivilegePartnerPacket({ namePrivilege: "PARTNER_REPORTS_ASSETS_BUILDINGS", type: subscriberType }) &&
                    (isPrivilegePartnerGroup(user, "PARTNER_REPORTS_ASSETS_BUILDINGS")) &&
                    <li className={`menu-item menu-item-submenu menu-item-children ${getMenuItemActive(
                      "/reports/asset/units",
                      true
                    )}`} aria-haspopup="true">
                      <NavLink className="menu-link menu-toggle" to="/reports/asset/units">
                        <i className="menu-bullet menu-bullet-dot">
                          <span style={{
                            backgroundColor: `${getDotActive(
                              "/reports/asset/units",
                              true
                            )}`
                          }}></span>
                        </i>
                        <span className="menu-text text-white">Phân bổ tài sản</span>
                      </NavLink>
                    </li>
                  }
                </ul>
              </div>
            </li>
          )}
        </>
      )}
    </>
  );
}
