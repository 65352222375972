import React from 'react';
import Select from 'react-select';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getColorValid = (valid, isFocused) => {
  if (valid.includes('is-valid')) {
    return '#007c7e !important'
  }
  else if (valid.includes('is-invalid'))
    return '#F64E60 !important'
  else {
    if (isFocused) {
      return '#00CFA7 !important'
    }
    else {
      return '#E4E6EF !important'
    }
  }
}




export function SelectBoxField(props) {
  const {
    field,
    form,
    options,
    checkYup = true,
    label,
    isMulti = false,
    placeholder = "-- Chọn --",
    disabled,
    required = false,
    handleFieldChange,
    formProps,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type,
    groupHeader,
    styleText,
    styleMenu
  } = props;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: 120,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
    }),

    menu: (provided, state) => {
      return {
        ...provided,
        width: styleMenu.width,
        zIndex: 99999
      }
    },
    menuPortal: (provided, state) => {
      return {
        ...provided,
        zIndex: 99999
      }
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },

  }


  const { name, value } = field;
  const { errors, touched } = form;


  const selectedOption = options && options.filter(option => value !== null && (isMulti ? value.includes(option.value) : option && option.value === value));

  const onChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : selectedOption;

    const multiValue = [];
    isMulti && selectedOption && selectedOption.map((item) => multiValue.push(item.value));

    const changeEvent = {
      target: { name: name, value: selectedValue }
    };

    const changeMultiEvent = {
      target: { name: name, value: multiValue }
    };

    field.onChange(changeEvent);
    form.setFieldTouched(field.name, true);

    form.handleChange(isMulti ? changeMultiEvent : changeEvent);
    handleFieldChange(isMulti ? changeMultiEvent : changeEvent, formProps);
  }

  const Option = props => {
    const { innerProps, innerRef, data, isFocused } = props;
    return (
      <>
        {innerProps.id.includes('option-0') &&
          (<>
            <div className='d-flex' style={{ borderBottom: "1px solid" }}>
              {groupHeader && groupHeader.map((item, index) => {
                return (
                  <>
                    {index === 0 ?
                      (
                        <div className={`${styleText[index]} m-3`} style={{ width: "10px" }}>
                          <b>{item}</b>
                        </div>
                      )
                      :
                      (
                        <div className={`${styleText[index]} col my-3`}>
                          <b>{item}</b>
                        </div>
                      )
                    }
                  </>
                )
              })}
            </div>
          </>)}
        <article ref={innerRef} {...innerProps} className={'custom-option'}>
          <div
            type="button"
            className="d-flex"
            style={{
              backgroundColor: (value === data.value) ? '#007bff' : (isFocused && "#deebff"),
              borderBottom: data.label[0] == options?.length ? "0px" : "1px solid",
              borderColor: "#0123"
            }}
          >
            {data.label && data.label.map((item, index) => (
              <>
                {index === 0 ? (
                  <span className={`${(value === data.value) && "text-white"} ${styleText[index]} m-3`} style={{ width: "10px" }}>{item}</span>
                )
                  :
                  (
                    <span className={`col ${(value === data.value) && "text-white"} ${styleText[index]} my-3`}>{(item === "null" || item === "undefined") ? "" : item}</span>
                  )
                }
              </>
            ))}
          </div>
        </article >
      </>
    );
  };

  const getOptionLabel = option => option && option.label && option.label[1];

  const getOptionValue = option => option && option.value;


  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      <Select
        id={name}
        {...field}
        menuPlacement="auto"
        menuPosition="fixed"
        value={selectedOption}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        noOptionsMessage={() => "Không có dữ liệu"}
        className={checkYup ? "react-select" : ""}
        styles={customStyles}
        menuPortalTarget={document.body}
        components={{ Option }}
        classNamePrefix={getFieldCSSClasses(touched[field.name], errors[field.name])}
        isOptionDisabled={(option) => option.isdisabled}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}