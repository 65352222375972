import axios from "axios";

export const ORGANIZATIONS_URL = "/organizations";

// CREATE =>  POST: add a new organization to the server
export function createOrganization(organization) {
  return axios.post(ORGANIZATIONS_URL, organization);
}

// READ
export function getAllOrganizations() {
  return axios.get(ORGANIZATIONS_URL);
}

export function getInitOrganization() {
  return axios.get(`${ORGANIZATIONS_URL}/create`);
}

export function getOrganizationCloneById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}/clone`);
}

export function getOrganizationById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}`);
}
export function getOrganizationEditById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}/edit`);
}

export function getOrganization(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}`);
}


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrganizations(queryParams) {
  return axios.post(`${ORGANIZATIONS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateOrganization(organization, organizationId) {
  return axios.put(`${ORGANIZATIONS_URL}/${organizationId}`, organization);
}

// UPDATE Status
export function updateStatusForOrganizations(ids, status) {
  return axios.post(`${ORGANIZATIONS_URL}/status/update`, {
    ids,
    status,
  });
}

// UPDATE IsActive
export function updateIsActiveForOrganizations(ids, isActive) {
  return axios.post(`${ORGANIZATIONS_URL}/isActives/update`, {
    ids,
    isActive,
  });
}

// DELETE => delete the organization from the server
export function deleteOrganization(organizationId) {
  return axios.delete(`${ORGANIZATIONS_URL}/${organizationId}`);
}

// DELETE Organizations by ids
export function deleteOrganizations(ids) {
  return axios.delete(`${ORGANIZATIONS_URL}/bulk`, { data: { ids } });

}

export function configFunctionEdit(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}/configFunctions/edit`);
};

export function configFunctionUpdate(organizationId, configFunctions) {
  return axios.put(`${ORGANIZATIONS_URL}/${organizationId}/configFunctions`, configFunctions);
};
