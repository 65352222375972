import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialUnitTypesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  unitTypeForEdit: null,
  unitTypeForShow: null,
  lastError: null,
  filterCount: null,
  currentUnitType: null,
  currentUnitTypes: [],
  notification: null,
  error: null,
  isError: null,
};
export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const unitTypesSlice = createSlice({
  name: "unitTypes",
  initialState: initialUnitTypesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentUnitType = null;
      state.currentUnitTypes = [];
    },

    resetModalShow: (state, action) => {
      state.errorType = null;
      state.isError = null;
      state.unitTypeForShow = null;
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.unitTypeForEdit = null;
    },

    resetCurrentUnitTypes: (state, action) => {
      state.currentUnitType = null;
      state.currentUnitTypes = [];
    },

    // getUnitTypeById
    unitTypeFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.unitTypeForEdit = action.payload.unitTypeForEdit;
      state.error = null;
      state.isError = null;
    },
    //show
    getUnitType: (state, action) => {
      state.actionsLoading = false;
      state.unitTypeForShow = action.payload.unitTypeForShow;
      state.error = null;
      state.isError = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },
    // findAllUnitTypes
    unitTypesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.isError = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.currentUnitType = null;
    },
    // findUnitTypes
    unitTypesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;
      state.isError = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.currentUnitType = null;
    },
    // findParentUnitTypes
    unitTypesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.isError = null;
      state.parents = parents;
      state.totalCount = totalCount;
      state.currentUnitType = null;
    },
    // createUnitType
    unitTypeCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = null;
      state.entities.unshift(action.payload.unitType);
      state.currentUnitType = action.payload.unitType;
      state.currentUnitTypes.push(action.payload.unitType);
    },
    // updateUnitType
    unitTypeUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.unitType.id) {
          return action.payload.unitType;
        }
        return entity;
      });
      state.currentUnitType = action.payload.unitType;
      state.currentUnitTypes.push(action.payload.unitType);
    },
    // deleteUnitType
    unitTypeDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentUnitType = null;
      state.currentUnitTypes = [];
    },
    // deleteUnitTypes
    unitTypesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentUnitTypes = [];
    },
    // unitTypesUpdateState
    unitTypesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.isError = null;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
