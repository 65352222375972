import Axios from "axios";
import "lightgallery.js/dist/css/lightgallery.css";
import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
    LightgalleryItem, LightgalleryProvider
} from "react-lightgallery";
import { DatetimeColumnFormatter, formatBytes, handleDownloadFileDocument } from "../../../../../../_bloomgoo/_helpers";


export const AttachmentTable = (props) => {
    const { formProps, arrayHelpers, disabled = false } = props;

    const PhotoItem = ({ image, thumb, group, downloadUrl }) => {
        return (
            <LightgalleryItem group={group} src={image} thumb={thumb} downloadUrl={downloadUrl}>
                <i className="far fa-eye text-hover-primary mt-1"></i>
            </LightgalleryItem>
        )
    };

    const [pathDocViewer, setPathDocViewer] = useState();

    return (
        <>
            <Modal
                size="xl"
                show={pathDocViewer}
                onHide={() => setPathDocViewer()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body>
                    <iframe src={`${pathDocViewer}`} height="800px" width="100%" title="Iframe Example"></iframe>

                </Modal.Body>

            </Modal>


            <div className="react-bootstrap-table table-responsive">
                <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                    <thead>
                        <tr>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.documentOriginalName"></FormattedMessage></th>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.documentSize"></FormattedMessage></th>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.documentCreatedAt"></FormattedMessage></th>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.documentUserCreated"></FormattedMessage></th>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.download"></FormattedMessage></th>
                            <th className="text-center"><FormattedMessage id="MODEL.UI.attachments.preview"></FormattedMessage></th>
                            {!disabled && formProps.values && formProps.values.attachments && formProps.values.attachments.length > 0 && (<th className="text-center"></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <LightgalleryProvider>
                            {
                                formProps.values && formProps.values.attachments && formProps.values.attachments.map((row, rowIndex) => (
                                    <>
                                        <tr>
                                            <td className="text-left">
                                                {row.document && row.document.documentOriginalName}
                                            </td>

                                            <td className="text-right">
                                                {formatBytes(row.document && row.document.documentSize)}
                                            </td>
                                            <td className="text-center">
                                                {DatetimeColumnFormatter(row.document && row.document.createdAt)}
                                            </td>
                                            <td className="text-right">
                                                {row.document && row.document.createdUser && row.document.createdUser.fullName}
                                            </td>
                                            <td className="text-center" >
                                                <i
                                                    type="button"
                                                    className="flaticon-download text-hover-primary"
                                                    onClick={() => handleDownloadFileDocument(row.document && row.document.documentCode, row.document && row.document.documentOriginalName, row.document && row.document.documentExtension)}
                                                >
                                                </i>
                                            </td>
                                            <td className="text-center">

                                                {
                                                    row.document?.documentFormat.includes('image/') ?
                                                        <PhotoItem
                                                            key={rowIndex}
                                                            image={`${Axios.defaults.cdnURL}/${row.document?.documentPath}`}
                                                            group="attachmentTable"
                                                            downloadUrl={`${Axios.defaults.baseURL}/public/documents/${row.document?.documentCode}/download`}
                                                        />

                                                        :
                                                        <span
                                                            onClick={() => setPathDocViewer(`${['doc', 'docx', 'xls', 'xlsx'].includes(row?.document?.documentExtension) ? "https://docs.google.com/viewerng/viewer?url=" : ""}${Axios.defaults.cdnURL}/${row.document?.documentPath}${['doc', 'docx', 'xls', 'xlsx'].includes(row?.document?.documentExtension) ? "&embedded=true" : ""}`)}
                                                            target="blank"
                                                        >
                                                            <i className="far fa-eye text-hover-primary mt-1"></i>
                                                        </span >
                                                }
                                            </td>
                                            {!disabled &&
                                                <td className="text-center">
                                                    <span
                                                        type="button"
                                                        id={`documents[${rowIndex}].actionDelete`}
                                                        className="btn btn-icon-danger btn-sm text-center"
                                                        onClick={() => arrayHelpers.remove(rowIndex)}
                                                    >
                                                        <i className="flaticon-cancel pr-0"></i>
                                                    </span>
                                                </td>
                                            }
                                        </tr>
                                    </>
                                ))}
                        </LightgalleryProvider>
                    </tbody>
                </Table>
            </div>
        </>
    )
}