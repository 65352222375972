/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

export function SelectShowOption(props) {
  const {
    field,  // props field
    options,  // Danh sách option
    listOptions, // Danh sách option checkbox
    dropdown = null,
    label = "",  /// label
    isMulti = true,  // Chọn nhiều hay k
    placeholder = "-- Chọn --", // placeholder
    required = false,  // có phải trường bắt buộc hay k
    formProps,  // props của form
    withFeedbackLabel = true, // có hiển thị label feed back yup hay k
    customFeedbackLabel, // string label feed back yup
    type, // loại input
    classNameLabel,
    valueAll,
  } = props;

  const { name, value } = field;
  // const { errors, touched } = form;

  const [_options, setOptions] = useState();
  const [_listOptions, setListOptions] = useState();
  const [isShowCheckAll, setIsShowCheckAll] = useState(true)
  useEffect(() => {
    setOptions(options)
  }, [options]);

  useEffect(() => {
    setListOptions(listOptions)
  }, [listOptions]);




  const handleSetConfig = (obj) => {
    if (_options.some((ele) => ele.value === obj.value)) { }
    else {
      let aOptions = JSON.parse(JSON.stringify(_options))
      aOptions.unshift(obj);
      aOptions.pop();

      setOptions(aOptions)
    }
  };

  const selectMultiValue = (nameChild, valueChild) => {
    let _value = JSON.parse(JSON.stringify(value));
    if (nameChild === "all") {
      setIsShowCheckAll(true)
      _listOptions && _listOptions.map((item, index) => {
        if (valueChild === true) {
          _value = valueAll
        }

      })
    }

    else {
      setIsShowCheckAll(false)
      if (getCheckedAll()) {
        _listOptions && _listOptions.map((item, index) => {
          _value[item.name] = []
        })
      }

      if (_value[nameChild].some((ele) => ele === valueChild)) {
        _value[nameChild] = _value[nameChild].filter(ele => ele !== valueChild);
      }
      else {
        _value[nameChild].push(valueChild)
      }
    }
    formProps.setFieldValue(`${name}`, _value)
  };

  const selectValueIsMulti = (nameChild, valueChild) => {
    let _value = JSON.parse(JSON.stringify(value));
    if (nameChild === "all") {
      setIsShowCheckAll(true)
      _listOptions && _listOptions.map((item, index) => {
        if (valueChild === true) {
          _value = valueAll
        }

      })
    }
    else if (_value[nameChild].length === 1 && _value[nameChild].includes(valueChild)) {

    }
    else {
      _value[nameChild] = [valueChild]
    }
    formProps.setFieldValue(`${name}`, _value)
  };

  const getCheckedAll = () => {
    let isCheckAll = false;
    let _value = JSON.parse(JSON.stringify(value))
    _listOptions && _listOptions.map((item, index) => {
      let array1 = _value[item.name],
        set = new Set(valueAll[item.name]);
      let result = array1.map(x => set.has(x))
      if (result.filter((ele) => ele === true).length === valueAll[item.name].length) {
        isCheckAll = true
      }
      else {
        isCheckAll = false
      }

    })
    return isCheckAll;
  };

  return (
    <>
      {type !== "checkbox" && (
        <>
          {label && <label className={classNameLabel}>{label} {required && <span className="required">*</span>}</label>}
          <div name={name} id={name} className='btn-group-vertical border rounded' style={{
            width: "inherit"
          }}>
            {_options ?
              _options.map((item, index) => {
                return (
                  <>
                    <button
                      key={item.value}
                      type="button"
                      className={`btn btn-hover-bg-success ${value === item.value && "active"} btn-hover-text-white text-left py-2`}
                      value={item.value}
                      onClick={() => formProps.setFieldValue(`${name}`, item.value)}
                    >
                      {item.label}
                    </button>

                    {dropdown && index + 1 === _options?.length &&
                      <>
                        <button
                          className="btn btn-hover-bg-white  text-left pb-0 pt-0 "
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className='d-flex justify-content-between align-items-center'>
                            <span className='text-secondary text-over py-2'>{dropdown.filter((ele) => ele.value === value)[0]?.label}</span><i className='fas fa-caret-down text-primary ml-1'></i>
                          </div>

                        </button>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          style={{
                            maxHeight: "300px",
                            overflow: "overlay",
                            width: "inherit",
                          }}>

                          {dropdown && dropdown.map((row) => {

                            return (
                              <span
                                className={`dropdown-item ${row.value === value && "active"}`}
                                key={row.value}
                                onClick={() => {
                                  formProps.setFieldValue(`${name}`, row.value);
                                  handleSetConfig(row)
                                }}
                              >
                                {row.label}
                              </span>
                            )
                          })}
                        </div>
                      </>
                    }

                  </>
                )
              })
              :
              <button className="btn btn-hover-primary border-0 text-center" type="button" disabled>
                {"Không có dữ liệu"}
              </button>
            }
          </div>
        </>
      )}

      {type === "checkbox" && (
        isMulti ?
          <>
            {label && <label className={classNameLabel}>{label} {required && <span className="required">*</span>}</label>}
            <div name={name} id={name} className='btn-group-vertical border rounded' style={{
              width: "inherit"
            }}>
              {_listOptions ?
                _listOptions.map((item, index) => {
                  return (
                    <>
                      {
                        index === 0 &&
                        <button
                          key={"*"}
                          type="button"
                          className={`btn text-left  pl-2 py-2`}
                          onClick={() => !isShowCheckAll && selectMultiValue("all", !getCheckedAll())}
                        >
                          {<i className={`mr-1 ${(getCheckedAll() && isShowCheckAll) ? "text-success" : "text-white"}`}>&#10003;</i>}{"Tất cả"}
                        </button>
                      }
                      {
                        item.value && item.value.map((row, rowIndex) => {
                          return (
                            <>
                              <button
                                key={row.value}
                                type="button"
                                className={`btn text-left  pl-2 text-over py-0 pb-2`}
                                value={row.value}
                                onClick={() => selectMultiValue(item.name, row.value)}
                              >
                                {<i className={`${(value[item.name].includes(row.value) && !isShowCheckAll) ? "text-success" : "text-white"} mr-1`}>&#10003;</i>}{row.label}
                              </button>
                            </>
                          )
                        })
                      }


                    </>
                  )

                })
                :
                <button className="btn btn-hover-primary border-0 text-left" type="button" disabled>
                  {"Không có dữ liệu"}
                </button>
              }
            </div>
          </>
          :
          <>
            {label && <label className={classNameLabel}>{label} {required && <span className="required">*</span>}</label>}
            <div name={name} id={name} className='btn-group-vertical border rounded' style={{
              width: "inherit"
            }}>
              {_listOptions ?
                _listOptions.map((item, index) => {
                  return (
                    <>
                      {
                        index === 0 &&
                        <button
                          key={"*"}
                          type="button"
                          className={`btn text-left  pl-2 py-2`}
                          onClick={() => selectMultiValue("all", !getCheckedAll())}
                        >
                          {<i className={`mr-1 ${(getCheckedAll()) ? "text-success" : "text-white"}`}>&#10003;</i>}{"Tất cả"}
                        </button>
                      }
                      {
                        item.value && item.value.map((row, rowIndex) => {
                          return (
                            <>
                              <button
                                key={row.value}
                                type="button"
                                className={`btn text-left  pl-2 text-over py-0 pb-2`}
                                value={row.value}
                                onClick={() => selectValueIsMulti(item.name, row.value)}
                              >
                                {<i className={`${(value[item.name].includes(row.value) && !getCheckedAll()) ? "text-success" : "text-white"} mr-1`}>&#10003;</i>}{row.label}
                              </button>
                            </>
                          )
                        })
                      }


                    </>
                  )

                })
                :
                <button className="btn btn-hover-primary border-0 text-left" type="button" disabled>
                  {"Không có dữ liệu"}
                </button>
              }
            </div>
          </>
      )}


      {withFeedbackLabel && (
        <FieldFeedbackLabel
          // error={errors[field.name]}
          // touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
