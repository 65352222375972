import React from 'react';
import Select, { components } from 'react-select';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getColorValid = (valid, isFocused) => {
  if (valid.includes('is-valid')) {
    return '#007c7e !important'
  }
  else if (valid.includes('is-invalid'))
    return '#F64E60 !important'
  else {
    if (isFocused) {
      return '#00CFA7 !important'
    }
    else {
      return '#E4E6EF !important'
    }
  }
}




export function TagField(props) {
  const {
    field,
    form,
    options,
    checkYup = true,
    label,
    isMulti = false,
    placeholder = "-- Chọn --",
    icon,
    autoComplete = "on",
    disabled,
    minWidth = 120,
    required = false,
    handleFieldChange,
    formProps,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type,
    handleItem
  } = props;

  let textFilterInput;
  let isExist = true;
  let currentOptions = [];

  const filterExtendStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
      flexDirection: "row-reverse"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#fff'
    })
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth,
      borderColor: getColorValid(state.selectProps.classNamePrefix, state.isFocused),
      boxShadow: `0 0 0 0px ${getColorValid(state.selectProps.classNamePrefix, state.isFocused)}`,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 99999
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#b8b8c5',
      }
    },
  }

  const { name, value } = field;
  const { errors, touched } = form;

  const selectedOption = options && options.filter(option => value !== null && (isMulti ? value.includes(option.value) : option && option.value === value));

  const onChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : selectedOption;

    const multiValue = [];
    isMulti && selectedOption && selectedOption.map((item) => multiValue.push(item.value));

    const changeEvent = {
      target: { name: name, value: selectedValue }
    };

    const changeMultiEvent = {
      target: { name: name, value: multiValue }
    };

    field.onChange(changeEvent);
    form.setFieldTouched(field.name, true);

    form.handleChange(isMulti ? changeMultiEvent : changeEvent);
    handleFieldChange(isMulti ? changeMultiEvent : changeEvent, formProps);
  }


  const filterOption = ({ label, value, data }, inputValue) => {
    textFilterInput = inputValue;
    isExist = Boolean(options.filter(ele => ele.label === inputValue).length);
    currentOptions = options.filter(ele => ele.label.includes(inputValue));
    const otherKey = options.filter(
      opt => opt.label === label && opt.label.includes(inputValue)
    )

    if (inputValue === "") {
      return label.includes(inputValue) || otherKey.length > 0;
    }
    else return (label.includes(inputValue) && value !== '*') || otherKey.length > 0;
  };

  const Option = (props) => {
    const { innerProps, innerRef, data, isFocused } = props;
    return (
      <article ref={innerRef} {...innerProps} className={'custom-option'}>
        <div type="button" className="d-flex" style={{ backgroundColor: (value === data.value) ? '#2684ff' : (isFocused && "#deebff"), borderColor: "#0123" }}>
          <div className={`col ${(value === data.value) && "text-white"} my-3 d-flex justify-content-between`}>
            <div>{(data.label === "null" || data.label === "undefined") ? "" : data.label}</div>
            <div>{isFocused && (
              <>
                <i className='fas fa-edit text-hover-success mr-2 icon-nm' onClick={() => handleItem('edit', data.value)} />
                <i className='flaticon-delete text-hover-danger icon-nm' onClick={() => handleItem('delete', data.value)}></i>
              </>
            )}</div>
          </div>
        </div>
      </article >
    );
  };

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      <Select
        id={name}
        {...field}
        menuPlacement="auto"
        value={selectedOption}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        autoComplete={autoComplete}
        noOptionsMessage={() =>
          <span
            type="button"
            onClick={() => handleItem('new', textFilterInput)}
            className="text-dark text-hover-success"
          >
            <i className='flaticon2-add mr-1 text-dark text-hover-success icon-nm'></i><span>{`Thêm mới ${textFilterInput ? `"${textFilterInput}"` : "dữ liệu"}`}</span>
          </span>
        }
        className={checkYup ? "react-select" : ""}
        styles={icon ? filterExtendStyles : customStyles}
        menuPortalTarget={document.body}
        // components={icon ? { DropdownIndicator } : { MyOption }}
        components={{ Option }}
        classNamePrefix={getFieldCSSClasses(touched[field.name], errors[field.name])}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        filterOption={filterOption}
        isOptionDisabled={(option) => option.isdisabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          placeholder={placeholder}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
