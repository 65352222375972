import axios from "axios";

export const PROVINCES_URL = "/provinces";

// CREATE =>  POST: add a new province to the server
export function createProvince(province) {
  return axios.post(PROVINCES_URL, province);
}

// READ
export function getAllProvinces() {
  return axios.get(PROVINCES_URL);
}

export function getInitProvince() {
  return axios.get(`${PROVINCES_URL}/create`);
}

export function getProvinceCloneById(provinceId) {
  return axios.get(`${PROVINCES_URL}/${provinceId}/clone`);
}
export function getProvinceById(provinceId) {
  return axios.get(`${PROVINCES_URL}/${provinceId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProvinces(queryParams) {
  return axios.post(`${PROVINCES_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateProvince(province) {
  return axios.put(`${PROVINCES_URL}/${province.id}`, province);
}

// UPDATE Status
export function updateStatusForProvinces(ids, status) {
  return axios.post(`${PROVINCES_URL}/updateStatusForProvinces`, {
    ids,
    status,
  });
}

// DELETE => delete the province from the server
export function deleteProvince(provinceId) {
  return axios.delete(`${PROVINCES_URL}/${provinceId}`);
}

// DELETE Provinces by ids
export function deleteProvinces(ids) {
  return axios.delete(`${PROVINCES_URL}/bulk`, { data: { ids } });

}
