import axios from "axios";

export const EMAIL_TEMPLATES_URL = "/emailTemplates";

// SHOW: /api/v2/emailTemplates/{id}
export function showEmailTemplateById(id) {
  return axios.get(`${EMAIL_TEMPLATES_URL}/${id}/show`);
};

// UPDATE: /api/v2/emailTemplates/{id}
export function updateEmailTemplateById(emailTemplate, id) {
  return axios.put(`${EMAIL_TEMPLATES_URL}/${id}, `, emailTemplate);
};

// DELETE: /api/v2/emailTemplates/{id}
export function deleteEmailTemplateById(id) {
  return axios.delete(`${EMAIL_TEMPLATES_URL}/${id}`);
};

// GET: /api/v2/emailTemplates
export function indexEmailTemplate() {
  return axios.get(`${EMAIL_TEMPLATES_URL}`);
};



// POST: /api/v2/emailTemplates
export function emailTemplate(emailTemplate) {
  return axios.post(`${EMAIL_TEMPLATES_URL}`, emailTemplate);
};

// POST: /api/v2/emailTemplates/find
export function findEmailTemplates(queryParams) {
  return axios.post(`${EMAIL_TEMPLATES_URL}/find`, queryParams);
};

// GET: /api/v2/emailTemplates/{id}/edit
export function editEmailTemplateById(id) {
  return axios.get(`${EMAIL_TEMPLATES_URL}/${id}/edit`);
};

// GET: /api/v2/emailTemplates/{id}/clone
export function cloneEmailTemplateById(id) {
  return axios.get(`${EMAIL_TEMPLATES_URL}/${id}/clone`);
};

// GET: /api/v2/emailTemplates/organization/{organizationId}
export function findAllEmailTemplates(organizationId) {
  return axios.get(`${EMAIL_TEMPLATES_URL}/organization/${organizationId}`);
};

// GET: /api/v2/emailTemplates/create
export function createEmailTemplate() {
  return axios.get(`${EMAIL_TEMPLATES_URL}/create`);
};

// DELETE: /api/v2/emailTemplates/bulk
export function deleteBulkEmailTemplate(ids) {
  return axios.delete(`${EMAIL_TEMPLATES_URL}/bulk`, { data: { ids } });
};
