// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actionsDocuments from "../../../../Documents/_redux/documents/documentsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./DocumentsUIHelper";
import * as commonUIHelpers from "../../../../../_helpers/_CommonUIHelpers";
import {
  NotFoundTable,
  Pagination,
}
  from "../../../../../../_bloomgoo/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  getSelectOnlyRow,
} from "../../../../../../_bloomgoo/_helpers";
import { useDocumentsUIContext } from "./DocumentsUIContext";
import { FormattedMessage, injectIntl } from "react-intl";
import * as columnFormatters from "./column-formatters";
import { HeaderColumnFormatter, OrderColumnFormatter } from "../../../../../_helpers/column-formatters";

export function DocumentsTableIntl(props) {
  const { intl } = props;
  const uIContext = useDocumentsUIContext();
  const uIProps = useMemo(() => {
    return {
      queryParams: uIContext && uIContext.queryParams,
      setQueryParams: uIContext && uIContext.setQueryParams,
      id: uIContext && uIContext.id,
      setId: uIContext && uIContext.setId,
      ids: uIContext && uIContext.ids,
      setIds: uIContext && uIContext.setIds,
      selectedRows: uIContext && uIContext.selectedRows,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
    };
  }, [uIContext]);

  // Documents Redux state
  // Getting current state of products list from store (Redux)

  const { currentState } = useSelector(
    (state) => ({ currentState: state.documents }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    uIProps.setIds && uIProps.setIds([]);
    dispatch(actionsDocuments.fetchDocuments(uIProps.queryParams));
  }, [uIProps.queryParams, dispatch]);

  // uIProps.currentDocuments && uIProps.currentDocuments.map(())

  const columns = [
    {
      dataField: "id",
      headerClasses: "text-center",
      text: "#",
      sort: true,
      sortCaret: sortCaret,
      formatter: OrderColumnFormatter,
      classes: "text-center",
    },
    {
      dataField: "documentOriginalName",
      headerClasses: "text-center",

      text:
        intl.formatMessage({
          id: "MODEL.UI.documents.documentOriginalName",
        }),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.NameColumnFormatter,
      classes: "text-left",
    },
    {
      dataField: "documentCode",
      headerClasses: "text-center",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.CodeColumnFormatter,
      classes: "text-right",
      style: {
        width: "50px"
      }
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: commonUIHelpers.sizePerSmallPageList,
    sizePerPage: uIProps.queryParams.pageSize,
    page: uIProps.queryParams.pageNumber,
  };

  return (
    <>
      {entities && (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                  bordered={false}
                  bootstrap4
                  remote
                  keyField="id"
                  data={entities === null ? [] : entities}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(
                    uIProps.setQueryParams,
                    uIProps.queryParams
                  )}
                  selectRow={getSelectOnlyRow({
                    entities,
                    ids: uIProps.ids,
                    setIds: uIProps.setIds && uIProps.setIds,
                    selectedRows: uIProps.selectedRows,
                    setSelectedRows: uIProps.setSelectedRows && uIProps.setSelectedRows,
                  })}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities} />
                  <NoRecordsFoundMessage entities={entities} isLoading={listLoading} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
}
export const DocumentsTable = injectIntl(DocumentsTableIntl);
