import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialContractsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  entitiesPreExpired: [],
  parents: [],
  contractsOfUnitId: [],
  contractForEdit: null,
  contractForShow: null,
  fileDocx: null,
  notification: null,
  currentContracts: [],
  contractExtensionForEdit: null,
  filterCount: null,
  errorType: null,
  error: null,
  contractsCount: 1,
  contractEmails: [],
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: initialContractsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        // state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },
    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
        state.notification = null;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
        state.notification = null;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        // state.entities = [];
      } else {
      }
    },

    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentContract = null;
      state.currentContracts = [];
      state.entities = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.contractForEdit = null;
      state.contractExtensionForEdit = null;
    },

    resetCurrentContracts: (state, action) => {
      state.currentContract = null;
      state.currentContracts = [];
    },

    resetModalEmail: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.listLoading = null;
      state.actionsLoading = null;
      state.contractEmails = null;
    },

    resetModalPreExpired: (state, action) => {
      state.entitiesPreExpired = [];
      state.totalCountPreExpired = 0;
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },
    // getContractById
    contractFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.contractForEdit = action.payload.contractForEdit;
      state.currentContracts = [];
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },

    contractsFileFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { fileDocx } = action.payload;
      state.error = null;

      state.listLoading = false;
      state.fileDocx = fileDocx;
    },

    //show
    contractShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.contractForShow = action.payload.contractForShow;
      state.error = null;
      state.currentContracts = [];
      state.errorType = ERROR_TYPES_OBJ.MIDDLE

    },
    // findAllContracts
    contractsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // findContracts
    contractsPreExpiredFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesPreExpired =entities;
      state.filterCount.totalPreExpired = totalCount;
    },

    contractsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    contractsFetchedByUnitId: (state, action) => {
      const { entities } = action.payload;
      state.contractsOfUnitId = entities;
      state.actionsLoading = false;
    },

    // findParentContracts
    contractsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.parents = parents;
      state.totalCount = totalCount;
    },



    // createContract
    contractCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.END;
      state.entities.unshift(action.payload.contract);
      state.currentContracts.push(action.payload.contract);

    },
    // updateContract
    contractUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.END; //cho ra

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.contract.id) {
          return action.payload.contract;
        }
        return entity;
      });
      state.currentContracts.push(action.payload.contract);
    },

    // deleteContract
    contractDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentContracts = [];
    },


    // deleteContracts
    contractsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;


      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentContracts = [];
    },

    // buildingsUpdateStatus
    contractsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.errorType = ERROR_TYPES_OBJ.END;
      state.currentContracts = [];
    },

    contractExtensionFetched: (state, action) => {
      state.actionsLoading = false;
      state.contractExtensionForEdit = action.payload.contractExtensionForEdit;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    contractExtensionDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE2;
      state.actionsLoading = false;
      state.error = null;
    },


    contractExtension: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.contractId) {
          entity.oldEndTime = action.payload.contractExtension.oldEndTime;
        }
        return entity;
      });
      state.errorType = ERROR_TYPES_OBJ.MIDDLE2;
    },

    contractsCount: (state, action) => {
      state.contractsCount = action.payload.contractsCount;
      state.actionsLoading = false;
    },
    countContractsClean: (state, action) => {
      state.contractsCount = 1;
    },
    swapUnitUpdate: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
    },

    contractEmailsFetched: (state, action) => {
      const { contractEmails } = action.payload;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.error = null;
      state.contractEmails = contractEmails;
    },

    contractEmailsUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.END; //cho ra
      state.actionsLoading = false;
    },
  },
});


