/* eslint-disable array-callback-return */
import Axios from "axios";


export function uploadMultiFile(selectedFile) {
  let listFile = [];
  for (let i = 0; i < selectedFile.length; i++) {
    listFile.push(selectedFile[i])
  }

  let formData = new FormData()

  listFile.forEach(file => {
    formData.append("files", file)
  })
  return formData;
};


export function uploadAttachmentDataPost(selectedFile, data, id) {
  let listFile = [];
  if (selectedFile !== null && selectedFile.length > 0) { //
    for (let i = 0; i < selectedFile.length; i++) {
      listFile.push(selectedFile[i]);
    }
  }

  let formData = new FormData();

  formData.append("id", JSON.stringify(id))
  if (listFile !== null && listFile.length > 0) {
    listFile.forEach(file => {
      formData.append("attachments", file)
    });
  } else {
    formData.append("attachments", selectedFile)
  }


  formData.append("data", JSON.stringify(data))

  return formData;
};

export function uploadAttachmentData(selectedFile, data) {
  // CHI XU LY NOI HAM
  let listFile = [];
  // VOO NGHIA =>>>>>>>>
  if (selectedFile !== null && selectedFile.length > 0) { //
    for (let i = 0; i < selectedFile.length; i++) {
      listFile.push(selectedFile[i]);
    }
  }

  let formData = new FormData();


  if (listFile !== null && listFile.length > 0) {
    listFile.forEach(file => {
      formData.append("attachments", file)
    });
  } else {
    formData.append("attachments", selectedFile)
  }
  formData.append("data", JSON.stringify(data))
  return formData;
};

export function uploadFileData(selectedFile, data) {
  // CHI XU LY NOI HAM
  let listFile = [];
  // VOO NGHIA =>>>>>>>>
  if (selectedFile !== null && selectedFile.length > 0) { //
    for (let i = 0; i < selectedFile.length; i++) {
      listFile.push(selectedFile[i]);
    }
  }

  let formData = new FormData();


  if (listFile !== null && listFile.length > 0) {
    listFile.forEach(file => {
      formData.append("file", file)
    });
  } else {
    formData.append("file", selectedFile)
  }
  formData.append("data", JSON.stringify(data))
  return formData;
};

export function uploadFileDataSwapUnit(invoice, contract) {

  let invoiceFile = [];
  if (invoice.invoiceAttachments !== null && invoice.invoiceAttachments.length > 0) { //
    for (let i = 0; i < invoice.invoiceAttachments.length; i++) {
      invoiceFile.push(invoice.invoiceAttachments[i]);
    }
  }
  let contractFile = [];
  if (contract.contractAttachments !== null && contract.contractAttachments.length > 0) { //
    for (let i = 0; i < contract.contractAttachments.length; i++) {
      contractFile.push(contract.contractAttachments[i]);
    }
  }
  let formData = new FormData();

  if (invoiceFile !== null && invoiceFile.length > 0) {
    invoiceFile.forEach(file => {
      formData.append("invoiceAttachments", file);
    });
  } else formData.append("invoiceAttachments", invoiceFile);


  if (contractFile !== null && contractFile.length > 0) {
    contractFile.forEach(file => {
      formData.append("contractAttachments", file)
    });
  } else formData.append("contractAttachments", invoiceFile);
  formData.append("contractDataJson", JSON.stringify(contract.contractData));  // value1 
  formData.append("invoiceDataJson", JSON.stringify(invoice.invoiceData))    // value 2

  return formData;
};

export function uploadFileContractFormData(selectedFile, data) {
  let formData = new FormData();
  formData.append('file', selectedFile)
  formData.append("data", JSON.stringify(data))
  return formData;
};
export function uploadFileImageData(selectedImage, selectedFile, data, mode) {

  let listFile = [];

  if (selectedFile !== null && selectedFile.length > 0) {
    for (let i = 0; i < selectedFile.length; i++) {
      listFile.push(selectedFile[i]);
    }
  }

  let formData = new FormData();


  if (listFile !== null && listFile.length > 0) {
    listFile.forEach(file => {
      formData.append("attachments", file)
    });
  }

  formData.append(`${mode}`, selectedImage)
  formData.append("data", JSON.stringify(data))
  return formData;
};

export function uploadImageData(selectedImage, data, mode) {
  let formData = new FormData();

  formData.append(`${mode}`, selectedImage)
  formData.append("data", JSON.stringify(data))
  return formData;
};


export function uploadFile(selectedFile) {
  let formData = new FormData();
  formData.append("file", selectedFile);
  return formData;
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function handleSelectFile(event, documentsArrayHelpers) {
  const file = event.target.files;
  if (file) {
    for (let i = 0; i < file.length; i++) {
      documentsArrayHelpers.push({
        documentName: event.target.files[i].name,
        documentSize: event.target.files[i].size,
      })
    }
  }
  return documentsArrayHelpers
};

export const formatFile = ['xlsx', 'xls', 'zip', 'txt', 'rar', 'png', 'pdf', 'mpg', 'mp4', 'mov', 'jpg', 'exe', 'dwg', 'docx', 'doc']

export const checkFileFormat = (file) => {
  let url;
  if (file.type.includes('image'))
    url = URL.createObjectURL(file)
  else {
    formatFile.map((item) => {
      if (file.path.includes(item)) {
        url = `/media/users/upfile_${item}.png`;
      }
    })
  };

  return url;
}

const codeFunctionName = ['receiptPaymentVouchers', 'receiptPaymentVouchers', 'invoices', 'contracts', 'supports']
const convertToDownload = ['PT', 'PC', 'HĐ', 'HĐ', 'HT']
const prefix = (functionName) => {
  let currentFunctionName;
  codeFunctionName.map((item, index) => {
    if (functionName === item)
      currentFunctionName = convertToDownload[index]
  })
  return currentFunctionName;
}

export const handleDownloadFile = (functionName, id, name, toastDownload = () => { }) => {
  Axios.get(`${functionName}/${id}/export`, {
    // include your additional POSTed data here
    responseType: "blob"
  })
    .then((response) => {
      let blob = new Blob([response.data], { type: '.docx' }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `${prefix(functionName)}_${name}.docx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      toastDownload();
    })
    .catch((error) => {
      //console.log(error.response.data.code)
      toastDownload(error);
    });
}

export const handleDownloadFileDocument = (documentCode, name, type) => {
  Axios.get(`documents/${documentCode}/download`, {
    responseType: "blob"
  }).then((response) => {
    let blob = new Blob([response.data], { type: `.${type}` }),
      downloadUrl = window.URL.createObjectURL(blob),
      filename = `${name}`,
      disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    let a = document.createElement("a");
    if (typeof a.download === "undefined") {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  }).catch((error) => {

    // ...
  });
};

export const handleDownloadContractFormDocument = (templateType) => {
  Axios.get(`contractForms/${templateType}/download`, {
    responseType: "blob"
  }).then((response) => {
    let blob = new Blob([response.data], { type: '.docx' }),
      downloadUrl = window.URL.createObjectURL(blob),
      filename = `${prefix(templateType)}_${templateType}.docx`,
      disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    let a = document.createElement("a");
    if (typeof a.download === "undefined") {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  }).catch((error) => {

    // ...
  });
}