import axios from "axios";

export const SETTINGS_URL = "/settings";

export function getAllSettings() {
  return axios.get(SETTINGS_URL);
}

// UPDATE
export function updateSetting(setting) {
  return axios.put(`${SETTINGS_URL}`, setting);
}


export function updateByBuildingId(building) {
  return axios.put(`${SETTINGS_URL}/buildings/${building.buildingId}`, building);
}

export function updateSettingsCurrencies(currencies) {
  return axios.put(`${SETTINGS_URL}/currencies`, currencies);
}

//EDIT
export function getEditSetting() {
  return axios.get(`${SETTINGS_URL}/edit`);
}


export function getEditByBuildingId(buildingId) {
  return axios.get(`${SETTINGS_URL}/buildings/${buildingId}/edit`);
}

export function getSettingCurrencies() {
  return axios.get(`${SETTINGS_URL}/currencies`);
}


export function getSettingCurrenciesExcluded(currencyIds) {
  return axios.post(`${SETTINGS_URL}/currencies/excluded`, { ids: currencyIds });
}


export function getEditBuilding() {
  return axios.get(`${SETTINGS_URL}/buildings/edit`);
}

export function updateResetBuildingId(buildingId) {
  return axios.put(`${SETTINGS_URL}/buildings/${buildingId}/reset`);
}


export function updateEditBuildings(building) {
  return axios.put(`${SETTINGS_URL}/buildings`, building);
}


