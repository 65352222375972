import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Drawer,
  Space,
  Button,
  Card,
  Badge,
  Table,
  Typography,
  Divider,
  Statistic,
  Image,
  InputNumber,
  Select,
  Form,
  Tooltip,
  DatePicker,
  notification,
} from "antd";
import {
  InfoCircleOutlined,
  LogoutOutlined,
  RadiusBottomrightOutlined,
} from "@ant-design/icons";
import { SlashTimestampToDate } from "../../_bloomgoo/_helpers";
import * as actionsOrganization from "../modules/Organization/_redux/organization/organizationActions";
import {
  ORGANIZATION_STATUSES,
  ORGANIZATION_STATUSES_FILTER,
  ORGANIZATION_STATUSES_OBJ,
} from "../modules/Organizations/pages/organizations/OrganizationsUIHelpers";
import NumberFormat from "react-number-format";
import { addCommas } from "../_helpers/_CommonUIHelpers";
import { useHistory } from "react-router-dom";

export default function UpgradePage({
  open = true,
  onClose = () => {},
  closable = false,
}) {
  const { Text } = Typography;
  const { user, organization } = useSelector(
    (state) => ({
      user: state.auth.user,
      organization: state.organization.organizationForShow,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  let remainDay = (nowDate = new Date(), endDate = user.organization.endTime) =>
    Math.ceil(Math.abs(nowDate - endDate) / 86400000);

  let expiredDays = remainDay();

  useEffect(() => {
    dispatch(actionsOrganization.fetchOrganizationShow(user.organizationId));
  }, [dispatch, user.organizationId]);

  const [form] = Form.useForm();

  const [totalAmount, setTotalAmount] = useState(0);
  const userNumberValue = Form.useWatch("userNumber", form);
  const buildingNumbersValue = Form.useWatch("buildingNumber", form);
  const unitNumbersValue = Form.useWatch("unitNumber", form);
  const monthNumberValue = Form.useWatch("monthNumber", form);

  useEffect(() => {
    let _totalAmount =
      (userNumberValue * 20000 +
        buildingNumbersValue * 10000 +
        unitNumbersValue * 15000) *
      monthNumberValue;
    setTotalAmount(_totalAmount ?? 0);
  }, [
    userNumberValue,
    buildingNumbersValue,
    unitNumbersValue,
    monthNumberValue,
  ]);

  // useEffect(() => {
  //   let _totalAmount = (organization. * 20000 + buildingNumbersValue * 10000 + unitNumbersValue * 15000) * monthNumberValue;
  //   console.log("_totalAmount: ", _totalAmount);
  //   setTotalAmount(_totalAmount);
  // }, [organization])

  const dataSourceCN = [
    {
      name: "Ngân hàng:",
      value: "MBBANK",
      copiable: false,
    },
    {
      name: "Chủ tài khoản:",
      value: "LE VAN THANH",
      copiable: false,
    },
    {
      name: "Số tài khoản:",
      value: "0630199993333",
      copiable: true,
    },
    {
      name: "Số tiền:",
      value: addCommas(totalAmount),
      copiable: true,
    },
    {
      name: "Nội dung:",
      value: `${organization.code} ${organization.tel ?? ""} ${
        monthNumberValue ?? ""
      }`,
      copiable: true,
    },
  ];

  const dataSourceDN = [
    {
      name: "Ngân hàng:",
      value: "VIETCOMBANK",
      copiable: false,
    },
    {
      name: "Chủ tài khoản:",
      value: "CONG TY TNHH PHAN MEM BLOOMGOO",
      copiable: false,
    },
    {
      name: "Số tài khoản:",
      value: "0711000247647",
      copiable: true,
    },
    {
      name: "Số tiền:",
      value: addCommas(totalAmount),
      copiable: true,
    },
    {
      name: "Nội dung:",
      value: `${organization.code} ${organization.tel ?? ""} ${
        monthNumberValue ?? ""
      }`,
      copiable: true,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (_, record) => (
        <Typography.Paragraph copyable={record.copiable} className="my-2">
          {record.value}
        </Typography.Paragraph>
      ),
    },
  ];

  const tableProps = {
    bordered: false,
    showHeader: false,
  };

  let packet = [
    {
      name: "lite",
      userFree: 3,
      userPrice: 20000,
      buildingFree: 3,
      buildingPrice: 10000,
      unitPrice: 12000,
    },
    {
      name: "professional",
      userFree: 3,
      userPrice: 20000,
      buildingFree: 3,
      buildingPrice: 10000,
      unitPrice: 12000,
    },
    {
      name: "premium",
      userFree: 3,
      userPrice: 20000,
      buildingFree: 3,
      buildingPrice: 10000,
      unitPrice: 12000,
    },
  ];

  const openNotification = (
    placement,
    bankName,
    accountNumber,
    description,
    accountName
  ) => {
    console.log();
    notification.open({
      message: "Chuyển khoản bằng mã QR",
      description: (
        <Row>
          <Col span={24} className="text-center">
            <Space direction="vertical">
              <Typography.Paragraph>
                Mở App Ngân Hàng Quét QRCode
              </Typography.Paragraph>
              <Image
                preview={false}
                src={`https://img.vietqr.io/image/${bankName}-${accountNumber}-compact.png?amount=${totalAmount}&addInfo=<${description}>&accountName=${accountName}`}
              />
              <Typography.Paragraph>
                <a
                  href={`https://img.vietqr.io/image/${bankName}-${accountNumber}-compact.png?amount=${totalAmount}&addInfo=<${description}>&accountName=${accountName}`}
                >
                  Tải xuống
                </a>
              </Typography.Paragraph>
              <Typography.Paragraph>
                21 App ngân hàng hỗ trợ Quét Mã VietQR
              </Typography.Paragraph>
            </Space>
          </Col>
        </Row>
      ),
      placement,
    });
  };

  const initialValues = {
    userNumber: organization?.userNumbers,
    buildingNumber: organization?.buildingNumbers,
    unitNumber: organization?.unitNumbers,
    monthNumber: 6,
  };

  return (
    <>
      <Drawer
        title="Nâng cấp tài khoản"
        placement="right"
        closable={closable}
        open={open}
        key={"upgrade-drawer"}
        width={"100%"}
        onClose={onClose}
        headerStyle={{
          padding: 0,
          paddingLeft: closable ? 0 : "1em",
          paddingRight: "1em",
        }}
        bodyStyle={{ padding: 0, paddingTop: 0 }}
        extra={
          <Space size={3} className="py-2">
            {!closable && (
              <Button href="/dang-xuat" type="text">
                <LogoutOutlined />
                Đăng xuất
              </Button>
            )}
            <Button onClick={() => {}} type="primary">
              Tôi đã chuyển khoản
            </Button>
          </Space>
        }
      >
        <Row gutter={[16, 16]} className="pt-5">
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Badge.Ribbon
                  text={
                    organization.status === ORGANIZATION_STATUSES_OBJ.TRIAL
                      ? "Dùng thử"
                      : "Thuê bao"
                  }
                  color="red"
                >
                  <Card
                    hoverable
                    cover={<></>}
                    actions={[]}
                    size="small"
                    title={"THÔNG TIN TÀI KHOẢN"}
                    extra={<></>}
                  >
                    <div>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Card.Meta
                            title={
                              <Typography.Text
                                ellipsis={{
                                  rows: 1,
                                  tooltip: `${organization.code} - ${organization.name}`,
                                }}
                              >
                                <span className="text-primary">
                                  {organization.code}
                                </span>
                                {` - ${organization.name}`}
                              </Typography.Text>
                            }
                            description={
                              <Typography.Text
                                ellipsis={{
                                  rows: 1,
                                  tooltip: organization?.address,
                                }}
                              >
                                {organization?.address}
                              </Typography.Text>
                            }
                          />
                          <Divider className="my-3" />
                        </Col>

                        <Col span={8}>
                          <Statistic
                            title="Tổng người dùng"
                            valueStyle={{
                              color: "#cf1322",
                            }}
                            value={organization.userNumbers}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Tổng tòa nhà"
                            value={organization.buildingNumbers}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Tổng đơn vị thuê"
                            value={organization.unitNumbers}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Thời hạn"
                            value={`${Math.floor(expiredDays / 12)} tháng`}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Ngày bắt đầu"
                            value={SlashTimestampToDate(organization.startTime)}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Ngày hết hạn"
                            value={SlashTimestampToDate(organization.endTime)}
                            prefix={<></>}
                            suffix={<></>}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Badge.Ribbon>
              </Col>
              <Col span={12}>
                <Card
                  hoverable
                  cover={<></>}
                  actions={[]}
                  size="small"
                  title={"THÔNG TIN GIA HẠN"}
                  extra={
                    <>
                      <Select
                        size="small"
                        defaultValue="lite"
                        style={
                          {
                            //width: 120,
                          }
                        }
                        //onChange={handleChange}
                        options={[
                          {
                            value: "lite",
                            label: "Bản lite",
                          },
                          {
                            value: "professional",
                            label: "Bản chuyên nghiệp",
                          },
                          {
                            value: "premium",
                            label: "Bản cao cấp",
                          },
                        ]}
                      />
                    </>
                  }
                  style={{ height: "100%" }}
                >
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          label="Số người dùng"
                          tooltip="Số người dùng"
                          name="userNumber"
                          rules={[
                            {
                              required: true,
                              message: "نام کاربری را وارد کنید.",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            placeholder="Nhập số người dùng"
                            defaultValue={organization?.userNumbers}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Số tòa nhà"
                          tooltip={{
                            title: "Số tòa nhà",
                            icon: <InfoCircleOutlined />,
                          }}
                          name="buildingNumber"
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            placeholder="Nhập số tòa nhà"
                            defaultValue={
                              organization && organization.buildingNumbers
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Số đơn vị thuê"
                          tooltip={{
                            title: "Số đơn vị thuê",
                            icon: <InfoCircleOutlined />,
                          }}
                          name="unitNumber"
                        >
                          <InputNumber
                            style={{
                              width: "100%",
                            }}
                            placeholder="Nhập số đơn vị thuê"
                            defaultValue={
                              organization && organization.unitNumbers
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          label="Số tháng"
                          required
                          tooltip={{
                            title: "Số tháng sử dụng",
                            icon: <InfoCircleOutlined />,
                          }}
                          name="monthNumber"
                        >
                          <Select
                            defaultValue={6}
                            style={
                              {
                                //width: 120,
                              }
                            }
                            //onChange={handleChange}
                            options={[
                              {
                                value: 6,
                                label: 6,
                              },
                              {
                                value: 9,
                                label: 9,
                              },
                              {
                                value: 12,
                                label: 12,
                              },
                              {
                                value: 15,
                                label: 15,
                              },
                              {
                                value: 18,
                                label: 18,
                              },
                              {
                                value: 21,
                                label: 21,
                              },
                              {
                                value: 24,
                                label: 24,
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Gia hạn từ"
                          hidden={true}
                          tooltip={{
                            title: "Thòi gian bắt đầu gia hạn thêm",
                            icon: <InfoCircleOutlined />,
                          }}
                        >
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format={"DD/MM/YYYY"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Gia hạn đến"
                          hidden={true}
                          tooltip={{
                            title: "Thời gian kết thúc gia hạn thêm",
                            icon: <InfoCircleOutlined />,
                          }}
                        >
                          <DatePicker
                            style={{
                              width: "100%",
                            }}
                            format={"DD/MM/YYYY"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="pt-1">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  title="THÔNG TIN CHUYỂN KHOẢN"
                  bordered={false}
                  size={"small"}
                  extra={
                    <Tooltip
                      placement="left"
                      title="Bên trái là tài khoản cá nhân, bên phải là tài khoản doanh nghiệp"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Card
                        title="1. Tài khoản cá nhân"
                        hoverable
                        bordered={false}
                        size={"small"}
                        extra={
                          <Text
                            onClick={() =>
                              openNotification(
                                "bottomRight",
                                "MBBank",
                                "0630199993333",
                                dataSourceCN.find(
                                  (ele) => ele.name === "Nội dung:"
                                ).value,
                                "LE VAN THANH"
                              )
                            }
                          >
                            [ Quét mã QR ]
                          </Text>
                        }
                      >
                        <Table
                          key={"individual"}
                          size={"small"}
                          {...tableProps}
                          dataSource={dataSourceCN}
                          columns={columns}
                          pagination={false}
                        />
                        <Typography.Paragraph>
                          Lưu ý: Nhập chính xác nội dung{" "}
                          <b>{`${organization.code} ${organization.tel ?? ""} ${
                            monthNumberValue ?? ""
                          }`}</b>{" "}
                          khi chuyển khoản. Bạn sẽ nhận được email (hoặc SMS)
                          xác nhận khi giao dịch thành công
                        </Typography.Paragraph>
                      </Card>
                    </Col>

                    <Col span={12}>
                      <Card
                        title="2. Tài khoản doanh nghiệp"
                        hoverable
                        bordered={false}
                        size={"small"}
                        extra={
                          <Text
                            onClick={() =>
                              openNotification(
                                "bottomRight",
                                "Vietcombank",
                                "0711000247647",
                                dataSourceDN.find(
                                  (ele) => ele.name === "Nội dung:"
                                ).value,
                                "CONG TY TNHH PHAN MEM BLOOMGOO"
                              )
                            }
                          >
                            [ Quét mã QR ]
                          </Text>
                        }
                      >
                        <Table
                          key={"organization"}
                          size={"small"}
                          {...tableProps}
                          dataSource={dataSourceDN}
                          columns={columns}
                          pagination={false}
                        />
                        <Typography.Paragraph>
                          Lưu ý: Nhập chính xác nội dung{" "}
                          <b>{`${organization.code} ${organization.tel ?? ""} ${
                            monthNumberValue ?? ""
                          }`}</b>{" "}
                          khi chuyển khoản. Bạn sẽ nhận được email (hoặc SMS)
                          xác nhận khi giao dịch thành công
                        </Typography.Paragraph>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
