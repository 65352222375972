export const ServiceStatusCssClasses = ["success", "info", ""];
export const ServiceSharedCssClasses = ["primary", ""];
export const ServiceSharedTiles = ["Dùng chung", ""];
export const ServiceStatusTitles = ["Selling", "Sold"];
export const ServiceConditionCssClasses = ["success", "danger", ""];
export const ServiceConditionTitles = ["New", "Used"];
export const FormulaTypeTitles = ["Cố định", "Đầu người", "Định mức"];
export const ServiceTypeTitles = ["Tiền thuê", "Tiền đặt cọc", "Tiền điện", "Tiền nước", "Tiền gas", "Tiền xe", "Khác"];
export const defaultSorted = [{ dataField: "createdAt", order: "asc" }];

export const VOUCHERCATEGORIE_TYPE = [
  { id: 0, name: "Hạng mục thu" },
  { id: 1, name: "Hạng mục chi" }
]

