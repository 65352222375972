export const OrganizationStatusCssClasses = ["", "danger", "success", ""];
export const OrganizationIsActiveCssClasses = ["success", "danger"];
export const OrganizationStatusTitles = ["Selling", "Sold"];
export const OrganizationConditionCssClasses = ["success", "danger", ""];
export const OrganizationConditionTitles = ["New", "Used"];
export const StatusOrganizationConditionCssClasses = ["", "danger", "success"];
export const StatusOrganizationConditionTitles = ["", "Dùng thử", "Thuê bao"];
export const IsActiveOrganizationConditionCssClasses = ["success", "danger"];
export const IsActiveOrganizationConditionTitles = ["Kích hoạt", "Vô hiệu"];
export const defaultSorted = [{ dataField: "createdAt", order: "desc" }];
export const OrganizationSubscriberTitles = ["", "Miễn phí", "Chuyên nghiệp", "Cao cấp"];

export const ORGANIZATION_ISACTIVE_ = [
  { id: true, name: "Kích hoạt" },
  { id: false, name: "Vô hiệu" }
];



export const ORGANIZATION_IS_ACTIVE_FILTER = [
  { value: true, label: "Kích hoạt" },
  { value: false, label: "Vô hiệu" }
];
export const ORGANIZATION_STATUSES = [
  { id: 1, name: "Dùng thử" },
  { id: 2, name: "Thuê bao" }
];

export const ORGANIZATION_STATUSES_OBJ = {
  TRIAL: 1,
  SUBSCRIBER: 2
};

export const ORGANIZATION_STATUSES_FILTER = [
  { value: 1, label: "Dùng thử" },
  { value: 2, label: "Thuê bao" }
];

export const ORGANIZATION_SUBSCRIBER_TYPE = [
  { value: 1, label: "Miễn phí" },
  { value: 2, label: "Chuyên nghiệp" },
  { value: 3, label: "Cao cấp" }
];

export const ORGANIZATION_SUBSCRIBER_TYPE_FREE = [
  { value: 1, label: "Miễn phí" },
  { value: 2, label: "Chuyên nghiệp" },
  { value: 3, label: "Cao cấp" }
];

export const ORGANIZATION_SUBSCRIBER_TYPE_PRO = [
  { value: 2, label: "Chuyên nghiệp" },
  { value: 3, label: "Cao cấp" }
];

export const ORGANIZATION_SUBSCRIBER_TYPE_PRE = [
  { value: 3, label: "Cao cấp" }
];
//oganization
const ORGANIZATION_COLUMN_TOGGLE_CONFIG_KEY =
  process.env.REACT_APP_ORGANIZATION_COLUMN_TOGGLE_CONFIG_KEY ||
  "OganizationColumnToggleConfig";

export function getColumnToggleConfig() {
  const ls = localStorage.getItem(ORGANIZATION_COLUMN_TOGGLE_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return {
    createdAt: true,
    createdBy: true,
    updatedAt: true,
    updatedBy: true,
    version: true,
    contractId: true,
    taxMoney: true,
  };
}

export function setColumnToggleConfig(key, value) {
  const columnToggleConfig = getColumnToggleConfig();
  columnToggleConfig[key] = value;
  localStorage.setItem(
    ORGANIZATION_COLUMN_TOGGLE_CONFIG_KEY,
    JSON.stringify(columnToggleConfig)
  );
}
