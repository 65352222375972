export const EmailStatusCssClasses = ["success", "info", ""];
export const EmailStatusTitles = ["Selling", "Sold"];
export const EmailConditionCssClasses = ["success", "danger", ""];
export const EmailConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "createdAt", order: "asc" }];

export const dataFields = [
    "id",
    "action",
    "code",
    "name",
    "parentId",
    "address",
    "numberFloors",
    "ratio",
    "ownerName",
    "ownerTel",
    "latitude",
    "longitude",
    "displayOrder",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
];



const BUILDING_COLUMN_TOGGLE_CONFIG_KEY =
    process.env.REACT_APP_BUILDING_COLUMN_TOGGLE_CONFIG_KEY ||
    "EmailColumnToggleConfig";

export function getColumnToggleConfig() {
    const ls = localStorage.getItem(BUILDING_COLUMN_TOGGLE_CONFIG_KEY);
    if (ls) {
        try {
            return JSON.parse(ls);
        } catch (er) {
            console.error(er);
        }
    }
    return {
        ownerEmail: false,
        longitude: false,
        latitude: false,
        description: false,
        createdAt: false,
        createdBy: false,
        updatedAt: false,
        updatedBy: false,
    };
}

export function setColumnToggleConfig(key, value) {
    const invoiceColumnToggleConfig = getColumnToggleConfig();
    invoiceColumnToggleConfig[key] = value;
    localStorage.setItem(
        BUILDING_COLUMN_TOGGLE_CONFIG_KEY,
        JSON.stringify(invoiceColumnToggleConfig)
    );
}

