/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable array-callback-return */
//Units Helpers
import { isEqual } from "lodash-es";
import React, { useEffect, useRef, useState } from "react";
import { isNull, isUndefined } from "underscore";
import { copyString, removeMoneyCommas } from "../../_bloomgoo/_helpers";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  STRING_EXPIRE_LABEL,
  STRING_UN_EXPIRE_LABEL,
  STRING_DEFAULT_LABEL,
  BUILDING_STATUS_OBJ,
} from "./EnumUIHelpers";

export const UnitTypeCssClasses = ["success", "info", ""];
export const UnitTypeTitles = ["", "Cho thuê", "Kí túc xá", "Kho"];
export const UnitActiveCssClasses = ["success", "danger"];
export const UnitActiveTitles = ["Kích hoạt", "Vô hiệu"];
export const UnitStatusCssClasses = ["", "danger", "success", "muted"];
export const UnitStatusDeskbaseCssClasses = ["", "error", "success", "default"];
export const ContractStatusDeskbaseCssClasses = ["default", "success", "error"];
export const UnitStatusTitles = ["", "Trống", "Đã thuê", "Tạm dừng"];
export const UnitStatusTitleColorCodes = ["", "#F64E60", "#00BC7E", "#BEBEBE"];
export const UnitStatusEmptyCssClasses = ["", "danger", "success"];
export const UnitStatusEmptyTitles = ["", "Trống", "Đã thuê"];
export const PriceTypeTitles = [
  "",
  "Theo giờ",
  "Theo ngày",
  "Theo tháng",
  "Cố định",
  "Định mức",
];
export const PriceTypeUnitTitles = ["", " Giờ", " Ngày", " Tháng"];
export const UnitStatusDepositCssClasses = ["", "warning", "success"];
export const UnitStatusDepositTitles = ["", "Đang đặt cọc", "Không đặt cọc"];
export const UserGenderLabel = ["", "Nam", "Nữ"];

export const STATE_ACTIONS = {
  DEFAULT_ACTION: "DEFAULT_ACTION",
  UNIT_DETAIL_VOUCHERS_DELETE_ACTION: "UNIT_DETAIL_VOUCHERS_DELETE_ACTION",
  UNIT_DETAIL_DEPOSIT_NEW_ACTION: "UNIT_DETAIL_DEPOSIT_NEW_ACTION",
  UNIT_DETAIL_INVOICES_UPDATE_STATUS_ACTION:
    "UNIT_DETAIL_INVOICES_UPDATE_STATUS_ACTION",
};

export const UNIT_PRICE_TYPE_OBJ = {
  PER_HOUR: 1,
  PER_DAY: 2,
  PER_MONTH: 3,
};

export const UNIT_SYMBOL_PRICE_TYPE_OBJ = {
  PER_HOUR: "Giờ",
  PER_DAY: "Ngày",
  PER_MONTH: "Tháng",
};

export const UNIT_PRICE_TYPE_FILTER = [
  { value: UNIT_PRICE_TYPE_OBJ.PER_HOUR, label: "Theo giờ" },
  { value: UNIT_PRICE_TYPE_OBJ.PER_DAY, label: "Theo ngày" },
  { value: UNIT_PRICE_TYPE_OBJ.PER_MONTH, label: "Theo tháng" },
];

export const UnitConditionTitles = ["New", "Used"];
export const UseTypeTitles = ["", "Cho thuê", "Ký túc xá", "Kho"];

export const UNIT_STATUS_OBJ = {
  EMPTY: 1,
  RENTED: 2,
  STOPPED: 3,
  PRE_CHECKOUT: 4,
};

export const UNIT_TYPES = [
  { id: 1, name: "Cho thuê" },
  { id: 2, name: "Ký túc xá" },
  { id: 3, name: "Kho" },
];
export const UNIT_USE_TYPE_OBJ = {
  RENT: 1,
  DORMITORY: 2,
  WAREHOUSE: 3,
};
export const UNIT_USE_TYPE_LABEL = ["", "Cho thuê", "Ký túc xá", "Kho"];
export const UNIT_USE_TYPE = [
  { id: UNIT_USE_TYPE_OBJ.RENT, name: "Cho thuê" },
  { id: UNIT_USE_TYPE_OBJ.DORMITORY, name: "Ký túc xá" },
  { id: UNIT_USE_TYPE_OBJ.WAREHOUSE, name: "Kho" },
];
export const ACTIVE_STATUSES = [
  { id: "true", name: "Kích hoạt" },
  { id: "false", name: "Vô hiệu" },
];
export const UNIT_STATUSES = [
  { id: UNIT_STATUS_OBJ.EMPTY, name: "Trống" },
  { id: UNIT_STATUS_OBJ.RENTED, name: "Đã thuê" },
  { id: UNIT_STATUS_OBJ.STOPPED, name: "Tạm dừng" },
];

export const UNIT_STATUSES_FILTER = [
  { value: UNIT_STATUS_OBJ.EMPTY, label: "Trống" },
  { value: UNIT_STATUS_OBJ.RENTED, label: "Đã thuê" },
  { value: UNIT_STATUS_OBJ.STOPPED, label: "Tạm dừng" },
];

export const UNIT_STATUSES_DEPOSIT = [
  { value: true, label: "Đang cọc giữ chỗ" },
  { value: false, label: "Không cọc" },
];

//contract.status
//customerType
//InvoiceType
//servicesShare
//contractType
//Services
export const UserTypeTitles = ["", "Người dùng", "Môi giới", "Đối tác"];
export const ServiceIsDefaultTitles = ["Mặc định", ""];
export const ServiceIsDefaultClasses = ["success", "danger", ""];
export const ServiceStatusCssClasses = ["success", "info", ""];
export const ServiceSharedCssClasses = ["primary", ""];
export const ServiceSharedTiles = ["Dùng chung", ""];
export const ServiceStatusTitles = ["Selling", "Sold"];
export const ServiceConditionCssClasses = ["success", "danger", ""];
export const ServiceConditionTitles = ["New", "Used"];
export const ServiceTypeTitles = [
  "",
  "Tiền thuê",
  "Tiền cọc giữ chỗ",
  "Tiền cọc",
  "Tiền điện",
  "Tiền nước",
  "Tiền gas",
  "Tiền xe",
  "Dịch vụ khác",
  "",
  "",
  "Tiền trả trước",
];
export const FormulaTypeTitles = [
  "",
  "Theo giờ",
  "Theo ngày",
  "Theo tháng",
  "Cố định",
  "Định mức",
];
export const FormulaTypeTitlesServiceWithMeter = [
  "",
  "Theo giờ",
  "Theo ngày",
  "Theo tháng",
  "Cố định",
  "Định mức",
];
export const FormulaTypeTitlesUnitPrice = [
  "Theo giờ",
  "Theo ngày",
  "Theo tháng",
];
export const FormulaTypeTitlesVehicle = ["", "Cố định", "", "Theo thực tế"];
export const SERVICE_TYPES_OBJ = {
  RENT: 1,
  PRE_DEPOSITS: 2,
  DEPOSITS: 3,
  ELECTRICITY: 4,
  WATER: 5,
  GAS: 6,
  VEHICLE: 7,
  OTHER: 8,
  PREPAY: 11,
};

export const FORMULA_SERVICE_TYPES_FILTER = [
  { value: SERVICE_TYPES_OBJ.ELECTRICITY, label: "Tiền điện" },
  { value: SERVICE_TYPES_OBJ.WATER, label: "Tiền nước" },
  { value: SERVICE_TYPES_OBJ.GAS, label: "Tiền gas" },
  { value: SERVICE_TYPES_OBJ.OTHER, label: "Dịch vụ khác" },
];
export const SERVICE_TYPES = [
  { id: SERVICE_TYPES_OBJ.RENT, name: "Tiền thuê" },
  { id: SERVICE_TYPES_OBJ.PRE_DEPOSITS, name: "Tiền cọc giữ chỗ" },
  { id: SERVICE_TYPES_OBJ.DEPOSITS, name: "Tiền cọc" },
  { id: SERVICE_TYPES_OBJ.ELECTRICITY, name: "Tiền điện" },
  { id: SERVICE_TYPES_OBJ.WATER, name: "Tiền nước" },
  { id: SERVICE_TYPES_OBJ.GAS, name: "Tiền gas" },
  { id: SERVICE_TYPES_OBJ.VEHICLE, name: "Tiền xe" },
  { id: SERVICE_TYPES_OBJ.OTHER, name: "Dịch vụ khác" },
];
export const PRICE_TYPES_OBJ = {
  PER_HOUR: 1,
  PER_DAY: 2,
  PER_MONTH: 3,
  FIX_PRICE: 4,
  FORMULA_PRICE: 5,
};

export const FORMULA_TYPES = [
  { id: PRICE_TYPES_OBJ.PER_HOUR, name: "Theo giờ" }, //Hoặc đầu người theo ngày
  { id: PRICE_TYPES_OBJ.PER_DAY, name: "Theo ngày" }, //Đầu người theo giờ
  { id: PRICE_TYPES_OBJ.PER_MONTH, name: "Theo tháng" }, //Đầu người theo tháng
  { id: PRICE_TYPES_OBJ.FIX_PRICE, name: "Cố định" },
  { id: PRICE_TYPES_OBJ.FORMULA_PRICE, name: "Định mức" },
];

export const FORMULA_SERVICE_OBJ = {
  ELECTRICITY: 4,
  WATER: 5,
  GAS: 6,
  OTHER: 8,
};

export const FORMULA_SERVICE_TYPES = [
  { id: FORMULA_SERVICE_OBJ.ELECTRICITY, name: "Tiền điện" },
  { id: FORMULA_SERVICE_OBJ.WATER, name: "Tiền nước" },
  { id: FORMULA_SERVICE_OBJ.GAS, name: "Tiền gas" },
  { id: FORMULA_SERVICE_OBJ.OTHER, name: "Dịch vụ khác" },
];

export const FORMULA_TYPES_SERVICE_WITH_METER = [
  { id: PRICE_TYPES_OBJ.PER_HOUR, name: "Theo giờ" }, //Hoặc đầu người theo ngày
  { id: PRICE_TYPES_OBJ.PER_DAY, name: "Theo ngày" }, //Đầu người theo giờ
  { id: PRICE_TYPES_OBJ.PER_MONTH, name: "Theo tháng" }, //Đầu người theo tháng
  { id: PRICE_TYPES_OBJ.FIX_PRICE, name: "Cố định" },
  { id: PRICE_TYPES_OBJ.FORMULA_PRICE, name: "Định mức" },
];
export const FORMULA_TYPES_SERVICE_OTHER = [
  { id: PRICE_TYPES_OBJ.PER_HOUR, name: "Theo giờ" },
  { id: PRICE_TYPES_OBJ.PER_DAY, name: "Theo ngày" },
  { id: PRICE_TYPES_OBJ.PER_MONTH, name: "Theo tháng" },
  { id: PRICE_TYPES_OBJ.FORMULA_PRICE, name: "Định mức" },
];

export const FORMULA_TYPES_SERVICE_NOT_METER = [
  { id: PRICE_TYPES_OBJ.PER_HOUR, name: "Theo giờ" },
  { id: PRICE_TYPES_OBJ.PER_DAY, name: "Theo ngày" },
  { id: PRICE_TYPES_OBJ.PER_MONTH, name: "Theo tháng" },
];

export const FORMULA_TYPES_FILTER = [
  // Dịch vụ là điện, nước gas
  { value: PRICE_TYPES_OBJ.PER_HOUR, label: "Theo giờ" },
  { value: PRICE_TYPES_OBJ.PER_DAY, label: "Theo ngày" },
  { value: PRICE_TYPES_OBJ.PER_MONTH, label: "Theo tháng" },
  { value: PRICE_TYPES_OBJ.FIX_PRICE, label: "Cố định" },
  { value: PRICE_TYPES_OBJ.FORMULA_PRICE, label: "Định mức" },
];

//Meter Helpers
export const MeterTypeTitles = [
  "",
  "",
  "",
  "",
  "Công tơ điện",
  "Công tơ nước",
  "Công tơ gas",
];
export const MeterTypeTitlesLite = ["", "", "", "", "Điện", "Nước", "Gas"];
export const MeterIsShareTitles = ["Công tơ chung", "Công tơ riêng"];

export const MeterStatusCssClasses = ["success", "info", ""];
export const MeterStatusTitles = ["Selling", "Sold"];
export const MeterConditionCssClasses = ["success", "danger", ""];
export const MeterTypeClasses = [
  "",
  "",
  "",
  "",
  "primary",
  "success",
  "danger",
];
export const MeterConditionTitles = ["New", "Used"];

export const METER_TYPES = [
  { id: SERVICE_TYPES_OBJ.ELECTRICITY, name: "Công tơ điện" },
  { id: SERVICE_TYPES_OBJ.WATER, name: "Công tơ  nước" },
  { id: SERVICE_TYPES_OBJ.GAS, name: "Công tơ  gas" },
];

export const METER_IS_SHARED = [
  { value: true, name: "Công tơ chung" },
  { value: false, name: "Công tơ riêng" },
];
export const METER_TYPES_FILTER = [
  { value: SERVICE_TYPES_OBJ.ELECTRICITY, label: "Công tơ điện" },
  { value: SERVICE_TYPES_OBJ.WATER, label: "Công tơ  nước" },
  { value: SERVICE_TYPES_OBJ.GAS, label: "Công tơ  gas" },
];

export const METER_IS_SHARED_FILTER = [
  { value: true, label: "Công tơ chung" },
  { value: false, label: "Công tơ riêng" },
];
//InvoiceType Helper
export const CustomFieldFunctionTypeEnum = ["", "Hóa đơn"];
export const InvoiceIsClosingDebtsCssClasses = ["success", "warning"];
export const InvoiceIsClosingDebtsTitles = ["Đã chốt", "Chưa chốt"];
export const InvoiceIsClosingDebtsOptions = ["Huỷ chốt", "Chốt"];
export const InvoiceIsSentTitles = ["Đã gửi", "Chưa gửi"];
export const InvoiceIsSentOptions = ["Huỷ gửi", "Gửi"];
export const NewsLettersStatusTitles = ["", " Xuất bản", "Lưu nháp"];
export const NewsLettersStatusCssClasses = ["", "success", "warning"];
export const BuildingPostsStatusTitles = ["", " Xuất bản", "Lưu nháp"];
export const BuildingPostsStatusCssClasses = ["", "success", "warning"];
export const UnitPostsStatusTitles = ["", " Xuất bản", "Lưu nháp"];
export const UnitPostsStatusCssClasses = ["", "success", "warning"];
export const InvoiceStatusTitles = ["", "Chờ duyệt", " Đã duyệt"];
export const InvoiceStatusTitlesOptions = ["", "Duyệt", "Hủy duyệt"];
export const InvoiceStatusTitlesSubmit = ["", "Hủy duyệt", "Duyệt"];
export const InvoiceClosingTitlesSubmit = ["Hủy chốt", "Chốt"];
export const InvoiceStatusCssClasses = ["", "warning", "success"];
export const InvoiceConditionTitles = ["New", "Used"];
export const InvoiceConditionCssClasses = ["success", "danger", ""];
export const MeterLogStatusTitles = ["Chưa chốt", "Đã chốt"];
export const MeterLogStatusCssClasses = ["warning", "success"];
export const DeadlineStatusTitles = ["", "Chưa đến hạn", "Quá hạn"];
export const DeadlineStatusCssClasses = ["success", "danger", ""];
export const PaymentStatusCssClasses = ["", "success", "danger", "warning"];

export const InvoiceStatusAntdCssClasses = ["", "warning", "success"];
export const InvoiceConditionAntdCssClasses = ["success", "error", ""];
export const PaymentStatusAntdCssClasses = ["", "success", "error", "warning"];
export const InvoiceIsClosingDebtsAntdCssClasses = ["success", "warning"];
export const InvoiceIsSentAntdCssClasses = ["success", "danger"];

export const PaymentStatusTitles = [
  "",
  "Đã thanh toán",
  "Chưa thanh toán",
  "Chưa thanh toán đủ",
];
export const TemplateType_Obj = [
  "",
  "Hợp đồng thuê",
  "Hợp đồng đặt cọc",
  "Hóa đơn",
  "Phiếu thu",
  "Phiếu chi",
  "Phụ lục hợp đồng",
];
export const InvoiceTypeTitlesFilter = [
  "",
  "Hóa đơn tiền thuê",
  "Hóa đơn tiền cọc giữ chỗ",
  "Hóa đơn tiền cọc",
  "Hóa đơn tiền điện",
  "Hóa đơn tiền nước",
  "Hóa đơn tiền gas",
  "Hóa đơn tiền xe",
  "Hóa đơn dịch vụ khác",
  "Hóa đơn tổng hợp",
  "Hoá đơn trả phòng",
  "Hoá đơn trả trước",
];
export const InvoiceTypeTitles = [
  "",
  "Hóa đơn tiền thuê",
  "Hóa đơn tiền cọc giữ chỗ",
  "Hóa đơn tiền cọc",
  "Hóa đơn tiền điện",
  "Hóa đơn tiền nước",
  "Hóa đơn tiền gas",
  "Hóa đơn tiền xe",
  "Hóa đơn dịch vụ khác",
  "Hóa đơn tổng hợp",
  "Hoá đơn trả phòng",
  "Hoá đơn trả trước",
];

export const INVOICE_TYPES_OBJ = {
  RENT: 1,
  PRE_DEPOSITS: 2,
  DEPOSITS: 3,
  ELECTRICITY: 4,
  WATER: 5,
  GAS: 6,
  VEHICLE: 7,
  OTHER: 8,
  SUMMARY: 9,
  CHECKOUT: 10,
  PREPAY: 11,
};

export const FORMULARS_TYPES_OBJ = {
  ELECTRICITY: 4,
  WATER: 5,
  GAS: 6,
  OTHER: 7,
};

export const METER_TYPES_OBJ = {
  ELECTRICITY: 4,
  WATER: 5,
  GAS: 6,
};
export const INVOICE_TYPES_WHEN_EDIT = [
  { id: INVOICE_TYPES_OBJ.SUMMARY, name: "Hóa đơn tổng hợp" },
  { id: INVOICE_TYPES_OBJ.RENT, name: "Hóa đơn tiền thuê" },
  { id: INVOICE_TYPES_OBJ.DEPOSITS, name: "Hóa đơn tiền cọc" },
  { id: INVOICE_TYPES_OBJ.PRE_DEPOSITS, name: "Hóa đơn tiền cọc giữ chỗ" },
  { id: INVOICE_TYPES_OBJ.ELECTRICITY, name: "Hóa đơn tiền điện" },
  { id: INVOICE_TYPES_OBJ.WATER, name: "Hóa đơn tiền nước" },
  { id: INVOICE_TYPES_OBJ.GAS, name: "Hóa đơn tiền gas" },
  { id: INVOICE_TYPES_OBJ.VEHICLE, name: "Hóa đơn tiền xe" },
  { id: INVOICE_TYPES_OBJ.OTHER, name: "Hóa đơn dịch vụ khác" },
  { id: INVOICE_TYPES_OBJ.CHECKOUT, name: "Hóa đơn trả phòng" },
  { id: INVOICE_TYPES_OBJ.PREPAY, name: "Hóa đơn trả trước" },
];

export const SERVICE_TYPES_CUSTOM = [
  // khi thêm mới chỉ có điện, nước gas, khác
  { value: SERVICE_TYPES_OBJ.ELECTRICITY, label: "Tiền điện" },
  { value: SERVICE_TYPES_OBJ.WATER, label: "Tiền nước" },
  { value: SERVICE_TYPES_OBJ.GAS, label: "Tiền gas" },
  { value: SERVICE_TYPES_OBJ.VEHICLE, label: "Tiền xe" },
  { value: SERVICE_TYPES_OBJ.OTHER, label: "Khác" },
];
export const INVOICE_TYPES_WHEN_NEW = [
  { id: INVOICE_TYPES_OBJ.SUMMARY, name: "Hóa đơn tổng hợp" },
  { id: INVOICE_TYPES_OBJ.RENT, name: "Hóa đơn tiền thuê" },
  { id: INVOICE_TYPES_OBJ.DEPOSITS, name: "Hóa đơn tiền cọc" },
  { id: INVOICE_TYPES_OBJ.ELECTRICITY, name: "Hóa đơn tiền điện" },
  { id: INVOICE_TYPES_OBJ.WATER, name: "Hóa đơn tiền nước" },
  { id: INVOICE_TYPES_OBJ.GAS, name: "Hóa đơn tiền gas" },
  { id: INVOICE_TYPES_OBJ.VEHICLE, name: "Hóa đơn tiền xe" },
  { id: INVOICE_TYPES_OBJ.OTHER, name: "Hóa đơn dịch vụ khác" },
  { id: INVOICE_TYPES_OBJ.PREPAY, name: "Hóa đơn trả trước" },
];

export const INVOICE_APPROVER_OBJ = {
  NOT_APPROVAL: 1,
  APPROVAL: 2,
};

export const INVOICE_ISCLOSING_DEBTS_OBJ = {
  ISCLOSING: true,
  NOT_CLOSING: false,
};

export const METERLOGS_ISPEG_OBJ = {
  ISPEG: true,
  UNPEG: false,
};

export const INVOICE_APPROVERDED_STATUS = [
  { id: INVOICE_APPROVER_OBJ.NOT_APPROVAL, name: "Chờ duyệt" },
  { id: INVOICE_APPROVER_OBJ.APPROVAL, name: "Đã duyệt" },
];
export const INVOICE_UPDATE_STATUS = [
  { id: INVOICE_APPROVER_OBJ.NOT_APPROVAL, name: "Huỷ duyệt" },
  { id: INVOICE_APPROVER_OBJ.APPROVAL, name: "Duyệt" },
];

export const INVOICE_CLOSING_DEBTS_STATUS_FILTER = [
  { value: true, label: "Chốt" },
  { value: false, label: "Hủy chốt" },
];
export const INVOICE_CLOSING_DEBTS_UPDATE_STATUS = [
  { value: true, label: "Chốt" },
  { value: false, label: "Huỷ chốt" },
];

export const INVOICE_PAYMENT_STATUS = [
  { id: 2, name: "Chưa thanh toán" },
  { id: 1, name: "Đã thanh toán" },
  { id: 3, name: "Chưa thanh toán đủ" },
];
export const INVOICE_DEADLINE_STATUS = [
  { id: 1, name: "Chưa đến hạn" },
  { id: 2, name: "Quá hạn" },
];
export const INVOICE_PAYMENT_STATUS_OBJ = {
  PAID: 1,
  UNPAID: 2,
  NOTPAIDINFULL: 3,
};
export const INVOICE_PAYMENT_STATUS_FILTER = [
  { value: 2, label: "Chưa thanh toán" },
  { value: 1, label: "Đã thanh toán" },
  { value: 3, label: "Chưa thanh toán đủ" },
];
export const INVOICE_DEADLINE_STATUS_FILTER = [
  { value: 1, label: "Chưa đến hạn" },
  { value: 2, label: "Quá hạn" },
];

export const INVOICE_APPROVERDED_STATUS_OBJ = {
  PENDING: 1,
  APPROVED: 2,
};
export const INVOICE_APPROVERDED_STATUS_FILTER = [
  { value: INVOICE_APPROVERDED_STATUS_OBJ.PENDING, label: "Chờ duyệt" },
  { value: INVOICE_APPROVERDED_STATUS_OBJ.APPROVED, label: "Đã duyệt" },
];

export const INVOICE_TYPES_FILTER = [
  { value: INVOICE_TYPES_OBJ.SUMMARY, label: "Hóa đơn tổng hợp" },
  { value: INVOICE_TYPES_OBJ.RENT, label: "Hóa đơn tiền thuê" },
  { value: INVOICE_TYPES_OBJ.PRE_DEPOSITS, label: "Hóa đơn tiền cọc giữ chỗ" },
  { value: INVOICE_TYPES_OBJ.DEPOSITS, label: "Hóa đơn tiền cọc" },
  { value: INVOICE_TYPES_OBJ.ELECTRICITY, label: "Hóa đơn tiền điện" },
  { value: INVOICE_TYPES_OBJ.WATER, label: "Hóa đơn tiền nước" },
  { value: INVOICE_TYPES_OBJ.GAS, label: "Hóa đơn tiền gas" },
  { value: INVOICE_TYPES_OBJ.VEHICLE, label: "Hóa đơn tiền xe" },
  { value: INVOICE_TYPES_OBJ.OTHER, label: "Hóa đơn dịch vụ khác" },
  { value: INVOICE_TYPES_OBJ.PREPAY, label: "Hóa đơn trả trước" },
];
export const DISCOUNT_TYPES_SERVICE_OBJ = {
  IS_PERCENT: true,
  NOT_PERCENT: false,
};
export const DISCOUNT_TYPES_SERVICE = [
  { id: DISCOUNT_TYPES_SERVICE_OBJ.IS_PERCENT, name: "%" },
  { id: DISCOUNT_TYPES_SERVICE_OBJ.NOT_PERCENT, name: "Tuỳ chỉnh" },
];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "35", value: 35 },
  { text: "50", value: 50 },
];

export const pageSizeOptionsProTable = [10, 20, 35, 50];

export const sizePerSmallPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "7", value: 7 },
];
export const sizePerLagePageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "35", value: 35 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const isNullOrEmptyToDefaultZero = (value) => {
  return value === null || value.length === 0 ? 0 : value;
};
export const isNullOrEmptyToDefaultBlank = (value) => {
  return value === null || value === undefined ? "" : value;
};

export const isEqualTo = (value1, value2) => {
  if (value1 == value2) {
    // TODO  . khong sua
    return true;
  } else return false;
};

export const findContractFormByContractFormType = (
  contractForms,
  contractFormType,
  buildingId
) => {
  let newContractForms = [];
  contractForms &&
    contractForms.map((item) => {
      if (
        item.contractFormType === contractFormType ||
        !item.contractFormType
      ) {
        if (!buildingId && !item.buildingId) {
          let contractForm = { value: item.id, label: item.name };
          newContractForms.push(contractForm);
        } else if (buildingId) {
          let contractForm = { value: item.id, label: item.name };
          newContractForms.push(contractForm);
        }
      }
    });
  return newContractForms;
};

export const getElementById = (id, items) => {
  let _item;

  items &&
    items.map((item) => {
      if (item.id == id) {
        _item = item;
      }
    });
  return _item || { name: "", fullName: "" };
};

export const getMonthMeterLog = (month) => {
  return month === 1 ? 12 : month - 1;
};

export const getYearMeterLog = (month, year) => {
  return month === 1 ? year - 1 : year;
};

export const findParents = (parents) => {
  let newParents = [];
  parents &&
    parents.map((item) => {
      if (item) {
        let parent = {
          value: item.id,
          label: item.parentId
            ? "".padStart(item.level * 3, "-- ") +
            (item.parent && item.parent.name ? item.parent.name : "")
            : item.name
              ? item.name
              : "",
        };
        newParents.push(parent);
      }
    });
  return newParents;
};

export const findOptionTable = (options) => {
  let newOptions = [];
  options &&
    options.map((item) => {
      let option = {
        value: item.id,
        label: item.parentId
          ? "".padStart(item.level * 3, "-- ") +
          (item.parent && item.parent.name ? item.parent.name : "")
          : item.name
            ? item.name
            : "",
      };
      newOptions.push(option);
    });
  return newOptions;
};

let newParents = [{ name: "-- Chọn --", id: 0 }];
let childrens = [];
export function setChildrens(id, parents) {
  parents.forEach((ele, index) => {
    if (ele.id === id) {
      if (childrens.some((child) => child.id === ele.id)) {
      } else childrens.push(ele);
    } else if (ele.parentId === id) {
      setChildrens(ele.id, parents);
    } else {
    }
  });
  return childrens;
}

export const setParents = (buildingId, parents) => {
  if (!buildingId) return parents;
  const childrens = setChildrens(buildingId, parents);
  parents.forEach((item) => {
    if (childrens.some((_item) => _item.id === item.id)) {
    } else {
      !newParents.some((parent) => parent.id === item.id) &&
        newParents.push(item);
    }
  });
  return newParents;
};

export const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const stringToBoolean = (str) => {
  if (str === "true" || str === true) {
    str = true;
  } else {
    str = false;
  }
  return str;
};

export const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return !isEqual(ele, value);
  });
};

export const arraySum = (arr) => {
  let result = 0;
  result = arr.reduce(function (total, currentValue) {
    // Trả về biến total cộng với giá trị của phần tử đang lặp
    return (
      removeMoneyCommas(total) +
      removeMoneyCommas(currentValue ? currentValue : 0)
    );
  }, 0);
  return result;
};

//UNITS HELPER
export const unitIsRent = (useType) => {
  let isRent = false;
  if (useType === UNIT_USE_TYPE_OBJ.RENT) {
    isRent = true;
  } else {
  }
  return isRent;
};

export const AVAILABLE_MONTH = [
  { id: 1, name: "Tháng 1" },
  { id: 2, name: "Tháng 2" },
  { id: 3, name: "Tháng 3" },
  { id: 4, name: "Tháng 4" },
  { id: 5, name: "Tháng 5" },
  { id: 6, name: "Tháng 6" },
  { id: 7, name: "Tháng 7" },
  { id: 8, name: "Tháng 8" },
  { id: 9, name: "Tháng 9" },
  { id: 10, name: "Tháng 10" },
  { id: 11, name: "Tháng 11" },
  { id: 12, name: "Tháng 12" },
];

export const AVAILABLE_YEAR = [
  { id: 2010, name: "Năm 2010" },
  { id: 2011, name: "Năm 2011" },
  { id: 2012, name: "Năm 2012" },
  { id: 2013, name: "Năm 2013" },
  { id: 2014, name: "Năm 2014" },
  { id: 2015, name: "Năm 2015" },
  { id: 2016, name: "Năm 2016" },
  { id: 2017, name: "Năm 2017" },
  { id: 2018, name: "Năm 2018" },
  { id: 2019, name: "Năm 2019" },
  { id: 2020, name: "Năm 2020" },
  { id: 2021, name: "Năm 2021" },
  { id: 2022, name: "Năm 2022" },
  { id: 2023, name: "Năm 2023" },
  { id: 2024, name: "Năm 2024" },
  { id: 2025, name: "Năm 2025" },
  { id: 2026, name: "Năm 2026" },
  { id: 2027, name: "Năm 2027" },
  { id: 2028, name: "Năm 2028" },
  { id: 2029, name: "Năm 2029" },
  { id: 2030, name: "Năm 2030" },
  { id: 2031, name: "Năm 2031" },
  { id: 2032, name: "Năm 2032" },
  { id: 2033, name: "Năm 2033" },
  { id: 2034, name: "Năm 2034" },
  { id: 2035, name: "Năm 2035" },
  { id: 2036, name: "Năm 2036" },
  { id: 2037, name: "Năm 2037" },
  { id: 2038, name: "Năm 2038" },
  { id: 2039, name: "Năm 2039" },
  { id: 2040, name: "Năm 2040" },
  { id: 2041, name: "Năm 2041" },
  { id: 2042, name: "Năm 2042" },
  { id: 2043, name: "Năm 2043" },
  { id: 2044, name: "Năm 2044" },
  { id: 2045, name: "Năm 2045" },
  { id: 2046, name: "Năm 2046" },
  { id: 2047, name: "Năm 2047" },
  { id: 2048, name: "Năm 2048" },
  { id: 2049, name: "Năm 2049" },
  { id: 2050, name: "Năm 2050" },
  { id: 2051, name: "Năm 2051" },
  { id: 2052, name: "Năm 2052" },
];

export const isPartner = (user) => {
  if (user !== null && user.organization !== null) {
    if (user.userType === USER_TYPE.PARTNER) {
      return true;
    }
    return false;
  }
  return false;
};

//bank Helper
export const BankAccountTypeEnum = {
  CASH: 1,
  BANK: 2,
  OTHER: 3,
};

export const CommentTypeEnum = {
  INTERNAL: 1,
  EXTERNAL: 2,
};

export const BankAccountTypeFilter = [
  { value: BankAccountTypeEnum.CASH, label: "Tiền mặt" },
  { value: BankAccountTypeEnum.BANK, label: "Ngân hàng" },
  { value: BankAccountTypeEnum.OTHER, label: "Ví" },
];

export const DepositStatusEnum = {
  NEW: 1,
  DEPOSITED: 2,
  RENTED: 3,
  CANCELED: 4,
};

export const CONTRACT_FORM_TYPE_OBJ = {
  CONTRACT_RENTED: 1,
  CONTRACT_DEPOSIT: 2,
  INVOICE: 3,
  RECEIPT_VOUCHER: 4,
  PAYMENTVOUCHER: 5,
};

export const CUSTOM_FIELD_FUNCTION_TYPE_OBJ = {
  CONTRACT: 1,
  INVOICE: 2,
  VOUCHER: 3,
};

export const CustomFieldFunctionTypeFilter = [
  { id: CUSTOM_FIELD_FUNCTION_TYPE_OBJ.CONTRACT, name: "Hợp đồng" },
  // { id: CUSTOM_FIELD_FUNCTION_TYPE_OBJ.INVOICE, name: "Hóa đơn" },
  // { id: CUSTOM_FIELD_FUNCTION_TYPE_OBJ.VOUCHER, name: "Phiếu thu chi" }
];

export const FormulaTypeEnum = {
  PER_HOUR_PRICE: 1,
  PER_DAY_PRICE: 2,
  PER_MONTH_PRICE: 3,
  FIXPRICE: 4,
  FORMULA_SID: 5,
};

export const VOUCHER_CATEGORIES_TYPE_OJB = {
  RECEIPT: 1,
  PAYMENT: 2,
};

// contracts helper
export const ContractStatusCssClasses = ["info", "success", "danger"];
export const ContractStatusTitles = [
  "Chưa áp dụng",
  "Hiệu lực",
  "Hết hiệu lực",
]; // TODO: Ngôn ngữ
export const TypeContractCssClasses = ["", "success", "warning"];
export const TypeContractTitles = ["", "Thuê", "Đặt cọc giữ chỗ"];
export const CONTRACTS_FORMS_TYPE_OBJ = {
  RENT: 1,
  DEPOSIT: 2,
  INVOICE: 3,
  RECEIPTS: 4,
  PAYMENT: 5,
  EXTENSION: 6,
};
export const CONTRACTS_STATUS_OBJ = {
  EFFECTIVE: 1,
  EXPIRE: 2,
  PRE_EXPIRED: 3,
};
export const CONTRACTS_FORMS_TYPE = [
  { id: CONTRACTS_FORMS_TYPE_OBJ.RENT, name: "Hợp đồng thuê" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.DEPOSIT, name: "Hợp đồng đặt cọc" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.INVOICE, name: "Hóa đơn" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.RECEIPTS, name: "Phiếu thu" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.PAYMENT, name: "Phiếu chi" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.EXTENSION, name: "Phụ lục hợp đồng" },
];

export const CONTRACT_TYPES = [
  { id: CONTRACTS_FORMS_TYPE_OBJ.RENT, name: "Hợp đồng thuê" },
  { id: CONTRACTS_FORMS_TYPE_OBJ.DEPOSIT, name: "Hợp đồng đặt cọc" },
];

export const CONTRACTS_TYPE_FilTER = [
  { value: CONTRACTS_FORMS_TYPE_OBJ.RENT, label: "Thuê" }, //Hiện tại id=0
  { value: CONTRACTS_FORMS_TYPE_OBJ.DEPOSIT, label: "Đặt cọc giữ chỗ" },
];

//customer
export const TypeCustomerStatusCssClasses = ["", "success", "danger", ""];
export const CustomerTypeTitles = ["", "Cá nhân", "Tổ chức"];
export const CUSTOMER_TYPES_OBJ = {
  INDIVIDUAL: 1,
  ORGANIZATION: 2,
};
export const CustomFieldEnum = ["", "Hóa đơn"];
export const CUSTOMER_TYPES = [
  { id: CUSTOMER_TYPES_OBJ.INDIVIDUAL, name: "Cá nhân" },
  { id: CUSTOMER_TYPES_OBJ.ORGANIZATION, name: "Tổ chức" },
];

export const CUSTOMER_TYPES_FILTER = [
  { value: CUSTOMER_TYPES_OBJ.INDIVIDUAL, label: "Cá nhân" },
  { value: CUSTOMER_TYPES_OBJ.ORGANIZATION, label: "Tổ chức" },
];

export const SEQUENCE_TYPE_OBJ = {
  INCREACE: 1, // Tăng
  DECREACE: 2, //giảm
  ALLOCATION: 3, // cấp phát
  RESTORE: 4, // thu hồi
  CONVER: 5, // điều chuyển
  CANCEL: 6, //Báo huỷ
  IMPORT_EXCEL: 7, //Cấp phát thu hồi
};

// icon helper
export const ICON_TYPE_OBJ = {
  BUILDINGS: "flaticon-home-1",
  FLOORS: "flaticon-layers",
  UTILITIES: "flaticon-interface-8",
  UNITS: "flaticon-squares-4",
  SERVICES: "flaticon-clipboard",
  METERS: "flaticon-time-1",
  VEHICLETYPES: "flaticon-interface-9",
  CONTRACTFORM: "flaticon-folder-1",
  BANKACOUNTS: "flaticon-web",
  CUSTOMERS: "flaticon-customer",
  VEHICLES: "flaticon-truck",
  DEPOSITS: "flaticon-time-2 ",
  CONTRACTS: "flaticon-file-2",
  METERLOGS: "flaticon-time",
  INVOICES: "flaticon-file-1",
  PAYMENTVOUCHERS: "flaticon-price-tag",
  RECEIPTVOUCHERS: "flaticon-piggy-bank",
  OVERVIEWS: "flaticon-diagram",
  DEBTS: "flaticon-graphic-1",
  STATISTICS: "flaticon-statistics",
  REPORTTASKS: "flaticon-presentation-1",
  ASSETCATEGORIES: "flaticon-interface-6 ",
  ASSETWAREHOUSES: "flaticon-interface-7",
  ASSETSTATUSES: "flaticon-pie-chart",
  ALLOCATIONS: "flaticon-upload-1",
  ASSETCONVER: "flaticon-share",
  PROJECTS: "flaticon-list-3",
  TASKS: "flaticon-graphic",
  REPORTBUGS: "flaticon-lifebuoy",
};

export const BG_TYPE_OBJ = {
  UNIT_ALL: "light",
  UNIT_RENTED: "success",
  UNIT_RENTED_DEPOSIT: "info",
  UNIT_EMPTY: "danger",
  UNIT_EMPTY_DEPOSIT: "warning",
  UNIT_DEPOSIT: "secondary",
  UNIT_STOPPED: "dark",
};

export const LIGHT_BG_TYPE_OBJ = {
  UNIT_ALL: "#f3f6f9",
  UNIT_RENTED: "#c9f7f5",
  UNIT_RENTED_DEPOSIT: "#eee5ff",
  UNIT_EMPTY: "#ffe2e5",
  UNIT_EMPTY_DEPOSIT: "#fff4de",
  UNIT_DEPOSIT: "#ebedf3",
  UNIT_STOPPED: "#d1d3e0",
};

export const setIconNotification = (ICON_TYPE_OBJ, functionName) => {
  for (const typeIcon in ICON_TYPE_OBJ) {
    if (functionName === typeIcon) {
      return ICON_TYPE_OBJ[typeIcon];
    }
  }
};

export const setFilterHelper = (queryParams) => {
  let filter = { searchCriteria: [] };

  queryParams &&
    queryParams.filter &&
    queryParams.filter.searchCriteria &&
    queryParams.filter.searchCriteria.forEach((criteria, index) => {
      if (!criteria.isDefault) {
        const newCriteria = {
          key: criteria.key,
          operation: criteria.operation,
          value: criteria.value,
          prefix: criteria.prefix,
          suffix: criteria.suffix,
          isDefault: criteria.isDefault,
          level: criteria.level,
        };
        filter.searchCriteria.push(newCriteria);
      }
    });
  return filter;
};

// bankAccounts
export const BankAccountWalletTitles = [
  "",
  "VNPay",
  "ZaloPay",
  "MoMo",
  "ShopeePay",
  "Moca",
  "ViettelPay",
  "Payoo",
  "VCTPay",
  "TrueMoney",
  "BankPlus",
  "VinID",
  "FoxPay",
  "VNPT",
  "Khác",
];
export const BankAccountTypeTitles = ["", "Tiền mặt", "Ngân hàng", "Khác"];

export const IS_PRIMARY_FILTER = [
  { value: true, label: "Chính" },
  { value: false, label: "Không chính" },
];

export const IS_ORGANIZATION_FILTER = [
  { value: true, label: "Chung" },
  { value: false, label: "Riêng" },
];

export const IS_DEFAULT_FILTER = [
  { value: true, label: "Mặc định" },
  { value: false, label: "Tuỳ chọn" },
];

export const setStyleFilter = (type, queryParams) => {
  let style;
  let isExists = false;
  queryParams?.filter?.searchCriteria &&
    queryParams.filter.searchCriteria.forEach((item) => {
      if (item.key === type && item.value) {
        isExists = true;
      }
    });
  if (isExists) {
    style = {
      borderRadius: 0,
      borderColor: "#009c7e",
    };
  }

  return style;
};

//assets helper
export const ASSET_SEQUENCE_TYPES_OBJ = {
  INCREASE: 1, // Báo tăng
  DECREASE: 2, //Báo giảm
  NEW: 3, //Cấp phát
  RECOVERY: 4, //Thu hồi
  CONVER: 5, //Điều chuyển
  CANCEL: 6, // Báo hủy
};

//Deposits status

export const STATUS_DEPOSIT_OBJ = {
  NEW: 1, //Mới
  DEPOSIT: 2, //Có cọc
  RENTED: 3, //Đã thuê,
  CANCEL: 4, //Đã huỷ cọc giữ chỗ
};
export const StatusDepositCssClasses = ["", "default", "warning", "success"];
export const DISABLE_STATUS_DEPOSIT_COLOR = ["danger", "success"];
export const STATUS_DEPOSIT_DESK = ["", "Mới", "Đã cọc", "Đã thuê", "Hủy cọc"];
export const DISABLE_STATUS_DEPOSIT = ["Hủy cọc", "Đang cọc"];
export const DISABLE_STATUS_DEPOSIT_OBJ = {
  DEPOSITING: 1,
  CANCEL: 2,
};
export const USER_TYPE = {
  USER: 1,
  COLLABORATORS: 2, // Môi giới
  PARTNER: 3, // Đối tác
};

export const USER_TYPE_OPTIONS = [
  { label: "Người dùng", value: 1 },
  { label: "Môi giới", value: 2 },
  { label: "Đối tác", value: 3 },
];

export const CUSTOMER_GENDER_OPTIONS = [
  { label: "Nam", value: 1 },
  { label: "Nữ", value: 2 },
];

export const CUSTOMER_ISFOREIGN_OPTIONS = [
  { label: "Nước ngoài", value: true },
  { label: "Trong nước", value: false },
];

export const USER_TYPE_OBJ = {
  USER: 1,
  COLLABORATORS: 2, // Môi giới
  PARTNER: 3, // Đối tác
};

export const CASHFLOW_TYPE_OBJ = {
  REVENUE: 1, //Doanh thu
  COST: 3, // Chi phí
};

export const ROLE_TYPE_OBJ = {
  USER: 2,
  PARTNER: 3, // Đối tác
  COLLABORATORS: 4, // Môi giới
};

export const ROLE_TYPE_OPTIONS = [
  { label: "Nhân viên", value: 2 },
  { label: "Đối tác", value: 3 },
  { label: "Môi giới", value: 4 },
];

export const backgroundRandom = () => {
  let randomBackground;
  const BACKGROUNDS_ALERT = [
    "/media/svg/errors/error1.svg",
    "/media/svg/errors/error2.svg",
    "/media/svg/errors/error3.svg",
  ];
  randomBackground =
    BACKGROUNDS_ALERT[Math.floor(Math.random() * BACKGROUNDS_ALERT.length)];
  return randomBackground;
};

//CHECK VALUE

export const isEmpty = (value) => {
  let isEmpty = false;
  if (typeof value === "string" && value.length === 0) {
    isEmpty = true;
  } else if (typeof value === "number") {
  } else if (typeof value === "object" && isNull(value)) {
    isEmpty = true;
  } else if (isUndefined(value)) {
    isEmpty = true;
  } else if (value === []) {
    isEmpty = true;
  }

  return isEmpty;
};

export const convertJsonString = (value) => {
  if (typeof value === "undefined") return null;
  else if (typeof value === "object" && value === null) return null;
  else if (typeof value === "string" && value === "") return null;
  else return value;
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export function addCommas(nStr) {
  if (nStr) {
    return nStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return 0;
}

export function convertMoneyToWords(nStr) {
  if (nStr < 1000000) {
    return `${addCommas(nStr)}`;
  } else if (nStr < 1000000000) {
    return `${nStr / 1000000} triệu`;
  } else {
    return `${addCommas(nStr / 1000000000)} tỷ`;
  }
}

export const dataFields = [
  "id",
  "code",
  "name",
  "userId",
  "paymentMoney",
  "paymentAt",
  "createdAt",
  "createdBy",
  "updatedAt",
  "updatedBy",
  "version",
  "action",
];

export const colorExcel = {
  blue: "#bdd7ee",
  yellow: "#ffd966",
  yellow1: "#ffff00",
  yellow2: "#ffff66",
  green: "#a9d08e",
  red: "#f8cbad",
  white: "#fff",
};

export const iconModules = [
  { functionName: "DASHBOARD", icon: "flaticon-dashboard" },
  { functionName: "DESKS", icon: "fas fa-laptop" },
  { functionName: "NOTIFICATIONS", icon: "flaticon-alert-1" },
  { functionName: "SETTINGS", icon: "flaticon-cogwheel-1" },
  { functionName: "ORGANIZATIONS", icon: "flaticon-globe" },
  { functionName: "DEPARTMENTS", icon: "flaticon-map" },
  { functionName: "POSITIONS", icon: "flaticon-technology-1" },
  { functionName: "ROLES", icon: "flaticon-user-ok" },
  { functionName: "USERS", icon: "flaticon-users" },
  { functionName: "PROJECTS", icon: "flaticon-list-3" },
  { functionName: "TASKS", icon: "flaticon-graphic" },
  { functionName: "UNITSYMBOLS", icon: "flaticon-interface-3" },
  { functionName: "PERIODS", icon: "flaticon-stopwatch" },
  { functionName: "UNITTYPES", icon: "flaticon-background" },
  { functionName: "FORMULARS", icon: "flaticon-infinity" },
  { functionName: "VOUCHERCATEGORIES", icon: "flaticon-interface-5" },
  { functionName: "CONTRACTFORMS", icon: "flaticon-folder-1" },
  { functionName: "BANKACCOUNTS", icon: "flaticon-web" },
  { functionName: "BUILDINGS", icon: "far fa-building" },
  { functionName: "FLOORS", icon: "flaticon-layers" },
  { functionName: "UTILITIES", icon: "flaticon-interface-8" },
  { functionName: "UNITS", icon: "flaticon-home-1" },
  { functionName: "SERVICES", icon: "flaticon-clipboard" },
  { functionName: "METERS", icon: "flaticon-time-1" },
  { functionName: "VEHICLETYPES", icon: "flaticon2-rocket-1" },
  { functionName: "SUPPLIERS", icon: "flaticon-user-settings" },
  { functionName: "ASSETWAREHOUSES", icon: "flaticon-app" },
  { functionName: "ASSETCATEGORIES", icon: "flaticon-interface-6" },
  { functionName: "ASSETSTATUSES", icon: "flaticon-warning" },
  { functionName: "ASSETS", icon: "flaticon-tool" },
  { functionName: "ASSETSEQUENCES", icon: "flaticon-share" },
  { functionName: "CUSTOMERS", icon: "flaticon-customer" },
  { functionName: "VEHICLES", icon: "flaticon-truck" },
  { functionName: "DEPOSITS", icon: "flaticon-clock-2" },
  { functionName: "CONTRACTS", icon: "flaticon-edit-1" },
  { functionName: "METERLOGS", icon: "flaticon-time" },
  { functionName: "INVOICES", icon: "flaticon-file-2" },
  { functionName: "PAYMENTVOUCHERS", icon: "flaticon-price-tag" },
  { functionName: "RECEIPTVOUCHERS", icon: "flaticon-piggy-bank" },
  { functionName: "COLLABORATORS", icon: "flaticon2-group" },
  { functionName: "USERCOMMISSIONS", icon: "flaticon-medal" },
  { functionName: "COMMISSIONS", icon: "flaticon-confetti" },
  { functionName: "REFERRALS", icon: "flaticon-network" },
  { functionName: "DOCUMENTS", icon: "flaticon-folder" },
];

export const ERROR_TYPES_OBJ = {
  PREPARE: 1,
  START: 2,
  MIDDLE: 3,
  END: 4,
  //luu xong va k muon thoat 5
  MIDDLE2: 5,
};

export const ShowHideField = (props) => {
  ShowHideField.displayName = "ToggleColumnTable";
  const {
    setColumnToggleConfig,
    fixedField,
    columns,
    toggles,
    onColumnToggle,
  } = props;
  const setColumnToggle = (value, dataField) => {
    return setColumnToggleConfig(dataField, !value);
  };

  let dataFields =
    columns &&
    columns.map((column) => ({
      ...column,
      toggle: toggles[column.dataField],
    }));

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: "none" }}>
      <Popover.Content className="p-1">
        <ul
          className="navi"
          style={{ minWidth: "200px", maxHeight: "400px", overflow: "scroll" }}
        >
          {dataFields &&
            dataFields
              .filter((item) => !fixedField.some((el) => item.dataField === el))
              .map((item, rowIndex) => {
                return (
                  <li className="navi-item" key={item.dataField}>
                    <span type="button" className="navi-link">
                      <span className="navi-icon">
                        <label className="checkbox">
                          <input
                            name={`${item.dataField}`}
                            type={"checkbox"}
                            className={"form-control"}
                            checked={item.toggle}
                            onChange={(e) => {
                              onColumnToggle(item.dataField);
                              setColumnToggle(e.target.checked, item.dataField);
                            }}
                          />
                          <span></span>
                        </label>
                      </span>
                      <span className="navi-text text-dark"> {item.text}</span>
                    </span>
                  </li>
                );
              })}
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="right"
      overlay={popover}
    >
      <div className="text-center">
        <i className="flaticon2-settings text-hover-primary" type="button"></i>
      </div>
    </OverlayTrigger>
  );
};

// Phân quyền

export const SUBSCRIBER_TYPE = {
  FREE: 1,
  PROFESSIONAL: 2,
  PREMIUM: 3,
};

export const isSubsciberFree = (user) => {
  if (user !== null && user.organization !== null) {
    if (user.organization.subscriberType === SUBSCRIBER_TYPE.FREE) {
      return true;
    }
    return false;
  }
  return false;
};

export const isSubsciberPro = (user) => {
  if (user !== null && user.organization !== null) {
    if (user.organization.subscriberType === SUBSCRIBER_TYPE.PROFESSIONAL) {
      return true;
    }
    return false;
  }
  return false;
};

export const isSubsciberPre = (user) => {
  if (user !== null && user.organization !== null) {
    if (user.organization.subscriberType === SUBSCRIBER_TYPE.PREMIUM) {
      return true;
    }
    return false;
  }
  return false;
};

export const listTokenEmail = [
  { token: "${TenKhachThue}", name: "Tên khách thuê" },
  { token: "${SoHoaDon}", name: "Số hóa đơn" },
  { token: "${MaHoaDon}", name: "Mã hóa đơn" },
  { token: "${LoaiHoaDon}", name: "Loại hóa đơn" },
  { token: "${TenHoaDon}", name: "Tên hóa đơn" },
  { token: "${NgayLap}", name: "Ngày lập hóa đơn" },
  { token: "${Thang}", name: "Kỳ thanh toán" },
  { token: "${Nam}", name: "Năm" },
  { token: "${HanThanhToan}", name: "Hạn thanh toán hóa đơn" },
  { token: "${CanThanhToan}", name: "Số tiền cần phải thanh toán" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${TenCanHo}", name: "Tên đơn vị thuê" },
  { token: "${TenCanHoCha}", name: "Tên đơn vị thuê cha" },
  { token: "${TenToChuc}", name: "Tên tổ chức" },
  { token: "${DiaChiToChuc}", name: "Địa chỉ của tổ chức" },
  { token: "${SoDienThoaiToChuc}", name: "Số điện thoại của tổ chức" },
  { token: "${EmailToChuc}", name: "Email của tổ chức" },
  { token: "${TenToaNha}", name: "Tên tòa nhà" },
  { token: "${TenToaNhaCha}", name: "Tên tòa nhà cha" },
  { token: "${DiaChiToaNha}", name: "Địa chỉ tòa nhà" },
];

export const listTokenRentDeposit = [
  { token: "${LogoToChuc}", name: "Logo của tổ chức" },
  { token: "${TenToChuc}", name: "Tên của tổ chức" },
  { token: "${MaToChuc}", name: "Mã của tổ chức" },
  { token: "${MaSoThue}", name: "Mã số thuế" },
  { token: "${NganhNgheKinhDoanhTC}", name: "Ngành nghề kinh doanh" },
  { token: "${DiaChiToChuc}", name: "Địa chỉ của tổ chức" },
  { token: "${SoDienThoaiToChuc}", name: "Số điện thoại của tổ chức" },
  { token: "${EmailToChuc}", name: "Email của tổ chức" },
  { token: "${Website}", name: "Website của tổ chức" },
  { token: "${NguoiDaiDienToChuc}", name: "Người đại diện của tổ chức" },
  { token: "${ChucVu}", name: "Chức vụ của người đại diện" },
  { token: "${SDTNguoiDaiDien}", name: "Số điện thoại của người đại diện" },
  { token: "${EmailNguoiDaiDien}", name: "Email của người đại diện" },
  { token: "${NguoiLienHeToChuc}", name: "Người liên hệ của tổ chức" },
  { token: "${SDTNguoiLienHe}", name: "Số điện thoại của người liên hệ" },
  { token: "${EmailNguoiLienHe}", name: "Email của người liên hệ" },
  { token: "${MaToaNha}", name: "Mã tòa nhà" },
  { token: "${TenToaNha}", name: "Tên tòa nhà" },
  { token: "${TenToaNhaCha}", name: "Tên tòa nhà cha" },
  { token: "${SoTang}", name: "Số tầng" },
  { token: "${DiaChiToaNha}", name: "Địa chỉ tòa nhà" },
  { token: "${TenChuSoHuu}", name: "Chủ sở hữu tòa nhà" },
  { token: "${SDTChuSoHuu}", name: "Số điện thoại của chủ sở hữu" },
  { token: "${EmailChuSoHuu}", name: "Email của chủ sổ hữu" },
  { token: "${ViDo}", name: "Vĩ độ" },
  { token: "${KinhDo}", name: "Kinh độ" },
  { token: "${MaTang}", name: "Mã tầng" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${MaDVT}", name: "Mã đơn vị thuê" },
  { token: "${TenDVT}", name: "Tên đơn vị thuê" },
  { token: "${TenDVTCha}", name: "Tên đơn vị thuê cha" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${DienTichDVT}", name: "Diện tích đơn vị thuê" },
  { token: "${LoaiDVT}", name: "Loại đơn vị thuê" },
  { token: "${MucDichThue}", name: "Mục đích thuê" },
  { token: "${DonGiaMacDinh}", name: "Đơn giá mặc định" },
  { token: "${DonGiaThang}", name: "Đơn giá thuê theo tháng" },
  { token: "${CocTheoThang}", name: "Tiền đặt cọc theo tháng" },
  { token: "${DonGiaNgay}", name: "Đơn giá thuê theo ngày" },
  { token: "${CocTheoNgay}", name: "Tiền đặt cọc theo ngày" },
  { token: "${DonGiaGio}", name: "Đơn giá thuê theo giờ" },
  { token: "${CocTheoGio}", name: "Tiền đặt cọc theo giờ" },
  { token: "${KH_Ma}", name: "Mã khách hàng " },
  { token: "${KH_Ten}", name: "Tên khách hàng" },
  { token: "${KH_SDT}", name: "Số điện thoại khách hàng " },
  { token: "${KH_Email}", name: "Email " },
  { token: "${KH_QuocGia}", name: "Quốc gia" },
  { token: "${KHCN_GioiTinh}", name: "Giới tính" },
  { token: "${KHCN_NgaySinh}", name: "Ngày sinh" },
  { token: "${KHCN_CCCD}", name: "Số CMND/CCCD" },
  { token: "${KHCN_NgayCap}", name: "Ngày cấp CMND/CCCD" },
  { token: "${KHCN_NoiCap}", name: "Nơi cấp CMND/CCCD" },
  { token: "${KHCN_NgheNghiep}", name: "Nghề nghiệp" },
  { token: "${KHCN_NoiLamViec}", name: "Nơi làm việc" },
  { token: "${KHCN_QueQuan}", name: "Quê quán khách hàng" },
  { token: "${KHCN_VisaNoiCap}", name: "Nơi cấp visa" },
  { token: "${KHCN_VisaNgayHet}", name: "Ngày hết hạn visa" },
  { token: "${KHCN_QuocTich}", name: "Quốc tịch" },
  { token: "${KHCN_DiaChi}", name: "Địa chỉ hiện tại" },
  { token: "${KHTC_MaSoThue}", name: "Mã số thuế khách hàng tổ chức" },
  { token: "${KHTC_Website}", name: "Website khách hàng " },
  { token: "${KHTC_DiaChi}", name: "Địa chỉ khách hàng" },
  { token: "${KHTC_NganhNghe}", name: "Ngành nghề kinh doanh của khách hàng" },
  {
    token: "${KHTC_NguoiDaiDien}",
    name: "Họ tên người đại diện của khách hàng",
  },
  {
    token: "${KHTC_SDTDaiDien}",
    name: "Số điện thoại người đại diện của khách hàng",
  },
  {
    token: "${KHTC_EmailDaiDien}",
    name: "Email người đại diện của khách hàng",
  },
  { token: "${KHTC_NganHang}", name: "Tên ngân hàng" },
  { token: "${KHTC_ChiNhanh}", name: "Chi nhánh" },
  { token: "${KHTC_SoTaiKhoan}", name: "Số tài khoản" },
  { token: "${KHTC_TenTaiKhoan}", name: "Tên tài khoản" },
  { token: "${MaHopDong}", name: "Mã hợp đồng" },
  { token: "${SoHopDong}", name: "Số hợp đồng" },
  { token: "${TenHopDong}", name: "Tên hợp đồng" },
  { token: "${LoaiHopDong}", name: "Loại hợp đồng" },
  { token: "${ThoiHanHopDong}", name: "Thời hạn hợp đồng" },
  { token: "${NguoiTao}", name: "Người tạo hợp đồng" },
  { token: "${NgayThangNam}", name: "Ngày tạo hợp đồng" },
  { token: "${NgayBatDau}", name: "Thời gian bắt đầu hợp đồng" },
  { token: "${NgayKetThuc}", name: "Thời gian kết thúc hợp đồng" },
  { token: "${NgayTinhPhi}", name: "Ngày tính phí hợp đồng" },
  { token: "${NgayNhacXuatHoaDon}", name: "Ngày nhắc xuất hóa đơn" },
  { token: "${TienThue}", name: "Tiền thuê" },
  { token: "${TienThueBangChu}", name: "Tiền thuê bằng chữ" },
  { token: "${TienDatCoc}", name: "Số tiền đặt cọc hợp đồng" },
  { token: "${TienDatCocBangChu}", name: "Tiền đặt cọc bằng chữ" },
  { token: "${SoKhachThue}", name: "Số lượng khách thuê" },
  { token: "${NgayNhanBanGiao}", name: "Ngày  nhận bàn giao" },
  { token: "${NgayNhanBanGiao[Ngay]}", name: "Ngày nhận bàn giao - Ngày" },
  { token: "${NgayNhanBanGiao[Thang]}", name: "Ngày nhận bàn giao - Tháng" },
  { token: "${NgayNhanBanGiao[Nam]}", name: "Ngày nhận bàn giao - Năm" },
  { token: "${BanGiaoSoDien}", name: "Bàn giao số điện" },
  { token: "${BanGiaoSoNuoc}", name: "Bàn giao số nước" },
  { token: "${BanGiaoSoGas}", name: "Bàn giao số gas" },
  { token: "${CF_}", name: "Trường mở rộng" },
  { token: "${_KhachHang[STT]}", name: "STT" },
  { token: "${_KhachHang[Ten]}", name: "Tên khách thuê" },
  { token: "${_KhachHang[NgaySinh]}", name: "Ngày sinh" },
  { token: "${_KhachHang[CCCD]}", name: "CCCD" },
  { token: "${_KhachHang[SDT]}", name: "Số điện thoại" },
  { token: "${_DichVu[STT]}", name: "Số thứ tự trong bảng dịch vụ" },
  { token: "${_DichVu[Ten]}", name: "Tên dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[SL]}", name: "Số lượng dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[DonGia]}", name: "Đơn giá dịch vụ trong bảng dịch vụ" },
  {
    token: "${_DichVu[DonVi]}",
    name: "Đơn vị tính của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[KyThanhToan]}",
    name: "Kỳ thanh toán của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[NgayTinhPhi]}",
    name: "Ngày tính phí dịch vụ trong bảng dịch vụ",
  },
];

export const listTokenInvoice = [
  { token: "${LogoToChuc}", name: "Logo của tổ chức" },
  { token: "${TenToChuc}", name: "Tên của tổ chức" },
  { token: "${MaToChuc}", name: "Mã của tổ chức" },
  { token: "${MaSoThue}", name: "Mã số thuế" },
  { token: "${NganhNgheKinhDoanhTC}", name: "Ngành nghề kinh doanh" },
  { token: "${DiaChiToChuc}", name: "Địa chỉ của tổ chức" },
  { token: "${SoDienThoaiToChuc}", name: "Số điện thoại của tổ chức" },
  { token: "${EmailToChuc}", name: "Email của tổ chức" },
  { token: "${Website}", name: "Website của tổ chức" },
  { token: "${NguoiDaiDienToChuc}", name: "Người đại diện của tổ chức" },
  { token: "${ChucVu}", name: "Chức vụ của người đại diện" },
  { token: "${SDTNguoiDaiDien}", name: "Số điện thoại của người đại diện" },
  { token: "${EmailNguoiDaiDien}", name: "Email của người đại diện" },
  { token: "${NguoiLienHeToChuc}", name: "Người liên hệ của tổ chức" },
  { token: "${SDTNguoiLienHe}", name: "Số điện thoại của người liên hệ" },
  { token: "${EmailNguoiLienHe}", name: "Email của người liên hệ" },
  { token: "${MaToaNha}", name: "Mã tòa nhà" },
  { token: "${TenToaNha}", name: "Tên tòa nhà" },
  { token: "${TenToaNhaCha}", name: "Tên tòa nhà cha" },
  { token: "${SoTang}", name: "Số tầng" },
  { token: "${DiaChiToaNha}", name: "Địa chỉ tòa nhà" },
  { token: "${TenChuSoHuu}", name: "Chủ sở hữu tòa nhà" },
  { token: "${SDTChuSoHuu}", name: "Số điện thoại của chủ sở hữu" },
  { token: "${EmailChuSoHuu}", name: "Email của chủ sổ hữu" },
  { token: "${ViDo}", name: "Vĩ độ" },
  { token: "${KinhDo}", name: "Kinh độ" },
  { token: "${MaTang}", name: "Mã tầng" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${MaDVT}", name: "Mã đơn vị thuê" },
  { token: "${TenDVT}", name: "Tên đơn vị thuê" },
  { token: "${TenDVTCha}", name: "Tên đơn vị thuê cha" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${DienTichDVT}", name: "Diện tích đơn vị thuê" },
  { token: "${LoaiDVT}", name: "Loại đơn vị thuê" },
  { token: "${MucDichThue}", name: "Mục đích thuê" },
  { token: "${DonGiaMacDinh}", name: "Đơn giá mặc định" },
  { token: "${DonGiaThang}", name: "Đơn giá thuê theo tháng" },
  { token: "${CocTheoThang}", name: "Tiền đặt cọc theo tháng" },
  { token: "${DonGiaNgay}", name: "Đơn giá thuê theo ngày" },
  { token: "${CocTheoNgay}", name: "Tiền đặt cọc theo ngày" },
  { token: "${DonGiaGio}", name: "Đơn giá thuê theo giờ" },
  { token: "${CocTheoGio}", name: "Tiền đặt cọc theo giờ" },
  { token: "${KH_Ma}", name: "Mã khách hàng " },
  { token: "${KH_Ten}", name: "Tên khách hàng" },
  { token: "${KH_SDT}", name: "Số điện thoại khách hàng " },
  { token: "${KH_Email}", name: "Email " },
  { token: "${KH_QuocGia}", name: "Quốc gia" },
  { token: "${KHCN_GioiTinh}", name: "Giới tính" },
  { token: "${KHCN_NgaySinh}", name: "Ngày sinh" },
  { token: "${KHCN_CCCD}", name: "Số CMND/CCCD" },
  { token: "${KHCN_NgayCap}", name: "Ngày cấp CMND/CCCD" },
  { token: "${KHCN_NoiCap}", name: "Nơi cấp CMND/CCCD" },
  { token: "${KHCN_NgheNghiep}", name: "Nghề nghiệp" },
  { token: "${KHCN_NoiLamViec}", name: "Nơi làm việc" },
  { token: "${KHCN_QueQuan}", name: "Quê quán khách hàng" },
  { token: "${KHCN_VisaNoiCap}", name: "Nơi cấp visa" },
  { token: "${KHCN_VisaNgayHet}", name: "Ngày hết hạn visa" },
  { token: "${KHCN_QuocTich}", name: "Quốc tịch" },
  { token: "${KHCN_DiaChi}", name: "Địa chỉ hiện tại" },
  { token: "${KHTC_MaSoThue}", name: "Mã số thuế khách hàng tổ chức" },
  { token: "${KHTC_Website}", name: "Website khách hàng " },
  { token: "${KHTC_DiaChi}", name: "Địa chỉ khách hàng" },
  { token: "${KHTC_NganhNghe}", name: "Ngành nghề kinh doanh của khách hàng" },
  {
    token: "${KHTC_NguoiDaiDien}",
    name: "Họ tên người đại diện của khách hàng",
  },
  {
    token: "${KHTC_SDTDaiDien}",
    name: "Số điện thoại người đại diện của khách hàng",
  },
  {
    token: "${KHTC_EmailDaiDien}",
    name: "Email người đại diện của khách hàng",
  },
  { token: "${KHTC_NganHang}", name: "Tên ngân hàng" },
  { token: "${KHTC_ChiNhanh}", name: "Chi nhánh" },
  { token: "${KHTC_SoTaiKhoan}", name: "Số tài khoản" },
  { token: "${KHTC_TenTaiKhoan}", name: "Tên tài khoản" },
  { token: "${MaHopDong}", name: "Mã hợp đồng" },
  { token: "${SoHopDong}", name: "Số hợp đồng" },
  { token: "${TenHopDong}", name: "Tên hợp đồng" },
  { token: "${LoaiHopDong}", name: "Loại hợp đồng" },
  { token: "${ThoiHanHopDong}", name: "Thời hạn hợp đồng" },
  { token: "${NguoiTao}", name: "Người tạo hợp đồng" },
  { token: "${NgayThangNam}", name: "Ngày tạo hợp đồng" },
  { token: "${NgayBatDau}", name: "Thời gian bắt đầu hợp đồng" },
  { token: "${NgayKetThuc}", name: "Thời gian kết thúc hợp đồng" },
  { token: "${NgayTinhPhi}", name: "Ngày tính phí hợp đồng" },
  { token: "${NgayNhacXuatHoaDon}", name: "Ngày nhắc xuất hóa đơn" },
  { token: "${TienThue}", name: "Tiền thuê" },
  { token: "${TienThueBangChu}", name: "Tiền thuê bằng chữ" },
  { token: "${TienDatCoc}", name: "Số tiền đặt cọc hợp đồng" },
  { token: "${TienDatCocBangChu}", name: "Tiền đặt cọc bằng chữ" },
  { token: "${SoKhachThue}", name: "Số lượng khách thuê" },
  { token: "${NgayNhanBanGiao}", name: "Ngày  nhận bàn giao" },
  { token: "${NgayNhanBanGiao[Ngay]}", name: "Ngày nhận bàn giao - Ngày" },
  { token: "${NgayNhanBanGiao[Thang]}", name: "Ngày nhận bàn giao - Tháng" },
  { token: "${NgayNhanBanGiao[Nam]}", name: "Ngày nhận bàn giao - Năm" },
  { token: "${BanGiaoSoDien}", name: "Bàn giao số điện" },
  { token: "${BanGiaoSoNuoc}", name: "Bàn giao số nước" },
  { token: "${BanGiaoSoGas}", name: "Bàn giao số gas" },
  { token: "${CF_}", name: "Trường mở rộng" },
  { token: "${_KhachHang[STT]}", name: "STT" },
  { token: "${_KhachHang[Ten]}", name: "Tên khách thuê" },
  { token: "${_KhachHang[NgaySinh]}", name: "Ngày sinh" },
  { token: "${_KhachHang[CCCD]}", name: "CCCD" },
  { token: "${_KhachHang[SDT]}", name: "Số điện thoại" },
  { token: "${_DichVu[STT]}", name: "Số thứ tự trong bảng dịch vụ" },
  { token: "${_DichVu[Ten]}", name: "Tên dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[SL]}", name: "Số lượng dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[DonGia]}", name: "Đơn giá dịch vụ trong bảng dịch vụ" },
  {
    token: "${_DichVu[DonVi]}",
    name: "Đơn vị tính của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[KyThanhToan]}",
    name: "Kỳ thanh toán của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[NgayTinhPhi]}",
    name: "Ngày tính phí dịch vụ trong bảng dịch vụ",
  },
  { token: "${HD_Thang}", name: "Kỳ thanh toán" },
  { token: "${HD_Nam}", name: "Năm" },
  { token: "${HD_SoHoaDon}", name: "Số hóa đơn" },
  { token: "${HD_MaHoaDon}", name: "Mã hóa đơn" },
  { token: "${HD_LoaiHoaDon}", name: "Loại hóa đơn" },
  { token: "${HD_TenHoaDon}", name: "Tên hóa đơn" },
  { token: "${HD_NgayLap}", name: "Ngày lập hóa đơn" },
  { token: "${HD_HanThanhToan}", name: "Hạn thanh toán hóa đơn" },
  { token: "${_DichVu[STT]}", name: "Số thứ tự trong bảng dịch vụ" },
  { token: "${_DichVu[Ten]}", name: "Tên dịch vụ trong bảng dịch vụ" },
  {
    token: "${_DichVu[DonVi]}",
    name: "Đơn vị tính của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[Tu]}",
    name: "Ngày bắt đầu tính phí dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[Den]}",
    name: "Ngày kết thúc tính phí dịch vụ trong bảng dịch vụ",
  },
  { token: "${_DichVu[TG]}", name: "Thời gian sử dụng" },
  { token: "${_DichVu[Dau]}", name: "Chỉ số đầu của công tơ" },
  { token: "${_DichVu[Cuoi]}", name: "Chỉ số cuối của công tơ" },
  {
    token: "${_DichVu[SL]}",
    name: "Số lượng sử dụng dịch vụ trong bảng dịch vụ",
  },
  { token: "${_DichVu[DonGia]}", name: "Đơn giá dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[TamTinh]}", name: "Số tiền tạm tính của dịch vụ" },
  { token: "${_DichVu[DieuChinh]}", name: "Số tiền điều chỉnh của dịch vụ" },
  { token: "${_DichVu[ThanhTien]}", name: "Thành tiền của dịch vụ" },
  { token: "${_CongNo[STT]}", name: "Số thứ tự trong bảng công nợ kỳ trước" },
  { token: "${_CongNo[Thang]}", name: "Kỳ thanh toán" },
  { token: "${_CongNo[Nam]}", name: "Năm" },
  { token: "${_CongNo[TenHD]}", name: "Tên hóa đơn còn nợ" },
  { token: "${_CongNo[MaHD]}", name: "Mã hóa đơn còn nợ" },
  { token: "${_CongNo[SoHD]}", name: "Số hóa đơn còn nợ" },
  { token: "${_CongNo[LoaiHD]}", name: "Loại hóa đơn còn nợ" },
  { token: "${_CongNo[Tong]}", name: "Tổng tiền hóa đơn" },
  { token: "${_CongNo[DaThu]}", name: "Số tiền đã thanh toán" },
  { token: "${_CongNo[PhaiThu]}", name: "Số tiền còn nợ" },
  { token: "${_TraTruoc[STT]}", name: "Số thứ tự trong bảng trả trước" },
  { token: "${_TraTruoc[Thang]}", name: "Kỳ thanh toán" },
  { token: "${_TraTruoc[Nam]}", name: "Năm" },
  { token: "${_TraTruoc[TenHD]}", name: "Tên hóa đơn trả trước" },
  { token: "${_TraTruoc[MaHD]}", name: "Mã hóa đơn" },
  { token: "${_TraTruoc[SoHD]}", name: "Số hóa đơn" },
  { token: "${_TraTruoc[LoaiHD]}", name: "Loại hóa đơn" },
  { token: "${_TraTruoc[Tong]}", name: "Tổng tiền đã trả trước" },
  { token: "${_TraTruoc[ConLai]}", name: "Số tiền trả trước còn lại" },
  { token: "${_TraTruoc[ChiTra]}", name: "Số tiền chi trả cho hóa đơn này" },
];

export const listTokenVoucher = [
  { token: "${LogoToChuc}", name: "Logo của tổ chức" },
  { token: "${TenToChuc}", name: "Tên của tổ chức" },
  { token: "${MaToChuc}", name: "Mã của tổ chức" },
  { token: "${MaSoThue}", name: "Mã số thuế" },
  { token: "${NganhNgheKinhDoanhTC}", name: "Ngành nghề kinh doanh" },
  { token: "${DiaChiToChuc}", name: "Địa chỉ của tổ chức" },
  { token: "${SoDienThoaiToChuc}", name: "Số điện thoại của tổ chức" },
  { token: "${EmailToChuc}", name: "Email của tổ chức" },
  { token: "${Website}", name: "Website của tổ chức" },
  { token: "${NguoiDaiDienToChuc}", name: "Người đại diện của tổ chức" },
  { token: "${ChucVu}", name: "Chức vụ của người đại diện" },
  { token: "${SDTNguoiDaiDien}", name: "Số điện thoại của người đại diện" },
  { token: "${EmailNguoiDaiDien}", name: "Email của người đại diện" },
  { token: "${NguoiLienHeToChuc}", name: "Người liên hệ của tổ chức" },
  { token: "${SDTNguoiLienHe}", name: "Số điện thoại của người liên hệ" },
  { token: "${EmailNguoiLienHe}", name: "Email của người liên hệ" },
  { token: "${MaToaNha}", name: "Mã tòa nhà" },
  { token: "${TenToaNha}", name: "Tên tòa nhà" },
  { token: "${TenToaNhaCha}", name: "Tên tòa nhà cha" },
  { token: "${SoTang}", name: "Số tầng" },
  { token: "${DiaChiToaNha}", name: "Địa chỉ tòa nhà" },
  { token: "${TenChuSoHuu}", name: "Chủ sở hữu tòa nhà" },
  { token: "${SDTChuSoHuu}", name: "Số điện thoại của chủ sở hữu" },
  { token: "${EmailChuSoHuu}", name: "Email của chủ sổ hữu" },
  { token: "${ViDo}", name: "Vĩ độ" },
  { token: "${KinhDo}", name: "Kinh độ" },
  { token: "${MaTang}", name: "Mã tầng" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${MaDVT}", name: "Mã đơn vị thuê" },
  { token: "${TenDVT}", name: "Tên đơn vị thuê" },
  { token: "${TenDVTCha}", name: "Tên đơn vị thuê cha" },
  { token: "${TenTang}", name: "Tên tầng" },
  { token: "${DienTichDVT}", name: "Diện tích đơn vị thuê" },
  { token: "${LoaiDVT}", name: "Loại đơn vị thuê" },
  { token: "${MucDichThue}", name: "Mục đích thuê" },
  { token: "${DonGiaMacDinh}", name: "Đơn giá mặc định" },
  { token: "${DonGiaThang}", name: "Đơn giá thuê theo tháng" },
  { token: "${CocTheoThang}", name: "Tiền đặt cọc theo tháng" },
  { token: "${DonGiaNgay}", name: "Đơn giá thuê theo ngày" },
  { token: "${CocTheoNgay}", name: "Tiền đặt cọc theo ngày" },
  { token: "${DonGiaGio}", name: "Đơn giá thuê theo giờ" },
  { token: "${CocTheoGio}", name: "Tiền đặt cọc theo giờ" },
  { token: "${KH_Ma}", name: "Mã khách hàng " },
  { token: "${KH_Ten}", name: "Tên khách hàng" },
  { token: "${KH_SDT}", name: "Số điện thoại khách hàng " },
  { token: "${KH_Email}", name: "Email " },
  { token: "${KH_QuocGia}", name: "Quốc gia" },
  { token: "${KHCN_GioiTinh}", name: "Giới tính" },
  { token: "${KHCN_NgaySinh}", name: "Ngày sinh" },
  { token: "${KHCN_CCCD}", name: "Số CMND/CCCD" },
  { token: "${KHCN_NgayCap}", name: "Ngày cấp CMND/CCCD" },
  { token: "${KHCN_NoiCap}", name: "Nơi cấp CMND/CCCD" },
  { token: "${KHCN_NgheNghiep}", name: "Nghề nghiệp" },
  { token: "${KHCN_NoiLamViec}", name: "Nơi làm việc" },
  { token: "${KHCN_QueQuan}", name: "Quê quán khách hàng" },
  { token: "${KHCN_VisaNoiCap}", name: "Nơi cấp visa" },
  { token: "${KHCN_VisaNgayHet}", name: "Ngày hết hạn visa" },
  { token: "${KHCN_QuocTich}", name: "Quốc tịch" },
  { token: "${KHCN_DiaChi}", name: "Địa chỉ hiện tại" },
  { token: "${KHTC_MaSoThue}", name: "Mã số thuế khách hàng tổ chức" },
  { token: "${KHTC_Website}", name: "Website khách hàng " },
  { token: "${KHTC_DiaChi}", name: "Địa chỉ khách hàng" },
  { token: "${KHTC_NganhNghe}", name: "Ngành nghề kinh doanh của khách hàng" },
  {
    token: "${KHTC_NguoiDaiDien}",
    name: "Họ tên người đại diện của khách hàng",
  },
  {
    token: "${KHTC_SDTDaiDien}",
    name: "Số điện thoại người đại diện của khách hàng",
  },
  {
    token: "${KHTC_EmailDaiDien}",
    name: "Email người đại diện của khách hàng",
  },
  { token: "${KHTC_NganHang}", name: "Tên ngân hàng" },
  { token: "${KHTC_ChiNhanh}", name: "Chi nhánh" },
  { token: "${KHTC_SoTaiKhoan}", name: "Số tài khoản" },
  { token: "${KHTC_TenTaiKhoan}", name: "Tên tài khoản" },
  { token: "${MaHopDong}", name: "Mã hợp đồng" },
  { token: "${SoHopDong}", name: "Số hợp đồng" },
  { token: "${TenHopDong}", name: "Tên hợp đồng" },
  { token: "${LoaiHopDong}", name: "Loại hợp đồng" },
  { token: "${ThoiHanHopDong}", name: "Thời hạn hợp đồng" },
  { token: "${NguoiTao}", name: "Người tạo hợp đồng" },
  { token: "${NgayThangNam}", name: "Ngày tạo hợp đồng" },
  { token: "${NgayBatDau}", name: "Thời gian bắt đầu hợp đồng" },
  { token: "${NgayKetThuc}", name: "Thời gian kết thúc hợp đồng" },
  { token: "${NgayTinhPhi}", name: "Ngày tính phí hợp đồng" },
  { token: "${NgayNhacXuatHoaDon}", name: "Ngày nhắc xuất hóa đơn" },
  { token: "${TienThue}", name: "Tiền thuê" },
  { token: "${TienThueBangChu}", name: "Tiền thuê bằng chữ" },
  { token: "${TienDatCoc}", name: "Số tiền đặt cọc hợp đồng" },
  { token: "${TienDatCocBangChu}", name: "Tiền đặt cọc bằng chữ" },
  { token: "${SoKhachThue}", name: "Số lượng khách thuê" },
  { token: "${NgayNhanBanGiao}", name: "Ngày  nhận bàn giao" },
  { token: "${NgayNhanBanGiao[Ngay]}", name: "Ngày nhận bàn giao - Ngày" },
  { token: "${NgayNhanBanGiao[Thang]}", name: "Ngày nhận bàn giao - Tháng" },
  { token: "${NgayNhanBanGiao[Nam]}", name: "Ngày nhận bàn giao - Năm" },
  { token: "${BanGiaoSoDien}", name: "Bàn giao số điện" },
  { token: "${BanGiaoSoNuoc}", name: "Bàn giao số nước" },
  { token: "${BanGiaoSoGas}", name: "Bàn giao số gas" },
  { token: "${CF_}", name: "Trường mở rộng" },
  { token: "${_KhachHang[STT]}", name: "STT" },
  { token: "${_KhachHang[Ten]}", name: "Tên khách thuê" },
  { token: "${_KhachHang[NgaySinh]}", name: "Ngày sinh" },
  { token: "${_KhachHang[CCCD]}", name: "CCCD" },
  { token: "${_KhachHang[SDT]}", name: "Số điện thoại" },
  { token: "${_DichVu[STT]}", name: "Số thứ tự trong bảng dịch vụ" },
  { token: "${_DichVu[Ten]}", name: "Tên dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[SL]}", name: "Số lượng dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[DonGia]}", name: "Đơn giá dịch vụ trong bảng dịch vụ" },
  {
    token: "${_DichVu[DonVi]}",
    name: "Đơn vị tính của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[KyThanhToan]}",
    name: "Kỳ thanh toán của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[NgayTinhPhi]}",
    name: "Ngày tính phí dịch vụ trong bảng dịch vụ",
  },
  { token: "${HD_Thang}", name: "Kỳ thanh toán" },
  { token: "${HD_Nam}", name: "Năm" },
  { token: "${HD_SoHoaDon}", name: "Số hóa đơn" },
  { token: "${HD_MaHoaDon}", name: "Mã hóa đơn" },
  { token: "${HD_LoaiHoaDon}", name: "Loại hóa đơn" },
  { token: "${HD_TenHoaDon}", name: "Tên hóa đơn" },
  { token: "${HD_NgayLap}", name: "Ngày lập hóa đơn" },
  { token: "${HD_HanThanhToan}", name: "Hạn thanh toán hóa đơn" },
  { token: "${_DichVu[STT]}", name: "Số thứ tự trong bảng dịch vụ" },
  { token: "${_DichVu[Ten]}", name: "Tên dịch vụ trong bảng dịch vụ" },
  {
    token: "${_DichVu[DonVi]}",
    name: "Đơn vị tính của dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[Tu]}",
    name: "Ngày bắt đầu tính phí dịch vụ trong bảng dịch vụ",
  },
  {
    token: "${_DichVu[Den]}",
    name: "Ngày kết thúc tính phí dịch vụ trong bảng dịch vụ",
  },
  { token: "${_DichVu[TG]}", name: "Thời gian sử dụng" },
  { token: "${_DichVu[Dau]}", name: "Chỉ số đầu của công tơ" },
  { token: "${_DichVu[Cuoi]}", name: "Chỉ số cuối của công tơ" },
  {
    token: "${_DichVu[SL]}",
    name: "Số lượng sử dụng dịch vụ trong bảng dịch vụ",
  },
  { token: "${_DichVu[DonGia]}", name: "Đơn giá dịch vụ trong bảng dịch vụ" },
  { token: "${_DichVu[TamTinh]}", name: "Số tiền tạm tính của dịch vụ" },
  { token: "${_DichVu[DieuChinh]}", name: "Số tiền điều chỉnh của dịch vụ" },
  { token: "${_DichVu[ThanhTien]}", name: "Thành tiền của dịch vụ" },
  { token: "${_CongNo[STT]}", name: "Số thứ tự trong bảng công nợ kỳ trước" },
  { token: "${_CongNo[Thang]}", name: "Kỳ thanh toán" },
  { token: "${_CongNo[Nam]}", name: "Năm" },
  { token: "${_CongNo[TenHD]}", name: "Tên hóa đơn còn nợ" },
  { token: "${_CongNo[MaHD]}", name: "Mã hóa đơn còn nợ" },
  { token: "${_CongNo[SoHD]}", name: "Số hóa đơn còn nợ" },
  { token: "${_CongNo[LoaiHD]}", name: "Loại hóa đơn còn nợ" },
  { token: "${_CongNo[Tong]}", name: "Tổng tiền hóa đơn" },
  { token: "${_CongNo[DaThu]}", name: "Số tiền đã thanh toán" },
  { token: "${_CongNo[PhaiThu]}", name: "Số tiền còn nợ" },
  { token: "${_TraTruoc[STT]}", name: "Số thứ tự trong bảng trả trước" },
  { token: "${_TraTruoc[Thang]}", name: "Kỳ thanh toán" },
  { token: "${_TraTruoc[Nam]}", name: "Năm" },
  { token: "${_TraTruoc[TenHD]}", name: "Tên hóa đơn trả trước" },
  { token: "${_TraTruoc[MaHD]}", name: "Mã hóa đơn" },
  { token: "${_TraTruoc[SoHD]}", name: "Số hóa đơn" },
  { token: "${_TraTruoc[LoaiHD]}", name: "Loại hóa đơn" },
  { token: "${_TraTruoc[Tong]}", name: "Tổng tiền đã trả trước" },
  { token: "${_TraTruoc[ConLai]}", name: "Số tiền trả trước còn lại" },
  { token: "${_TraTruoc[ChiTra]}", name: "Số tiền chi trả cho hóa đơn này" },
  { token: "${MaPT}", name: "Mã phiếu thu" },
  { token: "${TenPT}", name: "Tên phiếu thu" },
  { token: "${NgayLapPT}", name: "Ngày lập phiếu thu" },
  { token: "${NguoiNopTien}", name: "Người nộp tiền" },
  { token: "${DiaChiNguoiNop}", name: "Địa chỉ của người nộp tiền" },
  { token: "${LyDoNopTien}", name: "Lý do nộp tiền" },
  { token: "${NguoiLapPT}", name: "Người lập phiếu thu" },
  { token: "${ThuNganPT}", name: "Thu ngân" },
  { token: "${KeToanTruongPT}", name: "Kế toán trưởng" },
  { token: "${GiamDocPT}", name: "Giám đốc" },
  { token: "${SoTienPT}", name: "Số tiền" },
  { token: "${SoTienBangChuPT}", name: "Số tiền bằng chữ" },
  { token: "${KemTheoPT}", name: "Kèm theo" },
  { token: "${_KhoanThu[STT]}", name: "Số thứ tự trong danh sách khoản thu" },
  { token: "${_KhoanThu[HangMuc]}", name: "Hạng mục thu" },
  { token: "${_KhoanThu[HoaDon]}", name: "Số hóa đơn cần thu" },
  { token: "${_KhoanThu[PhaiThu]}", name: "Tổng số tiền phải thu của hóa đơn" },
  { token: "${_KhoanThu[SoTien]}", name: "Số tiền thực thu" },
  { token: "${_KhoanThu[DienGiai]}", name: "Diễn giải" },
  { token: "${_TaiKhoanPT[STT]}", name: "Số thứ tự trong danh sách tài khoản" },
  { token: "${_TaiKhoanPT[STK]}", name: "Số tài khoản ngân hàng" },
  { token: "${_TaiKhoanPT[CTK]}", name: "Đơn vị thụ hưởng" },
  { token: "${_TaiKhoanPT[MaNganHang]}", name: "Tên ngân hàng thụ hưởng" },
  { token: "${_TaiKhoanPT[ChiNhanh]}", name: "Chi nhánh ngân hàng" },
  { token: "${_TaiKhoanPT[LoaiVi]}", name: "Tên ví điện tử" },
  { token: "${_TaiKhoanPT[SoTien]}", name: "Số tiền đã thu" },
  { token: "${_TaiKhoanPT[TiGia]}", name: "Tỷ giá tiền tệ" },
  { token: "${_TaiKhoanPT[ThanhTien]}", name: "Thành tiền" },
  { token: "${_TaiKhoanPT[TienTe]}", name: "Đơn vị tiền tệ" },
  { token: "${MaPC}", name: "Mã phiếu chi" },
  { token: "${TenPC}", name: "Tên phiếu chi" },
  { token: "${NgayLapPC}", name: "Ngày lập phiếu chi" },
  { token: "${NguoiNhanTien}", name: "Người nhận tiền" },
  { token: "${DiaChiNguoiNhan}", name: "Địa chỉ của người nhận tiền" },
  { token: "${LyDoChiTien}", name: "Lý do chi tiền" },
  { token: "${NguoiLapPC}", name: "Người lập phiếu thu" },
  { token: "${ThuNganPC}", name: "Thu ngân" },
  { token: "${KeToanTruongPC}", name: "Kế toán trưởng" },
  { token: "${GiamDocPC}", name: "Giám đốc" },
  { token: "${SoTienPC}", name: "Số tiền" },
  { token: "${SoTienBangChuPC}", name: "Số tiền bằng chữ" },
  { token: "${KemTheoPC}", name: "Kèm theo" },
  { token: "${_KhoanChi[STT]}", name: "Số thứ tự trong danh sách khoản chi" },
  { token: "${_KhoanChi[HangMuc]}", name: "Hạng mục chi" },
  { token: "${_KhoanChi[HoaDon]}", name: "Tên hóa đơn cần chi" },
  { token: "${_KhoanChi[PhaiThu]}", name: "Tổng số tiền phải chi của hóa đơn" },
  { token: "${_KhoanChi[SoTien]}", name: "Số tiền thực chi" },
  { token: "${_KhoanChi[DienGiai]}", name: "Diễn giải" },
  { token: "${_TaiKhoanPC[STT]}", name: "Số thứ tự trong danh sách tài khoản" },
  { token: "${_TaiKhoanPC[STK]}", name: "Số tài khoản ngân hàng" },
  { token: "${_TaiKhoanPC[CTK]}", name: "Đơn vị thụ hưởng" },
  { token: "${_TaiKhoanPC[MaNganHang]}", name: "Tên ngân hàng thụ hưởng" },
  { token: "${_TaiKhoanPC[ChiNhanh]}", name: "Chi nhánh ngân hàng" },
  { token: "${_TaiKhoanPC[LoaiVi]}", name: "Tên ví điện tử" },
  { token: "${_TaiKhoanPC[SoTien]}", name: "Số tiền đã chi" },
  { token: "${_TaiKhoanPC[TiGia]}", name: "Tỷ giá tiền tệ" },
  { token: "${_TaiKhoanPC[ThanhTien]}", name: "Thành tiền" },
  { token: "${_TaiKhoanPC[TienTe]}", name: "Đơn vị tiền tệ" },
];

export const listTokenAppendix = [
  { token: "${SoHopDong}", name: "Số hợp đồng thuê" },
  { token: "${NgayThangNam}", name: "Ngày ký hợp đồng thuê" },
  { token: "${NgayKetThuc}", name: "Ngày kết thúc cũ của hợp đồng" },
  { token: "${NgayKetThucMoi}", name: "Ngày kết thúc mới của hợp đồng" },
  { token: "${NgayGiaHan}", name: "Ngày gia hạn hợp đồng" },
  { token: "${TenToChuc}", name: "Tên tổ chức" },
  { token: "${MaSoThue}", name: "Mã số thuế" },
  { token: "${DiaChiToChuc}", name: "Địa chỉ tổ chức" },
  { token: "${TinhThanhToChuc}", name: "Tỉnh thành tổ chức" },
  { token: "${SoDienThoaiToChuc}", name: "Số điện thoại của tổ chức" },
  { token: "${NguoiDaiDienToChuc}", name: "Người đại diện tổ chức" },
  { token: "${ChucVu}", name: "Chức vụ người đại diện" },
  { token: "${TenKhachThue}", name: "Tên khách thuê" },
  { token: "${CCCD}", name: "Số căn cước công dân" },
  { token: "${NgayCap}", name: "Ngày cấp căn cước công dân" },
  { token: "${NoiCap}", name: "Nơi cấp" },
  { token: "${SoDienThoaiKhachThue}", name: "Số điện thoại của khách thuê" },
  { token: "${EmailKhachThue}", name: "Email của khách thuê" },
  { token: "${DiaChiKhachThue}", name: "Địa chỉ của khách thuê" },
  { token: "${MaSoThueKhachThue}", name: "Mã số thuế của khách thuê tổ chức" },
  { token: "${DiaChiKhachThueToChuc}", name: "Địa chỉ của khách thuê tổ chức" },
  {
    token: "${DaiDienKhachThue}",
    name: "Họ tên người đại diện của khách thuê tổ chức",
  },
  { token: "${SoDienThoaiDaiDien}", name: "Số điện thoại của người đại diện" },
  { token: "${EmailDaiDien}", name: "Email của người đại diện" },
  { token: "${LoaiHopDong}", name: "Loại hợp đồng" },
  { token: "${TenTaiSan}", name: "Tên tài sản " },
  { token: "${SoLuongTS}", name: "Số lượng tài sản có trong đơn vị thuê" },
];

export const getStringPriceType = (priceType, money) => {
  let stringPriceType = "";
  if (priceType === UNIT_PRICE_TYPE_OBJ.PER_HOUR) {
    stringPriceType = `${money} / giờ`;
  } else if (priceType === UNIT_PRICE_TYPE_OBJ.PER_DAY) {
    stringPriceType = `${money} / ngày`;
  } else if (priceType === UNIT_PRICE_TYPE_OBJ.PER_MONTH) {
    stringPriceType = `${money} / tháng`;
  }

  return stringPriceType;
};

export const UnitStatusIsDepositTitles = (status, isDeposit) => {
  if (status === UNIT_STATUS_OBJ.EMPTY) {
    if (isDeposit === true) {
      return "Sắp thuê";
    } else {
      return "Trống";
    }
  } else if (status === UNIT_STATUS_OBJ.RENTED) {
    if (isDeposit === true) {
      return "Đã thuê";
    } else {
      return "Đã thuê";
    }
  } else {
    return "Tạm ngưng";
  }
};
export const ShowToken = ({ listToken }) => {
  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: "none" }}>
      <Popover.Title className="bg-primary text-white py-2 text-center" as="h3">
        Token Email
      </Popover.Title>

      <Popover.Content>
        <table
          className="table table-head-custom table-vertical-center table-sm table-bordered table-hover card-scroll"
          style={{ maxWidth: "400px" }}
        >
          <thead>
            <tr>
              <th className="text-center">STT</th>
              <th className="text-center">Token</th>
              <th className="text-center">Tên</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {listToken.map((row, rowIndex) => (
              <>
                <tr>
                  <td className="text-center">{rowIndex + 1}</td>
                  <td className="text-left" id={row.token}>
                    {row.token}
                  </td>
                  <td className="text-left">{row.name}</td>
                  <td className="text-left">
                    <span
                      className="text-hover-primary px-2 pt-1"
                      type="button"
                      onClick={() => {
                        copyString(row.token);
                      }}
                    >
                      <i className="far fa-copy"></i>
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <span type="button">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`button-tooltip`}>
              <FormattedMessage id="MODEL.UI.contractForms.appendix"></FormattedMessage>
            </Tooltip>
          }
        >
          <span type="button">
            <i className="fas fa-info-circle text-hover-primary mr-3"></i>
          </span>
        </OverlayTrigger>
      </span>
    </OverlayTrigger>
  );
};

export const AccordionToken = ({ listToken, id }) => {
  const [isCollapse, setIsCollapse] = useState(true);
  return (
    <div className="accordion accordion-toggle-arrow mb-4">
      <div className="card">
        <div className="card-header" onClick={() => setIsCollapse(!isCollapse)}>
          <div
            className="card-title py-0"
            data-toggle="collapse"
            data-target={`#${id}`}
          >
            <b className="py-1">Danh sách token</b>
          </div>
        </div>
        <div
          id={id}
          className={isCollapse === true ? `collapse` : `collapse show`}
        >
          <div
            className="card-body pb-0"
            style={{ height: "400px", overflow: "scroll" }}
          >
            <table className="table table-head-custom table-vertical-center table-sm table-bordered table-hover card-scroll mb-0">
              <thead>
                <tr>
                  <th className="text-center">STT</th>
                  <th className="text-center">Token</th>
                  <th className="text-center">Tên</th>
                  <th className="text-center"></th>
                  <th className="text-center">STT</th>
                  <th className="text-center">Token</th>
                  <th className="text-center">Tên</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {listToken.map((row, rowIndex) => (
                  <>
                    {rowIndex % 2 === 0 && (
                      <tr>
                        <td className="text-center">{rowIndex + 1}</td>
                        <td className="text-left" id={row.token}>
                          {row.token}
                        </td>
                        <td className="text-left">{row.name}</td>
                        <td className="text-center">
                          <span
                            className="text-hover-primary px-2 pt-1"
                            type="button"
                            onClick={() => {
                              copyString(row.token);
                            }}
                          >
                            <i className="far fa-copy"></i>
                          </span>
                        </td>
                        <td className="text-center">{rowIndex + 2}</td>
                        <td
                          className="text-left"
                          id={
                            listToken[rowIndex + 1] &&
                            listToken[rowIndex + 1].token
                          }
                        >
                          {listToken[rowIndex + 1] &&
                            listToken[rowIndex + 1].token}
                        </td>
                        <td className="text-left">
                          {listToken[rowIndex + 1] &&
                            listToken[rowIndex + 1].name}
                        </td>
                        {listToken[rowIndex + 1] &&
                          listToken[rowIndex + 1].token && (
                            <td className="text-center">
                              <span
                                className="text-hover-primary px-2 pt-1"
                                type="button"
                                onClick={() => {
                                  copyString(
                                    listToken[rowIndex + 1] &&
                                    listToken[rowIndex + 1].token
                                  );
                                }}
                              >
                                <i className="far fa-copy"></i>
                              </span>
                            </td>
                          )}
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const useOutsideEffect = (ref, handleOutside) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const getColorUnitStatus = (status, isDeposit) => {
  let cssUnitChoose = "";
  if (status === UNIT_STATUS_OBJ.EMPTY) {
    if (isDeposit === true) {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_EMPTY_DEPOSIT;
    } else {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_EMPTY;
    }
  } else if (status === UNIT_STATUS_OBJ.RENTED) {
    if (isDeposit === true) {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_RENTED_DEPOSIT;
    } else {
      cssUnitChoose = BG_TYPE_OBJ.UNIT_RENTED;
    }
  } else {
    cssUnitChoose = BG_TYPE_OBJ.UNIT_STOPPED;
  }

  return cssUnitChoose;
};

export const getLightColorUnitStatus = (status, isDeposit) => {
  let cssUnitChoose = "";
  if (status === UNIT_STATUS_OBJ.EMPTY) {
    if (isDeposit === true) {
      cssUnitChoose = LIGHT_BG_TYPE_OBJ.UNIT_EMPTY_DEPOSIT;
    } else {
      cssUnitChoose = LIGHT_BG_TYPE_OBJ.UNIT_EMPTY;
    }
  } else if (status === UNIT_STATUS_OBJ.RENTED) {
    if (isDeposit === true) {
      cssUnitChoose = LIGHT_BG_TYPE_OBJ.UNIT_RENTED_DEPOSIT;
    } else {
      cssUnitChoose = LIGHT_BG_TYPE_OBJ.UNIT_RENTED;
    }
  } else {
    cssUnitChoose = LIGHT_BG_TYPE_OBJ.UNIT_STOPPED;
  }

  return cssUnitChoose;
};

export const ProhibitLink = [
  "new",
  "delete",
  "clone",
  "edit",
  "updateStatus",
  "import",
];

export const getStringIsForeign = (isForeign) => {
  if (isForeign === undefined || isForeign === null) {
    return "";
  } else {
    if (isForeign === false) {
      return "Trong nước";
    } else return "Nước ngoài";
  }
};

export const getColorStatusExpire = (expireStatus) => {
  if (expireStatus) {
    return "success";
  } else {
    return "danger";
  }
};

export const getStringStatusExpire = (expireStatus) => {
  if (expireStatus) {
    return STRING_EXPIRE_LABEL;
  } else {
    return STRING_UN_EXPIRE_LABEL;
  }
};

export const getLabel = (label) => {
  if (label === null || label === undefined) {
    return STRING_DEFAULT_LABEL;
  } else {
    return label;
  }
};

export const convertTimestampToHours = (timeStamp) => {
  return Math.floor(timeStamp / 3600);
};

export const getHoursTwoDate = (dayBefore, dayAfter) => {
  let yearBefore = Number(dayBefore.slice(0, 4));
  let monthBefore = Number(dayBefore.slice(5, 7));
  let dateBefore = Number(dayBefore.slice(8, 10));
  let yearAfter = Number(dayAfter.slice(0, 4));
  let monthAfter = Number(dayAfter.slice(5, 7));
  let dateAfter = Number(dayAfter.slice(8, 10));
  let hoursBefore = yearBefore * 8766 + monthBefore * 730 + dateBefore * 24;
  let hoursAfter = yearAfter * 8766 + monthAfter * 730 + dateAfter * 24;
  if (hoursAfter > hoursBefore) {
    return Number(hoursAfter - hoursBefore);
  } else {
    return 0;
  }
};

export const getStatusBuilding = (buildingId, buildings) => {
  const building = buildings.filter((item) => item.id === buildingId);
  if (building[0]?.status === BUILDING_STATUS_OBJ.USING) {
    return BUILDING_STATUS_OBJ.USING;
  } else if (building[0]?.status === BUILDING_STATUS_OBJ.STOPPED) {
    return BUILDING_STATUS_OBJ.STOPPED;
  }
};

export const TRANSACTION_HISTORIES_FUNCTION_FILTER = [
  { value: 1, label: "Thanh toán hóa đơn" },
  { value: 2, label: "Trả lương" },
];

export const getStringUpperFirstLetter = (str) => {
  let _str = str.toLowerCase();
  return _str.charAt(0).toUpperCase() + _str.slice(1);
};

export function sortField(data, field) {
  let _data = JSON.parse(JSON.stringify(data));
  _data.sort((a, b) => a[field] - b[field]);
  return _data;
}
