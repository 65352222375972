/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { Administrator } from "./privileges/Administrator";
import { Collaborator } from "./privileges/Collaborator";
import { Partner } from "./privileges/Partner";
import { User } from "./privileges/User";


export function AsideMenuList({ layoutProps }) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <Administrator /> {/* Vai trò quản trị */}
        <User /> {/* Vai trò nhân viên */}
        <Collaborator /> {/* Vai trò môi giới */}
        <Partner /> {/* Vai trò đối tác */}
      </ul>
    </>
  );
}
