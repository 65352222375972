import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (size, touched, errors) => {
  const classes = ["form-control", `form-control-${size}`];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function CheckboxField({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required = false,
  withLabel = true,
  withSpanLabel = true,
  withFeedbackLabel = true,
  customFeedbackLabel,
  className,
  size = "md",
  type = "checkbox",
  children,
  checked = field.value,
  ...props
}) {
  return (
    <>
      {withLabel && label && <label>{label} {required && <span className="required">*</span>}</label>}
      {type === 'checkbox' && (
        <div className={`d-flex justify-content-center ${className}`}>
          <label className='checkbox'>
            <input
              checked={field.value || false}
              type={type}
              className={getFieldCSSClasses(size, touched[field.name], errors[field.name])}
              {...field}
              {...props}
              value={field.value || false}
            />
            {children}
            <span></span> {withSpanLabel}
          </label>
        </div>
      )}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
