/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { getFilterConfig } from "../../../_helpers/LocalStorageConfig";

const CurrenciesUIContext = createContext();

export function useCurrenciesUIContext() {
  return useContext(CurrenciesUIContext);
}

export const CurrenciesUIConsumer = CurrenciesUIContext.Consumer;

export function CurrenciesUIProvider({ currencies, selectedRows, setSelectedRows, children }) {
  const initialFilter = {
    filter: {
      searchCriteria: [

        {
          key: "name",
          operation: ":",
          value: "",
          prefix: "*",
          suffix: "*"
        },
      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "displayOrder",
    pageNumber: 1,
    pageSize: 35,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [currentCurrencies, setCurrentCurrencies] = useState(currencies);
  const [ids, setIds] = useState([]);


  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {

  }, []);
  const [
    showEditCurrencyDialog,
    setShowEditCurrencyDialog,
  ] = useState(false);
  const openNewCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(true);
  };
  const openEditCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowEditCurrencyDialog(true);
  };
  const closeEditCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(false);
  };
  const [
    showDeleteCurrencyDialog,
    setShowDeleteCurrencyDialog,
  ] = useState(false);

  const openDeleteCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowDeleteCurrencyDialog(true);
  };
  const closeDeleteCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowDeleteCurrencyDialog(false);
  };

  const [
    showDeleteCurrenciesDialog,
    setShowDeleteCurrenciesDialog,
  ] = useState(false);
  const openDeleteCurrenciesDialog = () => {
    setShowDeleteCurrenciesDialog(true);
  };
  const closeDeleteCurrenciesDialog = () => {
    setShowDeleteCurrenciesDialog(false);
  };
  const [
    showFetchCurrenciesDialog,
    setShowFetchCurrenciesDialog,
  ] = useState(false);
  const openFetchCurrenciesDialog = () => {
    setShowFetchCurrenciesDialog(true);
  };
  const closeFetchCurrenciesDialog = () => {
    setShowFetchCurrenciesDialog(false);
  };

  const value = {
    ids,
    setIds,
    selectedRows,
    setSelectedRows,

    queryParams,
    setQueryParams,
    selectedId,
    currentCurrencies,
    showEditCurrencyDialog,
    openEditCurrencyDialog,
    openNewCurrencyDialog,
    closeEditCurrencyDialog,
    showDeleteCurrencyDialog,
    openDeleteCurrencyDialog,
    closeDeleteCurrencyDialog,
    showDeleteCurrenciesDialog,
    openDeleteCurrenciesDialog,
    closeDeleteCurrenciesDialog,
    showFetchCurrenciesDialog,
    openFetchCurrenciesDialog,
    closeFetchCurrenciesDialog,
  };

  return (
    <CurrenciesUIContext.Provider value={value}>
      {children}
    </CurrenciesUIContext.Provider>
  );
}
