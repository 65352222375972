/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { getFilterConfig } from "../../../../../_helpers/LocalStorageConfig";

const AttachmentsUIContext = createContext();

export function useAttachmentsUIContext() {
  return useContext(AttachmentsUIContext);
}

export const AttachmentsUIConsumer = AttachmentsUIContext.Consumer;

export function AttachmentsUIProvider({ selectedRows, setSelectedRows, isMulti, children }) {
  const initialFilter = {
    filter: {
      searchCriteria: [


      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "createdAt",
    pageNumber: 1,
    pageSize: 7,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  // const [currentAttachments, setCurrentAttachments] = useState(documents);
  const [ids, setIds] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {

  }, []);
  const [
    showEditCurrencyDialog,
    setShowEditCurrencyDialog,
  ] = useState(false);
  const openNewCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(true);
  };
  const openEditCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowEditCurrencyDialog(true);
  };
  const closeEditCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(false);
  };
  const [
    showDeleteCurrencyDialog,
    setShowDeleteCurrencyDialog,
  ] = useState(false);

  const openDeleteCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowDeleteCurrencyDialog(true);
  };
  const closeDeleteCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowDeleteCurrencyDialog(false);
  };

  const [
    showDeleteAttachmentsDialog,
    setShowDeleteAttachmentsDialog,
  ] = useState(false);
  const openDeleteAttachmentsDialog = () => {
    setShowDeleteAttachmentsDialog(true);
  };
  const closeDeleteAttachmentsDialog = () => {
    setShowDeleteAttachmentsDialog(false);
  };
  const [
    showFetchAttachmentsDialog,
    setShowFetchAttachmentsDialog,
  ] = useState(false);
  const openFetchAttachmentsDialog = () => {
    setShowFetchAttachmentsDialog(true);
  };
  const closeFetchAttachmentsDialog = () => {
    setShowFetchAttachmentsDialog(false);
  };

  const value = {
    ids,
    setIds,
    selectedRows,
    setSelectedRows,

    queryParams,
    setQueryParams,
    selectedId,
    isMulti,
    // currentAttachments,
    showEditCurrencyDialog,
    openEditCurrencyDialog,
    openNewCurrencyDialog,
    closeEditCurrencyDialog,
    showDeleteCurrencyDialog,
    openDeleteCurrencyDialog,
    closeDeleteCurrencyDialog,
    showDeleteAttachmentsDialog,
    openDeleteAttachmentsDialog,
    closeDeleteAttachmentsDialog,
    showFetchAttachmentsDialog,
    openFetchAttachmentsDialog,
    closeFetchAttachmentsDialog,
  };

  return (
    <AttachmentsUIContext.Provider value={value}>
      {children}
    </AttachmentsUIContext.Provider>
  );
}
