import { isEmpty } from "./_CommonUIHelpers";

const FILTER_CONFIG_KEY =
  process.env.REACT_APP_FILTER_CONFIG_KEY || "FilterConfig";

export function resetFilterConfig() {
  localStorage.setItem(FILTER_CONFIG_KEY, "");
  localStorage.removeItem(FILTER_CONFIG_KEY);
}

export function getFilterConfig() {
  const ls = localStorage.getItem(FILTER_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  const DEFAULT_VALUE = "";
  return {
    activeBuildingId: DEFAULT_VALUE,
    activeUnitId: DEFAULT_VALUE,
    activeServiceType: DEFAULT_VALUE,
    activeMonth: DEFAULT_VALUE,
    activeYear: DEFAULT_VALUE,
    activeMonthMeterLogs: DEFAULT_VALUE,
    activeYearMeterLogs: DEFAULT_VALUE,
    activeContractId: DEFAULT_VALUE,
    activeInvoiceType: DEFAULT_VALUE,
    activeInvoiceId: DEFAULT_VALUE,
    activeMeterId: DEFAULT_VALUE,
    activeBankId: DEFAULT_VALUE,
    activeMeterIsShared: DEFAULT_VALUE,
    activeDeskModeView: DEFAULT_VALUE,
    activeUnitScreenModeView: "GRID",
    activeServicesScreenModeView: "SERVICES_GRID",
    activeInvoicesScreenModeView: "INVOICES_GRID",
    showOrganizationUpdate: false,
    screenId: DEFAULT_VALUE,
    activeAssetInventoryPeriodId: DEFAULT_VALUE,
  };
}

export function setFilterConfig(key, value) {
  const filterConfig = getFilterConfig();
  if (key === "activeBuildingId" && (value || value === 0)) {
    filterConfig.activeBuildingId = !isEmpty(value) ? Number(value) : "";
  } else if (key === "activeUnitId" && value) {
    filterConfig.activeUnitId = !isEmpty(value) ? Number(value) : "";
  } else if (key === "activeServiceType") {
    filterConfig.activeServiceType = value;
  } else if (key === "activeMonth" && value) {
    if (!isEmpty(value)) {
      filterConfig.activeMonth = Number(value);
      filterConfig.activeMonthMeterLogs =
        Number(value) === 1 ? 12 : Number(value - 1);
      filterConfig.activeYearMeterLogs =
        Number(value) === 1
          ? filterConfig.activeYear - 1
          : filterConfig.activeYear;
    }
  } else if (key === "activeYear" && value) {
    filterConfig.activeYear = !isEmpty(value) ? Number(value) : "";
    if (Number(filterConfig.activeMonth) === 1) {
      filterConfig.activeYearMeterLogs = Number(value - 1);
    } else Number(value);
  } else if (key === "activeMonthMeterLogs" && value) {
    filterConfig.activeMonthMeterLogs = value;
  } else if (key === "activeYearMeterLogs" && value) {
    filterConfig.activeYearMeterLogs = value;
  } else if (key === "activeContractId" && value) {
    filterConfig.activeContractId = value;
  } else if (key === "activeInvoiceType") {
    filterConfig.activeInvoiceType = value;
  } else if (key === "activeInvoiceId") {
    filterConfig.activeInvoiceId = !isEmpty(value) ? Number(value) : "";
  } else if (key === "activeMeterId") {
    filterConfig.activeMeterId = !isEmpty(value) ? Number(value) : "";
  } else if (key === "activeBankId") {
    filterConfig.activeBankId = !isEmpty(value) ? Number(value) : "";
  } else if (key === "activeMeterIsShared") {
    filterConfig.activeMeterIsShared = value;
  } else if (key === "activeDeskModeView") {
    filterConfig.activeDeskModeView = value;
  } else if (key === "activeUnitScreenModeView") {
    filterConfig.activeUnitScreenModeView = value;
  } else if (key === "activeServicesScreenModeView") {
    filterConfig.activeServicesScreenModeView = value;
  } else if (key === "activeInvoicesScreenModeView") {
    filterConfig.activeInvoicesScreenModeView = value;
  } else if (key === "showOrganizationUpdate") {
    filterConfig.showOrganizationUpdate = Boolean(value);
  } else if (key === "screenId") {
    filterConfig.screenId = value;
  } else if (key === "activeAssetInventoryPeriodId") {
    filterConfig.activeAssetInventoryPeriodId = Number(value);
  }
  localStorage.setItem(FILTER_CONFIG_KEY, JSON.stringify(filterConfig));
}
export function setFilterConfigByOptions(filterConfig) {
  localStorage.setItem(FILTER_CONFIG_KEY, JSON.stringify(filterConfig));
}
