import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import { buildingsSlice } from "../app/modules/Buildings/_redux/buildings/buildingsSlice";
import { contractsSlice } from "../app/modules/Contracts/_redux/contracts/contractsSlice";
import { customersSlice } from "../app/modules/Customers/_redux/customers/customersSlice";
import { customerHistoriesSlice } from "../app/modules/CustomerHistories/_redux/customerHistories/customerHistoriesSlice";
import { invoicesSlice } from "../app/modules/Invoices/_redux/invoices/invoicesSlice";
import { organizationsSlice } from "../app/modules/Organizations/_redux/organizations/organizationsSlice";
import { organizationCommentsSlice } from "../app/modules/Administrator/ManagerCustomer/Customers/_redux/organizationComments/organizationCommentsSlice";
import { organizationSlice } from "../app/modules/Organization/_redux/organization/organizationSlice";
import { customerProfilesSlice } from "../app/modules/CustomerProfiles/_redux/customer-profiles/customerProfilesSlice";
import { entryExitHistoriesSlice } from "../app/modules/EntryExitHistories/_redux/entry-exit-histories/entryExitHistoriesSlice";
import { paymentVouchersSlice } from "../app/modules/PaymentVouchers/_redux/paymentVouchers/paymentVouchersSlice";
import { positionsSlice } from "../app/modules/Positions/_redux/positions/positionsSlice";
import { cashFlowsSlice } from "../app/modules/Administrator/ManagerSenior/CashFlows/_redux/cashFlows/cashFlowsSlice";
import { changeLogsSlice } from "../app/modules/Administrator/ManagerUpdate/ChangeLogs/_redux/change-logs/changeLogsSlice";
import { privilegesSlice } from "../app/modules/Privileges/_redux/privileges/privilegesSlice";
import { receiptVouchersSlice } from "../app/modules/ReceiptVouchers/_redux/receiptVouchers/receiptVouchersSlice";
import { transactionHistoriesSlice } from "../app/modules/TransactionHistories/_redux/transaction-histories/transactionHistoriesSlice";
import { rolesSlice } from "../app/modules/Roles/_redux/roles/rolesSlice";
import { customFieldsSlice } from "../app/modules/CustomFields/_redux/custom-fields/customFieldsSlice";
import { servicesSlice } from "../app/modules/Services/_redux/services/servicesSlice";
import { unitsSlice } from "../app/modules/Units/_redux/units/unitsSlice";
import { metersSlice } from "../app/modules/Meters/_redux/meters/metersSlice";
import { meterPartnersSlice } from "../app/modules/MeterPartnerLogs/_redux/meterPartners/meterPartnersSlice";
import { meterPartnerLogsSlice } from "../app/modules/MeterPartnerLogs/_redux/meterPartnerLogs/meterPartnerLogsSlice";
import { floorsSlice } from "../app/modules/Floors/_redux/floors/floorsSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/reports/reportsSlice";
import { vehiclesSlice } from "../app/modules/Vehicles/_redux/vehicles/vehiclesSlice";
import { referralsSlice } from "../app/modules/Referrals/_redux/referrals/referralsSlice";
import { documentsSlice } from "../app/modules/Documents/_redux/documents/documentsSlice";
import { collaboratorsSlice } from "../app/modules/Collaborators/_redux/collaborators/collaboratorsSlice";
import { commissionsSlice } from "../app/modules/Commissions/_redux/commissions/commissionsSlice";
import { transactionsSlice } from "../app/modules/Transactions/_redux/transactions/transactionsSlice";
import { desksSlice } from "../app/modules/Desks/_redux/desks/desksSlice";
import { deskBaseSlice } from "../app/modules/DeskBase/_redux/desk-base/deskBaseSlice";
import { deskTimeLineSlice } from "../app/modules/DeskTimeLine/_redux/desk-time-line/deskTimeLineSlice";
import { usersSlice } from "../app/modules/Users/_redux/users/usersSlice";
import { unitSymbolsSlice } from "../app/modules/UnitSymbols/_redux/unitSymbols/unitSymbolsSlice";
import { contractFormsSlice } from "../app/modules/ContractForms/_redux/contractForms/contractFormsSlice";
import { provincesSlice } from "../app/modules/Provinces/_redux/provinces/provincesSlice";
import { teamsSlice } from "../app/modules/Teams/_redux/teams/teamsSlice";
import { nationalsSlice } from "../app/modules/Nationals/_redux/nationals/nationalsSlice";
import { nationalitiesSlice } from "../app/modules/Nationalities/_redux/nationalities/nationalitiesSlice";
import { districtsSlice } from "../app/modules/Districts/_redux/districts/districtsSlice";
import { wardsSlice } from "../app/modules/Wards/_redux/wards/wardsSlice";
import { checkinsSlice } from "../app/modules/Checkins/_redux/checkins/checkinsSlice";
import { meterLogsSlice } from "../app/modules/MeterLogs/_redux/meterLogs/meterLogsSlice";
import { initialsSlice } from "../app/modules/Initials/_redux/initials/initialsSlice";
import { tasksSlice } from "../app/modules/Tasks/_redux/tasks/tasksSlice";
import { commentsSlice } from "../app/modules/Tasks/_redux/comments/commentsSlice";
import { issuesSlice } from "../app/modules/Issues/_redux/issues/issuesSlice";
import { supportsSlice } from "../app/modules/Supports/_redux/supports/supportsSlice";
import { troublesSlice } from "../app/modules/Troubles/_redux/troubles/troublesSlice";
import { brokerIntroductionsSlice } from "../app/modules/BrokerIntroductions/_redux/brokerIntroductions/brokerIntroductionsSlice";
import { supportCommentsSlice } from "../app/modules/Supports/_redux/supportComments/supportCommentsSlice";
import { currenciesSlice } from "../app/modules/Currencies/_redux/currencies/currenciesSlice";
import { receiptPaymentVouchersSlice } from "../app/modules/ReceiptPaymentVouchers/_redux/receiptPaymentVouchers/receiptPaymentVouchersSlice";
import { unitTypesSlice } from "../app/modules/UnitTypes/_redux/unitTypes/unitTypesSlice";
import { vehicleTypesSlice } from "../app/modules/VehicleTypes/_redux/vehicleTypes/vehicleTypesSlice";
import { periodsSlice } from "../app/modules/Periods/_redux/periods/periodsSlice";
// import { couponsSlice } from "../app/modules/Coupons/_redux/coupons/couponsSlice";
import { contractSourcesSlice } from "../app/modules/ContractSources/_redux/contractSources/contractSourcesSlice";
import { formulasSlice } from "../app/modules/Formulas/_redux/formulas/formulasSlice";
import { ticketsSlice } from "../app/modules/Tickets/_redux/tickets/ticketsSlice";
import { voucherCategoriesSlice } from "../app/modules/VoucherCategories/_redux/voucherCategories/voucherCategoriesSlice";
import { utilitiesSlice } from "../app/modules/Utilities/_redux/utilities/utilitiesSlice";
import { banksSlice } from "../app/modules/Banks/_redux/banks/banksSlice";
import { directionsSlice } from "../app/modules/Directions/_redux/directions/directionsSlice";
import { priceRangesSlice } from "../app/modules/PriceRanges/_redux/priceRanges/priceRangesSlice";
import { realEstateTypesSlice } from "../app/modules/RealEstateTypes/_redux/realEstateTypes/realEstateTypesSlice";
import { legalDocumentsSlice } from "../app/modules/LegalDocuments/_redux/legalDocuments/legalDocumentsSlice";
import { outstandingFeaturesSlice } from "../app/modules/OutstandingFeatures/_redux/outstandingFeatures/outstandingFeaturesSlice";
import { roadWideTypesSlice } from "../app/modules/RoadWideTypes/_redux/roadWideTypes/roadWideTypesSlice";
import { bedroomNumbersSlice } from "../app/modules/BedroomNumbers/_redux/bedroomNumbers/bedroomNumbersSlice";
import { roadWidthsSlice } from "../app/modules/RoadWidths/_redux/roadWidths/roadWidthsSlice";
import { metadataFloorsSlice } from "../app/modules/MetadataFloors/_redux/metadataFloors/metadataFloorsSlice";
import { businessesSlice } from "../app/modules/Businesses/_redux/businesses/businessesSlice";
import { departmentsSlice } from "../app/modules/Departments/_redux/departments/departmentsSlice";
import { assetCategoriesSlice } from "../app/modules/AssetCategories/_redux/assetCategories/assetCategoriesSlice";
import { depositsSlice } from "../app/modules/Deposits/_redux/deposits/depositsSlice";
import { bankAccountsSlice } from "../app/modules/BankAccounts/_redux/bankAccounts/bankAccountsSlice";
import { projectsSlice } from "../app/modules/Projects/_redux/projects/projectsSlice";
import { assetsSlice } from "../app/modules/Assets/_redux/assets/assetsSlice";
import { settingsSlice } from "../app/modules/Settings/_redux/settings/settingsSlice";
import { emailTemplatesSlice } from "../app/modules/Settings/_redux/emailTemplates/emailTemplatesSlice";
import { apiKeysSlice } from "../app/modules/Settings/_redux/api-keys/apiKeysSlice";
import { assetSequencesSlice } from "../app/modules/AssetSequences/_redux/assetSequences/assetSequencesSlice";
import { suppliersSlice } from "../app/modules/Suppliers/_redux/suppliers/suppliersSlice";
import { chatsSlice } from "../app/modules/Chats/_redux/chats/chatsSlice";
import { assetWarehousesSlice } from "../app/modules/AssetWarehouses/_redux/assetWarehouses/assetWarehousesSlice";
import { assetStatusesSlice } from "../app/modules/AssetStatuses/_redux/assetStatuses/assetStatusesSlice";
import { assetInventoriesSlice } from "../app/modules/AssetInventories/_redux/assetInventories/assetInventoriesSlice";
import { collaboratorCommissionsSlice } from "../app/modules/CollaboratorCommissions/_redux/collaboratorCommissions/collaboratorCommissionsSlice";
import { postsSlice } from "../app/modules/Posts/_redux/posts/postsSlice";
import { postBuildingsSlice } from "../app/modules/PostBuildings/_redux/postBuildings/postBuildingsSlice";
import { postUnitsSlice } from "../app/modules/PostUnits/_redux/postUnits/postUnitsSlice";
import { postSamplesSlice } from "../app/modules/PostSamples/_redux/postSamples/postSamplesSlice";
import { postListsSlice } from "../app/modules/PostLists/_redux/postLists/postListsSlice";
import { postPotentialsSlice } from "../app/modules/PostPotentials/_redux/postPotentials/postPotentialsSlice";
import { notificationsSlice } from "../app/modules/Notifications/_redux/notifications/notificationsSlice";
import { newsLettersSlice } from "../app/modules/NewsLetters/_redux/newsLetters/newsLettersSlice";
import { buildingPostsSlice } from "../app/modules/BuildingPosts/_redux/building-posts/buildingPostsSlice";
import { unitPostsSlice } from "../app/modules/UnitPosts/_redux/unit-posts/unitPostsSlice";
import { feedbacksSlice } from "../app/modules/Feedbacks/_redux/feedbacks/feedbacksSlice";
import { goodsSlice } from "../app/modules/Goods/_redux/goods/goodsSlice";
import { goodSequencesSlice } from "../app/modules/GoodSequences/_redux/good-sequences/goodSequencesSlice";
import { goodCategoriesSlice } from "../app/modules/GoodCategories/_redux/goodCategories/goodCategoriesSlice";

import { assetInventoryPeriodsSlice } from "../app/modules/AssetInventoryPeriods/_redux/assetInventoryPeriods/assetInventoryPeriodsSlice";
import { reportOverviewSlice } from "../app/modules/ReportOverview/_redux/overview/overviewSlice";
import { dashboardPartnerSlice } from "../app/modules/Partner/Dashboard/_redux/dashboardPartner/dashboardPartnerSlice";
import { toastsSlice } from "../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customFields: customFieldsSlice.reducer,
  assetWarehouses: assetWarehousesSlice.reducer,
  assetStatuses: assetStatusesSlice.reducer,
  assetInventories: assetInventoriesSlice.reducer,
  assetInventoryPeriods: assetInventoryPeriodsSlice.reducer,
  goods: goodsSlice.reducer,
  goodSequences: goodSequencesSlice.reducer,
  goodCategories: goodCategoriesSlice.reducer,
  teams: teamsSlice.reducer,
  customers: customersSlice.reducer,
  customerHistories: customerHistoriesSlice.reducer,
  reports: reportsSlice.reducer,
  feedbacks: feedbacksSlice.reducer,
  buildings: buildingsSlice.reducer,
  contracts: contractsSlice.reducer,
  contractSources: contractSourcesSlice.reducer,
  invoices: invoicesSlice.reducer,
  transactions: transactionsSlice.reducer,
  organizations: organizationsSlice.reducer,
  organizationComments: organizationCommentsSlice.reducer,
  organization: organizationSlice.reducer,
  customerProfiles: customerProfilesSlice.reducer,
  entryExitHistories: entryExitHistoriesSlice.reducer,
  paymentVouchers: paymentVouchersSlice.reducer,
  positions: positionsSlice.reducer,
  cashFlows: cashFlowsSlice.reducer,
  changeLogs: changeLogsSlice.reducer,
  referrals: referralsSlice.reducer,
  documents: documentsSlice.reducer,
  collaborators: collaboratorsSlice.reducer,
  commissions: commissionsSlice.reducer,
  privileges: privilegesSlice.reducer,
  receiptVouchers: receiptVouchersSlice.reducer,
  transactionHistories: transactionHistoriesSlice.reducer,
  roles: rolesSlice.reducer,
  services: servicesSlice.reducer,
  units: unitsSlice.reducer,
  meters: metersSlice.reducer,
  meterPartners: meterPartnersSlice.reducer,
  meterPartnerLogs: meterPartnerLogsSlice.reducer,
  floors: floorsSlice.reducer,
  vehicles: vehiclesSlice.reducer,
  desks: desksSlice.reducer,
  deskBase: deskBaseSlice.reducer,
  deskTimeLines: deskTimeLineSlice.reducer,
  users: usersSlice.reducer,
  unitSymbols: unitSymbolsSlice.reducer,
  contractForms: contractFormsSlice.reducer,
  provinces: provincesSlice.reducer,
  nationals: nationalsSlice.reducer,
  nationalities: nationalitiesSlice.reducer,
  districts: districtsSlice.reducer,
  wards: wardsSlice.reducer,
  checkins: checkinsSlice.reducer,
  meterLogs: meterLogsSlice.reducer,
  initials: initialsSlice.reducer,
  tasks: tasksSlice.reducer,
  comments: commentsSlice.reducer,
  issues: issuesSlice.reducer,
  supports: supportsSlice.reducer,
  troubles: troublesSlice.reducer,
  brokerIntroductions: brokerIntroductionsSlice.reducer,
  supportComments: supportCommentsSlice.reducer,
  currencies: currenciesSlice.reducer,
  receiptPaymentVouchers: receiptPaymentVouchersSlice.reducer,
  unitTypes: unitTypesSlice.reducer,
  vehicleTypes: vehicleTypesSlice.reducer,
  periods: periodsSlice.reducer,
  // coupons: couponsSlice.reducer,
  formulas: formulasSlice.reducer,
  tickets: ticketsSlice.reducer,
  voucherCategories: voucherCategoriesSlice.reducer,
  utilities: utilitiesSlice.reducer,
  banks: banksSlice.reducer,
  directions: directionsSlice.reducer,
  priceRanges: priceRangesSlice.reducer,
  realEstateTypes: realEstateTypesSlice.reducer,
  legalDocuments: legalDocumentsSlice.reducer,
  metadataFloors: metadataFloorsSlice.reducer,
  outstandingFeatures: outstandingFeaturesSlice.reducer,
  roadWideTypes: roadWideTypesSlice.reducer,
  bedroomNumbers: bedroomNumbersSlice.reducer,
  roadWidths: roadWidthsSlice.reducer,
  businesses: businessesSlice.reducer,
  departments: departmentsSlice.reducer,
  assetCategories: assetCategoriesSlice.reducer,
  deposits: depositsSlice.reducer,
  bankAccounts: bankAccountsSlice.reducer,
  projects: projectsSlice.reducer,
  assets: assetsSlice.reducer,
  assetSequences: assetSequencesSlice.reducer,
  suppliers: suppliersSlice.reducer,
  notifications: notificationsSlice.reducer,
  newsLetters: newsLettersSlice.reducer,
  buildingPosts: buildingPostsSlice.reducer,
  unitPosts: unitPostsSlice.reducer,
  settings: settingsSlice.reducer,
  emailTemplates: emailTemplatesSlice.reducer,
  apiKeys: apiKeysSlice.reducer,
  chats: chatsSlice.reducer,
  collaboratorCommissions: collaboratorCommissionsSlice.reducer,
  posts: postsSlice.reducer,
  postBuildings: postBuildingsSlice.reducer,
  postUnits: postUnitsSlice.reducer,
  postSamples: postSamplesSlice.reducer,
  postLists: postListsSlice.reducer,
  postPotentials: postPotentialsSlice.reducer,
  reportOverview: reportOverviewSlice.reducer,


  // Partner
  dashboardPartner: dashboardPartnerSlice.reducer,
  toasts: toastsSlice.reducer,



});

export function* rootSaga() {
  yield all([auth.saga()]);
}
// cac phương thức sử dụng nằm trong UI ở _redux
