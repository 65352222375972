import React from "react";

function SelectionCheckbox({ isSelected, onChange, className = "checkbox checkbox-single" }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }}
      />
      <label className={className}>
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props) {
  const { items,
    setItems,
    ids,
    setIds,
    itemId,
    item,
    selectedRows,
    setSelectedRows } = props;
  if (ids.some((id) => id === itemId)) {
    setIds(ids.filter((id) => id !== itemId));
    items && setItems(items.filter((item) => item.id !== itemId));
    setSelectedRows(selectedRows.filter((el) => el.id !== itemId));
  } else {
    if (items) {
      const newItems = [...items];
      newItems.push(item);
      setItems(newItems);

    }

    const newIds = [...ids];
    const newSelectedRows = [...selectedRows];
    newIds.push(itemId);
    newSelectedRows.push(item);
    setIds(newIds);
    setSelectedRows(newSelectedRows);

  }
}

function groupingAllOnSelect(props) {
  const { isSelected, setIds, ids, entities, setSelectedRows, } = props;
  if (!isSelected) {
    let _ids = [...ids];
    entities && entities.forEach((el) => _ids.push(el.id));
    setIds([...new Set(_ids)]);
    setSelectedRows && setSelectedRows(entities);
  } else {
    let _ids = [...ids];
    let newIds = [];
    let newSelectedRows = [];
    let allIds = entities && entities.map(el => el.id);
    _ids.map(el => {
      if (allIds.length && allIds.some(id => id === el)) {
      } else {
        newIds.push(el);
      }
      return "";
    }
    );
    setIds(newIds);
    setSelectedRows(newSelectedRows);

  }
  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

export function getSelectRow(props) {
  const {
    entities,
    ids,
    setIds,
    clickToEdit,
    selectedRows,
    setSelectedRows } = props;
  return {
    mode: "checkbox",
    clickToSelect: true,
    clickToEdit: clickToEdit,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      let count = 0
      for (let i = 0; i < ids.length; i++) {
        if (entities.some(item => item.id === ids[i])) {
          count++;
        }
      }
      const isSelected = count === entities?.length
      const props = {
        isSelected,
        entities,
        setIds,
        ids,
        selectedRows,
        setSelectedRows
      };
      return (
        <SelectionCheckbox
          className="checkbox checkbox-single ml-1"
          isSelected={isSelected}
          onChange={() => groupingAllOnSelect(props)
          }
        />
      );
    },

    selectionRenderer: ({ rowIndex }) => {
      const isSelected = entities[rowIndex] && ids.some(
        (el) => el === entities[rowIndex].id
      );

      const props = {
        ids,
        setIds,
        selectedRows,
        setSelectedRows,
        itemId: entities[rowIndex] && entities[rowIndex].id,
        item: entities[rowIndex],
      };
      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => groupingItemOnSelect(props)}
        />
      );
    },
  };
}


export const setEntities = (aEntities, totalSize, sizePerPage, page) => {
  //console.log(totalSize, sizePerPage, page);
  let newEntities = [...aEntities];
  newEntities = newEntities.splice((page - 1) * sizePerPage, sizePerPage);
  //console.log(newEntities);
  return newEntities
}