/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import { Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_bloomgoo/_partials/controls";
import { FormattedMessage } from "react-intl";
import * as actionsUsers from "../../Users/_redux/users/usersActions";
import { SelectBoxField } from "../../../../_bloomgoo/_partials/controls/forms/SelectBoxField";
import { UserTypeTitles } from "../../../_helpers/_CommonUIHelpers";
import { setFilterConfig } from "../../../_helpers/LocalStorageConfig";
import {
  isPrivilegeUserAction,
  isPrivilegeUserGroup,
  isPrivilegeUserPacket,
} from "../../../_helpers/PrivilegesUIHelper/User";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { useHistory, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_bloomgoo/_helpers";
import { ButtonCardMenu } from "../../../../_bloomgoo/_partials/button/ButtonCardMenu";
import { useSettingsUIContext } from "../SettingsUIContext";
import { getHeaderSettings } from "../SettingsUIHelpers";

export default function SettingsCardPeronal() {
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      tabPeronal2ButtonClick:
        settingsUIContext && settingsUIContext.tabPeronal2ButtonClick,
      tabFunctionsButtonClick:
        settingsUIContext && settingsUIContext.tabFunctionsButtonClick,
      tabEmailsButtonClick:
        settingsUIContext && settingsUIContext.tabEmailsButtonClick,
      tabDecentralizationButtonClick:
        settingsUIContext && settingsUIContext.tabDecentralizationButtonClick,
      tabCurrenciesButtonClick:
        settingsUIContext && settingsUIContext.tabCurrenciesButtonClick,
      tabBuildingButtonClick:
        settingsUIContext && settingsUIContext.tabBuildingButtonClick,
    };
  }, [settingsUIContext]);
  const dispatch = useDispatch();
  const {
    userBuildingPermissionForEdit,
    user,
    users,
    actionsLoading,
    myProfile,
  } = useSelector(
    (state) => ({
      userBuildingPermissionForEdit: state.users.userBuildingPermissionForEdit,
      user: state.auth.user,
      users: state.users.entities,
      actionsLoading: state.users.actionsLoading,
      notification: state.users.notification,
      myProfile: state.users.myProfile,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actionsUsers.fetchUsersByOrganizationId(user.organizationId));
    dispatch(actionsUsers.fetchUserBuildingPermission(user.id));
  }, [dispatch]);

  useEffect(() => {
    setFilterConfig("activeBuildingId", myProfile?.activeBuildingId);
  }, [myProfile?.activeBuildingId]);

  // Methods
  const saveUserBuildingPermission = (values) => {
    let _values = {
      id: userBuildingPermissionForEdit?.id,
      buildings: values,
    };
    dispatch(actionsUsers.updateUserBuildingPermission(_values)).then(() =>
      dispatch(actionsUsers.fetchMyProfile())
    );
  };

  const handleFieldChange = (e) => {
    let fieldName = e.target.name;
    if (fieldName === "id") {
      dispatch(actionsUsers.fetchUserBuildingPermission(e.target.value));
    }
  };

  const [checkLength, setCheckLength] = useState(0);
  const [initPermission, setInitPermission] = useState(
    userBuildingPermissionForEdit?.buildings
  );

  useEffect(() => {
    setInitPermission(userBuildingPermissionForEdit?.buildings);
  }, [userBuildingPermissionForEdit, dispatch]);

  useEffect(() => {
    let _checkLength = 0;
    initPermission &&
      initPermission.map((item) => {
        if (item?.isSelected) {
          _checkLength += 1;
        }
        return "";
      });

    setCheckLength(_checkLength);
  }, [initPermission, dispatch]);

  const _setInitPermission = (e, index) => {
    let _initPermission = JSON.parse(JSON.stringify(initPermission));
    if (e.target.name === "isSelectedAll") {
      _initPermission &&
        _initPermission.map((row, rowIndex) => {
          _initPermission[rowIndex].isSelected = e.target.checked;
        });
    } else {
      _initPermission[index].isSelected = e.target.checked;
    }

    setInitPermission(_initPermission);
  };

  const history = useHistory();

  let location = useLocation();


  return (
    <>
      <Card>
        <CardHeader>
          <div className="btn-group my-2" role="group" aria-label="...">
            {getHeaderSettings({ user, history, location })}
          </div>
        </CardHeader>
        <CardBody className="card-scroll">
          <div className="nav-tabs-filter"></div>

          {actionsLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <div className="modal-body container container-fluid px-40">
            <div className="form-group">
              <div className="form-group row my-3">
                <div className="col-lg-6">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id: userBuildingPermissionForEdit?.id,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      ...props
                    }) => (
                      <>
                        <div className="col-lg-12 d-flex">
                          <FormattedMessage id="MODEL.UI.settings.userId">
                            {(msg) => (
                              <>
                                <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                                  <span>
                                    Chọn người dùng
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <Field
                                    name="id"
                                    component={SelectBoxField}
                                    type={"text"}
                                    checkYup={false}
                                    placeholder={msg}
                                    groupHeader={[
                                      "#",
                                      "Họ và tên",
                                      "Loại người dùng",
                                    ]}
                                    styleText={["center", "right", "center"]}
                                    styleMenu={{
                                      width: "200%",
                                    }}
                                    options={
                                      users &&
                                      users.map(function (item, index) {
                                        return {
                                          label: [
                                            `${index + 1}`,
                                            `${item.fullName}`,
                                            `${UserTypeTitles[item.userType]}`,
                                          ],
                                          value: item.id,
                                        };
                                      })
                                    }
                                    formProps={props}
                                    handleFieldChange={handleFieldChange}
                                  />
                                </div>
                              </>
                            )}
                          </FormattedMessage>
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="px-3 text-primary">
                <div
                  className="react-bootstrap-table table-responsive"
                >
                  <Table
                    bordered
                    hover
                    size="sm"
                    className="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-center" rowSpan={2}>
                          #
                        </th>
                        <th className="text-center" rowSpan={2}>
                          Mã
                        </th>
                        <th className="text-center" rowSpan={2}>
                          <FormattedMessage id="MODEL.UI.settings.buildings"></FormattedMessage>
                        </th>
                        <th className="text-center" rowSpan={2}>
                          Cha
                        </th>
                        <th className="text-center" rowSpan={2}>
                          Số tầng
                        </th>
                        <th className="text-center">
                          {isPrivilegeUserAction(user, "SETTINGS_PERMISSION_BUILDINGS_WRITE") ?
                            <FormattedMessage id="MODEL.UI.settings.buildingSelect"></FormattedMessage> : ""
                          }
                        </th>
                      </tr>
                      {isPrivilegeUserAction(user, "SETTINGS_PERMISSION_BUILDINGS_WRITE") ?
                        <tr className="text-center border-right">
                          <div className="d-flex justify-content-center">
                            <label className="checkbox">
                              <input
                                name={`isSelectedAll`}
                                type="checkbox"
                                className="form-control form-control-sm"
                                checked={checkLength === initPermission?.length}
                                onChange={(e) => _setInitPermission(e)}
                              />
                              <span className="my-1"></span>
                            </label>
                          </div>
                        </tr>
                        :
                        <></>
                      }

                    </thead>
                    <tbody>
                      {initPermission &&
                        initPermission.map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="text-center">{rowIndex + 1}</td>
                            <td>{item.building.code}</td>
                            <td>
                              {item.building.level === 1
                                ? "-- " + item.building.name
                                : item.building.name}
                            </td>
                            <td>
                              {item.building && item.building.parent
                                ? item.building.parent.name
                                : ""}
                            </td>
                            <td className="text-center">
                              {item.building.numberFloors}
                            </td>
                            <td className="text-center">
                              <div className="d-flex justify-content-center">
                                <label className="checkbox">
                                  <input

                                    type="checkbox"
                                    className="form-control form-control-sm"
                                    name={`buildings[${rowIndex}].isSelected`}
                                    checked={
                                      initPermission[rowIndex].isSelected
                                    }
                                    onChange={(e) =>
                                      _setInitPermission(e, rowIndex)
                                    }
                                    disabled={
                                      !isPrivilegeUserAction(
                                        user,
                                        "SETTINGS_PERMISSION_BUILDINGS_WRITE"
                                      )
                                    }
                                  />
                                  <span></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="text-right mr-3 mb-4">
                <Button
                  className="btn btn-secondary btn-md"
                  handleClick={() =>
                    dispatch(
                      actionsUsers.fetchUserBuildingPermission(
                        userBuildingPermissionForEdit?.id
                      )
                    )
                  }
                  message="COMMONS.UI.Reload"
                  icon="flaticon2-refresh-arrow icon-ms"
                />
                <Button
                  className="btn btn-primary btn-md ml-2"
                  handleClick={() => saveUserBuildingPermission(initPermission)}
                  message="COMMONS.UI.Save"
                  icon="flaticon-edit-1 icon-ms"
                  hidden={
                    !isPrivilegeUserAction(
                      user,
                      "SETTINGS_PERMISSION_BUILDINGS_WRITE"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
