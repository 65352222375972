import axios from "axios";

export const API_KEYS_URL = "/apiKeys";


export function getAPIKeyById(id) {
  return axios.get(`${API_KEYS_URL}/${id}/key`);
};

export function resetAPIKeyById(id, password) {
  return axios.post(`${API_KEYS_URL}/${id}/reset`, password);
};

export function updateIsActiveAPIKeyById(id, password) {
  return axios.post(`${API_KEYS_URL}/${id}/isActive`, password);
};

// show
export function getShowAPIKeyById(id) {
  return axios.get(`${API_KEYS_URL}/${id}/show`);
};

// update
export function updateAPIKeyById(apiKey, id) {
  return axios.put(`${API_KEYS_URL}/${id}`, apiKey);
};

// delete
export function deleteAPIKeyById(id, password) {
  return axios.delete(`${API_KEYS_URL}/${id}`, { data: password });
};


// index
export function getAllAPIKey() {
  return axios.get(`${API_KEYS_URL}`);
};

// store
export function createAPIKey(apiKey) {
  return axios.post(`${API_KEYS_URL}`, apiKey);
};

// find
export function findAPIKeys(queryParams) {
  return axios.post(`${API_KEYS_URL}/find`, queryParams);
};

// edit
export function getEditAPIKeyById(id) {
  return axios.get(`${API_KEYS_URL}/${id}/edit`);
};

// clone
export function getCloneAPIKeyById(id) {
  return axios.get(`${API_KEYS_URL}/${id}/clone`);
};

// create
export function getInitAPIKey() {
  return axios.get(`${API_KEYS_URL}/create`);
};
