import axios from "axios";

export const DOCUMENTS_URL = "/documents";

// CREATE =>  POST: add a new document to the server
export function createDocument(document) {
    return axios.post(DOCUMENTS_URL, document);
}

// READ
export function getAllDocuments() {
    return axios.get(DOCUMENTS_URL);
}

export function getInitDocument() {
    return axios.get(`${DOCUMENTS_URL}/create`);
}

export function downloadFile(fileCode) {
    return axios.get(`${DOCUMENTS_URL}/${fileCode}/download`);
}
export function getDocumentById(documentId) {
    return axios.get(`${DOCUMENTS_URL}/${documentId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDocuments(queryParams) {
    return axios.post(`${DOCUMENTS_URL}/find`, queryParams);
}

export function uploadFile(functionName, file) {
    return axios.post(`${DOCUMENTS_URL}/function/${functionName}/upload`, file);
}

export function uploadMultiFile(files) {
    return axios.post(`${DOCUMENTS_URL}/multiUploads`, files);
}

// UPDATE => PUT: update the procuct on the server
export function updateDocument(document) {
    console.log("document: ", document)
    return axios.put(`${DOCUMENTS_URL}/${document.id}`, document);
}

// UPDATE Status
export function updateStatusForDocuments(ids, status) {
    return axios.post(`${DOCUMENTS_URL}/updateStatusForDocuments`, {
        ids,
        status,
    });
}

// DELETE => delete the document from the server
export function deleteDocument(documentId) {
    return axios.delete(`${DOCUMENTS_URL}/${documentId}`);
}

// DELETE Documents by ids
export function deleteDocuments(ids) {
    return axios.delete(`${DOCUMENTS_URL}/bulk`, { data: { ids } });
}