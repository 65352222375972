import React from "react";
import { toAbsoluteUrl } from "../../../_bloomgoo/_helpers";
import SVG from "react-inlinesvg";
export const SvgIconColumnFormatter = (cellContent, row) => {
  return (
    <>
      {
        cellContent !== "0" ?
          <span className="svg-icon svg-icon-lg svg-icon-danger d-flex justify-content-center">
            <img src={`/media/svg/icons/Utilities/${cellContent.toLowerCase()}.svg`} onError={(e) => e.target.src = ""} alt="" width="24" height="24" ></img>
            {/* <SVG src={toAbsoluteUrl(`/media/svg/icons/Utility/${cellContent.toLowerCase()}.svg`)} /> */}
          </span> :
          <span className="svg-icon svg-icon-primary svg-icon-lg d-flex justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                <path d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z" fill="#000000" />
              </g>
            </svg>
          </span>
      }

    </>
  );
}