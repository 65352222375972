import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialBusinessesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  businesseForEdit: null,

  filterCount: null,
  errorType: null,
  currentBusinesses: [],
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const businessesSlice = createSlice({
  name: "businesses",
  initialState: initialBusinessesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    startCallParent: (state, action) => {
      state.error = null;



      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.businesseForEdit = null;
    },

    resetCurrentBusinesses: (state, action) => {
      state.currentBusiness = null;
      state.currentBusinesses = [];
    },

    // getBusinesseById
    businesseFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.businesseForEdit = action.payload.businesseForEdit;
      state.error = null;

    },
    // findAllBusinesses
    businessesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findBusinesses
    businessesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },
    // findParentBusinesses
    businessesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createBusinesse
    businesseCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities.unshift(action.payload.businesse);
      state.currentBusinesses.push(action.payload.businesse);
    },
    // updateBusinesse
    businesseUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.businesse.id) {
          return action.payload.businesse;
        }
        return entity;
      });
      state.currentBusinesses.push(action.payload.businesse);
    },
    // deleteBusinesse
    businesseDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentBusinesses = [];
    },
    // deleteBusinesses
    businessesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentBusinesses = [];
    },
    // businessesUpdateState
    businessesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
