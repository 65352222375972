import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCommentState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  commentForEdit: null,
  createdNote: null,
  notes: null,
  notification: null,
  error: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState: initialCommentState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },


    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.commentForEdit = null;
    },

    // --------- Comment Task ----------- //
    commentFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { commentForEdit } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.commentForEdit = commentForEdit;
    },

    commentsFetched: (state, action) => {
      const { totalCountComment, entitiesComment } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.totalCountComment = totalCountComment;
      state.entitiesComment = entitiesComment;
    },

    commentUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.comment.id) {
          return action.payload.comment;
        }
        return entity;
      });
    },

    taskCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      // state.entities.unshift(action.payload.support);
      // state.currentComment.unshift(action.payload.support);
    },
  },
});
