import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialChatsState = {
  listLoading: null,
  actionsLoading: null,
  error: null,

  // friendRequests: null,
  chatUser: null,
  messages: [],
  contacts: null,
  activeConversation: null,
  conversations: null,
  fileUpload: []
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState: initialChatsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },

    done: (state) => {
      state.listLoading = false;
      state.error = null;

    },

    setChatUser: (state, action) => {
      const { chatUser } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.chatUser = chatUser;
    },

    fetchAllMessagesByConversation: (state, action) => {
      const { messages } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.messages = messages;
    },

    fetchConversations: (state, action) => {
      const { conversations } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.conversations = conversations;
    },

    setActiveConversation: (state, action) => {
      const { activeConversation } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.activeConversation = activeConversation;
    },

    addMessage: (state, action) => {
      const { message } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.messages.push(message)
    },

    pushMessages: (state, action) => {
      const { messages } = action.payload;
      state.listLoading = false;
      state.error = null;

      messages.reverse().forEach(ele => state.messages.unshift(ele))
      //console.log("old-messages", messages)
    },

    setContacts: (state, action) => {
      const { contacts } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.contacts = contacts;
    },

    removeContacts: (state) => {
      state.listLoading = false;
      state.error = null;

      state.contacts = null;
    },

    fetchRequest: (state, action) => {
      const { friendRequests } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.friendRequests = friendRequests;
    },

    pushConversation: (state, action) => {
      const { conversation } = action.payload;
      state.listLoading = false;
      state.error = null;

      //console.log(conversation)
      state.conversations.push(conversation);
    },

    setFileUpload: (state, action) => {
      const { fileUpload } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.fileUpload = fileUpload;
    },

    updateSeenMessage: (state, action) => {
      const { messageSeen } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.messages.pop();
      state.messages.push(messageSeen);
    }
  }
});
