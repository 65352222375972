import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialBankAccountsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  bankAccountForEdit: null,
  bankAccountForShow: null,
  currentBankAccount: null,
  currentBankAccounts: [],

  filterCount: null,
  notification: null,
  error: null,

  isError: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState: initialBankAccountsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },




    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentBankAccount = null;
      state.currentBankAccounts = [];
    },

    resetModalShow: (state, action) => {
      state.error = null;
      state.errorType = null;
      state.isError = null;
      state.bankAccountForShow = null;
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.bankAccountForEdit = null;
    },

    resetCurrentBankAccounts: (state, action) => {
      state.currentBankAccount = null;
      state.currentBankAccounts = [];
    },

    // getBankAccountById
    bankAccountFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.bankAccountForEdit = action.payload.bankAccountForEdit;
      state.error = null;

    },
    //show
    bankAccountShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.bankAccountForShow = action.payload.bankAccountForShow;
      state.error = null;

    },
    // findAllBankAccounts
    bankAccountsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findBankAccounts
    bankAccountsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentBankAccounts
    bankAccountsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createBankAccount
    bankAccountCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      // state.entities.map((entity,rowIndex) => {
      //   if (action.payload.bankAccount.isDefault === true || action.payload.bankAccount.isDefault === "true") {
      //     entity.isDefault = false;

      //   }
      // return entity
      // });
      //After the map is done, push 
      state.entities.unshift(action.payload.bankAccount)
      state.currentBankAccount = action.payload.bankAccount;
      state.currentBankAccounts.push(action.payload.bankAccount);
    },
    // updateBankAccount
    bankAccountUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      // state.entities = state.entities.map((entity) => {
      //   if (action.payload.bankAccount.isDefault) {
      //     entity.isDefault = false;
      //   }
      //   if (entity.id === action.payload.bankAccount.id) {
      //     return action.payload.bankAccount;
      //   }
      //   return entity;
      // });
      state.currentBankAccount = action.payload.bankAccount;
      state.currentBankAccounts.push(action.payload.bankAccount);

    },
    // deleteBankAccount
    bankAccountDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentBankAccount = null;
      state.currentBankAccounts = [];

    },
    // deleteBankAccounts
    bankAccountsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentBankAccount = null;
      state.currentBankAccounts = [];

    },
    // bankAccountsUpdateState
    bankAccountsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.currentBankAccount = action.payload.bankAccount;

    },
  },
});
