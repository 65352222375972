/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";


let _ids = [];
function SelectionCheckbox({ onChange, className = "checkbox checkbox-single", entities, rowIndex }) {
  const [ids, setIds] = useState([])
  useEffect(() => {
    setIds(_ids);
    return () => {
    }
  }, [_ids]);
  const isSelected = entities[rowIndex] && ids.some(
    (el) => el === entities[rowIndex].id
  );
  return (
    <>
      <input type="checkbox" style={{ display: "none" }}
      />
      <label className={className}>
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
};

function SelectionHeaderCheckbox({ isSelected, onChange, className = "checkbox checkbox-single" }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }}
      />
      <label className={className}>
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>

    </>
  );
};

function groupingItemOnSelect(props) {
  const { items, setItems, ids, setIds, selectedRows = [], setSelectedRows, itemId, item, stateOrganization, setOrganization, setIsPrimary } = props;
  if (ids.some((id) => id === itemId)) {
    !stateOrganization && setOrganization && setOrganization(false)
    setIsPrimary && setIsPrimary(false)
    // eslint-disable-next-line array-callback-return
    !stateOrganization && items && items.some((item) => {
      if (item.isOrganization && item.id !== itemId) {
        !stateOrganization && setOrganization && setOrganization(true)
        return true;
      }
      else !stateOrganization && setOrganization && setOrganization(false)

      if (item.isPrimary && item.id !== itemId) {
        setIsPrimary && setIsPrimary(true)
        return true;
      }
      else setIsPrimary && setIsPrimary(false)
    })

    setIds(ids.filter((id) => id !== itemId));
    items && setItems(items.filter((item) => item.id !== itemId));
    setSelectedRows && setSelectedRows(selectedRows.filter((item) => item.id !== itemId));

  } else {
    if (item?.isOrganization && !stateOrganization && setOrganization) setOrganization(true)
    if (item?.isPrimary && setIsPrimary) setIsPrimary(true)

    if (items) {
      const newItems = [...items];
      newItems.push(item);
      setItems(newItems);
    }

    const newIds = [...ids];
    newIds.push(itemId);
    setIds(newIds);

    if (selectedRows) {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.push(item);
      setSelectedRows(newSelectedRows);
    }
  }

}

function groupingAllOnSelect(props) {
  const { isSelected, setIds, setSelectedRows, entities, stateOrganization, setOrganization, setIsPrimary } = props;
  if (!isSelected) {
    const allIds = [];
    entities && entities.forEach((el) => allIds.push(el.id));
    setIds(allIds);
    setSelectedRows && setSelectedRows(entities);
    entities && entities.map((item) => {
      if (item.isOrganization) {
        !stateOrganization && setOrganization && setOrganization(true)
      }
      if (item.isPrimary) {
        setIsPrimary && setIsPrimary(true)
      }
      return "";
    })
  } else {
    setIds([]);
    setSelectedRows && setSelectedRows([]);
    !stateOrganization && setOrganization && setOrganization(false)
    setIsPrimary && setIsPrimary(false);
  }

  return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

export const getSelectOnlyRow = (props) => {
  const { setSelectedRows } = props;
  return {
    mode: 'radio',
    clickToSelect: true,
    bgColor: '#c8e6c9',
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRows([row]);
      }
    },
    selectionRenderer: ({ rowIndex, checked }) => {
      return (
        <label className={"checkbox checkbox-single"}>
          <input
            type="checkbox"
            checked={checked}
          />
          <span />
        </label>
      );
    },
  };
}

export function getSelectRow(props) {
  const { entities,
    items,
    setItems,
    ids,
    setIds,
    selectedRows,
    setSelectedRows,
    clickToEdit,
    stateOrganization,
    setOrganization,
    setIsPrimary,
    hideSelectionHeader = false,
    hideSelectColumn = false } = props;
  _ids = ids;
  return {
    mode: "checkbox",
    clickToSelect: true,
    clickToEdit: clickToEdit,
    hideSelectAll: false,
    hideSelectColumn: hideSelectColumn,
    hideSelectionHeader: hideSelectionHeader,
    selectionHeaderRenderer: () => {
      const isSelected = entities && ids && (entities.length > 0 && entities.length === ids.length && ids.some(item => item === entities[0].id));
      const props = { isSelected, entities, setIds, setSelectedRows, stateOrganization, setOrganization, setIsPrimary };
      return (
        <SelectionHeaderCheckbox
          className="checkbox checkbox-single ml-1"
          isSelected={isSelected}
          onChange={() => groupingAllOnSelect(props)}
        />
      );
    },

    selectionRenderer: ({ rowIndex }) => {

      const props = {
        ids,
        setIds,
        selectedRows,
        setSelectedRows,
        itemId: entities[rowIndex] && entities[rowIndex].id,
        item: entities[rowIndex],
        items,
        setItems,
        stateOrganization,
        setOrganization,
        setIsPrimary
      };
      return (
        <SelectionCheckbox
          entities={entities}
          rowIndex={rowIndex}
          onChange={() => groupingItemOnSelect(props)}
        />
      );
    },
  };

}
