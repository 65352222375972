import axios from "axios";

export const DISTRICTS_URL = "/districts";

// CREATE =>  POST: add a new district to the server
export function createDistrict(district) {
  return axios.post(DISTRICTS_URL, district);
}

// READ
export function getAllDistricts() {
  return axios.get(DISTRICTS_URL);
}

export function getInitDistrict() {
  return axios.get(`${DISTRICTS_URL}/create`);
}

export function getDistrictCloneById(districtId) {
  return axios.get(`${DISTRICTS_URL}/${districtId}/clone`);
}
export function getDistrictById(districtId) {
  return axios.get(`${DISTRICTS_URL}/${districtId}`);
}

export function findDistrictsByProvinceId(provinceId) {
  return axios.get(`${DISTRICTS_URL}/province/${provinceId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDistricts(queryParams) {
  return axios.post(`${DISTRICTS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateDistrict(district) {
  return axios.put(`${DISTRICTS_URL}/${district.id}`, district);
}

// UPDATE Status
export function updateStatusForDistricts(ids, status) {
  return axios.post(`${DISTRICTS_URL}/updateStatusForDistricts`, {
    ids,
    status,
  });
}

// DELETE => delete the district from the server
export function deleteDistrict(districtId) {
  return axios.delete(`${DISTRICTS_URL}/${districtId}`);
}

// DELETE Districts by ids
export function deleteDistricts(ids) {
  return axios.delete(`${DISTRICTS_URL}/bulk`, { data: { ids } });

}
