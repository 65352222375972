import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialBuildingsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  buildingForEdit: {},
  buildingForShow: null,

  filterCount: null,
  currentBuildings: [],
  error: null,
  errorType: null,
  notification: null,
  isError: null,
  buildingCount: null,
  alert: {
    type: '',
    message: ''
  }
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
  download: "download"
};

export const buildingsSlice = createSlice({
  name: "buildings",
  initialState: initialBuildingsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }



      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.alert = action.payload.alert;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.alert = { type: '', message: '' }
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },

    resetCurrentMeterLogs: (state, action) => {
      state.currentMeterLogs = [];
    },

    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;

    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null
      };
      state.errorType = null;
      state.isError = null;
      state.buildingForEdit = null;
    },

    resetCurrentBuildings: (state, action) => {
      state.currentBuilding = null;
      state.currentBuildings = [];
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    // getBuildingById
    buildingFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.buildingForEdit = action.payload.buildingForEdit;
      state.currentBuildings = [];
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },

    //show
    buildingShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.buildingForShow = action.payload.buildingForShow;
      state.error = null;
    },

    buildingParentShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.buildingParentForShow = action.payload.buildingParentForShow;
      state.error = null;
    },

    startCallParent: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findAllBuildings
    buildingsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.buildingCount = totalCount;
    },

    // getUserById
    buildingUserPermissionFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.buildingUserPermissionForEdit = action.payload.buildingUserPermissionForEdit;
      state.error = null;

    },

    // findBuildings
    buildingsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.buildingCount = totalCount;

    },

    // findParentBuildings
    buildingsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.parents = parents;
      state.totalCount = totalCount;
      state.buildingCount = totalCount;

    },

    // createBuilding
    buildingCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.building);
      state.currentBuildings.push(action.payload.building);
      state.buildingCount = state.buildingCount + 1;

    },

    // updateBuilding
    buildingUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.currentBuilding = action.payload.building;
      state.buildingForShow = action.payload.buildingForShow;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.building.id) {
          return action.payload.building;
        }
        return entity;
      });
      state.currentBuildings.push(action.payload.building);
    },

    // deleteBuilding
    buildingDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentBuildings = [];
      state.buildingCount = state.buildingCount - 1;
    },

    // deleteBuildings
    buildingsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentBuildings = [];
      state.buildingCount = state.buildingCount - action.payload.ids.length;
    },

    // buildingsUpdateStatus
    buildingsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.currentContracts = [];
    },

    buildingCount: (state, action) => {
      state.buildingCount = action.payload.buildingCount;
      state.actionsLoading = false;
    },

    countBuildingsClean: (state, action) => {
      state.buildingCount = null;
    },

    buildingImportFetched: (state, action) => {
      const { buildingImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.buildingImport = buildingImport;
    },


    buildingImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
    },

    resetBuildingImport: (state, action) => {
      state.buildingImport = null;
    },
  },
});
