import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import PersonalPassword from "../../../../app/modules/Settings/settings-personal/PersonalPassword";
import { HTMLDefaultColumnFormatter } from "../../../../app/_helpers/column-formatters";
import { ERROR_TYPES_OBJ, useDidMountEffect, useOutsideEffect } from "../../../../app/_helpers/_CommonUIHelpers";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";



const getPassWordCSSClasses = (touched, errors, isErrors, checkYup) => {
  const classes = ["", ""];
  if (touched && errors) {
    checkYup && classes.push("is-invalid");
  }
  if (touched && !errors) {
    checkYup && classes.push("is-valid");
  }
  if (isErrors === "is-invalid") {
    checkYup && classes.push("is-invalid");
  }
  if (isErrors === "is-valid") {
    checkYup && classes.push("custom-is-valid");
  }

  return classes.join(" ");
};

export function InputHover({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  size = "md",
  withFeedbackLabel = true,
  required = false,
  customFeedbackLabel,
  checkYup = true,
  defaultValue,
  setFieldValue,
  formProps,
  resize = false,
  type = "text",
  hiddenArrows = false,
  className = "",
  style,
  min,
  max,
  updateInfo,
  forcusField,
  setForcusField,
  autoComplete,
  labelColor = "",
  textImportant,
  ...props
}) {
  const disabled = props.disabled;
  const getFieldCSSClasses = (size, touched, errors, touchedArray, errorsArray, indexArray, nameChildren, checkYup) => {
    const classes = ["form-control", `form-control-${size}`];
    if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren]) {
      checkYup && classes.push("is-invalid");
    };

    if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && !(errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren])) {
      checkYup && classes.push("custom-is-valid");

    };

    if (touched && errors) {
      checkYup && classes.push("is-invalid");
      // handleForcusField(true)
    };

    if (touched && !errors) {
      checkYup && classes.push("custom-is-valid");
    };
    return classes.join(" ");
  };

  const handleForcusField = (value) => {
    if (!disabled) {
      let _forcusField = JSON.parse(JSON.stringify(forcusField));
      for (const property in _forcusField) {
        if (property === field.name) _forcusField[field.name] = value;
        else _forcusField[property] = false;
      }
      setForcusField(_forcusField)
    }
    else { }
  };

  const [isShowPassWord, setIsShowPassWord] = useState(false)
  const changeShowPassWord = () => {
    setIsShowPassWord(!isShowPassWord);
  };

  let nameArray = "";
  let nameChildren = "";
  let indexArray;
  if (field.name.includes('].')) {
    const str = field.name;
    let position = str.indexOf('[');
    nameArray = str.slice(0, position);
    nameChildren = str.slice(position + 4);
    indexArray = str.slice(position + 1, position + 2);
  };

  const handleUpValue = () => {
    let value = field.value;
    if (value === max) {
      value = min;
    }
    formProps.setFieldValue(`${field.name}`, value + 1)
  };

  const handleDownValue = () => {
    let value = field.value;
    if (value === min) {
      value = max;
    }
    formProps.setFieldValue(`${field.name}`, value - 1)
  };

  const [isForcus, setIsForcus] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [isForcus]);

  const wrapperRef = useRef(null);

  let fieldValue = field.value;


  const handleOutSide = () => {
    setIsForcus(false)
    formProps.handleReset()
  };

  useOutsideEffect(wrapperRef, handleOutSide);

  return (
    <>

      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      {type !== 'textarea' && type !== "number" && type !== "password" && (
        forcusField?.[field.name] ?

          <div
            className="input-group"
            ref={wrapperRef}
          >
            <input
              type={type}

              style={{ maxWidth: "40px !important" }}
              className={
                `
         ${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup)}
          ${className}
          form-control form-control-md
          `
              }
              {...field}
              {...props}
              value={(field.value === null || field.value === undefined) ? "" : field.value}
            />
            <div className="input-group-append">
              <span className="input-group-text  form-control form-control-md  d-flex justify-content-center" style={{
                borderRadius: "0px 4px 4px  0px"
              }}>
                <span
                  type="button"
                  className="flaticon2-check-mark text-success text-success icon-md font-size-icon-md   mr-2"
                  onClick={() => formProps.handleSubmit()}
                />
                <span
                  type="button"
                  className="flaticon2-delete text-danger icon-sm "
                  onClick={() => { handleForcusField(false); formProps.handleReset() }}
                />
              </span>
            </div>
          </div>
          :
          <div
            className={disabled ? "input-group input-off-hover" : "input-group"}
            onClick={() => { handleForcusField(true); formProps.handleReset() }}
          >
            <div className={!disabled ? "form-control form-control-hover" : 'form-control'} style={disabled ? { border: 0 } : {}}>
              <span
                className={textImportant && `font-weight-bold`}
              >
                {(defaultValue === null || defaultValue === undefined) ? `-- -- --` : defaultValue}
              </span>

            </div>
            {!disabled && <div className="input-group-append"><span className='input-group-text input-group-text-hover'><i className="fas fa-pen" type="button" /></span></div>}
          </div>
      )}
      {type === 'textarea' && (
        forcusField?.[field.name] ?
          <div
            ref={wrapperRef}
          >
            <textarea
              style={{ resize: resize }}
              type={type}
              rows={7}
              className={`${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup)} ${className}`}
              {...field}
              {...props}
              value={(field.value === null || field.value === undefined) ? "" : field.value}
            />
            <div className="text-right mt-1">
              <button
                className={`btn btn-light-success btn-elevate btn-sm font-size-xs font-size-icon-md mr-1 btn`}

                onClick={() => formProps.handleSubmit()}
              >
                <i className="flaticon2-check-mark icon-md  mr-1"></i>Lưu lại
              </button>
              <button
                className={`btn btn-light-danger btn-elevate btn-sm font-size-xs`}
                onClick={() => { handleForcusField(false); formProps.handleReset() }}
              >
                <i className="flaticon2-delete icon-sm  mr-1"></i>Hủy bỏ
              </button>
            </div>
          </div>
          :
          <div className="form-group">
            <div
              className="input-group"
              onClick={() => handleForcusField(true)}
            >
              <div className="form-control form-control-hover"
                style={{
                  height: "fit-content"
                }}
              >
                {(defaultValue === null || defaultValue === undefined) ? `-- -- --` : defaultValue}
              </div>
              {!disabled && <div className="input-group-append"><span className='input-group-text input-group-text-hover'><i className="fas fa-pen" type="button" /></span></div>}

            </div>
          </div>
      )}

      {type === 'number' && (
        forcusField?.[field.name] ?
          <div className="form-group">
            <div
              className="input-group"
              ref={wrapperRef}
            >
              <input
                type={type}
                min={min}
                max={max}
                style={{ maxWidth: "40px !important" }}
                className={
                  `
            ${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren, checkYup)}
             ${className}
             form-control form-control-md input-number
             `
                }
                {...field}
                {...props}
                value={(field.value === null || field.value === undefined) ? "" : field.value}
              />
              <div className="input-group-append">
                <span className="input-group-text  form-control form-control-md  d-flex justify-content-center" style={{
                  borderRadius: "0px 4px 4px  0px"
                }}>
                  <span
                    type="button"
                    className="flaticon2-check-mark text-success text-success icon-md font-size-icon-md   mr-2"
                    onClick={() => formProps.handleSubmit()}
                  />
                  <span
                    type="button"
                    className="flaticon2-delete text-danger icon-sm "
                    onClick={() => { handleForcusField(false); formProps.handleReset() }}
                  />
                </span>
              </div>
            </div>
          </div>
          :
          <div className="form-group">
            <div
              className={disabled ? "input-group input-off-hover" : "input-group"}
              onClick={() => handleForcusField(true)}
            >
              <div className={!disabled ? "form-control form-control-hover" : 'form-control'} style={disabled ? { border: 0 } : {}}>{defaultValue}</div>
              {!disabled && <div className="input-group-append"><span className='input-group-text input-group-text-hover'><i className="fas fa-pen" type="button" /></span></div>}

            </div>
          </div>
      )}
      {type === 'password' && (
        isForcus ?
          <PersonalPassword />
          :
          <div className="form-group">
            <div
              className="input-group"
              onClick={() => setIsForcus(true)}
            >
              <div className="form-control form-control-hover">{defaultValue}</div>
              {!disabled && <div className="input-group-append"><span className='input-group-text input-group-text-hover'><i className="fas fa-pen" type="button" /></span></div>}

            </div>
          </div>
      )}

      {false && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
