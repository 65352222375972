/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useDocumentsUIContext } from "../DocumentsUIContext";
import * as actions from "../../../_redux/documents/documentsActions";
import { formatFile } from "../../../../../../_bloomgoo/_helpers";

export function DocumentPreview({ show, mode, documentOriginalName, onHide, file }) {
  const uIContext = useDocumentsUIContext();
  const uIProps = useMemo(() => {
    return {
      selectedRows: uIContext && uIContext.selectedRows,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
      setIds: uIContext && uIContext.setIds,
    };
  }, [uIContext]);

  const dispatch = useDispatch();

  const onHideModal = () => {
    if (mode === "preview") {
      uIProps.setSelectedRows && uIProps.setSelectedRows([])
      uIProps.setIds && uIProps.setIds([])
    }
    onHide();
  }

  let fileActive = mode.includes("From") ? file : uIProps.selectedRows[0];

  console.log("fileActive: ", fileActive)
  const [isImage, setIsImage] = useState(
    fileActive?.documentFormat.includes('image') ? true : false
  );

  const getImageExtensionFile = (file) => {
    let url = "";
    if (file?.documentFormat.includes('image'))
      url = `${Axios.defaults.cdnURL}/${file.documentPath}`
    else {
      formatFile.map((item) => {
        if (file?.documentExtension === item) {
          url = `/media/users/upfile_${item}.png`;
        }
      })
    };
    return url;
  };

  return (
    <>
      <Modal
        size={"lg"}
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <span className="flaticon-folder"> </span>
            <FormattedMessage
              id="DOCUMENTS.UI.Title.Show"
              values={{
                params: documentOriginalName
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="text-center">
            {isImage ?
              <div className="my-10">
                <img
                  className="image-input-drop"
                  src={getImageExtensionFile(fileActive)}
                  width={400}
                  alt=""
                />
              </div>
              :
              <iframe
                src={`https://drive.google.com/viewer?url=${Axios.defaults.cdnURL}/${fileActive?.documentPath}&embedded=true`}
                title="file"
                width="750"
                height="750"
              ></iframe>
            }
          </div>

        </Modal.Body>
        <Modal.Footer className="form">
          <div className="form-group">
            <button
              type="button"
              onClick={onHideModal}
              className="btn btn-light btn-elevate btn-sm"
            >
              <i className="flaticon2-left-arrow-1 icon-ms"></i>
              <FormattedMessage id="COMMONS.UI.Cancel"></FormattedMessage>
            </button>
            <> </>
            <button
              type="button"
              onClick={() =>
                dispatch(actions.downloadFile(
                  fileActive?.documentCode,
                  fileActive?.documentOriginalName,
                  fileActive?.documentFormat
                ))}
              className="btn btn-primary btn-elevate btn-sm"
            >
              <i className="flaticon-download icon-sm"></i>
              <FormattedMessage id="COMMONS.UI.Download"></FormattedMessage>
            </button>
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
}