import React from "react";
import { FormattedMessage } from "react-intl";
import * as requestFromServer from "./contractsCrud";
import { contractsSlice, callTypes } from "./contractsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice";

const { actions } = contractsSlice;

// Area  actions Clean state
export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentContracts = () => (dispatch) => {
  dispatch(actions.resetCurrentContracts());
};

export const resetModalEmail = () => (dispatch) => {
  dispatch(actions.resetModalEmail());
};

export const resetModalPreExpired = () => (dispatch) => {
  dispatch(actions.resetModalPreExpired());
};

// Area actions fetch list

export const fetchAllContracts = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));

  return requestFromServer
    .getAllContracts()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.contractsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchAllContracts";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
export const fetchParentContracts = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));

  return requestFromServer
    .getAllContracts()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.contractsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchParentContracts";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list

export const fetchContractsPreExpired =
  (queryParams, buildingId) => (dispatch) => {
    const callType = callTypes.table;
    dispatch(actions.startCall({ callType: callTypes.table }));
    return requestFromServer
      .findContractsPreExpired(queryParams, buildingId)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.contractsPreExpiredFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchContracts";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const fetchContractsByBuildingIdAndUnitId =
  (buildingId, unitId, queryParams) => (dispatch) => {
    const callType = callTypes.table;
    dispatch(actions.startCall({ callType: callTypes.table }));
    return requestFromServer
      .findContractsByBuildingIdAndUnitId(buildingId, unitId, queryParams)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        const filterCount = {
          total: response.data.total,
          totalDeposit: response.data.totalDeposit,
          totalDepositExpired: response.data.totalDepositExpired,
          totalDepositValidated: response.data.totalDepositValidated,
          totalExpired: response.data.totalExpired,
          totalRent: response.data.totalRent,
          totalRentExpired: response.data.totalRentExpired,
          totalRentValidated: response.data.totalRentValidated,
          totalValidated: response.data.totalValidated,
          totalPreExpired: response.data.totalPreExpired,
        };
        dispatch(
          actions.contractsFetched({ totalCount, entities, filterCount })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchContracts";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const fetchContracts = (queryParams, buildingId) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callTypes.table }));
  return requestFromServer
    .findContracts(queryParams, buildingId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalDeposit: response.data.totalDeposit,
        totalDepositExpired: response.data.totalDepositExpired,
        totalDepositValidated: response.data.totalDepositValidated,
        totalExpired: response.data.totalExpired,
        totalRent: response.data.totalRent,
        totalRentExpired: response.data.totalRentExpired,
        totalRentValidated: response.data.totalRentValidated,
        totalValidated: response.data.totalValidated,
        totalPreExpired: response.data.totalPreExpired,
      };
      dispatch(actions.contractsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchContracts";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const findHistoryContractsByUnitId =
  (buildingId, unitId, queryParams) => (dispatch) => {
    const callType = callTypes.table;
    dispatch(actions.startCall({ callType: callTypes.table }));
    return requestFromServer
      .findHistoryContractsByUnitId(buildingId, unitId, queryParams)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.contractsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "findHistoryContractsByUnitId";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const fetchContractsByUnitId = (props) => (dispatch) => {
  const { unitId, mode } = props;
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getContractsByUnitId(unitId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      if (mode === "contract" || mode === "showContractByUnitId") {
        dispatch(actions.contractsFetchedByUnitId({ totalCount, entities }));
      } else dispatch(actions.contractsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchContractsByUnitId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const getContractsByUnitId = (unitId) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getContractsByUnitId(unitId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.contractsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchContractsByUnitId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area actions get item

export const exportContract = (contractId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));

  return requestFromServer
    .exportContract(contractId)
    .then((response) => {
      const fileDocx = response.data;
      dispatch(actions.contractsFileFetched({ fileDocx }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "exportContract";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchContract =
  ({ id, mode, callType }) =>
    (dispatch) => {
      if (mode === "clone") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getContractCloneById(id)
          .then((response) => {
            const contract = response.data;
            dispatch(actions.contractFetched({ contractForEdit: contract }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
            error.callType = callType;
            error.action = "fetchContract";
            error.status = "warning";
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(
              toastsSlice.actions.errorToast({ error, callType: callType })
            );
          });
      } else if (mode === "edit" || mode === "editContractOnDesks") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getContractEditById(id)
          .then((response) => {
            const contract = response.data;
            //console.log("contract: ", contract)
            dispatch(actions.contractFetched({ contractForEdit: contract }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
            error.callType = callType;
            error.action = "fetchContract";
            error.status = "warning";
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(
              toastsSlice.actions.errorToast({ error, callType: callType })
            );
          });
      } else if (mode === "show") {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getContractById(id)
          .then((response) => {
            const contract = response.data;
            dispatch(actions.contractShowFetched({ contractForShow: contract }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
            error.callType = callType;
            error.action = "fetchContract";
            error.status = "warning";
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(
              toastsSlice.actions.errorToast({ error, callType: callType })
            );
          });
      }
    };

export const fetchInitContractByDepositId =
  ({ depositId, mode, callType }) =>
    (dispatch) => {
      if (depositId) {
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getInitialContractByDepositId(depositId)
          .then((response) => {
            const contract = response.data;
            dispatch(actions.contractFetched({ contractForEdit: contract }));
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
            error.callType = callType;
            error.action = "fetchInitContractByDepositId";
            error.status = "warning";
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(
              toastsSlice.actions.errorToast({ error, callType: callType })
            );
          });
      }
    };

export const fetchContractExtension = (id, callType) => (dispatch) => {
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getContractExtensionById(id)
    .then((response) => {
      const contractExtension = response.data;
      dispatch(
        actions.contractExtensionFetched({
          contractExtensionForEdit: contractExtension,
        })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchContractExtension";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchInitContract =
  ({ unitId = 0, callType }) =>
    (dispatch) => {
      //console.log(callType);
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getInitContract(unitId)
        .then((response) => {
          const contract = response.data;
          dispatch(actions.contractFetched({ contractForEdit: contract }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchInitContract";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const updateSwapUnit =
  (unitIdFrom, unitIdTo, contract) => (dispatch) => {
    const callType = callTypes.exitForm;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .updateSwapUnit(unitIdFrom, unitIdTo, contract)
      .then((response) => {
        dispatch(actions.swapUnitUpdate({}));
        const notification = {
          status: "success",
          title: (
            <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
          ),
          content: (
            <FormattedMessage id={`${response?.data?.code}`}></FormattedMessage>
          ),
        };
        dispatch(
          toastsSlice.actions.successToast({ notification, callType: callType })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "updateSwapUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };
export const fetchContractByUnitIdFromAndUnitIdTo =
  (unitIdFrom, unitIdTo) => (dispatch) => {
    const callType = callTypes.initialization;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getContractByUnitIdFromAndUnitIdTo(unitIdFrom, unitIdTo)
      .then((response) => {
        const contract = response.data;
        dispatch(actions.contractFetched({ contractForEdit: contract }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "updateSwapUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

// Area actions create, update item

export const createContract = (contractForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createContract(contractForCreation)
    .then((response) => {
      const contract = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.create"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.contractCreated({ contract }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "createContract";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateContract = (contract, id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateContract(contract, id)
    .then((response) => {
      const contract = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.contractUpdated({ contract }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "updateContract";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updateContractsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForContracts(ids, status)
    .then(() => {
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.contractsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "updateContractsStatus";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateContractExtension =
  (contractExtension, contractId) => (dispatch) => {
    const callType = callTypes.reloadForm;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .extensionForContracts(contractExtension)
      .then((response) => {
        const notification = {
          status: "success",
          title: (
            <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
          ),
          content: (
            <FormattedMessage id="COMMONS.UI.SUCCESS.extension"></FormattedMessage>
          ),
        };
        contractExtension = response.data.contractExtension;
        dispatch(
          toastsSlice.actions.successToast({ notification, callType: callType })
        );
        dispatch(
          actions.contractExtension({
            contractExtension: contractExtension,
            contractId: contractId,
          })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "updateContractExtension";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

// Area actions delete item

export const deleteContracts = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteContracts(ids)
    .then(() => {
      dispatch(actions.contractsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "deleteContracts";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteContractExtension =
  (contractId, contractExtensionId) => (dispatch) => {
    const callType = callTypes.reloadForm;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .deleteContractExtension(contractId, contractExtensionId)
      .then((response) => {
        const notification = {
          status: "success",
          title: (
            <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
          ),
          content: (
            <FormattedMessage id="COMMONS.UI.SUCCESS.delete"></FormattedMessage>
          ),
        };

        dispatch(
          toastsSlice.actions.successToast({ notification, callType: callType })
        );
        dispatch(actions.contractExtensionDeleted({}));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "deleteContractExtension";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const deleteContract = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteContract(id)
    .then((response) => {
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.delete"></FormattedMessage>
        ),
      };
      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.contractDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "deleteContract";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchContractsCount = (buildingId) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return buildingId
    ? requestFromServer
      .findContractsCount(buildingId)
      .then((response) => {
        const contractsCount = response.data;
        dispatch(actions.contractsCount({ contractsCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchContractsCount";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(
          toastsSlice.actions.errorToast({ error, callType: callType })
        );
      })
    : dispatch(actions.contractsCount({ contractsCount: 0 }));
};
export const cleanContractsCount = (buildingId) => (dispatch) => {
  return dispatch(actions.countContractsClean());
};

export const fetchContractEmails =
  ({ id }) =>
    (dispatch) => {
      const callType = callTypes.action;
      dispatch(actions.startCall({ callType: callType }));

      return requestFromServer
        .getContractEmails(id)
        .then((response) => {
          const contractEmails = response.data;
          dispatch(actions.contractEmailsFetched({ contractEmails }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchAllContracts";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const updateContractEmails =
  ({ emails, id }) =>
    (dispatch) => {
      const callType = callTypes.exitForm;
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .updateContractEmails(emails, id)
        .then((response) => {
          const contract = response.data;
          const notification = {
            status: "success",
            title: (
              <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
            ),
            content: (
              <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
            ),
          };

          dispatch(
            toastsSlice.actions.successToast({ notification, callType: callType })
          );
          dispatch(actions.contractEmailsUpdated({ contract }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "updateContract";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const downloadContractExport = (buildingId) => (dispatch) => {
  return requestFromServer
    .getContractExportExcel(buildingId)
    .then((response) => {
      let blob = new Blob([response.data], { type: ".xlsx" }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Hop_dong.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.action = "downloadContractForm";
      error.status = "warning";
    });
};

export const downloadContractUnitExport = (buildingId, unitId) => (dispatch) => {
  const callType = callTypes.export;
  return requestFromServer
    .getContractUnitExportExcel(buildingId, unitId)
    .then((response) => {
      let blob = new Blob([response.data], { type: ".xlsx" }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Hop_dong.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }

      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.download" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.action = "downloadContractForm";
      error.status = "warning";
    });
};

export const downloadContractCustomerIdExport =
  (id, customerId) => (dispatch) => {
    return requestFromServer
      .getContractCustomerIdExport(id, customerId)
      .then((response) => {
        let blob = new Blob([response.data], { type: ".docx" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = `Hop_dong.docx`,
          disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      })

      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.action = "downloadContractForm";
        error.status = "warning";
      });
  };
