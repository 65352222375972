/* eslint-disable array-callback-return */
import { SUBSCRIBER_TYPE, USER_TYPE } from "../_CommonUIHelpers";

export const subscriberTypeFree = [
    "DASHBOARD_BASE", // Quản trị cơ sở
    "DESKS", // Bàn làm việc
    "ORGANIZATIONS", // Tổ chức
    "UNIT_SYMBOLS", // Đơn vị tính
    "PERIODS", // Chu kỳ
    "UNIT_TYPES", // Loại đơn vị thuê
    "FORMULARS", // Định mức
    "VOUCHER_CATEGORIES", // Hạng mục thu chi
    "CONTRACT_FORM_ORGANIZATIONS", // Mẫu biểu chung
    "BANK_ACCOUNT_ORGANIZATIONS", // Tài khoản chung
    // Phân hệ quản lý tổ chức
    "ORGANIZATION", // Tổ chức
    "DEPARTMENTS", // Phòng ban
    "POSITIONS", //Chức danh
    "ROLES", // Vai trò
    "USERS", // Người dùng
    "BUILDINGS", // Tòa nhà
    "FLOORS", // Tầng
    "UNITS", // Đơn vị thuê
    "METERS", // Công tơ
    "SERVICES", // Dịch vụ
    "CONTRACT_FORMS", // Mẫu biểu
    "CUSTOMERS", // Khách hàng
    "DEPOSITS", // Đặt cọc
    "CONTRACTS", // Hợp đồng
    "METER_LOGS", // Chỉ số công tơ
    "METER_LOGS_HISTORIES", // Lịch sử công tơ
    "INVOICES_HISTORIES", // Lịch sử hóa đơn
    "INVOICES", // Hóa đơn 
    "PAYMENT_VOUCHERS", // Phiếu Chi
    "RECEIPT_VOUCHERS", // Phiếu thu
    "SETTINGS", // Cấu hình chung
    "SETTINGS_PROFILE", // Hồ sơ cá nhân
    "SUPPORTS", // Trung tâm hỗ trợ
    "NEW_LETTERS", //Bảng tin 
    "BUILDINGPOSTS", //QUản lý bải đăng -> tòa nhà
    "UNITSPOSTS", //QUản lý bải đăng -> Đơn vị thuê
    "MANAGERS", // Quản lý bải đăng
    "TROUBLES", //Sự cố
    "COUPONS", // Phiếu giảm giá
    "CONTRACT_SOURCES", // Chủ nhà
    "CUSTOM_FIELDS", // Trường tùy chỉnh
    "FEEDBACKS", // Góp ý
    "SETTINGS_API_KEYS" // Cấu hình chung => API Key
];

export const subscriberTypePro = [
    "DASHBOARD", // Bảng quản trị
    "DASHBOARD_BASE", // Quản trị cơ sở
    "DESKS", // Bàn làm việc
    "NOTIFICATIONS", // Thông báo
    // Phân hệ bảng hàng
    "REALTIME", // Bảng hàng
    "UNITCARD", // Bán phòng
    "ORGANIZATIONS", // Tổ chức
    "DEPARTMENTS", // Phòng ban
    "POSITIONS", //Chức danh
    "ROLES", // Vai trò
    "USERS", // Người dùng
    "UNIT_SYMBOLS", // Đơn vị tính
    "PERIODS", // Chu kỳ
    "UNIT_TYPES", // Loại đơn vị thuê
    "FORMULARS", // Định mức
    "VOUCHER_CATEGORIES", // Hạng mục thu chi
    "CONTRACT_FORM_ORGANIZATIONS", // Mẫu biểu chung
    "BANK_ACCOUNT_ORGANIZATIONS", // Tài khoản chung
    "BUILDINGS", // Tòa nhà
    "FLOORS", // Tầng
    "UTILITIES", // Tiện ích
    "UNITS", // Đơn vị thuê
    "METERS", // Công tơ
    "SERVICES", // Dịch vụ
    "VEHICLE_TYPES", // Loại phương tiện
    "CONTRACT_FORMS", // Mẫu biểu
    "BANK_ACCOUNTS", // Tài khoản
    "SUPPLIES", // Nhà cung cấp
    "ASSET_WAREHOUSES", //Nguồn tài sản
    "ASSET_CATEGORIES", // Nhóm tài sản
    "ASSET_STATUSES", // Tình trạng tài sản
    "ASSETS", // Tài sản
    "ASSET_SEQUENCES", // Cấp phát thu hồi
    "GOOD_CATEGORIES", // Nhóm hàng hóa
    "ASSET_INVENTORIES", // Kiểm kê tài sản
    "CUSTOMERS", // Khách hàng
    "VEHICLES", // Phương tiện
    "DEPOSITS", // Đặt cọc
    "CONTRACTS", // Hợp đồng
    "METER_LOGS", // Chỉ số công tơ
    "METER_LOGS_HISTORIES", //Lịch sử công tơ
    "INVOICES_HISTORIES", // Lịch sử hóa đơn
    "METER_PARTNER_LOGS", // Chỉ số đối chiếu
    "INVOICES", // Hóa đơn
    "PAYMENT_VOUCHERS", // Phiếu Chi
    "RECEIPT_VOUCHERS", // Phiếu thu
    "REPORTS_OVERVIEW_CHART", // Báo cáo tài chính tổng quan 
    "REPORTS_DEBTS_OVERVIEW", // Báo cáo công nợ tổng quan 
    "REPORTS_PREPAYS_OVERVIEW", // Báo cáo giữ khách tổng quan
    "REPORTS_STATISTICS_OVERVIEW", // Báo cáo thống kê tổng quan
    "REPORTS_TASKS_OVERVIEW", // Báo cáo công việc tổng quan
    "REPORT_ASSETS_OVERVIEW", // Báo cáo công việc tổng quan
    "REPORT_METER_LOGS_OVERVIEW", // Báo cáo chỉ số tổng quan
    "REPORTS_FINANCIALS_CASH_FLOW",  // Báo cáo tài chính dòng tiền
    "SETTINGS", // Cấu hình chung
    "SETTINGS_PROFILE", // Hồ sơ cá nhân
    "SETTINGS_PERMISSION_BUILDING", // Phân quyền tòa nhà
    "SETTINGS_FUNCTIONS", // Chức năng
    "SETTINGS_BUILDINGS", // Cấu hình tổ chức
    "SETTINGS_BUILDING", // Cấu hình tòa nhà
    "SETTINGS_CURRENCIES", // Tiền tệ
    "SUPPORTS", // Trung tâm hỗ trợ
    "BUILDINGPOSTS", //QUản lý bải đăng -> tòa nhà
    "UNITSPOSTS", //QUản lý bải đăng -> Đơn vị thuê
    "MANAGERS", // Quản lý bải đăng
    "NEW_LETTERS", //Bảng tin
    "MANAGERS", // Quản lý bải đăng
    "TROUBLES", //Sự cố
    "COUPONS", // Phiếu giảm giá
    "CONTRACT_SOURCES", // Chủ nhà
    "CUSTOM_FIELDS", // Trường tùy chỉnh
    "FEEDBACKS", // Góp ý
    "SETTINGS_API_KEYS" //Cấu hình chung => API Key

];

export const subscriberTypePre = [
    // Phân hệ bảng quản trị
    "DASHBOARD", // Bảng quản trị
    "DASHBOARD_BASE", // Quản trị cơ sở
    "DESKS", // Bàn làm việc
    "NOTIFICATIONS", // Thông báo
    "NEWLETTERS", // Bảng tin
    "BUILDINGPOSTS", //QUản lý bải đăng -> tòa nhà
    "UNITSPOSTS", //QUản lý bải đăng -> Đơn vị thuê
    "MANAGERS", // Quản lý bải đăng
    // Phân hệ bảng hàng
    "REALTIME", // Bảng hàng
    "UNITCARD", // Bán phòng
    // Phân hệ quản lý cơ sở
    "BUILDINGS", // Tòa nhà
    "FLOORS", // Tầng
    "UTILITIES", // Tiện ích
    "UNITS", // Đơn vị thuê
    "METERS", // Công tơ
    "SERVICES", // Dịch vụ
    "VEHICLE_TYPES", // Loại phương tiện
    "CONTRACT_FORMS", // Mẫu biểu
    "BANK_ACCOUNTS", // Tài khoản
    "CUSTOM_FIELDS", // Trường tùy chỉnh
    // Phân hệ quản lý khách hàng
    "CUSTOMERS", // Khách hàng
    "VEHICLES", // Phương tiện
    // Phân hệ quản lý hợp đồng
    "DEPOSITS", // Đặt cọc
    "CONTRACTS", // Hợp đồng
    // Phân hệ quản lý tài chính
    "METER_LOGS", // Chỉ số công tơ
    "METER_LOGS_HISTORIES", //Lịch sử công tơ
    "METER_PARTNERS", // Công tơ đối chiếu
    "METER_PARTNER_LOGS", // Chỉ số đối chiếu
    "INVOICES", // Hóa đơn
    "INVOICES_HISTORIES", // Lịch sử hóa đơn
    "PAYMENT_VOUCHERS", // Phiếu Chi
    "RECEIPT_VOUCHERS", // Phiếu thu
    // Phân hệ quản lý tài sản
    "SUPPLIES", // Nhà cung cấp
    "ASSET_WAREHOUSES", //Nguồn tài sản
    "ASSET_CATEGORIES", // Nhóm tài sản
    "ASSET_STATUSES", // Tình trạng tài sản
    "ASSETS", // Tài sản
    "ASSET_SEQUENCES", // Cấp phát thu hồi
    "GOOD_CATEGORIES", // Nhóm hàng hóa
    "ASSET_INVENTORIES_PERIODS", // Đợt kiểm kê
    "ASSET_INVENTORIES", // Kiểm kê tài sản
    // Phân hệ quản lý công việc
    "PROJECTS", // Dự án
    "TASKS", // Công việc
    "TROUBLES", // Sự cố
    // Phân hệ quản lý môi giới
    "COLLABORATORS", // Môi giới
    "USER_COMMISSIONS", // Hoa hồng
    "COMMISSIONS", // Thanh toán
    // Phân hệ quản lý tổ chức
    "ORGANIZATION", // Tổ chức
    "DEPARTMENTS", // Phòng ban
    "POSITIONS", //Chức danh
    "ROLES", // Vai trò
    "USERS", // Người dùng
    // Phân hệ quản lý danh mục
    "UNIT_SYMBOLS", // Đơn vị tính
    "PERIODS", // Chu kỳ
    "UNIT_TYPES", // Loại đơn vị thuê
    "FORMULARS", // Định mức
    "VOUCHER_CATEGORIES", // Hạng mục thu chi
    "CONTRACT_SOURCES", // Nguồn hợp đồng
    "CONTRACT_FORM_ORGANIZATIONS", // Mẫu biểu chung
    "BANK_ACCOUNT_ORGANIZATIONS", // Tài khoản chung
    "CUSTOM_FIELD_ORGANIZATIONS", // Trường tùy chỉnh chung
    // Phân hệ tài liệu lưu trữ
    "DOCUMENTS", // Tài liệu
    // Phân hệ báo cáo & thống kê
    "REPORTS", // Báo cáo & thống kê
    "REPORTS_FINANCIALS", // Báo cáo tài chính
    "REPORTS_FINANCIALS_OVERVIEW", // Báo cáo tài chính tổng quan 
    "REPORTS_FINANCIALS_PROFITS",  // Báo cáo tài chính lợi nhuận
    "REPORTS_FINANCIALS_REVENUE_COSTS",  // Báo cáo tài chính doanh thu - chi phí
    "REPORTS_FINANCIALS_CASH_FLOW",  // Báo cáo tài chính dòng tiền
    "REPORTS_FINANCIALS_VOUCHERCATEGORIES",  // Báo cáo tài chính hạng mục
    "REPORTS_FINANCIALS_VOUCHERCATEGORY_BUILDINGS", // Báo cáo tài chính hạng mục theo tòa nhà
    "REPORTS_FINANCIALS_ACCOUNTS", // Báo cáo tài chính tài khoản
    "REPORTS_FINANCIALS_INVOICES", // Báo cáo tài chính hóa đơn
    "REPORTS_FINANCIALS_SERVICES", // Báo cáo tài chính dịch vụ 
    "REPORTS_FINANCIALS_SERVICES_BUILDINGS", // Báo cáo tài chính dịch vụ - tòa nhà
    "REPORTS_DEBTS", // Báo cáo công nợ 
    "REPORTS_DEBTS_OVERVIEW", // Báo cáo công nợ tổng quan 
    "REPORTS_DEBTS_INVOICES", // Báo cáo công nợ hóa đơn
    "REPORTS_DEBTS_BUILDINGS", // Báo cáo công nợ tòa nhà
    "REPORTS_PREPAYS", // Báo cáo giữ khách 
    "REPORTS_PREPAYS_OVERVIEW", // Báo cáo giữ khách tổng quan
    "REPORTS_PREPAYS_DEPOSITS", // Báo cáo giữ khách đặt cọc
    "REPORTS_PREPAYS_DEPOSIT_BUILDINGS", // Báo cáo giữ khách đặt cọc - tòa nhà
    "REPORTS_PREPAYS_PREPAY", // Báo cáo giữ khách tiền trả trước
    "REPORTS_PREPAYS_PREPAY_BUILDINGS", // Báo cáo giữ khách tiền trả trước - tòa nhà
    "REPORTS_STATISTICS", // Báo cáo thống kê
    "REPORTS_STATISTICS_OVERVIEW", // Báo cáo thống kê tổng quan
    "REPORTS_STATISTICS_FILLRATE", // Báo cáo thống kê tỷ lệ lấp đầy
    "REPORTS_STATISTICS_EMPTYRATE", // Bảng hàng
    "REPORTS_STATISTICS_EMPTYRATE", // Báo cáo thống kê tỷ lệ trống
    "REPORTS_STATISTICS_CONTRACTS", // Báo cáo thống kê hợp đồng
    "REPORTS_STATISTICS_CUSTOMERS", // Báo cáo thống kê khách hàng
    "REPORTS_STATISTICS_CUSTOMER_DEMOGRAPHICS", // Báo cáo thống kê tỷ lệ khách hàng
    "REPORTS_TASKS", // Báo cáo công việc 
    "REPORTS_TASKS_OVERVIEW", // Báo cáo công việc tổng quan
    "REPORTS_TASKS_PROJECT", // Báo cáo công việc dự án
    "REPORTS_TASKS_TASKS", // Báo cáo công việc công việc
    "REPORTS_TASKS_MY_TASKS", // Báo cáo công việc của tôi
    "REPORTS_ASSETS", // Báo cáo tài sản
    "REPORTS_ASSETS_OVERVIEW", // Báo cáo tài sản tổng quan
    "REPORTS_ASSETS_BUILDINGS", // Báo cáo phân bổ tài sản
    "REPORTS_METER_LOGS", // Báo cáo chỉ số
    "REPORTS_METER_LOGS_OVERVIEW", // Báo cáo chỉ số tổng quan
    "REPORTS_METER_LOGS_BUILDINGS", // Báo cáo chỉ số tòa nhà
    // Phân hệ cấu hình hệ thống
    "SETTINGS", // Cấu hình chung
    "SETTINGS_PROFILE", // Hồ sơ cá nhân
    "SETTINGS_PERMISSION_BUILDINGS", // Phân quyền tòa nhà
    "SETTINGS_FUNCTIONS", // Chức năng
    "SETTINGS_BUILDINGS", // Cấu hình tòa nhà
    "SETTINGS_ORGANIZATIONS", // Cấu hình chung
    "SETTINGS_ORGANIZATION_BUILDINGS", // Cấu hình theo tòa
    "SETTINGS_EMAIL_TEMPLATES", // Cấu hình mẫu email
    "SETTINGS_CURRENCIES", // Tiền tệ
    // Phân hệ hỗ trợ
    "FEEDBACKS", // Góp ý, đánh giá
    "SUPPORTS", // Trung tâm hỗ trợ
    "SETTINGS_API_KEYS" //Cấu hình chung => API Key
]

export const ServicePack = ["", subscriberTypeFree, subscriberTypePro, subscriberTypePre];

export const isUser = (user) => {
    if (user !== null && user.organization !== null) {
        if (user.userType === USER_TYPE.USER) {
            return true;
        }
        return false;
    }
    return false;
};

export const isPrivilegeUserPacket = (props) => {
    const { namePrivilege, type } = props
    let isPrivilege = false

    if (type === SUBSCRIBER_TYPE.FREE) {
        subscriberTypeFree.some(el => el === namePrivilege) ?
            isPrivilege = true : isPrivilege = false

    } else if (type === SUBSCRIBER_TYPE.PROFESSIONAL) {
        subscriberTypePro.some(el => el === namePrivilege) ?
            isPrivilege = true : isPrivilege = false
    } else if (type === SUBSCRIBER_TYPE.PREMIUM) {
        subscriberTypePre.some(el => el === namePrivilege) ?
            isPrivilege = true : isPrivilege = false
    }
    return isPrivilege
}

export const isPrivilegeUserAction = (user, privilegeName) => {
    let isPrivilegeUserAction = false;
    if (isUser(user)) {
        user && user.authorities && user.authorities.map((item) => {
            if (item === privilegeName) {
                isPrivilegeUserAction = true;
            }
        });
        if (user.isAdministrator === true) {
            isPrivilegeUserAction = true;
        }
    }
    return isPrivilegeUserAction;
};

export const isPrivilegeUserGroup = (user, privilegeName) => {
    let isPrivilege = false;
    user && user.authorities && user.authorities.map((item) => {
        if (item.includes(privilegeName) && !item.indexOf(privilegeName)) {
            isPrivilege = true;
        }
    });

    if (user.isAdministrator === true && ServicePack[user.organization.subscriberType].includes(privilegeName)) {
        isPrivilege = true;
    }

    return isPrivilege;
};



