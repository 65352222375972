import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialSettingAPIsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  settingAPIForEdit: null,
  notification: null,
  error: null,
  errorType: null,
  apiKeyForShow: null,
  settingAPICurrenciesExcluded: null,
  apiKeyValue: null,
  action: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState: initialSettingAPIsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },


    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.settingAPIForEditBuilding = null;
    },

    apiKeyGettedById: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.apiKeyValue = action.payload.apiKeyValue;
      state.action = action.payload.action;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // SHOW
    apiKeyShow: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.apiKeyForShow = action.payload.apiKeyForShow;
      state.action = action.payload.action;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // UPDATE
    apiKeyUpdate: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.action = action.payload.action
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.apiKeyUpdate.id) {
      //     return action.payload.apiKeyUpdate;
      //   }
      //   return entity;
      // });
      // state.currentAPIKeys.push(action.payload.apiKeyUpdate);
    },

    // DELETE
    apiKeyDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.action = action.payload.action;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentAPIKeys = [];
    },

    // RESET
    apiKeyReseted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.apiKeyValue = action.payload.apiKeyValue;
      state.action = action.payload.action;
      state.actionsLoading = false;
    },

    // FETCHALL
    apiKeyFetchIndex: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // CREATE
    apiKeyCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.action = action.payload.action;
      state.entities.unshift(action.payload.apiKey);
    },


    // FETCH buildingId
    apiKeysFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount
    },

    // EDIT
    apiKeyEdit: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.apiKeyEdit = action.payload.apiKeyEdit;
      state.action = action.payload.action;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // CLONE
    apiKeyClone: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.apiKeyEdit = action.payload.apiKeyClone;
      state.action = action.payload.action;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },



    // FETCH all
    apiKeysFetchedAll: (state, action) => {
      const { apiKeys } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = apiKeys;
    },

    // NEW
    apiKeyNew: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.apiKeyEdit = action.payload.apiKeyNew;
      state.action = action.payload.action;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // DELETE bulk
    apiKeyDeletedBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentAPIKeys = [];
      state.errorType = ERROR_TYPES_OBJ.END;

    },
  }
});
