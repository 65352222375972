/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "../../../../_bloomgoo/_partials/controls";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
import { TAB_ACTIVE_BUILDINGS, TAB_ACTIVE_DEFAULT_VALUE, TAB_ACTIVE_ORGANIZATION } from "../../../_helpers/TabActiveUIHelpers";
import { getHeaderSettings } from "../SettingsUIHelpers";
import SettingsBuilding from "./SettingsBuilding";
import SettingsBuildings from "./SettingsBuildings";

export default function SettingsCardBuilding() {

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [tabActive, setTabActive] = useState(
    isPrivilegeUserAction(user, "SETTINGS_ORGANIZATION_BUILDINGS_READ") ?
      TAB_ACTIVE_BUILDINGS
      :
      isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_READ") ?
        TAB_ACTIVE_ORGANIZATION
        :

        TAB_ACTIVE_DEFAULT_VALUE
  )


  let location = useLocation();
  const history = useHistory();



  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="btn-group" role="group" aria-label="...">
              {getHeaderSettings({ user, history, location })}
            </div>
          }
        >
        </CardHeader>
        <CardBody className="card-scroll">
          <div className="nav-tabs-filter">
            <ul className="nav nav-tabs nav-tabs-line">
              {isPrivilegeUserAction(user, "SETTINGS_ORGANIZATION_BUILDINGS_READ") &&
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === TAB_ACTIVE_BUILDINGS && 'active'}`}
                    data-toggle="tab"
                    href="#kt_tab_pane_1"
                    onClick={() => setTabActive(TAB_ACTIVE_BUILDINGS)}
                  >
                    Tùy chỉnh
                  </a>
                </li>
              }
              {isPrivilegeUserAction(user, "SETTINGS_ORGANIZATIONS_READ") &&
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === TAB_ACTIVE_ORGANIZATION && 'active'}`}
                    data-toggle="tab"
                    href="#kt_tab_pane_2"
                    onClick={() => setTabActive(TAB_ACTIVE_ORGANIZATION)}
                  >
                    Mặc định
                  </a>
                </li>
              }

            </ul>
          </div >
          <div className="modal-body container container-fluid px-40 pb-5">
            {tabActive === TAB_ACTIVE_BUILDINGS && <SettingsBuilding />}
            {tabActive === TAB_ACTIVE_ORGANIZATION && <SettingsBuildings />}
          </div>
        </CardBody>
      </Card>
    </>
  );
}
