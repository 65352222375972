import React, { useMemo, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Marquee from "react-fast-marquee";
import {
  Alert,
  FloatButton,
  Typography,
  Drawer,
  Timeline,
  Form,
  Badge,
  notification,
} from "antd";
import {
  CustomerServiceOutlined,
  CommentOutlined,
  TrophyOutlined,
  QuestionCircleOutlined,
  BugOutlined,
} from "@ant-design/icons";
import { useHtmlClassService } from "../../_core/BloomGooLayout";
import UpgradePage from "../../../../app/pages/UpgradePage";
import { SupportDrawerEditDialog } from "../../../../app/modules/Supports/pages/supports/support-drawer-edit-dialog/SupportDrawerEditDialog";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  const { user } = useSelector(
    ({ auth, state }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const NOTIFICATION_EXPIRED_DAYS = 999999999;

  let remainDay = (nowDate = new Date(), endDate = user.organization.endTime) =>
    Math.ceil(Math.abs(nowDate - endDate) / 86400000);

  let expiredDays = remainDay();

  // CHANGELOG
  const [openChangeLog, setOpenChangeLog] = useState(false);
  const showDrawerChangeLog = () => {
    setOpenChangeLog(true);
  };
  const onCloseChangeLog = () => {
    setOpenChangeLog(false);
  };
  const dispatch = useDispatch();
  // TICKET
  const [openTicket, setOpenTicket] = useState(false);
  const showDrawerTicket = () => {
    setOpenTicket(true);
  };
  const onCloseTicket = () => {
    setOpenTicket(false);
  };

  // UPDATE
  const [openUpdatePage, setOpenUpdatePage] = useState(false);
  const showUpdatePage = () => {
    setOpenUpdatePage(true);
  };

  const [formTicket] = Form.useForm();

  const [api] = notification.useNotification();
  const openNotification = (placement) => {
    console.log("placement: ", placement);
    api.info({
      message: `Notification ${placement}`,
      description: "Hello",
      placement,
      style: { zIndex: 9999 },
    });
  };

  return (
    <div
      className={`footer bg-white d-flex flex-lg-column  ${layoutProps.footerClasses}`} //className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between px-0`}
      >
        <Alert
          type="info"
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              <Typography.Text className="mr-5">
                Tài khoản:{" "}
                <Typography.Text strong type="success">
                  {user.username}
                </Typography.Text>{" "}
              </Typography.Text>
              {expiredDays <= NOTIFICATION_EXPIRED_DAYS && (
                <Typography.Text className="mr-5">
                  Tài khoản còn{" "}
                  <Typography.Text
                    strong
                    type={`${
                      expiredDays < 15
                        ? "danger"
                        : expiredDays < 30
                        ? "warning"
                        : "success"
                    }`}
                  >
                    {expiredDays}
                  </Typography.Text>{" "}
                  ngày nữa là hết hạn, vui lòng gia hạn
                </Typography.Text>
              )}
              <Typography.Text className="mr-5">
                Hotline, Zalo:{" "}
                <Typography.Link
                  href="tel:0968031388"
                  target="_blank"
                  className="mr-5"
                >
                  0968031388
                </Typography.Link>
              </Typography.Text>

              <Typography.Text className="mr-5">
                Tải phần mềm điều khiển máy tính từ xa:{" "}
                <Typography.Link
                  href="https://www.ultraviewer.net/vi/UltraViewer_setup_6.5_vi.exe"
                  target="_blank"
                  className="mr-5"
                >
                  UltraViewer
                </Typography.Link>
              </Typography.Text>

              <Typography.Text className="mr-5">
                Tải phiên bản:{" "}
                <Typography.Link
                  href="/apps/ChuoiCanHoSetup1.0.0.exe"
                  target="_blank"
                  className="mr-2"
                >
                  Desktop App
                </Typography.Link>
                {/* <Typography.Link href="#" target="_blank" className="mr-2">
                  iOS App,
                </Typography.Link>
                <Typography.Link href="#" target="_blank" className="mr-2">
                  Android App.
                </Typography.Link> */}
              </Typography.Text>

              <Typography.Text className="mr-5">
                {today.toString()} &copy; CHUOICANHO |{" "}
                <Typography.Link href="https://chuoicanho.com" target="_blank">
                  www.chuoicanho.com
                </Typography.Link>
              </Typography.Text>
            </Marquee>
          }
        />
        <Badge dot offset={[-30, -70]} size="small">
          <FloatButton.Group
            icon={<CustomerServiceOutlined />}
            type="primary"
            trigger="click"
          >
            <Badge dot>
              <FloatButton
                icon={<BugOutlined />}
                onClick={showDrawerChangeLog}
                tooltip={"Nhật ký thay đổi"}
              />
            </Badge>
            <FloatButton
              icon={<QuestionCircleOutlined />}
              onClick={showDrawerTicket}
              tooltip={"Tạo yêu cầu"}
            />
            <FloatButton
              icon={<TrophyOutlined />}
              onClick={showUpdatePage}
              tooltip={"Nâng cấp"}
            />
            <FloatButton
              icon={<CommentOutlined />}
              href="tel:0968031388"
              tooltip={"Hotline, Zalo: 0968031388"}
            />
          </FloatButton.Group>
        </Badge>
        <Drawer
          title="Lịch sử cập nhật"
          placement="left"
          headerStyle={{ padding: 0 }}
          onClose={onCloseChangeLog}
          open={openChangeLog}
        >
          <Timeline>
            <Timeline.Item>
              <p>01/12/2022:</p>
              <p>Cập nhật chức năng nâng cấp</p>
              <p>Cập nhật chức năng tạo yêu cầu</p>
            </Timeline.Item>
            <Timeline.Item>
              <p>01/12/2022:</p>
              <p>Cập nhật phiên bản v2.0</p>
              <p>Cập nhật chức năng bảng quản trị cơ sở</p>
              <p>Tối ưu UI/UX mọi chức năng trên 1 màn hình</p>
            </Timeline.Item>
            <Timeline.Item>
              <p>01/03/2022:</p>
              <p>Ra mắt phiên bản mới 1.0</p>
            </Timeline.Item>
          </Timeline>
        </Drawer>

        {openTicket && (
          <SupportDrawerEditDialog
            show={openTicket}
            onHide={() => setOpenTicket(false)}
            mode="new"
            isSelf={false}
          />
        )}

        <UpgradePage
          open={openUpdatePage}
          onClose={() => setOpenUpdatePage(false)}
          closable={true}
        />
      </div>
    </div>
  );
}
