/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/supports/supportsActions";
import * as actionsComments from "../../../_redux/supportComments/supportCommentsActions";
import * as actionsUsers from "../../../../Users/_redux/users/usersActions";
import { useSupportsUIContext } from "../SupportsUIContext";
import { getStringDateNow, uploadAttachmentData } from '../../../../../../_bloomgoo/_helpers';
import { CommentTypeEnum, ERROR_TYPES_OBJ, useDidMountEffect } from "../../../../../_helpers/_CommonUIHelpers";
import { isAdministrator } from "../../../../../_helpers/PrivilegesUIHelper/Administrator";
import { SupportDrawerEditForm } from "./SupportDrawerEditForm";

export function SupportDrawerEditDialog({ id, show, onHide, isSelf = true, mode }) {
  // Supports UI Context
  const uIContext = useSupportsUIContext();
  const uIProps = useMemo(() => {
    return {
      queryParams: uIContext && uIContext.queryParams,
      setIds: uIContext && uIContext.setIds,
      setSelectedRows: uIContext && uIContext.setSelectedRows,
    };
  }, [uIContext]);

  // Supports Redux state
  const dispatch = useDispatch();
  const { actionsLoading, supportForEdit, parents, users, errorType, commentForEdit, error } = useSelector(
    (state) => ({
      actionsLoading: state.supports.actionsLoading,
      supportForEdit: state.supports.supportForEdit,
      commentForEdit: state.supportComments.commentForEdit,
      parents: state.supports.parents,
      users: state.users.entities,
      errorType: state.supports.errorType,
      error: state.supports.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    id && dispatch(actionsComments.fetchInitComments(id))
    dispatch(actions.fetchParentSupports());
    id ? (isAdministrator(user) ? dispatch(actions.fetchSupport(id, mode)) : dispatch(actions.fetchSupportContact(id, mode))) : (isAdministrator(user) ? dispatch(actions.fetchInitSupports()) : dispatch(actions.fetchInitSupportsContact()));
    dispatch(actionsUsers.fetchAllUsers());
  }, [id, dispatch]);


  //console.log("commentForEdit: ", commentForEdit)
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    })
  )

  // server request for saving support
  const [fileSelected, setFileSelected] = useState({ selectedFile: null })
  const saveSupport = (support) => {
    if (!id) {
      // server request for creating task
      isAdministrator(user) ? dispatch(actions.createSupport(uploadAttachmentData(fileSelected.selectedFile, support))) : dispatch(actions.createSupportContact(uploadAttachmentData(fileSelected.selectedFile, support)))
    } else {
      // server request for updating task
      isAdministrator(user) ? dispatch(actions.updateSupport(uploadAttachmentData(fileSelected.selectedFile, support), support.id)) : dispatch(actions.updateSupportContact(uploadAttachmentData(fileSelected.selectedFile, support), support.id))
    }
  };

  const handleFieldChange = (e, props) => {
    let fieldName = e.target.name;
    if (fieldName === 'status') {
      if (e.target.value === 2)
        props.values.startDateString === null && props.setFieldValue("startDateString", getStringDateNow())
      if (e.target.value === 4) {
        props.setFieldValue("process", 100)
        props.values.dueDateString === null && props.setFieldValue("dueDateString", getStringDateNow())
      }
      if (e.target.value === 5)
        props.values.completionDateString === null && props.setFieldValue("completionDateString", getStringDateNow())
    }

    if (fieldName === 'commentType') {
      //console.log("e.target: ", e.target.checked)
      //console.log("value: ", props.values.commentType)
      if (e.target.checked === true)
        props.setFieldValue('commentType', CommentTypeEnum.EXTERNAL)
      else props.setFieldValue('commentType', CommentTypeEnum.INTERNAL)
    }
  };
  const onHideModal = () => {
    onHide();
    if (errorType === ERROR_TYPES_OBJ.END) {
      uIProps.setIds && uIProps.setIds && uIProps.setIds && uIProps.setIds([]);
      uIProps.setSelectedRows && uIProps.setSelectedRows && uIProps.setSelectedRows && uIProps.setSelectedRows([]);
      if (isSelf) {
        isAdministrator(user) ? dispatch(actions.fetchSupports(uIProps.queryParams)) : dispatch(actions.fetchSupportsContact(uIProps.queryParams));
      }

    };
  }
  useDidMountEffect(() => {
    if (errorType === ERROR_TYPES_OBJ.END) {
      onHideModal();
    }
  }, [errorType, error]);

  return (
    <SupportDrawerEditForm
      saveSupport={saveSupport}
      actionsLoading={actionsLoading}
      users={users}
      mode={mode}
      support={supportForEdit || []}
      comment={commentForEdit}
      parents={parents}
      onHide={onHideModal}
      fileSelected={fileSelected}
      setFileSelected={setFileSelected}
      handleFieldChange={handleFieldChange}
      error={error}
      show={show}
      id={id}
    />);
}
