/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ErrorMessages, ModalProgressBar } from "../../../../_bloomgoo/_partials/controls";
import { Table } from "react-bootstrap";
import * as  UIHelpers from "../../../_helpers/_CommonUIHelpers";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";
import { useEmailsUIContext } from "./EmailsUIContext";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";

export function EmailTemplateDeletesDialog({ onHide, show }) {
  const uIContext = useEmailsUIContext();
  const uIProps = useMemo(() => {
    return {
      ids: uIContext.ids,
      queryParams: uIContext.queryParams,
      selectedRows: uIContext.selectedRows,
      setIds: uIContext.setIds,
      setSelectedRows: uIContext.setSelectedRows,
    };
  }, [uIContext]);

  //console.log(props);
  const { actionsLoading, errorType, error, isLoading } = useSelector(
    (state) => ({
      isLoading: state.emailTemplates.isLoading,
      actionsLoading: state.emailTemplates.actionsLoading,
      errorType: state.emailTemplates.errorType,
      error: state.emailTemplates.error,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!metersGovernmentUIProps.selectedRows.length) {
    //   onHide();
    // }
    return () => {
      onHide();
    }
  }, []);

  UIHelpers.useDidMountEffect(() => {
    if (errorType === UIHelpers.ERROR_TYPES_OBJ.END) {
      uIProps.setIds([]);
      uIProps.setSelectedRows([]);
      dispatch(actions.fetchEmailTemplates(uIProps.queryParams));

      // refresh list after update
      onHide();
    }
  }, [errorType, error]);

  const deleteEmailTemplate = () => {
    if (uIProps.ids.length > 1) {
      dispatch(actions.deleteBulkEmailTemplate(uIProps.ids))
    } else {
      dispatch(actions.deleteEmailTemplateById({ id: uIProps.ids[0] }))
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >

      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <span className="flaticon-cogwheel-1"> &nbsp; </span>
          {/* <FormattedMessage id="COMMONS.UI.Delete"></FormattedMessage>{" "} */}
          {`Mẫu tin > Xóa`}
        </Modal.Title>
      </Modal.Header>
      <>
        {
          // metersGovernmentUIProps.ids.length > 0 &&
          <Modal.Body className="overlay overlay-block cursor-default">
            {error && error.action === "deleteRole" && ((error.response === !undefined || error.response === !null) ?
              <ErrorMessages type="danger" message={error && error.response.data ? error.response.data.code : "COMMONS.UI.Notification.Danger"} icon="flaticon-danger text-danger" /> :
              <ErrorMessages type="danger" message={error && error.clientMessage} icon="flaticon-danger text-danger" />
            )
            }
            {!isLoading && (
              <>
                <div className="form-group">
                  <span className="">
                    <FormattedMessage id="COMMONS.UI.DeleteConfirm" />
                  </span>
                </div>
                <div className="form-group">
                  <Table bordered hover size="sm">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center"><FormattedMessage id="MODEL.UI.positions.code" /></th>
                        <th className="text-center"><FormattedMessage id="MODEL.UI.positions.name" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        uIProps.selectedRows && uIProps.selectedRows.map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="text-center">{rowIndex + 1}</td>
                            <td className="text-left">
                              <span className="list-timeline-text">
                                <span>
                                  {item?.code}
                                </span>
                              </span>
                            </td>
                            <td className="text-left">
                              <span className="list-timeline-text">
                                <span>
                                  {item?.name}
                                </span>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            {isLoading && (
              <span>
                <FormattedMessage id="COMMONS.UI.IsDeleting"></FormattedMessage>
              </span>
            )}

          </Modal.Body>}
        <Modal.Footer>
            <Button
            handleClick={onHide}
            message="COMMONS.UI.Close"
            icon="flaticon-cancel icon-md"
            className="btn btn-light btn-elevate btn-md"
          />
          &nbsp;
          {uIProps.ids.length > 0 && (
            <Button
              actionsLoading={actionsLoading}
              handleClick={() => deleteEmailTemplate()}
              disabled={actionsLoading}
              className="btn btn-delete btn-danger btn-elevate btn-md"
              message="COMMONS.UI.Delete.Confirm"
              icon="flaticon-edit-1 icon-md"
            />
          )}
        </Modal.Footer>
      </>
    </Modal >

  );
}