export const isAdministrator = (user) => {
    const ORGANIZATION_TYPES = {
        OWNERSHIP: 1,
        SUBSCRIBERS: 2,
    }

    if (user !== null && user.organization !== null) {
        if (user.organization.organizationType === ORGANIZATION_TYPES.OWNERSHIP) {
            return true;
        }
        return false;
    }
    return false;
};