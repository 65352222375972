/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditVoucherCategoryDialog, openDeleteVoucherCategoryDialog }
) => {
  const popover = (
    <Popover
      id="popover-basic"
      style={{ maxWidth: "none" }}
    >
      <Popover.Content className="p-1">
        <ul className="navi navi-hover" style={{ width: "130px" }}>
          <li className="navi-item" onClick={() => openEditVoucherCategoryDialog(row.id)}>
            <span type="button" className="navi-link">
              <span className="navi-icon">
                <i className="flaticon-edit text-primay"></i>
              </span>
              <span className="navi-text"><FormattedMessage id="COMMONS.UI.Edit"></FormattedMessage></span>
            </span>
          </li>
          {/* <li className="navi-item" onClick={() => openCloneDepartmentPage(row.id)}>
        <span type="button" className="navi-link">
          <span className="navi-icon">
            <i className="flaticon-add text-primay"></i>
          </span>
          <span className="navi-text"><FormattedMessage id="COMMONS.UI.Clone"></FormattedMessage></span>
        </span>
      </li> */}
          <li className="navi-item" onClick={() => openDeleteVoucherCategoryDialog(row.id)}>
            <span type="button" className="navi-link">
              <span className="navi-icon">
                <i className={`flaticon-delete text-danger`}></i>
              </span>
              <span className={`navi-text text-danger`}><FormattedMessage id="COMMONS.UI.Delete"></FormattedMessage></span>
            </span>
          </li>
        </ul>
      </Popover.Content>
    </Popover >
  );
  return (
    <>

      <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover} >
        <span type="button">
          <i className="ki ki-bold-more-hor icon-ms text-hover-primary"></i>
        </span>
      </OverlayTrigger>

    </>
  )
}
