import axios from "axios";

export const SUPPORTS_URL = "/supportComments";

// CREATE =>  POST: add a new comment to the server
export function createComment(supportId, comment) {
  return axios.post(`${SUPPORTS_URL}/support/${supportId}`, comment);
}

export function getInitComment(supportId) {
  return axios.get(`${SUPPORTS_URL}/support/${supportId}/create`);
}

// READ
export function getAllComments() {
  return axios.get(SUPPORTS_URL);
}

export function getCommentById(commentId) {
  return axios.get(`${SUPPORTS_URL}/${commentId}/edit`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findComments(supportId, queryParams) {
  return axios.post(`${SUPPORTS_URL}/support/${supportId}/find`, queryParams);
}

export function findCommentsContact(supportId, queryParams) {
  return axios.post(`${SUPPORTS_URL}/support/${supportId}/contact/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateComment(comment) {
  return axios.put(`${SUPPORTS_URL}/${comment.id}`, comment);
}

// UPDATE Status
export function updateStatusForComments(ids, status) {
  return axios.post(`${SUPPORTS_URL}/updateStatusForComments`, {
    ids,
    status,
  });
}

// DELETE => delete the comment from the server
export function deleteComment(commentId) {
  return axios.delete(`${SUPPORTS_URL}/${commentId}`);
}

// DELETE Comments by ids
export function deleteComments(ids) {
  return axios.delete(`${SUPPORTS_URL}/bulk`, { data: { ids } });

}
