import axios from "axios";

export const CONTRACTS_URL = "/contracts";

// CREATE =>  POST: add a new contract to the server
export function createContract(contract) {
  return axios.post(CONTRACTS_URL, contract);
}

// READ
export function getAllContracts() {
  return axios.get(CONTRACTS_URL);
}

export function exportContract(contractId) {
  return axios.get(`${CONTRACTS_URL}/${contractId}/export`);
}

export function getInitContract(unitId) {
  return axios.get(`${CONTRACTS_URL}/unit/${unitId}/create`);
}

export function getContractsByUnitId(unitId) {
  return axios.get(`${CONTRACTS_URL}/unit/${unitId}`);
}

export function findContractsByBuildingIdAndUnitId(
  buildingId,
  unitId,
  queryParams
) {
  return axios.post(
    `${CONTRACTS_URL}/building/${buildingId}/unit/${unitId}/findInUnitDetails`,
    queryParams
  );
}

export function getInitialContractByDepositId(depositId) {
  return axios.get(`${CONTRACTS_URL}/deposit/${depositId}/create`);
}

export function getContractCloneById(contractId) {
  return axios.get(`${CONTRACTS_URL}/${contractId}/clone`);
}

export function getContractExtensionById(contractId) {
  return axios.get(`${CONTRACTS_URL}/${contractId}/extension`);
}

export function getContractById(contractId) {
  return axios.get(`${CONTRACTS_URL}/${contractId}`);
}

export function getContractEditById(contractId) {
  return axios.get(`${CONTRACTS_URL}/${contractId}/edit`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findContracts(queryParams, buildingId) {
  return axios.post(
    `${CONTRACTS_URL}/building/${buildingId}/find`,
    queryParams
  );
}

export function findContractsPreExpired(queryParams, buildingId) {
  return axios.post(
    `${CONTRACTS_URL}/building/${buildingId}/preExpired/find`,
    queryParams
  );
}

export function findHistoryContractsByUnitId(buildingId, unitId, queryParams) {
  return axios.post(
    `${CONTRACTS_URL}/building/${buildingId}/unit/${unitId}/find`,
    queryParams
  );
}
// UPDATE => PUT: update the procuct on the server
export function updateContract(contract, id) {
  //console.log("contract: ", contract)
  return axios.put(`${CONTRACTS_URL}/${id}`, contract, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// UPDATE Status
export function updateStatusForContracts(ids, status) {
  return axios.post(`${CONTRACTS_URL}/updateStatusForContracts`, {
    ids,
    status,
  });
}

export function extensionForContracts(contractExtension) {
  return axios.post(
    `${CONTRACTS_URL}/${contractExtension.id}/extension`,
    contractExtension
  );
}

// DELETE => delete the contract from the server
export function deleteContract(contractId) {
  return axios.delete(`${CONTRACTS_URL}/${contractId}`);
}

export function deleteContractExtension(contractId, contractExtensionId) {
  return axios.delete(
    `${CONTRACTS_URL}/${contractId}/extension/${contractExtensionId}`
  );
}

// DELETE Contracts by ids
export function deleteContracts(ids) {
  return axios.delete(`${CONTRACTS_URL}/bulk`, { data: { ids } });
}

export function updateSwapUnit(unitIdFrom, unitIdTo, contract) {
  //console.log(contract);
  return axios.post(
    `${CONTRACTS_URL}/unitFrom/${unitIdFrom}/unitTo/${unitIdTo}/transfer`,
    contract
  );
}
export function getContractByUnitIdFromAndUnitIdTo(unitIdFrom, unitIdTo) {
  return axios.get(
    `${CONTRACTS_URL}/unitFrom/${unitIdFrom}/unitTo/${unitIdTo}/transfer`
  );
}

export function findContractsCount(buildingId) {
  return axios.get(`${CONTRACTS_URL}/building/${buildingId}/count`);
}

export function getContractEmails(id) {
  return axios.get(`${CONTRACTS_URL}/${id}/emails`);
}

export function updateContractEmails(emails, id) {
  return axios.post(`${CONTRACTS_URL}/${id}/emails`, emails);
}

export function getContractExportExcel(buildingId) {
  return axios.get(`${CONTRACTS_URL}/buildings/${buildingId}/export`, {
    // include your additional POSTed data here
    responseType: "blob",
  });
}

export function getContractUnitExportExcel(buildingId, unitId) {
  return axios.get(
    `${CONTRACTS_URL}/buildings/${buildingId}/units/${unitId}/export`,
    {
      // include your additional POSTed data here
      responseType: "blob",
    }
  );
}

export function getContractCustomerIdExport(id, customerId) {
  return axios.get(`${CONTRACTS_URL}/${id}/customers/${customerId}/export`, {
    // include your additional POSTed data here
    responseType: "blob",
  });
}
