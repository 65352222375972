/* eslint-disable eqeqeq */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable array-callback-return */

export const TAB_ACTIVE_DEFAULT_VALUE = `default`;      // Đơn vị thuê
export const TAB_ACTIVE_UNITS = `units`;      // Đơn vị thuê
export const TAB_ACTIVE_PAYMENTS = `payments`;      // Thanh toán
export const TAB_ACTIVE_FLOORS = `floors`;      // Tầng
export const TAB_ACTIVE_SERVICES = `services`;  // Dịch vụ
export const TAB_ACTIVE_GOODS = `goods`;        // Hàng hóa
export const TAB_ACTIVE_BUILDINGS = `buildings`;        // Tòa nhà
export const TAB_ACTIVE_ORGANIZATION = `organization`;        // Tổ chức
export const TAB_ACTIVE_CONTRACTS = `contracts`;        // Hợp đồng
export const TAB_ACTIVE_DEPOSITS = `deposits`;        // Hợp đồng
export const TAB_ACTIVE_CUSTOMERS = `customers`;        // Khách hàng
export const TAB_ACTIVE_INVOICES = `invoices`;        // Khách hàng

export const TAB_ACTIVE_ALL = `all`;        // Tất cả
export const TAB_ACTIVE_EMPTY = `empty`;        // Trống
export const TAB_ACTIVE_RENTED = `rented`;        // Đã thuê
export const TAB_ACTIVE_DEPOSIT = `deposit`;        // Giữ chỗ
export const TAB_ACTIVE_EMPTY_DEPOSIT = `empty-deposit`;        // Trống giữ chỗ
export const TAB_ACTIVE_RENTED_DEPOSIT = `rented-deposit`;        // Đã thuê giữ chỗ
export const TAB_ACTIVE_STOPPED = `stopped`;        // Tạm ngưng

export const TAB_ACTIVE_INFO = `info`;      // Thông tin
export const TAB_ACTIVE_HISTORY = `history`;      // Lịch sử
export const TAB_ACTIVE_HISTORY_CONTRACTS = `history-contracts`;      // Lịch sử đặt cọc
export const TAB_ACTIVE_HISTORY_DEPOSITS = `history-deposits`;      // Lịch sử giữ chỗ
export const TAB_ACTIVE_HISTORY_INVOICES = `history-invoices`;      // Lịch sử hóa đơn

