
import React from "react";
import { DatetimeColumnFormatter } from "../../../../../_helpers/StringHelpers";

export function AlertCreate(props) {

    const { intl, alertCreateInfo } = props
    const messageParams = JSON.parse(alertCreateInfo.messageParams);
    const messageId = alertCreateInfo.messageId;
    const notificationType = alertCreateInfo.notificationType;
    const functionName = alertCreateInfo.functionName;

    return (
        <>

            <span type="button"
                className="navi-link">
                <span className="navi-icon">
                    {notificationType === "NOTIFICATION" && <i className="flaticon-alert text-success"></i>}
                    {notificationType === "WARNING" && <i className="flaticon-exclamation text-danger"></i>}
                </span>
                <span className="navi-text">
                    <span className="text-wrap overflow-wrap" dangerouslySetInnerHTML={{
                        __html: intl.formatHTMLMessage(
                            {
                                id: messageId,
                            },
                            {
                                ...messageParams,
                                params: intl.formatHTMLMessage(
                                    {
                                        id: `NOTIFICATIONS.UI.${functionName.toUpperCase()}`,
                                    },

                                )
                            }
                        )
                    }}
                    />
                    <br />
                    <span className="text-muted">{DatetimeColumnFormatter(alertCreateInfo.eventAt)}</span>
                </span>
            </span>

        </>
    );
}