import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialContractFormsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  contractFormForEdit: null,
  contractFormForShow: null,

  filterCount: null,
  currentContractForm: null,
  currentContractForms: [],
  notification: null,
  error: null,

  isError: null,
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const contractFormsSlice = createSlice({
  name: "contractForms",
  initialState: initialContractFormsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },




    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentContractForm = null;
      state.currentContractForms = [];
      state.currentContractForm = null;
      state.currentContractForms = [];
    },

    resetModalShow: (state, action) => {
      state.errorType = null;
      state.isError = null;
      state.error = null;
      state.contractFormForShow = null;
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.contractFormForEdit = null;
      state.currentContractForm = null;
    },

    resetCurrentContractForms: (state, action) => {
      state.currentContractForm = null;
      state.currentContractForms = [];
    },

    // getContractFormById
    contractFormFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.contractFormForEdit = action.payload.contractFormForEdit;
      state.error = null;

    },

    //show
    contractFormShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.contractFormForShow = action.payload.contractFormForShow;
      state.error = null;

    },
    // findAllContractForms
    contractFormsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findContractForms
    contractFormsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },
    // findParentContractForms
    contractFormsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createContractForm
    contractFormCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.isError = false;
      state.entities.map((entity, rowIndex) => {
        if (action.payload.contractForm.isDefault === true || action.payload.contractForm.isDefault === "true") {
          entity.isDefault = false;
        }
      });
      state.currentContractForm = action.payload.contractForm;
      state.currentContractForms.push(action.payload.contractForm);
      //After the map is done, push 
      state.entities.unshift(action.payload.contractForm)

    },
    // updateContractForm
    contractFormUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (action.payload.contractForm.isDefault) {
          entity.isDefault = false;
        }
        if (entity.id === action.payload.contractForm.id) {
          return action.payload.contractForm;
        }
        return entity;
      });
      state.currentContractForm = action.payload.contractForm;
      state.currentContractForms.push(action.payload.contractForm);

    },

    // deleteContractForm
    contractFormDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentContractForm = null;
      state.currentContractForms = [];


    },
    // deleteContractForms
    contractFormsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentContractForm = null;
      state.currentContractForms = [];

    },
    // contractFormsUpdateState
    contractFormsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
