import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function SettingCurrencyDialogHeader() {

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = <FormattedMessage id="PAYMENTCURRENCIES.UI.Title"></FormattedMessage>;
    setTitle(_title);
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
