/* eslint-disable */
"use strict";

import { KTUtil } from "./../../components/util.js";
import KTLayoutContent from "./content.js";

var KTLayoutStretchedCard = (function () {
  // Private properties
  var _element;

  // Private functions

  var _init = function () {
    _element = KTUtil.getById("kt_page_stretched_card");

    if (!_element) {
      return;
    }

    var scroll = KTUtil.find(_element, ".card-scroll");
    var scrollFilter = KTUtil.find(_element, ".card-scroll-filter");
    var footerReport = KTUtil.find(_element, ".footer-report");
    var scrollLeft = KTUtil.find(_element, ".card-scroll-left");
    var scrollRight = KTUtil.find(_element, ".card-scroll-right");
    var scrollFilterCard = KTUtil.find(_element, ".table-filter-card");
    var DeskUnitLeftHeader = KTUtil.find(_element, ".desk-unit-left-header");
    var DeskUnitRightHeader = KTUtil.find(_element, ".desk-unit-right-header");
    var DeskGirdHeader = KTUtil.find(_element, ".desk-gird-header");

    var cardBody = KTUtil.find(_element, ".card-body");
    var cardBodys = KTUtil.findAll(_element, ".card-body");
    var cardHeader = KTUtil.find(_element, ".card-header");
    var cardHeaders = KTUtil.findAll(_element, ".card-header");
    var navTabsFilter = KTUtil.find(_element, ".nav-tabs-filter");
    var navTabsFilterShow = KTUtil.find(_element, ".nav-tabs-filter-show");
    var btnGroupEmailSetting = KTUtil.find(_element, ".btn-email-group");
    var headerTable = KTUtil.find(_element, ".header-table-height");
    var filterTable = KTUtil.find(_element, ".filter-table-height");
    var notFoundTable = KTUtil.find(_element, ".not-found-table");
    var antTabsNav = KTUtil.find(_element, ".ant-tabs-fixed .ant-tabs-nav");
    var antTabsNavs = KTUtil.findAll(_element, ".ant-tabs-fixed .ant-tabs-nav");

    var antTabsNavWrap = KTUtil.find(
      _element,
      ".ant-tabs-fixed .ant-tabs-nav .ant-tabs-nav-wrap"
    );
    var antTabsNavWraps = KTUtil.findAll(
      _element,
      ".ant-tabs-fixed .ant-tabs-nav .ant-tabs-nav-wrap"
    );

    var height = KTLayoutContent.getHeight();
    var heightNotFoundTable = 0;
    var heightLeft = 0;
    var heightRight = 0;

    height =
      height -
      (parseInt(KTUtil.actualHeight(navTabsFilter)) || 0) -
      (parseInt(KTUtil.actualHeight(navTabsFilterShow)) || 0) -
      (parseInt(KTUtil.actualHeight(btnGroupEmailSetting)) || 0) +
      (footerReport && 30);

    if (cardHeaders.length > 1) {
      cardHeaders.forEach((ele) => {
        height -= parseInt(KTUtil.actualHeight(ele)) || 0;

        height =
          height -
          (parseInt(KTUtil.css(ele, "marginTop")) || 0) -
          (parseInt(KTUtil.css(ele, "marginBottom")) || 0);
      });
    } else {
      height -= parseInt(KTUtil.actualHeight(cardHeader)) || 0;

      height =
        height -
        (parseInt(KTUtil.css(cardHeader, "marginTop")) || 0) -
        (parseInt(KTUtil.css(cardHeader, "marginBottom")) || 0);
    }

    // LOẠI BỎ NHỮNG TRƯỜNG HỢP TAB CỐ ĐỊNH
    if (antTabsNavs.length > 1) {
      antTabsNavs.forEach((ele) => {
        height =
          height -
          (parseInt(KTUtil.css(ele, "marginTop")) || 0) -
          (parseInt(KTUtil.css(ele, "marginBottom")) || 0);
      });

      antTabsNavWraps.forEach((ele) => {
        height -= parseInt(KTUtil.actualHeight(ele)) || 0;
      });
    } else {
      height =
        height -
        (parseInt(KTUtil.css(antTabsNav, "marginTop")) || 0) -
        (parseInt(KTUtil.css(antTabsNav, "marginBottom")) || 0);

      height -= parseInt(KTUtil.actualHeight(antTabsNavWrap)) || 0;
    }

    height =
      height -
      (parseInt(KTUtil.css(_element, "marginTop")) || 0) -
      (parseInt(KTUtil.css(_element, "marginBottom")) || 0);

    height =
      height -
      (parseInt(KTUtil.css(_element, "paddingTop")) || 0) -
      (parseInt(KTUtil.css(_element, "paddingBottom")) || 0);

    if (cardBodys.length > 1) {
      cardBodys.forEach((ele) => {
        height =
          height -
          (parseInt(KTUtil.css(ele, "paddingTop")) || 0) -
          (parseInt(KTUtil.css(ele, "paddingBottom")) || 0);
        height =
          height -
          (parseInt(KTUtil.css(ele, "marginTop")) || 0) -
          (parseInt(KTUtil.css(ele, "marginBottom")) || 0);
      });
    } else {
      height =
        height -
        (parseInt(KTUtil.css(cardBody, "paddingTop")) || 0) -
        (parseInt(KTUtil.css(cardBody, "paddingBottom")) || 0);
      height =
        height -
        (parseInt(KTUtil.css(cardBody, "marginTop")) || 0) -
        (parseInt(KTUtil.css(cardBody, "marginBottom")) || 0);
    }

    //height = height - 6;

    KTUtil.css(scroll, "height", height + "px");

    KTUtil.css(scrollFilter, "height", height + "px");

    heightNotFoundTable = height;
    heightNotFoundTable =
      height -
      (parseInt(KTUtil.actualHeight(headerTable)) || 0) -
      (parseInt(KTUtil.actualHeight(filterTable)) || 0) -
      (parseInt(KTUtil.actualHeight(antTabsNav)) || 0);
    KTUtil.css(notFoundTable, "height", heightNotFoundTable + "px");

    heightRight =
      KTLayoutContent.getHeight() -
      (parseInt(KTUtil.actualHeight(DeskUnitLeftHeader)) || 0) -
      (parseInt(KTUtil.actualHeight(DeskUnitRightHeader)) || 0);
    heightRight =
      heightRight -
      (parseInt(KTUtil.css(_element, "marginTop")) || 0) -
      (parseInt(KTUtil.css(_element, "marginBottom")) || 0);

    heightRight =
      heightRight -
      (parseInt(KTUtil.css(_element, "paddingTop")) || 0) -
      (parseInt(KTUtil.css(_element, "paddingBottom")) || 0);

    heightRight =
      heightRight -
      (parseInt(KTUtil.css(cardBody, "paddingTop")) || 0) -
      (parseInt(KTUtil.css(cardBody, "paddingBottom")) || 0);

    heightRight =
      heightRight -
      (parseInt(KTUtil.css(cardBody, "marginTop")) || 0) -
      (parseInt(KTUtil.css(cardBody, "marginBottom")) || 0);
    heightRight = heightRight - 3;
    heightLeft =
      heightRight -
      (parseInt(KTUtil.actualHeight(DeskUnitLeftHeader)) || 0) -
      (parseInt(KTUtil.actualHeight(DeskUnitRightHeader)) || 0) -
      parseInt(KTUtil.actualHeight(DeskGirdHeader));

    KTUtil.css(
      scrollRight,
      "height",
      heightRight - (scrollFilterCard && 200) + "px"
    );

    KTUtil.css(scrollLeft, "height", heightLeft + "px");
  };

  // Public methods
  return {
    init: function (id) {
      // Initialize
      _init();
      // Re-calculate on window resize
      KTUtil.addResizeHandler(function () {
        _init();
      });
    },

    update: function () {
      _init();
    },
  };
})();

// Webpack support
if (typeof module !== "undefined") {
  // module.exports = KTLayoutStretchedCard;
}

export default KTLayoutStretchedCard;
