import { toastsSlice } from "./toastsSlice";


const { actions } = toastsSlice;

export const hideToasts = () => (dispatch) => {
    dispatch(actions.hideToast({}));
};

export const warningToast = (warning, callType) => (dispatch) => {
    dispatch(actions.warningToast({ warning, callType: callType }));
};

export const infoToast = (info, callType) => (dispatch) => {
    dispatch(actions.infoToast({ info, callType: callType }));
};

export const successToast = (notification, callType) => (dispatch) => {
    dispatch(actions.successToast({ notification, callType: callType }));
};

export const errorToast = (error, callType) => (dispatch) => {

    dispatch(actions.errorToast({ error, callType: callType }));
};