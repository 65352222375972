import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../_helpers';

export function ButtonEdit(props) {
    const {
        message = <FormattedMessage id="COMMONS.UI.Tooltip.Edit" />,
        action = () => { },
        isLoading = false,
        hidden = false,
        disabled = false,
    } = props;

    const [runLoading, setRunLoading] = useState(null);
    useEffect(() => {
        isLoading === false && setRunLoading(null);
        return () => {
        };
    }, [isLoading]);

    return (
        !hidden && (
            runLoading && isLoading ?
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                />
                :
                <OverlayTrigger
                    overlay={
                        <Tooltip id="common-edit-tooltip">
                            {message}
                        </Tooltip>
                    }
                >
                    <span
                        type="button"
                        disabled={disabled}
                        className="btn btn-icon btn-sm mx-1"
                        onClick={() => { action(); setRunLoading(true) }}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                        </span>
                    </span>
                </OverlayTrigger>
        )
    );
}
