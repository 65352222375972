import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCustomerHistoriesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  customerHistoryForEdit: null,
  customerHistoryForShow: null,
  currentCustomerHistory: null,
  currentCustomerHistories: [],
  notification: null,
  error: null,
  isError: null,
  errorType: null,
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const customerHistoriesSlice = createSlice({
  name: "customerHistories",
  initialState: initialCustomerHistoriesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },



    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCustomer = null;
      state.currentCustomers = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.customerForEdit = null;
    },

    resetCurrentCustomers: (state, action) => {
      state.currentCustomer = null;
      state.currentCustomers = [];
    },

    //show
    customerHistoryShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerHistoryForShow = action.payload.customerHistoryForShow;
      state.error = null;

    },

    // getCustomerById
    customerHistoryFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customerHistoryForEdit = action.payload.customerHistoryForEdit;
      state.error = null;

    },

    // updateCustomer
    customerHistoryUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.isError = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.customerHistory.id) {
          return action.payload.customerHistory;
        }
        return entity;
      });
      state.currentCustomerHistory = action.payload.customerHistory;
      state.currentCustomerHistories.push(action.payload.customerHistory);

    },
    // findCustomers
    customerHistoriesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount
    },

    // findAllCustomers
    customerHistoriesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },

    resetCustomerHistoriesModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.entities = [];
    },
  }
});
