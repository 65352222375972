/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import { Tab } from "bootstrap";
import { Field, FieldArray } from "formik";
import React, { useState } from "react";
import { Table, Tabs } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { formatBytes } from "../../../../../../_bloomgoo/_helpers";
import {
  Input,
  InputFile,
  SelectField,
  TextareaField,
} from "../../../../../../_bloomgoo/_partials/controls";
import { convertJsonString } from "../../../../../_helpers/_CommonUIHelpers";
import { AttachmentsDialog } from "../../../../Documents/pages/documents/attachment-dialog/AttachmentsDialog";
import {
  StatusSupportConditionTitles,
  SUPPORTS_PRIORITY,
  SUPPORTS_STATUSES,
} from "../SupportsUIHelpers";
import { ConversationBox } from "./ConversationBox";
import { HistoryConversation } from "./HistoryConversation";

export function FieldForm({
  parents,
  support,
  handleFieldChange,
  handleChange,
  users,
  props,
  fileSelected,
  setFileSelected,
  comment,
  mode,
  supportKey,
  setSupportsKey,
}) {
  let documentsArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    documentsArrayHelpers = arrayHelpers;
  };
  const [isShowDocuments, setIsShowDocuments] = useState(false);

  function applyDocumentsSelectedRows(selectedRows) {
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.map((row) => {
        const documentObj = {
          document: {
            id: null,
            documentCode: convertJsonString(row.documentCode),
            documentExtension: convertJsonString(row.documentExtension),
            documentFormat: convertJsonString(row.documentFormat),
            documentName: convertJsonString(row.documentName),
            documentOriginalName: convertJsonString(row.documentOriginalName),
            documentSize: convertJsonString(row.documentSize),
          },
          documentId: convertJsonString(row.id),
          functionName: "SUPPORTS",
        };
        documentsArrayHelpers.push(documentObj);
        return "";
      });
    }
    setIsShowDocuments(false);
  }

  const [isShowAttachmentList, setIsShowAttachmentList] = useState(false);

  return (
    <>
      {isShowDocuments && (
        <AttachmentsDialog
          show={isShowDocuments}
          onHide={() => {
            setIsShowDocuments(false);
          }}
          applyDocumentsSelectedRows={applyDocumentsSelectedRows}
        />
      )}
      {isShowAttachmentList && (
        <AttachmentsDialog
          isMulti={true}
          show={isShowAttachmentList}
          onHide={() => setIsShowAttachmentList(false)}
          applySelectedRows={applyDocumentsSelectedRows}
        ></AttachmentsDialog>
      )}
      {!support.id ? (
        <>
          <div className={`form-group row ${mode === "new" ? "d-none" : ""}`}>
            <div className="col-lg-3 d-none">
              <FormattedMessage id="MODEL.UI.supports.id">
                {(msg) => (
                  <Field
                    name="id"
                    component={Input}
                    disabled
                    placeholder={msg}
                    label={msg}
                    type={"hidden"}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.supports.code">
                {(msg) => (
                  <Field
                    name="code"
                    component={Input}
                    placeholder={msg}
                    label={msg}
                    type={"text"}
                    disabled
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.supports.contactName">
                {(msg) => (
                  <Field
                    name="contactName"
                    component={Input}
                    required={true}
                    placeholder={msg}
                    label={msg}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.supports.contactTel">
                {(msg) => (
                  <Field
                    name="contactTel"
                    component={Input}
                    required={true}
                    placeholder={msg}
                    label={msg}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-4">
              <FormattedMessage id="MODEL.UI.supports.contactEmail">
                {(msg) => (
                  <Field
                    name="contactEmail"
                    component={Input}
                    required={true}
                    placeholder={msg}
                    label={msg}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <FormattedMessage id="MODEL.UI.supports.name">
                {(msg) => (
                  <Field
                    name="name"
                    component={Input}
                    placeholder={msg}
                    label={msg}
                    required={true}
                    type={"text"}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <FormattedMessage id="MODEL.UI.supports.description">
                {(msg) => (
                  <Field
                    name="description"
                    component={TextareaField}
                    placeholder={msg}
                    label={msg}
                    type={"textarea"}
                    handleFieldChange={handleFieldChange}
                    formProps={props}
                    displayType="ReactQuill"
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              Hình ảnh
              <InputFile
                setFileSelected={setFileSelected}
                setIsShowAttachmentList={setIsShowAttachmentList}
                formProps={props}
              />
            </div>
          </div>
        </>
      ) : (
        <Tabs
          defaultActiveKey="supports"
          transition={false}
          id="noanim-tab-example"
          activeKey={supportKey}
          onSelect={(k) => setSupportsKey(k)}
        >
          <Tab eventKey="supports" title="Thông tin">
            <div className="form-group row pt-3">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.code"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>
                  :{" "}
                  {props.values
                    ? props.values.code
                      ? props.values.code
                      : ""
                    : ""}
                </span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.name"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>
                  :{" "}
                  <b>
                    {props.values
                      ? props.values.name
                        ? props.values.name
                        : ""
                      : ""}
                  </b>
                </span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.status"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>
                  :{" "}
                  {props.values
                    ? props.values.status
                      ? StatusSupportConditionTitles[props.values.status]
                      : ""
                    : ""}
                </span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 mt-2">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.description"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9 row ml-0 mt-2">
                <span>:&nbsp;</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.values && props.values.description,
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.contactName"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>: {props.values ? props.values.contactName : ""}</span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.contactTel"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>: {props.values ? props.values.contactTel : ""}</span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>
                  <FormattedMessage id="MODEL.UI.supports.contactEmail"></FormattedMessage>
                </b>
              </div>
              <div className="col-lg-9">
                <span>: {props.values ? props.values.contactEmail : ""}</span>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <b>Danh sách tài liệu</b>
              </div>
            </div>

            {support.id ? (
              <div className="form-group">
                <FieldArray name="attachments">
                  {(arrayHelpers) => {
                    bindArrayHelpers(arrayHelpers);
                    return (
                      <>
                        <div className="react-bootstrap-table table-responsive">
                          <Table
                            bordered
                            hover
                            size="sm"
                            className="table table-head-custom table-vertical-center table-sm table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">
                                  <FormattedMessage id="MODEL.UI.attachments.documentOriginalName"></FormattedMessage>
                                </th>
                                <th className="text-center">
                                  <FormattedMessage id="MODEL.UI.attachments.documentSize"></FormattedMessage>
                                </th>
                                <th className="text-center">
                                  <FormattedMessage id="COMMONS.UI.Tooltip.Download"></FormattedMessage>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.values &&
                                props.values.attachments &&
                                props.values.attachments.map(
                                  (row, rowIndex) => (
                                    <>
                                      <tr>
                                        <td className="text-left">
                                          {row.document &&
                                            row.document.documentOriginalName}
                                        </td>
                                        <td className="text-right">
                                          {formatBytes(
                                            row.document &&
                                              row.document.documentSize
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <a
                                            href={`${Axios.defaults.cdnURL}/${row.document.documentCode}`}
                                          >
                                            {" "}
                                            <i className="flaticon-download text-primary"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    );
                  }}
                </FieldArray>
              </div>
            ) : (
              <>
                <InputFile
                  setFileSelected={setFileSelected}
                  setIsShowAttachmentList={setIsShowAttachmentList}
                  formProps={props}
                />
              </>
            )}
            <div className="form-group row">
              <div className="col-lg-4">
                <FormattedMessage id="MODEL.UI.supports.status">
                  {(msg) => (
                    <Field
                      name="status"
                      component={SelectField}
                      defaulted={false}
                      type={"text"}
                      label={msg}
                      disabled={mode === "new"}
                      required={true}
                      options={[{ label: "-- Chọn --", value: 0 }].concat(
                        SUPPORTS_STATUSES &&
                          SUPPORTS_STATUSES.map(function (item) {
                            return { label: item.name, value: item.id };
                          })
                      )}
                      formProps={props}
                      handleFieldChange={handleFieldChange}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-4">
                `
                <FormattedMessage id="MODEL.UI.supports.priority">
                  {(msg) => (
                    <Field
                      name="priority"
                      component={SelectField}
                      type={"text"}
                      label={msg}
                      options={[{ label: "-- Chọn --", value: 0 }].concat(
                        SUPPORTS_PRIORITY &&
                          SUPPORTS_PRIORITY.map(function (item) {
                            return { label: item.name, value: item.id };
                          })
                      )}
                      formProps={props}
                      handleFieldChange={handleFieldChange}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-4">
                <FormattedMessage id="MODEL.UI.taskAssignees.assigneeId">
                  {(msg) => (
                    <Field
                      name="assigneeId"
                      component={SelectField}
                      type={"text"}
                      placeholder={msg}
                      required={true}
                      label={msg}
                      options={[{ label: "-- Chọn --", value: 0 }].concat(
                        users &&
                          users.map(function (item) {
                            return { label: item.fullName, value: item.id };
                          })
                      )}
                      formProps={props}
                      handleFieldChange={handleFieldChange}
                    />
                  )}
                </FormattedMessage>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <FormattedMessage id="MODEL.UI.supports.startDate">
                  {(msg) => (
                    <Field
                      name="startDateString"
                      component={Input}
                      required={true}
                      placeholder={msg}
                      label={msg}
                      type={"datetime-local"}
                      onChange={(e) => {
                        handleChange(e);
                        handleFieldChange(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-6">
                <FormattedMessage id="MODEL.UI.supports.dueDate">
                  {(msg) => (
                    <Field
                      name="dueDateString"
                      component={Input}
                      placeholder={msg}
                      label={msg}
                      type={"datetime-local"}
                      onChange={(e) => {
                        handleChange(e);
                        handleFieldChange(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-3 d-none">
                <FormattedMessage id="MODEL.UI.taskAssignees.id">
                  {(msg) => (
                    <Field
                      name="id"
                      component={Input}
                      placeholder={msg}
                      label={msg}
                      formProps={props}
                      type={"number"}
                      onChange={(e) => {
                        handleChange(e);
                        handleFieldChange(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-12 d-none">
                <FormattedMessage id="MODEL.UI.taskAssignees.reporterId">
                  {(msg) => (
                    <Field
                      name="reporterId"
                      component={SelectField}
                      type={"text"}
                      placeholder={msg}
                      label={msg}
                      options={[{ label: "-- Chọn --", value: 0 }].concat(
                        users &&
                          users.map(function (item) {
                            return { label: item.fullname, value: item.id };
                          })
                      )}
                      formProps={props}
                      handleFieldChange={handleFieldChange}
                    />
                  )}
                </FormattedMessage>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <FormattedMessage id="MODEL.UI.taskAssignees.completionDate">
                  {(msg) => (
                    <Field
                      name="completionDateString"
                      component={Input}
                      placeholder={msg}
                      label={msg}
                      type={"datetime-local"}
                      onChange={(e) => {
                        handleChange(e);
                        handleFieldChange(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="col-lg-6">
                <FormattedMessage id="MODEL.UI.supports.process">
                  {" "}
                </FormattedMessage>
                <div className="input-group">
                  <Field
                    name="process"
                    component={Input}
                    placeholder={"0 - 100"}
                    type={"text"}
                    required={true}
                    onChange={(e) => {
                      handleChange(e);
                      handleFieldChange(e, props);
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="supportComments" title="Trao đổi">
            {support && support.id && (
              <fieldset className="group-border">
                <ConversationBox
                  comment={comment}
                  support={support}
                  handleFieldChange={handleFieldChange}
                  supportKey={supportKey}
                />
              </fieldset>
            )}
          </Tab>
          <Tab eventKey="historyOwner" title="Lịch sử trao đổi">
            <HistoryConversation
              supportId={support.id}
              supportKey={supportKey}
              setSupportsKey={setSupportsKey}
            />
          </Tab>
          <Tab eventKey="historyCustomer" title="Lịch sử trao đổi khách hàng">
            <HistoryConversation
              supportId={support.id}
              supportKey={supportKey}
              setSupportsKey={setSupportsKey}
            />
          </Tab>
        </Tabs>
      )}
    </>
  );
}
