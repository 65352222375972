import React, { useEffect, useMemo, useState } from "react";
import { useSubheader } from "../../_bloomgoo/layout";
import { Row } from "./Row";

export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  const data1 = [
    { startChargeDateString: 1, endChargeDateString: 1, startNumber: 1, endNumber: 1, amount: 1, unitPrice: 1, amountMoney: 1, adjustmentMoney: 1, totalMoney: 1 },
    { startChargeDateString: 2, endChargeDateString: 2, startNumber: 2, endNumber: 2, amount: 2, unitPrice: 2, amountMoney: 2, adjustmentMoney: 2, totalMoney: 2 },
    { startChargeDateString: 3, endChargeDateString: 3, startNumber: 3, endNumber: 3, amount: 3, unitPrice: 3, amountMoney: 3, adjustmentMoney: 3, totalMoney: 3 },
    { startChargeDateString: 4, endChargeDateString: 4, startNumber: 4, endNumber: 4, amount: 4, unitPrice: 4, amountMoney: 4, adjustmentMoney: 4, totalMoney: 4 },
    { startChargeDateString: 5, endChargeDateString: 5, startNumber: 5, endNumber: 5, amount: 5, unitPrice: 5, amountMoney: 5, adjustmentMoney: 5, totalMoney: 5 },
    { startChargeDateString: 6, endChargeDateString: 6, startNumber: 6, endNumber: 6, amount: 6, unitPrice: 6, amountMoney: 6, adjustmentMoney: 6, totalMoney: 6 },
    { startChargeDateString: 7, endChargeDateString: 7, startNumber: 7, endNumber: 7, amount: 7, unitPrice: 7, amountMoney: 7, adjustmentMoney: 7, totalMoney: 7 },
    { startChargeDateString: 8, endChargeDateString: 8, startNumber: 8, endNumber: 8, amount: 8, unitPrice: 8, amountMoney: 8, adjustmentMoney: 8, totalMoney: 8 },
    { startChargeDateString: 9, endChargeDateString: 9, startNumber: 9, endNumber: 9, amount: 9, unitPrice: 9, amountMoney: 9, adjustmentMoney: 9, totalMoney: 9 },
    { startChargeDateString: 10, endChargeDateString: 10, startNumber: 10, endNumber: 10, amount: 10, unitPrice: 10, amountMoney: 10, adjustmentMoney: 10, totalMoney: 10 },
    { startChargeDateString: 11, endChargeDateString: 11, startNumber: 11, endNumber: 11, amount: 11, unitPrice: 11, amountMoney: 11, adjustmentMoney: 11, totalMoney: 11 },
    { startChargeDateString: 12, endChargeDateString: 12, startNumber: 12, endNumber: 12, amount: 12, unitPrice: 12, amountMoney: 12, adjustmentMoney: 12, totalMoney: 12 },
    { startChargeDateString: 13, endChargeDateString: 13, startNumber: 13, endNumber: 13, amount: 13, unitPrice: 13, amountMoney: 13, adjustmentMoney: 13, totalMoney: 13 },
    { startChargeDateString: 14, endChargeDateString: 14, startNumber: 14, endNumber: 14, amount: 14, unitPrice: 14, amountMoney: 14, adjustmentMoney: 14, totalMoney: 14 },
    { startChargeDateString: 15, endChargeDateString: 15, startNumber: 15, endNumber: 15, amount: 15, unitPrice: 15, amountMoney: 15, adjustmentMoney: 15, totalMoney: 15 },
    { startChargeDateString: 16, endChargeDateString: 16, startNumber: 16, endNumber: 16, amount: 16, unitPrice: 16, amountMoney: 16, adjustmentMoney: 16, totalMoney: 16 },
    { startChargeDateString: 17, endChargeDateString: 17, startNumber: 17, endNumber: 17, amount: 17, unitPrice: 17, amountMoney: 17, adjustmentMoney: 17, totalMoney: 17 },
    { startChargeDateString: 18, endChargeDateString: 18, startNumber: 18, endNumber: 18, amount: 18, unitPrice: 18, amountMoney: 18, adjustmentMoney: 18, totalMoney: 18 },
    { startChargeDateString: 19, endChargeDateString: 19, startNumber: 19, endNumber: 19, amount: 19, unitPrice: 19, amountMoney: 19, adjustmentMoney: 19, totalMoney: 19 },
    { startChargeDateString: 20, endChargeDateString: 20, startNumber: 20, endNumber: 20, amount: 20, unitPrice: 20, amountMoney: 20, adjustmentMoney: 20, totalMoney: 20 },
    { startChargeDateString: 21, endChargeDateString: 21, startNumber: 21, endNumber: 21, amount: 21, unitPrice: 21, amountMoney: 21, adjustmentMoney: 21, totalMoney: 21 },
    { startChargeDateString: 22, endChargeDateString: 22, startNumber: 22, endNumber: 22, amount: 22, unitPrice: 22, amountMoney: 22, adjustmentMoney: 22, totalMoney: 22 },
    { startChargeDateString: 23, endChargeDateString: 23, startNumber: 23, endNumber: 23, amount: 23, unitPrice: 23, amountMoney: 23, adjustmentMoney: 23, totalMoney: 23 },
    { startChargeDateString: 24, endChargeDateString: 24, startNumber: 24, endNumber: 24, amount: 24, unitPrice: 24, amountMoney: 24, adjustmentMoney: 24, totalMoney: 24 },
    { startChargeDateString: 25, endChargeDateString: 25, startNumber: 25, endNumber: 25, amount: 25, unitPrice: 25, amountMoney: 25, adjustmentMoney: 25, totalMoney: 25 },

  ];

  const initData = { data: data1, subtotalMoney: 0 }

  const [servicesProps, setServicesProps] = useState(data1);
  const [cloneServicesProps, setCloneServicesProps] = useState([]);
  const [subtotalMoney, setSubTotalMoney] = useState([]);

  useEffect(() => {
    setCloneServicesProps(servicesProps);
    setSubTotalMoney(initData.subtotalMoney);
  }, [servicesProps]);

  const handleFieldChange = (e, type, rowIndex) => {

  }


  return <>
    <div className="card card-custom gutter-b">
      <div className="card-body p-0">
        <table className="table">
          <thead>
            <tr>
              {/* <th className="font-weight-bold text-muted text-uppercase">startChargeDateString</th>
              <th className="font-weight-bold text-muted text-uppercase">endChargeDateString</th> */}
              <th className="font-weight-bold text-muted text-uppercase">startNumber</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">endNumber</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">amount</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">unitPrice</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">amountMoney</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">adjustmentMoney</th>
              <th className="font-weight-bold text-muted text-uppercase text-right">totalMoney</th>

            </tr>
            <tr>
              {/* <th className="font-weight-bold text-muted text-uppercase">startChargeDateString</th>
              <th className="font-weight-bold text-muted text-uppercase">endChargeDateString</th> */}
              <th className="font-weight-bold text-muted text-uppercase"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right"></th>
              <th className="font-weight-bold text-muted text-uppercase text-right">{subtotalMoney}</th>

            </tr>
          </thead>
          <tbody>
            {cloneServicesProps && cloneServicesProps.map((row, rowIndex) => {
              return (
                <Row beforeRow={row} rowIndex={rowIndex} subtotalMoney={subtotalMoney} setSubTotalMoney={setSubTotalMoney} />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  </>;
};
