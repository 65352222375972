import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { getFilterConfig } from "../../../_helpers/LocalStorageConfig";
import { getColumnToggleConfig, setColumnToggleConfig } from "./EmailsUIHelpers";
import { clearFilterVal } from "./EmailTable";

const EmailsUIContext = createContext();

export function useEmailsUIContext() {
  return useContext(EmailsUIContext);
}

export const EmailsUIConsumer = EmailsUIContext.Consumer;
export function EmailsUIProvider({ emailsUIEvents, children }) {
  const initialFilter = {
    filter: {
      searchCriteria: [


      ]
    },
    sortOrder: "desc", // asc||desc
    sortField: "createdAt",
    pageNumber: 1,
    pageSize: 35,
  };
  const [tabActive, setTabActive] = useState(`1`);
  const emailId = getFilterConfig().activeEmailId;
  const [activeEmailId, setActiveEmailId] = useState(emailId)
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [stateDataField, setStateDataField] = useState(getColumnToggleConfig())
  const changeHiddenColumns = (e) => {
    let _stateDataField = JSON.parse(JSON.stringify(stateDataField));
    _stateDataField[e.target.name] = e.target.checked;

    setStateDataField(_stateDataField);
    setColumnToggleConfig(_stateDataField)
  }

  const value = {
    changeHiddenColumns,
    stateDataField,
    queryParams,
    setQueryParamsBase,
    activeEmailId,
    setActiveEmailId,
    ids,
    setIds,
    selectedRows,
    setSelectedRows,

    setQueryParams,
    tabActive,
    setTabActive,
    newEmailButtonClick: emailsUIEvents.newEmailButtonClick,
    openEditEmailPage: emailsUIEvents.openEditEmailPage,
    openCloneEmailPage: emailsUIEvents.openCloneEmailPage,
    openDeleteEmailDialog: emailsUIEvents.openDeleteEmailDialog,
    openDeleteEmailsDialog: emailsUIEvents.openDeleteEmailsDialog,
    openShowEmailPage: emailsUIEvents.openShowEmailPage,
    openImportUnitPage: emailsUIEvents.openImportUnitPage,
    clearFilterVal: clearFilterVal,
    tabPeronal2ButtonClick: emailsUIEvents.tabPeronal2ButtonClick,
    tabFunctionsButtonClick: emailsUIEvents.tabFunctionsButtonClick,
    tabEmailsButtonClick: emailsUIEvents.tabEmailsButtonClick,
    tabDecentralizationButtonClick: emailsUIEvents.tabDecentralizationButtonClick,
    tabCurrenciesButtonClick: emailsUIEvents.tabCurrenciesButtonClick,
    tabBuildingButtonClick: emailsUIEvents.tabBuildingButtonClick,
  };

  return (
    <EmailsUIContext.Provider value={value}>
      {children}
    </EmailsUIContext.Provider>
  );
}
