/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import {
  Input,
  SwitchField,
  TextareaField,
} from "../../../../_bloomgoo/_partials/controls";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
import * as UIHelpers from "../../../_helpers/_CommonUIHelpers";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";
import { useEmailsUIContext } from "./EmailsUIContext";
import { EmailTable } from "./EmailTable";
import { EmailTemplateDeleteDialog } from "./EmailTemplateDeleteDialog";

const EmailTemplateSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  name: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  subject: Yup.string()
    .trim()
    .nullable()
    .max(
      5000,
      <FormattedMessage
        id="COMMONS.UI.FeedbackMaxSymbols"
        values={{ params: "5000" }}
      ></FormattedMessage>
    )
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
  content: Yup.string()
    .trim()
    .nullable()
    .required(
      <FormattedMessage
        id="MODEL.UI.VALIDATE.Required"
        values={{
          params: (
            <FormattedMessage id="MODEL.UI.tasks.name"></FormattedMessage>
          ),
        }}
      ></FormattedMessage>
    ),
});

export function EmailFieldForm({ reset, id, onHide, mode }) {
  const uIContext = useEmailsUIContext();
  const uIProps = useMemo(() => {
    return {
      ids: uIContext.ids,
      setIds: uIContext.setIds,
      queryParams: uIContext.queryParams,
      selectedRows: uIContext.selectedRows,
      setSelectedRows: uIContext.setSelectedRows,
      setQueryParams: uIContext.setQueryParams,
      newEmailButtonClick: uIContext.newEmailButtonClick,
      openEditEmailPage: uIContext.openEditEmailPage,
      openCloneEmailPage: uIContext.openCloneEmailPage,
      openDeleteEmailDialog: uIContext.openDeleteEmailDialog,
      openShowEmailPage: uIContext.openShowEmailPage,
      stateDataField: uIContext.stateDataField,
      changeHiddenColumns: uIContext.changeHiddenColumns,
    };
  }, [uIContext]);

  const { actionsLoading, emailTemplateEdit } = useSelector(
    (state) => ({
      actionsLoading: state.emailTemplates.actionsLoading,
      emailTemplateEdit: state.emailTemplates.emailTemplateEdit,
    }),
    shallowEqual
  );


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.newEmailTemplate());

    dispatch(actions.fetchEmailTemplates(uIProps.queryParams));
  }, [id, dispatch, uIProps.queryParams]);

  useEffect(() => {
    if (id) {
      if (mode === "edit") {
        dispatch(actions.editEmailTemplateById({ id: id }));
      } else if (mode === "clone") {
        dispatch(actions.cloneEmailTemplateById({ id: id }));
      }
    } else {
      dispatch(actions.newEmailTemplate());
    }
  }, [dispatch]);

  const saveEmailTemplate = (emailTemplate) => {
    // server request for creating meterLogGovernment
    dispatch(actions.createEmailTemplate(emailTemplate, "reloadForm"));
    dispatch(actions.newEmailTemplate());
  };

  const handleFieldChange = (e, props) => { };

  const [currentEmailTemplateId, setCurrentEmailTemplateId] = useState(1);

  const emailTemplateEvents = (action, id, row) => {
    console.log("action: ", action)
    if (action === "reset") {
      setCurrentEmailTemplateId();
      dispatch(actions.newEmailTemplate());
    }

    if (action === "edit") {
      setCurrentEmailTemplateId(id);
      dispatch(actions.editEmailTemplateById({ id: id }));
      handleCollapse("form", true)
    }
    if (action === "clone") {
      setCurrentEmailTemplateId(id);
      dispatch(actions.cloneEmailTemplateById({ id: id }));
      handleCollapse("form", true)
    }
    if (action === "delete") {
      setInfoEmailTemplateDelete(row);
    }
  };

  const [infoEmailTemplateDelete, setInfoEmailTemplateDelete] = useState();

  const [collapse, setCollapse] = useState({
    form: false,
    table: true,
    server: true,
  });

  useEffect(() => {
    if (collapse.form) {
      dispatch(actions.newEmailTemplate());
    }
  }, [collapse.form]);

  const handleCollapse = (name, value) => {
    const _collapse = { ...collapse };
    if (value) {
      _collapse[name] = value;
    }
    else {
      _collapse[name] = !_collapse[name];
    }
    setCollapse(_collapse);
  };

  const getIconCollapse = (name) => {
    if (collapse[name]) {
      return <i className="fas fa-caret-square-down mr-2 text-primary" />;
    }
    else {
      return <i className="fas fa-caret-square-right mr-2 text-hover-primary" />;
    }
  };

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    }),
    shallowEqual
  );

  return (
    <>
      <EmailTemplateDeleteDialog
        show={Boolean(infoEmailTemplateDelete)}
        onHide={() => setInfoEmailTemplateDelete(false)}
        emailTemplate={infoEmailTemplateDelete}
        currentEmailTemplateId={currentEmailTemplateId}
      />
      <Formik
        enableReinitialize={true}
        initialValues={emailTemplateEdit}
        validationSchema={EmailTemplateSchema}
        onSubmit={(values) => {
          saveEmailTemplate(values);
        }}
        onHide={onHide}
      >
        {({ handleSubmit, handleChange, resetForm, ...rest }) => {
          console.log("rest: ", rest.values)
          return (
            <>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <div
                data-toggle="collapse"
                href="#collapseForm"
                role="button"
                aria-expanded="false"
                aria-controls="collapseForm"
                onClick={() => handleCollapse("form")}
              >
                {collapse.form ?
                  <Button
                    message="COMMONS.UI.Close"
                    icon="flaticon-cancel icon-ms"
                    color="danger"
                  />
                  :
                  <Button
                    message="COMMONS.UI.New"
                    icon="flaticon-plus icon-ms"
                    hidden={!isPrivilegeUserAction(user, "SETTINGS_EMAIL_TEMPLATES_CREATE_WRITE")}
                  />
                }

              </div>
              {collapse.form &&
                <>
                  <div className="ml-7 text-left">
                    <div className="row form-group">
                      <div className="col-lg-4">
                        <FormattedMessage id="MODEL.UI.emailTemplates.code">
                          {(msg) => (
                            <Field
                              name="code"
                              component={Input}
                              placeholder={msg}
                              label={msg}
                              required={true}
                              type={"text"}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, rest);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                      <div className="col-lg-4">
                        <FormattedMessage id="MODEL.UI.emailTemplates.name">
                          {(msg) => (
                            <Field
                              name="name"
                              component={Input}
                              placeholder={msg}
                              required={true}
                              label={msg}
                              type={"text"}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, rest);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                      <div className="col-lg-4 ">
                        <FormattedMessage id="MODEL.UI.emailTemplates.isDefault">
                          {(msg) => (
                            <Field
                              name="isDefault"
                              component={SwitchField}
                              placeholder={msg}
                              label={msg}
                              type={"checkbox"}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, rest);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-lg-12">
                        <FormattedMessage id="MODEL.UI.emailTemplates.subject">
                          {(msg) => (
                            <Field
                              name="subject"
                              component={Input}
                              placeholder={msg}
                              required={true}
                              label={msg}
                              type={"text"}
                              onChange={(e) => {
                                handleChange(e);
                                handleFieldChange(e, rest);
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>

                    <div className="row form-group d-flex justify-content-between">
                      <label className="d-flex align-items-end">
                        <span className="ml-2">
                          Nội dung <span className="required">*</span>
                        </span>
                      </label>
                      <label>
                        <span>
                          <UIHelpers.ShowToken
                            listToken={UIHelpers.listTokenEmail}
                          />
                        </span>
                      </label>
                      <div className="col-lg-12">
                        <FormattedMessage id="MODEL.UI.emailTemplates.description">
                          {(msg) => (
                            <Field
                              name="content"
                              component={TextareaField}
                              type={"textarea"}
                              required={true}
                              height={rest.values?.description ? 350 : 250}
                              handleFieldChange={handleFieldChange}
                              formProps={rest}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end my-3">
                      <Button
                        disabled={actionsLoading}
                        handleClick={resetForm}
                        message="COMMONS.UI.Reload"
                        icon="flaticon2-refresh-arrow icon-sm"
                        color="secondary"
                        size="sm"
                      />
                      &nbsp;
                      <Button
                        isLoading={actionsLoading}
                        handleClick={() => {
                          handleSubmit();
                        }}
                        size="sm"
                        disabled={actionsLoading}
                        message="COMMONS.UI.Save"
                        icon="flaticon-edit-1 icon-sm"
                      />
                    </div>
                  </div>
                </>}
              <div
                data-toggle="collapse"
                href="#collapseTable"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTable"
                onClick={() => handleCollapse("table")}
                className="text-left gutter-b"
              >
                {getIconCollapse("table")}
                <b>Danh sách email</b>
              </div>
              <div className="collapse show ml-7" id="collapseTable">
                <EmailTable
                  emailTemplateEvents={emailTemplateEvents}
                  queryParams={uIProps.queryParams}
                  setQueryParams={uIProps.setQueryParams}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
}
