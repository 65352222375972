/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessages, Input, ModalProgressBar } from "../../../../_bloomgoo/_partials/controls";
import * as actions from "../_redux/api-keys/apiKeysActions";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { ERROR_TYPES_OBJ, useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { Field, Formik } from "formik";
const APIKeyPassWordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /(?=.*?[A-Z]).{6,}/,
      "Ít nhất 6 ký tự, ít nhất 1 ký tự hoa "
    )
    .required(),
});

export function APIKeyActiveDialog({ selectedRow, show, onHide, setIds, setSelectedRows, queryParams }) {
  const dispatch = useDispatch();
  const { actionsLoading, error, errorType, entities } = useSelector(
    (state) => ({
      entities: state.apiKeys.entities,
      actionsLoading: state.apiKeys.actionsLoading,
      errorType: state.apiKeys.errorType,
      error: state.apiKeys.error,
    }),
    shallowEqual
  );

  useDidMountEffect(() => {
    if (errorType === ERROR_TYPES_OBJ.END) {
      setIds([]);
      setSelectedRows([]);
      dispatch(actions.fetchAPIKeys(queryParams));
      onHide();
    }

  }, [errorType, error]);

  const updateActiveAPIKey = (values) => {
    dispatch(actions.updateIsActiveAPIKeyById(selectedRow?.id, values))
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <i className="flaticon-clipboard icon-lg text-white"> &nbsp; </i>
          {selectedRow?.isActive ?
            <>
              <FormattedMessage id="API_KEYS.UI.Title.UnIsActive"></FormattedMessage>{" "}
            </>
            :
            <>
              <FormattedMessage id="API_KEYS.UI.Title.IsActive"></FormattedMessage>{" "}
            </>
          }

        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          password: "",
          isActive: selectedRow?.isActive ? false : true
        }}
        validationSchema={APIKeyPassWordSchema}
        onSubmit={(values) => {
          updateActiveAPIKey(values);
        }}
      >
        {({ handleSubmit, handleChange, errors, ...props }) => (
          <>
            <Modal.Body>
              {error && error.action === "resetApiKey" && ((error.response === !undefined || error.response === !null) ?
                <ErrorMessages type="danger" message={error && error.response.data ? error.response.data.code : "COMMONS.UI.Notification.Danger"} icon="flaticon-danger text-danger" /> :
                <ErrorMessages type="danger" message={error && error.clientMessage} icon="flaticon-danger text-danger" />
              )
              }
              {!actionsLoading && (
                <span>

                  {selectedRow?.isActive ?
                    <>
                      <FormattedMessage id="MODEL.UI.UnIsActiveConfirm" values={{
                        params: `"${selectedRow?.name ? selectedRow.name : ""}"`,
                      }}></FormattedMessage>
                      <div className="text-italic">Ghi chú: Hủy kích hoạt API Key làm gián đoạn tới các nền tảng bên thứ ba.</div>
                      <div className="mt-3">Xác nhận mật khẩu để hủy kích hoạt API Key</div>
                    </>
                    :
                    <>
                      <FormattedMessage id="MODEL.UI.IsActiveConfirm" values={{
                        params: `"${selectedRow?.name ? selectedRow.name : ""}"`,
                      }}></FormattedMessage>
                      <div className="text-italic">Ghi chú: Kích API Key sẽ có thể kết nối tới các nền tảng bên thứ ba.</div>
                      <div className="mt-3">Xác nhận mật khẩu để kích hoạt API Key</div>
                    </>
                  }

                  <Field
                    name="password"
                    component={Input}
                    required={true}
                    type={"password"}
                    onChange={e => { handleChange(e) }}
                  />
                </span>
              )}
              {actionsLoading && (
                <span>
                  <FormattedMessage id="COMMONS.UI.IsResetting"></FormattedMessage>
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                handleClick={onHide}
                message="COMMONS.UI.Close"
                icon="flaticon-cancel icon-md"
                className="btn btn-light btn-elevate btn-md"
              />
              &nbsp;
              <Button
                actionsLoading={actionsLoading}
                handleClick={() => handleSubmit()}
                disabled={actionsLoading}
                message="COMMONS.UI.Save"
                icon="flaticon-edit-1 icon-md"
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}


