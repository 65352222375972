import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialDesksState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  entitiesInvoices: null,
  parents: [],
  deskForEdit: null,
  desksData: null,
  assets: null,
  buildingDesks: [],
  invoicesByUnits: null,
  error: null,
  isError: null,
  errorType: null,
  contract: null,
  contractDeposit: null,
  invoices: [],
  meterLogs: [],
  utilities: [],
  unitInfo: null,
  unitsDesk: [],
  refresh: 0,
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
};

export const desksSlice = createSlice({
  name: "desks",
  initialState: initialDesksState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
        state.actionsLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.isError = null;
      state.deskForEdit = null;
    },
    refresh: (state, action) => {
      state.refresh += 1;
    },
    // getDeskById
    deskFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.deskForEdit = action.payload.deskForEdit;
      state.error = null;
    },
    // findAllDesks
    desksFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findDesks
    desksFetched: (state, action) => {
      const { totalCount, entities, desksData } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.totalCount = totalCount;
      state.desksData = desksData;
    },

    desksInvoiceFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      const { totalCount, entities, desksData } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entitiesInvoices = entities;
      state.totalCount = totalCount;
      state.desksData = desksData;
    },
    desksFetchedAllUnit: (state, action) => {
      state.listLoading = false;
      state.buildingDesks = action.payload;
      let units = [];
      action.payload.floors.map((floor, index) => {
        units = units.concat(floor.units);
        return "";
      });
      state.unitsDesk = units;
    },
    // findParentDesks
    desksParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createDesk
    deskCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.entities.unshift(action.payload.desk);
    },
    // updateDesk
    deskUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.desk.id) {
          return action.payload.desk;
        }
        return entity;
      });
    },
    // deleteDesk
    deskDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteDesks
    desksDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // desksUpdateState
    desksStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //get unit
    unitFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.contract = action.payload.contract;
      state.contractDeposit = action.payload.contractDeposit;
      state.deposit = action.payload.deposit;
      state.invoices = action.payload.invoices;
      state.meterLogs = action.payload.meterLogs;
      state.unitInfo = action.payload.unitInfo;
      state.utilities = action.payload.utilities;
    },

    unitCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
    },
    //fetch assets by unitId
    assetsFetchedByUnitId: (state, action) => {
      state.listLoading = false;
      state.error = null;

      const { assets } = action.payload;
      state.assets = assets;
    },

    invoicesFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { invoices } = action.payload;
      state.invoicesByUnits = invoices;
    },
  },
});
