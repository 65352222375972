import { Field, Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { Input } from "../../../../_bloomgoo/_partials/controls";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
import * as auth from "../../Auth";
import * as actionsUsers from "../../Users/_redux/users/usersActions";

function PersonalPassword({ onHide }) {
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
      actionsLoading: state.users.actionsLoading,
    }),
    shallowEqual
  );

  const saveUser = (values) => {
    dispatch(actionsUsers.updateUserPassword(user.id, values));
  };

  const handleFieldChange = (e, props) => { };

  // const refContainer = useRef(initialValue);

  const persionalPassWordSchema = Yup.object().shape({
    password: Yup.string().trim().nullable().required(),
    newPassword: Yup.string().trim().nullable().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null]),
  });

  const initChangePassword = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <>
      {/* <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <span className="flaticon-time"> </span>
            Thay đổi mật khẩu
          </Modal.Title>
        </Modal.Header>
        <Information title="Trợ giúp" content={<FormattedMessage id="SETTINGS.INFO.EDIT" ></FormattedMessage>} /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initChangePassword}
        validationSchema={persionalPassWordSchema}
        onHide={onHide}
        onSubmit={(values) => {
          saveUser(values);
        }}
      >
        {({ handleSubmit, handleChange, handleReset, ...props }) => (
          <>
            <div className="col-lg-12 d-flex mt-5 ml-2">
              <FormattedMessage id="MODEL.UI.settings.passwordPre">
                {(msg) => (
                  <>
                    <div className="col-lg-3 col-form-label text-lg-left d-flex align-items-center text-input-hover ">
                      <span>
                        {msg}
                        <span className="required h6">{" "}*</span>
                      </span>
                    </div>
                    <div className="col-lg-9 pr-0">
                      <Field
                        name="password"
                        placeholder={"******"}
                        component={Input}
                        type={"password"}
                        checkTrue={false}
                        onChange={(e) => {
                          handleChange(e);
                          handleFieldChange(e, props);
                        }}
                      />
                    </div>
                  </>
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-12 d-flex py-3 ml-4">
              <FormattedMessage id="MODEL.UI.settings.passwordNew">
                {(msg) => (
                  <>
                    <div className="col-lg-3 pl-0 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                      <span>
                        {msg}
                        <span className="required h6">{" "}*</span>
                      </span>
                    </div>
                    <div className="col-lg-9 my-auto pl-0">
                      <Field
                        name="newPassword"
                        placeholder={"******"}
                        component={Input}
                        type={"password"}
                        checkTrue={false}
                        onChange={(e) => {
                          handleChange(e);
                          handleFieldChange(e, props);
                        }}
                      />
                    </div>
                  </>
                )}
              </FormattedMessage>
            </div>
            <div className=" col-lg-12 d-flex ml-4">
              <FormattedMessage id="MODEL.UI.settings.confirm">
                {(msg) => (
                  <>
                    <div className=" col-lg-3 px-0 col-form-label text-lg-left d-flex align-items-center text-input-hover">
                      <span>
                        {msg}
                        <span className="required h6">{" "}*</span>
                      </span>
                    </div>
                    <div className="col-lg-9 my-auto pl-0">
                      <Field
                        name="confirmPassword"
                        placeholder={"******"}
                        component={Input}
                        checkTrue={false}
                        type={"password"}
                        onChange={(e) => {
                          handleChange(e);
                          handleFieldChange(e, props);
                        }}
                      />
                    </div>
                  </>
                )}
              </FormattedMessage>
            </div>
            <div className="col-lg-12 my-3 ml-4 text-right">
              <Button
                className="btn btn-primary btn-sm ml-2"
                handleClick={() => handleSubmit()}
                message="COMMONS.UI.Save"
                icon="flaticon-edit-1 icon-sm"
                hidden={
                  !isPrivilegeUserAction(user, "SETTINGS_PROFILE_WRITE")
                }
              />
              <Button
                handleClick={onHide}
                message="COMMONS.UI.Close"
                icon="flaticon-cancel icon-sm"
                className="btn btn-light btn-elevate btn-sm mx-2"
              />
            </div>
          </>
        )}
      </Formik>
      {/* </Modal> */}
    </>
  );
}
export default connect(null, auth.actions)(PersonalPassword);
