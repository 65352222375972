/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { isEqual, isFunction } from "lodash";
import { getFilterConfig } from "../../../../../_helpers/LocalStorageConfig";

const DocumentsUIContext = createContext();

export function useDocumentsUIContext() {
  return useContext(DocumentsUIContext);
}

export const DocumentsUIConsumer = DocumentsUIContext.Consumer;

export function DocumentsUIProvider({ documents, selectedRows, setSelectedRows, children }) {
  const initialFilter = {
    filter: {
      searchCriteria: [

        {
          key: "documentFormat",
          operation: ":",
          value: "image/",
          prefix: "*",
          suffix: "*"
        },
      ]
    },
    sortOrder: "asc", // asc||desc
    sortField: "createdAt",
    pageNumber: 1,
    pageSize: 7,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [currentDocuments, setCurrentDocuments] = useState(documents);
  const [ids, setIds] = useState([]);


  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {

  }, []);
  const [
    showEditCurrencyDialog,
    setShowEditCurrencyDialog,
  ] = useState(false);
  const openNewCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(true);
  };
  const openEditCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowEditCurrencyDialog(true);
  };
  const closeEditCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowEditCurrencyDialog(false);
  };
  const [
    showDeleteCurrencyDialog,
    setShowDeleteCurrencyDialog,
  ] = useState(false);

  const openDeleteCurrencyDialog = (id) => {
    setSelectedId(id);
    setShowDeleteCurrencyDialog(true);
  };
  const closeDeleteCurrencyDialog = () => {
    setSelectedId(undefined);
    setShowDeleteCurrencyDialog(false);
  };

  const [
    showDeleteDocumentsDialog,
    setShowDeleteDocumentsDialog,
  ] = useState(false);
  const openDeleteDocumentsDialog = () => {
    setShowDeleteDocumentsDialog(true);
  };
  const closeDeleteDocumentsDialog = () => {
    setShowDeleteDocumentsDialog(false);
  };
  const [
    showFetchDocumentsDialog,
    setShowFetchDocumentsDialog,
  ] = useState(false);
  const openFetchDocumentsDialog = () => {
    setShowFetchDocumentsDialog(true);
  };
  const closeFetchDocumentsDialog = () => {
    setShowFetchDocumentsDialog(false);
  };

  const value = {
    ids,
    setIds,
    selectedRows,
    setSelectedRows,

    queryParams,
    setQueryParams,
    selectedId,
    currentDocuments,
    showEditCurrencyDialog,
    openEditCurrencyDialog,
    openNewCurrencyDialog,
    closeEditCurrencyDialog,
    showDeleteCurrencyDialog,
    openDeleteCurrencyDialog,
    closeDeleteCurrencyDialog,
    showDeleteDocumentsDialog,
    openDeleteDocumentsDialog,
    closeDeleteDocumentsDialog,
    showFetchDocumentsDialog,
    openFetchDocumentsDialog,
    closeFetchDocumentsDialog,
  };

  return (
    <DocumentsUIContext.Provider value={value}>
      {children}
    </DocumentsUIContext.Provider>
  );
}
