import * as requestFromServer from "./currenciesCrud";
import { currenciesSlice, callTypes } from "./currenciesSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = currenciesSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentCurrencies = () => (dispatch) => {
  dispatch(actions.resetCurrentCurrencies());
};

// Area actions fetch list

export const fetchAllCurrencies = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllCurrencies()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.currenciesFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllCurrencies";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentCurrencies = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllCurrencies()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.currenciesParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentCurrencies";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
// Area Filter list

export const fetchCurrenciesExcluded = (queryParams, ids) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findCurrenciesExcluded(queryParams, ids)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;

        dispatch(actions.currenciesFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "Can't find currencies";
        error.callType = callType;
        error.action = "fetchCurrenciesExcluded";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

export const fetchCurrencies = (queryParams) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return (
    requestFromServer
      .findCurrencies(queryParams)
      // .getAllCurrencies()
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        const filterCount = {
          total: response.data.total
        }
        dispatch(actions.currenciesFetched({ totalCount, entities, filterCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "Can't find currencies";
        error.callType = callType;
        error.action = "fetchCurrencies";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      })
  );
};

// Area actions get item

export const fetchCurrency = (id, mode) => (dispatch) => {
  if (!id) {
    const callType = callTypes.action;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitCurrency()
      .then((response) => {
        const currency = response.data;
        dispatch(
          actions.currencyFetched({ currencyForEdit: currency })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchCurrency";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode && mode === "clone") {
      const callType = callTypes.action;
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getCurrencyCloneById(id)
        .then((response) => {
          const currency = response.data;
          dispatch(
            actions.currencyFetched({ currencyForEdit: currency })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchCurrency";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    }
    else
      if (mode === "edit") {
        const callType = callTypes.action;
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getCurrencyById(id)
          .then((response) => {
            const currency = response.data;
            dispatch(
              actions.currencyFetched({ currencyForEdit: currency })
            );
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchCurrency";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
};

// Area actions create, update item

export const createCurrency = (currencyForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createCurrency(currencyForCreation)
    .then((response) => {
      const currency = response.data;
      dispatch(actions.currencyCreated({ currency }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createCurrency";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateCurrency = (currency) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateCurrency(currency)
    .then((response) => {
      const currency = response.data;
      dispatch(actions.currencyUpdated({ currency }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateCurrency";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updateCurrenciesStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForCurrencies(ids, status)
    .then(() => {
      dispatch(actions.currenciesStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateCurrenciesStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item

export const deleteCurrencies = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteCurrencies(ids)
    .then(() => {
      dispatch(actions.currenciesDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteCurrencies";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteCurrency = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteCurrency(id)
    .then((response) => {
      dispatch(actions.currencyDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteCurrency";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};