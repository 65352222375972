/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { Card, CardBody, CardHeader, Input, MoneyField } from "../../../../_bloomgoo/_partials/controls";
import { colorExcel } from "../../../_helpers/_CommonUIHelpers";
import { isPrivilegeUserAction } from "../../../_helpers/PrivilegesUIHelper/User";
import * as actionsOrganizations from "../../Organizations/_redux/organizations/organizationsActions";
import { getHeaderSettings } from "../SettingsUIHelpers";

export default function SettingsCardFunctions() {
  const dispatch = useDispatch();

  const { user, actionsLoading, configFunctionForEdit } = useSelector(
    (state) => ({
      user: state.auth.user,
      configFunctionForEdit: state.organizations.configFunctionForEdit,
      notification: state.organizations.notification,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actionsOrganizations.configFunctionEdit(user.organizationId));
  }, [dispatch]);


  const saveConfigFunctions = (configFunctions) => {
    dispatch(actionsOrganizations.configFunctionUpdate(user.organizationId, configFunctions));
  }

  const converFunctions = {
    ORGANIZATIONS: "Tổ chức",
    DEPARTMENTS: "Phòng ban",
    POSITIONS: "Chức danh",
    ROLES: "Vai trò",
    USERS: "Nhân sự",
    PROJECTS: "Dự án",
    TASKS: "Công việc",
    UNIT_SYMBOLS: "Đơn vị tính",
    PERIODS: "Kỳ thanh toán",
    UNIT_TYPES: "Loại đơn vị thuê",
    FORMULAS: "Định mức",
    RECEIPT_VOUCHER_CATEGORIES: "Hạng mục thu",
    PAYMENT_VOUCHER_CATEGORIES: "Hạng mục chi",
    BUILDINGS: "Tòa nhà",
    FLOORS: "Tầng",
    UTILITIES: "Tiện ích",
    UNITS: "Đơn vị thuê",
    SERVICES: "Dịch vụ",
    ELECTRICITY_METERS: "Công tơ điện",
    WATER_METERS: "Công tơ nước",
    GAS_METERS: "Công tơ gas",
    VEHICLE_TYPES: "Loại phương tiện",
    CONTRACT_FORMS_CONTRACT: "Biểu mẫu",
    ORGANIZATION_CONTRACT_FORMS_CONTRACT: "Biễu mẫu chung hợp đồng",
    BANK_ACCOUNTS_CASH: "Tài khoản tiền mặt",
    ORGANIZATION_BANK_ACCOUNTS_CASH: "Tài khoản chung tiền mặt",
    SUPPLIERS: "Nhà cung cấp",
    ASSET_WAREHOUSES: "Vị trí tài sản",
    ASSET_CATEGORIES: "Nhóm tài sản",
    ASSET_STATUSES: "Tình trạng tài sản",
    ASSETS: "Tài sản",
    INCREASE_ASSET_SEQUENCES: "Báo tăng",
    DECREASE_ASSET_SEQUENCES: "Báo giảm",
    ALLOCATION_ASSET_SEQUENCES: "Cấp phát",
    RESTORE_ASSET_SEQUENCES: "Thu hồi",
    MOVE_ASSET_SEQUENCES: "Điều chuyển",
    DESTROY_ASSET_SEQUENCES: "Báo hủy",
    CUSTOMERS: "Khách hàng",
    VEHICLES: "Phương tiện",
    DEPOSITS: "Đặt cọc giữ chỗ",
    CONTRACTS: "Hợp đồng",
    METER_LOGS: "Chỉ số công tơ",
    INVOICES: "Hóa đơn",
    PAYMENT_VOUCHERS: "Phiếu chi",
    RECEIPT_VOUCHERS: "Phiếu thu",
    COLLABORATORS: "Cộng tác viên",
    USER_COMMISSIONS: "Hoa hồng",
    ORGANIZATION_CONTRACT_FORMS_CONTRACT_DEPOSIT: "Biễu mẫu chung cọc giữ chỗ",
    ORGANIZATION_CONTRACT_FORMS_INVOICE: "Biễu mẫu chung hóa đơn",
    ORGANIZATION_CONTRACT_FORMS_RECEIPT_VOUCHER: "Biễu mẫu chung phiếu thu",
    ORGANIZATION_CONTRACT_FORMS_PAYMENT_VOUCHER: "Biễu mẫu chung phiếu chi",
    ORGANIZATION_CONTRACT_FORMS_CONTRACT_ADDENDUM: "Biễu mẫu chung phụ lục hợp đồng",
    CONTRACT_FORMS_CONTRACT_DEPOSIT: "Biễu mẫu cọc giữ chỗ",
    CONTRACT_FORMS_CONTRACT_ADDENDUM: "Biễu mẫu phụ lục hợp đồng",
    CONTRACT_FORMS_PAYMENT_VOUCHER: "Biễu mẫu phiếu chi",
    CONTRACT_FORMS_RECEIPT_VOUCHER: "Biễu mẫu phiếu thu",
    CONTRACT_FORMS_INVOICE: "Biễu mẫu hóa đơn",
    ORGANIZATION_BANK_ACCOUNTS_BANK: "Tài khoản chung ngân hàng",
    ORGANIZATION_BANK_ACCOUNTS_OTHER: "Tài khoản chung ví",
    BANK_ACCOUNTS_BANK: "Tài khoản ngân hàng",
    BANK_ACCOUNTS_OTHER: "Tài khoản ví",
    ASSET_INVENTORY_PERIODS: "Đợt kiểm kê",
    ELECTRICITY_METER_PARTNERS: "Công tơ điện - Công tơ đối chiếu",
    WATER_METER_PARTNERS: "Công tơ nước - Công tơ đối chiếu",
    GAS_METER_PARTNERS: "Công tơ gas - Công tơ đối chiếu",
    ASSET_INVENTORIES: "Kiểm kê tài sản",
    CUSTOMERS_INDIVIDUAL: "Khách hàng cá nhân",
    CUSTOMERS_ORGANIZATION: "Khách hàng doanh nghiệp",
    CUSTOMER_PROFILES: "Hồ sơ khách hàng",
    ALLOCATION_RESTORE_ASSET_SEQUENCES: "Nhập Excel cấp phát thu hồi",
    API_KEYS: "API Key"

  }

  const [lengthApply, setLengthApply] = useState();
  const [data, setData] = useState();
  const history = useHistory();

  useEffect(() => {
    configFunctionForEdit && setData(configFunctionForEdit)
  }, [configFunctionForEdit]);

  const handleFieldChange = (e, rowIndex, type) => {
    let _data = JSON.parse(JSON.stringify(data));
    _data.functions[rowIndex][type] = e.target.value;
    setData(_data);

  };

  const applyLength = () => {
    let _data = JSON.parse(JSON.stringify(data));
    _data.functions && _data.functions.map((row, rowIndex) => {
      _data.functions[rowIndex].length = lengthApply;
    })
    setData(_data);
  }
  const handleSubmit = () => {
    saveConfigFunctions(data)
  }

  const refreshPage = () => {
    window.location.reload();
  }

  let location = useLocation();



  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="btn-group" role="group" aria-label="...">
              {getHeaderSettings({ user, history, location })}
            </div>
          }
        >
        </CardHeader>
        <CardBody className="card-scroll">
          <div className="nav-tabs-filter">
          </div >
          {actionsLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}

          {/* {loading && <ModalProgressBar />} */}
          {/* begin::Header */}
          <div className="modal-body container container-fluid px-40">
            <div className="form-group d-flex justify-content-between my-3">
              <div className=" align-items-start flex-column">
              </div>
              <div className="d-flex align-items-center mr-2">
                <div className="row d-flex justify-content-end">
                  <div className="col-lg-7">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Độ dài"
                        aria-label="Độ dài"
                        aria-describedby="basic-addon2"
                        value={lengthApply}
                        onChange={(e) => setLengthApply(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button className="input-group-text btn btn-primary" id="basic-addon2" onClick={() => applyLength()}>Áp dụng</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="row  px-2">
              <div className="col-lg-12">

                <div className="react-bootstrap-table table-responsive">
                  <Table bordered hover size="sm" className="table table-head-custom table-vertical-center table-sm table-bordered table-hover">
                    <thead
                      style={{
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      <tr
                        style={{
                          backgroundColor: `${colorExcel.blue}`,
                        }}
                      >
                        <th className="text-center">#</th>
                        <th className="text-center">Tên chức năng</th>
                        <th className="text-center">Tiền tố</th>
                        <th className="text-center">Hậu tố</th>
                        <th className="text-center">Độ dài</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data && data.functions && data.functions.map((row, rowIndex) => (
                          <>
                            <tr>
                              <td className="text-center">
                                {rowIndex + 1}
                              </td>
                              <td className="text-left">
                                {converFunctions[row.name]}
                              </td>
                              <td className="text-center">
                                <input
                                  name={`functions[${rowIndex}].prefix`}
                                  component={Input}
                                  className="form-control form-control-sm"
                                  type="text"
                                  value={row.prefix}
                                  onChange={e => handleFieldChange(e, rowIndex, "prefix")}
                                  disabled={!isPrivilegeUserAction(user, "SETTINGS_FUNCTIONS_WRITE")}
                                />
                              </td>
                              <td>
                                <input
                                  name={`functions[${rowIndex}].suffix`}
                                  component={Input}
                                  className="form-control form-control-sm"
                                  type="text"
                                  value={row.suffix}
                                  onChange={e => handleFieldChange(e, rowIndex, "suffix")}
                                  disabled={!isPrivilegeUserAction(user, "SETTINGS_FUNCTIONS_WRITE")}
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  name={`functions[${rowIndex}].length`}
                                  component={MoneyField}
                                  className="form-control form-control-sm"
                                  type={"number"}
                                  value={row.length}
                                  onChange={e => handleFieldChange(e, rowIndex, "length")}
                                  disabled={!isPrivilegeUserAction(user, "SETTINGS_FUNCTIONS_WRITE")}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="text-right mb-4">
              <Button
                className="btn btn-secondary btn-md"
                handleClick={() => refreshPage()}
                message="COMMONS.UI.Reload"
                icon="flaticon2-refresh-arrow icon-ms"
              />
              <Button
                className="btn btn-primary btn-md ml-2 mr-2"
                handleClick={() => handleSubmit()}
                message="COMMONS.UI.Save"
                icon="flaticon-edit-1 icon-ms"
                hidden={!isPrivilegeUserAction(user, "SETTINGS_FUNCTIONS_WRITE")}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
