import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button } from "../../../../_bloomgoo/_partials/button/Button";
import { getFilterConfig } from "../../../_helpers/LocalStorageConfig";
import { ERROR_TYPES_OBJ, useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import * as actionsSettings from "../_redux/settings/settingsActions";

export default function SettingsBuildingNotification({ show, onHide, action, actionsLoading, error, errorType }) {
  const dispatch = useDispatch();
  useDidMountEffect(() => {
    if (errorType === ERROR_TYPES_OBJ.END) {
      dispatch(actionsSettings.fetchEditBuildingById(getFilterConfig().activeBuildingId));
      onHide();
    }
  }, [errorType, error]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <i className="flaticon-interface-5 text-white"></i>&nbsp;
            Cấu hình tòa nhà > Thiết lập mặc định
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!actionsLoading && (
            <span>
              Bạn có muốn thiết lập lại cấu hình tòa nhà từ dữ liệu mặc định không? <br />
            </span>
          )}
          {actionsLoading && (
            < span>
              Đang thiết lập lại dữ liệu tòa nhà...
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            handleClick={onHide}
            message="COMMONS.UI.Close"
            icon="flaticon-cancel icon-md"
            size="sm"
            className="btn btn-light btn-elevate btn-sm"
          />
          &nbsp;
          <Button
            actionsLoading={actionsLoading}
            handleClick={action}
            disabled={actionsLoading}
            message="MODEL.UI.settings.confirm"
            size="sm"
            icon="flaticon-edit-1 icon-md"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
