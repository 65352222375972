import { INVOICE_PAYMENT_STATUS_OBJ, CONTRACTS_STATUS_OBJ } from "../../app/_helpers/_CommonUIHelpers";
import { DATE_TIME_TIMESTAMP } from "./DateHelpers";
export const rowEvents = (props) => {
    const { ids, setIds, setSelectedRows=()=>{}, arrayRemove, selectedRows=[], setCurrentRow=()=>{} } = props;

    return {
        onClick: (e, row, rowIndex) => {
            if (typeof e.target.className === "object") {
            } else
                if (e.target.className.includes("notEvent")) {
                    setCurrentRow && setCurrentRow();
                }
                else if (e.target.className.includes("action")) {
                    setCurrentRow && setCurrentRow(row);
                    setIds([row.id]);
                    setSelectedRows([row]);

                }
                else {
                    setCurrentRow && setCurrentRow();
                    if (!ids.some(id => id === row.id)) {
                        setIds([row.id, ...ids]);
                        setSelectedRows([row, ...selectedRows]);
                    } else {
                        const _selectedRows = (selectedRows || []).length ? selectedRows.filter(function (selectedRow) {
                            return selectedRow?.id !== row.id;
                        }) : []
                        setIds(arrayRemove(ids, row.id));
                        setSelectedRows(_selectedRows);
                    }
                }

        },
    };
};

export const rowOnlySelectEvents = (props) => {
    const { ids, setIds, setSelectedRows } = props;
    return {
        onClick: (e, row, rowIndex) => {
            if (!ids.some(id => id === row.id)) {
                setIds([row.id]);
                setSelectedRows([row]);
            } else {
                setIds([]);
                setSelectedRows([]);
            }
        },
    };
};

export const rowStyle = (props) => {

    return (row, rowIndex) => {
        const { ids, currentItems, mode, currentIds = [], settingBuilding, activeStatus, tabActivePreExpired } = props;
        let backgroundColor = '';
        if (ids && ids.some(id => id === row.id)) {
            backgroundColor = '#c8e6c9';
            return { backgroundColor };
        };

        if (currentItems === []) return;

        if (currentItems && currentItems.some(item => item.id === row.id)) {
            backgroundColor = "#ffe79e"
            return { backgroundColor };
        };

        if (currentIds && currentIds.length && currentIds.some(item => item === row.id)) {
            backgroundColor = "#ffe79e"
            return { backgroundColor };
        };
        if (mode === "invoices" && row?.paymentStatus === INVOICE_PAYMENT_STATUS_OBJ.UNPAID && row?.isClosingDebts === false) {
            let now = new Date();
            let maxDay = row.expiredDate;
            let offset = maxDay - now.getTime();
            if (!row.expiredDate) { return }
            else if (offset < 0) {
                backgroundColor = "#FFE2E5";
            }
            else if (Math.round(offset / 1000 / 60 / 60 / 24) === 0) {
                backgroundColor = "#fff4d4";
            }
            if (tabActivePreExpired === true) {
                backgroundColor = "#FFE2E5";
            }
            return { backgroundColor };
        };
        if (mode === "contracts") {
            let now = new Date();
            let maxDay = row.endTime;
            let offset = maxDay - now.getTime();

            let preExpiredDays = (settingBuilding && settingBuilding?.notificationContractPreExpiredDays ? settingBuilding?.notificationContractPreExpiredDays : 0) * DATE_TIME_TIMESTAMP.DAY
            if (!row.endTime) { return }
            else if ((offset < preExpiredDays) && row.status === 1) {
                backgroundColor = "#FFE2E5";
            }
            if (activeStatus === CONTRACTS_STATUS_OBJ.PRE_EXPIRED) {
                backgroundColor = "#FFE2E5";
            }
            return { backgroundColor };
        }
        if (mode === "deposits") {
            let now = new Date();
            let maxDay = row.expiryDate;
            let offset = maxDay - now.getTime();
            if (!row.expiryDate) { return }
            else if (offset < 0 && row.disableStatus !== 2 && row.status === 1) {
                backgroundColor = "#FFE2E5";
            } else if (Math.round(offset / 1000 / 60 / 60 / 24) === 0 && row.status !== 2) {
                backgroundColor = "#fff4d4";
            }
            return { backgroundColor };
        }
    }
};

