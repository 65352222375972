/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  isPrivilegePartnerGroup,
  isPrivilegePartnerPacket,
  isPartner,
} from "../../../../../../app/_helpers/PrivilegesUIHelper/Partner";
import { checkIsActive, toAbsoluteUrl } from "../../../../../_helpers";

export function HeaderPartner({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  return (
    <>
      {isPartner(user) && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/#"
            )}`}
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to="/#">
              <i className="flaticon-grid-menu-v2 icon-md text-primary mr-2"></i>
              <span className="menu-text">Nghiệp vụ</span>
            </NavLink>
            <div
              className="menu-submenu menu-submenu-fixed menu-submenu-left"
              style={{ width: "945px" }}
            >
              <div className="menu-subnav">
                <ul className="menu-content">
                  <li className="menu-item ">
                    {(isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD") ||
                      isPrivilegePartnerGroup(user, "PARTNER_USERS") ||
                      isPrivilegePartnerGroup(user, "PARTNER_UNIT_SYMBOLS") ||
                      isPrivilegePartnerGroup(user, "PARTNER_COLLABORATORS") ||
                      isPrivilegePartnerGroup(user, "PARTNER_TASKS") ||
                      isPrivilegePartnerGroup(user, "PARTNER_DOCUMENTS")) && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">
                            TỔ CHỨC
                          </span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                    <ul className="menu-inner menu-inner-grid">
                      {isPrivilegePartnerPacket({
                        namePrivilege: "PARTNER_DASHBOARD",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/partner/dashboard"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/partner/dashboard"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/dashboard.svg"
                                )}
                              />
                              <span className="menu-text">Quản trị</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegePartnerPacket({
                        namePrivilege: "COLLABORATORS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegePartnerGroup(user, "COLLABORATORS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/collaborators"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/collaborators"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/collaborators.svg"
                                )}
                              />
                              <span className="menu-text">Môi giới</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegePartnerPacket({
                        namePrivilege: "TASKS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegePartnerGroup(user, "TASKS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/tasks"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/tasks"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/tasks.svg"
                                )}
                              />
                              <span className="menu-text">Công việc</span>
                            </NavLink>
                          </li>
                        )}

                      {isPrivilegePartnerPacket({
                        namePrivilege: "DOCUMENTS",
                        type: user.organization.subscriberType,
                      }) &&
                        isPrivilegePartnerGroup(user, "DOCUMENTS") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/documents"
                            )} menu-link-grid`}
                          >
                            <NavLink
                              className="menu-link menu-link-grid"
                              to="/documents"
                            >
                              <img
                                alt=""
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Apps/documents.svg"
                                )}
                              />
                              <span className="menu-text">Tài liệu</span>
                            </NavLink>
                          </li>
                        )}
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {(isPrivilegePartnerGroup(user, "BUILDINGS") ||
                        isPrivilegePartnerGroup(user, "UNITS") ||
                        isPrivilegePartnerGroup(user, "SERVICES") ||
                        isPrivilegePartnerGroup(user, "ASSETS")) && (
                          <h3 className="menu-heading menu-toggle">
                            <i className="menu-bullet menu-bullet-dot">
                              <span></span>
                            </i>
                            <span className="menu-text d-flex justify-content-center">
                              CƠ SỞ
                            </span>
                            <i className="menu-arrow"></i>
                          </h3>
                        )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegePartnerPacket({
                          namePrivilege: "BUILDINGS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "BUILDINGS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/buildings"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/buildings"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/buildings.svg"
                                  )}
                                />
                                <span className="menu-text">Tòa nhà</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "UNITS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "UNITS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/units"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/units"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/units.svg"
                                  )}
                                />
                                <span className="menu-text">Đơn vị thuê</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "SERVICES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "SERVICES") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/services"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/services"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/services.svg"
                                  )}
                                />
                                <span className="menu-text">Dịch vụ</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "ASSETS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "ASSETS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/assets"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/assets"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/assets.svg"
                                  )}
                                />
                                <span className="menu-text">Tài sản</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>

                      {isPrivilegePartnerGroup(user, "PARTNER_REPORT") && (
                        <h3 className="menu-heading menu-toggle">
                          <i className="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span className="menu-text d-flex justify-content-center">
                            BÁO CÁO & THỐNG KÊ
                          </span>
                          <i className="menu-arrow"></i>
                        </h3>
                      )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegePartnerPacket({
                          namePrivilege: "PARTNER_REPORTS_FINANCIALS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(
                            user,
                            "PARTNER_REPORTS_FINANCIALS"
                          ) && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/overview/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/partner/reports/overview/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-overview.svg"
                                  )}
                                />
                                <span className="menu-text">Tài chính</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "PARTNER_REPORTS_FINANCIALS_PROFIT",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(
                            user,
                            "PARTNER_REPORTS_FINANCIALS_PROFIT"
                          ) && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/partner/reports/statistics/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/partner/reports/statistics/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-statistics.svg"
                                  )}
                                />
                                <span className="menu-text">Lợi nhuận</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege:
                            "PARTNER_REPORTS_FINANCIALS_REVENUE_EXPENSES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(
                            user,
                            "PARTNER_REPORTS_FINANCIALS_REVENUE_EXPENSES"
                          ) && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/debts/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/debts/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-debts.svg"
                                  )}
                                />
                                <span className="menu-text">
                                  Doanh thu-chi phí
                                </span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege:
                            "PARTNER_REPORTS_FINANCIALS_VOUCHERCATEGORIES",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(
                            user,
                            "PARTNER_REPORTS_FINANCIALS_VOUCHERCATEGORIES"
                          ) && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/reports/task/chart"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/reports/task/chart"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/report-tasks.svg"
                                  )}
                                />
                                <span className="menu-text">Theo hạng mục</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      {(isPrivilegePartnerGroup(user, "CUSTOMERS") ||
                        isPrivilegePartnerGroup(user, "VEHICLES") ||
                        isPrivilegePartnerGroup(user, "CONTRACTS") ||
                        isPrivilegePartnerGroup(user, "METER_LOGS") ||
                        isPrivilegePartnerGroup(user, "INVOICES") ||
                        isPrivilegePartnerGroup(user, "PAYMENT_VOUCHERS") ||
                        isPrivilegePartnerGroup(user, "RECEIPT_VOUCHERS")) && (
                          <h3 className="menu-heading menu-toggle">
                            <i className="menu-bullet menu-bullet-dot">
                              <span></span>
                            </i>
                            <span className="menu-text d-flex justify-content-center">
                              CHO THUÊ
                            </span>
                            <i className="menu-arrow"></i>
                          </h3>
                        )}

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegePartnerPacket({
                          namePrivilege: "CONTRACTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "CONTRACTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/contracts"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/contracts"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/contracts.svg"
                                  )}
                                />
                                <span className="menu-text">Hợp đồng</span>
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    </ul>
                  </li>

                  <li className="menu-item ">
                    <ul className="menu-inner">
                      <h3 className="menu-heading menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i>
                        <span className="menu-text d-flex justify-content-center">
                          TIỆN ÍCH
                        </span>
                        <i className="menu-arrow"></i>
                      </h3>

                      <ul className="menu-inner menu-inner-grid">
                        {isPrivilegePartnerPacket({
                          namePrivilege: "SETTINGS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "SETTINGS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/settings/personal"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/settings/personal"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/settings.svg"
                                  )}
                                />
                                <span className="menu-text">Cấu hình</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "POSTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "POSTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/posts"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/posts"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/posts.svg"
                                  )}
                                />
                                <span className="menu-text">Đăng tin</span>
                              </NavLink>
                            </li>
                          )}

                        {isPrivilegePartnerPacket({
                          namePrivilege: "SUPPORTS",
                          type: user.organization.subscriberType,
                        }) &&
                          isPrivilegePartnerGroup(user, "SUPPORTS") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/supports"
                              )} menu-link-grid`}
                            >
                              <NavLink
                                className="menu-link menu-link-grid"
                                to="/supports"
                              >
                                <img
                                  alt=""
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Apps/supports.svg"
                                  )}
                                />
                                <span className="menu-text">Trung tâm hỗ trợ</span>
                              </NavLink>
                            </li>
                          )}

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/guides"
                          )} menu-link-grid`}
                        >
                          <a
                            className="menu-link menu-link-grid"
                            href="https://chuoicanho.com/ho-tro/"
                            target="_blank"
                          >
                            <img
                              alt=""
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Apps/guides.svg"
                              )}
                            />
                            <span className="menu-text">Hướng dẫn</span>
                          </a>
                        </li>
                      </ul>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          {isPrivilegePartnerPacket({
            namePrivilege: "PARTNER_DASHBOARD",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/partner/dashboard"
                )}`}
              >
                <NavLink className="menu-link" to="/partner/dashboard">
                  <i className="flaticon-dashboard icon-md text-primary mr-2"></i>{" "}
                  <span className="menu-text">Bảng quản trị</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}

          {isPrivilegePartnerPacket({
            namePrivilege: "PARTNER_DASHBOARD_BUILDING",
            type: user.organization.subscriberType,
          }) &&
            isPrivilegePartnerGroup(user, "PARTNER_DASHBOARD_BUILDING") && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/partner/deskbase"
                )}`}
              >
                <NavLink className="menu-link" to="/partner/deskbase/buildings">
                  <i className="flaticon-computer icon-md text-primary mr-2"></i>
                  <span className="menu-text">Quản trị cở sở</span>
                  {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                </NavLink>
              </li>
            )}
          {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/chats"
          )}`}
        >
          <NavLink className="menu-link" to="/chats">

            <i className="flaticon-chat-1 icon-md text-primary mr-2"></i>
            <span className="menu-text">Chat</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li> */}
        </ul>
      )}

      {/*end::1 Level*/}

      {/*Classic submenu*/}
      {/*Mega submenu*/}
      {/*begin::1 Level*/}
    </>
  );
}
