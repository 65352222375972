import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../../../_helpers/_CommonUIHelpers";

const initialCashFlowsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  cashFlowForEdit: null,
  cashFlowForShow: null,
  filterCount: null,
  currentCashFlow: null,
  currentCashFlows: [],
  notification: null,
  error: null,
  isError: null,
  errorType: null

};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const cashFlowsSlice = createSlice({
  name: "cashFlows",
  initialState: initialCashFlowsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },



    notification: (state, action) => {
      state.notification = action.payload.notification;
    },





    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCashFlow = null;
      state.currentCashFlows = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.cashFlowForEdit = null;
    },

    resetCurrentCashFlows: (state, action) => {
      state.currentCashFlow = null;
      state.currentCashFlows = [];
    },

    // getCashFlowById
    cashFlowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.cashFlowForEdit = action.payload.cashFlowForEdit;
      state.error = null;

    },
    //show
    getCashFlow: (state, action) => {
      state.actionsLoading = false;
      state.cashFlowForShow = action.payload.cashFlowForShow;
      state.error = null;

    },
    // findAllCashFlows
    cashFlowsFetchedAll: (state, action) => {
      const { total, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.entities = entities;
      state.total = total;
    },
    // findCashFlows
    cashFlowsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;


      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.currentCashFlow = null;

    },
    // findParentCashFlows
    cashFlowsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createCashFlow
    cashFlowCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;

      state.isError = false;
      state.entities.unshift(action.payload.cashFlow);
      state.currentCashFlow = action.payload.cashFlow;
      state.currentCashFlows.push(action.payload.cashFlow);
    },
    // updateCashFlow
    cashFlowUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.cashFlow.id) {
          return action.payload.cashFlow;
        }
        return entity;
      });
      state.currentCashFlow = action.payload.cashFlow;
      state.currentCashFlows.push(action.payload.cashFlow);

    },
    // deleteCashFlow
    cashFlowDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.currentCashFlows = [];

    },
    // deleteCashFlows
    cashFlowsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;

      state.isError = false;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentCashFlow = null;
      state.currentCashFlows = [];

    },
    // cashFlowsUpdateState
    cashFlowsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;

      state.error = null;

      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
