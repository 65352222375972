import React from "react";
import { Route } from "react-router-dom";
import { EmailsUIProvider } from "./EmailsUIContext";
import { EmailTemplateDeleteDialog } from "./EmailTemplateDeleteDialog";
import { EmailTemplateDeletesDialog } from "./EmailTemplateDeletesDialog";
import { EmailTemplateEditDialog } from "./EmailTemplateEditDialog";
import { EmailTemplatesLoadingDialog } from "./EmailTemplatesLoadingDialog";
import * as actions from "../_redux/emailTemplates/emailTemplatesActions";

// import { OverviewLoadingDialog } from "./overview-loading-dialog/OverviewLoadingDialog";
import SettingsCardEmails from "./SettingsCardEmails";
import { useDispatch } from "react-redux";
import { SettingsUIProvider } from "../SettingsUIContext";

export default function SettingEmailsPage({ history }) {
  const dispatch = useDispatch();

  const emailsUIEvents = {
    newEmailButtonClick: () => {
      history.push("/settings/emails/new");
    },
    openCloneEmailPage: (id) => {
      history.push(`/settings/emails/${id}/clone`);
    },
    openEditEmailPage: (id) => {
      history.push(`/settings/emails/${id}/edit`);
    },
    openDeleteEmailDialog: (id) => {
      history.push(`/settings/emails/${id}/delete`);
    },
    openDeleteEmailsDialog: () => {
      history.push(`/settings/emails/deletes`);
    },
    openShowEmailPage: (id) => {
      history.push(`/settings/emails/${id}/show`);
    },

    tabPeronal2ButtonClick: () => {
      history.push("/settings/personal");
    },
    tabFunctionsButtonClick: () => {
      history.push("/settings/functions");
    },
    tabEmailsButtonClick: () => {
      history.push("/settings/emails");
    },
    tabDecentralizationButtonClick: () => {
      history.push("/settings/decentralization");
    },
    tabCurrenciesButtonClick: () => {
      history.push("/settings/currencies");
    },
    tabBuildingButtonClick: () => {
      history.push("/settings/building");
    },
  };
  return (
    <>
      <EmailsUIProvider emailsUIEvents={emailsUIEvents}>
        <EmailTemplatesLoadingDialog />
        <Route path="/settings/emails/new">
          {({ history, match }) =>
            match && (
              <EmailTemplateEditDialog
                show={match != null}
                id={match && match.params.id}
                mode="new"
                onHide={() => {
                  history.push("/settings/emails");
                  // dispatch(actions.resetModal()); // clean state emails
                }}
              />
            )
          }
        </Route>
        <Route path="/settings/emails/:id/clone">
          {({ history, match }) =>
            match && (
              <EmailTemplateEditDialog
                show={match != null}
                id={match && match.params.id}
                mode="clone"
                onHide={() => {
                  history.push("/settings/emails");
                  dispatch(actions.resetModal()); // clean state units
                }}
              />
            )
          }
        </Route>
        <Route path="/settings/emails/:id/edit">
          {({ history, match }) =>
            match && (
              <EmailTemplateEditDialog
                show={match != null}
                id={match && match.params.id}
                mode="edit"
                onHide={() => {
                  history.push("/settings/emails");
                  dispatch(actions.resetModal()); // clean state units
                }}
              />
            )
          }
        </Route>
        <Route path="/settings/emails/deletes">
          {({ history, match }) =>
            match && (
              <EmailTemplateDeletesDialog
                show={match != null}
                onHide={() => {
                  history.push("/settings/emails");
                  dispatch(actions.resetModal()); // clean state units
                }}
              />
            )
          }
        </Route>
        <Route path="/settings/emails/:id/delete">
          {({ history, match }) =>
            match && (
              <EmailTemplateDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/settings/emails");
                  dispatch(actions.resetModal()); // clean state units
                }}
              />
            )
          }
        </Route>
        <SettingsCardEmails />
      </EmailsUIProvider>
    </>
  );
}
