import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialCustomFieldsState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  customFieldForEdit: null,
  customFieldForShow: null,
  entitiesForNew: null,
  lastError: null,
  filterCount: null,
  currentCustomField: null,
  currentCustomFields: [],
  notification: null,
  error: null,
  isError: null,
};
export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const customFieldsSlice = createSlice({
  name: "customFields",
  initialState: initialCustomFieldsState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },


    startCall: (state, action) => {
      ;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentCustomField = null;
      state.currentCustomFields = [];
    },

    resetModalShow: (state, action) => {
      state.error = null;
      state.errorType = null;
      state.isError = null;
      state.customFieldForShow = null;
    },

    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.customFieldForEdit = null;
    },

    resetCurrentCustomFields: (state, action) => {
      state.currentCustomField = null;
      state.currentCustomFields = [];
    },

    // getCustomFieldById
    customFieldFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customFieldForEdit = action.payload.customFieldForEdit;
      state.error = null;
      state.isError = null;
    },
    customFieldBuildingFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customFieldForBuilding = action.payload.customFieldForBuilding;
      state.error = null;
      state.isError = null;
    },
    //show
    getCustomField: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.customFieldForShow = action.payload.customFieldForShow;
      state.error = null;
      state.isError = null;
    },
    // findAllCustomFields
    customFieldsFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.isError = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.currentCustomField = null;
    },
    // findCustomFields
    customFieldsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;

      state.error = null;
      state.isError = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.currentCustomField = null;
    },

    customFieldForNewsFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.isError = null;
      state.entitiesForNew = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
      state.currentCustomField = null;
    },
    // findParentCustomFields
    customFieldsParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.isError = null;
      state.parents = parents;
      state.totalCount = totalCount;
      state.currentCustomField = null;
    },
    // createCustomField
    customFieldCreated: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.reloadForm) {
        state.errorType = ERROR_TYPES_OBJ.MIDDLE2;
      }
      else {
        state.errorType = ERROR_TYPES_OBJ.END;
      }
      state.actionsLoading = false;
      state.error = null;
      state.isError = null;
      state.entities.unshift(action.payload.customField);
      state.currentCustomField = action.payload.customField;
      state.currentCustomFields.push(action.payload.customField);
    },
    // updateCustomField
    customFieldUpdated: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.reloadForm) {
        state.errorType = ERROR_TYPES_OBJ.MIDDLE2;
      }
      else {
        state.errorType = ERROR_TYPES_OBJ.END;
      }
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.customField.id) {
          return action.payload.customField;
        }
        return entity;
      });
      state.currentCustomField = action.payload.customField;
      state.currentCustomFields.push(action.payload.customField);
    },
    // deleteCustomField
    customFieldDeleted: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.reloadForm) {
        state.errorType = ERROR_TYPES_OBJ.MIDDLE2;
      }
      else {
        state.errorType = ERROR_TYPES_OBJ.END;
      }
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentCustomField = null;
      state.currentCustomFields = [];
    },
    // deleteCustomFields
    customFieldsDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.isError = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentCustomFields = [];
    },
    // customFieldsUpdateState
    customFieldsStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.isError = null;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
