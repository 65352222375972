import React from "react";
import { FormattedMessage } from "react-intl";
import * as requestFromServer from "./unitsCrud";
import { unitsSlice, callTypes } from "./unitsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice";

const { actions } = unitsSlice;

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentUnits = () => (dispatch) => {
  dispatch(actions.resetCurrentUnits());
};

export const fetchAllUnits = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllUnits()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsFetchedAll({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchAllUnits";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByBuildingIdAndRented = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingIdAndRented(buildingId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByBuildingIdAndRented";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitByInvoiceId = (invoiceId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUnitByInvoiceId(invoiceId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByBuildingIdAndRented";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByBuildingIdAndRentedInvoiceType =
  ({ buildingId, month, year, invoiceType, callType }) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .findByBuildingIdAndRentedInvoiceType(
          buildingId,
          month,
          year,
          invoiceType
        )
        .then((response) => {
          const entities = response.data.entities;
          const totalCount = response.data.totalCount;
          dispatch(actions.unitsIsRentedFetched({ totalCount, entities }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchUnitsByBuildingIdAndRented";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const fetchUnitsByBuildingId = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingId(buildingId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByBuildingId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByOtherBuildingId = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingIdAssets(buildingId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.otherUnitsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByOtherBuildingId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByContractId = (contractId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByContractId(contractId)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByContractId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByBuildingIdAndEmpty =
  (buildingId, contractId, mode) => (dispatch) => {
    const callType = callTypes.list;
    if (mode === "edit" || mode === "clone" || mode === "editContractOnDesks") {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .findByBuildingIdAndEmptyWithContractId(buildingId, contractId)
        .then((response) => {
          const entities = response.data.entities;
          const totalCount = response.data.totalCount;
          dispatch(actions.unitsIsEmptyFetched({ totalCount, entities }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchUnitsByBuildingIdAndEmpty";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(
            toastsSlice.actions.errorToast({ error, callType: callType })
          );
        });
    } else {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .findByBuildingIdAndEmpty(buildingId)
        .then((response) => {
          const entities = response.data.entities;
          const totalCount = response.data.totalCount;
          dispatch(actions.unitsIsEmptyFetched({ totalCount, entities }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchUnitsByBuildingIdAndEmpty";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(
            toastsSlice.actions.errorToast({ error, callType: callType })
          );
        });
    }
  };

export const fetchUnitsByBuildingIdAndNotDeposited =
  (buildingId, depositId) => (dispatch) => {
    const callType = callTypes.list;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .findByBuildingIdAndNotDeposited(buildingId, depositId)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.unitsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnitsByBuildingIdAndNotDeposited";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const fetchUnits =
  ({ callType = "list", queryParams, buildingId }) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .findUnits(queryParams, buildingId)
        .then((response) => {
          const entities = response.data.entities;
          const totalCount = response.data.totalCount;
          const filterCount = {
            total: response.data.total,
            totalDeposits: response.data.totalDeposits,
            totalEmpty: response.data.totalEmpty,
            totalRented: response.data.totalRented,
          };
          dispatch(actions.unitsFetched({ totalCount, entities, filterCount }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "Can't find units";

          error.callType = callType;
          error.action = "fetchUnits";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };
export const fetchDesksByBuildingId =
  (buildingId, month, year, queryParams) => (dispatch) => {
    const callType = callTypes.table;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .findDesksByBuildingId(buildingId, month, year, queryParams)
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.unitsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "Can't find units";

        error.callType = callType;
        error.action = "fetchDesksByBuildingId";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };
export const fetchDesks = (buildingId, queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findDesks(buildingId, queryParams)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalDeposits: response.data.totalDeposits,
        totalEmpty: response.data.totalEmpty,
        totalRented: response.data.totalRented,
      };
      dispatch(actions.unitsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchDesks";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentUnits = (buildingId) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingId(buildingId)
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchParentUnits";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitTypes = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingId(buildingId)
    .then((response) => {
      const unitsType = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.unitsParentFetched({ totalCount, unitsType }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitTypes";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnit = (id, buildingId, mode) => (dispatch) => {
  const callType = callTypes.initialization;
  if (
    mode &&
    (mode === "new" ||
      mode === "newUnitByFloorOnDesk" ||
      mode === "newUnitByBuildingOnDesk")
  ) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitialUnitByBuildingId(buildingId)
      .then((response) => {
        const unit = response.data;
        dispatch(actions.unitFetched({ unitForEdit: unit }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else if (id && mode === "clone") {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getUnitCloneById(id)
      .then((response) => {
        const unit = response.data;
        dispatch(actions.unitFetched({ unitForEdit: unit }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else if (mode && (mode === "edit" || mode === "editUnitOnDesk")) {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getUnitEditById(id)
      .then((response) => {
        const unit = response.data;
        dispatch(actions.unitFetched({ unitForEdit: unit }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else if (mode === "show") {
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getUnitById(id)
      .then((response) => {
        const unit = response.data;
        dispatch(actions.unitShowFetched({ unitForShow: unit }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnit";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  }
};

export const getInitialUnitByBuildingId = (buildingId) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getInitialUnitByBuildingId(buildingId)
    .then((response) => {
      const unit = response.data;
      dispatch(actions.unitFetched({ unitForEdit: unit }));
    })
    .catch((error) => {
      let alert = {
        type: "warning",
        message: "ERRORS.CLIENT.COMMONS.GLOBAL",
      };

      if (error?.response?.data) {
        alert.message = error?.response?.data?.code;
      }
      dispatch(actions.catchError({ alert, error, callType: callType }));
    });
};

export const getUnitEditById = (id) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUnitEditById(id)
    .then((response) => {
      const unit = response.data;
      dispatch(actions.unitFetched({ unitForEdit: unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnit";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const getUnitById = (id) => (dispatch) => {
  const callType = callTypes.initialization;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getUnitById(id)
    .then((response) => {
      const unit = response.data;
      dispatch(actions.unitShowFetched({ unitForShow: unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnit";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteUnit = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteUnit(id)
    .then((response) => {
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.delete"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else {
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "deleteUnit";
        error.status = "warning";
      }
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const createUnit = (unitForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createUnit(unitForCreation)
    .then((response) => {
      const unit = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.create"></FormattedMessage>
        ),
      };
      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitCreated({ unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "createUnit";
      error.status = "warning";

      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const createUnitBulk = (buildingId, floorId, unitBulk) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .storeUnitsBulk(buildingId, floorId, unitBulk)
    .then((response) => {
      const unit = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.create"></FormattedMessage>
        ),
      };
      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitCreated({ unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "createUnit";
      error.status = "warning";

      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateUnit = (unit) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUnit(unit)
    .then((response) => {
      const unit = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitUpdated({ unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";

      error.callType = callType;
      error.action = "updateUnit";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateUnitsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForUnits(ids, status)
    .then((response) => {
      const activeIds = response.data.idsUpdated;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage
            id="COMMONS.UI.INVOICE.Update"
            values={{
              params: `'${activeIds.length}/${ids.length}'`,
            }}
          ></FormattedMessage>
        ),
      };
      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitsStatusUpdated({ ids: activeIds, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "updateUnitsStatus";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const deleteUnits = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteUnits(ids)
    .then((response) => {
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.delete"></FormattedMessage>
        ),
      };
      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "deleteUnits";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchAssetsByUnitId = (unitId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findAssetsByUnitId(unitId)
    .then((response) => {
      const assets = response.data;
      dispatch(actions.assetsFetchedByUnitId({ assets }));
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchAssetsByUnitId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsCount = (buildingId) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return buildingId
    ? requestFromServer
      .findUnitsCount(buildingId)
      .then((response) => {
        const unitsCount = response.data;
        dispatch(actions.unitsCount({ unitsCount }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnitsCount";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(
          toastsSlice.actions.errorToast({ error, callType: callType })
        );
      })
    : dispatch(actions.unitsCount({ unitsCount: 0 }));
};
export const cleanUnitsCount = (buildingId) => (dispatch) => {
  return dispatch(actions.countUnitsClean());
};

export const fetchUnitsBuildingGrid = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findUnitsBuildingGrid()
    .then((response) => {
      const buildings = response.data;
      dispatch(actions.unitsBuildingGrid({ buildingsGrid: buildings }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsBuildingGrid";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByBuildingIdAssets = (buildingId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findByBuildingIdAssets(buildingId)
    .then((response) => {
      const unitsAssets = response.data.entities;
      dispatch(actions.unitsFetchedAssets({ unitsAssets }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.callType = callType;
      error.action = "fetchUnitsByBuildingId";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchUnitsByBuildingIdAndAssetInventoryPeriodId =
  (buildingId, assetInventoryPeriodId) => (dispatch) => {
    const callType = callTypes.list;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .findByBuildingIdAndAssetInventoryPeriodId(
        buildingId,
        assetInventoryPeriodId
      )
      .then((response) => {
        const entities = response.data.entities;
        const totalCount = response.data.totalCount;
        dispatch(actions.unitsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
        error.callType = callType;
        error.action = "fetchUnitsByBuildingId";
        error.status = "warning";
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  };

export const downloadUnitExport = (buildingId) => (dispatch) => {
  const callType = callTypes.export;
  return requestFromServer
    .getUnitExportExcel(buildingId)
    .then((response) => {
      let blob = new Blob([response.data], { type: ".xlsx" }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Don_vi_thue.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.download" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.action = "downloadContractForm";
      error.status = "warning";
    });
};

export const downloadTemplateUnitExport = () => (dispatch) => {
  return requestFromServer
    .getTemplateUnitExportExcel()
    .then((response) => {
      let blob = new Blob([response.data], { type: ".xlsx" }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `Tep_mau_don_vi_thue.xlsx`,
        disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })

    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
      error.action = "downloadContractForm";
      error.status = "warning";
    });
};

export const fetchUnitImport =
  (buildingId, file, callType = callTypes.initialization) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getUnitImport(buildingId, file)
        .then((response) => {
          const building = response.data;

          dispatch(actions.unitImportFetched({ unitImport: building }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchMeterLog";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const resetUnitImport = () => (dispatch) => {
  dispatch(actions.resetUnitImport());
};

export const fetchUnitImportStore =
  (buildingId, file, callType = callTypes.initialization) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getUnitImportStore(buildingId, file)
        .then((response) => {
          const user = response.data;
          const notification = {
            status: "success",
            title: (
              <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
            ),
            content: (
              <FormattedMessage
                id="COMMONS.UI.SUCCESS.IMPORT"
                values={{
                  params: `'${response.data.successRecords}/${response.data.records}'`,
                }}
              ></FormattedMessage>
            ),
          };
          dispatch(actions.unitImportStoreFetched({ userImport: user }));
          dispatch(
            toastsSlice.actions.successToast({ notification, callType: callType })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchMeterLog";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const getUnitRealtime =
  (callType = callTypes.initialization) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getUnitRealtime()
        .then((response) => {
          const reportRealTime = response.data;
          dispatch(actions.unitRealTimeGetted({ reportRealTime }));
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";
          error.callType = callType;
          error.action = "fetchMeterLog";
          error.status = "warning";
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    };

export const updateUnitRealtime = (unit) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUnitRealtime(unit)
    .then((response) => {
      const unit = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitRealtimeUpdated({ unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";

      error.callType = callType;
      error.action = "updateUnit";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateUnitEmptyRate = (unit) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateUnitEmptyRate(unit)
    .then((response) => {
      const unit = response.data;
      const notification = {
        status: "success",
        title: (
          <FormattedMessage id="NOTIFICATIONS.UI.Title"></FormattedMessage>
        ),
        content: (
          <FormattedMessage id="COMMONS.UI.SUCCESS.update"></FormattedMessage>
        ),
      };

      dispatch(
        toastsSlice.actions.successToast({ notification, callType: callType })
      );
      dispatch(actions.unitRealtimeUpdated({ unit }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL";

      error.callType = callType;
      error.action = "updateUnit";
      error.status = "warning";
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};
