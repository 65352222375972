/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/BloomGooLayout";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand(code) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const VIP_ORGANIZATIONS = [
    { code: 'aplus' },
    { code: 'bizciti' },
    { code: 'tingtong' },
    { code: 'nghiepvu' }
  ];

  const VIP_ORGANIZATION = VIP_ORGANIZATIONS.filter(item => item.code === code.code);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img height="40" src={toAbsoluteUrl(`/media/logos/${VIP_ORGANIZATION.length == 0 ? 'chuoicanho' : code.code}/logo.png`)} />
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
