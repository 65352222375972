import { FormattedMessage } from "react-intl";
import React from "react";
import * as requestFromServer from "./organizationsCrud";
import { organizationsSlice, callTypes } from "./organizationsSlice";

import { toastsSlice } from "../../../../../_bloomgoo/_partials/toasts-error/_redux/toasts/toastsSlice"

const { actions } = organizationsSlice;

// Area  actions Clean state

export const resetModule = () => (dispatch) => {
  dispatch(actions.resetModule());
};

export const resetModal = () => (dispatch) => {
  dispatch(actions.resetModal());
};

export const resetCurrentOrganizations = () => (dispatch) => {
  dispatch(actions.resetCurrentOrganizations());
};

// Area actions fetch list

export const fetchAllOrganizations = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllOrganizations()
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.organizationsFetchedAll({ totalCount, entities }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchAllOrganizations";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchParentOrganizations = () => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getAllOrganizations()
    .then((response) => {
      const parents = response.data.entities;
      const totalCount = response.data.totalCount;
      dispatch(actions.organizationsParentFetched({ totalCount, parents }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchParentOrganizations";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area Filter list

export const fetchOrganizations = (queryParams) => (dispatch) => {
  const callType = callTypes.table;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .findOrganizations(queryParams)
    .then((response) => {
      const entities = response.data.entities;
      const totalCount = response.data.totalCount;
      const filterCount = {
        total: response.data.total,
        totalActives: response.data.totalActives,
        totalInActives: response.data.totalInActives,
        totalSubscribers: response.data.totalSubscribers,
        totalTrials: response.data.totalTrials,
      }
      //console.log("totalSubscribers", filterCount.totalSubscribers)
      dispatch(actions.organizationsFetched({ totalCount, entities, filterCount }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "fetchOrganizations";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions get item

export const getOrganization = (id) => (dispatch) => {
  const callType = callTypes.action;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .getOrganization(id)
    .then((response) => {
      const organization = response.data;
      dispatch(
        actions.getOrganization({ organizationForShow: organization })
      );
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "getOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const fetchOrganization = (id, mode) => (dispatch) => {
  if (!id) {
    const callType = callTypes.action;
    dispatch(actions.startCall({ callType: callType }));
    return requestFromServer
      .getInitOrganization()
      .then((response) => {
        const organization = response.data;
        dispatch(
          actions.organizationFetched({ organizationForEdit: organization })
        );
      })
      .catch((error) => {
        if (error?.response && error?.response.data) {
          error.clientMessage = error?.response?.data?.code;
        } else
          error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
        error.callType = callType;
        error.action = "fetchOrganization";
        error.status = "warning"
        dispatch(actions.catchError({ error, callType: callType }));
        dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
      });
  } else
    if (mode === "clone") {
      const callType = callTypes.action;
      dispatch(actions.startCall({ callType: callType }));
      return requestFromServer
        .getOrganizationCloneById(id)
        .then((response) => {
          const organization = response.data;
          dispatch(
            actions.organizationFetched({ organizationForEdit: organization })
          );
        })
        .catch((error) => {
          if (error?.response && error?.response.data) {
            error.clientMessage = error?.response?.data?.code;
          } else
            error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
          error.callType = callType;
          error.action = "fetchOrganization";
          error.status = "warning"
          dispatch(actions.catchError({ error, callType: callType }));
          dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
        });
    }
    else
      if (mode && mode === "edit") {
        const callType = callTypes.action;
        dispatch(actions.startCall({ callType: callType }));
        return requestFromServer
          .getOrganizationEditById(id)
          .then((response) => {
            const organization = response.data;
            dispatch(
              actions.organizationFetched({ organizationForEdit: organization })
            );
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
            error.callType = callType;
            error.action = "fetchOrganization";
            error.status = "warning"
            dispatch(actions.catchError({ error, callType: callType }));
            dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
          });
      }
      else
        if (mode === "show") {
          const callType = callTypes.action;
          dispatch(actions.startCall({ callType: callType }));
          return requestFromServer
            .getOrganizationById(id)
            .then((response) => {
              const organization = response.data;
              dispatch(
                actions.organizationFetched({ organizationForEdit: organization })
              );
            })
            .catch((error) => {
              if (error?.response && error?.response.data) {
                error.clientMessage = error?.response?.data?.code;
              } else
                error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
              error.callType = callType;
              error.action = "fetchOrganization";
              error.status = "warning"
              dispatch(actions.catchError({ error, callType: callType }));
              dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
            });
        }
};

export const deleteOrganization = (id) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteOrganization(id)
    .then((response) => {
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationDeleted({ id }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions create, update item

export const createOrganization = (organizationForCreation) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .createOrganization(organizationForCreation)
    .then((response) => {
      const organization = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.create" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationCreated({ organization }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "createOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateOrganization = (organization, organizationId) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateOrganization(organization, organizationId)
    .then((response) => {
      const organization = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.edit" ></FormattedMessage>
      }

      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationUpdated({ organization }))
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateOrganization";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions  update status item

export const updateOrganizationsStatus = (ids, status) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateStatusForOrganizations(ids, status)
    .then((response) => {
      const activeIds = response.data.idsUpdated;
      const notification = {
        status: "success",
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.INVOICE.Update"
          values={{
            params: `'${activeIds.length}/${ids.length}'`,
          }}
        ></FormattedMessage >
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationsStatusUpdated({ ids: activeIds, status }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateOrganizationsStatus";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const updateOrganizationsIsActive = (ids, isActive) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .updateIsActiveForOrganizations(ids, isActive)
    .then((response) => {
      const activeIds = response.data.idsUpdated;
      const notification = {
        status: "success",
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.INVOICE.Update"
          values={{
            params: `'${activeIds.length}/${ids.length}'`,
          }}
        ></FormattedMessage >
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.organizationsIsActiveUpdated({ ids: activeIds, isActive }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "updateOrganizationsIsActive";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

// Area actions delete item

export const deleteOrganizations = (ids) => (dispatch) => {
  const callType = callTypes.exitForm;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .deleteOrganizations(ids)
    .then(() => {
      dispatch(actions.organizationsDeleted({ ids }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "deleteOrganizations";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

//configFunction

export const configFunctionEdit = (organizationId) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .configFunctionEdit(organizationId)

    .then((response) => {
      const configFunction = response.data;

      dispatch(actions.configFunctionFetched({ configFunctionForEdit: configFunction }));
    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "configFunctionEdit";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};

export const configFunctionUpdate = (organizationId, configFunctions) => (dispatch) => {
  const callType = callTypes.list;
  dispatch(actions.startCall({ callType: callType }));
  return requestFromServer
    .configFunctionUpdate(organizationId, configFunctions)
    .then((response) => {
      const configFunction = response.data;
      const notification = {
        status: 'success',
        title: <FormattedMessage id="NOTIFICATIONS.UI.Title" ></FormattedMessage>,
        content: <FormattedMessage id="COMMONS.UI.SUCCESS.update" ></FormattedMessage>
      }
      dispatch(toastsSlice.actions.successToast({ notification, callType: callType }));
      dispatch(actions.configFunctionFetched({ configFunctionForEdit: configFunction }));

    })
    .catch((error) => {
      if (error?.response && error?.response.data) {
        error.clientMessage = error?.response?.data?.code;
      } else
        error.clientMessage = "ERRORS.CLIENT.COMMONS.GLOBAL"
      error.callType = callType;
      error.action = "configFunctionUpdate";
      error.status = "warning"
      dispatch(actions.catchError({ error, callType: callType }));
      dispatch(toastsSlice.actions.errorToast({ error, callType: callType }));
    });
};