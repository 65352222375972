import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { isEqual } from "lodash";
import { useCurrenciesUIContext } from "./CurrenciesUIContext";
import { FormattedMessage } from 'react-intl';
import { Input } from "../../../../_bloomgoo/_partials/controls";

const prepareFilter = (queryParams, values) => {
  const { name } = values;
  const newQueryParams = { ...queryParams };
  const filter = { searchCriteria: [] };

  filter.searchCriteria.push({
    key: "name",
    operation: ":",
    value: name,
    prefix: "*",
    suffix: "*"
  });


  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CurrenciesFilter({ listLoading }) {

  const currenciesUIContext = useCurrenciesUIContext();
  const currenciesUIProps = useMemo(() => {
    return {
      openNewVoucherCategoryDialog: currenciesUIContext.openNewVoucherCategoryDialog,
      setQueryParams: currenciesUIContext.setQueryParams,
      queryParams: currenciesUIContext.queryParams,
      buildingId: currenciesUIContext.buildingId,
    };
  }, [currenciesUIContext]);
  // building Redux state

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(currenciesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, currenciesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      currenciesUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-8 margin-bottom-10-mobile">
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: "",
              }}
              onSubmit={(values) => {
                applyFilter(values);
              }}
            >
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-1 col-form-label text-lg-left ">
                      Tên
                    </div>
                    <div className="col-lg-6">
                      <FormattedMessage id="COMMONS.UI.PLACEHOLDER.Input" >
                        {msg => (
                          <Field
                            name="name"
                            component={Input}
                            placeholder={msg}
                            type="text"
                            className="form-control form-control-md"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e)
                              handleSubmit();
                            }}
                          />
                        )}
                      </FormattedMessage>
                      {/* <small className="form-text text-muted">
                        <b><FormattedMessage id="COMMONS.UI.SEARCH.Search" ></FormattedMessage></b> <FormattedMessage id="COMMONS.UI.SEARCH.By" ></FormattedMessage> <FormattedMessage id="MODEL.UI.currencies.name" ></FormattedMessage>
                      </small> */}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}