import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../../../../_bloomgoo/_partials/button/Button";
import { Documents } from "../organization-documents/Documents";
import { DocumentsUIProvider } from "../organization-documents/DocumentsUIContext";


export function ImageDocumnentsForm({
  actionsLoading,
  onHide,
  selectFileDocument,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <DocumentsUIProvider
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        >
          <Documents />
        </DocumentsUIProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          handleClick={onHide}
          message="COMMONS.UI.Close"
          icon="flaticon-cancel icon-md"
          className="btn btn-light btn-elevate btn-md"
        />
        &nbsp;
        <Button
          isLoading={actionsLoading}
          handleClick={() => selectFileDocument(selectedRows)}
          disabled={actionsLoading}
          message="COMMONS.UI.Save"
          icon="flaticon-edit-1 icon-md"
        />
      </Modal.Footer>
    </>
  );
}
