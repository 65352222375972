/* eslint-disable eqeqeq */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable array-callback-return */
import React from "react";
import { FormattedMessage } from "react-intl";

// Default position in array
export const FIRST_POSITION = 0;
export const SECOND_POSITION = 1;
export const THIRD_POSITION = 2;
export const FOURTH_POSITION = 3;

// NUMBER VALUE
export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const ELEVENT = 11;


// Enum Value

export const BOOLEAN_TRUE_VALUE = true;
export const BOOLEAN_FALSE_VALUE = false;

export const STRING_DEFAULT_LABEL = "-- -- --"
export const BLANK_DEFAULT_LABEL = ""

export const POSITION_INTERFACE_OBJ = {  // Vị trí hiển thị giao diện
    FRONT: `front`, // Phía trước
    REAR: `rear`,  // Phía sau
    END: `end`  // Phía cuối
}
// RADIO VALUE
export const RADIO_CONTRACT_LONG_TERM = `long-term`;      // Hợp đồng dài hạn
export const RADIO_CONTRACT_SHORT_TERM = `short-term`;      // Hợp đồng ngắn hạn

// Enum State

export const ContractPeriodTypeCssClasses = ["success", "warning"];
export const ContractPeriodTypeTitles = ["MODEL.UI.contracts.long-term", "MODEL.UI.contracts.short-term"]
// buildings
export const BuildingStatusCssClasses = [BLANK_DEFAULT_LABEL, "success", "dark"];
export const BuildingStatusTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="MODEL.UI.buildings.using" />,
    <FormattedMessage id="MODEL.UI.buildings.stopped" />
]

// change log
export const ChangeLogsStatusCssClasses = [BLANK_DEFAULT_LABEL, "warning", "success"];
export const ChangeLogsStatusTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="MODEL.UI.changeLogs.draft" />,
    <FormattedMessage id="MODEL.UI.changeLogs.published" />
]

// 
export const TransactionHistoriesStatusCssClasses = [BLANK_DEFAULT_LABEL, "success", "danger"];
export const TransactionHistoriesStatusTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="MODEL.UI.transactionHistories.confirmed" />,
    <FormattedMessage id="MODEL.UI.transactionHistories.unConfirmed" />
]

export const UnitStatusDepositTitles = [
    [],
    ["MODEL.UI.units.empty", "MODEL.UI.units.emptyDeposit"],
    ["MODEL.UI.units.rented", "MODEL.UI.units.rentedDeposit"],
    ["MODEL.UI.units.stop"]
]
// transaction action status
export const TransactionActionStatusCssClasses = [BLANK_DEFAULT_LABEL, "warning", "success", "danger", "secondary"];
export const TransactionActionStatusTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="MODEL.UI.transactionHistories.pending" />,
    <FormattedMessage id="MODEL.UI.transactionHistories.confirm" />,
    <FormattedMessage id="MODEL.UI.transactionHistories.cancel" />,
    <FormattedMessage id="MODEL.UI.transactionHistories.failed" />
]

// transaction action status
export const TransactionActionCssClasses = [BLANK_DEFAULT_LABEL, "danger", "success"];
export const TransactionActionTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="MODEL.UI.transactionHistories.action" />,
    <FormattedMessage id="MODEL.UI.transactionHistories.exported" />,
]

// Meter Log Record Status
export const MeterLogRecordCssClasses = ["warning", "success"];
export const MeterLogRecordTitles = [
    <FormattedMessage id="MODEL.UI.meterLogs.notWriteBack" />,
    <FormattedMessage id="MODEL.UI.meterLogs.write" />,
]

export const MeterLogPegCssClasses = ["warning", "success"];
export const MeterLogPegTitles = [
    <FormattedMessage id="MODEL.UI.meterLogs.cancelPeg" />,
    <FormattedMessage id="MODEL.UI.meterLogs.isPeg" />,
]

export const ReceiptPaymentTitles = [
    BLANK_DEFAULT_LABEL,
    <FormattedMessage id="COMMONS.UI.NewRevenue" />,
    <FormattedMessage id="COMMONS.UI.NewCost" />
]

export const UnitIsDepositTitles = [
    <FormattedMessage id="MODEL.UI.units.not_deposit" />,
    <FormattedMessage id="MODEL.UI.units.deposited" />
]

// service type 
export const ServiceTypeTitles = ["", "Tiền thuê", "Tiền cọc giữ chỗ", "Tiền cọc", "Tiền điện", "Tiền nước", "Tiền gas", "Tiền xe", "Dịch vụ khác", "", "", "Tiền trả trước"];

// bank account wallet
export const BankAccountWalletTitles = ["", "VNPay", "ZaloPay", "MoMo", "ShopeePay", "Moca", "ViettelPay", "Payoo", "VCTPay", "TrueMoney", "BankPlus", "VinID", "FoxPay", "VNPT", "Khác"];
// Enum String
export const STRING_EXPIRE_LABEL = <FormattedMessage id="COMMONS.UI.Expire" />
export const STRING_UN_EXPIRE_LABEL = <FormattedMessage id="COMMONS.UI.UnExpire" />

// Enum Obj
//deposit
export const DEPOSIT_DISABLE_OBJ = {
    IS_DEPOSIT: ONE,
    DISABLE_DEPOSIT: TWO
}


export const DEPOSIT_STATUS_OBJ = {
    EXPRIRE: ONE,
    UN_EXPRIRE: TWO
}
// contract
export const CONTRACT_STATUS_OBJ = {
    EXPRIRE: ONE,
    UN_EXPRIRE: TWO
}

// bankAccount type
export const BANK_ACCOUNT_TYPES_OBJ = {
    CASH: ONE,
    BANK: TWO,
    OTHER: THREE,
}

// organization
export const ORGANIZATION_OBJ = {
    BEE: "bee",
    NGHIEPVU: "nghiepvu"
}
// meter logs
export const METER_LOGS_TYPE_OBJ = {
    ELECTRICITY: 4,
    WATER: 5,
    GAS: 6
}
// building
export const BUILDING_STATUS_OBJ = {
    USING: 1,
    STOPPED: 2
}

// voucher
export const VOUCHER_TYPE_OBJ = {
    RECEIPT: ONE,
    PAYMENT: TWO
}

// transaction history
export const TRANSACTION_HISTORY_STATUS_OBJ = {
    CONFIRMED: ONE,
    UNCONFIRMED: TWO
}

// transaction history action status
export const TRANSACTION_HISTORY_ACTIONS_OBJ = {
    PENDING: ONE,
    CONFIRM: TWO,
    CANCEL: THREE,
    FAILED: FOUR
}

export const SERVICE_TYPES_OBJ = {
    RENT: ONE,
    PRE_DEPOSITS: TWO,
    DEPOSITS: THREE,
    ELECTRICITY: FOUR,
    WATER: FIVE,
    GAS: SIX,
    VEHICLE: SEVEN,
    OTHER: EIGHT,
    PREPAY: ELEVENT,

    INVOICE_SUMMARY_OTHER_COST_MONEY: 100,
    INVOICE_SUMMARY_DISCOUNT_MONEY: 101,
    INVOICE_SUMMARY_VAT_MONEY: 102,
    INVOICE_SUMMARY_REFUND_MONEY: 103,
    INVOICE_SUMMARY_DEBTS_MONEY: 104,
};

// good sequence 
export const GOOD_SEQUENCE_TYPE_OBJ = {
    INCREASE: ONE,
    DECREASE: TWO,
    ALLOCATION: THREE,
};

// gender  
export const GENDER_OBJ = {
    OTHER: ZERO,
    MALE: ONE,
    FEMALE: TWO,
};


// change logs
export const CHANGE_LOGS_STATUS_OBJ = {
    DRAFT: ONE,
    PUBLISHED: TWO,
};

// units is deposit
export const UNITS_IS_DEPOSIT_OBJ = {
    DEPOSITED: BOOLEAN_TRUE_VALUE,
    NODEPOSIT: BOOLEAN_FALSE_VALUE,
};

export const ALERT_TYPE_OBJ = {
    NONE: '',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
}

// FILTER 
export const TIMELINE_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 6, label: "Tháng này" },
    { value: 3, label: "Tuần này" },
    { value: 4, label: "Tuần trước" },
    { value: 5, label: "Trong 30 ngày" },
    { value: 7, label: "Tháng trước" },
    { value: 8, label: "Tháng trước nữa" },
    { value: 9, label: "Quý này" },
    { value: 10, label: "Quý trước" },
    { value: 11, label: "Năm nay" },
    { value: 12, label: "Năm trước" },
    { value: 2, label: "Toàn thời gian" }
];

export const PERIOD_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 3, label: "Kỳ này" },
    { value: 4, label: "Kỳ trước" },
    { value: 5, label: "Năm nay" },
    { value: 6, label: "Năm trước" },
    { value: 2, label: "Toàn kỳ" }
];

export const MONTHS_FILTER = [
    { id: 1, name: "Tháng 1" },
    { id: 2, name: "Tháng 2" },
    { id: 3, name: "Tháng 3" },
    { id: 4, name: "Tháng 4" },
    { id: 5, name: "Tháng 5" },
    { id: 6, name: "Tháng 6" },
    { id: 7, name: "Tháng 7" },
    { id: 8, name: "Tháng 8" },
    { id: 9, name: "Tháng 9" },
    { id: 10, name: "Tháng 10" },
    { id: 11, name: "Tháng 11" },
    { id: 12, name: "Tháng 12" },
];

export const YEARS_FILTER = [
    { id: 2015, name: "Năm 2015" },
    { id: 2016, name: "Năm 2016" },
    { id: 2017, name: "Năm 2017" },
    { id: 2018, name: "Năm 2018" },
    { id: 2019, name: "Năm 2019" },
    { id: 2020, name: "Năm 2020" },
    { id: 2021, name: "Năm 2021" },
    { id: 2022, name: "Năm 2022" },
    { id: 2023, name: "Năm 2023" },
    { id: 2024, name: "Năm 2024" },
    { id: 2025, name: "Năm 2025" },
    { id: 2026, name: "Năm 2026" },
    { id: 2027, name: "Năm 2027" },
    { id: 2028, name: "Năm 2028" },
    { id: 2029, name: "Năm 2029" },
    { id: 2030, name: "Năm 2030" },
];

// building
export const BUILDING_STATUS_FILTER = [
    { value: 1, label: "Đang sử dụng" },
    { value: 2, label: "Ngưng sử dụng" }
];

// transaction histories
export const TRANSACTION_HISTORIES_STATUS_FILTER = [
    { value: ONE, label: <FormattedMessage id="MODEL.UI.transactionHistories.confirmed" /> },
    { value: ONE, label: <FormattedMessage id="MODEL.UI.transactionHistories.unConfirmed" /> }
]

export const TIMELINE_SUGGEST_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 2, label: "Toàn thời gian" },
    { value: 6, label: "Tháng này" },
];

export const PERIOD_SUGGEST_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 3, label: "Kỳ này" },
    { value: 4, label: "Kỳ trước" },
];

export const TIMELINE_SUGGEST_REPORT_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 5, label: "Năm nay" },
    { value: 6, label: "Tháng này" },
];

export const PERIOD_SUGGEST_REPORT_FILTER = [
    { value: 1, label: "Tùy chỉnh" },
    { value: 5, label: "Năm nay" },
    { value: 3, label: "Kỳ này" },
];

export const CHANGE_LOGS_STATUS_FILTER = [
    { value: 1, label: <FormattedMessage id="MODEL.UI.changeLogs.draft" /> },
    { value: 2, label: <FormattedMessage id="MODEL.UI.changeLogs.published" /> }
];

// Report
export const REPORT_VOUCHER_CATEGORY_FILTER = [
    { value: VOUCHER_TYPE_OBJ.RECEIPT, label: "Thu" },
    { value: VOUCHER_TYPE_OBJ.PAYMENT, label: "Chi" },
];