/* Pagination Helprs */
import React from "react";

export const getPagesCount = (totalSize, sizePerPage) => {
  return Math.ceil(totalSize / sizePerPage);
};

export const getPages = (page, pagesCount, paginationSize) => {
  const result = [];
  if (!page) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < page) {
    return result;
  }

  if (pagesCount < paginationSize + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (page === pagesCount) {
    for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(paginationSize / 2);
  if (shiftCount < 1) {
    result.push(page);
    return result;
  }


  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - page < shiftCount + 2) {
    for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i);
    }
  }
  result.push(page);
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
};
const dataTypeFilter = {
  NOT_SPECIFIED: 1,
  NUMBER: 2,
  STRING: 3,
  DATETIME: 4,
};

const setOperationInvoiceType = (key, operation) => {
  if (key === "invoiceType") {
    return ":";
  } else return operation
}
export function getHandlerTableChange(setQueryParams, queryParams, type) {
  // props:
  // page: number;
  // sizePerPage: number;
  // sortField: string;
  // sortOrder: 'asc' | 'desc';
  // filters: { [key: string]: { filterVal: any; filterType: 'TEXT'; comparator: any } };
  // data: T[];
  // cellEdit: {
  //     rowId: string;
  //     dataField: any;
  //     newValue: any;
  // };
  return (type, { page, sizePerPage, sortField, sortOrder, filters, data }) => {
    //B1 ke thua gia tri cu

    // ghi chu, phai clone ra 1 query moi. vi k dc phep thay doi prop
    const searchCriteria = [];
    queryParams && queryParams.filter && queryParams.filter.searchCriteria && queryParams.filter.searchCriteria.forEach((criteria, index) => {
      if (criteria.isDefault || criteria.level === 2) {
        const newCriteria = {
          key: criteria.key,
          operation: setOperationInvoiceType(criteria.key, criteria.operation),
          value: criteria.value,
          prefix: criteria.prefix,
          suffix: criteria.suffix,
          isDefault: criteria.isDefault,
          level: criteria.level,
        };
        searchCriteria.push(newCriteria);
      }
    });

    // neu la filter
    if (type === "filter") {
      for (const dataField in filters) {
        const { filterVal, filterType, } = filters[dataField];
        let operation = ":";
        let value = "";
        let prefix = "";
        let suffix = "";
        let dataType = "";

        if (filterType === "TEXT") {
          operation = ":";
          value = filterVal;
          dataType = dataTypeFilter.STRING
          prefix = "*";
          suffix = "*";
        }
        else if (filterType === "NUMBER") {
          let number = filterVal.number;
          let comparator = filterVal.comparator === "=" ? ":" : filterVal.comparator;
          operation = comparator;
          value = number;
          dataType = dataTypeFilter.NUMBER
          prefix = "";
          suffix = "";
        } else if (filterType === "SELECT") {
          operation = ":";
          value = filterVal === "true" ? true : filterVal === "false" ? false : filterVal;
          dataType = dataTypeFilter.NUMBER
          prefix = "";
          suffix = "";
          console.log("value: ", value)

        } else if (filterType === "DATE") {
          let date = filterVal.date;
          let comparator = filterVal.comparator === "=" ? ":" : filterVal.comparator;
          operation = comparator;
          value = date;
          dataType = dataTypeFilter.DATETIME;
          prefix = "";
          suffix = "";
        }


        //b3. tao ra 1 doi tuong tim kiem moi
        const newCriteria = {
          key: dataField,
          operation: operation,
          value: value,
          prefix: prefix,
          dataType: dataType,
          suffix: suffix,
          isDefault: false,
          level: 3,
        };

        // kiem tra xem no co trong cai clone o tren chua

        let isExists = false;
        for (let i; i < searchCriteria.length; i++) {
          if (searchCriteria[i].key === dataField) {
            searchCriteria[i].value = filterVal;
            isExists = true;
          }
        }
        if (!isExists) {
          searchCriteria.push(newCriteria);
        }
      }

    };

    const filter = { searchCriteria };
    // final
    const pageNumber = page || 1;
    setQueryParams && setQueryParams((prev) =>
      type === "sort"
        ? { ...prev, sortOrder, sortField }
        : type === "pagination"
          ? { ...prev, pageNumber, pageSize: sizePerPage }
          : type === "filter"
            ? { ...prev, filter }
            : prev
    );
  };
}

export function getHandlerFilterTableChange(setQueryParams) {
  return (type, { page, sizePerPage, sortField, sortOrder, filters, data }) => {
    const pageNumber = page || 1;
    setQueryParams && setQueryParams((prev) =>
      type === "sort"
        ? { ...prev, sortOrder, sortField }
        : type === "pagination"
          ? { ...prev, pageNumber, pageSize: sizePerPage }
          : prev
    );
  };
}

export function PleaseWaitMessage({ entities }) {
  return <>{entities === null && <div>Đang tải dữ liệu...</div>}</>;
}

export function NoRecordsFoundMessage({ entities, isLoading }) {
  return (
    <>
      {/* {!isLoading && (< div className={`${entities && entities.length === 0 ? "tab-error" : ""}`}>
        {entities && entities.length === 0 && < NotFoundTable />}
      </div>)} */}
    </>
  );
}
