/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as actionsComments from "../../../_redux/supportComments/supportCommentsActions";
import { Formik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { compareDate } from "../../../../../../_bloomgoo/_helpers";
import { CommentTypeEnum } from "../../../../../_helpers/_CommonUIHelpers";
export function HistoryConversation(props) {
  const { supportId, supportKey, setSupportsKey } = props
  const { entities, user } = useSelector(
    (state) => ({
      user: state.auth.user,
      actionsLoading: state.supportComments.actionsLoading,
      entities: state.supportComments.entities,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const queryParams = {
    filter: {
      searchCriteria: [
        {
          key: "commentType",
          operation: ":",
          value: supportKey === "historyOwner" ? CommentTypeEnum.INTERNAL : CommentTypeEnum.EXTERNAL,
          prefix: "",
          suffix: "",
          isDefault: true,
          level: 1
        },
      ]
    },
    sortOrder: "desc", // asc||desc
    sortField: "updatedAt",
    pageNumber: 1,
    pageSize: 35,
  };

  useEffect(() => {
    console.log("Vào fieldForm")
    supportKey === "historyOwner" ? dispatch(actionsComments.fetchComments(supportId, queryParams)) : dispatch(actionsComments.findCommentsContact(supportId, queryParams));
  }, [dispatch, supportId, supportKey]);

  const editComment = (id) => {
    dispatch(actionsComments.fetchComment(id));
    setSupportsKey('supportComments')
  }
  //console.log("entities: ", entities)
  const [currentIndex, setCurrentIndex] = useState(1);
  return (
    <>
      <Formik
        enableReinitialize={true}
        // initialValues={task}
        onSubmit={(values, { resetForm }) => {
          // saveTask(values);
          resetForm()
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, ...props }) => (
          <>
            <div className="card-body">
              <div className="timeline timeline-3">
                <div className="timeline-items">


                  {entities && entities.map((item, index) => (
                    <>
                      <div className="timeline-item">
                        <div className="timeline-media">
                          <img
                            src={`${Axios.defaults.cdnURL}/${item && item.user && item.user.avatar}`}
                            alt=""
                            onError={(e) => e.target.src = "/media/public/blank.png"}
                          />
                        </div>
                        <div className="timeline-content">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="mr-2">
                              <span
                                type="button"
                                className="text-dark-75 text-hover-primary font-weight-bold"><b>{item.user ? item.user.fullName : ""}</b></span>
                              <span className="text-muted ml-2">{compareDate(item.updatedAt, new Date())} trước</span>
                              {/* <span className="label label-light-success font-weight-bolder label-inline ml-2">new</span> */}
                            </div>
                            <div data-toggle="tooltip" title="Quick actions" data-placement="left">
                              {item.userId === user.id && <i className="fas fa-pen-square mr-2" onClick={() => editComment(item.id)}></i>}
                            </div>
                          </div>
                          <div className={`${currentIndex === index ? "" : "text-over"}`}>
                            <span className="" dangerouslySetInnerHTML={{
                              __html: item.comment
                            }}

                            />
                            {(item && item.attachments && item.attachments.length > 0) && <span className="nav nav-tabs"></span>}
                            <ul className="nav">
                              {item && item.attachments && item.attachments.map((row) => (
                                <li className="nav-item"><span
                                  type="button"
                                  className={"nav-link"} >
                                  <i className="fas fa-link mr-1"></i><a href={`${Axios.defaults.cdnURL}/${row.document && row.document.documentCode}`}>{row.document && row.document.documentOriginalName}</a>
                                </span></li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>

          </>
        )}
      </Formik>
    </>
  );
}

