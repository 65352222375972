import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TYPES_OBJ } from "../../../../_helpers/_CommonUIHelpers";

const initialMeterLogsGovernmentState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  parents: [],
  meterPartnerForEdit: {},
  meterLogGovernmentForShow: null,
  currentMeterLogsGovernment: [],
  meterLogsGovernment: null,
  notification: null,
  filterCount: null,
  meterLogsGovernmentHistory: [],
  errorType: null
};


export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm"
};

export const meterPartnersSlice = createSlice({
  name: "meterPartners",
  initialState: initialMeterLogsGovernmentState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      }
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
        state.entities = [];
      }
      else
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else if (action.payload.callType === callTypes.action) {
          state.actionsLoading = false;
        } else if (action.payload.callType === callTypes.initialization) {
          state.notification = null;
          state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
          state.actionsLoading = false;
        } else {
          state.actionsLoading = false;
        }
    },


    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else {

      }
    },
    resetCurrentMeterLogsGovernment: (state, action) => {
      state.currentMeterLogsGovernment = [];
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) { state.error = null };
      state.errorType = null;
      state.isError = null;
      state.meterForEdit = null;
    },

    // SHOW
    meterPartnerShow: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerShow = action.payload.meterPartnerShow;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.error = null;
    },

    // UPDATE
    meterPartnerUpdate: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.meterPartnerUpdate.id) {
          return action.payload.meterPartnerUpdate;
        }
        return entity;
      });
      state.currentMeterPartners.push(action.payload.meterPartnerUpdate);
    },

    // DELETE
    meterPartnerDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentMeterPartners = [];
    },

    // FETCHALL
    meterPartnerFetchIndex: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // CREATE
    meterPartnerCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities.unshift(action.payload.meterPartner);
    },


    // FETCH buildingId
    meterPartnersFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
      state.filterCount = filterCount
    },

    // EDIT
    meterPartnerEdit: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerEdit = action.payload.meterPartnerEdit;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // CLONE
    meterPartnerClone: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerClone = action.payload.meterPartnerClone;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },



    // FETCH all
    meterPartnersFetchedAll: (state, action) => {
      const { meterPartners } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = meterPartners;
    },

    // NEW
    meterPartnerNew: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.meterPartnerEdit = action.payload.meterPartnerNew;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE
    },

    // DELETE bulk
    meterPartnerDeletedBulk: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
      state.currentMeterPartners = [];
      state.errorType = ERROR_TYPES_OBJ.END;

    },
  }
}
);