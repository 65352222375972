import axios from "axios";

export const BUILDINGS_URL = "/buildings";

// CREATE =>  POST: add a new building to the server
export function createBuilding(building) {
  return axios.post(BUILDINGS_URL, building);
}

// READ
export function getAllBuildings() {
  return axios.get(BUILDINGS_URL);
}

export function getInitBuilding() {
  return axios.get(`${BUILDINGS_URL}/create`);
}

export function findBuildingsByOrganizationId(organizationId) {
  return axios.get(`${BUILDINGS_URL}/organization/${organizationId}`);
}
export function getBuildingUserPermissionById(buildingId) {
  return axios.get(`${BUILDINGS_URL}/${buildingId}/buildingPermission/edit`);
}
export function getBuildingById(buildingId) {
  return axios.get(`${BUILDINGS_URL}/${buildingId}`);
}

export function getBuildingEditById(buildingId) {
  return axios.get(`${BUILDINGS_URL}/${buildingId}/edit`);
}

export function getBuildingCloneById(buildingId) {
  return axios.get(`${BUILDINGS_URL}/${buildingId}/clone`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBuildings(queryParams) {
  return axios.post(`${BUILDINGS_URL}/find`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateBuilding(building, id) {
  return axios.put(`${BUILDINGS_URL}/${id}`, building);
}

// UPDATE Status
export function updateStatusForBuildings(ids, status) {
  return axios.post(`${BUILDINGS_URL}/updateStatusForBuildings`, {
    ids,
    status,
  });
}
export function updateBuildingUserPermission(building) {
  return axios.put(`${BUILDINGS_URL}/${building.id}/buildingPermission/update`, building);
}

// DELETE => delete the building from the server
export function deleteBuilding(buildingId) {
  return axios.delete(`${BUILDINGS_URL}/${buildingId}`);
}

// DELETE Buildings by ids
export function deleteBuildings(ids) {
  return axios.delete(`${BUILDINGS_URL}/bulk`, { data: { ids } });

}

export function initialBuildings(initialObj) {
  return axios.post(`${BUILDINGS_URL}/initial`, initialObj);
}



export function findBuildingCount() {
  return axios.get(`${BUILDINGS_URL}/count`);
}

export function getTemplateBuildingExportExcel() {
  return axios.get(`${BUILDINGS_URL}/import/template`, {
    // include your additional POSTed data here
    responseType: "blob"
  })
}

// IMPORT
export function getBuildingImport(file) {
  return axios.post(`${BUILDINGS_URL}/import`, file);
};

// IMPORT store
export function getBuildingImportStore(file) {
  return axios.post(`${BUILDINGS_URL}/import/store`, file);
};

export function getBuildingExportExcel(buildingId) {
  return axios.get(`${BUILDINGS_URL}/export`, {
    // include your additional POSTed data here
    responseType: "blob"
  })
}
